import React from 'react';
import PropTypes from 'prop-types';
import ImportPdfOrderForm from 'components/Form/ImportPdfOrderForm/ImportPdfOrderForm';

const ModalImportPdfOrder = ({ className }) => {
  return (
    <div className={className}>
      <ImportPdfOrderForm />
    </div>
  );
};

ModalImportPdfOrder.propTypes = {
  className: PropTypes.string,
};

export default ModalImportPdfOrder;
