import React from 'react';
import { connect } from 'react-redux';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { modalActions } from 'actions';

const SubstitutCellRenderer = ({ value, openSubtitutModal, similarGroupItemList }) => {
  return (
    <ButtonPrimary
      className="substitut-button"
      onClick={() => {
        openSubtitutModal(value, similarGroupItemList);
      }}
    >
      Substitut
    </ButtonPrimary>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openSubtitutModal: (product, similarGroupItemList) =>
    dispatch(
      modalActions.open('substitut', {
        item: product,
        similarGroupItemList,
        width: '800',
        title: 'Produit de substitution',
      })
    ),
});

export default connect(null, mapDispatchToProps)(SubstitutCellRenderer);
