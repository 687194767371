import { aggridSearchBarConstants } from 'constants/AggridSearchBar.constants';

const initState = {
  searchValue: null,
};

export function aggridSearchBar(state = initState, action) {
  switch (action.type) {
    case aggridSearchBarConstants.SEARCH_FOR_PRODUCT:
      return {
        ...state,
        searchValue: action.query,
      };
    case aggridSearchBarConstants.SET_SEARCH:
      return {
        ...state,
        searchValue: null,
      };
    default:
      return state;
  }
}
