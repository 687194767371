import React, { Component } from 'react';
import { connect } from 'react-redux';

class AssortmentFilter extends Component {
  render() {
    const { categories, parentChange, parentIsChecked } = this.props;
    return (
      <div
        className="ag-filter-toolpanel-instance-body ag-filter"
        ref="agFilterToolPanelBody" // eslint-disable-line
        style={{
          position: 'relative',
          height: '150px',
        }}
      >
        <div className="ag-filter-toolpanel-instance-filter" style={{ border: 'none' }}>
          <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper">
            <div
              className="ag-virtual-list-item ag-filter-virtual-list-item"
              aria-rowindex="1"
              tabIndex="-1"
            >
              {categories.map((category) => {
                if (['Catalogue permanent', 'Catalogue précommande'].includes(category.name)) {
                  return null;
                }
                return (
                  <label className="ag-set-filter-item" key={`label-for${category.label}`}>
                    <div
                      role="presentation"
                      ref="eCheckbox"  // eslint-disable-line
                      className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                    >
                      <div
                        ref="eWrapper"  // eslint-disable-line
                        className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
                          parentIsChecked(category.id) ? 'ag-checked' : ''
                        }`}
                        role="presentation"
                      >
                        <input
                          className="ag-input-field-input ag-checkbox-input"
                          type="checkbox"
                          id={`ag-input-id-assortmentfilter-${category.id}`}
                          value={category.id}
                          onChange={parentChange}
                          checked={parentIsChecked(category.id)}
                        />
                      </div>
                    </div>
                    <span
                      ref="eFilterItemValue" // eslint-disable-line
                      className="ag-set-filter-item-value"
                    >
                      <span>{category.name}</span>
                    </span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { category } = state;
  return {
    categories: category.categories,
  };
};

const ConnectedFilter = connect(mapStateToProps, null, null, { withRef: true })(AssortmentFilter);

class Overtop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: {},
    };

    this.valueGetter = this.props.valueGetter;

    this.isFilterActive = this.isFilterActive.bind(this);
    this.onChange = this.onChange.bind(this);
    this.isParentChecked = this.isParentChecked.bind(this);
  }

  onChange(event) {
    const oldValue = !!this.state.isChecked[event.target.value];
    const { value } = event.target;
    this.setState(
      (prevState) => ({
        isChecked: {
          ...prevState.isChecked,
          [value]: !oldValue,
        },
      }),
      this.props.filterChangedCallback
    );
  }

  getModel() {
    return { value: this.state.isChecked };
  }

  setModel(model) {
    this.state.isChecked = model ? model.value : {};
  }

  doesFilterPass(params) {
    return Object.keys(this.state.isChecked).some(
      (item) =>
        this.state.isChecked[item] === true && params.node.data.group_item.split(',').includes(item)
    );
  }

  isFilterActive() {
    return Object.keys(this.state.isChecked).some((item) => this.state.isChecked[item]);
  }

  isParentChecked(id) {
    return this.state.isChecked[id];
  }

  render() {
    return (
      <ConnectedFilter
        {...this.props}
        parentIsChecked={this.isParentChecked}
        parentChange={this.onChange}
        isChecked={this.state.isChecked}
      />
    );
  }
}

export default Overtop;
