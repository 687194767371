import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalValidateCredit.style';

const handleClick = (closeModal, validateCredits) => {
  closeModal();
  validateCredits();
};

const ModalValidateCredit = ({ className, closeModal, validateCredits }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block">
        <span>Souhaitez-vous valider les demandes d&lsquo;avoir saisies ?</span>
      </div>
      <div className="modal-body__button-block">
        <ButtonPrimary
          inverted
          onClick={() => {
            closeModal();
          }}
        >
          ANNULER
        </ButtonPrimary>
        <ButtonPrimary onClick={() => handleClick(closeModal, validateCredits)}>
          VALIDER
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalValidateCredit.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  validateCredits: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});
export default connect(null, mapDispatchToProps)(withStyle(ModalValidateCredit));
