import { clientStatusConstants } from './Client.constants';
import { catalogConstants } from './Catalog.constants';

const { STATUS_ACTIVE, STATUS_BLOCKED, STATUS_INACTIVE } = clientStatusConstants;
const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;

const SECTIONS = {
  // Clients Filters
  clientStatusCatalog: {
    name: 'Permanent',
    sectionKey: 'permanent',
    options: [
      {
        label: 'Actifs',
        value: STATUS_ACTIVE,
      },
      {
        label: 'Bloqués',
        value: STATUS_BLOCKED,
      },
    ],
  },
  clientStatusPreorder: {
    name: 'Précommande',
    sectionKey: 'preorder',
    options: [
      {
        label: 'Actifs',
        value: STATUS_ACTIVE,
      },
      {
        label: 'Bloqués',
        value: STATUS_BLOCKED,
      },
    ],
  },
  clientStatus: {
    name: 'Utilisateurs',
    sectionKey: 'clientStatus',
    options: [
      {
        label: 'Actifs',
        value: STATUS_ACTIVE,
      },
      {
        label: 'Inactifs',
        value: STATUS_INACTIVE,
      },
    ],
  },
  // Catalog Filters
  catalog: {
    name: 'Catalogue',
    sectionKey: 'groupItem',
    options: [], // Filled in CatalogFilterForm
  },
  temperature: {
    name: 'Temperature',
    sectionKey: 'type',
    options: [
      {
        label: 'Sec',
        value: TEMPERATURE_DRY,
      },
      {
        label: 'Frais',
        value: TEMPERATURE_FRESH,
      },
      {
        label: 'Surgelé',
        value: TEMPERATURE_FROZEN,
      },
    ],
  },
  type: {
    name: 'Type',
    sectionKey: 'others',
    // the order of options here is crucial. see components/Filter/CatalogFilterForm
    options: [
      {
        label: 'Favoris',
        value: 'is_favorite',
      },
      {
        label: 'Promotions',
        value: 'has_promotion',
      },
      {
        label: 'Nouveautés',
        value: 'is_new',
      }, // Also filled with selections (in this position) in CatalogFilterForm
      {
        label: 'Articles déjà commandés',
        value: 'is_ordered',
      },
    ],
  },
  manufacturer: {
    name: 'Marques',
    sectionKey: 'manufacturer',
    options: [], // Filled from manufacturers (reducer)
  },
};

export const filterConstants = {
  OPEN: 'FILTER_MENU_OPEN',
  CLOSE: 'FILTER_MENU_CLOSE',
  UPDATE_FILTER: 'FILTER_UPDATE',
  DELETE_FILTER: 'FILTER_DELETE',
  DELETE_ALL: 'FILTER_DELETE_ALL',
  SECTIONS,
};
