/* eslint-disable react/no-will-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchFilter, getPath } from 'helpers';
import styled from 'styled-components';
import { matchPath } from 'react-router';
import screen from 'components/Core/Utils/Device';

const WithStickyStyle = styled.div`
  position: fixed;
  /* bottom: ${(props) => `${props.bottom}px`}; */
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 10;
  .insertstickyCard {
  .cart-footer-notice {
    text-align: center;
    padding-right: 20px;
    background: #f5f5f5;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 70%;
    padding: 8px 0;
    /* display: ${({ bottom }) => (bottom ? 'block' : 'none')}; */
    span {
      color: #787878;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
    }
    @media ${screen.tablet} {
      text-align: left;
      padding-left: 20px;
    }
  }
`;

class Sticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: 0,
    };
    this.footerRoot = document.getElementById('root');
  }

  componentDidMount() {
    if (window) window.addEventListener('scroll', this.handleScroll);
    this.footerRoot = document.getElementById('footer-block');
    if (
      document.documentElement.offsetHeight >=
      document.getElementById('footer-block').getBoundingClientRect().top
    ) {
      this.setState({
        bottom:
          document.documentElement.offsetHeight -
          document.getElementById('footer-block').getBoundingClientRect().top,
      });
    } else {
      this.setState({
        bottom: 0,
      });
    }
  }

  componentWillUpdate(prevProps) {
    const { isLoadingPromotionsCatalog, isLoadingNewCatalog } = this.props;
    const {
      isLoadingNewCatalog: prevIsLoadingNewCatalog,
      isLoadingPromotionsCatalog: prevIsLoadingPromotionsCatalog,
    } = prevProps;
    if (
      isLoadingNewCatalog !== prevIsLoadingNewCatalog ||
      isLoadingPromotionsCatalog !== prevIsLoadingPromotionsCatalog
    ) {
      if (
        document.documentElement.offsetHeight >=
        document.getElementById('footer-block').getBoundingClientRect().top
      ) {
        this.setState({
          bottom:
            document.documentElement.offsetHeight -
            document.getElementById('footer-block').getBoundingClientRect().top,
        });
      } else {
        this.setState({
          bottom: 0,
        });
      }
    }
  }

  componentWillUnmount() {
    if (window) window.removeEventListener('scroll', this.handleScroll);
  }

  getCollection(collections, filter) {
    if (collections && collections !== []) {
      const collection = collections.filter((c) => matchFilter(c.filter, filter));
      return collection[0];
    }
    return {};
  }

  handleScroll = () => {
    if (
      document.documentElement.offsetHeight >=
      document.getElementById('footer-block').getBoundingClientRect().top
    ) {
      this.setState({
        bottom:
          document.documentElement.offsetHeight -
          document.getElementById('footer-block').getBoundingClientRect().top,
      });
    } else {
      this.setState({
        bottom: 0,
      });
    }
  };

  render() {
    const { children } = this.props;
    const matchCart = matchPath(window.location.pathname, {
      path: getPath('cart'),
    });
    const { bottom } = this.state;
    return (
      <WithStickyStyle bottom={bottom} id="sticky-block">
        {matchCart && (
          <div className="cart-footer-notice">
            <span>
              {`
                  Photos non contractuelles
                  , Prix HT (Hors frais de livraison) 
                  , Poids et prix peuvent varier selon arrivage et découpe.
                `}
            </span>
          </div>
        )}
        {children}
      </WithStickyStyle>
    );
  }
}

Sticky.propTypes = {
  children: PropTypes.any,
  isLoadingNewCatalog: PropTypes.bool,
  isLoadingPromotionsCatalog: PropTypes.bool,
};
const mapStateToProps = (state) => {
  const {
    catalog: { collections: collectionsCatalog },
    platform,
  } = state;
  const isNewCollection = collectionsCatalog[0] || [];
  const hasPromotionCollection = collectionsCatalog[1] || [];
  const { isLoading: isLoadingNewCatalog } = isNewCollection || true;
  const { isLoading: isLoadingPromotionsCatalog } = hasPromotionCollection || true;
  return {
    isLoadingPromotionsCatalog,
    isLoadingNewCatalog,
    platform,
  };
};

export default connect(mapStateToProps)(Sticky);
