import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { HiPencilAlt } from '@react-icons/all-files/hi/HiPencilAlt';
import Aggrid from 'components/AggridV2/Aggrid';
import { articleActions } from 'actions/Article';
import { selectCurrentPlatformId } from 'selectors/platform';
import { selectArticleData } from 'selectors/article';
import { imageCellRenderer } from 'components/Aggrid/cellRenderers';
import { ArticleListWrapper } from 'pages/Article/ArticleList.style';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { articleGroupActions, articleGroupItemActions, modalActions } from 'actions';
import { selectArticleGroupData } from 'selectors/articleGroup';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { selectArticleGroupItemData } from 'selectors/articleGroupItem';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import columnDefinitions from './columnDefinitions';

let selectedGroupVar = null;
let selectedGroupProductIdListVar = [];

const ArticleList = ({
  fetchAllArticles,
  fetchAllGroups,
  currentPlatformId,
  articleList,
  articleGroupList,
  openAddGroupModal,
  updateArticleGroup,
  articleGroupItemList,
  addGroupItems,
  fetchArticleGroupItemsFromGroup,
  deleteArticleGroupItems,
  pushToast,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAddGroupList, setSelectedAddGroupList] = useState([]);
  const [selectedUpdateGroupId, setSelectedUpdateGroupId] = useState(null);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedGroupProductIdList, setSelectedGroupProductIdList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchAllArticles(currentPlatformId);
    fetchAllGroups(currentPlatformId);
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      fetchArticleGroupItemsFromGroup(selectedGroup);
      setSelectedGroupProductIdList([]);
      gridApi.deselectAll();
    } else if (gridApi) {
      gridApi.hideOverlay();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (articleGroupItemList) {
      const newState = articleGroupItemList.map((articleGroupItem) => articleGroupItem.product.id);
      setSelectedGroupProductIdList(newState);
      selectedGroupProductIdListVar = newState;
    }
  }, [articleGroupItemList]);

  useEffect(() => {
    if (gridApi) {
      gridApi.onFilterChanged();
      if (selectedGroup && !selectedGroupProductIdList.length) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [selectedGroupProductIdList]);

  const defaultColDef = useMemo(() => ({ resizable: true }), []);

  const statusCellRenderer = ({ value }) => {
    if (Number(value) === 1) {
      return (
        <div className="article-list__status">
          <div className="article-list__status__active">Actif</div>
        </div>
      );
    }

    return (
      <div className="article-list__status">
        <div className="article-list__status__inactive">Inactif</div>
      </div>
    );
  };

  const stockCellRenderer = ({ value }) => {
    if (Number(value) > 0) {
      return (
        <>
          <AiFillCheckCircle style={{ fill: '#35e687' }} /> {value}
        </>
      );
    }

    return (
      <>
        <AiFillCloseCircle style={{ fill: '#f84746' }} />
        {value}
      </>
    );
  };

  const onSelectionChanged = useCallback((event) => {
    setSelectedProductList(event.api.getSelectedRows());
  }, []);

  const handleGroupEditKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      if (event.target.value !== event.target.defaultValue) {
        if (!event.target.value.match('^[a-zA-Z0-9_ -]{3,25}$')) {
          return pushToast(
            toastConstants.TYPE_ERROR,
            "Caractères alphanumériques, '-' et '_'  uniquement, entre 3 et 25 caractères"
          );
        }
        updateArticleGroup({ id: selectedUpdateGroupId, name: event.target.value }, () => {
          pushToast(toastConstants.TYPE_SUCCESS, 'Groupe modifié avec succès');
        });
      }

      return setSelectedUpdateGroupId(null);
    }
    if (event.key === 'Escape') {
      return setSelectedUpdateGroupId(null);
    }

    return null;
  };

  const handleAddGroupCheckboxClick = (articleGroupId) => {
    if (selectedAddGroupList.includes(articleGroupId)) {
      return setSelectedAddGroupList(
        selectedAddGroupList.filter((groupList) => !(groupList === articleGroupId))
      );
    }

    return setSelectedAddGroupList([...selectedAddGroupList, articleGroupId]);
  };

  const handleAddGroupItems = () => {
    const selectedProductIdList = selectedProductList.map(
      (selectedProduct) => selectedProduct.product_id
    );
    const parsedGroupItems = {};
    selectedAddGroupList.forEach((articleGroupId) => {
      parsedGroupItems[String(articleGroupId)] = selectedProductIdList;
    });

    if (Object.entries(parsedGroupItems).length) {
      addGroupItems(parsedGroupItems, () => {
        gridApi.deselectAll();
        pushToast(toastConstants.TYPE_SUCCESS, 'Produit(s) ajouté(s) au(x) groupe(s) avec succès');
      });
      setIsDropdownOpen(false);
      setSelectedAddGroupList([]);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const externalFilterChanged = (newValue) => {
    if (gridApi) {
      setSelectedGroup(newValue);
      selectedGroupVar = newValue;
      if (!newValue) {
        gridApi.onFilterChanged();
      }
    }
  };

  const isExternalFilterPresent = () => !!selectedGroupVar;

  const doesExternalFilterPass = ({ data }) =>
    selectedGroupProductIdListVar.includes(Number(data.product_id));

  return (
    <ArticleListWrapper isEditingGroup={!!selectedUpdateGroupId}>
      <div className="article-list__group-list__container">
        <div className="article-list__group-list__title">
          <span>Groupes</span>
          <a onClick={openAddGroupModal}>+</a>
        </div>
        <ul className="article-list__group-list">
          {articleGroupList &&
            articleGroupList.map((articleGroup, index) => (
              <li>
                {selectedUpdateGroupId !== articleGroup.id && (
                  <>
                    <span>
                      <label
                        className={
                          selectedGroup &&
                          !(selectedGroup === articleGroup.id) &&
                          'article-list__group-list--inactive'
                        }
                        title={articleGroup.name}
                        onClick={() => {
                          if (!selectedUpdateGroupId) {
                            externalFilterChanged(
                              selectedGroup === articleGroup.id ? null : articleGroup.id
                            );
                          }
                        }}
                      >
                        {articleGroup.name}
                      </label>
                    </span>
                    <span>
                      {!selectedUpdateGroupId && (
                        <HiPencilAlt
                          onClick={() => {
                            setSelectedUpdateGroupId(articleGroup.id);
                          }}
                        />
                      )}
                    </span>
                  </>
                )}
                {selectedUpdateGroupId === articleGroup.id && (
                  <div className="article-list__group-list__input-container">
                    <input
                      type="text"
                      defaultValue={articleGroup.name}
                      onKeyPress={handleGroupEditKeyPress}
                      /* eslint-disable-next-line jsx-a11y/no-autofocus */
                      autoFocus={!!selectedUpdateGroupId}
                    />
                    <span
                      onClick={() => {
                        setSelectedUpdateGroupId(null);
                      }}
                    >
                      X
                    </span>
                  </div>
                )}
              </li>
            ))}
          {!articleGroupList.length && <li>Pas de groupe</li>}
        </ul>
      </div>
      <div className="article-list__aggrid-container">
        <div className="article-list__title">
          {(selectedGroup && articleGroupList.find((group) => group.id === selectedGroup).name) ||
            'Liste des produits'}
        </div>
        <div className="article-list__button-container">
          {!selectedProductList || !selectedProductList.length ? (
            <ButtonPrimary disabled>AJOUTER au groupe</ButtonPrimary>
          ) : (
            <Dropdown
              headerTitle="AJOUTER au groupe"
              listOpen={isDropdownOpen}
              toggleDropDown={setIsDropdownOpen}
              defaultIcon={false}
            >
              <div className="dd-list">
                <ul>
                  {articleGroupList && (
                    <>
                      {articleGroupList.map((articleGroup, index) => (
                        <li>
                          <input
                            id={`checkbox-add-${index}`}
                            type="checkbox"
                            style={{ appearance: 'auto' }}
                            onChange={() => {
                              handleAddGroupCheckboxClick(articleGroup.id);
                            }}
                            checked={selectedAddGroupList.includes(articleGroup.id)}
                          />{' '}
                          <label htmlFor={`checkbox-add-${index}`}>{articleGroup.name}</label>
                        </li>
                      ))}
                      <li>
                        <ButtonPrimary
                          disabled={!selectedAddGroupList.length || !selectedProductList.length}
                          onClick={handleAddGroupItems}
                        >
                          Appliquer
                        </ButtonPrimary>
                      </li>
                    </>
                  )}
                  {!articleGroupList.length && <li>Pas de groupe</li>}
                </ul>
              </div>
            </Dropdown>
          )}
          {!!(selectedGroup && selectedProductList.length) && (
            <ButtonPrimary
              disabled={!selectedGroup || !selectedProductList.length}
              onClick={() => {
                deleteArticleGroupItems(
                  selectedGroup,
                  selectedProductList.map((product) => product.product_id),
                  () => {
                    gridApi.deselectAll();
                    pushToast(
                      toastConstants.TYPE_SUCCESS,
                      'Produit(s) supprimé(s) du groupe avec succès'
                    );
                  }
                );
              }}
            >
              RETIRER
            </ButtonPrimary>
          )}
        </div>
        <Aggrid
          defaultColDef={defaultColDef}
          columnDefs={columnDefinitions}
          rowData={articleList.length ? articleList : null}
          frameworkComponents={{ imageCellRenderer, statusCellRenderer, stockCellRenderer }}
          rowSelection="multiple"
          onGridReadyCallback={onGridReady}
          onSelectionChanged={onSelectionChanged}
          isExternalFilterPresent={isExternalFilterPresent}
          externalFilterChanged={externalFilterChanged}
          doesExternalFilterPass={doesExternalFilterPass}
        />
      </div>
    </ArticleListWrapper>
  );
};

const mapStateToProps = (state) => ({
  currentPlatformId: selectCurrentPlatformId(state),
  articleList: selectArticleData(state),
  articleGroupList: selectArticleGroupData(state),
  articleGroupItemList: selectArticleGroupItemData(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllArticles: (platformId) => dispatch(articleActions.fetchAllArticles(platformId, 20000)),
  fetchAllGroups: (platformId) =>
    dispatch(articleGroupActions.fetchAllArticleGroups(platformId, 20000)),
  updateArticleGroup: (data, successCallback) =>
    dispatch(articleGroupActions.updateArticleGroup(data, successCallback)),
  addGroupItems: (data, successCallback) =>
    dispatch(articleGroupItemActions.addArticleGroupItems(data, successCallback)),
  fetchArticleGroupItemsFromGroup: (articleGroupId) =>
    dispatch(articleGroupItemActions.fetchArticleGroupItems({ productGroup: articleGroupId })),
  deleteArticleGroupItems: (articleGroupId, articleGroupItemProductIdList, successCallback) =>
    dispatch(
      articleGroupItemActions.deleteArticleGroupItems(
        articleGroupId,
        articleGroupItemProductIdList,
        successCallback
      )
    ),
  openAddGroupModal: () =>
    dispatch(
      modalActions.open('addGroup', {
        modalHeader: '',
      })
    ),
  pushToast: (type, message, title = '') =>
    dispatch(toastActions.addToast({ type, message, title, duration: 1 })),
});

ArticleList.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
