import styled from 'styled-components';

const CreditItemWrapper = styled.div`
  .credit-item__quantity,
  .credit-item__raison,
  .credit-item__comment {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px;
    vertical-align: top;
    &-label {
      margin-right: 20px;
      color: #01366b;
      &:after {
        content: ':';
        display: ${({ validation }) => (validation === 'true' ? 'inline-block' : 'none')};
      }
    }
  }
  .credit-item__quantity {
    width: 20%;
  }
  .quantite-icon {
    .icon-add-circle {
      color: red;
      &:after {
        color: ${({ maxQuantity }) => (maxQuantity === true ? 'gray' : '')};
        cursor: ${({ maxQuantity }) => maxQuantity === true && 'no-drop'};
      }
    }
  }

  .credit-item__raison,
  .credit-item__comment {
    width: 35%;
  }

  .credit-item__comment {
    display: ${({ validation }) => (validation === 'true' ? 'block' : 'inline-block')};
    &-label {
      display: block;
    }
  }
  .credit-item__button {
    &-icon {
      font-size: 12px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-top: 0;
    }
  }

  .quantite-icon {
    i {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  textarea {
    height: 113px;
    width: 100%;
    border: 1px solid #01366b;
    background: transparent;
    outline: none;
  }

  select {
    height: 30px;
    width: 75%;
    border: 1px solid #01366b;
    background: transparent;
  }

  .credit-item__checkbox {
    position: relative;
  }
  .container {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .error {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 15px;
    color: red;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid rgba(120, 120, 120, 0.54);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid #01366b;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg) translateX(-3px);
    -ms-transform: rotate(45deg) translateX(-3px);
    transform: rotate(45deg) translateX(-3px);
  }

  .credit-item__checked-label {
    display: inline-block;
    margin-left: 30px;
    margin-top: 10px;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: 1024px) {
    .credit-item__quantity {
      width: 30%;
    }
    .credit-item__raison {
      width: 45%;
      margin-right: 0;
    }
    .credit-item__comment {
      margin-top: 18px;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .credit-item__raison {
      width: 67%;
      select {
        width: 85%;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .credit-item__quantity,
    .credit-item__raison,
    .credit-item__comment {
      width: 100%;
    }
    .credit-item__raison {
      select {
        width: 74%;
        margin-top: 18px;
      }
    }
  }
`;

export default CreditItemWrapper;
