import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import Configuration from './Configuration';

const ConfigurationLayout = ({ match }) => <Layout component={Configuration} match={match} />;

ConfigurationLayout.propTypes = {
  match: PropTypes.object,
};

export default ConfigurationLayout;
