import { loadingConstants } from '../constants';

export const loadingActions = {
  started,
  loaded,
  updateUserType,
};

function started(name) {
  return {
    type: loadingConstants.UPDATE_STATUS,
    name,
    status: loadingConstants.STATUS_STARTED,
  };
}

function loaded(name, userType = null) {
  return {
    type: loadingConstants.UPDATE_STATUS,
    name,
    userType,
    status: loadingConstants.STATUS_LOADED,
  };
}

function updateUserType(userType) {
  return {
    type: loadingConstants.UPDATE_USER_TYPE,
    userType,
  };
}
