export const recurrentCartConstants = {
  FETCH_REQUEST: 'RECURRENT_CART_FETCH_REQUEST',
  FETCH_SUCCESS: 'RECURRENT_CART_FETCH_SUCCESS',
  FETCH_FAILURE: 'RECURRENT_CART_FETCH_FAILURE',

  FETCH_ONE_REQUEST: 'RECURRENT_CART_FETCH_REQUEST',
  FETCH_ONE_SUCCESS: 'RECURRENT_CART_FETCH_SUCCESS',
  FETCH_ONE_FAILURE: 'RECURRENT_CART_FETCH_FAILURE',

  CREATE_REQUEST: 'RECURRENT_CART_CREATE_REQUEST',
  CREATE_SUCCESS: 'RECURRENT_CART_CREATE_SUCCESS',
  CREATE_FAILURE: 'RECURRENT_CART_CREATE_FAILURE',

  UPDATE_REQUEST: 'RECURRENT_CART_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECURRENT_CART_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECURRENT_CART_UPDATE_FAILURE',

  DELETE_REQUEST: 'RECURRENT_CART_DELETE_REQUEST',
  DELETE_SUCCESS: 'RECURRENT_CART_DELETE_SUCCESS',
  DELETE_FAILURE: 'RECURRENT_CART_DELETE_FAILURE',

  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BI-WEEKLY',
  MONTHLY: 'MONTHLY',

  DAYS: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'],
};
