/* eslint-disable no-case-declarations */
import { userService } from 'services';
import { store, history, getPath, getUserPlatforms, getEffectivePlatform } from 'helpers';
import { selectCurrentPlatform } from 'selectors/platform';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import { userConstants, platformConstants, appConstants } from '../constants';
import { platformActions, loadingActions } from '.';
import { setCookie } from '../helpers/cookies';

export const userActions = {
  login,
  loginAs,
  loginRefresh,
  recoverPassword,
  changePassword,
  fetchInformation,
  updatePassword,
  logoutAs,
  logout,
  toggleDashboard,
  updateInfo,
};

function login(username, password) {
  const { cookie } = document;
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        if (user.message && !user.id) {
          dispatch(
            failure('Votre accès à commande.com a été bloqué. Merci de contacter votre commercial.')
          );
        } else {
          dispatch(success(user));
          dispatch(fetchInformation(true));
        }
      },
      () => {
        dispatch(failure('Votre identifiant ou mot de passe est erroné'));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user, cookie };
  }
  function failure(message) {
    return { type: userConstants.LOGIN_FAILURE, message };
  }
}

function loginRefresh(token) {
  const { cookie } = document;
  return (dispatch) => {
    userService.loginWithRefreshToken(token).then(
      (user) => {
        if (!user || !user.access_token) {
          dispatch(userActions.logout());
          history.push(getPath('login'));
          dispatch(failure('Votre session a expiré, veuillez vous reconnecter!'));
        } else {
          dispatch(success(user));
          dispatch(fetchInformation(true));
        }
      },
      () => {
        dispatch(failure('Votre session a expiré, veuillez vous reconnecter!'));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user, cookie };
  }
  function failure(message) {
    return { type: userConstants.LOGIN_FAILURE, message };
  }
}

function recoverPassword(username) {
  return (dispatch) => {
    dispatch(request(username));

    userService.recoverPassword(username).then(
      () => {
        dispatch(success());
      },
      () => {
        dispatch(failure('L’identifiant saisi est erroné.'));
      }
    );
  };

  function request(user) {
    return { type: userConstants.RECOVER_PASSWORD_REQUEST, username: user };
  }
  function success() {
    return { type: userConstants.RECOVER_PASSWORD_SUCCESS };
  }
  function failure(message) {
    return { type: userConstants.RECOVER_PASSWORD_FAILURE, message };
  }
}

function changePassword(newPassword, newPasswordConfirmation, confirmationToken, isFirstLogin) {
  return (dispatch) => {
    dispatch(request(newPassword));

    userService
      .changePassword(newPassword, newPasswordConfirmation, confirmationToken, isFirstLogin)
      .then(
        () => {
          dispatch(success());
        },
        () => {
          dispatch(
            failure(
              "Le changement de votre mot de passe n'a pas pu être pris en compte. Veuillez recliquer sur le lien disponible dans l'email reçu."
            )
          );
        }
      );
  };

  function request(password) {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST, newPassword: password };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure(message) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, message };
  }
}

function updateInfo(user, withToast = null) {
  return (dispatch) => {
    dispatch(request());

    userService.updateInfo(user).then(
      (newUser) => {
        dispatch(success(newUser));
        if (withToast) {
          dispatch(
            toastActions.addToast({
              type: toastConstants.TYPE_SUCCESS,
              message: withToast.success,
            })
          );
        }
      },
      (error) => {
        dispatch(failure(error));
        if (withToast) {
          dispatch(
            toastActions.addToast({
              type: 'error',
              message: withToast.error,
            })
          );
        }
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success(newUser) {
    return { type: userConstants.UPDATE_SUCCESS, newUser };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function toggleDashboard() {
  return (dispatch) => {
    userService.toggleDashboard().then(
      (user) => dispatch(success(user)),
      () => dispatch(failure('set dashboard version failed'))
    );
  };

  function success(user) {
    return { type: userConstants.CHANGE_DASHBOARD_SUCCESS, user };
  }
  function failure(message) {
    return { type: userConstants.CHANGE_DASHBOARD_FAILURE, message };
  }
}

function fetchInformation(fromLogin = false) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.started('user'));

    userService.fetchInformation().then(
      (user) => {
        dispatch(success(user));
        dispatch(loadingActions.loaded('user', user.type));
        const { type, client, roles } = user;

        const localStorageUser = JSON.parse(localStorage.getItem('user'));
        localStorage.setItem('user', JSON.stringify({ ...localStorageUser, roles }));

        let shouldRedirectAfter = fromLogin;
        // filter the platforms
        const platforms = getUserPlatforms(user);
        // check if there is already a localStorage platform for user
        // If not, and if the user has more than one platform redirect to landing
        const effectivePlatform = getEffectivePlatform(user);

        if (type === userConstants.TYPE_CUSTOMER) {
          if (client.client_status.length) {
            if (!effectivePlatform && shouldRedirectAfter) {
              platformActions.redirectToLanding();
              return;
            }

            if (localStorageUser && localStorageUser.loggedAs) {
              dispatch({ type: platformConstants.FETCH_SUCCESS, platforms: [effectivePlatform] });
            } else {
              dispatch({ type: platformConstants.FETCH_SUCCESS, platforms });
            }
            dispatch(platformActions.setPlatform(effectivePlatform.id));
          } else {
            shouldRedirectAfter = false;
            dispatch({
              type: userConstants.LOGIN_FAILURE,
              message: "Votre compte n'est pas activé, veuillez contacter votre commercial.",
            });
          }
        } else if (type === userConstants.TYPE_SUPER_ADMIN) {
          dispatch({ type: platformConstants.FETCH_SUCCESS, platforms: [...platforms] });
          dispatch({
            type: platformConstants.UPDATE_ACTIVE,
            platformId: effectivePlatform.id,
          });
        } else {
          dispatch({ type: platformConstants.FETCH_SUCCESS, platforms });
          dispatch({ type: platformConstants.UPDATE_ACTIVE, platformId: effectivePlatform.id });
        }

        dispatch(loadingActions.loaded('platform'));

        // redirection
        // i'm pretty sure this is dead code
        if (shouldRedirectAfter) {
          switch (type) {
            case userConstants.TYPE_SUPER_ADMIN:
              history.push(getPath('configuration'));
              return;
            case userConstants.TYPE_CUSTOMER:
              history.push(userService.getHomepagePath());
              return;
            default:
              history.push(getPath('clientList'));
              break;
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FETCH_INFORMATION_REQUEST };
  }
  function success(user) {
    return { type: userConstants.FETCH_INFORMATION_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FETCH_INFORMATION_FAILURE, error };
  }
}

function updatePassword(oldPassword, newPassword, newPasswordConfirmation) {
  return (dispatch) => {
    dispatch(request(newPassword));

    userService.updatePassword(oldPassword, newPassword, newPasswordConfirmation).then(
      () => {
        const SUCCESS_MESSAGE = 'Votre nouveau mot de passe a bien été enregistré.';
        dispatch(success(SUCCESS_MESSAGE));
      },
      () => {
        dispatch(failure('Ancien mot de passe erroné.'));
      }
    );
  };

  function request(password) {
    return { type: userConstants.UPDATE_PASSWORD_REQUEST, newPassword: password };
  }
  function success(message) {
    return { type: userConstants.UPDATE_PASSWORD_SUCCESS, message };
  }
  function failure(message) {
    return { type: userConstants.UPDATE_PASSWORD_FAILURE, message };
  }
}

function loginAs(username) {
  return (dispatch) => {
    userService.loginAs(username);
    dispatch({ type: appConstants.RESET });
    dispatch({ type: userConstants.LOGIN_AS, username });
    dispatch(fetchInformation('clientList'));
  };
}

function logoutAs() {
  return (dispatch) => {
    userService.logoutAs();
    dispatch({ type: appConstants.RESET });
    dispatch({ type: userConstants.LOGOUT_AS });
    dispatch(fetchInformation(getPath('clientList')));
  };
}

function logout() {
  return (dispatch) => {
    userService.logout();
    setCookie('mobileApp', false, -1);
    dispatch({ type: userConstants.LOGOUT });
    dispatch({ type: appConstants.RESET });
  };
}
