export const termsConstants = {
  FETCH_REQUEST: 'TERMS_FETCH_REQUEST',
  FETCH_SUCCESS: 'TERMS_FETCH_SUCCESS',
  FETCH_FAILURE: 'TERMS_FETCH_FAILURE',

  FETCH_COUNT_REQUEST: 'TERMS_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'TERMS_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'TERMS_COUNT_FETCH_FAILURE',

  UPDATE_REQUEST: 'TERMS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TERMS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TERMS_UPDATE_FAILURE',

  DELETE_REQUEST: 'TERMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'TERMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'TERMS_DELETE_FAILURE',

  CREATE_REQUEST: 'TERMS_CREATE_REQUEST',
  CREATE_SUCCESS: 'TERMS_CREATE_SUCCESS',
  CREATE_FAILURE: 'TERMS_CREATE_FAILURE',

  STATUS_ACTIVE: 'ACTIVE',
  STATUS_INACTIVE: 'INACTIVE',

  TERM_STORAGE_URL: 'https://storage.cloud.google.com/',
};
