import { getDegressivePrice, getCartItem } from 'helpers';

export function formatPriceWithCurrency(price) {
  return `${Number(price).toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}€ HT`;
}

// Add @DND : TEMP - To be mutualized with getDiscountedPrice method
export function getCommercialDiscountedPrice(product) {
  const {
    degressive_prices: degressivePrices,
    has_promotion: hasPromotion,
    price: productPrice,
    quantity,
    use_degressive_prices: hasDegressivePrices,
  } = product || {};

  const { display_as_promotion: displayAsPromotion, price: promotionPrice } =
    product.promotion || {};

  let discountedPrice = 0;
  const degressivePrice = getDegressivePrice(product);

  if (
    hasDegressivePrices &&
    degressivePrices !== undefined &&
    degressivePrices.length > 0 &&
    quantity !== 0
  ) {
    discountedPrice =
      hasPromotion && displayAsPromotion && promotionPrice !== 0
        ? Math.min(promotionPrice, degressivePrice)
        : degressivePrice;
  } else {
    discountedPrice =
      hasPromotion && displayAsPromotion && promotionPrice !== 0 ? promotionPrice : productPrice;
  }

  return Number(discountedPrice);
}

export function getDiscountedPrice(product, cartItem) {
  const productItem = 'item' in product ? product.item : product;

  const {
    degressive_prices: degressivePrices,
    has_promotion: hasPromotion,
    price: productPrice,
    use_degressive_prices: hasDegressivePrices,
  } = productItem || {};

  const { display_as_promotion: displayAsPromotion, price: promotionPrice } =
    productItem.promotion || {};

  let discountedPrice = 0;
  const { quantity } = cartItem;
  const degressivePrice = getDegressivePrice(cartItem);

  if (
    hasDegressivePrices &&
    degressivePrices !== undefined &&
    degressivePrices.length > 0 &&
    quantity !== 0
  ) {
    discountedPrice =
      hasPromotion && displayAsPromotion && promotionPrice !== 0
        ? Math.min(promotionPrice, degressivePrice)
        : degressivePrice;
  } else {
    discountedPrice =
      hasPromotion && displayAsPromotion && promotionPrice !== 0 ? promotionPrice : productPrice;
  }

  return Number(discountedPrice);
}
