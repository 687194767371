import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getArraysIntersection } from 'helpers/Utils';
import { rolesConstants } from 'constants/Roles';

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const handleRender = (props) => {
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    if (
      allowedRoles === null ||
      (localStorageUser &&
        localStorageUser.roles &&
        (localStorageUser.roles.includes(rolesConstants.ROLE_SUPER_ADMIN) ||
          getArraysIntersection(allowedRoles, localStorageUser.roles).length))
    ) {
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('user') ? (
          handleRender(props)
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
