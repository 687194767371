/* eslint-disable no-nested-ternary */
import {
  catalogActions,
  searchActions,
  cartActions,
  cartItemsActions,
  orderActions,
  orderItemsActions,
  clientActions,
  messageActions,
  termsActions,
  commercialActions,
  modalActions,
  assortmentActions,
  creditActions,
  invoiceItemsActions,
  productActions,
} from 'actions';
import { getTableCollectionsByType, objectsAreEqual } from 'helpers';
import { tableConstants } from 'pages/../constants';
import { tableTypes } from './tableTypes';

const {
  TABLE_TYPE_CATALOG,
  TABLE_TYPE_ORDER,
  TABLE_TYPE_ORDER_ITEMS,
  TABLE_TYPE_CART_ITEMS,
  TABLE_TYPE_CART,
  TABLE_TYPE_SEARCH,
  TABLE_TYPE_SEARCH_CLIENT,
  TABLE_TYPE_CLIENT,
  TABLE_TYPE_MESSAGE,
  TABLE_TYPE_TERMS,
  TABLE_TYPE_COMMERCIAL,
  TABLE_TYPE_ASSORTMENT,
  TABLE_TYPE_CREDIT_ITEMS,
  TABLE_TYPE_INVOICE_ITEMS,
} = tableTypes;

const mutableTableTypes = [TABLE_TYPE_COMMERCIAL, TABLE_TYPE_TERMS, TABLE_TYPE_MESSAGE];

const { DIRECTION_REPLACE } = tableConstants;

export const mapStateToProps = (state, ownProps) => {
  const { platform, credit, productCard } = state;

  const { filter, type } = ownProps;

  const stateCollections = getTableCollectionsByType(type, state);

  if (!stateCollections) {
    return {};
  }

  const { collections, collectionsCount } = stateCollections;
  const collection = collections.find((c) => objectsAreEqual(filter, c.filter)) || {};
  const collectionCount = collectionsCount.find((c) => objectsAreEqual(filter, c.filter)) || {};

  let changeObject = {
    isLoading: false,
    itemsCount: 0,
    itemIds: '',
    totalRowsNumber: 0,
  };

  const { isOpen } = credit;

  if (collection) {
    const { items, isLoading } = collection;
    const { totalRowsNumber } = collectionCount || 0;
    changeObject = {
      isLoading,
      isOpen,
      itemsCount: items ? items.length : 0,
      checkData: items
        ? mutableTableTypes.includes(type)
          ? items.map((item) =>
              Object.values(item)
                .map((value) => value)
                .join('-')
            )
          : items.map((item) => `${item.id || 0}-${!!item.enabled}-${item.status || ''}`).join('-')
        : '',
      totalRowsNumber,
    };
  }

  return {
    changeObject,
    collectionCount,
    collection,
    platform,
    credit,
    currentProduct: productCard.currentProduct,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchItems: (props, page, direction = DIRECTION_REPLACE, initialRequest = false) => {
    const { type, filter, rowsByPage, orderBy } = props;
    if (type === TABLE_TYPE_CATALOG) {
      const getManufacturers = !(filter && filter.manufacturer) && initialRequest;
      dispatch(
        catalogActions.fetchItems(filter, page, rowsByPage, direction, getManufacturers, orderBy)
      );
      dispatch(productActions.getFavoriteProducts());
    } else if (type === TABLE_TYPE_ORDER) {
      dispatch(orderActions.fetchItems(filter, page, rowsByPage, direction));
    } else if (type === TABLE_TYPE_ORDER_ITEMS) {
      dispatch(orderItemsActions.fetchItems(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_INVOICE_ITEMS) {
      dispatch(invoiceItemsActions.fetchItems(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_CLIENT) {
      dispatch(clientActions.fetchClients(filter, page, rowsByPage, direction));
    } else if (type === TABLE_TYPE_CART) {
      dispatch(cartActions.fetchItems(filter, page, rowsByPage, direction));
    } else if (type === TABLE_TYPE_SEARCH) {
      const { searchValue, showManufacturers } = props;
      const getManufacturers = !(filter && filter.manufacturer) && initialRequest;
      dispatch(
        searchActions.search(
          searchValue,
          filter,
          showManufacturers || getManufacturers,
          false,
          page,
          rowsByPage,
          false,
          direction
        )
      );
      dispatch(productActions.getFavoriteProducts());
    } else if (type === TABLE_TYPE_SEARCH_CLIENT) {
      const { searchValue } = props;
      dispatch(
        searchActions.search(searchValue, filter, false, true, page, rowsByPage, false, direction)
      );
    } else if (type === TABLE_TYPE_CART_ITEMS) {
      const { cart } = filter;
      if (cart) {
        dispatch(cartItemsActions.fetchItems(filter, page, rowsByPage, direction));
      }
    } else if (type === TABLE_TYPE_MESSAGE) {
      dispatch(messageActions.fetchMessages(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_TERMS) {
      dispatch(termsActions.fetchTerms(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_COMMERCIAL) {
      dispatch(commercialActions.fetchCommercials(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_ASSORTMENT) {
      dispatch(assortmentActions.fetchAssortments(filter, page, rowsByPage));
    } else if (type === TABLE_TYPE_CREDIT_ITEMS) {
      dispatch(creditActions.fetchItems(filter, page, rowsByPage, direction));
    }
  },
  fetchTotalRowsNumber: (props) => {
    const { type, filter } = props;
    if (type === TABLE_TYPE_CATALOG) {
      dispatch(catalogActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_ORDER) {
      dispatch(orderActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_ORDER_ITEMS) {
      dispatch(orderItemsActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_INVOICE_ITEMS) {
      dispatch(invoiceItemsActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_CLIENT) {
      dispatch(clientActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_CART) {
      dispatch(cartActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_CART_ITEMS) {
      dispatch(cartItemsActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_SEARCH) {
      const { searchValue } = props;
      dispatch(searchActions.searchTotalRowsNumber(searchValue, filter, false));
    } else if (type === TABLE_TYPE_SEARCH_CLIENT) {
      const { searchValue } = props;
      dispatch(searchActions.searchTotalRowsNumber(searchValue, filter, true));
    } else if (type === TABLE_TYPE_MESSAGE) {
      dispatch(messageActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_TERMS) {
      dispatch(termsActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_COMMERCIAL) {
      dispatch(commercialActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_ASSORTMENT) {
      dispatch(assortmentActions.fetchTotalRowsNumber(filter));
    } else if (type === TABLE_TYPE_CREDIT_ITEMS) {
      dispatch(creditActions.fetchTotalRowsNumber(filter));
    }
  },
  openUpdateCommercialModal: (commercial) =>
    dispatch(
      modalActions.open('updateCommercial', {
        modalHeader: 'Fiche Commercial',
        commercial,
        width: '400',
      })
    ),
});
