import { articleGroupItemConstants } from 'constants/ArticleGroupItem.constants';

const initState = {
  data: [],
  isLoading: false,
  error: null,
  selectedItem: null,
};

export function articleGroupItem(state = initState, action) {
  switch (action.type) {
    case articleGroupItemConstants.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupItemConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case articleGroupItemConstants.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupItemConstants.GET_FROM_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case articleGroupItemConstants.GET_FROM_ITEM_SUCCESS: {
      return {
        ...state,
        selectedItem: {
          groups: (action.data && action.data.map((groupId) => Number(groupId))) || [],
        },
        isLoading: false,
      };
    }
    case articleGroupItemConstants.GET_FROM_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupItemConstants.ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupItemConstants.ADD_SUCCESS:
      return {
        ...state,
        data: [...action.articleGroupItems, ...state.data],
        isLoading: false,
      };
    case articleGroupItemConstants.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupItemConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupItemConstants.DELETE_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data.filter(
            (productGroupItem) =>
              !(
                `${productGroupItem.product_group.id}` === `${action.articleGroupId}` &&
                action.articleGroupItemProductIdList.includes(`${productGroupItem.product.id}`)
              )
          ),
        ],
        isLoading: false,
      };
    case articleGroupItemConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
