import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalAlertMoq.style';

const ModalSaveConfirmation = ({ className, closeModal, validateMoq }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>Le minimum de commande n&apos;a pas été atteint</span>
      </div>
      <div className="actions">
        <div>
          <span>Souhaitez-vous néanmoins valider la commande ?</span>
        </div>
        <ButtonPrimary inverted onClick={() => closeModal()}>
          <span>Annuler</span>
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            closeModal();
            validateMoq();
          }}
        >
          <span>Valider</span>
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalSaveConfirmation.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  validateMoq: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(withStyle(ModalSaveConfirmation));
