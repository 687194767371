import styled, { keyframes } from 'styled-components';

const fadeInLeft = keyframes`
  from {
    transform: translateX(calc(-10px));
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeOutLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(calc(-10px));
    opacity: 0;
  }
`;

export const CategoryColumnWrapper = styled.ul`
  padding: 0;
  margin: 0;
  border-left: 1px solid #f5f5f5 !important;
  overflow: auto;
  list-style: none;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  &::-webkit-scrollbar-thumb {
    background: #01366b;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #0fa3f0;
  }
  li {
    white-space: nowrap;
  }
  animation: ${fadeInLeft} 0.2s linear;
`;
