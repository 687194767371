import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const platformService = {
  fetchPlatforms,
  updatePlatform,
};

function fetchPlatforms() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/platform`, requestOptions).then(handleResponse);
}

function updatePlatform(id, data) {
  const body = JSON.stringify({
    id,
    ...data,
  });
  const requestOptions = {
    method: 'PUT',
    headers: {
      ...authHeader(false),
      'content-type': 'application/json',
    },
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/platform/update`, requestOptions).then(handleResponse);
}
