import { assortmentService } from 'services';
import { history } from 'helpers';
import { assortmentConstants, categoryConstants } from '../constants';
import { modalActions } from '.';

export const assortmentActions = {
  fetchTotalRowsNumber,
  fetchAssortments,
  updateAssortment,
  createAssortment,
  deleteAssortment,
  uploadSelection,
  updateStore,
  resetStore,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    assortmentService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: assortmentConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: assortmentConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: assortmentConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchAssortments(filter, offset = 0, limit = 10, withDetail = false) {
  return (dispatch) => {
    dispatch(request());
    assortmentService.fetchAssortments(filter, offset, limit, withDetail).then(
      (items) => {
        const effectiveItems = withDetail ? [items] : items;
        dispatch(success({ items: effectiveItems }));
        if (withDetail) {
          dispatch(updateStore('name', items.name));
          dispatch(updateStore('date', items.date_end));
          dispatch(updateStore('checked', items.items_ids));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: assortmentConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: assortmentConstants.FETCH_SUCCESS, items, filter };
  }
  function failure(error) {
    return { type: assortmentConstants.FETCH_FAILURE, error };
  }
}

function updateAssortment(id, data, filter, redirectLink) {
  return (dispatch) => {
    dispatch(request({ id, ...data }));
    assortmentService.updateAssortment(id, data).then(
      (assortment) => {
        dispatch(success(assortment));
        if (redirectLink) {
          history.push(redirectLink);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(assortment) {
    return {
      type: assortmentConstants.UPDATE_REQUEST,
      assortment,
      data,
      filter: filter || { id },
    };
  }
  function success(assortment) {
    return { type: assortmentConstants.UPDATE_SUCCESS, assortment, filter: filter || { id } };
  }
  function failure(error) {
    return { type: assortmentConstants.UPDATE_FAILURE, error, filter: filter || { id } };
  }
}

function createAssortment(data, redirectLink) {
  return (dispatch) => {
    dispatch(request());
    assortmentService.createAssortment(data).then(
      (assortment) => {
        dispatch(success(assortment));
        if (redirectLink) {
          history.push(redirectLink);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: assortmentConstants.CREATE_REQUEST };
  }
  function success(assortment) {
    return {
      type: assortmentConstants.CREATE_SUCCESS,
      assortment,
      filter: { client: Number(data.client.id), status: categoryConstants.STATUS_SELECTION },
    };
  }
  function failure(error) {
    return { type: assortmentConstants.CREATE_FAILURE, error };
  }
}

function uploadSelection(data, redirectLink) {
  return (dispatch) => {
    dispatch(request());
    assortmentService.uploadSelection(data).then(
      (selection) => {
        dispatch(success(selection));
        dispatch(modalActions.close());
        if (redirectLink) {
          history.push(redirectLink.replace(':assortmentId', selection.groupItem.id));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: assortmentConstants.UPLOAD_REQUEST };
  }
  function success(selection) {
    return { type: assortmentConstants.UPLOAD_SUCCESS, selection };
  }
  function failure(error) {
    return { type: assortmentConstants.UPLOAD_FAILURE, error };
  }
}

function deleteAssortment(assortmentId) {
  return (dispatch) => {
    dispatch(request());
    assortmentService.deleteAssortment(assortmentId).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: assortmentConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: assortmentConstants.DELETE_SUCCESS, assortmentId };
  }
  function failure(error) {
    return { type: assortmentConstants.DELETE_FAILURE, error };
  }
}

function updateStore(key, value) {
  return (dispatch) => {
    dispatch({ type: assortmentConstants.UPDATE_STORE, key, value });
  };
}

function resetStore() {
  return (dispatch) => {
    dispatch({ type: assortmentConstants.RESET_STORE });
  };
}
