export const prospectConstants = {
  FETCH_REQUEST: 'PROSPECT_FETCH_REQUEST',
  FETCH_SUCCESS: 'PROSPECT_FETCH_SUCCESS',
  FETCH_FAILURE: 'PROSPECT_FETCH_FAILURE',

  CREATE_REQUEST: 'PROSPECT_CREATE_REQUEST',
  CREATE_SUCCESS: 'PROSPECT_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROSPECT_CREATE_FAILURE',

  UPDATE_REQUEST: 'PROSPECT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROSPECT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROSPECT_UPDATE_FAILURE',
};
