import { ocrOrderPdfConstants } from 'constants/OcrOrderPdf.constants';

const initState = {
  data: [],
  selectedJobResult: null,
  isLoading: false,
  error: null,
};

export function ocrOrderPdf(state = initState, action) {
  switch (action.type) {
    case ocrOrderPdfConstants.GET_ALL_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ocrOrderPdfConstants.GET_ALL_JOB_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case ocrOrderPdfConstants.GET_ALL_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ocrOrderPdfConstants.ADD_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ocrOrderPdfConstants.ADD_JOB_SUCCESS:
      return {
        ...state,
        data: [action.data, ...state.data],
        isLoading: false,
      };
    case ocrOrderPdfConstants.UPDATE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ocrOrderPdfConstants.UPDATE_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ocrOrderPdfConstants.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        data: state.data.map((job) => {
          if (job.id === action.data.id) {
            return action.data;
          }

          return job;
        }),
        isLoading: false,
      };
    case ocrOrderPdfConstants.ADD_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ocrOrderPdfConstants.DELETE_JOB_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ocrOrderPdfConstants.DELETE_JOB_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((job) => job.id !== action.jobId),
        isLoading: false,
      };
    }
    case ocrOrderPdfConstants.DELETE_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ocrOrderPdfConstants.GET_JOB_RESULT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ocrOrderPdfConstants.GET_JOB_RESULT_SUCCESS:
      return {
        ...state,
        selectedJobResult: action.data,
        isLoading: false,
      };
    case ocrOrderPdfConstants.GET_JOB_RESULT_FAILURE:
      return {
        ...state,
        isLoading: false,
        selectedJobResult: null,
        error: action.error,
      };
    default:
      return state;
  }
}
