import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IoReload } from '@react-icons/all-files/io5/IoReload';
import { RiArrowDownSFill } from '@react-icons/all-files/ri/RiArrowDownSFill';
import InvoiceOrderListControl from 'components/InvoiceOrderListControl/InvoiceOrderListControl';
import { dateTimeToFormatedDate, getIconClassByType, getPath, history } from 'helpers';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import Icon from 'components/Icon/Icon';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import InvoiceBreadCrumb from 'pages/Invoice/InvoiceBreadcumb';
import { invoiceActions, orderActions } from 'actions';
import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';
import { selectInvoiceCollections, selectInvoiceCollectionsCount } from 'selectors/invoice';
import isEqual from 'lodash.isequal';
import { categoryConstants, invoiceConstants, userConstants } from '../../../constants';
import './InvoiceList.scss';

const InvoiceList = ({
  match,
  user,
  currentPlatformId,
  fetchInvoiceList,
  fetchInvoiceListTotalRowCount,
  invoiceCollections,
  invoiceCollectionsCount,
  downloadPdf,
  renewOrder,
}) => {
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [currentCollection, setCurrentCollection] = useState(null);
  const [invoiceTotalRowCount, setInvoiceTotalRowCount] = useState(null);

  const limit = 25;
  const { TYPE_CUSTOMER: USER_TYPE_CUSTOMER } = userConstants;

  let filter = {
    platform: currentPlatformId,
    or: [
      ['invoice.number', 'like', `%25${searchInput}%25`],
      ['o.number', 'like', `%25${searchInput}%25`],
    ],
  };

  if (user.type !== USER_TYPE_CUSTOMER) {
    filter = {
      ...filter,
      or: [
        ...filter.or,
        ['client.name', 'like', `%25${searchInput}%25`],
        ['client.extCode', 'like', `%25${searchInput}%25`],
      ],
    };
  }

  useEffect(() => {
    if (invoiceCollections) {
      const invoiceCollection = invoiceCollections.find((collection) =>
        isEqual(collection.filter, filter)
      );
      setCurrentCollection(invoiceCollection);
      if (
        invoiceCollection &&
        !invoiceCollection.isLoading &&
        !isEqual(invoiceCollection, currentCollection)
      ) {
        const newItems = invoiceCollection.items.map((item) => ({
          ...item,
          handleRowClick: () => redirectToInvoiceDetail(item.id),
        }));
        setItems([...items, ...newItems]);
      }
    }
  }, [invoiceCollections]);

  useEffect(() => {
    if (invoiceCollectionsCount) {
      const currentCount = invoiceCollectionsCount.find((count) => isEqual(count.filter, filter));
      if (currentCount && !currentCount.isLoading) {
        setInvoiceTotalRowCount(currentCount.totalRowsNumber);
      }
    }
  }, [invoiceCollectionsCount]);

  useEffect(() => {
    setOffset(0);
    setItems([]);
    fetchInvoiceListTotalRowCount(filter);
    fetchInvoiceList(filter, 0, limit);
  }, [searchInput]);

  // const { type: userType } = user !== {} && user;
  const { type: userType } = user;
  const columns = React.useMemo(() => [
    {
      id: 'validationDate',
      Header: 'Date de validation',
      accessor: (item) => item.date,
      Cell: ({ value }) => dateTimeToFormatedDate(value),
    },
    {
      id: 'InvoiceNumber',
      Header: 'N° de document',
      accessor: (item) => item.number,
    },
    {
      id: 'ClientCode',
      Header: 'Code client',
      isHidden: user.type === USER_TYPE_CUSTOMER,
      accessor: (item) => item.client.ext_code,
    },
    {
      id: 'ClientName',
      Header: 'Nom Client',
      isHidden: user.type === USER_TYPE_CUSTOMER,
      accessor: (item) => item.client.name,
    },
    {
      id: 'invoiceType',
      Header: 'Type',
      accessor: (item) => item.order_type,
      Cell: ({ value }) => (
        <>
          {value === invoiceConstants.TYPE_INVOICE && (
            <div className="invoice-list__type-invoice">Facture</div>
          )}
          {value === invoiceConstants.TYPE_CREDIT && (
            <div className="invoice-list__type-credit">Avoir</div>
          )}
        </>
      ),
    },
    {
      id: 'orderNumber',
      Header: 'Commande associée',
      accessor: (item) => (item.order && item.order.number) || '-',
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item.references,
      Cell: ({ value }) => (
        <div>
          <div>{value.uniqueItems} ref.</div>
          <div>{value.packages} colis</div>
        </div>
      ),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.total_price,
      Cell: ({ value }) => (
        <div className="invoice-list__total-container">{formatPrice(value || 0)} € HT</div>
      ),
    },
    {
      id: 'callToAction',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      className: 'invoice-list__action-cell',
      Cell: ({ value }) => (
        <div className="invoice-list__row-action__container">
          {value && (
            <>
              <div className="invoice-list__row-action__separator" />
              <div className="invoice-list__row-action__action-container">
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadPdf(value);
                  }}
                >
                  <i className="icon-fac" />
                </span>
                {value.order && user.type === USER_TYPE_CUSTOMER && (
                  <span
                    className="invoice-list__row-action__renew"
                    onClick={(event) => {
                      event.stopPropagation();
                      renewOrder(value.order.id);
                    }}
                  >
                    <IoReload />
                    Renouveler
                  </span>
                )}
              </div>
              <div className="invoice-list__row-action__separator" />
            </>
          )}
        </div>
      ),
    },
    {
      id: 'label',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="invoice-list__label-container">
          {Object.entries(value.totals_by_type).map((temperature) => (
            <i className={`${getIconClassByType(temperature[0])} temp-icon`} />
          ))}
          {value.order && value.order.is_preorder && <Icon content="\e941" />}
          {value.order && !value.order.is_preorder && <Icon content="\e94c" />}
          {isInvoiceHasCredit(value) && (
            <span className="invoice-list__expander" onClick={(event) => event.stopPropagation()}>
              {/* {row.isExpanded ? <RiArrowDownSFill /> : <RiArrowRightSFill />} */}
              <RiArrowDownSFill />
            </span>
          )}
        </div>
      ),
    },
  ]);

  const handleSearchEnterPress = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value !== searchInput) {
        setSearchInput(event.target.value);
      }
    }
  };

  const redirectToInvoiceDetail = (invoiceId) =>
    history.push(getPath('invoicedetail').replace(':id', invoiceId));

  const isInvoiceHasCredit = (invoice) =>
    invoice.invoice_items.some((invoiceItem) => invoiceItem.credits.length > 0);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {isInvoiceHasCredit(row.original) && (
          <tr>
            <td colSpan="99" className="invoice-list__credit-cell">
              <span className="invoice-list__credit-label">Avoir demandé</span>
              <table className="invoice-list__credit-table">
                {row.original.invoice_items.map(
                  (invoiceItem) =>
                    !!invoiceItem.credits.length &&
                    invoiceItem.credits.map((credit) => (
                      <tr className="invoice-list__credit-item">
                        <td>{dateTimeToFormatedDate(credit.date_create)}</td>{' '}
                        <td>
                          Article {invoiceItem.reference} pour {credit.quantity} UC -{' '}
                          {credit.reason} - {credit.comment}
                        </td>{' '}
                        <td className="invoice-list__credit-price">
                          {formatPrice(credit.quantity * invoiceItem.price)} € HT
                        </td>
                      </tr>
                    ))
                )}
              </table>
            </td>
          </tr>
        )}
      </>
    ),
    []
  );

  const data = React.useMemo(() => items, [items]);

  return (
    <div className="invoice-list__container fade-in">
      <div className="invoice-list__breadcrum">
        <InvoiceBreadCrumb
          type="list_status"
          matchPath={match.path}
          userType={userType !== undefined ? userType : ''}
        />
      </div>
      <div className="invoice-list__content">
        <InvoiceOrderListControl match={match} handleSearchEnterPress={handleSearchEnterPress} />
      </div>
      <div className="invoice-list__content">
        <ReactTable
          columns={columns}
          data={data}
          isLoading={currentCollection && currentCollection.isLoading && !data.length}
          next={() => {
            fetchInvoiceList(filter, offset + limit, limit);
            setOffset(offset + limit);
          }}
          totalRowCount={invoiceTotalRowCount}
          loadedRowCount={data.length}
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </div>
  );
};

InvoiceList.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  invoiceCollections: PropTypes.arrayOf(PropTypes.object),
  currentPlatformId: PropTypes.number,
  fetchInvoiceList: PropTypes.func,
  fetchInvoiceListTotalRowCount: PropTypes.func,
  downloadPdf: PropTypes.func,
  renewOrder: PropTypes.func,
  invoiceCollectionsCount: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { user } = state;
  const currentPlatformId = selectCurrentPlatformId(state);

  return {
    user: user.information,
    selectedPlatform: selectCurrentPlatform(state),
    currentPlatformId,
    invoiceCollections: selectInvoiceCollections(state),
    invoiceCollectionsCount: selectInvoiceCollectionsCount(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchInvoiceList: (filter, offset, limit) =>
    dispatch(invoiceActions.fetchInvoiceOrder(filter, offset, limit)),
  fetchInvoiceListTotalRowCount: (filter) => dispatch(invoiceActions.fetchTotalRowsNumber(filter)),
  downloadPdf: (invoice) => dispatch(invoiceActions.downloadInvoiceFormPdf(invoice)),
  renewOrder: (id) => dispatch(orderActions.renewOrder(id, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
