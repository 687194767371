import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getDegressivePricesTable, formatPriceWithCurrency } from 'helpers';
import { BsInfoCircle } from '@react-icons/all-files/bs/BsInfoCircle';

import Tooltip from 'components/Core/Tooltip/Tooltip';
import './DegressivePrices.scss';

const DegressivePrices = ({ product }) => {
  const {
    degressive_prices: degressivePrices,
    use_degressive_prices: hasDegressivePrices,
  } = product;

  const degressivePricesTable = getDegressivePricesTable(product) || [];
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  return (
    <>
      {hasDegressivePrices && degressivePrices && degressivePrices.length > 0 && (
        <div className="degressive-price__wrapper">
          <button
            type="button"
            className="degressive-price__link"
            onMouseEnter={(event) => {
              setIsTooltipVisible(true);
              setParentElement(event.target);
            }}
            onMouseLeave={() => {
              setIsTooltipVisible(false);
            }}
          >
            <BsInfoCircle size={14} />
            Voir tableau des prix
          </button>

          <Tooltip
            className="degressive-price__popin"
            isVisible={isTooltipVisible}
            parentElement={parentElement}
            tooltipPosition="bottom"
          >
            <table className="degressive-price__table">
              <thead>
                <tr>
                  <th>Quantité</th>
                  <th>Prix HT / unité</th>
                  <th>Prix HT / colis</th>
                </tr>
              </thead>
              <tbody>
                {degressivePricesTable.map((step) => {
                  const { id, quantityRange, unitPrice, groupPrice } = step;
                  return (
                    <tr key={id}>
                      <td>{quantityRange}</td>
                      <td>{formatPriceWithCurrency(unitPrice)}</td>
                      <td>{formatPriceWithCurrency(groupPrice)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tooltip>
        </div>
      )}
    </>
  );
};

DegressivePrices.propTypes = {
  product: PropTypes.object,
};

export default DegressivePrices;
