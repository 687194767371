import React, { useCallback } from 'react';
import ModalConfirmWrapper from 'components/Modal/ModalConfirm.style';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { modalActions } from 'actions';
import { connect } from 'react-redux';

const ModalConfirm = ({ title, message, callback, closeModal }) => {
  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleConfirmModal = useCallback(() => {
    callback();
    closeModal();
  }, [callback, closeModal]);

  return (
    <ModalConfirmWrapper>
      <div className="modal-body">
        <h3>{title || 'Veuillez confirmer'}</h3>
        <p>{message}</p>
        <div className="modal-confirm__button-wrapper">
          <ButtonPrimary inverted onClick={handleCloseModal}>
            Annuler
          </ButtonPrimary>
          <ButtonPrimary onClick={handleConfirmModal}>Confirmer</ButtonPrimary>
        </div>
      </div>
    </ModalConfirmWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(ModalConfirm);
