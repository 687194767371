import { containerConstants } from '../constants';

const initialState = {
  containerLineShowed: true,
  summaryShowed: true,
  isLoading: false,
  collections: [],
  binsDry: [],
  binsReefer: [],
  packerDry: false,
  packerReefer: false,
  containerSave: false,
};

export function container(state = initialState, action) {
  const { filter } = action;
  let { collections } = state;
  if (!Object.keys(containerConstants).find((key) => containerConstants[key] === action.type)) {
    return state;
  }
  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  switch (action.type) {
    case containerConstants.STATE_CONTAINERS_DRY:
      return {
        ...state,
        binsDry: action.bins,
      };
    case containerConstants.STATE_CONTAINERS_REEFER:
      return {
        ...state,
        binsReefer: action.bins,
      };
    case containerConstants.STATE_PACKER_DRY:
      return {
        ...state,
        packerDry: action.packerDry,
      };
    case containerConstants.STATE_PACKER_REEFER:
      return {
        ...state,
        packerReefer: action.packerReefer,
      };
    case containerConstants.DELETE_CONTAINERS:
      return {
        ...state,
        binsDry: [],
        binsReefer: [],
        packerDry: false,
        packerReefer: false,
      };
    case containerConstants.CHANGE_STATUS:
      return {
        ...state,
        containerLineShowed: action.containerLineShowed,
      };
    case containerConstants.CHANGE_LOGISTIC:
      return {
        ...state,
        summaryShowed: action.summaryShowed,
      };
    case containerConstants.FETCH_REQUEST:
      collections = [{ ...collections, isLoading: true }];
      return {
        ...state,
        collections,
      };
    case containerConstants.FETCH_SUCCESS:
      collections = [
        {
          ...collection,
          items: action.items,
          isLoading: false,
        },
      ];
      return {
        ...state,
        collections,
      };
    case containerConstants.FETCH_FAILURE:
      return state;
    case containerConstants.SAVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case containerConstants.SAVE_SUCCESS:
      return {
        ...state,
        containerSave: action.containers || null,
        isLoading: false,
      };
    case containerConstants.SAVE_FAILURE:
      return state;
    case containerConstants.GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case containerConstants.GET_SUCCESS:
      return {
        ...state,
        containersDetail: action.containers || null,
        isLoading: false,
      };
    case containerConstants.GET_FAILURE:
      return state;
    default:
      return state;
  }
}
