import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions, termsActions } from 'actions';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import withStyle from './ModalAddTerms.style';

class ModalAddTerms extends React.Component {
  constructor(props) {
    super(props);
    const { term } = this.props;

    const { title, full_filename: fullFilename } = term || {};
    this.state = {
      title: title || '',
      file: null,
      oldFile: fullFilename || null,
      hasChangedFile: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  selectFile = (e) => {
    this.setState({
      file: e.target.files[0] || null,
      oldFile: e.target.files[0] ? e.target.files[0].name : null,
      hasChangedFile: true,
    });
  };

  handleSubmit = () => {
    const { term } = this.props;
    const { id } = term || {};
    if (!id) {
      this.create();
    } else {
      this.update();
    }
  };

  create = () => {
    const { createTerms, closeModal, platformId } = this.props;
    const { title, file } = this.state;
    if (title && file) {
      createTerms({ title, file, platform: { id: platformId } });
      closeModal();
    }
  };

  update = () => {
    const { term, updateTerms, closeModal } = this.props;
    const { title, file, hasChangedFile } = this.state;
    if (term.id) {
      if (hasChangedFile) {
        if (title && file) {
          updateTerms(term.id, { title, file }, true);
          closeModal();
        }
      } else if (!hasChangedFile) {
        if (title) {
          updateTerms(term.id, { title, file }, false);
          closeModal();
        }
      }
    }
  };

  deleteFile = () => {
    this.setState({
      file: null,
      hasChangedFile: true,
      oldFile: null,
    });
  };

  render() {
    const { className, term } = this.props;
    const { title, oldFile, file, hasChangedFile } = this.state;
    return (
      <div className={className}>
        <div className="title">
          <span className="bold">libellé</span>
          <input
            type="text"
            className="input-label"
            onChange={this.handleChange}
            value={title}
            placeholder="libellé"
          />
        </div>
        <div className="file">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>
            <input type="file" className="input-file" onChange={this.selectFile} hidden />
            <ButtonSecondary inverted>
              <span>Parcourir</span>
            </ButtonSecondary>
          </label>
        </div>
        <div className="oldFile" hidden={!oldFile}>
          <span className="bold">{oldFile}</span>
          <Icon onClick={this.deleteFile} content="\e915" />
        </div>
        <div className="validate">
          <div className="page-profil__alert-bloc">
            {title === '' && <span className="alert-bloc__label">Libellé invalide</span>}
            {(hasChangedFile || !term || !term.id) && file === null && (
              <span className="alert-bloc__label">Aucun document n’a été importé</span>
            )}
          </div>
          <ButtonPrimary
            onClick={this.handleSubmit}
            disabled={!title || (hasChangedFile && file === null)}
          >
            <span>Valider</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalAddTerms.propTypes = {
  className: PropTypes.string,
  platformId: PropTypes.number,
  term: PropTypes.object,
  closeModal: PropTypes.func,
  createTerms: PropTypes.func,
  updateTerms: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  createTerms: (form) => dispatch(termsActions.createTerms(form)),
  updateTerms: (id, form, hasChangedFile) =>
    dispatch(termsActions.updateTerms(id, form, hasChangedFile)),
});

export default connect(null, mapDispatchToProps)(withStyle(ModalAddTerms));
