/* eslint-disable no-nested-ternary */
// Importing catalogConstants from the absolute path (`constants`) bugs.
import {
  getCartItemById,
  addUpdateTempsCartItem,
  addRequestCartItem,
  addSuccessCartItem,
  objectsAreEqual,
} from 'helpers';
import { cartItemsConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const initState = {
  collections: [],
  collectionsCount: [],
};

export function cartItems(state = initState, action) {
  if (!Object.keys(cartItemsConstants).find((key) => cartItemsConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter, cartId } = action;

  if (!filter) {
    filter = {};
  }
  if (!filter.cart && cartId) {
    filter.cart = cartId;
  }
  if (!cartId && filter && filter.cart) {
    cartId = filter.cart;
  }

  let collection;

  /* Only for removing : take the cartId items.
  We only add on a specific filter to display the item on the right table */
  if (action.cartItemId && action.type.indexOf('DELETE') !== -1) {
    const cartItem = getCartItemById(action.cartItemId, collections);
    if (cartItem) {
      cartId = cartItem.cart_id;
      filter.cart = cartItem.cart_id;
    }
  }

  collection = collections.find((c) => objectsAreEqual(filter, c.filter));

  if (!collection && action.type !== cartItemsConstants.ADD_TEMPS_CART_SUCCESS) {
    collection = {
      filter,
      isLoading: true,
      cartId,
      items: [],
    };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find((c) => objectsAreEqual(filter, c.filter));

  if (!collectionCount && action.type !== cartItemsConstants.ADD_TEMPS_CART_SUCCESS) {
    collectionCount = { filter, isLoading: true, cartId };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case cartItemsConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true, cartId });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case cartItemsConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
        cartId,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case cartItemsConstants.FETCH_COUNT_FAILURE:
      return state;
    case cartItemsConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true, cartId });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case cartItemsConstants.FETCH_SUCCESS:
      collection = Object.assign(collection, {
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
        cartId,
      });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case cartItemsConstants.FETCH_FAILURE:
      return state;
    case cartItemsConstants.ADD_UPDATE_TEMPS:
      collections
        .filter(
          (c) =>
            c.cartId === cartId &&
            ((c.filter['item.type'] && c.filter['item.type'] === action.item.type) ||
              !c.filter['item.type'])
        )
        .map((c) =>
          addUpdateTempsCartItem(c, cartId, action.item, action.quantity, action.platform)
        );
      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.ADD_REQUEST:
      collections
        .filter(
          (c) =>
            c.cartId === cartId &&
            ((c.filter['item.type'] && c.filter['item.type'] === action.item.type) ||
              !c.filter['item.type'])
        )
        .map((c) => addRequestCartItem(c, action.item));
      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.ADD_SUCCESS:
      collections
        .filter(
          (c) =>
            c.cartId === cartId &&
            ((c.filter['item.type'] && c.filter['item.type'] === action.item.type) ||
              !c.filter['item.type'])
        )
        .map((c) => addSuccessCartItem(c, action.cartItem, action.item));
      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.ADD_TEMPS_CART_SUCCESS:
      // when the cart temps has been replaced by a real one, this is for updating cart ids
      collections
        .filter((c) => c.cartId === action.tempsCartId)
        .map((c) => {
          Object.assign(c, {
            cartId: action.cartId,
            filter: Object.assign(c.filter, { cart: action.cartId }),
            isLoading: false,
          });
          const { items } = c;
          items.map((item) => Object.assign(item, { cart_id: action.cartId }));
          return c;
        });

      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case cartItemsConstants.DELETE_REQUEST:
      collectionsCount
        .filter((c) => c.cartId === cartId)
        .map((c) => Object.assign(c, { totalRowsNumber: Number(c.totalRowsNumber) - 1 }));

      collections
        .filter((c) => c.cartId === cartId)
        .map((c) => {
          const { items } = c;
          const itemIndex = items.findIndex((i) => String(i.id) === String(action.cartItemId));
          items.splice(itemIndex, 1);
          return Object.assign(c, { items });
        });

      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case cartItemsConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case cartItemsConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        collections,
      };
    case cartItemsConstants.UPDATE_SUCCESS:
      collections.forEach((c, index) => {
        const { items } = c;
        if (items) {
          const cartItem = items.find((i) => String(i.id) === String(action.cartItem.id));
          const itemIndex = items.findIndex((i) => String(i.id) === String(action.cartItem.id));
          if (cartItem && itemIndex) {
            Object.assign(cartItem, action.cartItem);
            Object.assign(items[itemIndex], cartItem);
            Object.assign(collections[index], Object.assign({}, c));
          }
        }
      });
      return {
        ...state,
        isLoading: false,
        collections,
      };
    case cartItemsConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
