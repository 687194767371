import isEqual from 'lodash.isequal';
/* eslint-disable no-nested-ternary */
import {
  addTempsCart,
  updateTempsCartFromApi,
  objectsAreEqual,
  updateCartInCollections,
  deleteCartInCollections,
} from 'helpers';
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { cartConstants, orderConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;

const initState = {
  collections: [],
  collectionsCount: [],
  current: {},
  currentPreorder: {},
  currentPreorderIsInitialized: false,
  currentIsInitialized: false,
  validated: {},
  error: {
    message: '',
  },
};

export function cart(state = initState, action) {
  if (!Object.keys(cartConstants).find((key) => cartConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount, current, currentPreorder } = state;

  const { currentPreorderIsInitialized, currentIsInitialized } = state;

  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  if (
    filter.id &&
    action.items &&
    action.items.length &&
    (action.items[0].status === cartConstants.CART_STATUS_CURRENT ||
      action.items[0].status === cartConstants.CART_STATUS_CURRENT_PREORDER)
  ) {
    delete filter.id;
    filter = Object.assign(filter, { status: action.items[0].status });
  }

  const typesToCreateCollection = [
    cartConstants.FETCH_COUNT_REQUEST,
    cartConstants.FETCH_COUNT_SUCCESS,
    cartConstants.FETCH_REQUEST,
    cartConstants.FETCH_SUCCESS,
    cartConstants.CREATE_REQUEST,
    cartConstants.CREATE_SUCCESS,
  ];

  let collection = collections.find((c) => objectsAreEqual(filter, c.filter));

  if (!collection && typesToCreateCollection.includes(action.type)) {
    collection = { filter, isLoading: true, items: [] };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find((c) => objectsAreEqual(filter, c.filter));

  if (!collectionCount && typesToCreateCollection.includes(action.type)) {
    collectionCount = { filter, isLoading: true, items: [] };
    collectionsCount.push(collectionCount);
  }

  /* Reset Error */
  Object.assign(state, { error: initState.error });

  switch (action.type) {
    case cartConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case cartConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case cartConstants.FETCH_COUNT_FAILURE:
      return state;
    case cartConstants.FETCH_REQUEST: {
      collection = { ...collection, isLoading: true };
      collections = [
        ...collections.filter((c) => !isEqual(collection.filter, c.filter)),
        collection,
      ];
      return {
        ...state,
        collections,
      };
    }
    case cartConstants.FETCH_SUCCESS: {
      collection = {
        ...collection,
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
      };
      collections = [
        ...collections.filter((c) => !isEqual(collection.filter, c.filter)),
        collection,
      ];

      return {
        ...state,
        collections,
        current:
          filter.status &&
          filter.status === cartConstants.CART_STATUS_CURRENT &&
          action.items.length
            ? collection.items[0]
            : current,
        currentPreorder:
          filter.status &&
          filter.status === cartConstants.CART_STATUS_CURRENT_PREORDER &&
          action.items.length
            ? collection.items[0]
            : currentPreorder,
        currentPreorderIsInitialized:
          filter.status && filter.status === cartConstants.CART_STATUS_CURRENT_PREORDER
            ? true
            : currentPreorderIsInitialized,
        currentIsInitialized:
          filter.status && filter.status === cartConstants.CART_STATUS_CURRENT
            ? true
            : currentIsInitialized,
      };
    }
    case cartConstants.FETCH_FAILURE:
      return { ...state, current: {}, currentPreorder: {} };
    case cartConstants.COPY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case cartConstants.COPY_SUCCESS:
      if (action.newStatus === cartConstants.CART_STATUS_CURRENT) {
        return {
          ...state,
          current: action.cart,
          isLoading: false,
        };
      }
      if (action.newStatus === cartConstants.CART_STATUS_CURRENT_PREORDER) {
        return {
          ...state,
          currentPreorder: action.cart,
          isLoading: false,
        };
      }
      return {
        ...state,
        copiedCart: action.cart,
        isLoading: false,
      };
    case cartConstants.COPY_FAILURE:
      return {
        ...state,
      };
    case cartConstants.CREATE_REQUEST:
      collection = addTempsCart(collection, filter.status, action.tempsId, action.name);
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
        current:
          filter.status === cartConstants.CART_STATUS_CURRENT ? collection.items[0] : current,
        currentPreorder:
          filter.status === cartConstants.CART_STATUS_CURRENT_PREORDER
            ? collection.items[0]
            : currentPreorder,
        currentPreorderIsInitialized:
          filter.status && filter.status === cartConstants.CART_STATUS_CURRENT_PREORDER
            ? true
            : currentPreorderIsInitialized,
        currentIsInitialized:
          filter.status && filter.status === cartConstants.CART_STATUS_CURRENT
            ? true
            : currentIsInitialized,
      };
    case cartConstants.CREATE_SUCCESS: {
      const newCollection = JSON.parse(
        JSON.stringify(updateTempsCartFromApi(collection, action.cart, action.tempsId))
      );
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];

      return {
        ...state,
        collections: newCollections,
        current:
          filter.status === cartConstants.CART_STATUS_CURRENT ? collection.items[0] : current,
        currentPreorder:
          filter.status === cartConstants.CART_STATUS_CURRENT_PREORDER
            ? collection.items[0]
            : currentPreorder,
      };
    }
    case cartConstants.CREATE_FAILURE:
      return {
        ...state,
        error: {
          message: action.error,
        },
      };
    case cartConstants.SET_ERROR_TOASTED:
      return {
        ...state,
        error: {
          ...state.error,
          toasted: true,
        },
      };
    case cartConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case cartConstants.DELETE_SUCCESS:
      if (String(action.cartId) === String(current.id)) {
        current = {};
      }
      if (String(action.cartId) === String(currentPreorder.id)) {
        currentPreorder = {};
      }
      return {
        ...state,
        collections: deleteCartInCollections(action.cartId, collections),
        current,
        currentPreorder,
        isLoading: false,
      };
    case cartConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case cartConstants.UPDATE_DELIVERY_DATES:
      return {
        ...state,
        deliveryDates: action.deliveryDates,
      };
    case cartConstants.VALIDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case cartConstants.VALIDATE_SUCCESS:
      return {
        ...state,
        validated: { id: action.cartId, newOrder: action.order },
      };
    case cartConstants.VALIDATE_FAILURE:
      return {
        ...state,
        error: {
          message: action.error,
        },
        isLoading: false,
      };
    case cartConstants.UPDATE_REQUEST:
      if (current && current.id === action.cart.id) {
        current = action.cart;
      }
      if (currentPreorder && currentPreorder.id === action.cart.id) {
        currentPreorder = action.cart;
      }
      return {
        ...state,
        collections: updateCartInCollections(action.cart, collections),
        current,
        currentPreorder,
      };
    case cartConstants.UPDATE_SUCCESS:
      if (current && current.id === action.cart.id) {
        current = action.cart;
      }
      if (currentPreorder && currentPreorder.id === action.cart.id) {
        currentPreorder = action.cart;
      }
      return {
        ...state,
        collections: updateCartInCollections(action.cart, collections),
        current,
        currentPreorder,
      };
    case cartConstants.UPDATE_FAILURE:
      return state;
    case cartConstants.CART_UPDATE:
      if (action.cart.status === CART_STATUS_CURRENT) {
        current = Object.assign({}, action.cart);
      }
      if (action.cart.status === CART_STATUS_CURRENT_PREORDER) {
        currentPreorder = Object.assign({}, action.cart);
      }
      return {
        ...state,
        collections: updateCartInCollections(action.cart, collections),
        current,
        currentPreorder,
      };
    case cartConstants.DOWNLOAD_CART_PDF_REQUEST:
      return {
        ...state,
        pdfIsLoading: true,
        idCartDownload: action.cart.id,
      };
    case orderConstants.DOWNLOAD_CART_PDF_SUCCESS:
      return {
        ...state,
        pdfIsLoading: false,
      };
    case orderConstants.DOWNLOAD_CART_PDF_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
