import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withStyle from './Reminder.styles';

const Reminder = ({ className }) => (
  <div className={className}>
    <div className="icon">
      <Icon content="\e902" />
    </div>
    <div className="label">
      <p className="regular">
        Pour enregistrer les modifications, cliquez sur le bouton ENREGISTRER
      </p>
    </div>
  </div>
);
Reminder.propTypes = {
  className: PropTypes.string,
};

export default withStyle(Reminder);
