/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { assortmentActions } from 'actions';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonSecondary } from 'components/Core/Button/Button';
import withStyle from './Assortment.styles';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const HEADERS = (
  <thead key="header-prospect">
    <tr>
      <th>Secteurs/Rayons/Familles </th>
    </tr>
  </thead>
);

class Assortment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      nodes: [],
      brand: null,
      client: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      if (
        this.props.isLoading === false &&
        (this.props.brand !== this.state.brand || this.props.client !== this.state.client)
      ) {
        this.setState({
          client: this.props.client,
          brand: this.props.brand,
          nodes: this.getCategoriesToRows(),
        });
      }
    }
  }

  getCategoriesToRows() {
    const { categories } = this.props;
    const nodes = this.loopCategories(Object.values(categories));
    return nodes;
  }

  loopCategories = (children, parentId = 0) => {
    /* Take an objet to prevent back updates */
    const nodes = Object.keys(children)
      .map((key) => children[key])
      .map((child) => {
        const node =
          !child.children && !child.items
            ? { value: `${child.id}`, label: child.name }
            : { value: `${parentId}-${child.id}`, label: child.name };
        if (child.children && Object.keys(child.children).length) {
          node.children = this.loopCategories(child.children, child.id);
        } else if (child.items && child.items.length) {
          node.children = this.loopCategories(child.items, child.id);
        }
        return node;
      });
    return nodes;
  };

  itemFinder = (accumulator, currentValue) => {
    const { children, items } = currentValue;
    const isItem = !children && !items;
    if (isItem) {
      return [...accumulator, currentValue];
    }
    const effectiveChildren = items || children;
    return effectiveChildren.reduce(this.itemFinder, accumulator);
  };

  checkAll = () => {
    const { updateChecked } = this.props;
    const { nodes } = this.state;
    const items = nodes.reduce(this.itemFinder, []);
    updateChecked(items.map(({ value }) => value));
  };

  deploySectors = () => {
    const { nodes } = this.state;
    this.setState({
      expanded: nodes.map(({ value }) => value),
    });
  };

  deployShelves = () => {
    const { nodes } = this.state;
    const expanded = [];
    nodes.forEach(({ value, children }) => {
      expanded.push(value);
      if (children) {
        children.forEach(({ value: childValue }) => expanded.push(childValue));
      }
    });
    this.setState({
      expanded,
    });
  };

  render() {
    const { className, isLoading, checked, updateChecked } = this.props;
    const { expanded, nodes } = this.state;
    return (
      <div className={className}>
        <h2>Sélectionner son assortiment</h2>
        <div className="assortment-actions">
          <ButtonSecondary inverted onClick={this.checkAll}>
            <span className="button-icon-label">Tout Cocher</span>
          </ButtonSecondary>
          <ButtonSecondary inverted onClick={this.deploySectors}>
            <span className="button-icon-label">Déployer Secteur</span>
          </ButtonSecondary>
          <ButtonSecondary inverted onClick={this.deployShelves}>
            <span className="button-icon-label">Déployer Rayon</span>
          </ButtonSecondary>
          {checked.length > 0 && (
            <div className="assortment-actions__span-block">
              <span>Nombre de produits sélectionnés : </span>
              <span>{checked.length}</span>
            </div>
          )}
        </div>
        <table className="assortment-table">
          {HEADERS}
          <tbody>
            <tr>
              <td>
                {!isLoading ? (
                  <CheckboxTree
                    nodes={nodes}
                    checked={checked}
                    expanded={expanded}
                    onCheck={(checked) => {
                      updateChecked(checked);
                    }}
                    onExpand={(expanded) => this.setState({ expanded })}
                    icons={{
                      check: <i className="icon-check-ok" />,
                      expandClose: <i className="icon-arrow-right" />,
                      expandOpen: <i className="icon-arrow-down" />,
                      expandAll: <i className="icon-arrow-down" />,
                      parentOpen: <i className="icon-arrow-down" />,
                      halfCheck: <i className="rct-icon-half-check icon-check-ok" />,
                    }}
                  />
                ) : (
                  <Spinner />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { category, assortment } = state;
  const { categories, brand, client, isLoading } = category || {};
  const {
    assortment: { checked },
  } = assortment;

  return {
    categories: categories && categories.length ? categories[0].categories : [],
    brand,
    client,
    isLoading,
    checked: Object.values(checked) || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChecked: (checked) => {
    dispatch(assortmentActions.updateStore('checked', checked));
  },
});

Assortment.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  isLoading: PropTypes.bool,
  brand: PropTypes.string,
  client: PropTypes.string,
  checked: PropTypes.array,
  updateChecked: PropTypes.func,
};

Assortment.defaultProps = {
  categories: null,
  brand: null,
  isLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(Assortment));
