import React from 'react';
import PropTypes from 'prop-types';
import OngletItem from './OngletItem';

const OngletList = ({ list, active }) => {
  const onglets = list.map((onglet) => {
    const { type, link, name, icon, iconClassName } = onglet;
    return (
      <OngletItem
        key={`onglet:${name}`}
        name={name}
        type={type}
        active={active === type}
        link={link}
        icon={icon}
        iconClassName={iconClassName}
      />
    );
  });
  return <ul>{onglets}</ul>;
};

OngletList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      icon: PropTypes.string,
      iconClassName: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  active: PropTypes.string,
};
export default OngletList;
