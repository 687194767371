import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const searchService = {
  search,
  searchTotalRowsNumber,
  searchLogs,
};

function searchLogs(obj) {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const body = JSON.stringify(obj);
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/v2.0/search/logs/add`, requestOptions).then(handleResponse);
}

function search(searchValue, filter, showManufacturers, showClients, offset = 0, limit = 10) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const { searchValue: filterSearchValue, ...filterCopy } = filter;
  const urlFilter = JSON.stringify(filterCopy);
  // Need two encode to avoid server redirection with the / char on search
  const encodedSearchValue = encodeURIComponent(encodeURIComponent(searchValue));
  if (showClients) {
    return fetch(
      `${config.apiUrl}/api/search?q=${encodedSearchValue}&offset=${offset}&limit=${limit}&filter=${urlFilter}`,
      requestOptions
    ).then(handleResponse);
  }
  return fetch(
    `${config.apiUrl}/api/search?q=${encodedSearchValue}&showManufacturers=${showManufacturers}&limitManufacturers=false&offset=${offset}&limit=${limit}&filter=${urlFilter}`,
    requestOptions
  ).then(handleResponse);
}

function searchTotalRowsNumber(searchValue, filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const { searchValue: filterSearchValue, ...filterCopy } = filter;
  const urlFilter = JSON.stringify(filterCopy);
  // Need two encode to avoid server redirection with the / char on search
  const encodedSearchValue = encodeURIComponent(encodeURIComponent(searchValue));
  return fetch(
    `${config.apiUrl}/api/search/count?q=${encodedSearchValue}&filter=${urlFilter}`,
    requestOptions
  ).then(handleResponse);
}
