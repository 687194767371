import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPath } from 'helpers';
import { userService } from 'services';
import { userConstants, cartConstants } from 'components/../constants';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';

const { TYPE_CUSTOMER } = userConstants;
const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;

const CartBreadCrumb = ({
  isCurrentCart,
  isCurrentPreorderCart,
  isPreorder,
  cartName,
  isListCart,
  user,
}) => {
  const url = [
    {
      text: 'Accueil ',
      href:
        user.type === TYPE_CUSTOMER ? userService.getHomepagePath(user) : getPath('dashboardAdmin'),
    },
  ];
  if (user.type !== TYPE_CUSTOMER) {
    /*
      We access a cart from the list of
      current carts or carts to validate in the orderList page
    */
    if (!isPreorder) {
      url.push({
        text: 'Commandes (permanent)',
        href: getPath('orderList').replace(':status?', CART_STATUS_CURRENT),
      });
    } else {
      url.push({
        text: 'Précommandes',
        href: getPath('preorderList').replace(':status?', CART_STATUS_CURRENT_PREORDER),
      });
    }
  } else if (!isCurrentCart && !isCurrentPreorderCart) {
    // User is not a commercial and...
    if (isListCart) {
      url.push({
        text: 'Commande express ',
        href: getPath('cartList'),
      });
    } else {
      url.push(
        {
          text: 'Commande express ',
          href: getPath('cartList'),
        },
        {
          text: 'Mes paniers types ',
          href: getPath('cartList'),
        }
      );
    }
  }

  let currentPage;
  if (isCurrentCart) {
    currentPage = 'Panier permanent';
  } else if (isCurrentPreorderCart) {
    currentPage = 'Panier précommande';
  } else {
    currentPage = cartName || 'Panier';
  }
  return (
    <div>
      <BreadCrumb urlTab={url} currentPage={currentPage} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    user: { information },
  } = state;
  return {
    user: information,
  };
};

CartBreadCrumb.propTypes = {
  isCurrentCart: PropTypes.bool,
  isCurrentPreorderCart: PropTypes.bool,
  isPreorder: PropTypes.bool,
  isListCart: PropTypes.bool,
  cartName: PropTypes.string,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(CartBreadCrumb);
