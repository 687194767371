/* eslint-disable no-nested-ternary */
// Importing manufacturerConstants from the absolute path (`constants`) bugs.
import { searchConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const initState = {
  collectionsManufacturers: [],
  collections: [],
  collectionsClient: [],
  collectionsClientCount: [],
  collectionsCount: [],
  isLoading: false,
};

export function search(state = initState, action) {
  if (!Object.keys(searchConstants).find((key) => searchConstants[key] === action.type)) {
    return state;
  }

  let { filter } = action;
  if (!filter) {
    filter = {};
  }

  let { collections, collectionsClient, collectionsCount, collectionsClientCount } = state;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((m) => JSON.stringify(m.filter) === JSON.stringify(filter));
  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionClient = collectionsClient.find(
    (m) => JSON.stringify(m.filter) === JSON.stringify(filter)
  );
  if (!collectionClient) {
    collectionClient = { filter, isLoading: true };
    collectionsClient.push(collectionClient);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );
  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  let collectionClientCount = collectionsClientCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );
  if (!collectionClientCount) {
    collectionClientCount = { filter, isLoading: true };
    collectionsClientCount.push(collectionClientCount);
  }

  switch (action.type) {
    case searchConstants.FETCH_COUNT_REQUEST:
      if (action.countClients) {
        collectionClientCount = Object.assign(collectionClientCount, { isLoading: true });
        collectionsClientCount = Object.assign([collectionClientCount], collectionsClientCount);
        return {
          ...state,
          collectionsClientCount,
        };
      }
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount: [{ isLoading: true, filter }],
        collectionsClientCount: [{ isLoading: true, filter }],
      };
    case searchConstants.FETCH_COUNT_SUCCESS:
      if (action.countClients) {
        collectionClientCount = Object.assign(collectionClientCount, {
          totalRowsNumber: action.numberClientDataAvailable,
          isLoading: false,
        });
        collectionsClientCount = Object.assign([collectionClientCount], collectionsClientCount);
      } else {
        collectionCount = Object.assign(collectionCount, {
          totalRowsNumber: action.numberDataAvailable,
          isLoading: false,
        });
        collectionsCount = Object.assign([collectionCount], collectionsCount);
      }
      return {
        ...state,
        collectionsCount,
        collectionsClientCount,
      };
    case searchConstants.FETCH_COUNT_FAILURE:
      return state;
    case searchConstants.FETCH_REQUEST:
      if (action.showClients) {
        collectionClient = Object.assign(collectionClient, { isLoading: true });
        collectionsClient = Object.assign([collection], collectionsClient);
        return {
          ...state,
          collectionsClient,
        };
      }
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case searchConstants.FETCH_SUCCESS:
      if (action.showClients) {
        collectionClient = Object.assign(collectionClient, {
          items:
            action.direction === DIRECTION_AFTER
              ? (collectionClient.items || []).concat(action.items)
              : action.direction === DIRECTION_BEFORE
              ? action.items.concat(collectionClient.items || [])
              : action.items,
          isLoading: false,
        });
        collectionsClient = Object.assign([collectionClient], collectionsClient);
      } else {
        collection = Object.assign(collection, {
          items:
            action.direction === DIRECTION_AFTER
              ? (collection.items || []).concat(action.items)
              : action.direction === DIRECTION_BEFORE
              ? action.items.concat(collection.items || [])
              : action.items,
          isLoading: false,
        });
        collections = Object.assign([collection], collections);
      }
      return {
        ...state,
        collections,
        collectionsClient,
      };
    case searchConstants.FETCH_FAILURE:
      return state;
    default:
      return state;
  }
}
