import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BsFillCaretDownFill } from '@react-icons/all-files/bs/BsFillCaretDownFill';
import { userActions, modalActions } from 'actions';
import { getPath, history } from 'helpers';
import Icon from 'components/Icon/Icon';
import { userConstants } from 'pages/../constants';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import get from 'lodash.get';
import { ProfilMenuStyle } from './ProfilMenu.style';
import TermList from '../../TermList/TermList';

class ProfilMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
    };
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  goTo(routeName) {
    history.push(getPath(routeName));
  }

  toggleDropDown(listOpen) {
    this.setState({ listOpen });
  }

  render() {
    const { listOpen } = this.state;
    const { openContactsModal, user } = this.props;
    const type = get(user, 'type', null);
    const { TYPE_CUSTOMER } = userConstants;
    const dropdownTitle = (
      <div className="header--profil-group">
        <div className="header--profil-icon">
          <span className="icon-profil">
            <BsFillCaretDownFill />
          </span>
          <span className="header--profil-username">{user.username}</span>
        </div>
      </div>
    );

    return (
      <ProfilMenuStyle>
        <Dropdown
          headerTitle={dropdownTitle}
          listOpen={listOpen}
          toggleDropDown={this.toggleDropDown}
          defaultIcon={false}
        >
          <div className="dd-list">
            <ul className="profile-menu__content">
              <li onClick={() => this.goTo('profil')}>
                <Icon content="\e94a" />
                <div className="profile-menu__profile-link">
                  <span>{user.client_name}</span>
                  <span className="profile-menu__my-profile">Voir mon profil</span>
                </div>
              </li>
              {type === TYPE_CUSTOMER && (
                <li onClick={() => openContactsModal()}>
                  <Icon content="\e946" />
                  <span>Contacts</span>
                </li>
              )}
              <li
                onClick={() => {
                  this.props.logoutUser();
                  this.goTo('login');
                }}
              >
                <Icon content="\e92f" />
                <span>Déconnexion</span>
              </li>
            </ul>
            <hr />
            <div className="profil-menu__footer">
              <TermList />
            </div>
          </div>
        </Dropdown>
      </ProfilMenuStyle>
    );
  }
}
const mapStateToProps = (state) => ({
  user: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => {
  const modalType = 'contacts';
  const modalProps = {
    modalHeader: 'Contacts',
    width: '400',
    color: '#4A4A4A',
    fontWeight: '500',
    border: true,
    padding: true,
  };
  return {
    openContactsModal: () => dispatch(modalActions.open(modalType, modalProps)),
    logoutUser: () => {
      dispatch(userActions.logout());
    },
  };
};

ProfilMenu.propTypes = {
  openContactsModal: PropTypes.func,
  user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilMenu);
