import React from 'react';

import Layout from 'layouts/Layout';
import RecurrentCartList from './RecurrentCartList';

const RecurrentCartListLayout = ({ match }) => (
  <Layout component={RecurrentCartList} match={match} />
);

export default RecurrentCartListLayout;
