import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import OrderDetail from './OrderDetail';

const OrderDetailLayout = ({ match }) => <Layout component={OrderDetail} match={match} />;

OrderDetailLayout.propTypes = {
  match: PropTypes.object,
};
export default OrderDetailLayout;
