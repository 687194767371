import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BiPurchaseTag } from '@react-icons/all-files/bi/BiPurchaseTag';
import { BiStore } from '@react-icons/all-files/bi/BiStore';
import { BsFillCaretDownFill } from '@react-icons/all-files/bs/BsFillCaretDownFill';
import { getPath, history } from 'helpers';
import { userActions } from 'actions';
import { userService } from 'services';
import Icon from 'components/Icon/Icon';
import logo from 'styles/assets/images/logo.png';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { selectScreenWidth } from 'selectors/ui';
import { size } from 'components/Core/Utils/Device';
import { changePlatform } from 'helpers/Platform';
import { checkMatchRoute } from 'helpers/Routes';
import Hamburger from 'components/Core/Hamburger/Hamburger';
import { MenuItem, SubMenuItem } from './Header.style';

const CustomerHeader = ({ platforms, selectedId, screenWidth, user }) => {
  const [listOpen, setListOpen] = useState(false);

  const toggleDropDown = (isListOpen) => {
    setListOpen(isListOpen);
  };

  const handlePlatformChange = (e) => {
    changePlatform(e.target.value, user);
  };

  const goToDashboard = () => {
    history.push(userService.getHomepagePath());
  };

  const goToOrder = () => {
    history.push(getPath('orderList').replace(':status?', ''));
  };

  const goToPromotion = () => {
    history.push(getPath('promotionList'));
  };

  const expressCommandTitle = (
    <>
      <Icon content="\e951" />
      <span className="header__button-title">COMMANDE</span>
    </>
  );

  const hamburgerElements = [
    {
      title: (
        <>
          <BiStore />
          <span>CATALOGUE</span>
        </>
      ),
      routeName: 'aggrid',
    },
    {
      title: expressCommandTitle,
      children: [
        {
          title: 'Mes paniers types',
          routeName: 'cartList',
        },
        {
          title: 'Renouveler une commande',
          routeName: 'orderList',
        },
        {
          title: 'Importer un fichier',
          routeName: 'orderFromCsv',
        },
      ],
    },
    {
      title: (
        <>
          <Icon content="\e947" />
          <span>HISTORIQUE</span>
        </>
      ),
      routeName: 'orderList',
    },
  ];

  const disableCommandeAuto = 0;

  return (
    <>
      <div className="header-container">
        <span onClick={goToDashboard}>
          <img src={logo} className="logo" alt="commande" />
        </span>
        <div className={`hr}`} />
        <div className={`header-soc}`}>
          <div className="header-soc--select">
            <select
              placeholder="Platformes"
              onChange={handlePlatformChange}
              defaultValue={selectedId}
            >
              {platforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
            <BsFillCaretDownFill />
          </div>
        </div>
      </div>
      {screenWidth <= size.tablet && <Hamburger menuElementList={hamburgerElements} />}
      {screenWidth > size.tablet && (
        <div className="header-container header-container__menu">
          <MenuItem onClick={goToDashboard} active={checkMatchRoute(userService.getHomepagePath())}>
            <BiStore />
            <span>CATALOGUE</span>
          </MenuItem>
          <MenuItem onClick={goToPromotion} active={checkMatchRoute(getPath('promotionList'))}>
            <BiPurchaseTag />
            <span>PROMOTIONS</span>
          </MenuItem>
          <MenuItem
            active={
              checkMatchRoute(getPath('cartList')) || checkMatchRoute(getPath('orderFromCsv'))
            }
          >
            <Dropdown
              headerTitle={expressCommandTitle}
              listOpen={listOpen}
              toggleDropDown={toggleDropDown}
              defaultIcon={false}
            >
              <ul className="dd-list">
                <SubMenuItem>
                  <Link to={getPath('cartList')}>Mes paniers types</Link>
                </SubMenuItem>
                {!disableCommandeAuto && (
                  <SubMenuItem>
                    <Link to={getPath('recurrentCartList')}>Mes commandes automatiques</Link>
                  </SubMenuItem>
                )}
                <SubMenuItem>
                  <Link to={getPath('orderList').replace(':status?', '')}>
                    Renouveler une commande
                  </Link>
                </SubMenuItem>
                <SubMenuItem>
                  <Link to={getPath('orderFromCsv')}>Importer un fichier</Link>
                </SubMenuItem>
              </ul>
            </Dropdown>
          </MenuItem>
          <MenuItem
            onClick={goToOrder}
            active={checkMatchRoute(getPath('orderList').replace(':status?', ''))}
          >
            <Icon content="\e947" />
            <span>HISTORIQUE</span>
          </MenuItem>
        </div>
      )}
    </>
  );
};

CustomerHeader.propTypes = {
  platforms: PropTypes.array,
  selectedId: PropTypes.number,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { platform, user } = state;
  const { selectedId, platforms } = platform;
  return {
    platforms,
    selectedId,
    user,
    screenWidth: selectScreenWidth(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutAs: () => {
    dispatch(userActions.logoutAs());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
