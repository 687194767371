// Importing platformConstants from the absolute path (`constants`) bugs.
import { platformConstants } from '../constants';

const initState = {
  platforms: [],
  selectedId: null,
  isLoading: false,
};

export function platform(state = initState, action) {
  switch (action.type) {
    case platformConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case platformConstants.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        platforms: action.platforms.filter((p) => p),
      };
    case platformConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case platformConstants.UPDATE_ACTIVE:
      return {
        ...state,
        selectedId: action.platformId,
      };
    case platformConstants.UPDATE_REQUEST:
      return {
        ...state,
        platforms: state.platforms
          .filter((p) => p.id !== action.platform.id)
          .concat([action.platform]),
        isLoading: true,
      };
    case platformConstants.UPDATE_SUCCESS:
      return {
        ...state,
        platforms: state.platforms
          .filter((p) => p.id !== action.platform.id)
          .concat([action.platform]),
        isLoading: false,
      };
    case platformConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
