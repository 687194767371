import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const categoryService = {
  fetchCategories,
  fetchCategoriesV2,
  fetchCategoriesByBrand,
};

function fetchCategories(platformId, clientId = null, showItems = false, status = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/group/categories?platform=${platformId}&showItems=${showItems}&client=${clientId}&status=${status}`,
    requestOptions
  ).then(handleResponse);
}

function fetchCategoriesV2(platformId, clientId = null, showItems = false, status = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(
    `${config.apiUrl}/api/v2.0/categories?platform=${platformId}&showItems=${showItems}&client=${clientId}&status=${status}`,
    requestOptions
  ).then(handleResponse);
}

function fetchCategoriesByBrand(brand, status = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/category/brand/${brand}&status=${status}`,
    requestOptions
  ).then(handleResponse);
}
