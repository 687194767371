import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const messageService = {
  fetchMessages,
  fetchTotalRowNumber,
  deleteMessage,
  updateMessage,
  createMessage,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/message/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchMessages(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${config.apiUrl}/api/message?filter=${urlFilter}&offset=${offset}&limit=${limit}&orderByDesc=id`,
    requestOptions
  ).then(handleResponse);
}

function deleteMessage(messageId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/message/delete/${messageId}`, requestOptions).then(
    handleResponse
  );
}

function updateMessage(id, data) {
  const body = JSON.stringify({
    id,
    subject: data.subject,
    date_begin: data.date_begin,
    date_end: data.date_end,
    content: data.content,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/message/update`, requestOptions).then(handleResponse);
}

function createMessage(data) {
  const body = JSON.stringify({
    subject: data.subject,
    date_begin: data.date_begin,
    date_end: data.date_end,
    content: data.content,
    client_id: data.clientId,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/message/add`, requestOptions).then(handleResponse);
}
