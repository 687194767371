import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import { modalActions } from 'actions';

const CommercialsActionsTD = styled.td`
  color: #d0021b;
  font-size: 16px;
  .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }
  i {
    cursor: pointer;
  }
`;

const CommercialsActionsDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  color: #d0021b;
  display: block;
  text-align: center .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }

  i {
    cursor: pointer;
  }
`;

const CommercialsActions = ({
  className,
  shouldShowResponsive,
  commercial,
  openUpdateCommercialModal,
}) =>
  !shouldShowResponsive ? (
    <CommercialsActionsTD className={className}>
      <div className="action-block">
        <Icon content="\e937" onClick={() => openUpdateCommercialModal(commercial)} />
      </div>
    </CommercialsActionsTD>
  ) : (
    <CommercialsActionsDIV className={className}>
      <div className="action-block">
        <Icon content="\e937" onClick={() => openUpdateCommercialModal(commercial)} />
      </div>
    </CommercialsActionsDIV>
  );

const mapDispatchToProps = (dispatch) => ({
  openUpdateCommercialModal: (commercial) =>
    dispatch(
      modalActions.open('updateCommercial', {
        modalHeader: 'Fiche Commercial',
        commercial,
        width: '400',
      })
    ),
});

CommercialsActions.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  commercial: PropTypes.object,
  openUpdateCommercialModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(CommercialsActions);
