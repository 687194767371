import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const articleGroupItemService = {
  fetchAllArticleGroupItems,
  addArticleGroupItems,
  deleteArticleGroupItems,
};

function fetchAllArticleGroupItems(filter, offset, limit) {
  const headers = {
    ...authHeader(),
  };
  const jsonFilter = JSON.stringify(filter);
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/productgroupitem?&filter=${jsonFilter}&offset=${offset}&limit=${limit}&orderByDesc=dateCreated`,
    requestOptions
  ).then(handleResponse);
}

function addArticleGroupItems(data) {
  const body = JSON.stringify(data);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/productgroupitem/addtogroup`, requestOptions).then(
    handleResponse
  );
}

function deleteArticleGroupItems(articleGroupId, articleGroupItemProductIdList) {
  const body = JSON.stringify({
    group: articleGroupId,
    products: articleGroupItemProductIdList,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };

  const requestOptions = {
    method: 'PATCH',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/productgroupitem/deletefromgroup`, requestOptions).then(
    handleResponse
  );
}
