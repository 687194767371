import styled from 'styled-components';

export const QuantityHandlerWrapper = styled.div`
  display: flex;
  align-items: center;

  .quantite-icon {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  svg {
    width: 17px;
    height: 17px;
  }

  .icon-add-button {
    background: ${({ disablePlus }) => (disablePlus === true ? '#d8d8d8' : '#c2d2de')};
    color: ${({ disablePlus }) => (disablePlus === true ? '#FFFFFF' : '#01366B')};
    cursor: ${({ disablePlus }) => disablePlus === true && 'no-drop'};
  }

  .icon-less-button {
    background: ${({ disableMinus }) => (disableMinus === true ? '#d8d8d8' : '#c2d2de')};
    color: ${({ disableMinus }) => (disableMinus === true ? '#FFFFFF' : '#01366B')};
    cursor: ${({ disableMinus }) => disableMinus === true && 'no-drop'};
  }

  .input-quantite-cadre {
    width: 45px;
    height: 24px;
    margin: 0 5px;
    border: 1px solid #c2dbde;
    border-radius: 5px;
    color: #00a6ff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
`;
