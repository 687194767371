export const moqConstants = {
  UPLOAD_REQUEST: 'MOQ_UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'MOQ_UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'MOQ_UPLOAD_FAILURE',
  FETCH_REQUEST: 'MOQ_FETCH_REQUEST',
  FETCH_SUCCESS: 'MOQ_FETCH_SUCCESS',
  FETCH_FAILURE: 'MOQ_FETCH_FAILURE',
  VALIDATE_REQUEST: 'MOQ_VALIDATE_REQUEST',
  VALIDATE_SUCCESS: 'MOQ_VALIDATE_SUCCESS',
  VALIDATE_FAILURE: 'MOQ_VALIDATE_FAILURE',
};
