import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { getPath, history, getCollection, getValueOfAttribut, objectsAreEqual } from 'helpers';
import { cartActions, creditActions, commercialActions } from 'actions';
import { userConstants, cartConstants, platformConstants } from 'pages/../constants';
import DashboardSection from 'components/DashboardAdmin/DashboardSection';
import Icon from 'components/Icon/Icon';
import withStyle from './dashboardWithStyle';

const { TYPE_ADMIN } = userConstants;
const {
  CART_STATUS_MOQ_STAND_BY,
  CART_STATUS_MOQ_PREORDER,
  CART_STATUS_CURRENT,
  CART_STATUS_CURRENT_PREORDER,
} = cartConstants;

const { HAS_CREDIT_KEY } = platformConstants;

const BREAKPOINT = 544; // in px
const MonthlyNumberHeader = ['Vendeur', 'CA Mois', 'Marge mois', '% Marge', 'R/O CA', 'R/O Marge'];
const CommandeNumberHeader = [
  'Vendeur',
  'CA Mois',
  'NB Commandes mois',
  'NB Clients',
  'Panier moyen',
];

class DashboardAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldShowResponsive: false };
  }

  componentDidMount() {
    const {
      selectedPlatform: { id: platformId },
      fetchTotalRowsNumber,
      fetchTotalCreditRowsNumber,
      fetchCommercials,
      fetchSalesNumbers,
      fetchTotalMOQStandByRowsNumber,
    } = this.props;
    if (Number(platformId)) {
      fetchTotalMOQStandByRowsNumber(Number(platformId));
      fetchTotalRowsNumber(CART_STATUS_CURRENT, platformId);
      fetchTotalRowsNumber(CART_STATUS_CURRENT_PREORDER, platformId);
      fetchTotalCreditRowsNumber(Number(platformId));
    }

    fetchCommercials();
    fetchSalesNumbers();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedPlatform: { id: platformId },
      fetchTotalRowsNumber,
      fetchTotalCreditRowsNumber,
      fetchTotalMOQStandByRowsNumber,
    } = this.props;
    const {
      selectedPlatform: { id: prevPlatformId },
    } = prevProps;
    if (Number(platformId) && platformId !== prevPlatformId) {
      fetchTotalMOQStandByRowsNumber(Number(platformId));
      fetchTotalRowsNumber(CART_STATUS_CURRENT, Number(platformId));
      fetchTotalRowsNumber(CART_STATUS_CURRENT_PREORDER, Number(platformId));
      fetchTotalCreditRowsNumber(Number(platformId));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ shouldShowResponsive: this.isWidthLessThanBreakPoint() });
  };

  isWidthLessThanBreakPoint = (breakpoint = BREAKPOINT) => document.body.clientWidth <= breakpoint;

  render() {
    const {
      className,
      collectionCountCurrentPreorder,
      collectionCountCurrent,
      salesNumbers,
      collectionCountCredit,
      collectionCountPreorder,
      commercialCollectionItems,
      selectedPlatform: { attributs },
    } = this.props;
    const { shouldShowResponsive } = this.state;
    return (
      <div className={className}>
        <div className="home-page__button-section">
          {!!Number(getValueOfAttribut(attributs || [], HAS_CREDIT_KEY)) && (
            <div className="home-page__button-container credit">
              <ButtonPrimary
                inverted
                className="home-page__button"
                onClick={() =>
                  history.push(getPath('orderList').replace(':status', 'STATUS_CREDIT'))
                }
              >
                <Icon content="\e918" />
                <span>Avoirs demandés</span>
              </ButtonPrimary>
              <i className="data-number">
                {(collectionCountCredit && collectionCountCredit.totalRowsNumber) || 0}
              </i>
            </div>
          )}

          <div className="home-page__button-container credit">
            <ButtonPrimary
              inverted
              className="home-page__button"
              onClick={() =>
                history.push(getPath('preorderList').replace(':status', CART_STATUS_MOQ_STAND_BY))
              }
            >
              <Icon content="\e958" />
              <span>Précommandes à valider</span>
            </ButtonPrimary>
            <i className="data-number">
              {(collectionCountPreorder && collectionCountPreorder.totalRowsNumber) || 0}
            </i>
          </div>

          <div className="home-page__button-container cart">
            <ButtonPrimary
              inverted
              className="home-page__button"
              onClick={() =>
                history.push(getPath('orderList').replace(':status', CART_STATUS_CURRENT))
              }
            >
              <Icon content="\e94c" />
              <span>paniers en cours</span>
            </ButtonPrimary>
            <i className="data-number">
              {(collectionCountCurrent && collectionCountCurrent.totalRowsNumber) || 0}
            </i>
          </div>

          <div className="home-page__button-container cart">
            <ButtonPrimary
              inverted
              className="home-page__button"
              onClick={() =>
                history.push(
                  getPath('preorderList').replace(':status', CART_STATUS_CURRENT_PREORDER)
                )
              }
            >
              <Icon content="\e941" />
              <span>paniers en cours</span>
            </ButtonPrimary>
            <i className="data-number">
              {(collectionCountCurrentPreorder && collectionCountCurrentPreorder.totalRowsNumber) ||
                0}
            </i>
          </div>
        </div>

        <div className="home-page___content-section">
          <DashboardSection
            type="monthly"
            label="Mes chiffres mensuels"
            header={MonthlyNumberHeader}
            data={commercialCollectionItems}
            responsive={shouldShowResponsive}
          />
          <DashboardSection
            label="Chiffres sur Commande.com"
            header={CommandeNumberHeader}
            data={salesNumbers}
            responsive={shouldShowResponsive}
          />
        </div>
      </div>
    );
  }
}

DashboardAdmin.propTypes = {
  className: PropTypes.string,
  fetchTotalRowsNumber: PropTypes.func,
  fetchCommercials: PropTypes.func,
  fetchSalesNumbers: PropTypes.func,
  fetchTotalCreditRowsNumber: PropTypes.func,
  fetchTotalMOQStandByRowsNumber: PropTypes.func,
  collectionCountCurrent: PropTypes.object,
  collectionCountCredit: PropTypes.object,
  collectionCountCurrentPreorder: PropTypes.object,
  collectionCountPreorder: PropTypes.object,
  commercialCollectionItems: PropTypes.array,
  salesNumbers: PropTypes.array,
  selectedPlatform: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    cart: { collectionsCount: cartCollectionsCount },
    platform: { platforms, selectedId },
    user: { information: userInfo },
    credit: { collectionsCount: creditCollectionsCount },
    commercial: { collections: commercialCollections, salesNumbers },
  } = state;
  const selectedPlatform = platforms.find((p) => Number(p.id) === Number(selectedId)) || {};
  // Commercials info
  const effectiveCommercialCollection = getCollection(commercialCollections || [], {});
  const commercialCollection =
    userInfo.type !== TYPE_ADMIN
      ? (effectiveCommercialCollection.items || []).filter(({ id }) => id === userInfo.id)
      : effectiveCommercialCollection.items;
  // Counts for the buttons
  const collectionCountCurrent = cartCollectionsCount.find(
    ({ filter: { status } }) => status === CART_STATUS_CURRENT
  );
  const collectionCountCurrentPreorder = cartCollectionsCount.find(
    ({ filter: { status } }) => status === CART_STATUS_CURRENT_PREORDER
  );
  const collectionCountPreorder =
    cartCollectionsCount.find((c) =>
      objectsAreEqual(
        {
          or: [
            ['c.status', 'eq', CART_STATUS_MOQ_STAND_BY],
            ['c.status', 'eq', CART_STATUS_MOQ_PREORDER],
          ],
          platform: selectedPlatform.id,
        },
        c.filter
      )
    ) || [];
  const collectionCountCredit = creditCollectionsCount.find(
    ({ filter: { platform } }) => platform === selectedId
  );
  const collectionSalesNumbers =
    userInfo.type === TYPE_ADMIN
      ? salesNumbers
      : salesNumbers.filter((salesNumber) => salesNumber.id === userInfo.id);

  return {
    salesNumbers: collectionSalesNumbers || [],
    selectedPlatform,
    collectionCountCurrent,
    collectionCountCurrentPreorder,
    collectionCountCredit,
    collectionCountPreorder,
    commercialCollectionItems: commercialCollection || [],
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchTotalRowsNumber: (status, platformId) => {
    dispatch(cartActions.fetchTotalRowsNumber({ status, platform: platformId }));
  },
  fetchTotalCreditRowsNumber: (platformId) =>
    dispatch(creditActions.fetchTotalRowsNumber({ platform: platformId })),
  fetchCommercials: () => dispatch(commercialActions.fetchCommercials({}, 0, 100)),
  fetchSalesNumbers: () => dispatch(commercialActions.fetchSalesNumbers()),
  fetchTotalMOQStandByRowsNumber: (platformId) => {
    dispatch(
      cartActions.fetchTotalRowsNumber({
        or: [
          ['c.status', 'eq', CART_STATUS_MOQ_STAND_BY],
          ['c.status', 'eq', CART_STATUS_MOQ_PREORDER],
        ],
        platform: platformId,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(DashboardAdmin));
