export function authHeader(enableLoggedAs = true) {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.access_token) {
    if (enableLoggedAs === true && user.loggedAs) {
      return {
        Authorization: `Bearer ${user.access_token}`,
        'X-Switch-User': user.loggedAs,
      };
    }

    return { Authorization: `Bearer ${user.access_token}` };
  }

  return {};
}

export function isLoggedAsSomeoneElse() {
  const user = JSON.parse(localStorage.getItem('user'));

  return user && !!user.loggedAs;
}

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}
