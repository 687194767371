import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deliveryPlanningActions } from 'actions';
import Icon from 'components/Icon/Icon';

class Planning extends React.Component {
  componentDidMount() {
    const { fetchDeliveryPlannings } = this.props;
    fetchDeliveryPlannings();
  }

  getItemsByTemperature = () => {
    const { deliveryPlannings, temperatures } = this.props;
    const byTemperature = {};
    temperatures.forEach((temperature) => {
      byTemperature[temperature] = [];
    });
    let itemList = [];
    // Add all children to the list
    deliveryPlannings.forEach((item) => {
      itemList.push(item);
      if (item && item.children && item.children.length && item.children.length > 0) {
        itemList = itemList.concat(item.children);
      }
    });
    itemList.forEach((item) => {
      if (byTemperature[item.temperature] !== undefined) {
        byTemperature[item.temperature].push(item);
      }
    });

    return byTemperature;
  };

  getItemsByDeliveryModes = () => {
    const { deliveryPlannings, deliveryModes } = this.props;
    const byDeliveryMode = {};
    const getMode = (mode) => (mode ? 'Retrait' : 'Livraison');
    deliveryModes.forEach((deliveryMode) => {
      byDeliveryMode[deliveryMode] = [];
    });
    let itemList = [];
    // Add all children to the list
    deliveryPlannings.forEach((item) => {
      itemList.push(item);
      if (item && item.children && item.children.length && item.children.length > 0) {
        itemList = itemList.concat(item.children);
      }
    });

    itemList.forEach((item) => {
      const newItem = {
        ...item,
        delivery_mode: !item.temperature ? getMode(item.delivery_mode) : '',
      };
      if (newItem.delivery_mode) {
        if (byDeliveryMode[newItem.delivery_mode] === undefined) {
          byDeliveryMode[newItem.delivery_mode] = [];
        }
        byDeliveryMode[newItem.delivery_mode].push(newItem);
      }
    });
    return byDeliveryMode;
  };

  onChange = (event) => {
    const { clientPlannings, updateState, platform, platformDeliveryMode } = this.props;
    const selectedPlanningId = Number(event.target.value);
    const selectedTemperatureOrPlanning = event.target.options[
      event.target.selectedIndex
    ].getAttribute('name');
    const getMode = (mode) => (mode ? 'Retrait' : 'Livraison');
    const newClientPlannings = clientPlannings
      .map((planning) => ({
        ...planning,
        delivery_mode: planning.delivery_mode !== undefined ? getMode(planning.delivery_mode) : '',
      }))
      .filter((planning) => {
        if (!platformDeliveryMode) {
          return !(
            planning.temperature === selectedTemperatureOrPlanning &&
            String(planning.platform_id) === String(platform.id)
          );
        }
        return !(
          planning.delivery_mode === selectedTemperatureOrPlanning &&
          String(planning.platform_id) === String(platform.id)
        );
      })
      .map((planning) => ({
        id: planning.id,
        temperature: planning.temperature,
        delivery_mode: planning.delivery_mode,
        platform_id: String(planning.platform_id),
      }));

    newClientPlannings.push({
      id: selectedPlanningId,
      temperature: platformDeliveryMode ? '' : selectedTemperatureOrPlanning,
      delivery_mode: platformDeliveryMode ? selectedTemperatureOrPlanning : '',
      platform_id: String(platform.id),
    });

    updateState('delivery_plannings', newClientPlannings);
  };

  isPlanningChecked = (id) => {
    const { clientPlannings } = this.props;
    return clientPlannings.findIndex((clientPlanning) => clientPlanning.id === id) > -1;
  };

  render() {
    const { platformDeliveryMode, deliveryModes } = this.props;

    const byTemperature = this.getItemsByTemperature();
    const byDeliveryMode = this.getItemsByDeliveryModes();

    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon" content="\e90e" />
          <span className="entete__label">Attribuer un planning</span>
        </div>
        <div className="block-info__content planning">
          {platformDeliveryMode
            ? deliveryModes.map(
                (deliveryMode) =>
                  byDeliveryMode[deliveryMode].length > 0 && (
                    <div>
                      <div className="bloc__select">
                        <span className="bold ligne__attribut">{`${deliveryMode}:`}</span>
                        <select onChange={this.onChange}>
                          <option selected disabled hidden>
                            Sélectionner votre planning de livraison
                          </option>
                          {byDeliveryMode[deliveryMode].map((item) => (
                            <option
                              value={item.id}
                              name={item.delivery_mode}
                              selected={this.isPlanningChecked(item.id)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )
              )
            : Object.keys(byTemperature).map(
                (temperature) =>
                  byTemperature[temperature].length > 0 && (
                    <div>
                      <div className="bloc__select">
                        <span className="bold ligne__attribut">{`${temperature}:`}</span>
                        <select onChange={this.onChange}>
                          <option selected disabled hidden>
                            Sélectionner votre planning de livraison
                          </option>
                          {byTemperature[temperature].map((item) => (
                            <option
                              value={item.id}
                              name={item.temperature}
                              selected={this.isPlanningChecked(item.id)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )
              )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { deliveryPlanning, platform } = state;
  const selectedPlatform = Number(platform.selectedId) || null;
  return {
    deliveryPlannings: deliveryPlanning.items || [],
    platform: platform.platforms.find((p) => Number(p.id) === Number(selectedPlatform)) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveryPlannings: () => {
    const filter = { and: [['dp.parent', 'isNull', null]] };
    dispatch(deliveryPlanningActions.fetchItems(filter));
  },
});

Planning.propTypes = {
  fetchDeliveryPlannings: PropTypes.func,
  updateState: PropTypes.func,
  deliveryPlannings: PropTypes.object,
  platform: PropTypes.object,
  clientPlannings: PropTypes.array,
  temperatures: PropTypes.array,
  deliveryModes: PropTypes.array,
  platformDeliveryMode: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
