import { containerConstants } from 'constants/ContainerV2.constants';

const containersTypeReefer = [
  {
    name: '20 Reefer',
    width: 244,
    height: 259,
    length: 605,
    volumePallet: 24 * 1e6,
    volumeVrac: 26 * 1e6,
    weight: 26000,
    pallet: true,
    type: containerConstants.TYPE_CONTAINER_REEFER,
    mode: containerConstants.MODE_VRAC,
  },
  {
    name: '40 Reefer',
    width: 244,
    height: 259,
    length: 1219,
    volumePallet: 53 * 1e6,
    volumeVrac: 56 * 1e6,
    weight: 26000,
    pallet: true,
    type: containerConstants.TYPE_CONTAINER_REEFER,
    mode: containerConstants.MODE_VRAC,
  },
];

export default containersTypeReefer;
