import React, { useEffect } from 'react';
import { Switch, Route, matchPath } from 'react-router-dom';
import { PrivateRoute } from 'hocs';
import { history } from 'pages/../helpers';
import RoutesList from '../constants/Routes';

export const getPath = (routeName) => {
  const searchedRoute = RoutesList.filter((route) => route.name === routeName).pop();
  return (searchedRoute && searchedRoute.path) || null;
};

export const checkMatchRoute = (routeName) => {
  return matchPath(window.location.pathname, { path: routeName });
};

export const Routes = () => {
  const { pathname } = history.location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const routes = RoutesList.map((route) => {
    if (route.isPrivate) {
      return (
        <PrivateRoute
          key={route.name}
          exact={route.pathIsExact}
          path={route.path}
          component={route.component}
          allowedRoles={route.roles || null}
        />
      );
    }
    return (
      <Route
        key={route.name}
        exact={route.pathIsExact}
        path={route.path}
        component={route.component}
      />
    );
  });

  return <Switch>{routes}</Switch>;
};
