/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { getValueOfAttribut, getHasApiStockLeft } from 'helpers';
import { platformConstants } from 'pages/../constants';

const TEMPERATURE_DRY = 1;
const TEMPERATURE_FRESH = 2;
const TEMPERATURE_FROZEN = 3;

const { STOCK_DISPLAY_OUT_KEY } = platformConstants;

const ProductWordingTD = styled.td`
  width: 360px;
  padding-right: 5px;
  .td__libelle-prod-ligne {
    position: relative;
    margin-top: 5px;
  }

  .td__libelle-prod-span {
    font-size: 14px;
    cursor: pointer;
    color: #4a4a4a;
    line-height: 18px;
    margin-left: 2px;
    text-transform: uppercase;
  }

  .td__libelle-prod-icon-new {
    font-size: 20px
    position: absolute;
    left: -23px;
    top: 0px;
  }

  .td_libelle-prod__icon-temperature {
    font-size: 12px;
    margin-right: 10px;
    border-radius: 100%;
    .icon-dry:before,
    .icon-apple:before,
    .icon-snow:before {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }

  .td_libelle-prod__icon-order-type {
    color: #787878;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .td_libelle-prod__icon-order-type i:before {
    font-size: 15px;
  }
  .td_lib-prod {
    display: inline-block;
  }
  .td_libelle-prod__stock-label {
    font-size: 14px;
    vertical-align: middle;
  }

  .td_lib-prod__etat {
    font-size: 18px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    i {
      margin-left: 2px;
    }
  }
  .stock {
    color: #29d24e;
  }
  .preorder-stock {
    color: #f5a623;
  }
  .td-libelle-prod__ref {
    color: #787878;
    font-weight: 500;
  }
  .red {
    color: red;
  }
  @media screen and (max-width: 768px) {
    .td__libelle-prod-span {
      margin-left: 0;
      min-height: 30px;
    }
  }

  /****Responsive */
  @media all and ${screen.tablet} {
    .td__libelle-prod-ligne {
      .icon-new{
        position: absolute;
      }
    }
  }
`;

const ProductWordingDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  padding-top: 15px;
  margin-bottom: 15px;

  .info-produit {
    width: 100%;
    display: inline-flex;
    margin: 0px auto;
  }
  .libelle-prod {
    margin-top: 5px;
    color: #4a4a4a;
  }

  .libelle-produit__stock-label {
    margin-top: 14px;
    color: #29d24e;
  }

  .libelle-produit__icon-temperature {
    margin-top: 12px;
    font-size: 12px;
    margin-right: 10px;
  }

  .libelle-produit__icon-order-type {
    color: #787878;
    margin-bottom: 0px;
    margin-top: 14px;
    margin-right: 10px;
  }

  .libelle-produit__etat {
    font-size: 18px;
    margin-top: 8px;
    margin-right: 10px;
  }
  .libelle-prod {
    display: inline-block;
  }
  .stock {
    color: #29d24e;
  }
  .preorder-stock {
    color: #f5a623;
  }
  .libelle-produit__ref {
    margin-top: 6px;
    color: #787878;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .red {
    color: red;
  }
`;

const ProductWording = ({
  className,
  isProductNew,
  hasProductPromotion,
  displayAsPromotion,
  productName,
  productRef,
  productEan,
  productTemperature,
  isProductPreordered,
  productStock,
  shouldShowResponsive,
  selectedPlatform,
  ...rest
}) => {
  const { attributs } = selectedPlatform;
  const shouldDisplayStock =
    !isProductPreordered && !Number(getValueOfAttribut(attributs || [], STOCK_DISPLAY_OUT_KEY));

  const isProductAvailable = getHasApiStockLeft(
    { stock: productStock, is_preorder: isProductPreordered },
    selectedPlatform
  );
  let iconTemperature;
  switch (productTemperature) {
    case TEMPERATURE_DRY:
      iconTemperature = 'icon-dry';
      break;
    case TEMPERATURE_FRESH:
      iconTemperature = 'icon-apple';
      break;
    case TEMPERATURE_FROZEN:
      iconTemperature = 'icon-snow';
      break;
    default:
      iconTemperature = 'icon-dry';
      break;
  }

  const iconOrderType = isProductPreordered ? 'icon-ship' : 'icon-van2';

  const iconAvailability = isProductAvailable ? 'icon-state' : 'icon-state red';
  const spanAvailabilityStock = isProductAvailable
    ? 'td_libelle-prod__stock-label libelle-produit__stock-label stock regular'
    : 'td_libelle-prod__stock-label libelle-produit__stock-label regular red';
  return !shouldShowResponsive ? (
    <ProductWordingTD className={className} {...rest}>
      <div className="td__libelle-prod-ligne">
        <i
          className="td__libelle-prod-icon-new icon-new"
          hidden={!isProductNew || (hasProductPromotion && displayAsPromotion)}
        />
        {hasProductPromotion && displayAsPromotion && (
          <PromotionIcon className="td__libelle-prod-icon-new" />
        )}
        <span className="bold td__libelle-prod-span">{productName}</span>
      </div>
      <div className="td__libelle-prod-ligne">
        <span className="td_libelle-prod__icon-temperature">
          <i className={iconTemperature} />
        </span>
        <span className="td_libelle-prod__icon-order-type">
          <i className={iconOrderType} />
        </span>
        {isProductPreordered && (
          <div className="td_lib-prod">
            <span className="td_lib-prod__etat preorder-stock">
              <i className="icon-state" />
            </span>
            <span className="td_libelle-prod__stock-label libelle-produit__stock-label preorder-stock regular">
              En précommande
            </span>
          </div>
        )}
        {shouldDisplayStock && (
          <div className="td_lib-prod">
            <span className="td_lib-prod__etat stock">
              <i className={iconAvailability} />
            </span>
            <span className={spanAvailabilityStock}>
              {isProductAvailable ? 'En stock' : 'Indisponible'}
            </span>
          </div>
        )}
      </div>
      <div className="td__libelle-prod-ligne">
        <span className="td-libelle-prod__ref">{`Réf: ${productRef} - EAN: ${productEan}`}</span>
      </div>
    </ProductWordingTD>
  ) : (
    <ProductWordingDIV className={className} {...rest}>
      <div className="info-produit">
        <span className="bold libelle-prod">{productName}</span>
      </div>
      <div className="info-produit">
        <span className="libelle-produit__icon-temperature">
          <i className={iconTemperature} />
        </span>
        <span className="libelle-produit__icon-order-type">
          <i className={iconOrderType} />
        </span>
        {isProductPreordered && (
          <div className="td_lib-prod">
            <span className="td_lib-prod__etat preorder-stock">
              <i className="icon-state" />
            </span>
            <span className="td_libelle-prod__stock-label libelle-produit__stock-label preorder-stock regular">
              En précommande
            </span>
          </div>
        )}
        {shouldDisplayStock && (
          <div className="td_lib-prod">
            <span className="td_lib-prod__etat stock">
              <i className={iconAvailability} />
            </span>
            <span className={spanAvailabilityStock}>
              {isProductAvailable ? 'En stock' : 'Indisponible'}
            </span>
          </div>
        )}
      </div>
      <div className="info-produit">
        <span className="regular libelle-produit__ref">{`Réf :${productRef}- EAN :${productEan}`}</span>
      </div>
    </ProductWordingDIV>
  );
};

const mapStateToProps = (state) => {
  const {
    platform: { platforms, selectedId },
  } = state;
  const selectedPlatform = platforms.find((p) => Number(p.id) === Number(selectedId)) || {};
  return {
    selectedPlatform,
  };
};

ProductWording.propTypes = {
  className: PropTypes.string,
  isProductNew: PropTypes.bool,
  hasProductPromotion: PropTypes.bool,
  displayAsPromotion: PropTypes.bool,
  productName: PropTypes.string,
  productRef: PropTypes.string,
  productEan: PropTypes.string,
  productTemperature: PropTypes.number,
  isProductPreordered: PropTypes.bool,
  isProductAvailable: PropTypes.bool,
  productStock: PropTypes.object,
  shouldShowResponsive: PropTypes.bool,
  selectedPlatform: PropTypes.object,
};

export default connect(mapStateToProps)(ProductWording);
