import React from 'react';
import PropTypes from 'prop-types';
import AddCreditForm from 'components/Form/AddCreditForm/AddCreditForm';

const ModalAddCredit = ({ className, invoice, invoiceItem }) => {
  return (
    <div className={className}>
      <AddCreditForm invoice={invoice} invoiceItem={invoiceItem} />
    </div>
  );
};

ModalAddCredit.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object,
  invoiceItem: PropTypes.object,
};

export default ModalAddCredit;
