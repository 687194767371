import styled from 'styled-components';

const ModalConfirmWrapper = styled.div`
  .modal-body {
    .modal-confirm__button-wrapper {
      display: flex;
      justify-content: space-around;
    }
  }
`;

export default ModalConfirmWrapper;
