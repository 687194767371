import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon/Icon';

function PasswordRecoverySuccess(props) {
  const { className } = props;
  return (
    <div className={className}>
      <div className="password-recovery__background">
        <div className="password_recovery__success-form">
          <div className="password_recovery__icon-check">
            <div className="password_recovery__icon">
              <Icon className="password_recovery__icon-color" content="\e912" />
            </div>
          </div>
          <div>
            <h2 className="passwword-recovery__success-text regular">
              {' '}
              Veuillez consulter votre Email{' '}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

PasswordRecoverySuccess.propTypes = {
  className: PropTypes.string,
};

export default PasswordRecoverySuccess;
