import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCollection } from 'helpers';
import Alert from 'components/Alert/Alert';

const Messages = ({ messages }) => {
  const rows = messages.map((message) => (
    <Alert type="message" subject={message.subject} text={message.content} />
  ));

  return <div hidden={messages.length === 0}>{rows}</div>;
};

Messages.propTypes = {
  messages: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { message, platform } = state;
  const messageFilter = {
    platform: platform.selectedId,
  };
  const messageCollection = getCollection(message.collections || [], messageFilter);
  return { messages: messageCollection.items || [] };
};

export default connect(mapStateToProps)(Messages);
