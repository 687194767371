import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { capitalizeFirstLetter } from 'helpers/Utils';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { getEffectiveQuickFilterName, getEffectiveAvailabilityFilterName } from 'helpers/Filter';
import { aggridFilterActions } from 'actions/AggridFilter';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import { selectItemsFromCatalog } from 'selectors/product';
import ResetControl from 'components/CatalogControlsV3/ResetControl';
import { findCategoryById } from 'helpers/Category';
import { selectCategories } from 'selectors/category';
import { FilterTagBarWrapper } from './FilterTagBar.style';
import FilterTag from './FilterTag';

const FilterTagBar = ({
  handleFilter,
  rowCount,
  currentFilterList,
  totalRowCount,
  setCurrentFilters,
  categories,
}) => {
  const {
    selection,
    quickList,
    availabilityList,
    storeAvailabilityList,
    temperatureList,
    category,
    isPreorder,
    categoryId,
  } = currentFilterList;

  const removeCategoryAndSelectionFilter = () => {
    const newFilters = {
      ...currentFilterList,
      category: null,
      categoryId: null,
      selection: null,
      isPreorder: null,
      selectedCategoryList: [],
      categoryColumns: [],
    };

    setCurrentFilters(newFilters);
    handleFilter(newFilters);
  };

  const removeQuickFilterFromList = (filterValue) => {
    const newFilters = {
      ...currentFilterList,
      quickList: quickList.filter((item) => item !== filterValue),
    };

    setCurrentFilters(newFilters);
    handleFilter(newFilters);
  };

  const removeAvailabilityFilterFromList = (filterValue) => {
    const newFilters = {
      ...currentFilterList,
      availabilityList: availabilityList.filter((item) => item !== filterValue),
    };
    if (filterValue === aggridFilterConstants.FILTER_PREORDER) {
      newFilters.isPreorder = null;
    }

    setCurrentFilters(newFilters);
    handleFilter(newFilters);
  };

  const removeStoreAvailabilityFilterFromList = (filterValue) => {
    const newFilters = {
      ...currentFilterList,
      storeAvailabilityList: storeAvailabilityList.filter((item) => item !== filterValue),
    };

    setCurrentFilters(newFilters);
    handleFilter(newFilters);
  };

  const removeTemperatureFilterFromList = (filterValue) => {
    const newFilters = {
      ...currentFilterList,
      temperatureList: temperatureList.filter((item) => item !== filterValue),
    };

    setCurrentFilters(newFilters);
    handleFilter(newFilters);
  };
  const categoryLabel = category ? `: ${capitalizeFirstLetter(category.toLowerCase())}` : '';

  const isFilterPresent = () => {
    const {
      availabilityList,
      category,
      quickList,
      selectedCategoryList,
      selection,
      storeAvailabilityList,
      temperatureList,
    } = currentFilterList;

    if (
      !availabilityList.length &&
      !category &&
      !quickList.length &&
      !selectedCategoryList.length &&
      !selection &&
      !storeAvailabilityList.length &&
      !temperatureList.length
    ) {
      return false;
    }

    return true;
  };

  return (
    <FilterTagBarWrapper>
      <div className="filter-tag-bar__tag-container">
        <div className="filter-tag-bar__row-counter">
          {rowCount > 0 && totalRowCount && `${rowCount} / ${totalRowCount}`}
        </div>
        {isFilterPresent() && <div className="filter-tag-bar__v-separator" />}
        {isFilterPresent() && (
          <div className="filter-tag-bar__reset-button">
            <ResetControl setCurrentFilters={setCurrentFilters} handleFilter={handleFilter} />
          </div>
        )}
        <div className="filter-tag-bar__tag-list">
          {!!selection && (
            <FilterTag
              key={uuid()}
              label={`${findCategoryById(categories, selection).name}${categoryLabel}`}
              handleClick={() => {
                removeCategoryAndSelectionFilter();
              }}
            />
          )}
          {!selection && !!category && (
            <FilterTag
              key={uuid()}
              label={`All: ${capitalizeFirstLetter(category.toLowerCase())}`}
              handleClick={() => {
                removeCategoryAndSelectionFilter();
              }}
            />
          )}
          {!!quickList &&
            quickList.map((item) => (
              <FilterTag
                key={uuid()}
                label={capitalizeFirstLetter(getEffectiveQuickFilterName(item))}
                handleClick={() => {
                  removeQuickFilterFromList(item);
                }}
              />
            ))}
          {!!availabilityList &&
            availabilityList.length > 0 &&
            availabilityList.map((item) => (
              <FilterTag
                key={uuid()}
                label={capitalizeFirstLetter(getEffectiveAvailabilityFilterName(item))}
                handleClick={() => {
                  removeAvailabilityFilterFromList(item);
                }}
              />
            ))}
          {!!storeAvailabilityList &&
            storeAvailabilityList.length > 0 &&
            storeAvailabilityList.map((item) => (
              <FilterTag
                key={uuid()}
                label={capitalizeFirstLetter(getEffectiveAvailabilityFilterName(item))}
                handleClick={() => {
                  removeStoreAvailabilityFilterFromList(item);
                }}
              />
            ))}
          {!!temperatureList &&
            temperatureList.length > 0 &&
            temperatureList.map((item) => (
              <FilterTag
                key={uuid()}
                label={capitalizeFirstLetter(item.toLowerCase())}
                handleClick={() => {
                  removeTemperatureFilterFromList(item);
                }}
              />
            ))}
        </div>
      </div>
    </FilterTagBarWrapper>
  );
};

const mapStateToProps = (state) => ({
  currentFilterList: selectAggridFilter(state),
  // totalRowCount: selectItemsFromCatalog(state, { aggrid: true }).length,
  categories: selectCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentFilters: (filters) => dispatch(aggridFilterActions.setCurrentFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTagBar);
