import React from 'react';
import PropTypes from 'prop-types';
import MoqQuantity from 'components/MOQ/MoqQuantity';
import CreditItemWrapper from './CreditItem.style';

const CreditItem = ({
  addCredit,
  idLigne,
  idProduct,
  idOrder,
  creditValue,
  isMaxQuantity,
  creditValidate,
  orderItemId,
  disabled,
  maxQuantity,
  currentQuantity,
  creditList,
}) => {
  const { quantity, reason, comment, litigation, errors } = creditValue;

  const handleCheck = () => {
    addCredit({
      idOrder,
      idProduct,
      idLigne,
      orderItemId,
      litigation: !creditValue.litigation,
    });
  };

  const updateQuantity = (newQuantity) => {
    if (errors && errors.quantity !== undefined) {
      delete errors.quantity;
    }
    addCredit({
      idOrder,
      idProduct,
      idLigne,
      orderItemId,
      quantity: newQuantity,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors && errors[name] !== undefined) {
      delete errors[name];
    }

    addCredit({
      idOrder,
      idProduct,
      idLigne,
      orderItemId,
      [name]: value,
      errors,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';
    if (name === 'reason') {
      errorMessage = 'La raison n’est pas indiquée';
    }
    if (name === 'comment') {
      errorMessage = 'Veuillez laisser un commentaire';
    }
    if (name === 'quantity') {
      errorMessage = 'Les quantités ne sont pas renseignées';
    }
    const quantityLeft = creditList
      .filter((credit) => credit.idLigne !== idLigne)
      .reduce((acc, credit) => acc + credit.quantity, 0);
    const newMaxQuantity = maxQuantity - quantityLeft;

    if (newMaxQuantity <= value) {
      updateQuantity(newMaxQuantity || 0);
    }

    if (value === '' || Number(value) === 0) {
      addCredit({
        idOrder,
        idProduct,
        idLigne,
        [name]: name === 'quantity' ? Number(value) : value,
        errors: {
          ...errors,
          [name]: errorMessage,
        },
      });
    }
  };

  return (
    <CreditItemWrapper maxQuantity={isMaxQuantity} validation={creditValidate}>
      <div className="credit-item__quantity">
        <span className="bold credit-item__quantity-label">Quantité</span>
        {creditValidate !== true ? (
          <React.Fragment>
            <MoqQuantity
              quantity={quantity || 0}
              updateQuantity={updateQuantity}
              isMaxQuantity={isMaxQuantity}
              maxQuantity={maxQuantity}
              currentQuantity={currentQuantity}
              handleBlur={(e) => {
                e.target.name = 'quantity';
                handleBlur(e);
              }}
              disabled={disabled}
            />
            {errors && errors.quantity && <span className="error">{errors.quantity}</span>}
          </React.Fragment>
        ) : (
          <span>64</span>
        )}
      </div>

      <div className="credit-item__raison">
        <span className="bold credit-item__raison-label">Raison</span>
        {creditValidate !== true ? (
          <React.Fragment>
            <select
              name="reason"
              value={reason || ''}
              onChange={handleChange}
              onBlur={(e) => handleBlur(e)}
              disabled={disabled}
            >
              <option />
              <option value="Prix HT erroné">Prix HT erroné</option>
              <option value="Quantité erronée">Quantité erronée</option>
              <option value="Inversion produit">Inversion produit</option>
              <option value="Marchandise abîmée">Marchandise abîmée</option>
              <option value="DLV dépassée">DLV dépassée</option>
            </select>
            {errors && errors.reason && <span className="error">{errors.reason}</span>}
          </React.Fragment>
        ) : (
          <span>Prix HT erroné</span>
        )}
      </div>

      <div className="credit-item__comment">
        {creditValidate === false ? (
          <React.Fragment>
            <textarea
              name="comment"
              value={comment || ''}
              onChange={(e) => handleChange(e)}
              onBlur={(e) => handleBlur(e)}
              disabled={disabled}
            />
            {errors && errors.comment && <span className="error">{errors.comment}</span>}
          </React.Fragment>
        ) : (
          <div>
            <span className="bold credit-item__comment-label">Commentaire</span>
            <span>example of comment</span>
          </div>
        )}
      </div>

      {creditValidate !== true && (
        <div className="credit-item__checkbox">
          <label htmlFor="checkCrecdit" className="container">
            <input type="checkbox" readOnly checked={litigation === true ? 'checked' : ''} />
            <span className="checkmark" onClick={!disabled ? handleCheck : () => {}} />
          </label>
          <span className="credit-item__checked-label">
            Litiges vus avec le livreur et mentionnés sur le bon de réception
          </span>
        </div>
      )}
    </CreditItemWrapper>
  );
};

CreditItem.propTypes = {
  idLigne: PropTypes.string,
  addCredit: PropTypes.func,
  idProduct: PropTypes.number,
  idOrder: PropTypes.number,
  creditValue: PropTypes.object,
  creditValidate: PropTypes.bool,
  isMaxQuantity: PropTypes.bool,
  disabled: PropTypes.bool,
  orderItemId: PropTypes.number,
  maxQuantity: PropTypes.number,
  currentQuantity: PropTypes.number,
  creditList: PropTypes.array,
};

export default CreditItem;
