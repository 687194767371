import styled from 'styled-components';

const ModalSaveRecurrentWrapper = styled.div`
  .modal-body {
    form {
      display: flex;
      flex-direction: column;

      input,
      select {
        margin-bottom: 10px;
        padding: 3px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .modal-save-recurrent__week-input {
      width: 40px;
    }

    input[type='checkbox'] {
      width: 12px;
      min-width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-left: 0;
      appearance: checkbox;
    }

    .modal-save-recurrent__checkbox-title {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .modal-save-recurrent__checkbox-warning-about-quantity {
      margin-bottom: 15px;
      font-style: italic;
      color: red;
      font-size: 12px;
    }

    .modal-save-recurrent__checkbox-list {
      margin-bottom: 10px;
      .modal-save-recurrent__checkbox-wrapper {
        display: inline-block;
        width: 50%;
      }
    }
  }

  .disabled-text {
    color: #ccc;
  }

  .react-datepicker-popper {
    left: 0 !important;
    top: 25px !important;
  }
`;

export default ModalSaveRecurrentWrapper;
