export const clientConstants = {
  FETCH_REQUEST: 'CLIENT_FETCH_REQUEST',
  FETCH_SUCCESS: 'CLIENT_FETCH_SUCCESS',
  FETCH_FAILURE: 'CLIENT_FETCH_FAILURE',
  FETCH_DETAIL_REQUEST: 'CLIENT_FETCH_DETAIL_REQUEST',
  FETCH_DETAIL_SUCCESS: 'CLIENT_FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILURE: 'CLIENT_FETCH_DETAIL_FAILURE',
  FETCH_COUNT_REQUEST: 'CLIENT_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'CLIENT_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'CLIENT_COUNT_FETCH_FAILURE',
  UPDATE_STATUS_REQUEST: 'CLIENT_UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS: 'CLIENT_UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE: 'CLIENT_UPDATE_STATUS_FAILURE',
  UPDATE_EMAIL: 'CLIENT_UPDATE_EMAIL',
};

export const clientStatusConstants = {
  STATUS_ACTIVE: 'ACTIVE',
  STATUS_BLOCKED: 'BLOCKED',
  STATUS_INACTIVE: 'INACTIVE',
};
