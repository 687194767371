export const toastConstants = {
  SET_TOAST_LIST: 'TOAST_SET_TOAST_LIST',
  ADD_TOAST: 'TOAST_ADD_TOAST',
  REMOVE_TOAST: 'TOAST_REMOVE_TOAST',

  TYPE_SUCCESS: 'success',
  TYPE_ERROR: 'error',
  TYPE_WARNING: 'warning',
  TYPE_INFO: 'info',
};
