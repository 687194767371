import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const cartItemsService = {
  fetchTotalRowNumber,
  fetchItems,
  addItem,
  updateItem,
  deleteItem,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const { cart } = filter;
  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${config.apiUrl}/api/cart/${cart}/item/count?filter=${urlFilter}`,
    requestOptions
  ).then(handleResponse);
}

function fetchItems(filter, page, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const { cart } = filter;
  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${config.apiUrl}/api/cart/${cart}/item?filter=${urlFilter}&page=${page}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function addItem(cartId, itemId, quantity = 1, priceNegociated = 0) {
  const objCartItem = {
    quantity,
  };
  if (priceNegociated && priceNegociated > 0) {
    objCartItem.price = priceNegociated;
  }
  const body = JSON.stringify(objCartItem);
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/cart/${cartId}/item/add/${itemId}`, requestOptions).then(
    handleResponse
  );
}

function updateItem(cartItem) {
  const body = JSON.stringify(cartItem);
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cart/item/update`, requestOptions).then(handleResponse);
}

function deleteItem(cartItemId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cart/item/delete/${cartItemId}`, requestOptions).then(
    handleResponse
  );
}
