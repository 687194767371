import React from 'react';
import PropTypes from 'prop-types';
import { MoqQuantityDIV } from './MoqQuantity.style';

const QuantityHandler = ({
  quantity,
  handleMinus,
  handlePlus,
  handleChange,
  handleBlur,
  disabled,
}) => (
  <div>
    <span className="quantite-icon" onClick={handleMinus}>
      <i className="icon-less-circle" />
    </span>
    <input
      type="number"
      className="input-quantite-cadre input"
      placeholder="0"
      value={quantity || 0}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
    />
    <span className="quantite-icon" onClick={handlePlus}>
      <i className="icon-add-circle" />
    </span>
  </div>
);

class MoqQuantity extends React.Component {
  handleMinus = () => {
    const { quantity, updateQuantity, disabled } = this.props;
    if (!disabled) {
      updateQuantity(Math.max((Number(quantity) || 0) - 1, 0));
    }
  };

  handlePlus = () => {
    const { quantity, updateQuantity, isMaxQuantity, disabled } = this.props;
    if ((isMaxQuantity === undefined || isMaxQuantity === false) && !disabled) {
      updateQuantity((Number(quantity) || 0) + 1);
    }
  };

  handleChange = (e) => {
    const { updateQuantity, disabled } = this.props;
    const quantity = e.target.value;
    if (!disabled) {
      updateQuantity(Number(parseInt(quantity, 10)) || 0);
    }
  };

  render() {
    const { className, quantity, handleBlur, disabled, maxQuantity, currentQuantity } = this.props;
    return (
      <MoqQuantityDIV className={className}>
        <QuantityHandler
          quantity={quantity}
          handleMinus={this.handleMinus}
          handlePlus={this.handlePlus}
          handleChange={this.handleChange}
          handleBlur={handleBlur}
          maxQuantity={maxQuantity}
          currentQuantity={currentQuantity}
          disabled={disabled}
        />
      </MoqQuantityDIV>
    );
  }
}

QuantityHandler.propTypes = {
  quantity: PropTypes.number,
  handleMinus: PropTypes.func,
  handlePlus: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

MoqQuantity.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func,
  isMaxQuantity: PropTypes.bool,
  maxQuantity: PropTypes.number,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  currentQuantity: PropTypes.number,
};

export default MoqQuantity;
