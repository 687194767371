import React from 'react';
import PropTypes from 'prop-types';
import logo from 'styles/assets/images/White.png';
import Spinner from 'components/Core/Spinner/Spinner';
import WithLoadingStyles from './WithLoadingStyles';

const Loading = ({ className }) => (
  <div className={className}>
    <div className="loading__container fade-in-with-translate-y">
      <img className="loading__logo" src={logo} alt="logo in white" />
      <h6 className="loading__logo-text">Commande.com</h6>
      <span className="loading__span">Chargement ...</span>
      <div className="spinner__container">
        <Spinner />
      </div>
    </div>
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

export default WithLoadingStyles(Loading);
