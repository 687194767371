import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/Alert/Alert';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { history, getPath } from 'helpers';

function PasswordRecoveryForm(props) {
  const { className, error, username, handleChange, handleSubmit, handleEnter } = props;
  return (
    <div className={className}>
      <div className="password-recovery__background">
        <div className="password-all">
          <div className="password_recovery__form">
            <div>
              <h2 className="password-recovery__text-label regular">
                Veuillez saisir votre identifiant pour récupérer votre mot de passe{' '}
              </h2>
              <input
                className="password-confirm__label regular"
                type="text"
                name="username"
                value={username}
                placeholder="Votre identifiant"
                onChange={handleChange}
                onKeyDown={(e) => handleEnter(e)}
                required
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus
              />
              <div className="password-recovery__button-container">
                <ButtonPrimary
                  inverted
                  className="password-recovery__btn"
                  onClick={(e) => history.push(getPath('login'))}
                >
                  Retour
                </ButtonPrimary>
                <ButtonPrimary
                  disabled={username === ''}
                  className="password-recovery__btn"
                  onClick={(e) => handleSubmit(e)}
                >
                  {' '}
                  Envoyer{' '}
                </ButtonPrimary>
              </div>
            </div>
          </div>
          {error && error && (
            <div className="login__errors">
              <Alert text={error} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

PasswordRecoveryForm.propTypes = {
  className: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  username: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEnter: PropTypes.func.isRequired,
};

export default PasswordRecoveryForm;
