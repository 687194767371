import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectCommercialError = (state) => {
  const {
    commercial: { error },
  } = state;

  return error;
};

export const selectCommercialByFilter = (state, filter) => {
  const collections = get(state, 'commercial.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectCommercialCountByFilter = (state, filter) => {
  const collections = get(state, 'commercial.collectionsCount', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};
