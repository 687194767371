// Importing catalogConstants from the absolute path (`constants`) bugs.
import { manufacturerConstants } from '../constants';

export const manufacturerActions = {
  fetchRequest,
  fetchSuccess,
  fetchError,
  updateCollections,
};

function fetchRequest() {
  return {
    type: manufacturerConstants.FETCH_REQUEST,
  };
}

function fetchSuccess({ manufacturers }) {
  return {
    type: manufacturerConstants.FETCH_SUCCESS,
    manufacturers,
  };
}

function fetchError() {
  return {
    type: manufacturerConstants.FETCH_FAILURE,
  };
}

function updateCollections({ manufacturers, filter }) {
  return {
    type: manufacturerConstants.UPDATE_FOR_COLLECTION,
    manufacturers,
    filter,
  };
}
