import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import ProductCard from 'components/ProductCard';
import FilterTagBar from 'components/FilterTagBar/FilterTagBar';
import { selectScreenWidth } from 'selectors/ui';
import { size } from 'components/Core/Utils/Device';
import { aggridConstants } from 'constants/Aggrid.constants';
import { ProductCardWrapper, AgListWrapper } from './AggridListView.style';
import columnDefs from './columnDefinitions';
import localeText from '../locales';
import './AggridListView.scss';

const resizeGrid = (screenWidth, columnApi, gridApi) => {
  if (columnApi && gridApi) {
    columnApi.setColumnVisible('agListNameCell', true);
    columnApi.setColumnVisible('agListReferenceCell', true);
    columnApi.setColumnVisible('agListPriceCell', true);
    columnApi.setColumnVisible('favoriteCell', true);
    columnApi.setColumnVisible('agListQuantityCell', true);
    columnApi.setColumnVisible('tabletCell', false);
    columnApi.setColumnVisible('mobileCell', false);

    if (screenWidth <= size.laptopL) {
      columnApi.setColumnVisible('agListReferenceCell', false);
    }
    if (screenWidth <= size.laptop) {
      columnApi.setColumnVisible('tabletCell', true);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListReferenceCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.tabletR) {
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', true);
      columnApi.setColumnVisible('agListPriceCell', true);
    }
    if (screenWidth <= size.tablet) {
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.mobileL) {
      columnApi.setColumnVisible('mobileCell', true);
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
      columnApi.setColumnVisible('agListQuantityCell', false);
      columnApi.setColumnVisible('favoriteCell', false);
    }
    gridApi.sizeColumnsToFit();
  }
};

const AggridListView = ({
  rowData,
  onGridReady,
  onFirstDataRendered,
  onCellClicked,
  onFilterChanged,
  frameworkComponents,
  doesExternalFilterPass,
  isExternalFilterPresent,
  handleFilter,
  currentProduct,
  close,
  columnApi,
  gridApi,
  screenWidth,
  rowCount,
  navigateToNextCell,
  searchValue,
}) => {
  useEffect(() => {
    window.addEventListener('resize', resizeGrid(screenWidth, columnApi, gridApi));
  });

  const getRowHeight = (params) => {
    if (window.innerWidth <= size.mobileL) {
      return aggridConstants.LIST_MOBILE_ROW_HEIGHT;
    }

    return aggridConstants.LIST_ROW_HEIGHT;
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onColumnVisible = (params) => {
    params.api.resetRowHeights();
  };

  return (
    <div className="ag-list__container">
      <div className="ag-list__sub-container">
        <AgListWrapper className="ag-list__wrapper">
          <div className="AggridContainer ag-theme-custom-list">
            <FilterTagBar handleFilter={handleFilter} rowCount={rowCount} />
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection="single"
              onGridReady={onGridReady}
              suppressRowClickSelection
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={onCellClicked}
              onFilterChanged={onFilterChanged}
              localeText={localeText}
              defaultColDef={{ resizable: true }}
              frameworkComponents={frameworkComponents}
              pivotMode={false}
              tooltipShowDelay={0}
              loadingOverlayComponent="Spinner"
              doesExternalFilterPass={doesExternalFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              getRowHeight={getRowHeight}
              onColumnVisible={onColumnVisible}
              onColumnResized={onColumnResized}
              suppressContextMenu
              navigateToNextCell={navigateToNextCell}
              handleFilter={handleFilter}
              searchValue={searchValue}
            />
          </div>
        </AgListWrapper>
        {screenWidth > size.tabletR && (
          <div className="ag-list__product-card-container">
            <div className="ag-list__product-card-container__border">
              {currentProduct && (
                <ProductCardWrapper className="ag-list__product-card-wrapper">
                  <ProductCard
                    product={currentProduct}
                    close={close}
                    handleFilter={handleFilter}
                    searchValue={searchValue}
                    source="aggridListView"
                  />
                </ProductCardWrapper>
              )}
              {!currentProduct && (
                <div className="product-card__no-product">Aucun article séléctionné</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  screenWidth: selectScreenWidth(state),
});

AggridListView.propTypes = {
  rowData: PropTypes.array,
  onGridReady: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  onFilterChanged: PropTypes.func,
  frameworkComponents: PropTypes.object,
  doesExternalFilterPass: PropTypes.func,
  isExternalFilterPresent: PropTypes.func,
  handleFilter: PropTypes.func,
  currentProduct: PropTypes.object,
  close: PropTypes.func,
  gridApi: PropTypes.object,
  screenWidth: PropTypes.number,
  rowCount: PropTypes.number,
  navigateToNextCell: PropTypes.func,
  searchValue: PropTypes.string,
};

export default connect(mapStateToProps)(AggridListView);
