import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body {
    padding: 20px;
    text-align: center;
  }

  .modal-body__message {
    color: #01366b;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: inline-block;
    padding-bottom: 20px;
  }
  .modal-body__button {
    padding-top: 13px;
    padding-bottom: 10px;
    padding-left: 9px;
    padding-right: 9px;
    width: 119px;
  }
  .modal-body__button span {
    text-transform: uppercase;
    letter-spacing: 1.06px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
`;
