import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectToastList } from 'selectors/toast';
import Toast from 'components/Core/Toast/Toast';
import './ToastList.scss';

const ToastList = ({ toastList }) => {
  return (
    <>
      {toastList.length > 0 && (
        <div className="toast-wrapper">
          {toastList.map((item) => (
            <>{item.toasted === false && <Toast item={item} />}</>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  toastList: selectToastList(state),
});

export default connect(mapStateToProps, null)(ToastList);
