import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Favorite from 'components/ProductFavorite/ProductFavorite';

class ProductFavorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkIsFavorite = (productId) => {
    const { favoriteItems } = this.props;
    if (favoriteItems) {
      const favoriteIds = favoriteItems.map((favoriteItem) => favoriteItem.id);

      return favoriteIds.includes(productId);
    }

    return false;
  };

  render() {
    const { product, favoriteItems } = this.props;
    const productId = product.product.id;

    if (favoriteItems) {
      return <Favorite productId={productId} isFavorite={this.checkIsFavorite(productId)} />;
    }

    return '';
  }
}

const mapStateToProps = (state) => {
  const {
    product: {
      favorites: { items },
    },
  } = state;

  return { favoriteItems: items };
};

ProductFavorite.propTypes = {
  product: PropTypes.shape({
    product: { id: PropTypes.number },
  }),
  favoriteItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

export default connect(mapStateToProps, null)(ProductFavorite);
