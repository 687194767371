import styled from 'styled-components';

export const ClientListWrapper = styled.div`
  .page-client-list__header {
    padding: 20px;
  }

  .AggridContainer {
    height: calc((var(--vh, 1vh) * 100) - 220px);
    padding: 0 20px 20px 20px;

    .client-status-block {
      text-align: center;
    }

    .client-list__row-count {
      height: 28px;
      padding: 0 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: #eaedf3;
    }
  }
`;
