import { isEmail } from 'helpers/Auth';
import { store } from 'helpers';
import { toastActions } from 'actions/Toast';
import {
  clientActionCellRenderer,
  commercialFilter,
  isCatalogActive,
  isClientActive,
  isCatalogActiveCellRenderer,
  isClientLockCellRenderer,
  isPreorderActive,
  isPreorderActiveCellRenderer,
  planningFilter,
  termsValueGetter,
} from './cellRenderer';

const columnDefs = [
  {
    colId: 'clientLocked',
    headerName: '',
    field: 'locked',
    minWidth: 30,
    maxWidth: 30,
    resizable: false,
    cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    cellRendererFramework: isClientLockCellRenderer,
  },
  {
    colId: 'clientCode',
    headerName: 'Code',
    field: 'ext_code',
    width: 50,
    valueFormatter: (params) => {
      return params.value || '-';
    },
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    suppressFiltersToolPanel: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    colId: 'clientStatus',
    headerName: 'Status',
    field: 'status',
    width: 50,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Actif', 'Inactif'],
      suppressMiniFilter: true,
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
    floatingFilter: true,
    valueGetter: isClientActive,
  },
  {
    colId: 'clientName',
    headerName: 'Nom',
    field: 'name',
    width: 100,
    valueFormatter: (params) => {
      return params.value || '-';
    },
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: {
      filterOptions: ['contains'],
    },
    suppressFiltersToolPanel: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    colId: 'clientSign',
    headerName: 'Enseigne',
    field: 'brand.name',
    width: 100,
    valueFormatter: (params) => {
      return params.value || '-';
    },
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'clientMail',
    headerName: 'Email',
    field: 'customers',
    width: 100,
    valueGetter: (params) => (params.data && params.data.customers[0].email.toLowerCase()) || '-',
    valueSetter: (params) => {
      if (!isEmail(params.newValue) && params.newValue !== null) {
        store.dispatch(
          toastActions.addToast({
            type: 'error',
            title: '',
            message: 'Veuillez renseigner un email valide',
          })
        );
        return false;
      }
      // eslint-disable-next-line no-param-reassign
      params.data.customers[0].email = params.newValue;

      return true;
    },
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    suppressFiltersToolPanel: true,
    editable: true,
    cellStyle: { cursor: 'text', textTransform: 'uppercase' },
  },
  {
    colId: 'clientGroup',
    headerName: 'Groupe',
    field: 'group.code',
    width: 55,
    valueFormatter: (params) => {
      return params.value || '-';
    },
    sortable: false,
    filter: 'agTextColumnFilter',
    floatingFilter: false,
    suppressFiltersToolPanel: true,
  },
  {
    colId: 'commercial',
    headerName: 'Commerciaux',
    field: 'commercials',
    sortable: false,
    hide: true,
    filter: false,
    valueGetter: commercialFilter,
    floatingFilter: true,
  },
  {
    colId: 'planning',
    headerName: 'Plannings',
    field: 'delivery_plannings',
    sortable: true,
    hide: true,
    filter: false,
    valueGetter: planningFilter,
    floatingFilter: false,
  },
  /* {
    colId: 'cgv',
    headerName: 'CGV',
    field: 'terms',
    sortable: true,
    hide: true,
    filter: false,
    valueGetter: termsValueGetter,
    floatingFilter: false,
  }, */
  {
    colId: 'catalogStatus',
    headerName: 'Permanent',
    field: 'client_status',
    width: 70,
    filter: false,
    sortable: false,
    valueGetter: isCatalogActive,
    cellRendererFramework: isCatalogActiveCellRenderer,
    floatingFilter: false,
  },
  {
    colId: 'preorderStatus',
    headerName: 'Précommande',
    field: 'client_status',
    width: 80,
    filter: false,
    sortable: false,
    valueGetter: isPreorderActive,
    cellRendererFramework: isPreorderActiveCellRenderer,
    floatingFilter: false,
  },
  {
    colId: 'clientAction',
    headerName: '',
    field: null,
    width: 90,
    cellStyle: { textAlign: 'center' },
    cellRendererFramework: clientActionCellRenderer,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
  },
];

export const defaultColDef = {
  resizable: true,
  floatingFilterComponentParams: { suppressFilterButton: false },
  cellStyle: { cursor: 'pointer' },
  enableRowGroup: false,
  suppressMenu: true,
};

export default columnDefs;
