const sideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Colonnes',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressFilterSearch: true,
      },
    },
  ],
  position: 'right',
};

export default sideBarDef;
