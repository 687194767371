// Importing userConstants from the absolute path (`constants`) bugs.
import { userConstants } from '../constants';

const initialState = {
  information: {},
  alert: {},
  isLoading: false,
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.FETCH_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.FETCH_INFORMATION_SUCCESS:
      return {
        ...state,
        information: action.user,
        isLoading: false,
      };
    case userConstants.FETCH_INFORMATION_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case userConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isSubmittingNewPassword: true,
        hasUpdatedPassword: false,
      };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmittingNewPassword: false,
        hasUpdatedPassword: true,
        alert: {
          message: action.message,
          type: 'success',
        },
      };
    case userConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmittingNewPassword: false,
        hasUpdatedPassword: false,
        alert: {
          message: action.message,
          type: 'error',
        },
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.CHANGE_DASHBOARD_SUCCESS:
      return {
        ...state,
        information: { ...state.information, ...action.user },
        isLoading: false,
      };
    case userConstants.CHANGE_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
