import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

const PromotionTimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .promotion-timer__label {
    background-color: #fcea37;
    padding: 5px;
    border-radius: 5px;
  }
`;

const PromotionTimer = ({ startDate, endDate }) => {
  const isFuture = useMemo(() => new Date(startDate) > new Date(), [startDate, endDate]);
  const countDownDate = useMemo(
    () => new Date(new Date() > new Date(startDate) ? endDate : startDate).getTime(),
    [startDate, endDate]
  );
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const getReturnValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}j ${hours}h ${minutes}m`;
  };

  return (
    <PromotionTimerWrapper>
      {isFuture && <div>Commence dans :</div>}
      {!isFuture && <div>Se termine dans :</div>}
      <div className="promotion-timer__label">{getReturnValues(countDown)}</div>
    </PromotionTimerWrapper>
  );
};

export default PromotionTimer;
