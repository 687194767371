import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userConstants } from 'constants/User.constants';
import Icon from 'components/Icon/Icon';
import AutoSuggestion from './AutoSuggestion';

class ClassicSearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      isSearching: false,
      isGoingToSearch: false,
      selectedItem: null,
    };
    this.timer = null;
    this.inputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleIconClick() {
    const { current: inputDom } = this.inputRef;
    const { searchValue, isGoingToSearch } = this.state;
    if (searchValue || isGoingToSearch) {
      this.setState({ searchValue: '', isGoingToSearch: false });
      this.handleBlur();
    } else {
      this.setState({ isGoingToSearch: true });
      inputDom.focus();
    }
  }

  handleBlur() {
    this.setState({ isSearching: false });
  }

  handleFocus() {
    this.setState({ selectedItem: null, isSearching: true });
  }

  handleChange(e) {
    const { value } = e.currentTarget;
    const {
      user: { username },
    } = this.props;
    this.setState({
      searchValue: value !== username ? value : '',
      isSearching: true,
    });

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.handleBlur, 1000);
  }

  render() {
    const { user, handleEnter, handleItemClick, shouldRedirect } = this.props;
    const { searchValue, isSearching, isGoingToSearch, selectedItem } = this.state;
    const { current: inputDom } = this.inputRef;
    const autoSuggestionWidth = (inputDom || {}).offsetWidth;

    return (
      <>
        <span
          className={`header--search-icon ${isGoingToSearch ? 'responsive' : ''}`}
          onClick={() => this.handleIconClick()}
        >
          <Icon content={searchValue || isGoingToSearch ? '\\e915' : '\\e93e'} />
        </span>
        <div className={`header--search-input ${isGoingToSearch ? 'responsive' : ''}`}>
          <input
            type="text"
            placeholder={`Rechercher ${
              user.type === userConstants.TYPE_COMMERCIAL || user.type === userConstants.TYPE_ADMIN
                ? 'un client'
                : 'un produit'
            }`}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13 && handleEnter) {
                this.setState(() => ({ isGoingToSearch: false }));
                handleEnter(searchValue);
              }
            }}
            value={selectedItem || searchValue}
            className={`search-box ${isGoingToSearch ? 'responsive' : ''}`}
            ref={this.inputRef}
          />
          <AutoSuggestion
            searchValue={searchValue}
            isSearching={isSearching}
            width={autoSuggestionWidth}
            handleItemClick={(item) => {
              this.setState({ selectedItem: item.data.product.name });
              if (handleItemClick) {
                handleItemClick(item);
              } else {
                this.setState({ isGoingToSearch: false, isSearching: false, searchValue: '' });
              }
            }}
            shouldRedirect={shouldRedirect}
          />
        </div>
      </>
    );
  }
}

ClassicSearchBar.propTypes = {
  user: PropTypes.shape({ type: PropTypes.string }),
  handleEnter: PropTypes.func,
  handleItemClick: PropTypes.func,
  shouldRedirect: PropTypes.bool,
};

ClassicSearchBar.defaultProps = {
  shouldRedirect: true,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user: user.information,
  };
};

export default connect(mapStateToProps)(ClassicSearchBar);
