import { saveFile } from 'helpers';
import { invoiceService } from 'services';
import { invoiceConstants } from '../constants';

export const invoiceActions = {
  fetchInvoice,
  fetchTotalRowsNumber,
  fetchInvoiceOrder,
  downloadInvoiceFormPdf,
};

function fetchInvoice(invoiceId) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.fetchInvoice(invoiceId).then(
      (invoice) => {
        dispatch(success(invoice));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.FETCH_ONE_REQUEST };
  }
  function success(invoice) {
    return {
      type: invoiceConstants.FETCH_ONE_SUCCESS,
      invoice,
    };
  }
  function failure(error) {
    return { type: invoiceConstants.FETCH_ONE_FAILURE, error };
  }
}

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    invoiceService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: invoiceConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: invoiceConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchInvoiceOrder(filter, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.fetchInvoiceOrder(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: invoiceConstants.FETCH_SUCCESS, items, filter };
  }
  function failure(error) {
    return { type: invoiceConstants.FETCH_FAILURE, error };
  }
}

function downloadInvoiceFormPdf(invoice) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.downloadInvoiceFormPdf(invoice.id).then(
      (invoiceFormBlob) => {
        dispatch(success());
        const fileName = `FAC-${invoice.number}.pdf`;
        saveFile(fileName, invoiceFormBlob);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: invoiceConstants.DOWNLOAD_INVOICE_FORM_REQUEST, invoice };
  }
  function success() {
    return { type: invoiceConstants.DOWNLOAD_INVOICE_FORM_SUCCESS };
  }
  function failure(error) {
    return { type: invoiceConstants.DOWNLOAD_INVOICE_FORM_FAILURE, error };
  }
}
