import React from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash.findindex';
import { connect } from 'react-redux';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import { modalActions, dayOffActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ModalNewDayOffStyle from './ModalNewDayOff.style';
import 'react-datepicker/dist/react-datepicker.css';

class ModalNewDayOff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayOff: '',
    };
  }

  handleSubmit = () => {
    const { closeModal, addDayoff, selectedPlatform } = this.props;
    const { dayOff } = this.state;
    const formaldate = Moment(dayOff).format('YYYY-MM-DD');
    addDayoff(selectedPlatform, formaldate);
    closeModal();
  };

  updateState = (date) => {
    this.setState({
      dayOff: Moment(date).format('YYYY-MM-DD'),
    });
  };

  render() {
    const { dayOffs, className } = this.props;
    const { dayOff } = this.state;

    const allDayOff = (date) =>
      findIndex(dayOffs, (item) => item.date === Moment(date).format('YYYY-MM-DD')) < 0;

    return (
      <div className={className}>
        <div className="day-off-calendar">
          <div className="day-off-calendar__valide">
            <DatePicker
              inline
              selected={dayOff ? Moment(dayOff).toDate() : ''}
              onChange={(date) => {
                this.updateState(date);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Du"
              locale="fr"
              filterDate={allDayOff}
            />
          </div>
          <div className="day-off__info">
            <ButtonPrimary className="day-off__valid" onClick={() => this.handleSubmit()}>
              <span>Valider</span>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}

ModalNewDayOff.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  addDayoff: PropTypes.func,
  selectedPlatform: PropTypes.number,
  dayOffs: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  const selectedPlatform = platform.selectedId;

  return {
    platform,
    selectedPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  addDayoff: (idPlatform, dayOff) => dispatch(dayOffActions.createDayOff(idPlatform, dayOff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNewDayOffStyle(ModalNewDayOff));
