import React from 'react';
import PropTypes from 'prop-types';
import withStyle from './ProgressBar.style';

const ProgressBar = ({ className }) => (
  <div className={className}>
    <div className="progress-bar">
      <div className="progress-bar-fill" />
    </div>
  </div>
);

ProgressBar.propTypes = {
  className: PropTypes.string,
};

export default withStyle(ProgressBar);
