import styled from 'styled-components';

const ModalSubstitutWrapper = styled.div`
  .modal-substitution__title {
    font-size: 24px;
  }

  .modal-substitut__container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 20px 0;
    max-height: 350px;
    overflow: auto;

    .product-thumbnail {
      margin-bottom: 15px;
    }
  }

  .product-thumbnail {
    width: 225px;
    height: 290px;
    border: 1px solid #cccccc;
    position: relative;
    overflow: hidden;

    h3 {
      height: 65px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    img {
      display: block;
      width: 100%;
      height: 225px;
      object-fit: cover;
    }

    figcaption {
      width: 100%;
      height: 225px;
      padding: 15px;
      position: absolute;
      bottom: 0;
      transform: translateY(calc(100%));
      transition: transform 0.1s linear;
      background: rgba(255, 255, 255, 0.85);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      table {
        white-space: nowrap;
        width: 100%;
        tr {
          width: 100%;
        }
      }

      .product-thumbnail__product-quantity {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      figcaption {
        top: 0;
        bottom: auto;
        transform: translateY(0);
      }
    }
  }
`;

export default ModalSubstitutWrapper;
