import styled from 'styled-components';

export default (Component) => styled(Component)`
  padding: 22px;
  background: #f5f5f5;
  .page-planning__section-label {
    padding-left: 26px;
    padding-right: 26px;
    span {
      color: #01366b;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .planning__table .row {
    margin-bottom: 20px;
    cursor: pointer;
  }
  .actions {
    text-align: right;
    padding: 11px 0;

    .planning {
      border-radius: 4.5px;
      background-color: #01366b;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      padding: 11px 18px;

      i {
        font-size: 20px;
        padding-right: 5px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  .sections {
    padding-bottom: 10px;
    .headline-temperature {
      color: #01366b;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.12px;
      line-height: 19px;
    }
  }

  .table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    margin-bottom: 40px;
    border: none;
    tr {
      th {
        background: #dbdee7;
        border-right: 1px solid #fff;
        color: #01366b;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 7px 20px;
        &.planning__name {
          width: 33%;
        }
        &.planning__date_s {
          width: 17%;
        }
        &.planning__date_e {
          width: 17%;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    .planning__type {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      margin-right: 10px;
      padding-bottom: 9px;
    }
    .planning__statut {
      padding: 4px 17px;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      border-radius: 4px;
      text-transform: uppercase;
      color: #fff;
      &.default {
        background-color: #cecece;
      }
      &.substitution {
        background: #f5a623;
      }
    }
    .planning__date_start,
    .planning__date_end {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
    }
    .planning__days {
      li {
        display: inline-block;
        list-style: none;
        border: 1px solid #a4a4a4;
        width: 20px;
        height: 20px;
        text-align: center;
        margin-right: 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        text-transform: uppercase;
        color: #a4a4a4;

        &.active {
          color: #fff;
          border-color: #01366b;
          background: #01366b;
        }
      }
    }
    .row {
      box-shadow: 0px 0px 0px 0px #f5f5f5;
      &.unclickable {
        cursor: not-allowed;
        opacity: 0.6;
      }
      &.parent {
        td {
          border-top: 27px solid #f5f5f5;
        }
      }
      &.parent:first-child {
        td {
          border-top: none;
        }
      }
      background: #fff;
      td {
        border-bottom: 1px solid #cecece;
        padding: 17px 20px;
        position: relative;
        &.first-td {
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background: #01366b;
          }
        }
        &:first-child {
          padding: 17px 20px;
        }
      }
      td.actions {
        text-align: center;
        .edit {
          padding: 7px;
          cursor: pointer;
          font-weight: bold;
          color: #01366b;
          border-radius: 4.5px;
          background: #ededed;
          font-size: 12px;
        }
      }
    }
    tr.hidden {
      visibility: collapse;
    }
  }
  .FROID {
    td.first-td {
      &:after {
        background: #63c8ff !important;
      }
    }
  }
  .POSITIF {
    td.first-td {
      &:after {
        background: #35e687 !important;
      }
    }
  }
  .SEC {
    td.first-td {
      &:after {
        background: #eddd0c !important;
      }
    }
  }
  /**************Responsive*********************/
  @media screen and (width: 1024px) {
    .table {
      tr {
        th.planning__name {
          width: auto;
        }
      }
    }
  }
  @media screen and (width: 768px) {
    .table {
      tr {
        th.planning__date_s,
        th.planning__date_e {
          width: auto;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 544px) {
    .table {
      tr,
      td {
        display: block;
        th {
          display: none;
        }
      }
      td {
        padding: 0;
        &:before {
          content: attr(data-label);
          color: #787878;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          background: #e2e2e2;
          display: block;
          padding: 7px 20px;
          border-bottom: 5px solid #f5f5f5;
        }
        &.row__default {
          &:after {
            content: '';
            bottom: 0 !important;
            height: calc(100% - 37px) !important;
            top: initial !important;
          }
        }
      }
      .row {
        td {
          &:first-child {
            padding: 0 0 20px 0;
          }

          p {
            padding: 7px 0 20px 23px;
            display: block;
            line-height: 20px;
            margin: 0;
          }
        }
      }
      .planning__type {
        padding-bottom: 10px;
        padding-top: 21px;
        padding-left: 20px;
      }
      .planning__statut {
        margin: 0 20px;
      }
      .planning__days {
        padding-left: 20px;
        margin: 5px 0;
        padding-bottom: 4px;
      }
    }
    .sections {
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 320px) {
    .actions {
      text-align: center;
    }
  }
`;
