import styled, { keyframes } from 'styled-components';
import { toastConstants } from 'constants/Toast.constants';

const fadeIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const ToastWrapper = styled.div`
  width: 300px;
  min-height: 50px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 2px;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;

  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.2s linear;
  transition: transform 0.075s linear, visibility 0.2s linear;

  ${(props) =>
    props.type === toastConstants.TYPE_SUCCESS &&
    'background: #d4edda;color: #155724;border: 1px solid #c3e6cb;'}
  ${(props) =>
    props.type === toastConstants.TYPE_INFO &&
    'background: #cce5ff;color: #004085;border: 1px solid #b8daff;'}
  ${(props) =>
    props.type === toastConstants.TYPE_WARNING &&
    'background: #fff3cd;color: #856404;border: 1px solid #ffeeba;'}
  ${(props) =>
    props.type === toastConstants.TYPE_ERROR &&
    'background: #f8d7da;color: #721c24;border: 1px solid #f5c6cb;'}

  &:hover {
    transform: scale(1.03);
  }

  .toast-wrapper__title {
    margin-bottom: 5px;
    font-weight: 700;
  }
`;

export default ToastWrapper;
