import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import { numberToPrice, getIconClassByType } from 'helpers';
import {
  getProductShortUnit,
  getPcbUnit,
  getProductImageFromEan,
  getProductType,
} from 'helpers/Product';
import { productConstants } from 'constants/Product.constants';
import ProgressBar from 'components/Core/ProgressBar';
import withModalContainerDetailStyle from './ModalContainerDetail.style';

const ModalContainerDetail = ({ className, containerDetail }) => {
  const { items: productList } = containerDetail;
  const nbRefs = productList.length;

  let nbColisTotal = 0;
  let totalHt = 0;
  productList.forEach((product) => {
    nbColisTotal += product.qty;
    totalHt += product.price * product.pcb * product.qty;
  });

  return (
    <div className={className}>
      <div className="modal-body">
        <div className="modal-body__text-block">
          <div className="modal-body-head">
            <div className="container-name">
              Conteneur {containerDetail.index + 1}
              <br />
              {containerDetail.containerName}
              <br />
              {containerDetail.mode}
            </div>
            <div className="gauge">
              <div className="gauge-bar">
                <div className="gauge-element gauge-bar-volume">
                  <ProgressBar
                    className="progress-bar"
                    percent={(containerDetail.volume * 100) / containerDetail.maxVolume}
                  />
                  <div className="gauge-title gauge-title-volume">
                    <span>
                      Volume : {`${containerDetail.volume} m3 / ${containerDetail.maxVolume} m3`}
                    </span>
                  </div>
                </div>
                <div className="gauge-element gauge-bar-weight">
                  <ProgressBar
                    className="progress-bar"
                    percent={(containerDetail.weight * 100) / containerDetail.maxWeight}
                  />
                  <div className="gauge-title gauge-title-weight">
                    <span>
                      Poids : {`${containerDetail.weight} Kg / ${containerDetail.maxWeight} kg`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="message">
            <span>
              Dans cette visualisation, vous n'avez pas la possibilité de modifier le contenu du
              conteneur
            </span>
          </div>
          <div className="references-head">
            <div className="references-colis">
              {nbRefs} Réferences | {nbColisTotal} Colis
            </div>
            <div className="total-ht">Total : {numberToPrice(Number(totalHt))} HT</div>
          </div>
          <div className="references-list">
            {(productList || []).map((product, i) => (
              <div className="item-product">
                <div className="icon-container">
                  <i className={getIconClassByType(product.type)} />
                </div>
                <div className="photo">
                  <img
                    src={getProductImageFromEan(product.ean, productConstants.IMAGE_SIZE_SMALL)}
                    alt={product.name}
                  />
                </div>
                <div className="product-title">{product.name}</div>
                <div className="ref-ean-dlv">
                  <ul>
                    <li>Réf: {product.reference}</li>
                    <li>EAN: {product.ean}</li>
                    <li>DLV: {product.dlv}</li>
                  </ul>
                </div>
                <div className="tarif">
                  <ul>
                    <li>{numberToPrice(Number(product.price))} HT / unité</li>
                    <li>{numberToPrice(Number(product.price * product.pcb))} HT / colis</li>
                    <li>PCB: {product.pcb} unités</li>
                  </ul>
                </div>
                <div className="nb-colis">
                  <span>{product.qty} colis</span>
                </div>
                <div className="ref-ht">
                  {numberToPrice(Number(product.price * product.pcb * product.qty))} HT
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ModalContainerDetail.propTypes = {
  className: PropTypes.string,
  containerDetail: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    user: { information },
    container,
  } = state;
  return {
    user: information,
    container,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withModalContainerDetailStyle(ModalContainerDetail));
