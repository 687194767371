import styled from 'styled-components';

export const NotificationCenterWrapper = styled.div`
  .notification-center__icon-container {
    width: 40px;
    height: 40px;
    background-color: #F0F2F5;
    border-radius: 100%;
    color: #01366b;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .notification-center__notif-counter {
      width: 17px;
      height: 17px;
      position: absolute;
      right: 0;
      top: 0;
      background: red;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      font-size: 12px;
    }

    svg {
      height: 18px;
      width: 18px
      display: block;
    }
  }

  .dd-panel-wrapper {
    left: unset;
    right: -40px;

    .dd-list {
      width: 300px;
      margin-top: 5px;
      max-height: calc((var(--vh, 1vh) * 100) - 145px);
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
    
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }  

      .notification-center__title {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
      }

      .notification-center__list-container {
        list-style: none;
        padding: 0;
        margin: 0;

        .notification-center__expired {
          color: #BBB;
        }
        
        li {
          padding: 10px;
          position: relative;

          .notification-center__platform {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .notification-center__sticker-container {
              display: flex;

              .notification-center__sticker {
                width: 15px;
                height: 15px;
                margin-left: 5px;
                background: #cce5ff;
                border-radius: 100%;
              }
            }
          }
          
          .notification-center__subject {
            font-weight: 700;
            padding: 3px 0;
          }

          &:hover {
            background: #F0F2F5;
          }
        }
      }

      .notification-center__no-message__container {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #A4A4A4;
      }
    }
  }
`;
