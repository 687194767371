/* eslint-disable no-param-reassign */

import { deliveryPlanningConstants } from '../constants';

const initState = {
  items: [],
  isLoading: true,
};

export function deliveryPlanning(state = initState, action) {
  if (
    !Object.keys(deliveryPlanningConstants).find(
      (key) => deliveryPlanningConstants[key] === action.type
    )
  ) {
    return state;
  }

  let { items } = state;
  let newItems = [...items];

  switch (action.type) {
    case deliveryPlanningConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deliveryPlanningConstants.FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
      };
    case deliveryPlanningConstants.FETCH_FAILURE:
      return state;
    case deliveryPlanningConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case deliveryPlanningConstants.UPDATE_SUCCESS:
      if (!action.item.parent_id) {
        newItems = newItems.filter((item) => (item.uid ? item.uid !== action.item.uid : true));
        newItems = newItems.map((item) =>
          item.children.length
            ? {
                ...item,
                children: item.children.filter((child) => child.id !== action.item.id),
              }
            : item
        );
        if (newItems.some((item) => item.id === action.item.id)) {
          newItems = newItems.map((item) => (item.id === action.item.id ? action.item : item));
        } else {
          newItems.push(action.item);
        }
      } else {
        const parent = newItems.find((item) => item.id === action.item.parent_id);
        if (parent && parent.children) {
          newItems = newItems.filter((item) => (item.id !== action.item.id: true));
          parent.children = parent.children.filter((item) =>
            item.uid ? item.uid !== action.item.uid : true
          );
          if (parent.children.some((child) => child.id === action.item.id)) {
            parent.children = parent.children.map((child) =>
              child.id === action.item.id ? action.item : child
            );
          } else {
            parent.children.push(action.item);
          }
        }
      }
      return {
        ...state,
        items: newItems,
        isLoading: false,
      };
    case deliveryPlanningConstants.UPDATE_FAILURE:
      return state;
    case deliveryPlanningConstants.DELETE_REQUEST:
      items = items.filter((itm) => {
        itm.children =
          itm.children &&
          itm.children.filter((child) => Number(child.id) !== Number(action.item.id));
        return Number(itm.id) !== Number(action.item.id);
      });

      return {
        ...state,
        items,
        isLoading: true,
      };
    case deliveryPlanningConstants.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case deliveryPlanningConstants.DELETE_FAILURE:
      return state;
    default:
      return state;
  }
}
