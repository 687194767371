import React from 'react';
import styled from 'styled-components';
import { getIconClassByType } from 'helpers';

const ProductBlocTD = styled.td`
  color: #787878;
  white-space : normal;
  padding: 5px 0;
  i {
    font-size: 11px;
    margin-right: 5px;
    &::before {
      height: 22px;
      width: 22px;
      line-height: 22px;
    }
  }
  div {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .boldgry {
    color: #4A4A4A
    font-size: 14px;
    font-weight: 700;
  }
  .bold {
    color: #01366B;
    font-size: 14px;
    font-weight: 700;
  }
  
  .small{
    font-size: 12px;
    color: #787878;
    font-weight: 500;
  }

  .delete {
    text-decoration:line-through;
    color: red;
    span {
      color: #787878;
    }
  }
`;

const ProductBlocDiv = styled.div`
  // Override the width specified in the cell class name
  width: unset!important;
  
  color: #787878;
  white-space : normal;
  display: block;
  float: ${(props) => props.float || 'unset'}
 
  .bold {
    color: #01366B;
    font-size: 14px;
    font-weight: 700;
  }
  .boldgry {
    color: #4A4A4A
    font-size: 14px;
    font-weight: 700;
  }
  .small{
    font-size: 12px;
    color: #787878;
    font-weight: 500;
  }

  .delete {
    text-decoration:line-through;
    color: red;
    span {
      color: #787878;
    }
  }
`;

class ProductBloc extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { textBlocs, text } = this.props;
    const { textBlocs: nextTextBlocs, text: nextText } = nextProps;
    const blocs = textBlocs || [{ content: text }];
    const changeData = blocs.map(({ content }) => content).toString();
    const nextBlocs = nextTextBlocs || [{ content: nextText }];
    const nextChangeData = nextBlocs.map(({ content }) => content).toString();
    return !(nextChangeData === changeData);
  }

  render() {
    const { textBlocs, text, style, float, className, shouldShowResponsive, ...rest } = this.props;
    const blocs = textBlocs || [{ content: text }];
    const {
      isBold: isStyleBold,
      isBoldGray: isStyleBoldGray,
      isSmall: isStyleSmall,
      isDeleted: isStyleDeleted,
      iconType: isStyleIconType,
    } = style;

    const textNodes = blocs.map((bloc, index) => {
      const { content, isBold, isSmall, isDeleted, iconType, isBoldGray } = bloc;

      let iconTemperature;

      let spanClass = '';
      if (isBoldGray || isStyleBoldGray) {
        spanClass = 'boldgry';
      } else if (isBold || isStyleBold) {
        spanClass = 'bold';
      } else if (isSmall || isStyleSmall) {
        spanClass = 'small';
      }
      if (isDeleted || isStyleDeleted) {
        spanClass = 'delete';
      }
      if (iconType || isStyleIconType) {
        iconTemperature = getIconClassByType(iconType || isStyleIconType);
      }
      return (
        <div key={`${index}`} className={spanClass}>
          {iconType && <i className={iconTemperature} />}
          <span>{content}</span>
        </div>
      );
    });
    return !shouldShowResponsive ? (
      <ProductBlocTD className={className} {...rest}>
        {textNodes}
      </ProductBlocTD>
    ) : (
      <ProductBlocDiv className={className} float={float} {...rest}>
        {textNodes}
      </ProductBlocDiv>
    );
  }
}

ProductBloc.defaultProps = {
  style: {},
};

export default ProductBloc;
