import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  height: 100%;
  display: inline-flex;

  .product-number {
    min-width: 18px;
    position: relative;
    bottom: 5px;
    margin: auto 12px;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 9px;
    font-weight: 600;
    font-style: normal;
    &.red {
      background-color: #d0021b;
    }
    &.blue {
      background-color: #01366b;
    }
  }
  .menu__column {
    animation-name: open;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    transform: translateX(-10%);
    overflow: auto;
    background: #fff;
    width: 240px;
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(74, 74, 74, 0.1);
    border-right: 1px solid #f5f5f5;

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
    }
    ::-webkit-scrollbar-thumb {
      background: #01366b;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #0fa3f0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 0;
    }

    .responsive-info {
      display: none;

      padding-bottom: 10px;
      border-bottom: 1px solid #f5f5f5;

      .side-nav__link {
        padding: 10px 20px;
        border-bottom: 1px solid #f5f5f5;
        span {
          text-transform: uppercase;
          margin-left: 10px;
          font-weight: 600;
        }
      }

      .parent-info {
        margin-top: 10px;

        span {
          display: block;
          text-transform: uppercase;
          color: #00a6ff;
          font-size: 14px;
          padding: 8px 20px;
          margin-top: 10px;
          margin-right: auto;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          text-align: left;
          word-break: break-word;
        }
      }
    }

    .see-all {
      button {
        text-transform: uppercase;
        span {
          font-size: 14px;
          color: #787878;
        }
      }
      border-bottom: 1px solid #f5f5f5;
      margin-bottom: 0;
    }

    .nested-link {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #a4a4a4;
    }

    .middle-link,
    .assortment-link,
    .end-link {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
    }

    .side-nav__link {
      text-align: start;
      align-items: center;
      position: relative;
      z-index: 10;
      font-size: 14px;
      border: none;
      padding: 8px 10px;
      width: 100%;
      background: transparent;
      transition: all 0.3s ease;
      cursor: pointer;
      color: #4a4a4a;
      display: flex;
      justify-content: normal;
      align-items: center;
    }

    .side-nav__link .side-nav__name {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      display: inline-block;
      width: 52%;
      text-transform: capitalize;
      word-wrap: break-word;
    }

    .side-nav__icon {
      padding-top: 3px;
      font-size: 14px;
      &.assortment {
        color: #eddd0c;
        vertical-align: top;
      }
      &.arrow {
        padding: 0;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
      display: inline-block;
    }

    .side-nav__icon:not(:last-child) {
      left: 15px;
      margin-right: 10px;
      font-size: 19px;
    }

    .side-nav__link--active,
    .side-nav__link:hover {
      background-color: #f6f6f6;
      color: #0fa3f0;
      padding-left: 15px;
      i {
        color: #0fa3f0;
      }
      .product-number {
        color: #fff;
      }
    }

    .side-nav__link:hover:before,
    .side-nav__link--active:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: -1;
      width: 4px;
      background-color: #0fa3f0;
    }

    .menu__item,
    .menu__heading {
      border: none;
      background: #fff;
      width: 100%;
      text-decoration: none;
      color: inherit;
      height: 50px;
      text-transform: uppercase;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      transition: all 0.5s;
      font-size: 12px;
      padding: 0 30px 0px 25px;
      span,
      i {
        margin: auto 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
      }
    }
    .gray {
      background: gray;
    }
    .border-bottom {
      border-bottom: 1px solid gray;
    }
    a {
      color: gray;
    }

    .menu__item {
      text-align: left;
      height: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: pointer;
    }

    .trail {
      span {
        transform: translateX(20px);
      }
      .menu__icon,
      span {
        color: red;
      }
    }

    @media ${screen.laptop} {
      width: 200px;
      .product-number {
        font-size: 6px;
        margin: auto 9px;
      }
    }

    @media ${screen.tablet} {
      width: 100%;
      .product-number {
        font-size: 9px;
        margin: auto 12px;
      }
      .responsive-info {
        display: block;
      }
    }
  }
`;
