import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from 'actions';
import PasswordRecoveryForm from 'components/Auth/PasswordRecovery/PasswordRecoveryForm';
import PasswordRecoverySuccessful from 'components/Auth/PasswordRecovery/PasswordRecoverySuccessful';

class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ username: value });
  }

  handleEnter(e) {
    if (e.which === 13) {
      this.handleSubmit(e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username } = this.state;
    const { userRecoverPassword } = this.props;
    if (username) {
      userRecoverPassword(username);
    }
  }

  render() {
    const { username } = this.state;
    const { className, isRecoverySuccessful, error } = this.props;

    return isRecoverySuccessful ? (
      <PasswordRecoverySuccessful className={className} />
    ) : (
      <PasswordRecoveryForm
        className={className}
        username={username}
        error={error}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleEnter={this.handleEnter}
      />
    );
  }
}

function mapStateToProps(state) {
  const { error, isRecoverySuccessful } = state.authentication;
  return {
    isRecoverySuccessful,
    error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  userRecoverPassword: (username) => {
    dispatch(userActions.recoverPassword(username));
  },
});

PasswordRecovery.propTypes = {
  className: PropTypes.string,
  isRecoverySuccessful: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  userRecoverPassword: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
