import { messageService } from 'services';
import { messageConstants } from '../constants';
import { loadingActions } from '.';

export const messageActions = {
  deleteMessage,
  fetchMessages,
  fetchTotalRowsNumber,
  updateMessage,
  createMessage,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    messageService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: messageConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: messageConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: messageConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchMessages(filter, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.started('message'));
    messageService.fetchMessages(filter, offset * limit, limit).then(
      (items) => {
        dispatch(success({ items, offset }));
        dispatch(loadingActions.loaded('message'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(loadingActions.loaded('message'));
      }
    );
  };
  function request() {
    return { type: messageConstants.FETCH_REQUEST, filter };
  }
  function success({ items, offset }) {
    return { type: messageConstants.FETCH_SUCCESS, items, offset, filter };
  }
  function failure(error) {
    return { type: messageConstants.FETCH_FAILURE, error };
  }
}

function updateMessage(id, message) {
  return (dispatch) => {
    dispatch(request(message));
    messageService.updateMessage(id, message).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(newMessage) {
    return { type: messageConstants.UPDATE_REQUEST, message: newMessage, filter: { id } };
  }
  function success() {
    return { type: messageConstants.UPDATE_SUCCESS, message, filter: { id } };
  }
  function failure(error) {
    return { type: messageConstants.UPDATE_FAILURE, error };
  }
}
function deleteMessage(messageId) {
  return (dispatch) => {
    dispatch(request());
    messageService.deleteMessage(messageId).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: messageConstants.DELETE_REQUEST, messageId };
  }
  function success() {
    return { type: messageConstants.DELETE_SUCCESS, messageId };
  }
  function failure(error) {
    return { type: messageConstants.DELETE_FAILURE, error };
  }
}

function createMessage(data, filter) {
  return (dispatch) => {
    dispatch(request());
    return messageService.createMessage(data).then(
      (message) => {
        dispatch(success(message));
        return message;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: messageConstants.CREATE_REQUEST, filter };
  }
  function success(message) {
    return { type: messageConstants.CREATE_SUCCESS, message, filter };
  }
  function failure(error) {
    return { type: messageConstants.CREATE_FAILURE, error };
  }
}
