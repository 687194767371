import { articleGroupService } from 'services';
import { articleGroupConstants, articleGroupItemConstants } from '../constants';

export const articleGroupActions = {
  fetchAllArticleGroups,
  fetchArticleGroupFromItemId,
  createArticleGroup,
  updateArticleGroup,
};

function fetchAllArticleGroups(platformId, limit = 100) {
  return (dispatch) => {
    dispatch(request());
    articleGroupService.fetchAllArticleGroups(platformId, 0, limit).then(
      (articleGroups) => {
        dispatch(success(articleGroups));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: articleGroupConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return {
      type: articleGroupConstants.GET_ALL_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: articleGroupConstants.GET_ALL_FAILURE };
  }
}

function fetchArticleGroupFromItemId(itemId) {
  return (dispatch) => {
    dispatch(request());
    articleGroupService.fetchAllArticleGroupsFromItemId(itemId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: articleGroupItemConstants.GET_FROM_ITEM_REQUEST };
  }
  function success(data) {
    return {
      type: articleGroupItemConstants.GET_FROM_ITEM_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: articleGroupItemConstants.GET_FROM_ITEM_FAILURE };
  }
}

function createArticleGroup(data, platformId, successCallback = null) {
  return (dispatch) => {
    dispatch(request());

    return articleGroupService.createArticleGroup(data, platformId).then(
      (articleGroup) => {
        dispatch(success(articleGroup));
        if (successCallback) {
          successCallback();
        }
        return articleGroup;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );

    function request() {
      return {
        type: articleGroupConstants.ADD_REQUEST,
      };
    }

    function success(articleGroup) {
      return {
        type: articleGroupConstants.ADD_SUCCESS,
        articleGroup,
      };
    }

    function failure(error) {
      return {
        type: articleGroupConstants.ADD_FAILURE,
        error,
      };
    }
  };
}

function updateArticleGroup(data, successCallback = null) {
  return (dispatch) => {
    dispatch(request());

    return articleGroupService.updateArticleGroup(data).then(
      (articleGroup) => {
        dispatch(success(articleGroup));
        if (successCallback) {
          successCallback();
        }
        return articleGroup;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );

    function request() {
      return {
        type: articleGroupConstants.UPDATE_REQUEST,
      };
    }

    function success(articleGroup) {
      return {
        type: articleGroupConstants.UPDATE_SUCCESS,
        articleGroup,
      };
    }

    function failure(error) {
      return {
        type: articleGroupConstants.UPDATE_FAILURE,
        error,
      };
    }
  };
}
