import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectMessageList = (state, filter) => {
  const collections = get(state, 'message.collections', []);
  const collection = collections.find((collection) => isEqual(collection.filter, filter));

  return get(collection, 'items', []);
};

export const selectMessageByFilter = (state, filter) => {
  const collections = get(state, 'message.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectMessageCountByFilter = (state, filter) => {
  const collections = get(state, 'message.collectionsCount', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};
