import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { ListWrapper } from 'components/Core/Dropdown/Dropdown.style';
import './CatalogControls.scss';

class TemperatureControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list,
      isAllToggled: false,
      searchValue: '',
      listOpen: false,
    };
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  toggleAll() {
    const { isAllToggled, list } = this.state;
    const { handleClickFilter, setCurrentFilters, currentFilters } = this.props;

    const newTemperatureFilters = [];

    if (!isAllToggled) {
      list.forEach((item) => {
        newTemperatureFilters.push(item.value);
      });
    }

    const newActiveFilters = { ...currentFilters, temperatureList: newTemperatureFilters };

    this.setState({ isAllToggled: !isAllToggled });
    setCurrentFilters(newActiveFilters);
    handleClickFilter(newActiveFilters);
  }

  toggleItem(itemValue) {
    const { currentFilters, setCurrentFilters, handleClickFilter } = this.props;
    const temperatureFilters = currentFilters.temperatureList || [];

    let newTemperatureFilters = [...temperatureFilters, itemValue];
    if (temperatureFilters.includes(itemValue)) {
      newTemperatureFilters = newTemperatureFilters.filter((item) => item !== itemValue);
    }
    let newActiveFilters;
    if (currentFilters.quickList.includes(aggridFilterConstants.FILTER_NEW)) {
      newActiveFilters = {
        ...currentFilters,
        temperatureList: newTemperatureFilters,
      };
    } else {
      newActiveFilters = {
        ...currentFilters,
        quickList: [],
        temperatureList: newTemperatureFilters,
      };
    }

    setCurrentFilters(newActiveFilters);
    handleClickFilter(newActiveFilters);
  }

  toggleDropDown(listOpen) {
    this.setState({ listOpen });
  }

  handleSearchChange(event) {
    const searchValue = event.target.value;
    const { list } = this.props;

    const newList = list.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    this.setState({ list: newList, searchValue });
  }

  render() {
    const { currentFilters } = this.props;
    const { isAllToggled, list, searchValue, listOpen } = this.state;
    const temperatureFilters = currentFilters.temperatureList || [];

    return (
      <Dropdown headerTitle="Températures" listOpen={listOpen} toggleDropDown={this.toggleDropDown}>
        <ul className="dd-list">
          <ListWrapper>
            {/* <li>
              <input
                className="dd-search"
                type="text"
                onChange={(event) => {
                  this.handleSearchChange(event);
                }}
                value={searchValue}
                placeholder="Recherche..."
              />
            </li> */}
            {/* <li>
              <label>
                <input type="checkbox" onChange={this.toggleAll} checked={isAllToggled} />
                (Tout selectionner)
              </label>
            </li> */}
            {/* <li>
              <hr />
            </li> */}
            {list &&
              list.map((item, key) => (
                <li className="dd-list-item" key={key}>
                  <label>
                    <input
                      onChange={() => {
                        this.toggleItem(item.value);
                      }}
                      checked={temperatureFilters.includes(item.value)}
                      type="checkbox"
                    />
                    {item.title}
                  </label>
                </li>
              ))}
          </ListWrapper>
        </ul>
      </Dropdown>
    );
  }
}

TemperatureControl.propTypes = {
  handleClickFilter: PropTypes.func,
  setCurrentFilters: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default TemperatureControl;
