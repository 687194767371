import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash.map';
import groupBy from 'lodash.groupby';
import Moment from 'moment';
import { connect } from 'react-redux';
import { getCollection, getPath } from 'helpers';
import { modalActions, dayOffActions } from 'actions';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Core/Spinner/Spinner';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { ButtonPrimary } from 'components/Core/Button/Button';
import WithDaysOffStyles from './WithDaysOffStyles';

const formatdate = (date) => {
  const array = date.split(' ');
  return `${array[1].charAt(0).toUpperCase() + array[1].slice(1)} ${array[0]}`;
};

class DaysOff extends React.Component {
  componentDidMount() {
    const { fetchDayOff, selectedPlatform } = this.props;
    if (selectedPlatform) {
      fetchDayOff({ platform: selectedPlatform });
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchDayOff, selectedPlatform } = this.props;
    const { selectedPlatform: prevSelectedPlatform } = prevProps;

    if (selectedPlatform && selectedPlatform !== prevSelectedPlatform) {
      fetchDayOff({ platform: selectedPlatform });
    }
  }

  deleteAll = (listday) => {
    const { deleteDayOff } = this.props;
    listday.forEach((d) => deleteDayOff(d.id));
  };

  handelExtractExcel = () => {
    const { openExtractExcel } = this.props;
    openExtractExcel();
  };

  render() {
    const { className, openNewDayOffModal, dayOff, isLoading, deleteDayOff } = this.props;
    const url = [
      {
        text: 'Accueil ',
        href: getPath('dashboardAdmin'),
      },
      {
        text: 'Paramètres ',
      },
    ];
    const currentPage = 'Jours fériés et fermeture';
    const ongletList = [
      {
        text: 'Mes commerciaux',
        href: getPath('commercial'),
      },
      {
        text: 'Plannings de livraison',
        href: getPath('planning'),
      },
      {
        text: 'Jours fériés & fermeture',
        href: getPath('dayoff'),
        status: 'active',
      },
      {
        text: 'CGV appliquées',
        href: getPath('terms'),
      },
      {
        text: 'Paramètres précommande',
        href: getPath('settingsPreorder'),
      },
      {
        text: 'Messagerie client',
        href: getPath('message'),
      },
      {
        text: 'Extract XLS preco',
        handle: this.handelExtractExcel,
      },
    ];
    dayOff.sort((dayOffA, dayOffB) => (dayOffA.date < dayOffB.date ? -1 : 1));
    const groupDay = groupBy(dayOff, (v) => Moment(v.date).format('YYYY MMMM'));
    return (
      <div className={className}>
        <div className="page-dayOff">
          <div className="page-dayOff__heading">
            <div className="heading__path">
              <BreadCrumb urlTab={url} currentPage={currentPage} />
              <OngletMenu list={ongletList} />
            </div>
          </div>
          <div className="actions">
            <ButtonPrimary inverted className="dayOff" onClick={() => openNewDayOffModal(dayOff)}>
              <i className="icon-calendar" />
              <span>AJOUTER UN JOUR</span>
            </ButtonPrimary>
          </div>
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <div className="group-dayOff__content">
              {Object.keys(groupDay).map((d, index) => (
                <div className="page-dayOff__content" key={String(index)}>
                  <div className="dayOff__month">
                    <div className="head-dayOff">
                      <h5>{formatdate(d)}</h5>
                      <span className="delete__all" onClick={() => this.deleteAll(groupDay[d])}>
                        <Icon content="\e915" />
                      </span>
                    </div>
                    <div className="dayOff__date">
                      {Object.values(groupDay[d])
                        .sort((dayA, dayB) => (dayA.date < dayB.date ? -1 : 1))
                        .map((day) => (
                          <span key={`day-off-${day.id}`}>
                            {Moment(day.date).format('DD/MM/YYYY')}
                            <Icon content="\e915" onClick={() => deleteDayOff(day.id)} />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

DaysOff.propTypes = {
  className: PropTypes.string,
  openNewDayOffModal: PropTypes.func,
  fetchDayOff: PropTypes.func,
  deleteDayOff: PropTypes.func,
  dayOff: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedPlatform: PropTypes.number,
  openExtractExcel: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { dayOff, platform } = state;
  const selectedPlatform = platform.selectedId;
  const dayOffCollection = getCollection(
    dayOff.collections || [],
    selectedPlatform ? { platform: selectedPlatform } : {}
  );
  return {
    dayOff: dayOffCollection.items || [],
    isLoading: dayOffCollection.isLoading || false,
    selectedPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewDayOffModal: (dayOffs) => dispatch(modalActions.open('dayOff', { width: '350', dayOffs })),
  fetchDayOff: (filter) => dispatch(dayOffActions.fetchDayOff(filter, 0, 100)),
  deleteDayOff: (id) => dispatch(dayOffActions.deleteDayOff(id)),
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDaysOffStyles(DaysOff));
