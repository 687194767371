import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  height: 100%;
  z-index: 1;
  @media ${screen.tablet} {
    width: 100%;
  }
`;
