import isEqual from 'lodash.isequal';
import { termsConstants } from '../constants';

const initState = {
  collections: [],
  collectionsCount: [],
};

export function terms(state = initState, action) {
  if (!Object.keys(termsConstants).find((key) => termsConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((m) => JSON.stringify(m.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case termsConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case termsConstants.FETCH_COUNT_SUCCESS: {
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      };
      const newCollectionsCount = [
        ...collectionsCount.filter((c) => !isEqual(newCollectionCount.filter, c.filter)),
        newCollectionCount,
      ];
      return {
        ...state,
        collectionsCount: newCollectionsCount,
      };
    }
    case termsConstants.FETCH_COUNT_FAILURE:
      return state;
    case termsConstants.FETCH_REQUEST: {
      const newCollection = { ...collection, isLoading: true };
      const newCollections = [
        ...collections.filter((collection) => !isEqual(newCollection.filter, collection.filter)),
        newCollection,
      ];

      return {
        ...state,
        collections: newCollections,
      };
    }
    case termsConstants.FETCH_SUCCESS: {
      const newCollection = {
        ...collection,
        items: collection.items || [],
        isLoading: false,
      };
      newCollection.items[action.offset] = action.items;
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];

      return {
        ...state,
        collections: newCollections,
      };
    }
    case termsConstants.FETCH_FAILURE:
      return state;
    case termsConstants.CREATE_REQUEST:
      return {
        ...state,
      };
    case termsConstants.CREATE_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items && collection.items[0]) {
          collection.items[0].unshift(action.term);
        }

        return { ...collection };
      });
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: collectionCount.totalRowsNumber + 1,
      };

      return {
        ...state,
        collections: newCollections,
        collectionsCount: [newCollectionCount],
      };
    }
    case termsConstants.CREATE_FAILURE:
      collection = Object.assign(collection, {
        items: [...(collection.items || []).filter((i) => i.uid !== action.uid)],
        isLoading: false,
      });
      collections = Object.assign([collection], collections);

      return {
        ...state,
        collections,
      };
    case termsConstants.UPDATE_REQUEST:
      collections
        .filter((t) => {
          const { items } = t;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.term.id)) > -1
            : false;
        })
        .forEach((t) => {
          const { items } = t;
          const newTerms = [...items];
          const newTerm = newTerms.find((i) => Number(i.id) === Number(action.term.id));
          Object.assign(newTerm, action.term);
          if (!action.term.date_unactivated) {
            delete newTerm.date_unactivated;
          }
          collections = Object.assign(
            [Object.assign(t, { items: newTerms, isLoading: false })],
            collections
          );
        });
      return {
        ...state,
        collections,
      };
    case termsConstants.UPDATE_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items) {
          return {
            ...collection,
            items: collection.items.map((page) => {
              const newPage = [...page];
              const itemIndex = page.findIndex((item) => item.id === action.term.id);
              if (itemIndex !== -1) {
                newPage[itemIndex] = action.term;
              }

              return newPage;
            }),
          };
        }

        return collection;
      });

      return {
        ...state,
        collections: newCollections,
      };
    }
    case termsConstants.UPDATE_FAILURE:
      return { ...state };
    case termsConstants.DELETE_REQUEST:
      collections
        .filter((t) => {
          const { items } = t;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.termId)) > -1
            : false;
        })
        .forEach((t) => {
          const { items } = t;
          const newItems = items ? items.filter((i) => Number(i.id) !== Number(action.termId)) : [];
          collections = Object.assign(
            [Object.assign(t, { items: newItems, isLoading: false })],
            collections
          );
        });
      return {
        ...state,
        collections,
      };
    case termsConstants.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case termsConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
