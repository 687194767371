import { PageContextConstant } from 'constants/PageContext.constants';

const initState = {
  page: '',
};

export function pageContext(state = initState, action) {
  switch (action.type) {
    case PageContextConstant.PAGE_CURRENT:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
}
