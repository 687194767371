import { promotionConstants } from '../constants';

const initState = {
  data: [],
  selectedProduct: null,
  isLoading: false,
  error: null,
  currentTab: null,
};

export const promotion = (state = initState, action) => {
  switch (action.type) {
    case promotionConstants.CHOOSE_TAB: {
      return {
        ...state,
        currentTab: action.currentTab,
      };
    }
    case promotionConstants.FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case promotionConstants.FETCH_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    }
    case promotionConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case promotionConstants.CHOOSE_CURRENT_PRODUCT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case promotionConstants.CHOOSE_CURRENT_PRODUCT_SUCCESS: {
      return {
        ...state,
        selectedProduct: action.product,
        isLoading: false,
      };
    }
    case promotionConstants.CHOOSE_CURRENT_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
