import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import map from 'lodash.map';
import reduce from 'lodash.reduce';
import { connect } from 'react-redux';
import { ButtonSecondary, ButtonPrimaryCircular } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import { creditActions } from 'actions';
import { userConstants } from 'constants/User.constants';
import CreditFormWrapper from './CreditForm.style';
import CreditItem from './CreditItem/CreditItem';
import CreditWording from '../Table/Cells/Credit/CreditWording';

const getCreditList = (idProduct, invoiceId, creditList) =>
  reduce(
    creditList,
    (result, current) => {
      if (current.invoiceId === invoiceId && current.idProduct === idProduct) {
        result.push(current);
      }
      return result;
    },
    []
  );

const CreditForm = ({
  invoiceId,
  idProduct,
  addCredit,
  className,
  closeCredit,
  creditList,
  product,
  index,
  creditValidate,
  orderItemId,
  showWording,
  creditWorddingValues,
  disabled,
  isLoading,
}) => {
  const addCreditItem = () => {
    addCredit({
      invoiceId,
      idProduct,
      idLigne: uuid(),
      reason: '',
      comment: '',
      litigation: false,
      quantity: 0,
      orderItemId: 0,
    });
  };

  const totalQuantityCredit = reduce(
    getCreditList(idProduct, invoiceId, creditList),
    (acc, current) => acc + current.quantity,
    0
  );

  if (!getCreditList(idProduct, invoiceId, creditList).length && !showWording && !isLoading) {
    addCredit({
      invoiceId,
      idProduct,
      idLigne: uuid(),
      reason: '',
      comment: '',
      litigation: false,
      quantity: 0,
      orderItemId: 0,
    });
  }
  const listOfCredit = getCreditList(idProduct, invoiceId, creditList);

  const { quantity_invoiced_u_c: productQuantity } = product;

  return (
    <CreditFormWrapper index={index} className={className}>
      <td colSpan="7">
        <form>
          {creditValidate !== true && !showWording ? (
            <span className="credit-form__title">
              Indiquer les quantités et raison de l&apos;avoir demandé sur cet article
            </span>
          ) : (
            ''
          )}

          <ButtonPrimaryCircular className="credit-form__button-close" onClick={closeCredit}>
            <Icon content="\e915" />
          </ButtonPrimaryCircular>

          <hr />
          {showWording
            ? map(creditWorddingValues, (credit) => <CreditWording credit={credit} />)
            : map(
                totalQuantityCredit >= productQuantity
                  ? listOfCredit.filter((c) => c.quantity)
                  : listOfCredit,
                (value, i) => (
                  <div key={`credit-${i}`}>
                    <CreditItem
                      idProduct={value.idProduct}
                      idLigne={value.idLigne}
                      invoiceId={value.invoiceId}
                      creditValue={value}
                      creditValidate={creditValidate}
                      isMaxQuantity={totalQuantityCredit >= productQuantity}
                      maxQuantity={productQuantity}
                      currentQuantity={totalQuantityCredit}
                      addCredit={addCredit}
                      orderItemId={orderItemId}
                      disabled={disabled}
                      creditList={listOfCredit}
                    />
                    <hr />
                  </div>
                )
              )}
          {creditValidate !== true &&
            !(totalQuantityCredit >= productQuantity) &&
            !showWording &&
            creditList.length < productQuantity && (
              <ButtonSecondary
                inverted
                className="credit-form__button"
                onClick={!disabled ? addCreditItem : () => {}}
                disabled={disabled}
              >
                <Icon className="credit-form__button-icon" content="\e93a" />
                <span className="credit-form__button-title">AJOUTER UNE LIGNE</span>
              </ButtonSecondary>
            )}
        </form>
      </td>
    </CreditFormWrapper>
  );
};

CreditForm.propTypes = {
  className: PropTypes.string,
  closeCredit: PropTypes.func,
  idProduct: PropTypes.number,
  product: PropTypes.object,
  invoiceId: PropTypes.number,
  addCredit: PropTypes.func,
  index: PropTypes.number,
  creditValidate: PropTypes.bool,
  creditList: PropTypes.array,
  orderItemId: PropTypes.number,
  showWording: PropTypes.bool,
  creditWorddingValues: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  addCredit: (creditItem) => dispatch(creditActions.addCredit(creditItem)),
  closeCredit: () => dispatch(creditActions.close()),
});
const mapStateToProps = (state) => {
  const {
    credit: { creditList },
    invoiceItems: { isLoading },
    user: {
      information: { type },
    },
  } = state;
  return {
    creditList,
    isLoading,
    disabled: [userConstants.TYPE_COMMERCIAL, userConstants.TYPE_ADMIN].includes(type),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditForm);
