/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { getPath, history, getCollection } from 'helpers';
import { categoryConstants } from 'constants/Category.constants';
import { BsHeartFill } from '@react-icons/all-files/bs/BsHeartFill';
import { ConstantItems } from './MenuItem.constant';
import menuItemWithStyle from './MenuItem.style';

const goToSlug = (slugGroup, slug) => {
  const path = getPath('catalog').replace(':slugGroup', `${slugGroup}`);
  const url =
    slug.trim() && slug.trim() !== '' && slug !== slugGroup
      ? path.replace(':slug?', `${slug}`)
      : path.replace('/:slug?', '');
  history.push(url);
};

const Item = ({
  handleClick,
  level,
  trail,
  item,
  parent,
  slugGroup,
  isSeeAll,
  dataNumber,
  handleOpenMenu,
  reset,
  isAssortment,
}) => {
  const itemIconColor = item.iconColor || '#01366B';
  const slug = isSeeAll ? (parent && parent.slug) || '' : item.slug;
  const goToSlugByGroup = !slugGroup;

  const effectiveChildren = item.categories
    ? Object.values(item.categories)
    : Object.values(item.children || {});

  const closeMenuAndGo = () => {
    handleOpenMenu();
    reset();
    goToSlug(goToSlugByGroup ? slug : slugGroup, goToSlugByGroup ? '' : slug);
  };

  const handleClickOnMenu = (e) =>
    handleClick && !isAssortment && effectiveChildren && effectiveChildren.length > 0
      ? handleClick(e, effectiveChildren, level, item.id, item)
      : closeMenuAndGo();

  return (
    <button
      type="submit"
      className={trail === item.id ? 'side-nav__link side-nav__link--active' : 'side-nav__link'}
      onClick={(e) => handleClickOnMenu(e)}
      onTouchEnd={(e) => handleClickOnMenu(e)}
    >
      {isAssortment || item.is_preorder === undefined ? null : item.is_preorder ? (
        <Icon className="side-nav__icon" content="\e941" />
      ) : (
        <Icon className="side-nav__icon" content="\e94c" />
      )}
      {!item.categories &&
        item.slug &&
        item.slug !== 'has_promotion' &&
        item.slug !== 'is_favorite' && (
          <Icon className="side-nav__icon" slug={item.slug} color={itemIconColor} />
        )}
      {item.slug === 'has_promotion' && <PromotionIcon className="side-nav__icon" />}
      {item.slug === 'is_favorite' && (
        <BsHeartFill className="side-nav__icon" color={itemIconColor} />
      )}
      {isAssortment && <Icon className="side-nav__icon assortment" content="\e945" />}
      <span className="side-nav__name">{item.name}</span>
      {!!(dataNumber && Number(dataNumber) >= 1) && (
        <i className={!item.categories ? 'product-number red' : 'product-number blue'}>
          {dataNumber}
        </i>
      )}
      {effectiveChildren && effectiveChildren.length > 0 && !isAssortment && (
        <Icon className="side-nav__icon arrow" content="\e907" />
      )}
    </button>
  );
};

const MenuItem = ({
  className,
  firstColumn,
  data,
  handleClick,
  handleClickGoBack,
  handleOpenMenu,
  reset,
  trail,
  parent,
  slugGroup,
  hasPromotionNumberDataAvailable,
  isNewNumberDataAvailable,
  level,
}) => {
  const spliteData = data.reduce(
    (acc, item) => {
      if (item.status === categoryConstants.STATUS_SELECTION) {
        acc.assortmentLinks.push(item);
      } else {
        acc.nestedLinks.push(item);
      }
      return acc;
    },
    { assortmentLinks: [], nestedLinks: [] }
  );
  const { seeAll, promotions, news, favorites } = ConstantItems;
  data.forEach((element) => {
    if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
      element.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (Object.prototype.hasOwnProperty.call(element, 'children')) {
      element.children.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  });

  return data ? (
    <div className={className}>
      <nav className="menu__column">
        {!firstColumn && parent && (
          <div className="responsive-info">
            <div className="side-nav__link" onClick={() => handleClickGoBack()}>
              <Icon content="\e905" />
              <span>Retour</span>
            </div>
            <div className="parent-info">
              <span>{parent.name}</span>
            </div>
          </div>
        )}
        {!firstColumn && (
          <ul className="see-all">
            <Item
              item={seeAll}
              slugGroup={slugGroup}
              isSeeAll
              handleClick={handleClick}
              handleOpenMenu={handleOpenMenu}
              parent={parent}
              reset={reset}
            />
          </ul>
        )}
        <ul className={firstColumn ? 'nested-link' : ''}>
          {spliteData.nestedLinks.map((item) => (
            <Item
              item={item}
              slugGroup={slugGroup}
              seeAll
              level={level}
              handleOpenMenu={handleOpenMenu}
              handleClick={handleClick}
              trail={trail}
              key={item.id}
              reset={reset}
              dataNumber={firstColumn ? item.count_items : 0}
            />
          ))}
        </ul>
        {firstColumn && (
          <div>
            <ul className="middle-link">
              <Item
                item={favorites}
                handleClick={handleClick}
                trail={trail}
                key={favorites.id}
                seeAll={false}
                // dataNumber={isFavoriteNumberDataAvailable}
                handleOpenMenu={handleOpenMenu}
                reset={reset}
              />
              <Item
                item={promotions}
                handleClick={handleClick}
                trail={trail}
                key={promotions.id}
                seeAll={false}
                dataNumber={hasPromotionNumberDataAvailable}
                handleOpenMenu={handleOpenMenu}
                reset={reset}
              />
              <Item
                item={news}
                handleClick={handleClick}
                trail={trail}
                key={news.id}
                seeAll={false}
                dataNumber={isNewNumberDataAvailable}
                handleOpenMenu={handleOpenMenu}
                reset={reset}
              />
            </ul>
            <ul className="assortment-link">
              {spliteData.assortmentLinks.map((item) => (
                <Item
                  item={item}
                  slugGroup={slugGroup}
                  seeAll
                  level={level}
                  handleOpenMenu={handleOpenMenu}
                  handleClick={handleClick}
                  trail={trail}
                  key={item.id}
                  reset={reset}
                  dataNumber={item.count_items}
                  isAssortment
                />
              ))}
            </ul>
          </div>
        )}
      </nav>
    </div>
  ) : null;
};

Item.propTypes = {
  handleClick: PropTypes.func,
  handleOpenMenu: PropTypes.func,
  trail: PropTypes.number,
  isSeeAll: PropTypes.bool,
  level: PropTypes.number,
  dataNumber: PropTypes.number,
  parent: PropTypes.object,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
    level: PropTypes.number,
    slug: PropTypes.string,
  }).isRequired,
  slugGroup: PropTypes.string,
  reset: PropTypes.func,
  isAssortment: PropTypes.bool,
};

MenuItem.propTypes = {
  className: PropTypes.string.isRequired,
  firstColumn: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.array,
      slug: PropTypes.string,
    })
  ),
  parent: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func,
  handleClickGoBack: PropTypes.func.isRequired,
  trail: PropTypes.number,
  isNewNumberDataAvailable: PropTypes.number,
  hasPromotionNumberDataAvailable: PropTypes.number,
  level: PropTypes.number,
  slugGroup: PropTypes.string,
  reset: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { catalog, platform } = state;
  const { collectionsCount: collectionsCountCatalog } = catalog;
  const isNewCollection = getCollection(collectionsCountCatalog, {
    is_new: true,
    platform: platform.selectedId,
  });
  const hasPromotionCollection = getCollection(collectionsCountCatalog, {
    has_promotion: true,
    platform: platform.selectedId,
  });
  return {
    isNewNumberDataAvailable: isNewCollection.totalRowsNumber,
    hasPromotionNumberDataAvailable: hasPromotionCollection.totalRowsNumber,
  };
};

export default connect(mapStateToProps)(menuItemWithStyle(MenuItem));
