import { uiConstants } from 'constants/Ui.constants';

export const uiActions = {
  screenResize,
};

function screenResize(screenWidth) {
  return (dispatch) => {
    dispatch({ type: uiConstants.SCREEN_RESIZE, screenWidth });
  };
}
