export const cartConstants = {
  CART_UPDATE: 'CART_UPDATE',

  FETCH_REQUEST: 'CART_FETCH_REQUEST',
  FETCH_SUCCESS: 'CART_FETCH_SUCCESS',
  FETCH_FAILURE: 'CART_FETCH_FAILURE',

  FETCH_COUNT_REQUEST: 'CART_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'CART_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'CART_COUNT_FETCH_FAILURE',

  CREATE_CURRENT_REQUEST: 'CART_CREATE_CURRENT_REQUEST',
  CREATE_CURRENT_SUCCESS: 'CART_CREATE_CURRENT_SUCCESS',
  CREATE_CURRENT_FAILURE: 'CART_CREATE_CURRENT_FAILURE',
  CREATE_CURRENT_TEMPS_SUCCESS: 'CART_CREATE_CURRENT_TEMPS_SUCCESS',
  CREATE_CURRENT_PREORDER_TEMPS_SUCCESS: 'CART_CREATE_CURRENT_PREORDER_TEMPS_SUCCESS',

  CREATE_CURRENT_PREORDER_REQUEST: 'CART_CREATE_CURRENT_PREORDER_REQUEST',
  CREATE_CURRENT_PREORDER_SUCCESS: 'CART_CREATE_CURRENT_PREORDER_SUCCESS',
  CREATE_CURRENT_PREORDER_FAILURE: 'CART_CREATE_CURRENT_PREORDER_FAILURE',

  CREATE_REQUEST: 'CART_CREATE_REQUEST',
  CREATE_SUCCESS: 'CART_CREATE_SUCCESS',
  CREATE_FAILURE: 'CART_CREATE_FAILURE',

  COPY_REQUEST: 'CART_COPY_REQUEST',
  COPY_SUCCESS: 'CART_COPY_SUCCESS',
  COPY_FAILURE: 'CART_COPY_FAILURE',

  UPDATE_REQUEST: 'CART_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CART_UPDATE_FAILURE',

  DELETE_REQUEST: 'CART_DELETE_REQUEST',
  DELETE_SUCCESS: 'CART_DELETE_SUCCESS',
  DELETE_FAILURE: 'CART_DELETE_FAILURE',

  VALIDATE_REQUEST: 'CART_VALIDATE_REQUEST',
  VALIDATE_SUCCESS: 'CART_VALIDATE_SUCCESS',
  VALIDATE_FAILURE: 'CART_VALIDATE_FAILURE',

  RESET_REQUEST: 'CART_RESET_REQUEST',
  RESET_SUCCESS: 'CART_RESET_REQUEST',
  RESET_FAILURE: 'CART_RESET_REQUEST',

  CLEAR: 'CART_CLEAR',
  UPDATE_DELIVERY_DATES: 'UPDATE_DELIVERY_DATES',

  CART_STATUS_CURRENT: 'CURRENT',
  CART_STATUS_CURRENT_PREORDER: 'CURRENT_PREORDER',
  CART_STATUS_CUSTOM: 'CUSTOM',
  CART_STATUS_CUSTOM_PREORDER: 'CUSTOM_PREORDER',
  CART_STATUS_MOQ_PREORDER: 'MOQ_PREORDER',
  CART_STATUS_MOQ_STAND_BY: 'STAND_BY_VALIDATION_PREORDER',
  CART_STATUS_PENDING_VALIDATION: 'PENDING_VALIDATION',
  CART_STATUS_CUSTOM_RECURRING: 'CUSTOM_RECURRING',

  CART_COMMERCIAL_TYPE: 'COMMERCIAL_CART',
  SET_ERROR_TOASTED: 'CART_SET_ERROR_TOASTED',

  DOWNLOAD_CART_PDF_REQUEST: 'CART_DOWNLOAD_CART_PDF_REQUEST',
  DOWNLOAD_CART_PDF_SUCCESS: 'CART_DOWNLOAD_CART_PDF_SUCCESS',
  DOWNLOAD_CART_PDF_FAILURE: 'CART_DOWNLOAD_CART_PDF_FAILURE',

  REASON_UNKNOW: 'Raison inconnu',
  REASON_REF_NOT_FOUND: 'Référence non trouvée',
  REASON_REF_OUT_OF_STOCK: 'Stock indisponible',
  REASON_REF_NOT_IN_ASSORTIMENT: 'Hors assortiment',
};
