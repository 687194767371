import { productCardConstants } from 'constants/ProductCard.constants';

const initState = {
  currentProduct: null,
};

export function productCard(state = initState, action) {
  switch (action.type) {
    case productCardConstants.CHOOSE_CURRENT_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case productCardConstants.CHOOSE_CURRENT_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentProduct: action.product,
      };
    case productCardConstants.CHOOSE_CURRENT_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
