import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';

import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalNewContainers.style';

const handleClick = (closeModal) => {
  closeModal();
};

const ModalNewContainer = ({ oldQuantity, apiContainer, countContainer, quantity, closeModal }) => (
  <div className="modal-body">
    <div className="modal-body__text-block">
      <span>Vous allez ajouter x nouveau(x) conteneur(s)</span>
    </div>
    <div className="modal-body__button-block">
      <ButtonPrimary inverted onClick={() => closeModal()}>
        ANNULER
      </ButtonPrimary>
      <ButtonPrimary onClick={() => handleClick(closeModal)}>Oui</ButtonPrimary>
    </div>
  </div>
);
ModalNewContainer.propTypes = {
  closeModal: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(mapDispatchToProps)(withStyle(ModalNewContainer));
