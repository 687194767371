import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiPadlock } from '@react-icons/all-files/gi/GiPadlock';
import { clientActions, modalActions } from 'actions';
import {
  userConstants,
  clientStatusConstants,
  platformConstants,
  catalogConstants,
} from 'pages/../constants';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import { getCollection, getValueOfAttribut } from 'helpers';
import Spinner from 'components/Core/Spinner/Spinner';
import Alert from 'components/Alert/Alert';
import {
  ClientAccess,
  Terms,
  PersonalInformation,
  Address,
  ClientActivation,
  // Geolocalisation,
  Planning,
  // Temperature,
  Commercials,
  Franco,
  DeliveryMode,
  Selection,
} from 'components/Profil';
import { selectClientDetail } from 'selectors/client';
import ClientBreadCrumb from './ClientBreadcumb';
import withClientStyle from './withClientStyle';

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const { TYPE_COMMERCIAL, TYPE_ADMIN } = userConstants;
const { STATUS_BLOCKED, STATUS_ACTIVE, STATUS_INACTIVE } = clientStatusConstants;
const {
  DELIVERY_MODE_KEY,
  HAS_DRY_PRODUCTS_KEY,
  HAS_FROZEN_PRODUCTS_KEY,
  HAS_FRESH_PRODUCTS_KEY,
} = platformConstants;
class Client extends React.Component {
  state = {
    hasInitialized: false,
    id: null,
    status: null,
    client_status: [],
    commercials: [],
    delivery_plannings: [],
    client_franco: [],
    customers: [],
    terms: [],
    client_delivery_modes: [],
    hasChanged: false,
  };

  componentDidMount() {
    const { fetchClientDetail, match } = this.props;
    const { id } = (match && match.params) || {};
    fetchClientDetail(id);
  }

  componentDidUpdate(prevProps) {
    const { hasInitialized } = this.state;
    const { fetchClientDetail, match, client } = this.props;
    // const client = this.getClient();
    const { match: prevMatch } = prevProps;
    const { id } = (match && match.params) || {};
    const { id: prevId } = (prevMatch && prevMatch.params) || {};
    if (hasInitialized && prevId !== id) {
      fetchClientDetail(id);
      this.setState({ hasInitialized: false });
    }
    const stateClientStatus = this.getStateClientStatus();
    const prevClientStatus = this.getOriginalClientStatus(prevProps);

    if (client.data && !hasInitialized) {
      this.setState({
        hasInitialized: true,
        ...client.data,
      });
    } else if (hasInitialized) {
      if (stateClientStatus && prevClientStatus && !stateClientStatus.id && prevClientStatus.id) {
        this.setState({
          ...client.data,
        });
      } else if (String(prevId) !== String(id)) {
        this.setState({
          hasInitialized: false,
        });
      }
    }
  }

  // getClient(effectiveProps) {
  //   const { client, match, selectedPlatform } = effectiveProps || this.props;
  //   const { id } = (match && match.params) || {};
  //   const { collections } = client;
  //   const collection = getCollection(collections, { platform: selectedPlatform.id });
  //   return collection && collection.items && collection.items.length
  //     ? collection.items.find((item) => item.id === id)
  //     : null;
  // }

  getStateClientStatus = () => {
    const { client_status: stateClientStatus } = this.state;
    const { selectedPlatform } = this.props;
    return (stateClientStatus || []).find(
      (status) => status.platform && Number(status.platform.id) === Number(selectedPlatform)
    );
  };

  getOriginalClientStatus = (effectiveProps = null) => {
    const { selectedPlatform, client } = effectiveProps || this.props;
    // const client = this.getClient(effectiveProps);
    const { client_status: clientStatusList } = client.data || {};
    return (clientStatusList || []).find(
      (status) => status.platform && Number(status.platform.id) === Number(selectedPlatform)
    );
  };

  updateState = (key, value) => {
    this.setState({
      [key]: value,
      hasChanged: true,
    });
  };

  getTemperatures = () => {
    const {
      platform: { attributs },
    } = this.props;
    const temperatures = [];
    const hasDryProducts = !!Number(getValueOfAttribut(attributs || [], HAS_DRY_PRODUCTS_KEY));
    const hasFreshProducts = !!Number(getValueOfAttribut(attributs || [], HAS_FRESH_PRODUCTS_KEY));
    const hasFrozenProducts = !!Number(
      getValueOfAttribut(attributs || [], HAS_FROZEN_PRODUCTS_KEY)
    );
    [
      [hasDryProducts, TEMPERATURE_DRY],
      [hasFreshProducts, TEMPERATURE_FRESH],
      [hasFrozenProducts, TEMPERATURE_FROZEN],
    ].forEach(([isTemperatureAvailable, temperature]) => {
      if (isTemperatureAvailable) {
        temperatures.push(temperature);
      }
    });
    return temperatures;
  };

  getDeliveryModes = (clientDeliveryModes) => {
    if (
      clientDeliveryModes.findIndex((deliveryMode) => !deliveryMode.delivery_mode) > -1 ||
      !clientDeliveryModes.length
    ) {
      return ['Retrait', 'Livraison'];
    }
    return ['Retrait'];
  };

  checkDeliveryPlanningsOk = () => {
    const { delivery_plannings: deliveryPlannings } = this.state;
    const client = { ...this.state };
    const { allDeliveryPlannings, platform } = this.props;
    const { attributs } = platform;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));

    const temperatures = this.getTemperatures();
    const deliveryModes = this.getDeliveryModes(client.client_delivery_modes);

    if (deliveryPlannings.length === 0 || !platform) {
      return false;
    }

    const planningIds = allDeliveryPlannings.reduce((acc, item) => {
      acc.push(item.id);
      if (item.children && item.children.length) {
        item.children.forEach((childitem) => {
          acc.push(childitem.id);
        });
      }
      return acc;
    }, []);
    const attribute = platformDeliveryMode ? 'delivery_mode' : 'temperature';
    const getMode = (mode) => (mode === true || mode === 'Retrait' ? 'Retrait' : 'Livraison');

    // Check if there is a deliveryPlanning by temperature
    // and if it's found in the list of plannings
    const areDeliveryPlanningsOk = (platformDeliveryMode ? deliveryModes : temperatures).every(
      (item) => {
        const deliveryPlanning = deliveryPlannings.find((planning) => {
          let newPlanning = planning;
          if (platformDeliveryMode) {
            newPlanning = {
              ...planning,
              delivery_mode: !planning.temperature ? getMode(planning.delivery_mode) : '',
            };
          }
          return (
            newPlanning[attribute] === item &&
            String(newPlanning.platform_id) === String(platform.id)
          );
        });

        const planningIdFoundInServer = planningIds.find(
          (planningId) => deliveryPlanning && deliveryPlanning.id === planningId
        );

        return !!deliveryPlanning && !!planningIdFoundInServer;
      }
    );

    return areDeliveryPlanningsOk;
  };

  isAbleToSave = () => {
    const { selectedPlatform, user } = this.props;
    const {
      hasChanged,
      commercials,
      client_franco: clientFranco,
      client_status: clientStatus,
      terms,
    } = this.state;

    if (
      !selectedPlatform ||
      !hasChanged ||
      clientStatus.length === 0 ||
      commercials.length === 0 ||
      terms.length === 0
    ) {
      return false;
    }

    if (!this.checkDeliveryPlanningsOk() && user.type === TYPE_ADMIN) {
      return false;
    }

    if (clientFranco.length > 0) {
      const francos = clientFranco.filter((franco) => !franco.id && franco.enable);
      const francoIndex = francos.findIndex(
        (franco) => !franco.temperature || !franco.amount || !franco.status
      );
      if (francoIndex > -1) {
        return false;
      }
    }

    const effectiveClientStatus = clientStatus.find(
      ({ platform, platform_id: platformId }) =>
        Number(platformId) === Number(selectedPlatform) ||
        (platform && Number(platform.id) === Number(selectedPlatform))
    );
    if (!effectiveClientStatus || effectiveClientStatus.status !== STATUS_ACTIVE) {
      return false;
    }

    return true;
  };

  saveChanges = () => {
    const {
      updateClient,
      match,
      platform: { attributs, selectedId },
    } = this.props;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));

    const { id } = (match && match.params) || {};
    const {
      client_status: clientStatus,
      commercials,
      delivery_plannings: deliveryPlannings,
      client_franco: clientFranco,
      terms,
      client_delivery_modes: clientDeliveryModes,
    } = this.state;
    if (this.isAbleToSave()) {
      const parsedClientStatus = clientStatus.map((status) => {
        const data = {
          client: { id: Number(id) },
          platform: { id: status.platform.id },
          status_catalog: status.status_catalog,
          status_preorder: status.status_preorder,
          status: status.status,
        };
        if (status.id) {
          data.id = status.id;
        }
        return data;
      });
      const parsedCommercials = commercials.map((commercial) => ({
        id: commercial.id,
      }));
      let parsedDeliveryPlannings = [];
      if (platformDeliveryMode && this.getDeliveryModes(deliveryPlannings) === 1) {
        parsedDeliveryPlannings = deliveryPlannings
          .filter(
            (deliveryPlanning) =>
              !deliveryPlanning.delivery_mode &&
              Number(deliveryPlanning.platform_id) === Number(selectedId)
          )
          .map((deliveryPlanning) => ({
            id: deliveryPlanning.id,
          }));
      } else {
        parsedDeliveryPlannings = deliveryPlannings.map((deliveryPlanning) => ({
          id: deliveryPlanning.id,
        }));
      }
      const parsedeClientFranco = clientFranco.map((franco) => {
        const data = {
          client: { id: Number(id) },
          platform: { id: franco.platform.id },
          temperature: franco.temperature,
          status: franco.status,
          amount: franco.amount,
          enable: franco.enable,
        };
        if (franco.id) {
          data.id = franco.id;
        }
        return data;
      });
      const parsedTerms = terms.map((term) => ({
        id: term.id,
      }));
      const parsedClientDeliveryModes = clientDeliveryModes.map((deliveryMode) => {
        if (deliveryMode.id) {
          return {
            id: deliveryMode.id,
            client: { id: Number(id) },
            delivery_mode: deliveryMode.delivery_mode,
          };
        }
        return { ...deliveryMode };
      });
      const data = {
        client_status: parsedClientStatus,
        commercials: parsedCommercials,
        delivery_plannings: parsedDeliveryPlannings,
        client_franco: parsedeClientFranco,
        terms: parsedTerms,
        client_delivery_modes: parsedClientDeliveryModes,
      };
      updateClient(Number(id), data);
      this.setState({
        hasChanged: false,
      });
    }
  };

  render() {
    const { className, user, selectedPlatform, platform, openRedirectModal } = this.props;
    const { attributs } = platform;
    const client = { ...this.state };

    if (!client) {
      return '';
    }

    const {
      commercials,
      terms,
      client_franco: clientFrancos,
      client_status: clientStatusList,
    } = client;

    // Information for the "Informations Client" and "Address" blocks
    const clientUsername =
      client && client.customers && client.customers.length > 0
        ? client.customers[0].username
        : null;
    const clientEmail =
      client && client.customers && client.customers.length > 0 ? client.customers[0].email : null;
    // Information mainly for the outstanding
    const clientStatus = (clientStatusList || []).find(
      (status) => status.platform && Number(status.platform.id) === selectedPlatform
    );
    const { status_preorder: statusPreorder, status_catalog: statusCatalog, status } =
      clientStatus || {};

    const isClientNotActive =
      !clientStatus ||
      status === STATUS_INACTIVE ||
      (statusPreorder === STATUS_BLOCKED && statusCatalog === STATUS_BLOCKED);

    // Platform can change client's delivery mode
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const temperatures = this.getTemperatures();
    const deliveryModes = this.getDeliveryModes(client.client_delivery_modes);

    return (
      <div className={className}>
        <div className="page-profil">
          {client && user.type ? (
            <div>
              <ClientBreadCrumb type="single" clientName={client.name} />
              <div className="page_profil__section-label">
                <span className="bold">
                  {' '}
                  {client.ext_code && `${client.ext_code.slice(1)} ${client.name}`}{' '}
                </span>
              </div>
              {user.type === TYPE_ADMIN && (
                <div>
                  <div className="page-profil__button-content">
                    {/* <ButtonPrimary inverted className="item-action__button">
                      <Icon content="\e930" />
                      <span>ENVOYER UN MESSAGE</span>
                    </ButtonPrimary> */}
                    {/*
                    <ButtonPrimary inverted className="item-action__button">
                      <Icon content="\e945" />
                      <span>ENVOYER UNE SÉLECTION</span>
                    </ButtonPrimary>
                    */}
                    {client.locked && (
                      <span className="page_profil__locked-message">
                        <GiPadlock /> Client bloqué en comptabilité
                      </span>
                    )}
                    <ButtonPrimary
                      inverted
                      className="item-action__button"
                      disabled={isClientNotActive}
                      onClick={() => (!isClientNotActive ? openRedirectModal(clientUsername) : {})}
                    >
                      <Icon content="\e910" />
                      <span>ESPACE CLIENT</span>
                    </ButtonPrimary>
                  </div>
                  {status === STATUS_ACTIVE &&
                    statusPreorder === STATUS_BLOCKED &&
                    statusCatalog === STATUS_BLOCKED && (
                      <div className="page-profil__alert-block">
                        <Alert text="ATTENTION : Client bloqué" />
                      </div>
                    )}
                  <div className="client__row">
                    <ClientActivation
                      id={client.id}
                      originalClientStatus={this.getOriginalClientStatus()}
                      clientStatus={clientStatus}
                      clientEmail={clientEmail}
                      updateState={this.updateState}
                    />
                    <ClientAccess
                      id={client.id}
                      clientStatus={clientStatus}
                      updateState={this.updateState}
                    />
                    {/* <Geolocalisation /> */}
                    <Commercials
                      clientCommercials={client.commercials}
                      updateState={this.updateState}
                    />
                  </div>
                  <div className="client__row">
                    <PersonalInformation
                      title="Informations client"
                      type="client"
                      name={client.name}
                      telephone={clientUsername && client.customers[0].tel_number1}
                      fax={clientUsername && client.customers[0].fax}
                      email={clientUsername && client.customers[0].email}
                    />
                    <Terms clientTerms={client.terms} updateState={this.updateState} />
                    {temperatures.length !== 0 && (
                      <Planning
                        clientPlannings={client.delivery_plannings}
                        updateState={this.updateState}
                        temperatures={temperatures}
                        deliveryModes={deliveryModes}
                        platformDeliveryMode={platformDeliveryMode}
                      />
                    )}
                    {/* <Temperature /> */}
                  </div>
                  <div className="client__row">
                    <Address
                      address={client.address}
                      city={client.city}
                      country={client.country}
                      zipCode={client.zipcode}
                    />
                    {temperatures.length !== 0 && (
                      <Franco
                        id={client.id}
                        clientFranco={client.client_franco}
                        updateState={this.updateState}
                        temperatures={temperatures}
                      />
                    )}
                    {platformDeliveryMode && (
                      <DeliveryMode
                        clientId={client.id}
                        clientDeliveryModes={client.client_delivery_modes}
                        updateState={this.updateState}
                      />
                    )}
                  </div>
                  <div>
                    <Selection client={client} />
                  </div>
                </div>
              )}
              {user.type === TYPE_COMMERCIAL && (
                <div>
                  <div className="page-profil__button-content">
                    {/*
                      <ButtonPrimary inverted className="item-action__button">
                        <Icon content="\e945" />
                        <span>ENVOYER UNE SÉLECTION</span>
                      </ButtonPrimary>
                      */}
                    {client.locked && (
                      <span className="page_profil__locked-message">
                        <GiPadlock /> Client bloqué en comptabilité
                      </span>
                    )}
                    <ButtonPrimary
                      inverted
                      className="item-action__button"
                      onClick={() => openRedirectModal(clientUsername)}
                    >
                      <Icon content="\e910" />
                      <span>PASSER UNE COMMANDE</span>
                    </ButtonPrimary>
                  </div>
                  {status === STATUS_ACTIVE &&
                    statusPreorder === STATUS_BLOCKED &&
                    statusCatalog === STATUS_BLOCKED && <Alert text="ATTENTION : Client bloqué" />}
                  <div className="client__row">
                    <PersonalInformation
                      title="Informations client"
                      type="client"
                      name={client.name}
                      telephone={clientUsername && client.customers[0].tel_number1}
                      fax={clientUsername && client.customers[0].fax}
                      email={clientUsername && client.customers[0].email}
                    />
                    {temperatures.length !== 0 && (
                      <Franco
                        readOnly
                        client={client}
                        clientFranco={client.client_franco}
                        temperatures={temperatures}
                      />
                    )}
                  </div>
                  <div className="client__row">
                    <Address
                      address={client.address}
                      city={client.city}
                      country={client.country}
                      zipCode={client.zipcode}
                    />
                    <Terms clientTerms={client.terms} readOnly />
                  </div>
                  <div className="client__row">
                    <ClientAccess
                      id={client.id}
                      clientStatus={clientStatus}
                      updateState={this.updateState}
                    />
                  </div>
                  <div>
                    <Selection client={client} />
                  </div>
                </div>
              )}
              {status === STATUS_ACTIVE ? (
                <div className="page-profil__alert-errors">
                  {commercials.length === 0 && (
                    <span className="alert-bloc__label">
                      Veuillez affecter un commercial au client.
                    </span>
                  )}
                  {terms.length === 0 && (
                    <span className="alert-bloc__label">CGV non attribuées.</span>
                  )}
                  {!this.checkDeliveryPlanningsOk() && user.type === TYPE_ADMIN && (
                    <span className="alert-bloc__label">Planning(s) non renseigné(s).</span>
                  )}
                  {clientFrancos &&
                  clientFrancos.find(
                    ({ enable, temperature, status: francoStatus, amount }) =>
                      enable && temperature && !(amount && francoStatus)
                  ) && ( // eslint-disable-next-line
                      <span className="alert-bloc__label">
                        Montant et/ou statut du Franco invalides.
                      </span>
                    )}
                </div>
              ) : (
                <div className="page-profil__alert-errors">
                  <span className="alert-bloc__label">Veuillez activer le client.</span>
                </div>
              )}

              <div className="client-validate page-profil__button-content">
                <ButtonPrimary
                  className="item-action__button"
                  disabled={!this.isAbleToSave()}
                  onClick={this.saveChanges}
                >
                  <span>Enregistrer</span>
                </ButtonPrimary>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, platform, deliveryPlanning } = state;
  const selectedPlatform = Number(platform.selectedId) || null;
  return {
    client: selectClientDetail(state),
    selectedPlatform,
    platform: platform.platforms.find((p) => Number(p.id) === Number(selectedPlatform)) || {},
    user: user.information,
    allDeliveryPlannings: deliveryPlanning.items || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  const modalType = 'redirect';
  const modalProps = {
    modalHeader: '',
  };
  return {
    fetchClientDetail: (clientId) => dispatch(clientActions.fetchClientDetail(clientId)),
    updateClient: (id, client) =>
      dispatch(clientActions.updateClientDetail(Number(id), client, true)),
    openRedirectModal: (username) =>
      dispatch(modalActions.open(modalType, { ...modalProps, username })),
  };
};

Client.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
  selectedPlatform: PropTypes.number,
  platform: PropTypes.object,
  fetchClientDetail: PropTypes.func,
  openRedirectModal: PropTypes.func,
  updateClient: PropTypes.func,
  allDeliveryPlannings: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(withClientStyle(Client));
