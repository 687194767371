import styled from 'styled-components';
import { ButtonPrimary } from 'components/Core/Button/Button';

export const ButtonP = styled(ButtonPrimary)`
  height: 40px;
  text-align: left;

  .header__button-content {
    margin: auto;
    font-size: 16px;
    padding: 0 20px;
  }

  .header__button-title {
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: Montserrat;
    .header__button-title-small {
      line-height: 13px;
      font-size: 10px;
    }
    .header__button-title-amount {
      font-weight: 600;
    }
  }
`;

export const ErrorDIV = styled.div`
  .icon-check-ok:before {
    color: #29d24e;
    font-size: 20px;
  }
  .error-page__button-block a:last-child i {
    font-size: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .error-page__text {
    text-align: center;
    span:first-child {
      color: #01366b;
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 40px;
      display: inline-block;
      width: 100%;
    }
    span:nth-child(2) {
      line-height: 24px;
      color: #787878;
      font-size: 20px;
      text-transform: uppercase;
      display: inline-block;
      width: 100%;
    }
  }

  .error-page__container {
    width: 100%;
    padding-top: 43px;
    text-align: center;
  }
  .error-page__icon .icon-check-ok:before {
    font-size: 14px;
  }
  .error-page__button-block {
    padding-top: 35px;
    letter-spacing: 0.15px;
    line-height: 15px;
    font-size: 12px;
  }
  .error-page__button {
    padding: 10px 20px;
    display: inline-block;
    margin-left: 18px;
  }

  .error-page__button i {
    margin-right: 10px;
  }

  .error-page__button .icon-eye {
    line-height: 0;
  }

  .error-page__button .icon-eye:before {
    font-size: 24px;
    top: 5px;
    position: relative;
  }
  .button-icon-save-label {
    letter-spacing: 0.15px;
    line-height: 15px;
    font-size: 12px;
  }

  @media screen and (max-width: 508px) {
    .error-page__text {
      width: 300px;
      font-size: 17px;
    }
    .error-page__button {
      margin: 4%;
      display: inline-block;
    }
    .error-page__icon {
      margin: 9%;
    }
  }
`;
