import React, { useState, useRef } from 'react';
import { GiPadlock } from '@react-icons/all-files/gi/GiPadlock';
import Tooltip from 'components/Core/Tooltip/Tooltip';
import styled from 'styled-components';

const ClientLockedCellWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    font-size: 16px;
    fill: #e0a800;
  }
`;

const ClientLockedCell = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  return (
    <ClientLockedCellWrapper
      onMouseEnter={(event) => {
        setIsTooltipVisible(true);
        setParentElement(event.target);
      }}
      onMouseLeave={() => {
        setIsTooltipVisible(false);
      }}
    >
      <Tooltip
        isVisible={isTooltipVisible}
        tooltipPosition="right"
        labelValue="Client bloqué en comptabilité"
        parentElement={parentElement}
      />
      <GiPadlock />
    </ClientLockedCellWrapper>
  );
};

export default ClientLockedCell;
