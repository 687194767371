import styled from 'styled-components';
import colors from 'styles/colors';

const getBgColor = (color, hover, disabled) => {
  if (disabled) {
    return color.disabled;
  }
  if (hover) {
    return color.hover;
  }
  return color.default;
};

export const ArticleListWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-top: 25px;
  display: flex;

  .article-list__group-list__container {
    max-width: 200px;
    padding-top: 74px;
    .article-list__group-list__title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;

      a {
        font-size: 25px;
        margin-right: 30px;
        cursor: pointer;
      }
    }
    .article-list__group-list {
      height: calc(100vh - 250px);
      overflow: auto;
      min-width: 160px;
      list-style: none;
      padding: 0;
      margin-right: 30px;

      .article-list__group-list__input-container {
        display: flex;
        span {
          margin-left: 5px;
          font-weight: bolder;
          color: red;
          cursor: pointer;
        }
        input {
          width: 100%;
        }
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;

        span {
          display: flex;
          align-items: center;
          &:first-child {
            width: calc(100% - 20px);
          }
          input[type='checkbox'] {
            appearence: auto;
            margin-left: 0;
          }

          label {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            opacity: ${(props) => (props.isEditingGroup ? 0.3 : 1)};

            &:hover {
              text-decoration: ${(props) => (props.isEditingGroup ? 'none' : 'underline')};
              cursor: ${(props) => (props.isEditingGroup ? 'inherit' : 'pointer')};
            }
          }

          .article-list__group-list--inactive {
            opacity: 0.3;
          }

          .article-list__group-list--active {
            font-weight: bold;
          }

          input[type='text'] {
            margin: 4px;
            width: 100%;
          }

          svg {
            display: none;
            cursor: pointer;
          }
        }

        &:hover {
          svg {
            display: block;
          }
        }
      }
    }
  }

  .article-list__aggrid-container {
    width: 100%;

    .article-list__title {
      font-size: 20px;
      font-weight: bold;
    }

    .ag-popup {
      height: auto !important;
    }

    .ag-row-selected {
      background: #f0f1f6;
    }

    .article-list__button-container {
      margin: 10px 0;
      display: flex;
      .dd-header {
        color: #ffffff;
        background-color: white;
        padding: 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;

        background-color: ${(props) => getBgColor(colors.primary, false, props.disabled)};
        border: 1px solid ${(props) => getBgColor(colors.primary, false, props.disabled)};
        color: #ffffff;

        border-radius: 5px;
        &:hover {
          background-color: ${(props) => getBgColor(colors.primary, true, props.disabled)};
          border: 1px solid ${(props) => getBgColor(colors.primary, true, props.disabled)};
          color: #ffffff;
        }
      }
      .dd-list {
        ul {
          list-style: none;
          padding: 0 15px;

          li {
            display: flex;
            margin-top: 5px;
          }
        }
      }
      a {
        border-radius: 5px;
        padding: 5px;
        margin-right: 5px;
      }
    }

    .ag-theme-balham {
      width: 100%;
      height: calc(100vh - 200px) !important;

      .ag-react-container {
        height: 100%;
      }

      .status-cell {
        svg {
          min-width: 15px;
          max-width: 15px;
          height: 15px;
          margin-right: 5px;
          padding: 0;
        }
      }

      .article-list__status {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .article-list__status__active,
        .article-list__status__inactive {
          height: 0;
          width: fit-content;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          color: #ffffff;
        }

        .article-list__status__active {
          background: #35e687;
        }

        .article-list__status__inactive {
          background: #f84746;
        }
      }
    }

    .ag-popup {
      height: auto !important;
    }
  }
`;
