import React from 'react';
import { connect } from 'react-redux';
import { BsFillHeartFill } from '@react-icons/all-files/bs/BsFillHeartFill';
import PropTypes from 'prop-types';
import { store } from 'helpers';
import { selectItemsFromCatalog, selectTopSales } from 'selectors/product';
import { doFiltersPass } from 'helpers/Filter';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import { selectAggridFilter } from 'selectors/aggridFilter';

import './CatalogControls.scss';

function FavoriteControl(props) {
  const { counters, favoriteCounter, notifCounterColor } = props;

  return (
    <>
      <BsFillHeartFill style={{ fill: notifCounterColor }} />
      <span>Favoris</span>
      {/* <div className="catalog-controls__tab-notif" style={{ background: notifCounterColor }}>
        ({counters > 99 ? '99+' : counters})
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  const filter = selectAggridFilter(state);
  const topSales = selectTopSales(state);

  return {
    favoriteCounter: selectItemsFromCatalog(state, { aggrid: true }).filter((item) =>
      doFiltersPass(
        aggridFilterConstants.FILTER_FAVORITE,
        filter.selection,
        filter.category,
        filter.availabilityList,
        filter.storeAvailabilityList,
        filter.temperatureList,
        item,
        topSales.items
      )
    ).length,
  };
};

FavoriteControl.propTypes = {
  notifCounterColor: PropTypes.string,
  favoriteCounter: PropTypes.number,
};

export default connect(mapStateToProps, null)(FavoriteControl);
