/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ handleCheck, isChecked, name, count, value }) => (
  <div className="filter-item checkbox">
    <label>
      <input onClick={handleCheck} type="checkbox" checked={isChecked} value={value} name={value} />
      <span>{name}</span>
      {!!count && <span className="bold">{` (${count})`}</span>}
      <span className="checkmark" />
    </label>
  </div>
);

CheckBox.propTypes = {
  handleCheck: PropTypes.func,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  count: PropTypes.number,
  value: PropTypes.string,
};

export default CheckBox;
