import { dayOffService } from 'services';
import { dayOffConstants } from '../constants';

export const dayOffActions = {
  deleteDayOff,
  fetchDayOff,
  createDayOff,
};

function fetchDayOff(filter = {}, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    dayOffService.fetchDayOff(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: dayOffConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: dayOffConstants.FETCH_SUCCESS, items, filter };
  }
  function failure(error) {
    return { type: dayOffConstants.FETCH_FAILURE, error };
  }
}

function deleteDayOff(dayOffId) {
  return (dispatch) => {
    dispatch(request());
    dayOffService.deleteDayOff(dayOffId).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: dayOffConstants.DELETE_REQUEST, dayOffId };
  }
  function success() {
    return { type: dayOffConstants.DELETE_SUCCESS, dayOffId };
  }
  function failure(error) {
    return { type: dayOffConstants.DELETE_FAILURE, error };
  }
}

function createDayOff(platformId, day) {
  return (dispatch) => {
    dispatch(request());
    return dayOffService.createDayOff(platformId, day).then(
      (dayOff) => {
        dispatch(success(dayOff));
        return dayOff;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: dayOffConstants.CREATE_REQUEST };
  }
  function success(dayOff) {
    return { type: dayOffConstants.CREATE_SUCCESS, dayOff, filter: { platform: platformId } };
  }
  function failure(error) {
    return { type: dayOffConstants.CREATE_FAILURE, error };
  }
}
