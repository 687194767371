import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const orderItemsService = {
  fetchTotalRowNumber,
  fetchItems,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/order_item/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);

  return fetch(
    `${config.apiUrl}/api/order_item?filter=${urlFilter}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}
