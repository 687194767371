import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import DropdownSelect from 'components/Core/DropdownSelect/DropdownSelect';
import TypeIcon from 'components/Core/Icon/TypeIcon';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { BsFillGearFill } from '@react-icons/all-files/bs/BsFillGearFill';
import { ListWrapper } from 'components/Core/Dropdown/Dropdown.style';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { selectCurrentPlatform } from 'selectors/platform';
import { getAvailableTemperatures } from 'helpers/Platform';
import { CommercialOrderDropdownSelectWrapper } from 'components/CommercialOrderForm/CommercialOrderDropdownSelect/CommercialOrderDropdownSelect.style';
import { getIconClassByType } from 'helpers';
import { getProductImageFromEan } from 'helpers/Product';
import { productConstants } from '../../../constants';

const CommercialOrderDropdownSelect = ({
  itemList,
  isDisabled,
  inputRef,
  selectedItem,
  setSelectedItem,
  currentPlatform,
  withFilter,
}) => {
  const limit = 25;
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [searchType, setSearchType] = useState(false);
  const [temperatureFilter, setTemperatureFilter] = useState([]);
  const availableTemperatureList = getAvailableTemperatures(currentPlatform);
  const dropdownSelectColumns = [
    {
      id: 'image',
      Header: '',
      disableSortBy: true,
      accessor: (item) => item.ean13,
      Cell: ({ value }) => (
        <img
          src={getProductImageFromEan(value, productConstants.IMAGE_SIZE_SMALL)}
          alt={`${value}`}
          style={{ width: '35px', height: '35px' }}
          className="img-cell__img"
        />
      ),
    },
    {
      id: 'type',
      Header: '',
      disableSortBy: true,
      accessor: (item) => item.type,
      Cell: ({ value }) => <TypeIcon className="react-table__type-icon" type={value} />,
    },
    {
      id: 'reference',
      Header: 'Référence',
      disableSortBy: true,
      accessor: (item) => item.reference,
    },
    {
      id: 'name',
      Header: 'Libellé',
      disableSortBy: true,
      accessor: (item) => item.name,
    },
    {
      id: 'boxStock',
      Header: 'Stk colis',
      disableSortBy: true,
      accessor: (item) => (Number(item.value_packing) > 0 ? item.value_packing : '0'),
      Cell: ({ value }) => <span style={{ color: value < 1 ? 'red' : '' }}>{value}</span>,
    },
    {
      id: 'unitStock',
      Header: 'Stk UC',
      disableSortBy: true,
      accessor: (item) => (Number(item.stock.value_cu) > 0 ? item.stock.value_cu : '0'),
      Cell: ({ value }) => <span style={{ color: value < 1 ? 'red' : '' }}>{value}</span>,
    },
    {
      id: 'pcb',
      Header: 'PCB',
      disableSortBy: true,
      accessor: (item) => item.pcb,
    },
    {
      id: 'boxPrice',
      Header: 'Prix colis',
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {value.has_promotion ? (
            <>
              <strike style={{ color: 'red', marginRight: '5px' }}>
                <span style={{ color: 'grey' }}>{formatPrice(value.price * value.pcb)} € HT</span>
              </strike>
              {formatPrice(value.applicable_price * value.pcb)} € HT
              <span style={{ marginLeft: '5px' }}>
                <PromotionIcon />
              </span>
            </>
          ) : (
            <>{formatPrice(value.applicable_price * value.pcb)} € HT</>
          )}
        </div>
      ),
    },
    {
      id: 'unitPrice',
      Header: 'Prix UC',
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {value.has_promotion ? (
            <>
              <strike style={{ color: 'red', marginRight: '5px' }}>
                <span style={{ color: 'grey' }}>{formatPrice(value.price)} € HT</span>
              </strike>
              {formatPrice(value.applicable_price)} € HT
              <span style={{ marginLeft: '5px' }}>
                <PromotionIcon />
              </span>
            </>
          ) : (
            <>{formatPrice(value.applicable_price)} € HT</>
          )}
        </div>
      ),
    },
  ];

  const handleFilterItems = (list, searchInput) => {
    let newList = list;
    if (searchInput) {
      const lowerSearchInput = searchInput.toLowerCase();
      newList =
        searchType === false
          ? newList.filter(
              (item) =>
                item.name.toLowerCase().includes(lowerSearchInput) ||
                item.reference.toLowerCase().includes(lowerSearchInput) ||
                item.ean13.toLowerCase().includes(lowerSearchInput)
            )
          : newList.filter(
              (item) =>
                item.name.toLowerCase().startsWith(lowerSearchInput) ||
                item.reference.toLowerCase().startsWith(lowerSearchInput) ||
                item.ean13.toLowerCase().startsWith(lowerSearchInput)
            );
    }
    if (temperatureFilter.length) {
      newList = newList.filter((item) => temperatureFilter.includes(item.type));
    }

    return newList;
  };

  const handleTemperatureFilterChange = (temperature) => {
    const newTemperatureFilterList = temperatureFilter.includes(temperature)
      ? temperatureFilter.filter((item) => item !== temperature)
      : [...temperatureFilter, temperature];
    setTemperatureFilter(newTemperatureFilterList);
  };

  return (
    <CommercialOrderDropdownSelectWrapper>
      <DropdownSelect
        itemList={itemList.sort((itemA, itemB) => (itemA.name < itemB.name ? -1 : 1))}
        columns={dropdownSelectColumns}
        limit={limit}
        isDisabled={isDisabled}
        inputRef={inputRef}
        selectedItem={selectedItem}
        selectedLabel={selectedItem && `${selectedItem.reference} ${selectedItem.name}`}
        setSelectedItem={setSelectedItem}
        handleFilterItems={handleFilterItems}
      />
      {withFilter && (
        <Dropdown
          headerTitle={<BsFillGearFill />}
          listOpen={isSettingOpen}
          toggleDropDown={setIsSettingOpen}
          defaultIcon={false}
        >
          <ul className="dd-list">
            <ListWrapper>
              <li>
                <div className="bold">Type de recherche: </div>
                <ul className="dropdown__radio-container">
                  <li>
                    <input
                      type="radio"
                      id="containFilter"
                      checked={!searchType}
                      onChange={() => {
                        setSearchType(false);
                      }}
                    />
                    <label htmlFor="containFilter"> Contient</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="startWithFilter"
                      checked={searchType}
                      onChange={() => {
                        setSearchType(true);
                      }}
                    />
                    <label htmlFor="startWithFilter">Commence par</label>
                  </li>
                </ul>
              </li>
              <li>
                <div className="bold">Par type: </div>
                <ul className="commercial-order-dropdown__temperature-control">
                  {availableTemperatureList.map((temperature) => (
                    <li key={uuid()}>
                      <input
                        type="checkbox"
                        checked={temperatureFilter.includes(temperature.value)}
                        onChange={() => {
                          handleTemperatureFilterChange(temperature.value);
                        }}
                      />
                      <i className={`${getIconClassByType(temperature.value)}`} />
                    </li>
                  ))}
                </ul>
              </li>
            </ListWrapper>
          </ul>
        </Dropdown>
      )}
    </CommercialOrderDropdownSelectWrapper>
  );
};

CommercialOrderDropdownSelect.propTypes = {
  itemList: PropTypes.array,
  isDisabled: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentPlatform: selectCurrentPlatform(state),
});

export default connect(mapStateToProps)(CommercialOrderDropdownSelect);
