import React from 'react';
import { connect } from 'react-redux';
import { ModalWrapper } from 'components/Modal';
import { selectToastList } from 'selectors/toast';
import { MODAL_TYPES } from '../constants/Modal.constants';

const LayoutWithModal = ({
  layout: Layout,
  modalProps,
  isOpen,
  modalType,
  toastList,
  ...props
}) => {
  const modalComponent = MODAL_TYPES[modalType];
  const { modalHeader } = modalProps;
  return (
    <div>
      <Layout {...props} />
      {isOpen && (
        <ModalWrapper component={modalComponent} modalHeader={modalHeader} {...modalProps} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { modal } = state;
  const { isOpen, modalType, modalProps } = modal;
  return {
    isOpen,
    modalType,
    modalProps,
    toastList: selectToastList(state),
  };
};

export default connect(mapStateToProps, null)(LayoutWithModal);
