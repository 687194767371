export const COLUMNS = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Libellé produit',
    className: 'header_product-wording_credit',
    dataType: 'credit_order_wording',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Marque',
    className: 'header_product-brand',
    dataType: 'credit_order_manufacturer',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'PCB',
    className: 'header_product-pcb',
    dataType: 'credit_order_pcb',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Prix unitaire HT',
    className: 'header_product-price',
    dataType: 'credit_order_price',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Quantités',
    className: 'header_product-quantity',
    dataType: 'credit_order_quantity',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Total',
    className: 'header_product-total',
    dataType: 'credit_order_final_price',
  },
];
