import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import capitalize from 'lodash.capitalize';
import { userConstants } from '../../constants';

const { TYPE_CUSTOMER, TYPE_COMMERCIAL, TYPE_ADMIN } = userConstants;

const PersonalInformation = ({
  title,
  type,
  name,
  telephone,
  fax,
  email,
  namegerant,
  user,
  username,
  lastname,
  firstname,
}) => (
  <div className="page-profil__block-info">
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e949" />
      <span className="entete__label">{title}</span>
    </div>
    {type === 'client' && (
      <div className="block-info__content">
        <div className="content__ligne">
          <span className="bold ligne__attribut">Nom de la société :</span>
          <span className="ligne__value">{name}</span>
        </div>
        <div className="content__ligne">
          <span className="bold ligne__attribut">Nom du gérant :</span>
          <span className="ligne__value">{namegerant}</span>
        </div>
        <div className="content__ligne">
          <span className="bold ligne__attribut">Téléphone :</span>
          <span className="ligne__value">{telephone}</span>
        </div>
        <div className="content__ligne">
          <span className="bold ligne__attribut">Fax :</span>
          <span className="ligne__value">{fax}</span>
        </div>
        <div className="content__ligne">
          <span className="bold ligne__attribut">E-mail :</span>
          <span className="ligne__value">{email}</span>
        </div>
      </div>
    )}
    {type === 'personnal' &&
      (user === TYPE_CUSTOMER ? (
        <div className="block-info__content">
          <div className="content__ligne">
            <span className="bold ligne__attribut">Nom du magasin :</span>
            <span className="ligne__value">{name}</span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">Téléphone :</span>
            <span className="ligne__value">{telephone}</span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">Fax :</span>
            <span className="ligne__value">{fax}</span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">E-mail :</span>
            <span className="ligne__value">{email}</span>
          </div>
        </div>
      ) : (
        <div className="block-info__content">
          <div className="content__ligne">
            <span className="bold ligne__attribut">Code vendeur :</span>
            <span className="ligne__value">
              {TYPE_COMMERCIAL === user ? lastname : capitalize(username)}
            </span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">Nom vendeur :</span>
            <span className="ligne__value">
              {TYPE_COMMERCIAL === user || TYPE_ADMIN === user
                ? `${firstname || ''} ${lastname || ''}`.trim()
                : capitalize(username) || ''}
            </span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">Téléphone :</span>
            <span className="ligne__value">{telephone}</span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">Fax :</span>
            <span className="ligne__value">{fax}</span>
          </div>
          <div className="content__ligne">
            <span className="bold ligne__attribut">E-mail :</span>
            <span className="ligne__value">{email}</span>
          </div>
        </div>
      ))}
  </div>
);

PersonalInformation.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  fax: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  namegerant: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default PersonalInformation;
