import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import Icon from 'components/Icon/Icon';
import Moment from 'moment';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import InvoiceBreadCrumb from 'pages/Invoice/InvoiceBreadcumb';
import { creditActions, invoiceActions, modalActions, orderActions } from 'actions';
import {
  dateTimeToFormatedDate,
  getIconClassByType,
  getPath,
  getValueOfAttribut,
  history,
} from 'helpers';
import { selectCurrentInvoice, selectInvoiceListByFilter } from 'selectors/invoice';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import './InvoiceDetail.scss';
import Countdown from 'components/Core/Countdown/Countdown';
import { platformConstants, userConstants } from '../../../constants';

const { HAS_CREDIT_KEY } = platformConstants;

const InvoiceDetail = ({
  match,
  user,
  currentInvoice,
  getItemByID,
  selectedPlatform,
  downloadPdf,
  openModalCreateCredit,
}) => {
  const { TYPE_CUSTOMER: USER_TYPE_CUSTOMER } = userConstants;

  useEffect(() => {
    const { id } = (match && match.params) || {};
    // just for testing the credit validation modal
    if (selectedPlatform.id) {
      getItemByID(id);
    }
  }, []);

  const invoice = currentInvoice.item;
  const platformHasCredit = !!Number(
    getValueOfAttribut(selectedPlatform.attributs, HAS_CREDIT_KEY)
  );
  const dateLimitCreditRequest = !!invoice && Moment(invoice.date).add(72, 'hours');
  const getTotalCreditedUc = (item) => {
    let totalQuantity = 0;
    item.credits.forEach((credit) => {
      totalQuantity += credit.quantity;
    });

    return totalQuantity;
  };

  const columns = React.useMemo(() => [
    {
      id: 'image',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <img
          className="invoice-detail__react-table__img"
          src={value.item.images.small}
          alt={value.label}
        />
      ),
    },
    {
      id: 'name',
      Header: 'Libellé',
      accessor: (item) => item.label,
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item.reference,
    },
    {
      id: 'ean',
      Header: 'EAN',
      accessor: (item) => item.ean13,
    },
    {
      id: 'pcb',
      Header: 'PCB',
      accessor: (item) => item.pcb,
    },
    {
      id: 'packageQuantity',
      Header: 'Qtés colis',
      accessor: (item) => item.quantity_invoiced_package,
      Cell: ({ value }) => <span className="invoice-detail__react-table__quantity">{value}</span>,
    },
    {
      id: 'unitQuantity',
      Header: 'Qtés UC',
      accessor: (item) => item.quantity_invoiced_u_c,
      Cell: ({ value }) => <span className="invoice-detail__react-table__quantity">{value}</span>,
    },
    {
      id: 'packagePrice',
      Header: 'Prix colix HT',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <span className="invoice-detail__react-table__quantity">
          {formatPrice(Number(value.price) * Number(value.pcb))} € HT
        </span>
      ),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.final_price,
      Cell: ({ value }) => (
        <span className="invoice-detail__react-table__total">
          {formatPrice(Number(value))} € HT
        </span>
      ),
    },
    {
      id: 'credit',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      isHidden:
        user.type !== USER_TYPE_CUSTOMER ||
        !Number(getValueOfAttribut(selectedPlatform.attributs, HAS_CREDIT_KEY)),
      accessor: (item) => item,
      Cell: ({ value }) => (
        <>
          <span
            onClick={
              dateLimitCreditRequest > Moment() &&
              getTotalCreditedUc(value) < value.quantity_invoiced_u_c
                ? (event) => {
                    event.stopPropagation();
                    openModalCreateCredit(invoice, value, () => getItemByID(match.id));
                  }
                : null
            }
            className={
              dateLimitCreditRequest > Moment() &&
              getTotalCreditedUc(value) < value.quantity_invoiced_u_c
                ? 'invoice-detail__react-table__credit-button'
                : 'invoice-detail__react-table__credit-button-disabled'
            }
          >
            Demander un avoir
          </span>
        </>
      ),
    },
  ]);

  return (
    <>
      {invoice && (
        <div className="fade-in invoice-detail__container">
          <div className="invoice-detail__page-header">
            <InvoiceBreadCrumb type="detail" invoiceId={invoice.number} userType={user.type} />
          </div>
          <div className="invoice-detail__page-content">
            <div className="invoice-detail__invoice-number">
              <span>
                Facture n° <strong>{invoice.number} </strong>
                {invoice.order && (
                  <>
                    associé à la commande{' '}
                    <span
                      className="invoice-detail__order-link"
                      onClick={(event) => {
                        history.push(getPath('orderdetail').replace(':id', invoice.order.id));
                      }}
                    >
                      {invoice.order.number}
                    </span>
                  </>
                )}
              </span>
              {user.type !== USER_TYPE_CUSTOMER && (
                <span>
                  Client n° <strong>{invoice.client.ext_code}</strong> - {invoice.client.name}
                </span>
              )}
            </div>
            <div className="invoice-detail__invoice-info">
              <div className="invoice-detail__invoice-info__type">
                {invoice.order && (
                  <span className="invoice-detail__invoice-info__type-icon">
                    {invoice.order.is_preorder && <Icon content="\e941" />}
                    {!invoice.order.is_preorder && <Icon content="\e94c" />}
                  </span>
                )}
                {invoice &&
                  Object.entries(invoice.totals_by_type).map((temperature) => (
                    <span>
                      <i className={`${getIconClassByType(temperature[0])} temp-icon`} />
                    </span>
                  ))}
              </div>
              <div className="invoice-detail__invoice-info__price-container">
                <div className="invoice-detail__invoice-info__price">
                  {formatPrice(invoice.total_price)} € HT
                </div>
                <div className="invoice-detail__invoice-info__package">
                  <span>{invoice.invoice_items.length}</span>{' '}
                  {invoice.invoice_items.length > 1 ? 'références' : 'référence'}
                </div>
              </div>
              <div className="invoice-detail__invoice-info__date-container">
                <div>Date de facture</div>
                <div className="invoice-detail__invoice-info__date">
                  {dateTimeToFormatedDate(invoice.date_create)}
                </div>
              </div>
              <div className="invoice-detail__invoice-info__credit">
                <div className="invoice-detail__invoice-info__credit-title">
                  <div>Avoir(s) associé(es)</div>
                  <ul>
                    {invoice.invoice_items.map((invoiceItem) =>
                      invoiceItem.credits.map((credit) => (
                        <li>
                          Ref. {invoiceItem.reference} pour {credit.quantity} UC de{' '}
                          {formatPrice(invoiceItem.price * credit.quantity)} € HT
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
              <div className="invoice-detail__invoice-info__actions">
                <span
                  onClick={() => {
                    downloadPdf(invoice);
                  }}
                >
                  <i className="icon-fac" /> Télécharger la facture
                </span>
              </div>
            </div>
            {user.type === USER_TYPE_CUSTOMER &&
              !!Number(getValueOfAttribut(selectedPlatform.attributs, HAS_CREDIT_KEY)) && (
                <div className="invoice-detail__credit-request-warning">
                  <IoWarning />
                  {dateLimitCreditRequest > Moment() && (
                    <>
                      <span>Vous avez </span>
                      <Countdown limitDate={dateLimitCreditRequest} />
                      <span> pour réaliser votre demande d'avoir</span>
                    </>
                  )}
                  {dateLimitCreditRequest <= Moment() && (
                    <span>
                      Le délai de 72 heures pour réaliser votre demande d'avoir est expiré
                    </span>
                  )}
                </div>
              )}
            <ReactTable columns={columns} data={invoice.invoice_items} />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { order, invoice, invoiceItems } = state;
  const invoiceId = (ownProps.match && ownProps.match.params.id) || {};
  const { collections: invoiceCollections } = invoice;
  const { collections: invoiceItemsCollections, isLoading: invoiceIsLoading } = invoiceItems;
  const currentPlatform = selectCurrentPlatform(state);

  return {
    currentInvoice: selectCurrentInvoice(state),
    invoiceList: selectInvoiceListByFilter(state, { id: invoiceId, platform: currentPlatform.id }),
    pdfIsLoading: order.pdfIsLoading || false,
    invoiceCollections,
    invoiceItemsCollections,
    user: selectCurrentUser(state),
    selectedPlatform: currentPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItemByID: (invoiceId) => dispatch(invoiceActions.fetchInvoice(invoiceId)),
  downloadPdf: (invoice) => dispatch(invoiceActions.downloadInvoiceFormPdf(invoice)),
  updateCreditList: (creditList) => dispatch(creditActions.updateCreditList(creditList)),
  createCredits: (creditList, invoiceFilter) =>
    dispatch(creditActions.createCredits(creditList, invoiceFilter)),
  renewOrder: (id) => dispatch(orderActions.renewOrder(id, true)),
  openModalCreateCredit: (invoice, invoiceItem) =>
    dispatch(
      modalActions.open('addCredit', {
        width: '500',
        invoice,
        invoiceItem,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
