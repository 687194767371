import styled from 'styled-components';

export const ReactTableWrapper = styled.div`
  margin: 25px 0;
  border-radius: 8px;
  border-bottom-width: 1px;
  box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  overflow: auto;

  .infinite-scroll-component {
    overflow: visible !important;
  }

  table {
    width: 100%;
    border-spacing: 1px;
    border-collapse: collapse;

    thead {
      text-align: left;
      border-bottom: 1px solid #e5e7eb;
      white-space: nowrap;
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      th {
        position: sticky;
        top: 0;
        background: #dbdee7;
        border-right: 1px solid #fff;
        &:last-child {
          border-right: none;
        }
      }
    }
    tr {
      border-bottom: 1px solid #e5e7eb;
    }

    th,
    td {
      margin: 0;
      padding: 14px 14px;
    }

    .react-table__no-result {
      border-bottom: none;
      td {
        margin: 20px auto;
        text-align: center;
      }
    }
  }
  .react-table__loader {
    border-bottom: none;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .react-table__row-counter {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    left: 0;
  }
`;
