export const cartItemsConstants = {
  FETCH_COUNT_REQUEST: 'CART_ITEMS_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'CART_ITEMS_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'CART_ITEMS_COUNT_FETCH_FAILURE',
  FETCH_REQUEST: 'CART_ITEMS_FETCH_REQUEST',
  FETCH_SUCCESS: 'CART_ITEMS_FETCH_SUCCESS',
  FETCH_FAILURE: 'CART_ITEMS_FETCH_FAILURE',
  ADD_UPDATE_TEMPS: 'CART_ITEMS_ADD_UPDATE_TEMPS',
  ADD_REQUEST: 'CART_ITEMS_ADD_REQUEST',
  ADD_SUCCESS: 'CART_ITEMS_ADD_SUCCESS',
  ADD_TEMPS_CART_SUCCESS: 'CART_ITEMS_ADD_TEMPS_CART_SUCCESS',
  ADD_FAILURE: 'CART_ITEMS_ADD_FAILURE',
  UPDATE_REQUEST: 'CART_ITEMS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CART_ITEMS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CART_ITEMS_UPDATE_FAILURE',
  DELETE_REQUEST: 'CART_ITEM_DELETE_REQUEST',
  DELETE_SUCCESS: 'CART_ITEM_DELETE_SUCCESS',
  DELETE_FAILURE: 'CART_ITEM_DELETE_FAILURE',
};
