import { manufacturerConstants } from '../constants';

const initState = {
  collections: [],
  filterManufacturers: [],
  isLoading: false,
};

export function manufacturer(state = initState, action) {
  let { filter } = action;
  if (!filter) {
    filter = {};
  }

  let { collections } = state;
  let collection = collections.find((m) => JSON.stringify(m.filter) === JSON.stringify(filter));
  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  switch (action.type) {
    case manufacturerConstants.FETCH_REQUEST:
      return {
        ...state,
        filterManufacturers: [],
        isLoading: true,
      };
    case manufacturerConstants.FETCH_SUCCESS:
      return {
        ...state,
        filterManufacturers: action.manufacturers,
        isLoading: false,
      };
    case manufacturerConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case manufacturerConstants.UPDATE_FOR_COLLECTION:
      collection = Object.assign(collection, { items: action.manufacturers, isLoading: false });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    default:
      return state;
  }
}
