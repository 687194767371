// Importing filterConstants from the absolute path (`constants`) bugs.
import { filterConstants } from '../constants';

const initialState = {
  isOpen: false,
  props: {},
  isClientsFilter: false,
  clientsFilter: {},
  catalogFilter: {},
};

export function filter(state = initialState, action) {
  switch (action.type) {
    case filterConstants.OPEN:
      return {
        ...state,
        isOpen: true,
        isClientsFilter: action.isClientsFilter,
        props: { ...action.props },
      };
    case filterConstants.CLOSE:
      return {
        ...state,
        props: {},
        isOpen: false,
      };
    case filterConstants.UPDATE_FILTER:
      return {
        ...state,
        ...action.filterData,
      };
    case filterConstants.DELETE_FILTER:
      return action.filterType
        ? {
            ...state,
            clientsFilter: {
              ...state.clientsFilter,
              [action.sectionKey]: state.clientsFilter[action.sectionKey].filter(
                (v) => v !== action.value
              ),
            },
          }
        : {
            ...state,
            catalogFilter: {
              ...state.catalogFilter,
              [action.sectionKey]: state.catalogFilter[action.sectionKey].filter(
                (v) => v !== action.value
              ),
            },
          };
    case filterConstants.DELETE_ALL:
      return {
        ...state,
        clientsFilter: {},
        catalogFilter: {},
      };
    default:
      return state;
  }
}
