// Importing catalogConstants from the absolute path (`constants`) bugs.
import { orderItemsConstants } from '../constants';

const initState = {
  collections: [],
  collectionsCount: [],
};

export function orderItems(state = initState, action) {
  if (!Object.keys(orderItemsConstants).find((key) => orderItemsConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case orderItemsConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case orderItemsConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case orderItemsConstants.FETCH_COUNT_FAILURE:
      return state;
    case orderItemsConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case orderItemsConstants.FETCH_SUCCESS:
      collection = Object.assign(collection, { items: action.items, isLoading: false });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case orderItemsConstants.FETCH_FAILURE:
      return state;
    default:
      return state;
  }
}
