import { userActions, alertActions, maintenanceActions } from 'actions';
import { history, getPath, store } from 'helpers';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        const u = JSON.parse(localStorage.getItem('user') || null);

        if (u && u.refresh_token) {
          store.dispatch(userActions.loginRefresh(u.refresh_token));
        } else {
          // auto logout if 401 response returned from api
          store.dispatch(userActions.logout());
          history.push(getPath('login'));
          store.dispatch(alertActions.error('Votre session a expirée, veuillez vous reconnecter.'));
        }
      }
      if (response.status === 503) {
        store.dispatch(maintenanceActions.success({ ...data, is_alive: false }));
        history.push(getPath('maintenance'));
      }
      if (data && data.error) {
        return Promise.reject(data.error_description);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
