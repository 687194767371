import styled from 'styled-components';

export default (Component) => styled(Component)`
  input {
    border: 0;
    border-bottom: 1px solid rgba(120, 120, 120, 0.6) !important;
    color: #787878;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    outline: none;
  }

  .modal-update-commercial__content-bloc {
    .modal-update-commercial__content-bloc__field {
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      span {
        width: 100%;
        display: inline-block;
        color: #01366b;
      }
      input {
        padding: 10px;
        display: inline-block;
        width: 100%;
      }
      input[type='number']::placeholder {
        color: #787878;
      }
    }
    .modal-update-commercial__content-bloc__field-mandatory {
      span {
        &:after {
          content: '*';
          color: #d0021b;
          font-size: 17px;
        }
      }
    }
    a {
      padding: 11px 15px;
      width: 135px;
      margin: 10px auto 0px auto;
      display: block;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1.06px;
      line-height: 16px;
      text-align: center;
    }
    .modal-update-commercial__content-bloc__notification {
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      text-align: center;
      font-size: 12px;
      color: #d0021b;
    }
  }
`;
