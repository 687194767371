/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { getPath, getCollection } from 'helpers';
import { modalActions, categoryActions, assortmentActions } from 'actions';
import { Assortment } from 'components/Prospect';
import Icon from 'components/Icon/Icon';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import Spinner from 'components/Core/Spinner/Spinner';
import withNewAssortmentStyle from './NewAssortment.style';
import { categoryConstants } from '../../constants';

class NewAssortmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.assortmentNameRef = React.createRef();
  }

  componentDidMount() {
    const {
      fetchCategories,
      match,
      fetchAssortments,
      fetchAssortmentDetail,
      platformId,
    } = this.props;
    const { params } = match || {};
    const { clientId, assortmentId } = params || {};

    if (platformId) {
      fetchCategories(platformId, clientId, true, categoryConstants.STATUS_CATALOG);
      fetchAssortments({ client: Number(clientId), status: categoryConstants.STATUS_SELECTION });
    }

    if (assortmentId) {
      fetchAssortmentDetail(Number(assortmentId));
    }
  }

  componentDidUpdate(prevProps) {
    const { match, fetchAssortmentDetail, fetchAssortments, fetchCategories } = this.props;
    const { match: prevMatch, platformId: prevPlatformId } = prevProps;
    const { platformId } = this.props;
    const { params } = match || {};
    const { clientId, assortmentId } = params || {};
    const { params: prevParams } = prevMatch || {};
    const { clientId: prevClientId, assortmentId: prevAssortmentId } = prevParams || {};
    if (assortmentId !== prevAssortmentId) {
      fetchAssortmentDetail(Number(assortmentId));
    }
    if (clientId !== prevClientId || prevPlatformId !== platformId) {
      fetchAssortments({ client: Number(clientId), status: categoryConstants.STATUS_SELECTION });
      fetchCategories(platformId, clientId, true, categoryConstants.STATUS_CATALOG);
    }
  }

  componentWillUnmount() {
    const { resetStore } = this.props;
    resetStore();
  }

  loadFromSelection = (e) => {
    const { fetchAssortmentDetail, updateStore } = this.props;
    const id = Number(e.target.value);
    if (id) {
      fetchAssortmentDetail(id);
    } else {
      updateStore('checked', []);
    }
  };

  handleSubmit = () => {
    const { match, checked, createAssortment, updateAssortment, name, date } = this.props;
    const { params } = match || {};
    const { clientId, assortmentId } = params || {};
    if (name && date && clientId && checked.length > 0) {
      const redirectLink = getPath('clientDetail').replace(':id', clientId);
      if (!assortmentId) {
        // create
        createAssortment(
          {
            name,
            label: name,
            items: checked,
            client: { id: Number(clientId) },
            date_end: date || null,
            status: categoryConstants.STATUS_SELECTION,
          },
          redirectLink
        );
      } else {
        updateAssortment(
          assortmentId,
          {
            name,
            label: name,
            date_end: date || null,
            items: checked,
          },
          redirectLink
        );
      }
    }
  };

  openDatepicker = () => {
    this._calendar.setOpen(true);
  };

  keyPress = (e) => {
    if (e.key === 'Enter') {
      const { current: assortmentNameRef } = this.assortmentNameRef;
      assortmentNameRef.blur();
    }
  };

  render() {
    const {
      className,
      openUploadModal,
      match,
      collections,
      checked,
      name,
      date,
      updateStore,
      isLoading,
    } = this.props;

    const { params } = match || {};
    const { clientId, ext_code: extCode, assortmentId } = params || {};
    const filter = { client: Number(clientId), status: categoryConstants.STATUS_SELECTION };
    const { items: assortmentsAvailable } = getCollection(collections, filter);
    const url = [
      { text: 'Accueil ', href: getPath('dashboardAdmin') },
      { text: 'Mes Clients', href: getPath('clientList') },
      { text: `Client ${extCode}`, href: getPath('clientDetail').replace(':id', clientId) },
    ];
    const currentPage = assortmentId ? 'Modifier une sélection' : 'Créer une sélection';
    return (
      <div className={className}>
        <div className="assortiment-page">
          <div className="assortiment-page__header">
            <BreadCrumb urlTab={url} currentPage={currentPage} />
            <div className="assortiment-page__header-label">
              <span className="bold">Créer une sélection</span>
            </div>
          </div>
          <div className="assortiment-page__content">
            <div className="assortiment-page__select-block">
              <div className="select-block">
                <input
                  type="text"
                  placeholder="LIBELLÉ DE LA SÉLECTION"
                  value={name}
                  onChange={(e) => updateStore('name', e.target.value)}
                  onKeyPress={this.keyPress}
                  ref={this.assortmentNameRef}
                  maxLength="30"
                />
              </div>
              <div className="select-block">
                <DatePicker
                  selected={
                    Moment(date).isValid() ? new Date(Moment(date).format('YYYY-MM-DD')) : ''
                  }
                  onChange={(day) => {
                    updateStore('date', day ? Moment(day.toString()).format('YYYY-MM-DD') : '');
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DATE DE FIN DE VALIDITÉ"
                  locale="fr"
                  ref={(c) => {
                    this._calendar = c;
                  }}
                />
                <Icon content="\e90e" onClick={this.openDatepicker} />
              </div>
              <div className="select-block">
                <span className="select-block__select">
                  <select onChange={this.loadFromSelection}>
                    <option value="">SÉLECTION EXISTANTE</option>
                    {(assortmentsAvailable || []).map((assortment) => (
                      <option key={assortment.id} value={assortment.id}>
                        {assortment.label || assortment.name || ''}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="select-block" hidden={assortmentId}>
                <ButtonSecondary
                  inverted
                  onClick={() => openUploadModal(clientId, date, name, extCode)}
                  disabled={!name}
                >
                  <Icon content="\e959" />
                  <span>Import excel</span>
                </ButtonSecondary>
              </div>
            </div>
            <div className="assortiment-page__selection-block">
              <Assortment />
            </div>
          </div>
          <div className="assortiment-action">
            <div className="errors">
              {!name && <span className="error">Veuillez saisir un libellé.</span>}
              {!date && <span className="error">Veuillez saisir une date de fin de validité.</span>}
              {!checked.length && (
                <span className="error">Veuillez sélectionner des produits.</span>
              )}
            </div>
            {isLoading ? (
              <div className="assortiment-action-spinner">
                <Spinner />
              </div>
            ) : (
              <ButtonPrimary
                onClick={this.handleSubmit}
                disabled={!name || !date || Number(checked.length) === 0}
              >
                <div className="assortiment-action-save-button">Valider</div>
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NewAssortmentPage.propTypes = {
  className: PropTypes.string.isRequired,
  match: PropTypes.object,
  openUploadModal: PropTypes.func,
  fetchCategories: PropTypes.func,
  fetchAssortmentDetail: PropTypes.func,
  fetchAssortments: PropTypes.func,
  createAssortment: PropTypes.func,
  updateAssortment: PropTypes.func,
  updateStore: PropTypes.func,
  collections: PropTypes.array,
  checked: PropTypes.array,
  name: PropTypes.string,
  date: PropTypes.string,
  resetStore: PropTypes.func,
  platformId: PropTypes.number,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { assortment, platform } = state;
  const {
    collections,
    assortment: { checked, name, date },
  } = assortment;
  const { selectedId: platformId } = platform;
  return {
    collections,
    checked: Object.values(checked),
    name,
    date,
    platformId,
    isLoading: assortment.isLoading || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openUploadModal: (clientId, dateEnd, label, clientExtCode) => {
    if (clientId && label) {
      const modalType = 'selection';
      const modalProps = {
        modalHeader:
          'Veuillez télécharger un fichier excel ne contenant que les codes produits souhaités en colonne A',
        width: '402',
        clientId,
        dateEnd,
        label,
        redirectLink: getPath('assortment')
          .replace(':clientId', clientId)
          .replace(':ext_code', clientExtCode),
      };
      dispatch(modalActions.open(modalType, { ...modalProps }));
    }
  },
  fetchCategories: (platformId, clientId, showItems, status) => {
    dispatch(categoryActions.fetchCategories(platformId, clientId, showItems, status));
  },
  fetchAssortmentDetail: (id) => {
    dispatch(assortmentActions.fetchAssortments({ id }, 0, 1, true));
  },
  fetchAssortments: (filter) => {
    dispatch(assortmentActions.fetchAssortments(filter, 0, 60));
  },
  createAssortment: (data, redirectLink) => {
    dispatch(assortmentActions.createAssortment(data, redirectLink));
  },
  updateAssortment: (id, data, redirectLink) => {
    dispatch(assortmentActions.updateAssortment(id, data, null, redirectLink));
  },
  updateStore: (key, value) => {
    dispatch(assortmentActions.updateStore(key, value));
  },
  resetStore: () => {
    dispatch(assortmentActions.resetStore());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNewAssortmentStyle(NewAssortmentPage));
