import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import CreditDetail from './CreditDetail';

const CreditDetailLayout = ({ match }) => <Layout component={CreditDetail} match={match} />;

CreditDetailLayout.propTypes = {
  match: PropTypes.object,
};

export default CreditDetailLayout;
