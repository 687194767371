import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const containerService = {
  saveContainers,
  getContainers,
};

function saveContainers(data) {
  const body = JSON.stringify({
    ...data,
    bins: JSON.stringify(data.bins),
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/v2.0/container/add`, requestOptions).then(handleResponse);
}

function getContainers(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/v2.0/container/order/${id}`, requestOptions).then(
    handleResponse
  );
}
