import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions, cartActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalAlertMoq.style';

const ModalValidateCart = ({ className, cart, validateCart, closeModal }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>Êtes-vous certains de valider cette précommande ?</span>
      </div>
      <div className="actions">
        <ButtonPrimary inverted onClick={() => closeModal()}>
          <span>Annuler</span>
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            closeModal();
            validateCart(cart);
          }}
        >
          <span>Valider</span>
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalValidateCart.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  cart: PropTypes.object,
  validateCart: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  validateCart: (cart) => dispatch(cartActions.validateCart(cart)),
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(withStyle(ModalValidateCart));
