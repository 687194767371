/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import PropTypes from 'prop-types';
import { getValueOfAttribut, getPath } from 'helpers';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { modalActions, deliveryPlanningActions } from 'actions';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { deliveryPlanningConstants, platformConstants } from '../../constants';
import WithPlanningsStyle from './WithPlanningsStyle';

const url = [
  {
    text: 'Accueil ',
    href: '/dashboard/logged-in',
  },
  {
    text: 'Paramètres',
  },
];
const currentPage = 'Plannings de livraison';
const { DAYS } = deliveryPlanningConstants;

const DeliveryPlanningDayItems = ({ days }) => {
  const listItemDay = [];
  for (let i = 1; i < 8; i += 1) {
    const isActive = days.find((day) => Number(day.week_day) === i);
    const className = isActive ? 'active' : '';
    listItemDay.push(<li className={className}>{DAYS[i - 1]}</li>);
  }
  return <ul className="planning__days">{listItemDay}</ul>;
};

const DeliveryPlanningItem = ({ item, openModal, classStyle }) => (
  <tr
    className={`row ${!item.id ? 'unclickable' : ''} ${classStyle || ''}`}
    onClick={(e) => {
      openModal(e, item);
    }}
  >
    <td data-label="Nom du planning" className="first-td">
      <span className="planning__type">{item.name}</span>
      {item.is_child || item.parent ? (
        <span className="planning__statut substitution">Substitution</span>
      ) : (
        <span className="planning__statut default">Default</span>
      )}
    </td>
    <td data-label="Date de debut" className="planning__date_start">
      <p>{item.date_begin ? Moment(item.date_begin).format('DD/MM/YYYY') : '_'}</p>
    </td>
    <td data-label="Date de fin" className="planning__date_end">
      <p>{item.date_end ? Moment(item.date_end).format('DD/MM/YYYY') : '_'}</p>
    </td>
    <td data-label="Jours">
      <DeliveryPlanningDayItems days={item.days} openModal />
    </td>
    <td className="actions">
      <span
        onClick={(e) => {
          openModal(e, item);
        }}
        className="edit"
      >
        <FaPencilAlt /> Editer
      </span>
    </td>
  </tr>
);

const DeliveryPlanningItems = ({ items, openModal }) => {
  const itemDisplayList = [];
  items.forEach((item) => {
    itemDisplayList.push(
      <DeliveryPlanningItem item={item} openModal={openModal} key={item.id} classStyle="parent" />
    );
    const { children } = item;
    if (children && children.length > 0) {
      children
        .sort((a, b) => a.date_create < b.date_create)
        .forEach((child) => {
          itemDisplayList.push(
            <DeliveryPlanningItem item={child} openModal={openModal} key={child.id} />
          );
        });
    }
  });
  return <tbody>{itemDisplayList}</tbody>;
};

const DeliveryPlanningSection = ({
  byTemperature,
  byDeliveryMode,
  platformDeliveryMode,
  openModal,
}) => {
  const displayBy = platformDeliveryMode ? byDeliveryMode : byTemperature;
  const itemDisplayList = Object.keys(displayBy)
    .sort((a, b) => (a <= b ? -1 : 1))
    .map((item) => {
      const items = displayBy[item].sort((a, b) => a.date_create < b.date_create);
      return (
        <div key={item} className={item}>
          <div className="sections">
            <div className="planning__section-temperature">
              <h4 className="headline-temperature">{item}</h4>
            </div>
          </div>
          <div className="planning__table">
            <table className="planning__table__sec table">
              <thead>
                <tr>
                  <th className="planning__name">Nom du planning</th>
                  <th className="planning__date_s">Date de debut</th>
                  <th className="planning__date_e">Date de fin</th>
                  <th className="planning__days">Jours</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              {items && <DeliveryPlanningItems items={items} openModal={openModal} />}
            </table>
          </div>
        </div>
      );
    });

  return <div>{itemDisplayList}</div>;
};

const { DELIVERY_MODE_KEY } = platformConstants;

class DeliveryPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasInitialized: false };
    this.getItemsByTemperature = this.getItemsByTemperature.bind(this);
    this.getItemsByDeliveryMode = this.getItemsByDeliveryMode.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    const { fetchItems } = this.props;
    fetchItems();
    this.setState({
      hasInitialized: true,
    });
  }

  componentDidUpdate() {
    const { hasInitialized } = this.state;
    const { fetchItems } = this.props;
    if (!hasInitialized) {
      this.setState({
        hasInitialized: true,
      });
      fetchItems();
    }
  }

  getItemsByTemperature() {
    const { deliveryPlanning } = this.props;
    const { items } = deliveryPlanning;
    const byTemperature = {};
    const itemList = [...(items || [])];

    itemList.forEach((item) => {
      if (item.temperature) {
        if (byTemperature[item.temperature] === undefined) {
          byTemperature[item.temperature] = [];
        }
        byTemperature[item.temperature].push(item);
      }
    });

    return byTemperature;
  }

  getItemsByDeliveryMode() {
    const { deliveryPlanning } = this.props;
    const { items } = deliveryPlanning;
    const byDeliveryMode = {};
    const itemList = [...(items || [])];
    const getDeliveryMode = (mode) => (mode ? 'Retrait' : 'Livraison');

    itemList.forEach((item) => {
      const newItem = {
        ...item,
        delivery_mode: !item.temperature ? getDeliveryMode(item.delivery_mode) : '',
      };
      if (newItem.delivery_mode) {
        if (byDeliveryMode[newItem.delivery_mode] === undefined) {
          byDeliveryMode[newItem.delivery_mode] = [];
        }
        byDeliveryMode[newItem.delivery_mode].push(newItem);
      }
    });
    return byDeliveryMode;
  }

  handelExtractExcel = () => {
    const { openExtractExcel } = this.props;
    openExtractExcel();
  };

  openModal(e, item) {
    const { openPlanningModal } = this.props;
    e.preventDefault();
    if (item) {
      if (item.id) {
        openPlanningModal(item);
      }
    } else {
      openPlanningModal();
    }
  }

  render() {
    const ongletList = [
      {
        text: 'Mes commerciaux',
        href: getPath('commercial'),
      },
      {
        text: 'Plannings de livraison',
        href: getPath('planning'),
        status: 'active',
      },
      {
        text: 'Jours fériés & fermeture',
        href: getPath('dayoff'),
      },
      {
        text: 'CGV appliquées',
        href: getPath('terms'),
      },
      {
        text: 'Paramètres précommande',
        href: getPath('settingsPreorder'),
      },
      {
        text: 'Messagerie client',
        href: getPath('message'),
      },
      {
        text: 'Extract XLS preco',
        handle: this.handelExtractExcel,
      },
    ];
    const { className, attributs } = this.props;
    const byTemperature = this.getItemsByTemperature();
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));

    const byDeliveryMode = this.getItemsByDeliveryMode(platformDeliveryMode);

    return (
      <div className={className}>
        <div className="page-planning">
          <div className="page-planning__heading">
            <BreadCrumb urlTab={url} currentPage={currentPage} />
            <OngletMenu list={ongletList} />
          </div>
          <div className="actions">
            <ButtonPrimary className="planning" onClick={(e) => this.openModal(e)}>
              <i className="icon-calendar" />
              <span>AJOUTER UN PLANNING</span>
            </ButtonPrimary>
          </div>
          <DeliveryPlanningSection
            byTemperature={byTemperature}
            byDeliveryMode={byDeliveryMode}
            platformDeliveryMode={platformDeliveryMode}
            openModal={this.openModal}
          />
        </div>
      </div>
    );
  }
}

DeliveryPlanningDayItems.propTypes = {
  days: PropTypes.array,
};

DeliveryPlanningItem.propTypes = {
  item: PropTypes.object,
  openModal: PropTypes.func,
  classStyle: PropTypes.string,
};

DeliveryPlanningSection.propTypes = {
  byTemperature: PropTypes.object,
  byDeliveryMode: PropTypes.object,
  openModal: PropTypes.func,
  platformDeliveryMode: PropTypes.bool,
};

DeliveryPlanningItems.propTypes = {
  items: PropTypes.array,
  openModal: PropTypes.func,
};

DeliveryPlanning.propTypes = {
  className: PropTypes.string,
  fetchItems: PropTypes.func,
  openPlanningModal: PropTypes.func,
  deliveryPlanning: PropTypes.object,
  attributs: PropTypes.array,
  openExtractExcel: PropTypes.func,
};

DeliveryPlanning.defaultProps = {
  deliveryPlanning: {
    items: [],
    isLoading: true,
  },
};

const mapStateToProps = (state) => {
  const {
    deliveryPlanning,
    platform: { selectedId, platforms },
  } = state;
  const { attributs } = platforms.find((p) => selectedId === p.id);
  return {
    attributs,
    deliveryPlanning,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchItems: () => {
    const filter = { and: [['dp.parent', 'isNull', null]] };
    dispatch(deliveryPlanningActions.fetchItems(filter));
  },
  openPlanningModal: (item) =>
    dispatch(
      modalActions.open('planning', {
        modalHeader: 'Ajouter ou modifier un planning',
        width: '600',
        item,
      })
    ),
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(WithPlanningsStyle(DeliveryPlanning));
