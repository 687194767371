import styled from 'styled-components';

export const NavigationDiv = styled.div`
  height: ${(props) => (props.isMenuOpen ? '100vh' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */

  .navigation--menu-wrapper {
    flex: 1; /* same as flex: 1 1 auto; */
    display: flex;
    flex-direction: row;

    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */

    padding: 0;
    margin: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.25);
    display: ${(props) => (props.isMenuOpen ? 'block' : 'none')};
  }

  .navigation--menu {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;
    flex: none;
    width: 100%;
  }

  @media all and (min-width: 800px) {
    .main {
      flex: 3 0px;
    }
    .main {
      order: 2;
    }
  }
`;
