export const aggridConstants = {
  SEARCH_FOR_PRODUCT: 'SEARCH_FOR_PRODUCT',
  FIXED_COLUMNS: [
    'quantity',
    'total',
    'availability',
    'agListQuantityCell',
    'mobileCell',
    'favoriteCell',
  ],
  FILTER_NO_FILTER: 'all',
  FILTER_FAVORITE: 'favorite',
  FILTER_PROMOTION: 'promotion',
  FILTER_NEW: 'new',
  TOGGLE_CATEGORY_DISPLAY: 'TOGGLE_CATEGORY_DISPLAY',
  LIST_ROW_HEIGHT: 106,
  LIST_MOBILE_ROW_HEIGHT: 160,
};
