import { aggridFilterConstants } from 'constants/AggridFilter.constants';

export const aggridFilterActions = {
  setCurrentFilters,
  toggleAggridView,
};

function setCurrentFilters(filters) {
  return (dispatch) => {
    dispatch({ type: aggridFilterConstants.SET_FILTERS, filters });
  };
}

function toggleAggridView() {
  return (dispatch) => {
    dispatch({ type: aggridFilterConstants.TOGGLE_VIEW });
  };
}
