export const categoryFlattener = (accumulatedCategories, category, parentId) => {
  const element = {
    ...category,
    parentId: parentId || -1,
    isItem: false, // will never get items in categories
  };
  const children = category.categories
    ? Object.values(category.categories)
    : Object.values(category.children || {});
  if (children.length > 0) {
    return children.reduce(
      (accumulator, child) => categoryFlattener(accumulator, child, category.id),
      [...accumulatedCategories, element]
    );
  }
  return [...accumulatedCategories, element];
};

export const searchParents = (categories, searchedParent) => {
  const parents = [];
  let parentId = searchedParent;
  while (parentId !== -1) {
    const currentParent = categories.find((category) => category.id === parentId); // eslint-disable-line
    if (currentParent) {
      parents.push(currentParent);
      parentId = currentParent.parentId; // eslint-disable-line
    } else {
      parentId = -1;
    }
  }
  return parents;
};

export const findCategoryById = (categories, currentCategoryId) =>
  categories.find((category) => category.id === currentCategoryId);

export const searchSiblings = (categories, searchedParent, excludeId = null) =>
  (categories || []).filter(
    (category) => category.parentId === searchedParent && category.id !== excludeId
  );

export const makeFilterFromCategoryId = (categoryId) => ({
  'categories.id': categoryId,
});
