import { rolesConstants } from 'constants/Roles';
import Login from 'pages/Auth/Login/Login';
import LandingPage from 'pages/LandingPage/LandingPage';
import PasswordRecovery from 'pages/Auth/PasswordRecovery/PasswordRecovery';
import PasswordChange from 'pages/Auth/PasswordRecovery/PasswordChange';
import Maintenance from 'pages/Maintenance/Maintenance';
import { DashboardAdminLayout } from 'pages/Dashboard/DashboardLayout';
import Profil from 'pages/Profil/ProfilLayout';
import Cart from 'pages/Cart/CartLayout';
import CommercialOrderLayout from 'pages/CommercialOrder/CommercialOrderLayout';
import CartListLayout from 'pages/Cart/CartListLayout';
import OrderListLayout from 'pages/Order/OrderList/OrderListLayout';
import InvoiceListLayout from 'pages/Invoice/InvoiceList/InvoiceListLayout';
import OrderDetailLayout from 'pages/Order/OrderDetail/OrderDetailLayout';
import InvoiceDetailLayout from 'pages/Invoice/InvoiceDetail/InvoiceDetailLayout';
import ConfirmationOrderLayout from 'pages/ConfirmationOrder/ConfirmationOrder';
import ClientLayout from 'pages/Client/ClientLayout';
import ClientListLayout from 'pages/Client/ClientListLayout';
import DeliveryPlanningLayout from 'pages/DeliveryPlanning/DeliveryPlanningLayout';
import MessagePageLayout from 'pages/Message/MessageLayout';
import DaysOffLayout from 'pages/DaysOff/DaysOffLayout';
import CommercialLayout from 'pages/Commercial/CommercialLayout';
import TermsLayout from 'pages/Terms/TermsLayout';
import SettingsPreorderLayout from 'pages/SettingsPreorder/SettingsPreorderLayout';
import NewAssortmentLayout from 'pages/Assortment/NewAssortmentLayout';
import ErrorLayout from 'pages/Error/Error';
import ConfigurationLayout from 'pages/Configuration/ConfigurationLayout';
import CreditDetailLayout from 'pages/Credit/CreditDetailLayout';
import LoadingLayout from 'pages/Loading/LoadingLayout';
import CommercialCartListLayout from 'pages/CommercialCartList/CommercialCartListLayout';
import OcrOrderMatchLayout from 'pages/OcrOrderMatch/OcrOrderMatchLayout';
import ArticleListLayout from 'pages/Article/ArticlesLayout';
import PromotionListLayout from 'pages/Promotion/PromotionLayout';
import RecurrentCartListLayout from 'pages/RecurentCart/RecurrentCartListLayout';
import AggridLayout from '../pages/Aggrid/AggridLayout';
import CatalogLayout from '../pages/Catalog/CatalogLayout';
import AggridNewLayout from '../pages/AggridNew/AggridNewLayout';
import OrderFromCsvLayout from '../pages/Order/OrderFromCsv/OrderFromCsvLayout';

const Routes = [
  {
    name: 'assortment',
    pathIsExact: true,
    path: '/assortiment/client/:clientId/:ext_code/:assortmentId?',
    component: NewAssortmentLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN, rolesConstants.ROLE_COMMERCIAL],
  },
  {
    name: 'message',
    pathIsExact: true,
    path: '/messages',
    component: MessagePageLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'planning',
    pathIsExact: true,
    path: '/plannings-de-livraisons',
    component: DeliveryPlanningLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'settingsPreorder',
    pathIsExact: true,
    path: '/parametrage-precommande',
    component: SettingsPreorderLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'dayoff',
    pathIsExact: true,
    path: '/day-off',
    component: DaysOffLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'commercial',
    pathIsExact: true,
    path: '/commercial',
    component: CommercialLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'commercialOrder',
    pathIsExact: true,
    path: '/commercial/order',
    component: CommercialOrderLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_COMMERCIAL, rolesConstants.ROLE_ADMIN],
  },
  // {
  //   name: 'product',
  //   pathIsExact: true,
  //   path: '/product/:id',
  //   component: ProductLayout,
  //   isPrivate: true,
  // },
  {
    name: 'clientDetail',
    pathIsExact: true,
    path: '/client/detail/:id',
    component: ClientLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN, rolesConstants.ROLE_COMMERCIAL],
  },
  {
    name: 'clientList',
    pathIsExact: true,
    path: '/client',
    component: ClientListLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN, rolesConstants.ROLE_COMMERCIAL],
  },
  {
    name: 'orderList',
    pathIsExact: true,
    path: '/order',
    component: OrderListLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'invoiceList',
    pathIsExact: true,
    path: '/invoice/',
    component: InvoiceListLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'preorderList',
    pathIsExact: true,
    path: '/preorder/:status?',
    component: OrderListLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'orderdetail',
    pathIsExact: true,
    path: '/order/detail/:id',
    component: OrderDetailLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'invoicedetail',
    pathIsExact: true,
    path: '/invoice/detail/:id',
    component: InvoiceDetailLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'orderfactureddetail',
    pathIsExact: true,
    path: '/order/detail/:id/:status',
    component: OrderDetailLayout,
    isPrivate: true,
    roles: [
      rolesConstants.ROLE_ADMIN,
      rolesConstants.ROLE_COMMERCIAL,
      rolesConstants.ROLE_CUSTOMER,
    ],
  },
  {
    name: 'login',
    pathIsExact: true,
    path: '/',
    component: Login,
  },
  {
    name: 'landingPage',
    pathIsExact: true,
    path: '/landing',
    component: LandingPage,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'passwordRecovery',
    pathIsExact: true,
    path: '/password-recovery',
    component: PasswordRecovery,
  },
  {
    name: 'passwordChange',
    pathIsExact: true,
    path: '/password-change/:confirmationToken',
    component: PasswordChange,
  },
  {
    name: 'configuration',
    pathIsExact: true,
    path: '/configuration',
    component: ConfigurationLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_SUPER_ADMIN],
  },
  // {
  //   name: 'dashboard',
  //   pathIsExact: true,
  //   path: '/dashboard',
  //   component: DashboardLayout,
  //   isPrivate: true,
  // },
  {
    name: 'aggrid_old',
    pathIsExact: true,
    path: '/aggrid_old',
    component: AggridLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'aggrid',
    pathIsExact: true,
    path: '/aggrid',
    component: AggridNewLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'catalog',
    pathIsExact: true,
    path: '/catalog',
    component: CatalogLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'orderFromCsv',
    pathIsExact: true,
    path: '/order-from-csv',
    component: OrderFromCsvLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  // {
  //   name: 'welcome',
  //   pathIsExact: true,
  //   path: '/dashboard/logged-in',
  //   component: DashboardLayout,
  //   isPrivate: true,
  // },
  {
    name: 'dashboardAdmin',
    pathIsExact: true,
    path: '/admin',
    component: DashboardAdminLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'profil',
    pathIsExact: true,
    path: '/profil',
    component: Profil,
    isPrivate: true,
  },
  // {
  //   name: 'prospect',
  //   pathIsExact: true,
  //   path: '/prospect',
  //   component: CreateProspectLayout,
  //   isPrivate: true,
  // },
  // {
  //   name: 'prospect',
  //   pathIsExact: true,
  //   path: '/prospect/:id',
  //   component: EditProspectLayout,
  //   isPrivate: true,
  // },
  // {
  //   name: 'catalog',
  //   pathIsExact: true,
  //   path: '/catalogue/:slugGroup/:slug?',
  //   component: Catalog,
  //   isPrivate: true,
  // },
  {
    name: 'cart',
    pathIsExact: true,
    path: '/panier/detail/:id/:temperature?',
    component: Cart,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'cartList',
    pathIsExact: true,
    path: '/panier/liste',
    component: CartListLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'recurrentCartList',
    pathIsExact: true,
    path: '/panier/recurrent/list',
    component: RecurrentCartListLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'cartValidation',
    pathIsExact: true,
    path: '/panier/validation/:type/:containerization?',
    component: ConfirmationOrderLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'terms',
    pathIsExact: true,
    path: '/cgv',
    component: TermsLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN],
  },
  {
    name: 'creditdetail',
    pathIsExact: true,
    path: '/credit/:id',
    component: CreditDetailLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'loading',
    pathIsExact: true,
    path: '/loading',
    component: LoadingLayout,
    isPrivate: true,
  },
  {
    name: 'maintenance',
    pathIsExact: true,
    path: '/maintenance',
    component: Maintenance,
  },
  {
    name: 'commercialCartList',
    pathIsExact: true,
    path: '/commercial/cart-list',
    component: CommercialCartListLayout,
    isPrivate: true,
  },
  {
    name: 'ocrOrderMatch',
    pathIsExact: true,
    path: '/commercial/order-match/:id',
    component: OcrOrderMatchLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN, rolesConstants.ROLE_COMMERCIAL],
  },
  {
    name: 'articleList',
    pathIsExact: true,
    path: '/articles',
    component: ArticleListLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_ADMIN, rolesConstants.ROLE_COMMERCIAL],
  },
  {
    name: 'promotionList',
    pathIsExact: true,
    path: '/promotions',
    component: PromotionListLayout,
    isPrivate: true,
    roles: [rolesConstants.ROLE_CUSTOMER],
  },
  {
    name: 'error',
    pathIsExact: true,
    path: '/*',
    component: ErrorLayout,
    isPrivate: true,
  },
];

export default Routes;
