import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const productService = {
  getItemByID,
  updateEngagementStock,
  getFavoriteProducts,
  likeProduct,
  unlikeProduct,
  getLastOrdersByItemID,
  safoItemAnalytic,
};

function safoItemAnalytic(payload) {
  // const urlFilter = JSON.stringify(filters);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const body = JSON.stringify(payload);
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/v2.0/analytics/items/add`, requestOptions).then(
    handleResponse
  );
}

function getItemByID(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/item/${id}`, requestOptions).then(handleResponse);
}

function getLastOrdersByItemID(id, platformId) {
  const filter = { platform: platformId };
  const urlFilter = JSON.stringify(filter);
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/order/item/${id}?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function updateEngagementStock(data) {
  const body = JSON.stringify({
    ...data,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/promotion/update`, requestOptions).then(handleResponse);
}

function getFavoriteProducts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/client/favorite-products`, requestOptions).then(
    handleResponse
  );
}

function likeProduct(productId) {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const body = JSON.stringify({
    id: productId,
  });
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/client/favorite-products`, requestOptions).then(
    handleResponse
  );
}

function unlikeProduct(productId) {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/client/favorite-products/${productId}`, requestOptions).then(
    handleResponse
  );
}
