import styled from 'styled-components';

export default (Component) => styled(Component)`
  .file {
    .btn__file {
      height: 40px;
      padding: 10px 20px;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      transition-property: background-color;
      transition-duration: 0.5s;
      cursor: pointer;
      background-color: #ffffff;
      color: #00a6ff;
      border: 1px solid #00a6ff;
      border-radius: 30px;
    }
    margin-bottom: 15px;
    text-align: center;
    input {
      display: none;
    }
  }
  .oldFile {
    text-align: center;
    word-break: break-all;
    span {
      color: #01366b;
      padding-right: 10px;
    }
    i {
      color: #01366b;
    }
    margin-bottom: 15px;
  }
  .validate {
    a {
      height: 40px;
      padding: 10px 20px;
    }
    margin: 0px auto;
    text-align: center;
  }
  .page-profil__alert-bloc {
    width: 100%;
    margin-bottom: 20px;
    .alert-bloc__label {
      display: block;
      color: #d0021b;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      text-align: center;
      &:before {
        content: '*';
      }
    }
  }
`;
