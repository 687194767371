/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterActions } from 'actions';
import Icon from 'components/Icon/Icon';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ClientsFilterForm from './ClientsFilterForm';
import CatalogFilterForm from './CatalogFilterForm';
import withStyle from './FilterMenu.styles';

class FilterMenu extends React.Component {
  constructor(props) {
    super(props);
    const {
      filter: { clientsFilter, catalogFilter },
    } = props;
    this.state = {
      clientsFilter,
      catalogFilter,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    const { closeMenu } = this.props;
    closeMenu();
    document.removeEventListener('click', this.handleClick);
  }

  handleClick = (e) => {
    const { closeMenu } = this.props;
    const { current: menuRef } = this.ref;
    if (menuRef && !menuRef.contains(e.target)) {
      closeMenu();
    }
  };

  updateFilterByKey = (key, value) => {
    const { filter } = this.props;
    const { isClientsFilter } = filter;
    this.setState((prevState) => {
      const { clientsFilter, catalogFilter } = prevState;
      const effectiveFilter = isClientsFilter ? clientsFilter : catalogFilter;
      const effectiveFilterKey = isClientsFilter ? 'clientsFilter' : 'catalogFilter';
      return {
        [effectiveFilterKey]: {
          ...effectiveFilter,
          [key]: value,
        },
      };
    });
  };

  handleSubmit = () => {
    const { closeMenu, filter, updateFilter } = this.props;
    const { clientsFilter, catalogFilter } = this.state;
    const { isClientsFilter } = filter;
    updateFilter(isClientsFilter ? { clientsFilter } : { catalogFilter });
    closeMenu();
  };

  render() {
    const { className, filter, closeMenu } = this.props;
    const { clientsFilter, catalogFilter } = this.state;
    const { isClientsFilter } = filter;
    return (
      <div className={className} ref={this.ref}>
        <div className="filter">
          <div className="filteropen_header">
            <span className="filteropen_header_icon">
              <Icon content="\e905" onClick={closeMenu} />
            </span>
            <h5>FILTRER PAR</h5>
          </div>
          <div className="filteropen_content">
            {isClientsFilter ? (
              <ClientsFilterForm
                updateFilter={this.updateFilterByKey}
                clientsFilter={clientsFilter}
              />
            ) : (
              <CatalogFilterForm
                updateFilter={this.updateFilterByKey}
                catalogFilter={catalogFilter}
              />
            )}
          </div>
          <div className="filteropen_footer">
            <ButtonPrimary className="btn-primary" onClick={this.handleSubmit}>
              <h5>APPLIQUER</h5>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}

FilterMenu.propTypes = {
  className: PropTypes.string,
  closeMenu: PropTypes.func,
  updateFilter: PropTypes.func,
  filter: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { filter } = state;
  return {
    filter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeMenu: () => dispatch(filterActions.close()),
  updateFilter: (filter) => dispatch(filterActions.updateFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(FilterMenu));
