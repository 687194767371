export const dayOffConstants = {
  FETCH_REQUEST: 'DAYOFF_FETCH_REQUEST',
  FETCH_SUCCESS: 'DAYOFF_FETCH_SUCCESS',
  FETCH_FAILURE: 'DAYOFF_FETCH_FAILURE',

  UPDATE_REQUEST: 'DAYOFF_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DAYOFF_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DAYOFF_UPDATE_FAILURE',

  DELETE_REQUEST: 'DAYOFF_DELETE_REQUEST',
  DELETE_SUCCESS: 'DAYOFF_DELETE_SUCCESS',
  DELETE_FAILURE: 'DAYOFF_DELETE_FAILURE',

  CREATE_REQUEST: 'DAYOFF_CREATE_REQUEST',
  CREATE_SUCCESS: 'DAYOFF_CREATE_SUCCESS',
  CREATE_FAILURE: 'DAYOFF_CREATE_FAILURE',
};
