import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import ProductCard from 'components/ProductCard/ProductCard';
import { productCardActions } from 'actions/ProductCard';
import withModalErrorStyle from './ModalErrorCart.style';

const ModalProductCard = ({
  className,
  closeModal,
  resetCurrentProduct,
  currentProduct,
  handleFilter,
}) => {
  const close = () => {
    closeModal();
    resetCurrentProduct();
  };

  return (
    <div className={className}>
      <ProductCard product={currentProduct} close={close} handleFilter={handleFilter} />
    </div>
  );
};

ModalProductCard.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  resetCurrentProduct: PropTypes.func,
  currentProduct: PropTypes.object,
  handleFilter: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  resetCurrentProduct: () => dispatch(productCardActions.chooseCurrentProduct(null)),
});

export default connect(null, mapDispatchToProps)(withModalErrorStyle(ModalProductCard));
