import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dateTimeToFormatedDate, getPath, store } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { messageActions, modalActions } from 'actions';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { selectMessageByFilter, selectMessageCountByFilter } from 'selectors/message';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import { BsTrash } from '@react-icons/all-files/bs/BsTrash';
import withMessageStyle from './withMessageStyle';
import { userConstants } from '../../constants';

const { TYPE_ADMIN } = userConstants;
const limit = 25;

const Message = ({
  className,
  user,
  selectedId,
  messageList,
  messageCount,
  fetchMessageList,
  fetchMessageTotalRowCount,
  openMessageModal,
  openDeleteModal,
  openExtractExcel,
}) => {
  const { information } = user;
  const { type } = information;
  const url = [
    {
      text: 'Accueil ',
      href: getPath('dashboardAdmin'),
    },
    {
      text: 'Paramètres',
    },
  ];
  const currentPage = 'Messagerie';

  const ongletList = [
    {
      text: 'Mes commerciaux',
      href: getPath('commercial'),
    },
    {
      text: 'Plannings de livraison',
      href: getPath('planning'),
    },
    {
      text: 'Jours fériés & fermeture',
      href: getPath('dayoff'),
    },
    {
      text: 'CGV appliquées',
      href: getPath('terms'),
    },
    {
      text: 'Paramètres précommande',
      href: getPath('settingsPreorder'),
    },
    {
      text: 'Messagerie client',
      href: getPath('message'),
      status: 'active',
    },
    {
      text: 'Extract XLS preco',
      handle: openExtractExcel,
    },
  ];
  const filter = { platform: selectedId };

  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [messageTotalRowCount, setMessageTotalRowCount] = useState(null);

  useEffect(() => {
    fetchMessageTotalRowCount(filter);
    fetchMessageList(filter, offset, limit);
  }, []);

  useEffect(() => {
    if (messageList && messageList.items && !messageList.isLoading) {
      setData(messageList.items.flat());
    }
  }, [messageList]);

  useEffect(() => {
    if (messageCount) {
      if (messageCount && !messageCount.isLoading) {
        setMessageTotalRowCount(messageCount.totalRowsNumber);
      }
    }
  }, [messageCount]);

  const columns = React.useMemo(() => [
    {
      id: 'subject',
      Header: 'Objet',
      accessor: (item) => item.subject,
    },
    {
      id: 'receiver',
      Header: 'Destinataire',
      accessor: (item) => (item.client ? item.client.ext_code : 'Tous'),
    },
    {
      id: 'startDate',
      Header: 'Début diffusion',
      accessor: (item) => dateTimeToFormatedDate(item.date_begin),
    },
    {
      id: 'endDate',
      Header: 'Fin diffusion',
      accessor: (item) => dateTimeToFormatedDate(item.date_end),
    },
    {
      id: 'actions',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="page-message__table__actions">
          <span
            onClick={() => {
              openMessageModal(filter, value);
            }}
            className="edit"
          >
            <FaPencilAlt /> Editer
          </span>
          <span
            onClick={() => {
              openDeleteModal(value.id);
            }}
            className="delete"
          >
            <BsTrash /> Supprimer
          </span>
        </div>
      ),
      className: 'actions',
    },
  ]);

  return (
    <div className={className}>
      <div className="page-message">
        <div className="page-message__header">
          <BreadCrumb urlTab={url} currentPage={currentPage} />
          <OngletMenu list={ongletList} />
          {user && type === TYPE_ADMIN && (
            <div>
              <div className="page-messag__actions-block">
                <ButtonPrimary inverted onClick={() => openMessageModal(filter)}>
                  <Icon content="\e930" />
                  <span>Nouveau Message</span>
                </ButtonPrimary>
              </div>
              <ReactTable
                columns={columns}
                data={data}
                isLoading={messageList && messageList.isLoading}
                next={() => {
                  fetchMessageList(filter, offset + 1, limit);
                  setOffset(offset + 1);
                }}
                totalRowCount={messageTotalRowCount}
                loadedRowCount={data.length}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    user,
    platform: { selectedId },
  } = state;
  const filter = { platform: selectedId };
  return {
    user,
    selectedId,
    messageList: selectMessageByFilter(state, filter),
    messageCount: selectMessageCountByFilter(state, filter),
  };
};

const mapDispatchToProps = (dispatch) => {
  const modalType = 'messagerie';
  const modalProps = {
    modalHeader: 'Envoyer le message à tous les clients actifs',
    width: '600',
  };
  return {
    fetchMessageList: (filter, offset, limit) =>
      dispatch(messageActions.fetchMessages(filter, offset, limit)),
    fetchMessageTotalRowCount: (filter) => dispatch(messageActions.fetchTotalRowsNumber(filter)),
    openMessageModal: (filter, message = null) =>
      dispatch(modalActions.open(modalType, { ...modalProps, tableFilter: filter, message })),
    openDeleteModal: (messageId) =>
      dispatch(
        modalActions.open('delete', {
          modalHeader: '',
          id: messageId,
          deleteType: 'MESSAGE',
        })
      ),
    openExtractExcel: () =>
      dispatch(
        modalActions.open('extractExcelPreorder', {
          dateSelected: new Date(),
        })
      ),
  };
};

Message.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  selectedId: PropTypes.number,
  openMessageModal: PropTypes.func,
  openExtractExcel: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withMessageStyle(Message));
