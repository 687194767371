import styled from 'styled-components';

export const OutstandingWrapper = styled.div`
  padding: 5px 20px 5px 0;
  height: 40px;
  display: flex;
  align-items: center;

  .page-catalog__alert-bloc {
    display: flex;
    border: 1px solid #a1d245;
    border-radius: 8px;
    color: #4a4a4a;
    background-color: #ebf7a2;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 100%;

    .alert-bloc__icon-alert {
      vertical-align: top;
      margin-right: 10px;
      display: inline-block;
    }

    .alert-bloc__label {
      display: inline-block;
      font-weight: 500;
    }
    .alert-bloc__label p {
      margin: 0px;
      text-align: justify;
      .bloc-label__attribut {
        color: #d0021b;
      }
  }
`;
