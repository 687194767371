// Importing modalConstants from the absolute path (`constants`) bugs.
import { modalConstants } from '../constants/Modal.constants';

export const modalActions = {
  open,
  close,
};

function open(modalType, modalProps) {
  return { type: modalConstants.OPEN, modalType, modalProps };
}

function close() {
  return { type: modalConstants.CLOSE };
}
