import React from 'react';
import { connect } from 'react-redux';
import reduce from 'lodash.reduce';
import findIndex from 'lodash.findindex';
import PropTypes from 'prop-types';
import { commercialActions } from 'actions';
import { getCollection } from 'helpers';
import Icon from 'components/Icon/Icon';

class Commercials extends React.Component {
  componentDidMount() {
    const { fetchCommercials } = this.props;
    fetchCommercials();
  }

  onChange = (event) => {
    const { updateState, clientCommercials } = this.props;
    const addedCommercial = Number(event.target.value);
    if (addedCommercial) {
      const isAlreadyAdded =
        clientCommercials &&
        clientCommercials.length &&
        clientCommercials.findIndex((commercial) => Number(commercial.id) === addedCommercial) > -1;
      if (!isAlreadyAdded) {
        const commercials = [
          ...(clientCommercials || []).map((commercial) => ({ id: commercial.id })),
          { id: addedCommercial },
        ];
        updateState('commercials', commercials);
      }
    }
  };

  removeCommercial = (commercialId) => {
    const { updateState, clientCommercials } = this.props;
    const commercials = (clientCommercials || []).filter(
      (commercial) => Number(commercial.id) !== Number(commercialId)
    );
    updateState('commercials', commercials);
  };

  render() {
    const { className, commercialsAvailable, clientCommercials } = this.props;
    const selectedCommercials = reduce(
      clientCommercials,
      (acc, clientCommercial) => {
        const index = findIndex(
          commercialsAvailable,
          (com) => Number(com.id) === Number(clientCommercial.id)
        );
        if (index >= 0) acc.push(commercialsAvailable[index]);
        return acc;
      },
      []
    );

    return (
      <div className={className}>
        <div className="page-profil__block-info">
          <div className="block-info__entete">
            <Icon className="entete__icon add_commercial" content="\e949" />
            <span className="entete__label">Affecter Commerciaux</span>
          </div>
          <div className="block-info__content commerciaux">
            <div className="content__ligne">
              <p>Sélectionner un ou plusieurs commerciaux à affecter dans le menu déroulant</p>
              <div className="content__ligne commerciaux_box">
                {/* <b>Catalogue Permanent :</b> */}
                <div className="bloc__select">
                  <span className="bold ligne__attribut">Sélectionner commercial :</span>
                  <select onChange={this.onChange}>
                    <option selected hidden disabled />
                    {commercialsAvailable.map(({ id, firstname, lastname, username }) => (
                      <option value={id}>
                        {firstname || lastname ? [firstname, lastname].join(' ').trim() : username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="content__ligne keywords">
                {selectedCommercials.map((clientCommercial) => (
                  <div>
                    <span>
                      {clientCommercial.firstname || clientCommercial.lastname
                        ? [clientCommercial.firstname, clientCommercial.lastname].join(' ').trim()
                        : clientCommercial.username}
                    </span>
                    <span
                      className="icon"
                      onClick={() => this.removeCommercial(clientCommercial.id)}
                    >
                      <Icon content="\e915" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Commercials.propTypes = {
  className: PropTypes.string,
  commercialsAvailable: PropTypes.array,
  clientCommercials: PropTypes.array,
  fetchCommercials: PropTypes.func,
  updateState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { commercial } = state;
  const { collections } = commercial;
  const commercialCollection = getCollection(collections || [], {});
  return {
    commercialsAvailable: (commercialCollection.items && commercialCollection.items.flat()) || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCommercials: () => dispatch(commercialActions.fetchCommercials({}, 0, 100)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Commercials);
