import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const CommercialCartListWrapper = styled.div`
  .page-commercial-cart-list {
    width: calc(100% - 45px);
    margin: 0 auto;
    padding-top: 22px;
    padding-bottom: 20px;
    .page-commercial__heading {
    }
    .commercial-cart-list__action-column {
      margin: 0 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .commercial-cart-list__action-column__button {
        margin-right: 15px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #01366b;
        border: 1px solid #01366b;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        &:hover {
          color: #01366b;
          background: #fff;
        }
        svg {
          width: 25px;
          height: auto;
          fill: #fff;
          margin-bottom: 0;
          margin-right: 10px;
        }
        &:hover svg {
          fill: #01366b;
        }
        span {
          text-align: center;
        }
      }
    }
    .commercial-cart-list__table-column {
      width: 100%;
      padding: 20px;
      .commercial-cart-list__title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          color: #01366b;
          font-weight: bold;
        }
        .commercial-cart-list__title-container__refresh-button {
          display: flex;
          align-items: center;
          padding: 7px 15px;
          background: #54bafc;
          text-transform: inherit;
          color: #fff;
          svg {
            margin-right: 5px;
          }
        }
      }
      .table-column__table-container {
        .react-table__wrapper {
          max-height: calc(50vh - 150px);
          box-shadow: none;
          border: 1px solid rgba(1, 54, 107, 0.1);
          border-radius: 4.5px;
          overflow: auto;
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar {
            height: 6px;
            width: 6px;
            background-color: #fefefe;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #ccc;
          }
        }
        .commercial-cart-list__table-column__title {
          font-weight: bold;
        }
        .commercial-cart-list__processing {
          display: flex;
          align-items: center;
          color: #f5a623;
          svg {
            fill: #f5a623;
            margin-right: 5px;
          }
        }
        .commercial-cart-list__done {
          display: flex;
          font-weight: bold;
          align-items: center;
          color: #2aa952;
          font-style: italic;
          font-size: 12px;
          span {
            display: flex;
            align-items: center;
            padding: 5px;
            border: 1px solid #2aa952;
            border-radius: 4.5px;
            svg {
              fill: #2aa952;
              margin-right: 5px;
            }
          }
        }
        .commercial-cart-list__actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .commercial-cart-list__actions__continue {
            display: flex;
            align-items: center;
            margin-right: 15px;
            padding: 7px;
            color: #fff;
            background: #01366b;
            text-transform: inherit;
            border: 1px solid #01366b;
            text-decoration: none;
            svg {
              fill: #fff;
              margin-right: 5px;
            }
          }
          .commercial-cart-list__actions__delete {
            display: flex;
            align-items: center;
            margin-right: 15px;
            padding: 7px;
            color: #fff;
            background: rgb(167, 0, 0);
            border: 1px solid rgb(167, 0, 0);
            border-radius: 4.5px;
            text-transform: inherit;
            font-weight: 700;
            font-size: 12px;
            svg {
              fill: #fff;
              font-size: 14px;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
`;
