import { catalogConstants } from '../constants/Catalog.constants';

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;

export function getIconClassByType(type) {
  switch (type) {
    case TEMPERATURE_DRY:
      return 'icon-dry';
    case TEMPERATURE_FRESH:
      return 'icon-apple';
    case TEMPERATURE_FROZEN:
      return 'icon-snow';
    default:
      return 'icon-dry';
  }
}
