import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import menuWithStyle from './Menu.style';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.menuWrapper = React.createRef();
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount() {
    const { current: refNode } = this.menuWrapper;
    refNode.addEventListener('mousedown', this.handleGlobalClick, false);
  }

  componentWillUnmount() {
    const { current: refNode } = this.menuWrapper;
    refNode.removeEventListener('mousedown', this.handleGlobalClick, false);
  }

  handleGlobalClick = (e) => {
    const { current: refNode } = this.menuWrapper;
    const { handleOpenMenu, reset } = this.props;
    if (!refNode.contains(e.target) || refNode === e.target) {
      handleOpenMenu();
      reset();
    }
  };

  render() {
    const {
      columns,
      handleClick,
      handleClickGoBack,
      handleOpenMenu,
      reset,
      trails,
      parents,
      className,
    } = this.props;

    return (
      <div className={className}>
        <div className="menu" ref={this.menuWrapper}>
          {columns.map((data, i) => (
            <MenuItem
              key={i}
              level={i}
              name={`column${i + 1}`}
              data={data}
              handleClick={handleClick}
              handleClickGoBack={handleClickGoBack}
              handleOpenMenu={handleOpenMenu}
              reset={reset}
              trail={trails[i]}
              slugGroup={parents[0] ? parents[0].slug : null}
              firstColumn={i === 0}
              parent={parents[i - 1]}
            />
          ))}
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        slug: PropTypes.string,
        name: PropTypes.string.isRequired,
        level: PropTypes.number,
        children: PropTypes.array,
        assortmentLink: PropTypes.bool,
      })
    )
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClickGoBack: PropTypes.func.isRequired,
  parents: PropTypes.array,
  trails: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string.isRequired,
  handleOpenMenu: PropTypes.func,
  reset: PropTypes.func,
};

export default menuWithStyle(Menu);
