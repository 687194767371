import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const invoiceService = {
  fetchInvoice,
  fetchInvoiceOrder,
  fetchTotalRowNumber,
  downloadInvoiceFormPdf,
};

function fetchInvoice(invoiceId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/invoice/${invoiceId}`, requestOptions).then(handleResponse);
}

function fetchInvoiceOrder(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${config.apiUrl}/api/invoice?filter=${urlFilter}&offset=${offset}&limit=${limit}&orderByDesc=dateCreate`,
    requestOptions
  ).then(handleResponse);
}

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/invoice/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function downloadInvoiceFormPdf(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/invoice/pdf/${id}`, requestOptions).then((r) => r.blob());
}
