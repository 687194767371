/* eslint-disable no-nested-ternary */
import { creditConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const initialState = {
  collections: [],
  collectionsCount: [],
  isOpen: false,
  creditProps: 0,
  creditList: [],
};

export function credit(state = initialState, action) {
  const { filter } = action;
  let { collections, collectionsCount } = state;
  if (!Object.keys(creditConstants).find((key) => creditConstants[key] === action.type)) {
    return state;
  }
  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));
  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case creditConstants.OPEN:
      return {
        ...state,
        isOpen: true,
        creditProps: action.creditProps,
      };
    case creditConstants.CLOSE:
      return { ...state, isOpen: false };
    case creditConstants.ADD_CREDIT: {
      const { creditList } = state;
      const { idOreder, idProduct, idLigne } = action.creditItem;
      let notFound = true;
      const newCreditList = (creditList || []).map((item) => {
        const newCredit = { ...item };
        if (
          newCredit.idOreder === idOreder &&
          newCredit.idProduct === idProduct &&
          newCredit.idLigne === idLigne
        ) {
          notFound = false;
          return { ...newCredit, ...action.creditItem };
        }
        return newCredit;
      });
      if (notFound) {
        return {
          ...state,
          creditList: [...creditList, action.creditItem],
        };
      }
      return {
        ...state,
        creditList: newCreditList,
      };
    }
    case creditConstants.UPDATE_CREDIT_LIST:
      return {
        ...state,
        creditList: action.creditList,
      };
    case creditConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case creditConstants.FETCH_SUCCESS:
      collection = Object.assign(collection, {
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
      });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case creditConstants.FETCH_FAILURE:
      return state;
    case creditConstants.FETCH_COUNT_ITEMS_REQUEST:
      collectionsCount = [{ ...collectionCount, isLoading: true }];
      return {
        ...state,
        collectionsCount,
      };
    case creditConstants.FETCH_COUNT_ITEMS_SUCCESS:
      collectionsCount = [
        {
          ...collectionCount,
          totalRowsNumber: action.numberDataAvailable,
          isLoading: false,
        },
      ];
      return {
        ...state,
        collectionsCount,
      };
    case creditConstants.FETCH_COUNT_ITEMS_FAILURE:
      return state;
    case creditConstants.CREATE_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case creditConstants.CREATE_ITEMS_SUCCESS:
      return {
        ...state,
        creditList: [],
        isLoading: false,
      };
    case creditConstants.CREATE_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
}
