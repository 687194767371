import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cartActions,
  modalActions,
  messageActions,
  assortmentActions,
  cartItemsActions,
  ocrOrderPdfActions,
  containerActions,
} from 'actions';
import { getValueOfAttribut } from 'helpers';
import { platformConstants } from 'pages/../constants';
import { getPath } from 'helpers/Routes';
import { history } from 'helpers/History';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withModalDeleteStyle from './ModalDelete.style';

class ModalDelete extends React.Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  getText(type) {
    switch (type) {
      case 'MESSAGE':
        return 'Êtes-vous sûr de vouloir supprimer ce message ?';
      case 'Assortment':
        return 'Êtes-vous certain de vouloir supprimer cette sélection ?';
      case 'CART_ITEM':
        return 'Êtes-vous certain de vouloir supprimer ce produit du panier ?';
      case 'CART_TYPE_ITEM':
        return 'Êtes-vous certain de vouloir supprimer ce produit du panier type ?';
      case 'CART_CURRENT':
        return "Êtes-vous certains de vouloir supprimer l'ensemble des produits au panier ?";
      case 'CART':
        return 'Êtes-vous sûr de vouloir supprimer ce panier type ?';
      case 'COMMERCIAL_CART':
        return 'Êtes-vous sûr de vouloir supprimer ce panier en cours ?';
      case 'OCR_JOB':
        return 'Êtes-vous sûr de vouloir supprimer cet import';
      default:
        return null;
    }
  }

  delete() {
    const {
      deleteType,
      closeModal,
      deleteCart,
      deleteMessage,
      deleteAssortment,
      deleteCartItem,
      deleteOcrJob,
      id,
      user,
      callback,
      selectedPlatform,
    } = this.props;
    if (deleteType === 'MESSAGE') {
      deleteMessage(id);
      history.push(getPath('message'));
    }
    if (deleteType === 'Assortment') {
      deleteAssortment(id);
    }
    if (deleteType === 'CART_ITEM' || deleteType === 'CART_TYPE_ITEM') {
      deleteCartItem(id);
    }
    if (['CART', 'CART_CURRENT', 'COMMERCIAL_CART'].includes(deleteType)) {
      deleteCart(id, deleteType, user, selectedPlatform);
    }
    if (deleteType === 'OCR_JOB') {
      deleteOcrJob(id);
    }
    if (callback) {
      callback();
    }

    closeModal();
  }

  render() {
    const { className, deleteType, closeModal, selectedPlatform } = this.props;
    const text = this.getText(deleteType);
    const { attributs } = selectedPlatform;
    return (
      <div className={className}>
        <div className="modal-body">
          <div className="modal-body__text-block bold">
            <span>{text}</span>
          </div>
          <div className="modal-body__button-block">
            <ButtonPrimary inverted onClick={closeModal}>
              NON
            </ButtonPrimary>
            <ButtonPrimary onClick={this.delete}>Oui</ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}

ModalDelete.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  deleteType: PropTypes.string,
  deleteCart: PropTypes.func,
  deleteMessage: PropTypes.func,
  deleteAssortment: PropTypes.func,
  deleteCartItem: PropTypes.func,
  closeModal: PropTypes.func,
  user: PropTypes.object,
  selectedPlatform: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    user: { information },
    platform: { selectedId, platforms },
  } = state;
  const selectedPlatform = platforms.find(({ id }) => Number(id) === Number(selectedId)) || {};
  return {
    user: information,
    selectedPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCart: (cartId, type, user, selectedPlatform) => {
    const { attributs } = selectedPlatform;
    const { KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;
    if (Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) === 1) {
      dispatch(containerActions.deleteContainers());
    }
    dispatch(cartActions.deleteCart(cartId, type, user));
  },
  deleteMessage: (messageId) => {
    dispatch(messageActions.deleteMessage(messageId));
  },
  deleteAssortment: (assortmentId) => {
    dispatch(assortmentActions.deleteAssortment(assortmentId));
  },
  deleteCartItem: (cartItemId) => {
    dispatch(cartItemsActions.deleteItem(cartItemId));
  },
  deleteOcrJob: (jobId) => dispatch(ocrOrderPdfActions.deleteOcrJob(jobId)),
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withModalDeleteStyle(ModalDelete));
