import styled from 'styled-components';

export default (Component) => styled(Component)`
  .block-info__entete {
    padding: 11px 10px 9px 10px;
    border-bottom: 1px solid rgba(1, 54, 107, 0.3);
    .entete__icon {
      color: #00a6ff;
      font-size: 12px;
      background-color: #e1f4fb;
      border-radius: 24px;
      margin: 0px 8px;
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      line-height: 18px;
    }
    .entete__label {
      color: #4a4a4a;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.12px;
      line-height: 19px;
      vertical-align: middle;
    }
  }
  .block-info__content {
    padding: 14px 20px 7px;
    .content__ligne {
      display: block;
      width: 100%;
      /* margin: 15px 0; */
      height: 50px;
      .ligne__attribut {
        width: 140px;
        display: inline-block;
        color: #01366b;
        margin-right: 10px;
      }
      .ligne__value {
        width: calc(100% - 173px);
        /* max-width: 60%; */
        box-sizing: content-box;
        border: none;
        border-bottom: 1px solid rgba(120, 120, 120, 0.6);
        height: 35px;
        padding: 0;
        &:focus {
          outline: none;
        }
      }
      select {
        width: 50%;
      }
      i {
        position: absolute;
        right: 24px;
      }
    }
    .content__ligne:nth-of-type(1) {
      margin: 0;
    }
  }
`;
