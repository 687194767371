import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectTermByFilter = (state, filter) => {
  const collections = get(state, 'terms.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectTermCountByFilter = (state, filter) => {
  const collections = get(state, 'terms.collectionsCount', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};
