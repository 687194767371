export const GROUP_BY = {
  cellIndex: null,
  comparisonDataKey: 'category',
};

export const PRODUCT_COLUMNS = [
  {
    type: 'PRODUCT_IMAGE',
    name: 'Image',
    className: 'header_product-image',
  },
  {
    type: 'PRODUCT_WORDING',
    name: 'Libellé produit',
    className: 'header_product-wording',
  },
];

export const CLIENT_COLUMNS = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Client',
    className: 'header_client-number',
    dataType: 'ext_code',
    style: {
      isBoldGray: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Client',
    className: 'header_order-number',
    dataType: 'name',
    style: {
      isBoldGray: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Enseigne',
    className: 'header_order-number',
    dataType: 'brand.name',
    style: {
      isBoldGray: true,
    },
  },
  {
    type: 'CLIENT_STATUS',
    name: 'Permanent',
    className: 'header_product-pcb',
    dataType: 'status_catalog',
  },
  {
    type: 'CLIENT_STATUS',
    name: 'Precommande',
    className: 'header_product-pcb',
    dataType: 'status_preorder',
  },
];
