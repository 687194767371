import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { getValueOfAttribut } from 'helpers';
import { platformConstants } from 'pages/../constants';
import Icon from 'components/Icon/Icon';
import { OutstandingWrapper } from './OutstandingCatalog.style';

const { PREORDER_DELAY_KEY, DELIVERY_DELAY_KEY, PREORDER_DATE_BEGIN_KEY } = platformConstants;

const OutstandingCatalog = ({ preorderDelay, deliveryDelay, preorderDateBegin }) => {
  const actualPreorderDate = Moment(preorderDateBegin);
  // We clone the actualPreorderDate in condition to not add twice
  while (Moment(actualPreorderDate).add(preorderDelay, 'days').isBefore(Moment())) {
    actualPreorderDate.add(preorderDelay, 'days');
  }

  return (
    <OutstandingWrapper>
      <div className="page-catalog__alert-bloc">
        <div className="alert-bloc__icon-alert">
          <Icon content="\e902" />
        </div>
        <div className="alert-bloc__label">
          <p className="bold">
            <span>Fin de précommande le </span>
            <span className="bloc-label__attribut">
              {Moment(actualPreorderDate).add(preorderDelay, 'days').format('DD/MM/YYYY')}
            </span>
            <span>, pour une livraison le </span>
            <span className="bloc-label__attribut">
              {Moment(actualPreorderDate)
                .add(preorderDelay + deliveryDelay, 'days')
                .format('DD/MM/YYYY')}
            </span>
            <span>. Il vous reste </span>
            <span className="bloc-label__attribut">
              {Moment(actualPreorderDate).add(preorderDelay, 'days').fromNow(true)}
            </span>
            <span> pour valider votre panier.</span>
          </p>
        </div>
      </div>
    </OutstandingWrapper>
  );
};

OutstandingCatalog.propTypes = {
  preorderDelay: PropTypes.number,
  deliveryDelay: PropTypes.number,
  preorderDateBegin: PropTypes.string,
};

const PreorderOutstanding = ({ selectedPlatform }) => {
  const { attributs } = selectedPlatform;
  const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
  const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
  const preorderDateBegin = Moment(
    getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY)
  ).format('YYYY-MM-DD HH:mm:00');

  return preorderDelay && deliveryDelay && preorderDateBegin ? (
    <OutstandingCatalog
      preorderDelay={preorderDelay}
      deliveryDelay={deliveryDelay}
      preorderDateBegin={preorderDateBegin}
    />
  ) : null;
};
PreorderOutstanding.propTypes = {
  selectedPlatform: PropTypes.object,
};

export default PreorderOutstanding;
