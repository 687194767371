import { prospectConstants } from '../constants';

const initState = {
  information: {},
  isLoading: false,
};

export function prospect(state = initState, action) {
  switch (action.type) {
    case prospectConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case prospectConstants.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        information: action.prospect,
      };
    case prospectConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case prospectConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case prospectConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        information: action.prospect,
      };
    case prospectConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case prospectConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case prospectConstants.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        information: action.prospect,
      };
    case prospectConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
