import { cartService, userService } from 'services';
import { store, getCartItems, getPath, history, getTempsCartStatus, saveFile } from 'helpers';
import { analytics } from 'utils/analytics/tagmanager';
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { selectFirstTemp } from 'selectors/cart';
import { cartConstants, tableConstants } from '../constants';
import { cartItemsActions, loadingActions } from '.';

const { DIRECTION_REPLACE } = tableConstants;

export const cartActions = {
  fetchTotalRowsNumber,
  fetchItems,
  validateCart,
  updateDeliveryDates,
  updateDeliveryMode,
  createCart,
  copyCart,
  updateStoreCart,
  updateCart,
  resetCart,
  deleteCart,
  renewCurrentCartWithReferences,
  renewCurrentCartWithReferencesExist,
  createCartFromItems,
  setErrorToasted,
  downloadCartPdf,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    cartService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: cartConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: cartConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: cartConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchItems(
  filter,
  page = 1,
  limit,
  direction = DIRECTION_REPLACE,
  cartToSaveBefore = null
) {
  return (dispatch) => {
    if (
      filter.status &&
      (filter.status === cartConstants.CART_STATUS_CURRENT ||
        cartConstants.CART_STATUS_CURRENT_PREORDER)
    ) {
      dispatch(loadingActions.started(`cart_${filter.status}`));
    }
    if (cartToSaveBefore) {
      saveBeforeFetching();
    } else {
      fetch();
    }
    function saveBeforeFetching() {
      const { cart_items: cartItems, status, name } = cartToSaveBefore;
      const datasToUpdate = {
        items: cartItems.map(({ id, quantity }) => ({ id, quantity })),
        status,
        name,
      };
      cartService.updateCart(cartToSaveBefore.id, datasToUpdate, false).then(
        () => {
          fetch();
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    }
    function fetch() {
      dispatch(request());
      cartService.fetchItems(filter, page, limit).then(
        (items) => {
          if (
            filter.status &&
            (filter.status === cartConstants.CART_STATUS_CURRENT ||
              cartConstants.CART_STATUS_CURRENT_PREORDER)
          ) {
            dispatch(loadingActions.loaded(`cart_${filter.status}`));
          }
          const itemsArray = !Array.isArray(items) ? [items] : items;
          dispatch(success({ items: itemsArray }));
          updateCartItems(itemsArray);
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    }
  };

  function request() {
    return {
      type: cartConstants.FETCH_REQUEST,
      filter,
      page,
      limit,
    };
  }
  function success({ items }) {
    return {
      type: cartConstants.FETCH_SUCCESS,
      filter,
      items,
      direction,
    };
  }
  function failure(error) {
    return { type: cartConstants.FETCH_FAILURE, error };
  }
  function updateCartItems(items) {
    items.forEach((cart) => {
      const { cart_items: cartItems, id } = cart;
      if (cartItems) {
        cartItemsActions.updateItemsRowsNumber(cartItems.length, id);
        cartItemsActions.refreshItems(cartItems, id);
      }
    });
  }
}

function setErrorToasted() {
  return { type: cartConstants.SET_ERROR_TOASTED };
}

function validateCart(
  cart,
  deliveryDates,
  withDeliveryAmount = false,
  deliveryAmount = 0,
  isPreparationOnly = 0,
  platformHasContainerization = false,
  checkStockDisponibility = false
) {
  return (dispatch) => {
    dispatch(request());
    cartService
      .validateCart(
        cart.id,
        deliveryDates,
        withDeliveryAmount,
        deliveryAmount,
        isPreparationOnly,
        cart.cart_items,
        checkStockDisponibility
      )
      .then(
        (order) => {
          const {
            CART_STATUS_CURRENT,
            CART_STATUS_CURRENT_PREORDER,
            CART_STATUS_MOQ_STAND_BY,
          } = cartConstants;
          dispatch(success(order));
          analytics.validateCart(selectFirstTemp(order));
          dispatch(removeCartFromStore(cart.id));
          if ([CART_STATUS_CURRENT_PREORDER, CART_STATUS_CURRENT].includes(cart.status)) {
            history.push(
              getPath('cartValidation')
                .replace(':type', cart.is_preorder ? 'preorder' : 'order')
                .replace(':containerization?', platformHasContainerization)
            );
          }
          if (CART_STATUS_MOQ_STAND_BY === cart.status) {
            history.push(getPath('preorderList').replace(':status?', CART_STATUS_MOQ_STAND_BY));
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request() {
    return { type: cartConstants.VALIDATE_REQUEST, cartId: cart.id };
  }
  function success(order) {
    return { type: cartConstants.VALIDATE_SUCCESS, cartId: cart.id, order };
  }
  function failure(error) {
    return { type: cartConstants.VALIDATE_FAILURE, error };
  }
  function removeCartFromStore(cartId) {
    return { type: cartConstants.DELETE_SUCCESS, cartId };
  }
}

function createCart(tempsId, name = '') {
  const { dispatch, getState } = store;
  const { platform } = getState();
  const status = getTempsCartStatus(tempsId);
  const platformId = platform && platform.selectedId ? platform.selectedId : null;
  dispatch(request());

  return cartService.createCart(platformId, status, name).then(
    (cart) => {
      dispatch(success(cart));
      return cart;
    },
    (error) => {
      dispatch(failure(error.toString()));
    }
  );

  function request() {
    return {
      type: cartConstants.CREATE_REQUEST,
      name,
      tempsId,
      filter: {
        status,
        platform: platformId,
      },
    };
  }

  function success(cart) {
    return {
      type: cartConstants.CREATE_SUCCESS,
      cart,
      tempsId,
      filter: {
        status,
        platform: platformId,
      },
    };
  }
  function failure(error) {
    return {
      type: cartConstants.CREATE_FAILURE,
      error,
    };
  }
}

function createCartFromItems(cartItems, deliveryDates, comment = null, cartType = null) {
  return (dispatch) => {
    const { getState } = store;
    const { platform } = getState();
    const status =
      cartType === cartConstants.CART_COMMERCIAL_TYPE
        ? cartConstants.CART_STATUS_PENDING_VALIDATION
        : cartConstants.CART_STATUS_CURRENT;
    const platformId = platform && platform.selectedId ? platform.selectedId : null;
    dispatch(request(status, platformId));

    return cartService
      .createCart(platformId, status, '', cartItems, deliveryDates, comment, cartType)
      .then(
        (cart) => {
          dispatch(success(cart, status, platformId));
          return cart;
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
  function request(status, platformId) {
    return {
      type: cartConstants.CREATE_REQUEST,
      filter: {
        status,
        platform: platformId,
      },
    };
  }

  function success(cart, status, platformId) {
    return {
      type: cartConstants.CREATE_SUCCESS,
      cart,
      filter: {
        status,
        platform: platformId,
      },
    };
  }
  function failure(error) {
    return {
      type: cartConstants.CREATE_FAILURE,
      error,
    };
  }
}

async function renewCurrentCartWithReferences(
  dispatch,
  platformId,
  tempsId,
  references,
  status = cartConstants.CART_STATUS_CURRENT
) {
  dispatch(request());
  const name = '';
  let cart = null;
  try {
    cart = await cartService.createCart(platformId, status, '', references);
    dispatch(success(cart));
    updateStoreCart(cart);
  } catch (error) {
    dispatch(failure(error.toString()));
    return error;
  }

  // .then(
  //   (cart) => {
  //     dispatch(success(cart));
  //   },
  //   (error) => {
  //     dispatch(failure(error.toString()));
  //   }
  // );

  function request() {
    return {
      type: cartConstants.CREATE_REQUEST,
      name,
      tempsId,
      filter: {
        status,
        platform: platformId,
      },
    };
  }
  function success(cart) {
    return {
      type: cartConstants.CREATE_SUCCESS,
      cart,
      tempsId,
      filter: {
        status,
        platform: platformId,
      },
    };
  }
  function failure(error) {
    return {
      type: cartConstants.CREATE_FAILURE,
      error,
    };
  }
  return cart;
}

async function renewCurrentCartWithReferencesExist(dispatch, referencesExist) {
  dispatch(request());

  let references = null;
  try {
    references = await cartService.renewCurrentCartWithReferencesExist(referencesExist);
    dispatch(success(references));
  } catch (error) {
    dispatch(failure(error.toString()));
    return error;
  }

  // return cartService.renewCurrentCartWithReferencesExist(referencesExist).then(
  //   (references) => {
  //     dispatch(success(references));
  //   },
  //   (error) => {
  //     dispatch(failure(error.toString()));
  //   }
  // );

  function request() {
    return {
      type: cartConstants.CREATE_REQUEST,
      references,
    };
  }
  function success(references) {
    return {
      type: cartConstants.CREATE_SUCCESS,
      references,
    };
  }
  function failure(error) {
    return {
      type: cartConstants.CREATE_FAILURE,
      error,
    };
  }
  return references;
}

function copyCart(id, newStatus, newName, redirect = false) {
  return (dispatch) => {
    dispatch(request());
    return cartService.copyCart(id, newStatus, newName).then(
      (cart) => {
        dispatch(success(cart));
        if (redirect === true) {
          const redirectTo = getPath('cart').replace(':id', cart.id).replace('/:temperature?', '');
          history.push(redirectTo);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: cartConstants.COPY_REQUEST };
  }
  function success(cart) {
    return { type: cartConstants.COPY_SUCCESS, cart, newStatus };
  }
  function failure(error) {
    return { type: cartConstants.COPY_FAILURE, error };
  }
}

function deleteCart(cartId, type, user) {
  return (dispatch) => {
    dispatch(request());
    cartService.deleteCart(cartId).then(
      () => {
        dispatch(success());
        let redirectTo;
        switch (type) {
          case 'CART':
            setTimeout(() => {
              redirectTo = getPath('cartList');
              history.push(redirectTo);
            }, 1500);
            break;
          case 'CART_CURRENT':
            redirectTo = userService.getHomepagePath(user);
            history.push(redirectTo);
            break;
          default:
            break;
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: cartConstants.DELETE_REQUEST, cartId };
  }
  function success() {
    return { type: cartConstants.DELETE_SUCCESS, cartId };
  }
  function failure(error) {
    return { type: cartConstants.DELETE_FAILURE, error };
  }
}

function resetCart(cart) {
  const resetedCart = resetCartAmounts({ ...cart });
  cartItemsActions.resetItems(getCartItems(cart.id));
  return (dispatch) => {
    dispatch(updateCart(cart, {}, true));
    updateStoreCart(resetedCart);
  };
}

function resetCartAmounts(cart) {
  const totalsByType = cart.totals_by_type;
  Object.keys(totalsByType).forEach((type) => {
    const totals = totalsByType[type];
    Object.keys(totals).forEach((key) => {
      totals[key] = 0;
    });
    totalsByType[type] = totals;
  });

  const resetedCart = {
    total_price_vat: 0,
    total_price: 0,
    total_quantity: 0,
    totals_by_type: totalsByType,
  };

  return Object.assign(cart, resetedCart);
}

function updateDeliveryDates(cart, deliveryDates) {
  return (dispatch) => {
    dispatch(updateCart(cart, deliveryDates));
  };
}

function updateDeliveryMode(cart, deliveryMode) {
  return (dispatch) => {
    cartService.updateCart(cart.id, deliveryMode, false);
    dispatch(request(Object.assign(cart, deliveryMode)));
  };
  function request(newCart) {
    return {
      type: cartConstants.UPDATE_REQUEST,
      cart: newCart,
    };
  }
}

function updateStoreCart(cart) {
  store.dispatch({ type: cartConstants.CART_UPDATE, cart });
}

function updateCart(cart, data, withReset = false) {
  return (dispatch) => {
    dispatch(request(Object.assign(cart, data)));
    cartService.updateCart(cart.id, data, withReset).then(
      (apiCart) => {
        dispatch(success(apiCart));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return {
      type: cartConstants.UPDATE_REQUEST,
      cart,
    };
  }
  function success(apiCart) {
    return { type: cartConstants.UPDATE_SUCCESS, cart: apiCart };
  }
  function failure(error) {
    return { type: cartConstants.UPDATE_FAILURE, error, cart };
  }
}

function downloadCartPdf(cart) {
  return (dispatch) => {
    dispatch(request());
    cartService.downloadCartPdf(cart.id).then(
      (cartPdfBlob) => {
        dispatch(success());
        const fileName = `PANIER-${cart.id}.pdf`;
        saveFile(fileName, cartPdfBlob);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: cartConstants.DOWNLOAD_CART_PDF_REQUEST, cart };
  }
  function success() {
    return { type: cartConstants.DOWNLOAD_CART_PDF_SUCCESS };
  }
  function failure(error) {
    return { type: cartConstants.DOWNLOAD_CART_PDF_FAILURE, error };
  }
}
