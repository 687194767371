import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  float: left;
  display: flex;
  padding: 0;

  .dd-header {
    height: 100%;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .dd-header-title {
      display: flex;
      align-items: center;
    }
  }

  .dd-search {
    width: 100%;
    border: 1px solid #01366b;
    margin-bottom: 10px;
  }

  .dd-list {
    min-width: calc(100% + 2px);
    margin: 0;
    padding: 0;
    background: #fff;
    list-style: none;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    overflow: hidden;
    cursor: initial;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      input[type='checkbox'] {
        cursor: pointer;
      }
    }
  }
`;

const fadeIn = keyframes`
  from {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }

  to {
    transform: translateY(100%);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }

  to {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }
`;

export const PanelWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  left: -1px;
  transform: translateY(100%);
  z-index: 10;
  visibility: ${(props) => (props.listOpen ? 'visible' : 'hidden')};
  animation: ${(props) => (props.listOpen ? fadeIn : fadeOut)} 0.2s linear;
  transition: visibility 0.2s linear;
`;

export const ListWrapper = styled.div`
  padding: 10px;
`;
