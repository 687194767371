import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Icon from 'components/Icon/Icon';
import withStyleCalendar from './Calendar.style';
import 'react-datepicker/dist/react-datepicker.css';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
  }

  closeCalendar = () => {
    const { current } = this.calendarRef;
    current.setOpen(false);
  };

  render() {
    const { className, InputClassName } = this.props;
    return (
      <div className={className}>
        <DatePicker
          {...this.props}
          dateFormat="dd/MM/yyyy"
          popperClassName="delivery-date-popper"
          calendarClassName="delivery-date-calendar-content"
          className={InputClassName}
          ref={this.calendarRef}
          locale="fr"
        >
          <Icon content="\e915" onClick={() => this.closeCalendar()} />
        </DatePicker>
      </div>
    );
  }
}

Calendar.propTypes = {
  className: PropTypes.string,
  InputClassName: PropTypes.string,
};

export default withStyleCalendar(Calendar);
