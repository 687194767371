import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions, cartActions, containerActions } from 'actions';
import { containerService } from 'services';
import { getPath, history, store } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalContainersNotFull.style';

const ModalContainersNotFull = ({ className, closeModal, cart, validateCart }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block">
        <p>Un ou plusieurs containers ne sont pas pleins !</p>
        <p>Voulez vous continuer vos achats ?</p>
      </div>

      <div className="modal-body__button-block">
        <ButtonPrimary
          onClick={() => {
            closeModal();
            history.push(getPath('aggrid'));
          }}
        >
          Retourner au catalogue
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            closeModal();
            validateCart(cart);
          }}
        >
          Valider votre panier
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalContainersNotFull.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  validateCart: PropTypes.func,
  cart: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => ({
  validateCart: (cart) => {
    const resultSave = new Promise((resolve) => {
      resolve(containerService.saveContainers(cart.dataBins));
    });
    if (resultSave) {
      resultSave.then((response) => {
        if (response && response.status === true) {
          dispatch(
            cartActions.validateCart(
              cart.effectiveCart,
              cart.deliveryDates,
              cart.withDeliveryAmount,
              cart.deliveryAmount,
              cart.isPreparationOnly,
              cart.platformHasContainerization
            )
          );
          dispatch(containerActions.deleteContainers());
        }
      });
    }
  },
  closeModal: () => dispatch(modalActions.close()),
});
export default connect(null, mapDispatchToProps)(withStyle(ModalContainersNotFull));
