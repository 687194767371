import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const assortmentService = {
  fetchTotalRowNumber,
  fetchAssortments,
  createAssortment,
  updateAssortment,
  deleteAssortment,
  uploadSelection,
};

function createAssortment(data) {
  const body = JSON.stringify({
    ...data,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/group/add/item`, requestOptions).then(handleResponse);
}

function updateAssortment(id, data) {
  const body = JSON.stringify({
    id,
    ...data,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/group/update`, requestOptions).then(handleResponse);
}

function fetchTotalRowNumber(/* filter */) {
  return new Promise((resolve) => {
    resolve({ numberDataAvailable: 100 });
  });
  /* ENDPOINT NO READY YET

    const requestOptions = {
      method: 'GET',
      headers: authHeader(false),
      mode: 'cors',
    };
    const urlFilter = JSON.stringify(filter);
    return fetch(`${config.apiUrl}/api/group/item/count?filter=${urlFilter}`, requestOptions)
    .then(handleResponse);
  */
}

function fetchAssortments(filter, offset, limit, withDetails) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  if (withDetails) {
    const { id } = filter;
    return fetch(`${config.apiUrl}/api/group/detail/${id}`, requestOptions).then(handleResponse);
  }
  return fetch(
    `${config.apiUrl}/api/group/item?filter=${urlFilter}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function deleteAssortment(assortmentId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/group/delete/${assortmentId}`, requestOptions).then(
    handleResponse
  );
}

function uploadSelection(data) {
  const { label, file, client, date_end: dateEnd } = data;
  const body = new FormData();
  body.append('client', client);
  body.append('dateEnd', dateEnd);
  body.append('file', file);
  body.append('label', label);
  body.append('name', label);
  const headers = {
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/group/selection/create`, requestOptions).then(handleResponse);
}
