import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useTable, useSortBy } from 'react-table';
import { useThrottle } from 'use-lodash-debounce-throttle';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TiArrowSortedDown } from '@react-icons/all-files/ti/TiArrowSortedDown';
import { TiArrowSortedUp } from '@react-icons/all-files/ti/TiArrowSortedUp';
import { TiArrowUnsorted } from '@react-icons/all-files/ti/TiArrowUnsorted';
import Spinner from 'components/Core/Spinner/Spinner';
import { ReactTableWrapper } from 'components/Core/ReactTable/ReactTable.style';

function ReactTable({
  columns,
  data,
  isLoading,
  next,
  totalRowCount,
  loadedRowCount,
  renderRowSubComponent,
  enableArrowNavigation,
}) {
  const [selectedRow, setSelectedRow] = useState(0);
  const tbodyRef = React.useRef(null);

  useEffect(() => {
    if (data) {
      if (enableArrowNavigation) {
        window.addEventListener('keydown', throttledHandleKeyDown);
        if (tbodyRef.current && selectedRow) {
          tbodyRef.current.children[selectedRow].classList.add('react-table__row__active');
        }
      }
    }

    return () => {
      window.removeEventListener('keydown', throttledHandleKeyDown);
    };
  }, [selectedRow, enableArrowNavigation]);

  useEffect(() => {
    if (tbodyRef.current && selectedRow !== 0) {
      Array.forEach(tbodyRef.current.children, (rowElem) =>
        rowElem.classList.remove('react-table__row__active')
      );
    }
    setSelectedRow(0);
  }, [totalRowCount]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp' && tbodyRef.current.children[selectedRow].previousElementSibling) {
      tbodyRef.current.children[selectedRow].classList.remove('react-table__row__active');
      tbodyRef.current.children[selectedRow].previousElementSibling.classList.add(
        'react-table__row__active'
      );
      setSelectedRow(() => tbodyRef.current.children[selectedRow].previousElementSibling.id);
      tbodyRef.current.children[selectedRow].previousElementSibling.scrollIntoView({
        block: 'center',
      });
    }
    if (event.key === 'ArrowDown' && tbodyRef.current.children[selectedRow].nextElementSibling) {
      tbodyRef.current.children[selectedRow].classList.remove('react-table__row__active');
      tbodyRef.current.children[selectedRow].nextElementSibling.classList.add(
        'react-table__row__active'
      );
      setSelectedRow(() => tbodyRef.current.children[selectedRow].nextElementSibling.id);
      tbodyRef.current.children[selectedRow].nextElementSibling.scrollIntoView({
        block: 'center',
      });
    }
    if (event.key === 'Enter' && tbodyRef.current.children[selectedRow]) {
      rows[tbodyRef.current.children[selectedRow].id].original.handleRowClick();
    }
  };

  const throttledHandleKeyDown = useThrottle(
    (event) => {
      handleKeyDown(event, selectedRow);
    },
    100,
    { leading: false }
  );

  const loader = (
    <tr className="react-table__loader">
      <td colSpan={columns.length}>
        <Spinner />
      </td>
    </tr>
  );

  // Render the UI for your table
  return (
    <ReactTableWrapper id="scrollable-div" className="react-table__wrapper">
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={data.length > 0 && !!next && data.length < totalRowCount}
        loader={loader}
        scrollableTarget="scrollable-div"
        pullDownToRefreshThreshold={10}
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={uuid()} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (column) =>
                    !column.isHidden && (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <span>
                          {column.render('Header')}
                          {!column.disableSortBy && (
                            <>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TiArrowSortedDown
                                    size="17px"
                                    style={{ transform: 'translateY(3px)' }}
                                  />
                                ) : (
                                  <TiArrowSortedUp size="17px" />
                                )
                              ) : (
                                <TiArrowUnsorted size="17px" style={{ fill: '#AAAAAA' }} />
                              )}
                            </>
                          )}
                        </span>
                      </th>
                    )
                )}
              </tr>
            ))}
          </thead>
          <tbody ref={tbodyRef} {...getTableBodyProps()}>
            {(!rows || !rows.length) && !isLoading && (
              <tr className="react-table__no-result">
                <td colSpan={columns.length}>Aucun résultat trouvé</td>
              </tr>
            )}
            {!isLoading &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <>
                    <tr
                      id={row.id}
                      tabIndex={0}
                      {...row.getRowProps()}
                      className={i === 0 ? 'react-table__row__active' : undefined}
                      style={(!row.original.handleRowClick && { cursor: 'initial' }) || {}}
                    >
                      {row.cells.map((cell) => {
                        return cell.column.isHidden ? null : (
                          <td
                            {...cell.getCellProps({
                              className: cell.column.className,
                            })}
                            onClick={!cell.column.disableClick && row.original.handleRowClick}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {!!renderRowSubComponent && renderRowSubComponent({ row })}
                  </>
                );
              })}
          </tbody>
        </table>
        {isLoading && loader}
      </InfiniteScroll>
      {totalRowCount > 0 && loadedRowCount > 0 && (
        <div className="react-table__row-counter">
          {loadedRowCount} sur {totalRowCount}
        </div>
      )}
    </ReactTableWrapper>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  next: PropTypes.func,
  totalRowCount: PropTypes.number,
  loadedRowCount: PropTypes.number,
  renderRowSubComponent: PropTypes.func,
  enableArrowNavigation: PropTypes.bool,
};

export default ReactTable;
