import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body__text-block {
    color: #01366b;
    text-align: center;
    padding-bottom: 20px;
  }
  .modal-body__text-block:first-letter {
    text-transform: uppercase;
  }
  .modal-body__button-block {
    text-align: center;
  }
  .modal-body__button-block a {
    padding: 12px 20px 11px 20px;
    width: 70px;
    text-align: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;
