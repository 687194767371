import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastActions } from 'actions/Toast';
import ToastWrapper from './Toast.style.jsx';

const Toast = ({ item, removeToast }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    if (item.duration !== 0) {
      setTimeout(() => {
        hideToastWithDelay();
      }, 10000);
    }

    return () => null;
  }, []);

  const hideToastWithDelay = () => {
    setShow(false);
    setTimeout(() => {
      removeToast(item.id);
    }, 300);
  };

  return (
    <ToastWrapper
      type={item.type}
      onClick={() => {
        hideToastWithDelay(item.id);
      }}
      show={show}
    >
      {item.title && <div className="toast-wrapper__title">{item.title}</div>}
      <div>{item.message}</div>
    </ToastWrapper>
  );
};

Toast.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  removeToast: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  removeToast: (toastId) => dispatch(toastActions.removeToast(toastId)),
});

export default connect(null, mapDispatchToProps)(Toast);
