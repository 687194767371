import React from 'react';
import { GiPadlock } from '@react-icons/all-files/gi/GiPadlock';
import { selectCurrentPlatformId } from 'selectors/platform';
import { store } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import { modalActions } from 'actions';
import { ClientStatus } from 'components/Table/Cells/Clients';
import ClientLockedCell from 'components/Table/Cells/Client/ClientLockedCell';
import { clientStatusConstants } from '../../constants';

export const isCatalogActive = (cell) => {
  const currentPlatformStatus = cell.data && cell.data.client_status;

  return currentPlatformStatus &&
    currentPlatformStatus &&
    currentPlatformStatus.status_catalog === clientStatusConstants.STATUS_ACTIVE
    ? 'Actif'
    : 'Inactif';
};

export const isClientActive = (cell) => {
  const currentPlatformStatus = cell.data && cell.data.status;

  return currentPlatformStatus && currentPlatformStatus === clientStatusConstants.STATUS_ACTIVE
    ? 'Actif'
    : 'Inactif';
};

export const isClientLockCellRenderer = (cell) => {
  return cell && cell.data && cell.data.locked && Number(cell.data.locked) ? (
    <ClientLockedCell />
  ) : (
    ''
  );
};

export const isPreorderActive = (cell) => {
  const currentPlatformStatus = cell.data && cell.data.client_status;

  return currentPlatformStatus &&
    currentPlatformStatus &&
    currentPlatformStatus.status_preorder === clientStatusConstants.STATUS_ACTIVE
    ? 'Actif'
    : 'Inactif';
};

export const commercialFilter = (cell) => {
  const selectedPlatformId = selectCurrentPlatformId(store.getState());
  return (
    cell.data &&
    cell.data.commercials
      .filter((commercial) => commercial.platform_id === selectedPlatformId)
      .map((commercial) => `${commercial.firstname} ${commercial.lastname}`)
  );
};

export const termsValueGetter = (cell) => {
  const selectedPlatformId = selectCurrentPlatformId(store.getState());
  const currentPlatformTerm =
    cell.data && cell.data.terms.find((term) => term.platform_id === selectedPlatformId);

  return (currentPlatformTerm && currentPlatformTerm.title) || '-';
};

export const planningFilter = (cell) => {
  const selectedPlatformId = selectCurrentPlatformId(store.getState());

  return (
    cell.data &&
    cell.data.delivery_plannings
      .filter((planning) => planning.platform_id === selectedPlatformId)
      .map((planning) => planning.name)
  );
};

export const clientActionCellRenderer = (params = {}) => {
  const {
    // eslint-disable-next-line camelcase
    data,
  } = params;
  const {
    // eslint-disable-next-line camelcase
    client_status,
  } = data || {};
  const { status_preorder: statusPreorder, status_catalog: statusCatalog, status } =
    // eslint-disable-next-line camelcase
    client_status || {};
  const isClientNotActive =
    // eslint-disable-next-line camelcase
    !client_status ||
    status === clientStatusConstants.STATUS_INACTIVE ||
    (statusPreorder === clientStatusConstants.STATUS_BLOCKED &&
      statusCatalog === clientStatusConstants.STATUS_BLOCKED);
  const username = params.data && params.data.customers[0].username;

  return (
    <ButtonPrimary
      inverted
      className="item-action__button"
      disabled={isClientNotActive}
      onClick={() =>
        !isClientNotActive &&
        store.dispatch(modalActions.open('redirect', { modalHeader: '', username }))
      }
      style={{ display: 'inline', padding: '10px' }}
    >
      <Icon content="\e910" /> <span>ESPACE CLIENT</span>
    </ButtonPrimary>
  );
};

export const isCatalogActiveCellRenderer = (cell) =>
  cell.data ? (
    <ClientStatus
      clientStatus={cell.data && cell.data.client_status}
      isPreorder={false}
      shouldShowResponsive
    />
  ) : (
    '-'
  );

export const isPreorderActiveCellRenderer = (cell) =>
  cell.data ? (
    <ClientStatus
      clientStatus={cell.data && cell.data.client_status}
      isPreorder
      shouldShowResponsive
    />
  ) : (
    '-'
  );
