import { aggridCategoryDisplayConstants } from 'constants/AggridCategoryDisplay.constants';

const initState = {
  categoryDisplay: false,
};

export function aggridCategoryDisplay(state = initState, action) {
  switch (action.type) {
    case aggridCategoryDisplayConstants.TOGGLE_CATEGORY_DISPLAY:
      return {
        ...state,
        categoryDisplay: action.categoryDisplay,
      };
    default:
      return state;
  }
}
