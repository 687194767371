import styled from 'styled-components';
import { ButtonPrimary } from 'components/Core/Button/Button';

export const ButtonP = styled(ButtonPrimary)`
  height: 40px;
  text-align: left;

  .header__button-content {
    margin: auto;
    font-size: 16px;
    padding: 0 20px;
  }

  .header__button-title {
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: Montserrat;
    .header__button-title-small {
      line-height: 13px;
      font-size: 10px;
    }
    .header__button-title-amount {
      font-weight: 600;
    }
  }
`;

export const ConfirmationCommandeDIV = styled.div`
  .icon-check-ok:before {
    color: #29d24e;
    font-size: 20px;
  }
  .cart-confirmation__button-block a:last-child i {
    font-size: 30px;
    display: inline-block;
    margin-top: 1px;
  }

  .cart-confirm__container-icon {
    text-align: center;
  }

  .cart-confirm__icon {
    color: #29d24e;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }

  .cart-confirm__text {
    color: #01366b;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 16px;
    display: block;
  }

  .cart-confirm__container {
    width: 100%;
    padding-top: 43px;
    text-align: center;
  }
  .cart-confirm__icon .icon-check-ok:before {
    font-size: 14px;
  }
  .cart-confirmation__button-block {
    padding-top: 35px;
    letter-spacing: 0.15px;
    line-height: 15px;
    font-size: 12px;
  }
  .cart-confirm__button {
    padding: 11px 20px;
    display: inline-block;
    margin-left: 18px;
  }

  .cart-confirm__button i {
    margin-right: 10px;
    vertical-align: middle;
  }

  .cart-confirm__button .icon-eye {
    line-height: 0;
    margin-top: 1px;
  }

  .cart-confirm__button .icon-eye:before {
    font-size: 24px;
    top: 5px;
    position: relative;
  }
  .button-icon-save-label {
    letter-spacing: 0.15px;
    line-height: 15px;
    font-size: 12px;
    vertical-align: middle;
  }

  @media screen and (max-width: 508px) {
    .cart-confirm__text {
      width: 300px;
      font-size: 17px;
    }
    .cart-confirm__button {
      margin: 4%;
      display: inline-block;
    }
    .cart-confirm__icon {
      margin: 9%;
    }
  }
`;
