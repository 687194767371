import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  width: calc(100% - 45px);
  margin: 0 auto;

  .react-table__wrapper {
    border: 0;
    border-radius: 4.5px;
    max-height: calc(100vh - 226px);
    overflow: auto;
    box-shadow: none;
    .react-table__row-counter {
      background: #dbdee7;
      color: #01366b;
      font-weight: 600;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #fefefe;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }

    .page-commercial__total-container {
      display: flex;
      color: #01366b;
      white-space: nowrap;
    }

    .page-message__table__actions {
      display: flex;
      justify-content: space-around;
    }

    table {
      border: 0;
      box-shadow: none;

      thead {
        color: #01366b;

        th {
          white-space: nowrap;
          background: #dbdee7;
          border-right: 1px solid #fff;
        }
        th:last-child {
          border-right: none;
        }
      }

      tbody {
        background: #fff;

        .icon-bdc {
          font-size: 29px;
          cursor: pointer;
        }

        tr {
          cursor: pointer;
          &:hover {
            background: rgb(249, 250, 251);
          }

          td {
            padding: 9px 24px;
          }
          td.actions {
            text-align: center;
            .edit,
            .delete {
              display: flex;
              align-items: center;
              padding: 7px;
              cursor: pointer;
              font-weight: bold;
              color: #01366b;
              border-radius: 4.5px;
              background: #ededed;
              font-size: 12px;
              svg {
                margin-right: 6px;
              }
            }
            .delete {
              background: #a70000;
              color: #fff;
            }
          }
        }

        .react-table__no-result {
          cursor: default;
        }
      }
    }
  }

  .heading__label {
    color: #01366b;
    height: 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .page-message {
    background-color: #f5f5f5;
    padding-top: 22px;
    padding-bottom: 10px;
  }
  .page-message__section-label {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #01366b;
    padding-left: 40px;
  }
  .page-messag__actions-block {
    padding: 11px 0;
    margin-bottom: 10px;
    text-align: right;
    a {
      padding: 12px 20px 10px 20px;
      color: #fff;
      background: #01366b;
      border: none;
    }
    span {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      vertical-align: middle;
      padding-left: 5px;
    }
    i {
      vertical-align: middle;
    }
  }
  table {
    tr {
      th {
        &.header_product-brand {
          width: 18.5%;
        }
        &:nth-child(2) {
          width: 20%;
        }
      }
      &.row {
        height: 85px;
        td {
          &:first-child {
            padding-left: 20px;
            .boldgry {
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
  .action-block i {
    margin-right: 25px;
  }
  /**********Responsive*************/
  @media ${screen.tablet} {
    .page-message {
      table {
        tr {
          &.row {
            height: auto;
            .header_product-brand {
              word-break: break-word;
              width: 100%;
              margin-bottom: 10px !important;
            }
            .header_product-date {
              padding-left: 0 !important;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
`;
