import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoggedAsSomeoneElse } from 'helpers';
import { userActions } from 'actions';
import { userConstants } from 'constants/User.constants';
import NotificationCenter from 'components/NotificationCenter/NotificationCenter';
import CustomerHeader from 'components/Core/Utils/Navigation/Header/CustomerHeader';
import AdminHeader from 'components/Core/Utils/Navigation/Header/AdminHeader';
import SuperAdminHeader from 'components/Core/Utils/Navigation/Header/SuperAdminHeader';
import { HeaderStyle } from './Header.style';
import ProfilMenu from './ProfilMenu';

const {
  TYPE_COMMERCIAL: USER_TYPE_COMMERCIAL,
  TYPE_ADMIN: USER_TYPE_ADMIN,
  TYPE_SUPER_ADMIN: USER_TYPE_SUPER_ADMIN,
  TYPE_CUSTOMER: USER_TYPE_CUSTOMER,
} = userConstants;

const Header = ({ userInfo, logoutAs }) => {
  const { type } = userInfo || null;

  return (
    <>
      <HeaderStyle>
        {(type === USER_TYPE_ADMIN || type === USER_TYPE_COMMERCIAL) && <AdminHeader />}

        {type === USER_TYPE_SUPER_ADMIN && <SuperAdminHeader />}

        {type === USER_TYPE_CUSTOMER && <CustomerHeader />}

        {!isLoggedAsSomeoneElse() ? (
          <div className="header-container">
            {type === USER_TYPE_CUSTOMER && <NotificationCenter />}
            <ProfilMenu />
          </div>
        ) : (
          <div className="preheader_logout-container" onClick={logoutAs}>
            <div className="preheader_logout_wrapper">
              <span className="preheader_logout">DÉCONNEXION</span>
              <span className="preheader_logout">ESPACE CLIENT</span>
            </div>
            <span className="button-circular__icon">
              <i className="icon-logout-preheader" />
            </span>
          </div>
        )}
      </HeaderStyle>
    </>
  );
};

Header.propTypes = {
  userInfo: PropTypes.object,
  logoutAs: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    userInfo: user.information,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutAs: () => {
    dispatch(userActions.logoutAs());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
