import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectMaintenance } from 'selectors/maintenance';
import { BsConeStriped } from '@react-icons/all-files/bs/BsConeStriped';
import styled from 'styled-components';
import { maintenanceActions } from 'actions/Maintenance';
import { history, getPath } from 'helpers';

const MaintenanceWrapper = styled.div`
  svg {
    width: 100%;
    margin: 0 auto 10px auto;
    font-size: 50px;
    color: #e14d39;
  }
  h3 {
    text-align: center;
  }
`;

const Maintenance = ({ checkHealth, alive }) => {
  useEffect(() => {
    checkHealth();
  }, []);
  useEffect(() => {
    if (alive && alive.isAlive === true) {
      history.push(getPath('login'));
    }
  }, [alive]);

  return (
    <MaintenanceWrapper>
      <div className="login__background fade-in">
        <div className="login__form">
          <div className="login__container">
            <BsConeStriped />
            <h3>{alive.title || <>Commande.com est en cours de maintenance</>}</h3>
            <p>
              {alive.message || (
                <>
                  Nous sommes actuellement en cours de maintenance. Veuillez nous excuser pour la
                  gêne occasionnée.
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </MaintenanceWrapper>
  );
};

const mapStateToProps = (state) => ({
  alive: selectMaintenance(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkHealth: () => dispatch(maintenanceActions.checkHealth()),
});

Maintenance.propTypes = {
  alive: PropTypes.shape({
    isAlive: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
