import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  background-color: #f5f5f5;
  display: contents;
  .panier__type {
    width: calc(100% - 85px);
    margin: 0 auto;
    table {
      tr {
        .header_product-wording,
        .header_product-brand {
          width: 22.5%;
        }
        .header_product-wording:first-child {
          padding-left: 20px;
        }
        .header_product-price {
          text-align: right;
          padding-right: 27px;
        }
        th.header_product-price {
          padding-right: 50px;
        }
        &.row {
          height: 80px;
          td {
            width: auto;
            .bold {
              color: #4a4a4a;
              &:first-letter {
                text-transform: capitalize;
              }
              i {
                font-size: 12px;
                &:before {
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                }
              }
            }
            &.header_product-price {
              .bold {
                color: #01366b;
                font-size: 18px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .sections .home-page__section {
    position: relative;
    margin-top: -1px;
    border-top: 1px solid #01366b;
  }
  .sections .table-wrapper {
    min-height: calc(100vh - 625px);
    .table {
      padding-top: 10px;
      tr {
        cursor: inherit;
      }
    }
  }
  .bloc-label__value {
    color: #01366b;
  }
  .page-cart {
    background-color: #f5f5f5;
    padding-bottom: ${() =>
      `${
        document.getElementById('sticky-block') &&
        document.getElementById('sticky-block').scrollHeight + 30
      }px`};
    padding-bottom: 40px;
    padding-top: 20px;
    .cart-footer-notice {
      position: fixed;
      width: calc(70% - 20px);
      left: 0;
      bottom: 0;
      background: #fff;
      padding: 8px;
      text-align: center;
      z-index: 10;
      font-size: 11px;
    }
  }
  .page-cart__top__section {
    margin-bottom: 0;
    padding: 0;
    background: #ffffff;
    position: fixed;
    top: ${() =>
      `${
        document.getElementById('list-cart') && document.getElementById('list-cart').offsetTop + 113
      }px`};
    right: 0;
    width: 30%;
    height: ${() =>
      `calc(100% - ${
        document.getElementById('list-cart') && document.getElementById('list-cart').offsetTop + 113
      }px)`};
    top: 60px;
    height: calc(100% - 60px);
    z-index: 10;
  }
  .page-cart__heading {
    padding: 2px 26px;
  }
  .page-cart__section-label {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #01366b;
    padding-left: 20px;
    display: none;
    i {
      padding-right: 13px;
    }
    span,
    i {
      display: inline-block;
      vertical-align: middle;
    }
    .client-name {
      float: right;
      text-align: right;
      letter-spacing: 0.15px;
      font-size: 12px;
      line-height: 19px;
      font-weight: 500;
      color: #4a4a4a;
    }
  }
  .cartname {
    padding: 16px 40px 5px;
    width: 100%;
    color: #01366b;
    font-weight: bold;
    text-transform: capitalize;
    border-bottom: 1px solid rgba(1, 54, 107, 0.2);
    i {
      font-size: 16px;
      padding-right: 12px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .cart-date-creation {
    width: 100%;
    background-color: #fff;
    color: #01366b;
    .information-not-available {
      width: 100%;
      padding: 0px 40px;
      display: block;
    }
    span {
      width: 100%;
      padding: 0px;
      color: #4a4a4a;
      font-size: 12px;
      :first-child {
        padding: 0px 0px 0px 40px;
      }
    }
    &:after {
      content: '';
      border-bottom: 1px solid rgba(1, 54, 107, 0.2);
      display: block;
      width: calc(100% - 80px);
      margin: 0 auto;
      padding-bottom: 5px;
    }
    div {
      display: inline-block;
      &:first-child {
        width: 100%;
      }
      .cart-date-creation__date-bloc {
        display: inline-block;
        float: left;
        width: 95%;
      }
      .cart-date-creation__status-bloc {
        float: right;
        padding-top: 3px;
        span {
          color: #f5a623;
          font-weight: 500;
          text-transform: uppercase;
          padding-right: 30px;
          font-size: 15px;
        }
      }
    }
  }

  .summary {
    background-color: #fff;
    position: relative;
    z-index: 11;
    table {
      padding: 10px;
      width: 100%;
    }
    tr {
      background: #f5f5f5;
      td {
        width: 100%;
        display: block;
        padding: 10px;
        &:first-child {
          width: 300px;
          white-space: nowrap;
        }
        &:endtd {
          width: 150px;
        }
        i {
          margin-right: 15px;
        }
      }
      td.td-permanent-card {
        width: 60%;
        display: inline-block;
      }
      td.td-permanent-cart__Total {
        width: 40%;
        display: inline-block;
      }
      td.endtd {
        padding: 0;
        background: #fff;
        height: 3px;
      }
    }
    &:not(:last-child):after {
      content: '';
      border-bottom: 3px solid #fff;
      display: block;
      width: 100%;
      margin: 0 auto;
    }
    &.delivery-mode {
      margin-bottom: 20px;
      padding: 16px 20px 16px 40px;
      width: 100%;
      .title-bold {
        color: #01366b;
        font-weight: bold;
      }
      .delivery-mode-choice {
        margin-bottom: 22px;
        width: 100%;
        display: inline-block;
        &:last-child {
          margin-bottom: 22px;
        }
      }
      .block__radio {
        width: 54%;
        &.no_radio_button {
          padding-left: 0;
        }
      }
      .delivery-date {
        float: right;
        width: 320px;
        span {
          color: #4a4a4a;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;
          font-weight: bold;
          margin-right: 10px;
          display: inline-block;
          &.delivery-date__text-bloc {
            cursor: none;
            font-weight: normal;
          }
        }
        .delivery-date__calendar-bloc {
          display: inline-block;
        }
      }
      label {
        span {
          color: #4a4a4a;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;
          font-weight: bold;
        }
        .delivery-mode-price {
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.15px;
        }
      }
      &:after {
        border-bottom: none;
      }
    }
  }
  .permanent-cart__Total-all {
    padding: 0 20px;
    background-color: #fff;
    text-align: right;
    font-size: 17px;
    span {
      color: #01366b;
      font-weight: bold;
    }
  }
  .page-content {
    ul {
      list-style-type: none;
      overflow: hidden;
      padding: 0px;
      line-height: 25px;
      margin-left: 20px;
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
      z-index: 1;
      li {
        display: inline-block;
        color: #a4a4a4;
        margin-right: 10px;
        border: 1px solid #01366b;
        border-radius: 4.25px 4.25px 0 0;
        background-color: #ffffff;
        padding: 4px 20px 0px 20px;
        vertical-align: bottom;
        line-height: normal;
        height: 45px;
        a {
          text-decoration: none;
          display: inline-block;
          position: relative;
          top: 3px;
          span {
            color: #a4a4a4;
            text-transform: uppercase;
            vertical-align: sub;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1.06px;
            vertical-align: middle;
          }
        }
        i {
          vertical-align: middle;
          margin-right: 5px;
        }
        &:hover {
          cursor: pointer;
          a {
            span {
              color: #01366b;
            }
          }
        }
        &.active {
          height: 55px;
          padding-top: 10px;
          border-bottom: none;
          a {
            span {
              color: #01366b;
            }
          }
        }
      }
    }
  }
  .actions {
    margin-bottom: 10px;
    text-align: center;
    padding-right: 0;
    a {
      margin-left: 5px;
      padding: 11px 12px;
      border-radius: 4.25px;
      border: 1px solid #01366b;
      color: #01366b;
      i {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-weight: normal;
      }
      svg {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
    span {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      padding-left: 6px;
    }
  }
  .copy-cart-spinner {
    display: inline-block;
    width: 197px;
    position: relative;
    top: 15px;
  }

  .permanent-cart__title {
    color: #01366b;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-left: 20px;
  }

  .permanent__headline-temperature {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }

  .permanent-cart__Date {
    float: right;
  }

  .permanent-cart__Total {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    text-align: right;
    display: block;
  }

  .permanent-cart__Total_Price {
    padding-left: 40%;
  }

  .permanent-cart__total-details {
    background-color: white;
    padding: 30px;
  }

  .permanent-cart {
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 14px;
    span {
      display: inline-block;
      margin-right: 8px;
    }
    .permanent-cart__calendar {
      display: inline-block;
      width: 25%;
    }
    .react-datepicker-popper {
      z-index: 2;
    }
    span:nth-child(2) {
      font-size: 12px;
      letter-spacing: 0.15px;
      font-weight: 500;
      line-height: 15px;
      width: 100px;
    }
  }
  .button {
    background-color: #f5f5f5;
    padding: 30%;
  }

  /* .icon-arrow-down {
  color: #4A4A4A;
  content: "\e903";
  font-size: 10px;
  padding: 16px;
  transform: rotate(180deg);
} */
  .cart__section-temperature {
    background: #fff;
    .permanent-cart__Total {
      display: inline-block;
      vertical-align: middle;
      float: right;
      padding: 9px 20px;
    }
  }
  .cart__section-temperature__icon {
    padding: 0;
    margin: 8px 23px;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  .icon-check-ok-white {
    content: '\e912';
    height: 12px;
    width: 16px;
    color: #4a4a4a;
  }

  .icon-delete:before {
    color: #0caaff;
    height: 16px;
    width: 15px;
    content: '\e916';
  }

  .permanent-cart__ref {
    color: #4a4a4a;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  .permanent-cart__formul {
    padding-left: 35%;
  }

  .Circle__icon-blue {
    margin-top: 0px;
    background-color: #63c8ff;
    border-radius: 24px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .Circle__icon-green {
    background-color: #35e687;
    border-radius: 24px;
    line-height: 24px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .permanent-cart-input__Date {
    height: 20px;
    width: 109px;
    border: 1px solid #01366b !important;
    background-color: #fff;
    margin-right: 10px;
  }
  .page_cart__section-label {
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #01366b;
  }
  .cart-footer-comment {
    padding: 20px;
    width: 100%;
    display: inline-block;
    span:first-child {
      color: #4a4a4a;
      font-size: 15px;
      line-height: 16px;
      font-weight: 500;
      display: block;
      margin-bottom: 7px;
    }
    span:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
    }
    textarea {
      margin-left: 0;
      height: 50px;
      border: 1px solid #01366b;
      background-color: #fff;
      width: 100%;
      display: block;
      vertical-align: middle;
      padding: 7px 10px 7px 10px;
      resize: none;
      overflow: hidden;
      font-family: arial;
    }
  }
  .use-panier {
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    span {
      border-radius: 4.25px;
      padding: 11px 10px;
      cursor: pointer;
      font-size: 12px;
      color: #fff;
      text-align: center;
      background: #01366b;
      display: inline-block;
      font-weight: 600;
    }
  }
  /**********Responsive************ */
  @media ${screen.laptop} {
    .permanent-cart {
      width: 75%;
    }
  }
  @media screen and (max-width: 906px) {
    .page-cart {
      background-color: #f5f5f5;
      padding-bottom: ${() =>
        `${
          document.getElementById('sticky-block') &&
          document.getElementById('sticky-block').scrollHeight + 40
        }px`};
      padding-top: 20px;
    }
    .actions {
      span {
        a {
          margin-bottom: 13px;
        }
        a {
          &:first-child {
            position: relative;
          }
        }
      }
    }
  }
  @media ${screen.tablet} {
    .page-cart {
      padding-bottom: ${() =>
        `${
          document.getElementById('sticky-block') &&
          document.getElementById('sticky-block').scrollHeight + 55
        }px`};
    }
    .cart-footer-comment {
      span:nth-child(2) {
        width: 66%;
        display: inline-block;
      }
      textarea {
        width: 66%;
        display: inline-block;
      }
    }
    .permanent-cart__Total-all {
      margin-bottom: 20px;
      padding-bottom: 19px;
    }
    .permanent-cart {
      width: 100%;
    }
    .panier__type {
      table {
        tr {
          &.row {
            height: auto;
          }
          .header_product-wording {
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
    .page-cart {
      table {
        tbody {
          .row {
            .header_product-quantity {
              width: 40%;
            }
            .header_product-action {
              width: 10%;
              display: inline-block;
              text-align: right;
            }
          }
        }
      }
    }
    .summary {
      &.delivery-mode {
        .delivery-date {
          float: unset;
          margin-top: 20px;
        }
        .block__radio {
          width: 100%;
        }
      }
    }
    .page-cart {
      table {
        tbody {
          .row {
            min-height: 520px;
          }
        }
      }
      .panier__type {
        table {
          tbody {
            .row {
              min-height: auto;
            }
          }
        }
      }
    }
    .header_product-quantity {
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
    .header_product-total {
      position: absolute;
      right: 60px;
      bottom: 17px;
    }
    .header_product-action {
      position: absolute;
      right: 15px;
      bottom: 20px;
    }
  }
  @media screen and (max-width: 648px) {
    .summary {
      margin: 0;
      border-radius: 0;
      table {
        padding: 10px;
        tr {
          td {
            padding-bottom: 20px;
            width: 100%;
            display: inline-block;
            &:last-child {
              width: 100%;
            }
          }
          &:last-child {
            td {
              padding-bottom: 7px;
            }
          }
        }
      }
      .permanent-cart__Total {
        text-align: right;
      }
    }
    .actions {
      span {
        &:last-child a {
          margin-top: 0;
        }
      }
      a {
        margin-bottom: 20px;
      }
    }
    .header_product-quantity {
      width: 75%;
    }
    .cart-date-creation {
      div {
        .cart-date-creation__date-bloc {
          width: auto;
        }
      }
      span {
        display: inline-block;
        padding-left: 40px;
        padding-right: 20px;
      }
    }
  }
  @media ${screen.mobileL} {
    .page-cart {
      padding-bottom: ${() =>
        `${
          document.getElementById('sticky-block') &&
          document.getElementById('sticky-block').scrollHeight + 65
        }px`};
    }
    .cart-footer-comment {
      span:first-child {
        width: 100%;
        display: inline-block;
      }
      span:nth-child(2),
      textarea {
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
      }
    }
    .page-cart__section-label {
      .client-name {
        float: unset;
        margin-top: 10px;
        display: inline-block;
        width: 100%;
      }
    }
  }

  @media ${screen.mobile} {
    .page-cart {
      padding-bottom: ${() =>
        `${
          document.getElementById('sticky-block') &&
          document.getElementById('sticky-block').scrollHeight + 70
        }px`};
    }
    .cart-footer-comment {
      width: 100%;
      display: inline-block;
      span:first-child {
        width: 100%;
        display: inline-block;
      }
      span:nth-child(2) {
        width: 100%;
        display: inline-block;
      }
      textarea {
        width: 100%;
        display: inline-block;
      }
    }
    .page-cart__section-label {
      padding-bottom: 35px;
    }
    .page-content li a {
      span {
        display: none;
      }
    }
    .actions {
      a {
        padding: 10px;
        span {
          display: none;
        }
      }
    }
    .summary table {
      padding: 7px 17px;
      tr {
        td {
          display: block;
          .permanent-cart-input__Date {
            width: 110px;
          }

          &:last-child {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 18px;
            text-align: right;
          }
        }
      }
    }
    .summary {
      &.delivery-mode {
        .delivery-date {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
  .clearfix {
    clear: both;
    height: 0px;
    &:after {
      content: '';
      clear: both;
    }
  }
  .col-70 {
    width: 70%;
    padding-right: 20px;
    position: relative;
  }
  .insertstickyCard {
    #sticky-block {
      background: #f5f5f5;
      box-shadow: none;
      border-top: none;
      position: absolute;
      left: 0;
      border-top: 1px solid #a4a4a4;
      .home__section__footer {
        padding: 0 10px 10px 10px;
        .footer__message-block {
          margin-bottom: 20px;
          .permanent-cart__text {
            font-size: 12px;
          }
        }
        .home__section__footer-errors {
          position: absolute;
          bottom: 0;
          background: #fff;
          padding: 10px;
          width: 100%;
          left: 0;
          min-height: 58px;
          text-align: center;
          .alert-bloc__label {
            padding-bottom: 4px;
          }
        }
      }
      .permanent-cart__Total {
        display: none;
      }
      .permanent-cart__button-container {
        float: none;
        width: 100%;
      }
      .permanent-cart__button-span {
        width: 100%;
        text-align: center;
        display: block;
        a {
          border-radius: 4.25px;
          padding: 4px 10px;
          .permanent-cart__button {
            padding: 7px 10px;
            .lxXJb .button-icon-label {
              font-size: 15px;
            }
          }
        }
      }
      .cart-footer-notice {
        display: none;
      }
    }
  }
  .actions-select {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1;
    select {
      padding: 13px 10px;
      border-radius: 4.25px 4.25px 0 0;
      border: 1px solid #01366b;
      border-bottom: 0;
      display: none;
    }
  }
  .dropdown-action {
    height: 37px;
    position: relative;
    display: flex;
    font-size: 13px;
    align-items: center;
    border: 1px solid #01366b;
    border-radius: 4.25px 4.25px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: #fff;
    font-family: Gotham Medium, sans-serif;
    .dd-header {
      svg {
        width: 17px;
        height: 17px;
        margin-right: 10px;
        fill: #01366b;
      }
    }
    .dd-panel-wrapper {
      left: inherit;
      right: 0;
      font-size: 13px;
      line-height: 16px;
      min-width: 250px;
      li {
        cursor: pointer;
        color: #01366b;
        padding: 8px 0;
        display: flex;
        margin-bottom: 5px;
        border-bottom: 1px solid #cecece;
        position: relative;
        svg {
          margin-right: 5px;
          width: 15px;
        }
        i {
          margin-right: 5px;
          width: 15px;
        }
        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .page-cart {
      #cart-page-notice.cart-footer-notice {
        display: block;
        position: relative;
        width: 100%;
        left: inherit;
        bottom: inherit;
        z-index: 9;
        padding: 20px;
      }
    }
    .col-70 {
      width: 100%;
      padding-right: 0;
    }
    .page-cart__top__section {
      margin-top: 20px;
      position: relative;
      top: inherit;
      right: inherit;
      width: 100%;
      height: auto;
      z-index: 10;
      .insertstickyCard {
        #sticky-block {
          background: #fff;
          box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
          border-top: none;
          position: fixed;
          left: inherit;
          width: 100%;
          z-index: 100;
          .cart-footer-notice {
            display: none;
            position: relative;
            padding: 8px 30px;
            width: 100%;
          }
          .permanent-cart__Total {
            display: block;
            font-size: 19px;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
    .endtd {
      display: none !important;
    }
  }
`;
