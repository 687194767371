import { searchParents } from './Category';

export const separateAssortment = (assortment = []) => {
  const assortmentFiltered = assortment.filter(
    (assortmentElement) => assortmentElement.isChecked === true
  );
  const assortmentItems = assortmentFiltered.filter((element) => element.isItem === true);
  let assortmentCategories = assortmentFiltered.filter((element) => element.isItem === false);

  assortmentItems.forEach((item) => {
    const parents = searchParents(assortmentCategories, item.parentId);
    parents.forEach((parent) => {
      const assortmentParentIndex = assortment.findIndex(
        (assortmentElement) => assortmentElement === parent.id
      );
      if (assortmentParentIndex > -1) {
        assortmentCategories.splice(assortmentParentIndex, 1);
      }
    });
  });
  if (assortmentCategories.length > 0) {
    const childrenFinder = (children, element) => {
      if (!element.children) {
        return [...children, element];
      }
      return element.children.reduce(childrenFinder, [...children]);
    };
    assortmentCategories = assortmentCategories.reduce(
      (children, element) => childrenFinder(children, element),
      []
    );
  }
  return {
    assortmentItems,
    assortmentCategories,
  };
};
