import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions, cartActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withModalErrorStyle from './ModalErrorCart.style';

const ModalOutOfStock = ({ className, closeModal, cart, validateCart }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>
          Un ou plusieurs produits de votre panier sont indisponibles. Voulez-vous confirmer cette
          commande ?
        </span>
      </div>
      <div className="modal-body__button-block">
        <div className="modal-body__button-block">
          <ButtonPrimary inverted onClick={closeModal}>
            NON
          </ButtonPrimary>
          <ButtonPrimary
            onClick={() => {
              validateCart(cart);
              closeModal();
            }}
          >
            Oui
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </div>
);

ModalOutOfStock.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  cart: PropTypes.object,
  validateCart: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  validateCart: (cart) =>
    dispatch(cartActions.validateCart(cart, undefined, false, 0, 0, false, true)),
});

export default connect(null, mapDispatchToProps)(withModalErrorStyle(ModalOutOfStock));
