import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { orderActions } from 'actions';
import { getIconClassByType } from 'helpers';
import Spinner from 'components/Core/Spinner/Spinner';

const FAC = 1;
const BDC = 2;

const CommandeValidateIconTD = styled.td`
  .order__icon {
    font-size: 29px;
    position: relative;
    display: inline-block;
    a {
      text-decoration: none;
      color: #01366b;
    }
  }
`;

const CommandeValidateIconDIV = styled.div`
  .order__icon {
    font-size: 29px;
    a {
      text-decoration: none;
      color: #01366b;
    }
  }
`;

const CommandeValidateIcon = ({
  className,
  commandevalidation,
  shouldShowResponsive,
  order,
  downloadPdf,
  pdfIsLoading,
  idOrderDownload,
  ...rest
}) => {
  let iconvalidate;
  switch (commandevalidation) {
    case FAC:
      iconvalidate = 'icon-fac';
      break;
    case BDC:
      iconvalidate = 'icon-bdc';
      break;
    default:
      iconvalidate = 'icon-bdc';
      break;
  }
  if (commandevalidation) {
    iconvalidate = getIconClassByType(commandevalidation);
  }

  return !shouldShowResponsive ? (
    <CommandeValidateIconTD className={className} {...rest}>
      <div className="order__icon">
        {pdfIsLoading && idOrderDownload === order.id ? (
          <Spinner />
        ) : (
          <i
            className={iconvalidate}
            onClick={(e) => {
              e.stopPropagation();
              downloadPdf(order);
            }}
          />
        )}
      </div>
    </CommandeValidateIconTD>
  ) : (
    <CommandeValidateIconDIV className={className} {...rest}>
      <div className="order__icon">
        {pdfIsLoading && idOrderDownload === order.id ? (
          <Spinner />
        ) : (
          <i
            className={iconvalidate}
            onClick={(e) => {
              e.stopPropagation();
              downloadPdf(order);
            }}
          />
        )}
      </div>
    </CommandeValidateIconDIV>
  );
};

CommandeValidateIcon.propTypes = {
  className: PropTypes.string,
  commandevalidation: PropTypes.object,
  shouldShowResponsive: PropTypes.bool,
  order: PropTypes.object,
  downloadPdf: PropTypes.func,
  pdfIsLoading: PropTypes.bool,
  idOrderDownload: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { order } = state;
  return {
    pdfIsLoading: order.pdfIsLoading || false,
    idOrderDownload: order.idOrderDownload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  downloadPdf: (order) => dispatch(orderActions.downloadOrderFormPdf(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommandeValidateIcon);
