import styled from 'styled-components';

export default (Component) => styled(Component)`


.modal-headerline {
  span {
    color: #4A4A4A;
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 19px;
    padding: 18px;
    text-align: center;
    display: block;
  }
}
.modal-header{
  color: #01366B;
  font-family: Montserrat;
  line-height: 22px;
  text-align: center;
  margin: 0;
  padding-left: 65px;
  font-size: 18px;
  padding-right: 65px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.modal-body {
  background: #FFFFFF;
  padding-bottom: 20px;
  .modal-body-content {
    max-height: 30vh;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
    }
    ::-webkit-scrollbar-thumb {
        background: #01366B;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #0FA3F0;
    }
  }
}

.checkbox-block span{
  color: #01366B;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 19px;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 10%);
  padding-left: 10px;
  cursor: pointer;
}

}
.checkbox-container {
  width: 100%;
  background-color: rgba(226,226,226,0.2);
  margin-bottom: 2px;
  padding: 11px 0px;
  cursor: pointer;
}
.checkbox__icon {
  display: inline-block;
  width: 7%;
  position: relative;
  top: 6px;
}
.checkbox-container {
  label {
    cursor: pointer;
  }
  input {
    display: none;
  }
}
.checkmark {
  position: relative;
  display: inline-block;
  top: -19px;
}
.checkbox-container input:checked ~ .checkmark {
  color: #29D24E;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-container .checkmark:after {
  width: 8px;
  height: 14px;
  border: solid #29D24E;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 3px;
}
.modal-header {
  text-align: center;
  padding:0px;
}
.modal-header{
  color: #01366B;
  font-family: Montserrat;
  line-height: 22px;
  text-align: center;
  margin: 0;
  padding-left: 65px;
  font-size: 18px;
  padding-right: 65px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom:none;
}
.modal-body__create-panier-block span {
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 15px;
  color: #01366B;
}
.modal-body__create-panier {
  width: 100%;
  padding-top: 13px;
  display: inline-block;
  position: relative;
}
.modal-body__create-panier a {
  position: absolute;
  top: 8px;
  right: 0;
  padding: 11px 19px;
  text-transform: uppercase;
  letter-spacing: 1.06px;
  font-size: 12px;
}
.create-panier__input-element {
  border: 1px solid #01366B;
  border-radius: 17px;
  height: 32px;
  padding: 5px 13px;
  width: 83%;
  color: #787878;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 15px;
}
.modal-content {
  max-width: 100px !important;
}
`;
