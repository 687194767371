import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { selectItemsFromCatalog, selectTopSales } from 'selectors/product';
import { doFiltersPass } from 'helpers/Filter';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';

import './CatalogControls.scss';

function PromotionControl(props) {
  const { counters, promotionCounter, notifCounterColor } = props;

  return (
    <>
      <PromotionIcon className="side-nav__icon" />
      <span>Promotions</span>
      {/* <div
        className="catalog-controls__tab-notif catalog-controls__tab-notif__promo"
        style={{ background: notifCounterColor }}
      >
        {counters.promotions !== undefined && (
          <span>({counters.promotions > 99 ? '99+' : counters.promotions})</span>
        )}
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  const filter = selectAggridFilter(state);
  const topSales = selectTopSales(state);

  return {
    promotionCounter: selectItemsFromCatalog(state, { aggrid: true }).filter((item) =>
      doFiltersPass(
        aggridFilterConstants.FILTER_PROMOTION,
        filter.selection,
        filter.category,
        filter.availabilityList,
        filter.storeAvailabilityList,
        filter.temperatureList,
        item,
        topSales.items
      )
    ).length,
  };
};

PromotionControl.propTypes = {
  notifCounterColor: PropTypes.string,
  promotionCounter: PropTypes.number,
};

export default connect(mapStateToProps, null)(PromotionControl);
