import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const commercialService = {
  fetchCommercials,
  fetchTotalRowNumber,
  addCommercial,
  updateCommercial,
  fetchSalesNumbers,
};

function fetchTotalRowNumber(type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/user/count?type=${type}`, requestOptions).then(handleResponse);
}

function fetchCommercials(type, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/user?type=${type}&offset=${offset}&limit=${limit}&orderByDesc=dateCreate`,
    requestOptions
  ).then(handleResponse);
}

function fetchSalesNumbers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/user/sales-numbers`, requestOptions).then(handleResponse);
}

function addCommercial(data) {
  const body = JSON.stringify({
    type: 'commercial',
    ...data,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/user`, requestOptions).then(handleResponse);
}

function updateCommercial(id, data) {
  const body = JSON.stringify({
    id,
    ...data,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/user/update`, requestOptions).then(handleResponse);
}
