import { uiConstants } from '../constants/Ui.constants';

const initState = {
  screenWidth: typeof window === 'object' ? window.innerWidth : null,
};

export function ui(state = initState, action) {
  switch (action.type) {
    case uiConstants.SCREEN_RESIZE:
      return Object.assign({}, state, {
        screenWidth: action.screenWidth,
      });
    default:
      return state;
  }
}
