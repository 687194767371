import TagManager from 'react-gtm-module';
import { productDetails, validateCart, addToCart, removeFromCart } from 'utils/analytics/ecommerce';

export const initializeTagManager = () =>
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
    dataLayerName: 'PageDataLayer',
  });

export const analytics = {
  productDetails,
  validateCart,
  addToCart,
  removeFromCart,
};
