import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { tableTypes, sectionsTypes } from 'components/Table/tableTypes';
import TableWrapper from 'components/Table';
import Messages from 'components/Messages/Messages';
import { catalogActions, categoryActions } from 'actions';
import withStyle from './dashboardWithStyle';
import { COLUMNS_PRODUCT } from './TableOptions';

const { SECTION_TYPE_CATEGORY } = sectionsTypes;
const { TABLE_TYPE_CATALOG } = tableTypes;

class Dashboard extends Component {
  componentDidMount() {
    const { fetchData, platform } = this.props;

    fetchData(platform.id);
  }

  render() {
    const { className, platform } = this.props;
    const { id: platformId } = platform;
    return platformId ? (
      <div className={className}>
        <div>
          <div className="product-page">
            <div className="messages">
              <Messages />
            </div>
            <TableWrapper
              filter={{ is_new: true, platform: Number(platformId), stock: 'available' }}
              orderBy="random"
              noPagination
              rowsByPage={4}
              className="product-list"
              columns={COLUMNS_PRODUCT}
              type={TABLE_TYPE_CATALOG}
              shouldShowHeaders
              section={{ label: 'Nouveautés', slug: 'is_new', type: SECTION_TYPE_CATEGORY }}
            />
            <TableWrapper
              filter={{ has_promotion: true, platform: Number(platformId), stock: 'available' }}
              orderBy="random"
              noPagination
              rowsByPage={4}
              className="product-list"
              columns={COLUMNS_PRODUCT}
              type={TABLE_TYPE_CATALOG}
              shouldShowHeaders
              section={{ label: 'Promotions', slug: 'has_promotion', type: SECTION_TYPE_CATEGORY }}
            />
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const {
    platform: { platforms, selectedId },
  } = state;
  return {
    platform: platforms.find((p) => Number(p.id) === Number(selectedId)) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchData: (platformId) => {
    dispatch(catalogActions.fetchTotalRowsNumber({ has_promotion: true, platform: platformId }));
    dispatch(catalogActions.fetchTotalRowsNumber({ is_new: true, platform: platformId }));
    dispatch(categoryActions.fetchCategories(platformId));
  },
});

Dashboard.propTypes = {
  className: PropTypes.string,
  platform: PropTypes.shape({
    id: PropTypes.number,
  }),
  fetchData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(Dashboard));
