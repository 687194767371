import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPath } from 'helpers';
import { userService } from 'services';
import { userConstants } from 'components/../constants';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';

const { TYPE_CUSTOMER } = userConstants;
const ClientBreadCrumb = ({ type, clientName, user }) => {
  const url = [
    {
      text: 'Accueil ',
      href:
        user.type === TYPE_CUSTOMER ? userService.getHomepagePath(user) : getPath('dashboardAdmin'),
    },
  ];
  const currentPage = 'Mes clients';
  const urlId = [
    {
      text: 'Accueil ',
      href:
        user.type === TYPE_CUSTOMER ? userService.getHomepagePath(user) : getPath('dashboardAdmin'),
    },
    {
      text: 'Mes clients',
      href: getPath('clientList').replace(':status?', ''),
    },
  ];
  const currentPageId = clientName;
  return (
    <div>
      {type === 'list' && <BreadCrumb urlTab={url} currentPage={currentPage} />}
      {type === 'single' && <BreadCrumb urlTab={urlId} currentPage={currentPageId} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    user: { information },
  } = state;
  return {
    user: information,
  };
};

ClientBreadCrumb.propTypes = {
  type: PropTypes.string,
  clientName: PropTypes.string,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(ClientBreadCrumb);
