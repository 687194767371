import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { clientActions, userActions } from 'actions';
import {
  selectClientCollectionByFilter,
  selectClientCollectionCountByFilter,
} from 'selectors/client';
import { getPath, history } from 'helpers';
import Spinner from 'components/Core/Spinner/Spinner';
import { selectCurrentPlatformId } from 'selectors/platform';
import { clientService } from 'services';
import sidebarDefs from './sidebarDefinitions';
import columnDefs, { defaultColDef } from './columnDefinitions';
import localeText from './locales';

const ClientAggrid = ({
  fetchAllClients,
  clientList,
  updateUserInfo,
  updateClientEmail,
  currentPlatformId,
}) => {
  const frameworkComponents = {
    Spinner,
  };

  const datasource = {
    getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
      gridRef.current.api.hideOverlay();
      gridRef.current.api.showLoadingOverlay();
      clientService
        .fetchClientsSSRM(currentPlatformId, filterModel, startRow, 10)
        .then((response) => {
          if (response.rowcount === 0) gridRef.current.api.showNoRowsOverlay();
          if (response.rowcount > 0) gridRef.current.api.hideOverlay();
          params.successCallback(response.rowdata, response.rowcount);
        })
        .catch((error) => {
          params.failCallback();
        });
    },
  };

  const onGridReady = (params) => {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
    window.onresize = () => {
      gridApi.sizeColumnsToFit();
    };
    params.api.setServerSideDatasource(datasource);
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onToolPanelVisibleChanged = (params) => {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
  };

  const onFilterChanged = (params) => {
    const gridApi = params.api;
    gridApi.hideOverlay();
  };

  const onSelectionChanged = (event) => {
    if (['clientMail', 'clientAction'].includes(event.column.colId)) {
      return;
    }

    if (getPath('clientDetail') && event && event.node && event.node.data && event.node.data.id) {
      history.push(getPath('clientDetail').replace(':id', event.node.data.id));
    }
  };

  const onCellValueChanged = (params) => {
    if (params.column.colId === 'clientMail') {
      updateUserInfo(
        { id: params.data.customers[0].id, email: params.newValue },
        {
          success: "L'email à bien été mis à jour",
          error: "Une erreur s'est produite, veuillez réessayer",
        }
      );
      updateClientEmail();
    }
  };

  const pagination = true;
  const paginationPageSize = 10;

  const gridRef = useRef(null);

  return (
    <div className="AggridContainer ag-theme-balham">
      <AgGridReact
        ref={gridRef}
        // sideBar={sidebarDefs}
        columnDefs={columnDefs}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={10}
        domLayout="autoHeight"
        rowModelType="serverSide"
        serverSideStoreType="serverSideStoreType"
        overlayNoRowsTemplate={
          '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No clients found</span>'
        }
        rowSelection="single"
        onGridReady={onGridReady}
        suppressRowClickSelection
        onFirstDataRendered={onFirstDataRendered}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        onFilterChanged={onFilterChanged}
        onCellClicked={onSelectionChanged}
        localeText={localeText}
        defaultColDef={defaultColDef}
        frameworkComponents={frameworkComponents}
        pivotMode={false}
        tooltipShowDelay={0}
        rowHeight={45}
        onCellValueChanged={onCellValueChanged}
        loadingOverlayComponent="Spinner"
        // groupDefaultExpanded={-1}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentPlatformId = selectCurrentPlatformId(state);
  return {
    clientList: selectClientCollectionByFilter(state, { platform: currentPlatformId }),
    currentPlatformId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllClients: (filter) => dispatch(clientActions.fetchClients(filter, 0, 10000)),
  updateUserInfo: (user, withToast) => dispatch(userActions.updateInfo(user, withToast)),
  updateClientEmail: (email) => dispatch(clientActions.updateClientEmail(email)),
});

ClientAggrid.propTypes = {
  fetchAllClients: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  updateUserInfo: PropTypes.func,
  updateClientEmail: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAggrid);
