import React from 'react';
import { Link } from 'react-router-dom';
import map from 'lodash.map';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withBreadcrumbStyle from './Breadcrumb.style';

function Breadcrumb({ className, urlTab, currentPage }) {
  return (
    <div className={className}>
      <div className="heading__path">
        <Icon className="heading__icon-home" content="\e927" />
        {map(urlTab, (tab, index) => (
          <div key={tab.href}>
            <Link
              to={{
                pathname: tab.href,
              }}
              className="heading__label "
            >
              {tab.text}
            </Link>
            {index < urlTab.length - 1 && <Icon className="heading__icon-arrow" content="\e907" />}
          </div>
        ))}
        {currentPage && (
          <>
            <Icon className="heading__icon-arrow" content="\e907" />
            <span className="heading__label">{currentPage}</span>
          </>
        )}
      </div>
    </div>
  );
}
Breadcrumb.propTypes = {
  urlTab: PropTypes.array.isRequired,
  className: PropTypes.string,
  currentPage: PropTypes.string,
};

export default withBreadcrumbStyle(Breadcrumb);
