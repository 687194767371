import React from 'react';
import PropTypes from 'prop-types';

import 'styles/assets/css/iconStyle.css';

const PromotionIcon = ({ className }) => {
  return (
    <i className={`icon-promo ${className}`} title="Promotion">
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
      <span className="path4" />
      <span className="path5" />
    </i>
  );
};

PromotionIcon.propTypes = {
  className: PropTypes.string,
};

export default PromotionIcon;
