import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { cartActions } from 'actions';
import { dateTimeToFormatedDate, getIconClassByType, getPath, history } from 'helpers';
import { selectCartCollectionByFilter } from 'selectors/cart';
import { CartListWrapper } from 'pages/Cart/CartList.style';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import { cartConstants } from '../../constants';
import withStyleCart from './Cart.style';
import CartBreadCrumb from './CartBreadcumb';

const { CART_STATUS_CUSTOM, CART_STATUS_CUSTOM_PREORDER } = cartConstants;

const CartList = ({ className, selectedPlatform, fetchItems, customCartList }) => {
  const [items, setItems] = useState([]);
  const [itemsLoadeds, setItemsLoadeds] = useState([]);

  useEffect(() => {
    fetchItems({
      or: [
        ['c.status', 'eq', CART_STATUS_CUSTOM],
        ['c.status', 'eq', CART_STATUS_CUSTOM_PREORDER],
      ],
      platform: selectedPlatform,
    });
  }, []);

  useEffect(() => {
    setItems(parseCollectionToRow(customCartList));
  }, [customCartList]);

  const getIsLoading = () => {
    if (customCartList && customCartList.isLoading === false) {
      return false;
    }
    return true;
  };

  const isLoading = getIsLoading();

  const parseCollectionToRow = (collection) => {
    const items = get(collection, 'items', []);
    const itemsLoadeds = get(collection, 'isLoading', '');
    return items.map((item) => ({
      handleRowClick: () => redirectToCartDetail(item.id),
      name: item.name,
      type: item.is_preorder,
      dateCreation: item.date_create,
      ref: item.count_items_by_type,
      total: item.total_price,
    }));
  };
  const redirectToCartDetail = (cartId) => history.push(getPath('cart').replace(':id', cartId));

  const columns = React.useMemo(() => [
    {
      id: 'name',
      Header: 'Nom du panier',
      accessor: (item) => item.name,
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: (item) => (item.type ? 'Précommande' : 'Permanent'),
    },
    {
      id: 'dateCreation',
      Header: 'Date création',
      accessor: (item) => dateTimeToFormatedDate(item.dateCreation),
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item,
      Cell: (row) =>
        Object.keys(row.value.ref).map((key) => (
          <div className="cart-list__ref-line">
            <i className={getIconClassByType(key)} /> {key} : {row.value.ref[key]} Réf.
          </div>
        )),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.total,
      Cell: (row) => <>{row.value.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} HT</>,
    },
  ]);

  const goToReccurentCartList = () => {
    history.push(getPath('recurrentCartList'));
  };

  // const NoDataComponent = (props) => {
  //   const { children } = props;
  //   return children === '' ? null : <div className="rt-noData">{children}</div>;
  // };
  const disableCommandeAuto = 0;
  return (
    <CartListWrapper>
      <div className="page-cart">
        <div className="page-cart__heading">
          <CartBreadCrumb isCurrentCart={false} cartName="Mes paniers types" isListCart />
          <div className="page-cart__section-label">
            <span className="bold">Mes paniers types</span>
          </div>
          <div className="page-cart__navigation-buttons">
            <button type="button" className="active-button">
              Panier types
            </button>
            {!disableCommandeAuto && (
              <button type="button" onClick={goToReccurentCartList}>
                Commandes automatiques
              </button>
            )}
          </div>
        </div>
        <div className="panier__type">
          {selectedPlatform && (
            <ReactTable columns={columns} data={items || []} isLoading={isLoading} />
          )}
        </div>
      </div>
    </CartListWrapper>
  );
};

CartList.propTypes = {
  className: PropTypes.string,
  selectedPlatform: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: platform.selectedId,
    customCartList: selectCartCollectionByFilter(state, {
      or: [
        ['c.status', 'eq', CART_STATUS_CUSTOM],
        ['c.status', 'eq', CART_STATUS_CUSTOM_PREORDER],
      ],
      platform: platform.selectedId,
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (filter) => dispatch(cartActions.fetchItems(filter, 1, 4000)),
  fetchTotalRowNumber: (filter) => dispatch(cartActions.fetchTotalRowNumber(filter, 0, 4000)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyleCart(CartList));
