import { creditService } from 'services';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import { creditConstants, tableConstants } from '../constants';
import { invoiceItemsActions } from './InvoiceItems';

const { DIRECTION_REPLACE } = tableConstants;

export const creditActions = {
  open,
  close,
  addCredit,
  fetchItems,
  fetchTotalRowsNumber,
  updateCreditList,
  createCredits,
};

function open(creditProps) {
  return { type: creditConstants.OPEN, creditProps };
}

function close() {
  return { type: creditConstants.CLOSE };
}

function addCredit(creditItem, creditProps) {
  return { type: creditConstants.ADD_CREDIT, creditItem, creditProps };
}

function updateCreditList(creditList) {
  return { type: creditConstants.UPDATE_CREDIT_LIST, creditList };
}

function fetchItems(filter, offset, limit, direction = DIRECTION_REPLACE) {
  return (dispatch) => {
    dispatch(request());
    creditService.fetchItems(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: creditConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return {
      type: creditConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: creditConstants.FETCH_FAILURE, error };
  }
}

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());
    creditService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: creditConstants.FETCH_COUNT_ITEMS_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: creditConstants.FETCH_COUNT_ITEMS_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: creditConstants.FETCH_COUNT_ITEMS_FAILURE, error };
  }
}

function createCredits(creditList, filter, withToast) {
  return (dispatch) => {
    dispatch(request());
    creditService.createItems(creditList).then(
      (res) => {
        dispatch(invoiceItemsActions.fetchItems(filter));
        dispatch(success(res));
        if (withToast) {
          dispatch(
            toastActions.addToast({
              type: toastConstants.TYPE_SUCCESS,
              message: "Demande d'avoir envoyée",
            })
          );
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (withToast) {
          dispatch(
            toastActions.addToast({
              type: 'error',
              message: 'Une erreur est survenue, veuillez réessayer plus tard',
            })
          );
        }
      }
    );
  };
  function request() {
    return { type: creditConstants.CREATE_ITEMS_REQUEST, creditList };
  }
  function success(res) {
    return {
      type: creditConstants.CREATE_ITEMS_SUCCESS,
      res,
    };
  }
  function failure(error) {
    return { type: creditConstants.CREATE_ITEMS_FAILURE, error };
  }
}
