import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import moment from 'moment';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { FaRegBell } from '@react-icons/all-files/fa/FaRegBell';
import { selectMessageList } from 'selectors/message';
import { messageActions } from 'actions';
import { selectPlatformList } from 'selectors/platform';
import { getPlatformFromListById } from 'helpers/Platform';
import { NotificationCenterWrapper } from './NotificationCenter.style';

const NotificationCenter = ({ fetchMessageList, messageList, platformList }) => {
  const [listOpen, setListOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    fetchMessageList();
  }, []);

  useEffect(() => {
    if (messageList && messageList.length > 0) {
      setNotificationList(messageList.flat());
    }
  }, [messageList]);

  const dropdownTitle = (
    <div className="notification-center__icon-container">
      {notificationList.filter((message) => moment(message.date_end) >= moment()).length > 0 && (
        <div className="notification-center__notif-counter">
          {notificationList.filter((message) => moment(message.date_end) >= moment()).length}
        </div>
      )}
      <FaRegBell />
    </div>
  );

  const buildNotificationList = (notificationList) => {
    if (!notificationList.length) {
      return (
        <div className="notification-center__no-message__container">
          <span>Pas de notifications</span>
        </div>
      );
    }

    return (
      <>
        {notificationList.map(
          (message) =>
            Object.keys(getPlatformFromListById(platformList, message.platform_id)).length > 0 && (
              <li key={uuid()}>
                <div className="notification-center__platform">
                  <span>{getPlatformFromListById(platformList, message.platform_id).name}</span>
                  <div className="notification-center__sticker-container">
                    {moment(message.date_end) < moment() && (
                      <div className="notification-center__expired">Expirée</div>
                    )}
                    <div className="notification-center__sticker" />
                  </div>
                </div>
                <div className="notification-center__subject">{message.subject}</div>
                <div>{message.content}</div>
              </li>
            )
        )}
      </>
    );
  };

  return (
    <NotificationCenterWrapper>
      <Dropdown
        headerTitle={dropdownTitle}
        listOpen={listOpen}
        toggleDropDown={setListOpen}
        defaultIcon={false}
      >
        <div className="dd-list">
          <div className="notification-center__last-container">
            <div className="notification-center__title">Notifications</div>
            <ul className="notification-center__list-container">
              {buildNotificationList(
                notificationList.filter((message) => moment(message.date_end) >= moment())
              )}
            </ul>
          </div>
          <div className="notification-center__archive-container">
            <div className="notification-center__title">Historique</div>
            <ul className="notification-center__list-container">
              {buildNotificationList(
                notificationList.filter((message) => moment(message.date_end) < moment())
              )}
            </ul>
          </div>
        </div>
      </Dropdown>
    </NotificationCenterWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchMessageList: () => dispatch(messageActions.fetchMessages(null, 0, 100)),
});

const mapStateToProps = (state) => ({
  messageList: selectMessageList(state, {}),
  platformList: selectPlatformList(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
