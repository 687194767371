import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions } from 'actions';
import { numberToPrice } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { catalogConstants, STATUS_FRANCO_NORMAL, STATUS_FRANCO_BLOCKED } from 'pages/../constants';
import withStyleModalFrancoAlert from './ModalFrancoAlert.style';

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const TEMPERATURES = [TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN];

const ModalFrancoAlert = ({ className, francoStatus, validateCart, closeModal }) => {
  let isBlocking = false;
  const nodes = TEMPERATURES.map((temperature) => {
    const franco = francoStatus[temperature] || {};
    switch (franco.status) {
      case STATUS_FRANCO_NORMAL:
        return (
          <div>
            <span className="bold">{`${temperature} : `}</span>
            <span>La commande n’atteint pas le montant minimum requis.</span>
          </div>
        );
      case STATUS_FRANCO_BLOCKED:
        isBlocking = true;
        return (
          <div>
            <span className="bold">{`${temperature} : `}</span>
            <span>{`La commande n’atteint pas le montant minimum requis de ${numberToPrice(
              franco.amount
            )}.`}</span>
          </div>
        );
      default:
        return null;
    }
  });

  return (
    <div className={className}>
      <div className="nodes">{nodes}</div>
      {isBlocking !== true && (
        <div className="actions">
          <div>
            <span>Souhaitez-vous néanmoins valider la commande ?</span>
          </div>
          <ButtonPrimary inverted onClick={() => closeModal()}>
            <span>Annuler</span>
          </ButtonPrimary>
          <ButtonPrimary
            onClick={() => {
              closeModal();
              validateCart();
            }}
          >
            <span>Valider</span>
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
};

ModalFrancoAlert.propTypes = {
  className: PropTypes.string,
  francoStatus: PropTypes.object,
  validateCart: PropTypes.func,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(withStyleModalFrancoAlert(ModalFrancoAlert));
