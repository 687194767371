import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions } from 'actions';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Configurator from 'components/Configurator/Configurator';
import WithConfigurationStyles from './WithConfigurationStyles';

const Configuration = ({ className, platform, openImportMoqModal }) => (
  <div className={className}>
    <div className="page-configuration">
      <div className="page-configuration__heading">
        <div className="page-configuration__section-label">
          <span className="bold">{`Configuration de la plateforme : ${
            (platform && platform.name) || ''
          }`}</span>
        </div>
        <div className="page-configuration__actions">
          <ButtonPrimary inverted onClick={() => openImportMoqModal(platform.id)}>
            <span>Importer les Moq</span>
          </ButtonPrimary>
        </div>
        {platform ? (
          <div>
            <Configurator platform={platform} />
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const {
    platform: { platforms, selectedId },
  } = state;
  return {
    platform: platforms.find((p) => Number(p.id) === Number(selectedId)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openImportMoqModal: (platformId) => {
    const modalType = 'importMoq';
    const modalProps = {
      modalHeader: 'Importez le fichier des Moqs',
      platformId,
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
});

Configuration.propTypes = {
  className: PropTypes.string,
  platform: PropTypes.object,
  openImportMoqModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithConfigurationStyles(Configuration));
