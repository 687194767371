import React from 'react';
import { FilterTagWrapper } from './FilterTagBar.style';

const FilterTag = ({ label, handleClick }) => {
  return (
    <FilterTagWrapper>
      {label}
      <span className="filter-tag__dismiss-button" onClick={handleClick}>
        x
      </span>
    </FilterTagWrapper>
  );
};

export default FilterTag;
