import get from 'lodash.get';
import isEqual from 'lodash.isequal';

/**
 * @param state Object
 * @param filter Object
 * @returns Array
 */
export const selectItemsFromCatalog = (state, filter) => {
  const collections = get(state, 'catalog.collections', []);
  const collection = collections.find((collection) => isEqual(collection.filter, filter));

  return get(collection, 'items', []);
};

export const selectIsLoadingFromCatalog = (state, filter) => {
  const collections = get(state, 'catalog.collections', []);
  const collection = collections.find((collection) => isEqual(collection.filter, filter));

  return get(collection, 'isLoading', false);
};

export const selectCurrentProduct = (state) => get(state, 'currentProduct');

export const selectTopSales = (state) => get(state, 'catalog.topSales', {});

export const selectTopSellingsItemsCount = (state) => {
  const itemCount = get(state, 'catalog.topSellingItems.count', 0);
  return itemCount > 0;
};

export const selectNewsPromotionsFavoritesCountersProducts = (state) => get(state, 'counters');
