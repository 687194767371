import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-headerline {
    height: 40px;
    padding: 10px;
    background-color: #f9f9f9;
    color: white;
    text-transform: capitalize;
  }

  .modal-body {
    padding: 2px 20px;
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    font-size: 12px;
  }

  .modal-header-text {
    height: 19px;
    width: 176px;
    color: #01366b;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.12px;
    line-height: 19px;
    font-size: 15px;
  }

  .client-commercials__center {
    padding: 0px 20px;
    &:last-child {
      padding-bottom: 20px;
    }
  }
`;
