import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history, getPath } from 'helpers';
import Sticky from 'components/Core/Sticky/Sticky';
import withModalCookiesStyle from './ModalCookies.style';

const handleClick = () => {
  history.push(getPath('welcome'));
};

const ModalCookies = ({ className, platform }) => (
  <div className={className}>
    <Sticky>
      <div className="modal-wrapper">
        <div className="modal-content">
          <div className="modal-component">
            <div className="modal-body">
              <div className="modal-body__text-block bold">
                <span>
                  {`En poursuivant votre navigation sur ce site, vous acceptez l'utilisation des cookies nous permettant 
                  de vous proposer des services personnalisés. `}
                </span>
                {platform.cookie_path && (
                  <a
                    onClick={handleClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={platform.cookie_path}
                  >
                    <span className="bold">En savoir plus</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </div>
);

const mapStateToProps = (state) => {
  const {
    platform: { platforms, selectedId },
  } = state;
  return {
    platform: platforms.find(({ id }) => Number(id) === Number(selectedId)) || {},
  };
};

ModalCookies.propTypes = {
  className: PropTypes.string,
  platform: PropTypes.object,
};

export default connect(mapStateToProps)(withModalCookiesStyle(ModalCookies));
