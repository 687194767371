import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { ButtonPrimary } from 'components/Core/Button/Button';

const PasswordForm = ({
  submitPassword,
  handleChange,
  makePasswordVisible,
  oldPassword,
  isOldPasswordVisible,
  newPassword,
  isNewPasswordVisible,
  newPasswordConfirmation,
  isNewPasswordConfirmationVisible,
  alert,
}) => (
  <div className="page-profil__block-info">
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e934" />
      <span className="entete__label">Changer mot de passe</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut password">Ancien mot de passe : </span>
        <span>{'  ' /* Add space to make it aligned */}</span>
        <input
          type={isOldPasswordVisible ? 'text' : 'password'}
          className="ligne__value-input"
          name="oldPassword"
          onChange={handleChange}
          value={oldPassword}
        />
        <Icon
          className="content-ligne__icon"
          content="\e952"
          onClick={() => makePasswordVisible('old')}
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut password">
          Nouveau mot de passe :<span className="red">*</span>
        </span>
        <input
          type={isNewPasswordVisible ? 'text' : 'password'}
          className="ligne__value-input"
          name="newPassword"
          onChange={handleChange}
          value={newPassword}
        />
        <Icon
          className="content-ligne__icon"
          content="\e952"
          onClick={() => makePasswordVisible('new')}
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut password">
          Répéter mot de passe :<span className="red">*</span>
        </span>
        <input
          type={isNewPasswordConfirmationVisible ? 'text' : 'password'}
          className="ligne__value-input"
          name="newPasswordConfirmation"
          onChange={handleChange}
          value={newPasswordConfirmation}
        />
        <Icon
          className="content-ligne__icon"
          content="\e952"
          onClick={() => makePasswordVisible('newConfirmation')}
        />
      </div>
      <div className="content__ligne-alert">
        {alert && <span className={alert.type}>{alert.message}</span>}
        {newPasswordConfirmation && newPassword && newPasswordConfirmation !== newPassword && (
          <span className="error">Les deux mots de passe saisis sont différents.</span>
        )}
      </div>
      <div className="content__ligne-button">
        <ButtonPrimary
          className="btn"
          onClick={submitPassword}
          disabled={
            !(
              oldPassword &&
              newPasswordConfirmation &&
              newPassword &&
              newPasswordConfirmation === newPassword
            )
          }
        >
          <span>Valider</span>
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const { user } = state;
  const { alert } = user;
  return {
    alert,
  };
};

PasswordForm.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  submitPassword: PropTypes.func,
  handleChange: PropTypes.func,
  makePasswordVisible: PropTypes.func,
  oldPassword: PropTypes.string,
  isOldPasswordVisible: PropTypes.bool,
  newPassword: PropTypes.string,
  isNewPasswordVisible: PropTypes.bool,
  newPasswordConfirmation: PropTypes.string,
  isNewPasswordConfirmationVisible: PropTypes.bool,
};

export default connect(mapStateToProps)(PasswordForm);
