import { invoiceItemsService } from 'services';
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { invoiceItemsConstants } from '../constants';

export const invoiceItemsActions = {
  fetchTotalRowsNumber,
  fetchItems,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    invoiceItemsService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceItemsConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: invoiceItemsConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: invoiceItemsConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchItems(filter, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    invoiceItemsService.fetchItems(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: invoiceItemsConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: invoiceItemsConstants.FETCH_SUCCESS, items, filter };
  }
  function failure(error) {
    return { type: invoiceItemsConstants.FETCH_FAILURE, error };
  }
}
