import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const articleGroupService = {
  fetchAllArticleGroups,
  fetchAllArticleGroupsFromItemId,
  createArticleGroup,
  updateArticleGroup,
};

function fetchAllArticleGroups(platform, offset, limit) {
  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/productgroup?&platform=${platform}&offset=${offset}&limit=${limit}&orderByDesc=dateCreated`,
    requestOptions
  ).then(handleResponse);
}

function fetchAllArticleGroupsFromItemId(itemId) {
  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/productgroupitem/productgroup?&item=${JSON.stringify(itemId)}`,
    requestOptions
  ).then(handleResponse);
}

function createArticleGroup(data, platformId) {
  const body = JSON.stringify({
    ...data,
    platform: { id: platformId },
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };

  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/productgroup/add`, requestOptions).then(handleResponse);
}

function updateArticleGroup(data) {
  const body = JSON.stringify({
    ...data,
  });
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/productgroup/update`, requestOptions).then(handleResponse);
}
