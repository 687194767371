import styled from 'styled-components';

export default (Component) => styled(Component)`
  /* Switch block */
  .switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 20px;
    margin: 5px 0;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #01366b;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #01366b;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .modal-content {
    width: 300px !important;
  }
  .heading__path {
    padding-bottom: 16px;
  }
  .page-client-list {
    background-color: #f5f5f5;
    padding-bottom: 20px;
    padding-top: 22px;
    .page-client-list__header {
      padding-left: 26px;
      padding-right: 26px;
      height: 30px;
    }
    .page-client-list__section-label {
      padding-bottom: 5px;
      padding-left: 40px;
      font-size: 16px;
      line-height: 19px;
      color: #01366b;
    }
    table {
      tr {
        .header_clientInfo {
          width: 24%;
        }
        .header_brand {
          width: 18%;
        }
        .header_product-pcb {
          width: 15%;
        }
        &.row {
          height: 80px;
          td {
            &:first-child {
              padding-left: 20px;
              .boldgry:nth-child(2) {
                max-width: 200px;
              }
              .boldgry:first-child {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .page-profil {
    background-color: #f5f5f5;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    table {
      padding-top: 0px !important;
      tr {
        .header_message-action {
          text-align: right;
          padding-right: 20px;
        }
        .header_clientInfo {
          width: 30%;
        }
        .header_brand {
          width: 30%;
        }
        .header_product-pcb {
          width: 30%;
        }
        &.row {
          height: 80px;
          td {
            padding-right: 20px;
            .action-block {
              i {
                margin-right: 0px;
              }
            }
            &:first-child {
              padding-left: 20px;
              .boldgry:first-child {
                font-size: 16px;
              }
              .boldgry:nth-child(2) {
                font-size: 14px;
              }
            }
            .small {
              font-weight: 500;
              font-size: 14px;
              color: #4a4a4a;
            }
          }
        }
      }
    }
  }
  .page_profil__section-label {
    padding-bottom: 5px;
    padding-left: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #01366b;
  }
  .page-profil__table-bloc {
    padding-bottom: 20px;
  }
  .page-profil__entete {
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
    left: -2px;
  }
  .entete__icon-home {
    color: #01366b;
    display: inline-block;
    margin-right: 2px;
  }
  .entete__label-profil {
    color: #01366b;
    height: 18px;
    font-weight: 600;
  }

  .page-profil__alert-block {
    margin-bottom: 20px;
  }

  .page-profil__alert-errors {
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
    .alert-bloc__label {
      display: block;
      color: #d0021b;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      &:before {
        content: '*';
      }
    }
  }

  .page-profil__section-info {
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
  }
  .page-profil__block-info {
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .block-info__entete {
    padding: 10px 18px;
    border-bottom: 1px solid rgba(1, 54, 107, 0.2);
  }
  .entete__icon {
    color: #00a6ff;
    font-size: 12px;
    background-color: #e1f4fb;
    border-radius: 100%;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
  }
  .entete__icon-user {
    color: #00a6ff;
    font-size: 17px;
    background-color: #e1f4fb;
    border-radius: 24px;
    padding: 6px;
  }
  .entete__label {
    color: #4a4a4a;
    font-weight: 600;
    -webkit-letter-spacing: 0.12px;
    -moz-letter-spacing: 0.12px;
    -ms-letter-spacing: 0.12px;
    letter-spacing: 0.12px;
    color: #4a4a4a;
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 19px;
    vertical-align: middle;
  }
  .download select {
    width: calc(50% - 10px);
  }
  .block-info__content {
    padding: 20px;
  }
  .content__ligne {
    margin-bottom: 20px;
  }
  .ligne__attribut {
    margin-right: 10px;
    color: #01366b;
  }
  .ligne__value {
    text-overflow: ellipsis;
    word-wrap: break-word;
    font-weight: 500;
    color: #787878;
  }
  .content-ligne__icon {
    font-size: 22px;
    width: 15%;
    position: relative;
    top: 7px;
  }

  .page-profil__button-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;

    .page_profil__locked-message {
      margin-right: auto;
      color: #e0a800;
      font-weight: bold;
    }

    .item-action__button {
      margin-left: auto;
    }
  }

  .page-profil__block-info-access__item {
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .page-profil__block-info-access__item-prop {
    display: inline-block;
    width: 52%;
  }

  .item__label {
    color: #4a4a4a;
    font-weight: 600;
    width: 100%;
    display: inline-block;
    padding-bottom: 10px;
  }
  .item__state {
    background-color: #35e687;
    padding: 4px 14px;
    border-radius: 4px;
    font-size: 10px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
  }
  .active {
    background-color: #35e687;
  }
  .blocked {
    background-color: #f84746;
    padding: 4px 6px;
  }
  .inactive {
    background-color: #a4a4a4;
  }
  .page-profil__block-info-access__item-action {
    display: inline-block;
    width: 48%;
    float: right;
    text-align: right;
    line-height: 16px;
    letter-spacing: 1.06px;
  }
  .item-action__button {
    padding: 11px 15px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.06px;
    line-height: 16px;
    margin: 0 5px;
  }
  .item-action__button i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 14px;
  }
  .item-action__button span {
    vertical-align: middle;
    padding-right: 8px;
    padding-left: 6px;
  }
  .page-profil__block-info {
    margin-bottom: 20px;
  }
  .total__attribut {
    color: #01366b;
    font-weight: 600;
    display: inline-block;
    width: 33%;
  }
  .download__link {
    color: #00a6ff;
    text-decoration: none;
  }
  .download__link span {
    text-decoration: underline;
    margin-right: 10px;
  }
  .icon-file {
    font-size: 16px;
  }
  .block-info__content-radio {
    label {
      span {
        color: #787878;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
  input[type='radio']:checked + span {
    color: #4a4a4a;
    font-weight: bold;
  }
  .devise {
    font-size: 10px;
  }
  .total {
    padding: 0 0 7px;

    .total__type-block:last-child .block__radio-hr {
      display: none;
    }
    .total__type-block {
      .block-option {
        display: inline-block;
      }
      .block__radio-hr {
        border-color: rgba(216, 216, 216, 0.2);
        margin-top: 20px;
        margin-bottom: 0;
      }
      .content__ligne {
        margin-bottom: 10px;
      }
      .ligne__attribut {
        margin-right: 10px;
        color: #01366b;
        width: 25%;
        display: inline-block;
        vertical-align: middle;
      }
      .block__radio {
        margin-right: 60px;
        padding-right: 0;
      }
      .total__type {
        padding: 20px 20px 0 20px;
      }
      input {
        width: calc(100% - 30%);
        padding: 11px 0;
        margin-bottom: 7px;
        font-family: Montserrat, sans-serif;
        border-bottom: 1px solid rgba(120, 120, 120, 0.6);
      }
    }
  }
  .selection {
    padding: 20px;
    display: inline-block;
    width: 100%;
    .selection__label {
      float: left;
      display: inline-block;
      width: 50%;
      span:first-child {
        color: #01366b;
        font-weight: bold;
        margin-right: 10px;
      }
      span:nth-child(2) {
        color: #4a4a4a;
        font-weight: 500;
        line-height: 18px;
      }
    }
    .selection__buton {
      float: right;
      a {
        padding: 10px 20px;
        color: #00a6ff;
        i {
          font-size: 14px;
          margin-right: 5px;
        }
        span {
          font-size: 12px;
          letter-spacing: 1.06px;
          line-height: 16px;
          text-transform: uppercase;
        }
      }
    }
  }
  .location {
    padding: 0;
    p {
      color: #4a4a4a;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      padding: 0 20px;
      margin: 22px 0;
    }
    .location-search {
      position: relative;
      padding: 0 20px;
      input {
        border: 1px solid #01366b !important;
        border-radius: 17px;
        padding: 7px 43px 7px 20px;
        width: 100%;
        color: #787878;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
      i {
        position: absolute;
        background: #01366b;
        width: 40px;
        height: 40px;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 100%;
        color: #fff;
        right: 18px;
        top: -2px;
      }
    }
    .localisation__map {
      padding-top: 20px;
    }
  }
  .planning {
    padding: 5px 20px 15px;
    select {
      width: calc(100% - 75px);
    }
    .ligne__attribut {
      display: inline-block;
      width: 65px;
    }
    .bloc__select {
      margin: 5px 0;
    }
  }
  .entete__icon.retrait {
    font-size: 22px;
  }
  .livraison {
    label {
      span {
        text-transform: none !important;
      }
    }
    .block__radio {
      display: block;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .commerciaux {
    padding: 0;
    p,
    b {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
    p {
      margin-bottom: 0;
      padding: 0 20px;
      margin-top: 17px;
    }
    b {
      padding-bottom: 10px;
      display: block;
    }
    select {
      width: calc(100% - 202px);
    }
    .keywords {
      background: rgba(216, 216, 216, 0.2);
      padding: 17px 23px;
      margin-bottom: 0;
      div {
        border-radius: 4px;
        background-color: #ffffff;
        padding: 3px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        color: #01366b;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.06px;
        line-height: 16px;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .icon {
        margin-left: 16px;
        vertical-align: middle;
        font-size: 10px;
        cursor: pointer;
      }
    }
    .commerciaux_box {
      padding: 13px 20px;
      margin-bottom: 5px;
    }
  }
  .entete__icon.tempera {
    font-size: 26px;
  }
  .temperature {
    padding: 13px 20px 0;
    .ligne__attribut {
      display: inline-block;
      width: 30%;
    }
    input {
      width: calc(100% - 40%);
      padding: 7px 0;
    }
    .add__ligne {
      color: #00a6ff;
      font-size: 14px;
      text-decoration: none;
      display: block;
      margin-top: 25px;
      padding-bottom: 17px;
    }
  }
  .client__row {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 3 - 20px);
    margin-right: 20px;

    .item-action__button {
      padding: 11px 16px;
      margin: 3px 0px;
      i {
        padding: 0 4px;
      }
    }
  }
  .download {
    padding: 10px 20px 1px;
    .ligne__attribut {
      width: 50%;
      display: inline-block;
    }
  }

  @media (max-width: 1024px) {
    .location {
      .localisation__map {
        img {
          width: 100%;
        }
      }
    }
    .total {
      .block__radio {
        margin-right: 0;
        padding-right: 30px;
      }
      .ligne__attribut {
        width: 30%;
      }
      input {
        width: calc(100% - 35%);
      }
    }
  }
  @media (max-width: 768px) {
    .client__row {
      width: calc(100% / 2 - 12px);
      &:nth-child(even) {
        margin-right: 20px;
      }
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
    .page-profil {
      table {
        width: 100%;
        display: inline-flex;
        tr {
          .header_clientInfo {
            padding-right: 20px;
            width: 49%;
          }
          .header_brand {
            width: 49%;
          }
          .header_message-action {
            text-align: unset;
          }
          &.row {
            height: auto;
            width: calc(50vw - 30px) !important;
            td {
              &.header_brand {
                padding-right: 0px;
              }
              &:first-child {
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
    .page-client-list {
      table {
        tr {
          td {
            min-height: 40px;
            .boldgry {
              padding-left: 20px;
              padding-top: 10px;
              padding-bottom: 10px;
              &:nth-child(2) {
                max-width: 100% !important;
                display: inline-block;
                padding-top: 0px;
              }
            }
          }
          .header_product-pcb {
            padding: 0px;
            width: 50%;
            display: inline-block;
            &:before {
              height: 40px;
              background-color: rgb(226, 226, 226);
              width: 100%;
              display: inline-block;
              font-weight: 700;
              padding-left: 20px;
              padding-top: 10px;
            }
            .client-status-block {
              padding: 10px 20px;
            }
          }
          .header_clientInfo,
          .header_brand,
          .header_order-number {
            width: 100%;
            display: inline-block;
            padding: 0px;
            &:before {
              height: 40px;
              background-color: rgb(226, 226, 226);
              width: 100%;
              display: inline-block;
              font-weight: 700;
              padding-left: 20px;
              padding-top: 10px;
            }
          }
          .header_action {
            padding-left: 20px;
          }
          .header_clientInfo {
            &:before {
              content: 'Client';
            }
          }
          .header_brand {
            &:before {
              content: 'Enseigne';
            }
          }
          .permenant {
            &:before {
              color: #787878;
              content: 'Permanent';
            }
          }
          .preco {
            padding-top: 2px;
            &:before {
              color: #787878;
              content: 'Preco';
            }
          }
          &.row {
            padding: 0px 0px 10px 0px;
            height: 360px;
            td:first-child {
              padding: 0px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 544px) {
    .client__row {
      width: 100%;
    }
    .item-action__button {
      margin-bottom: 20px;
    }
    .page-profil {
      table {
        tr {
          &.row {
            width: 100% !important;
          }
        }
      }
    }
  }
  @media (max-width: 320px) {
    .block__radio {
      margin-bottom: 15px;
    }
    .page-profil__block-info-access__item,
    .page-profil__block-info-access__item-action,
    .commerciaux select,
    .download select,
    .download .ligne__attribut {
      width: 100%;
    }
    .ligne__attribut {
      display: block;
    }
    .total {
      .ligne__attribut {
        width: 100%;
        padding-bottom: 10px;
      }
      input {
        width: 100%;
      }
    }

    .page-client-list {
      table {
        tr {
          .header_action {
            display: inline-block;
            width: 100%;
            padding-right: 20px;
            .button-container {
              display: inline-block;
              float: right;
              .button-container__btn {
                border-radius: 50%;
                padding: 11px 6px;
                .button-content__title {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;
