import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { DropdownSelectWrapper } from 'components/Core/DropdownSelect/DropdownSelect.style';

const DropdownSelect = ({
  itemList,
  columns,
  limit,
  isDisabled,
  inputRef,
  selectedItem,
  selectedLabel,
  setSelectedItem,
  handleFilterItems,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const parsedItems =
    itemList && itemList.length
      ? itemList.map((item) => ({
          ...item,
          handleRowClick: () => {
            setSelectedItem(item);
            setSearchInput('');
            setIsOpen(false);
          },
        }))
      : [];

  const getNextItems = (list, offset, limit) => {
    const newList = handleFilterItems(list, searchInput);

    return offset + limit > 0 ? newList.slice(0, offset + limit) : newList;
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      setSelectedItem(null);
      setSearchInput('');
      setIsOpen(false);
    }
  };

  return (
    <DropdownSelectWrapper isOpen={isOpen}>
      <div className="dropdown-select__search-container">
        <>
          <input
            type="text"
            onClick={() => {
              setIsOpen(true);
            }}
            disabled={isDisabled}
            ref={inputRef}
            value={searchInput}
            onChange={(event) => {
              setOffset(0);
              setIsOpen(true);
              setSearchInput(event.target.value);
            }}
            onBlur={() => {
              setIsOpen(false);
            }}
            onFocus={() => {
              setSelectedItem(null);
            }}
            onKeyUp={handleKeyUp}
          />
          {!isDisabled && searchInput && (
            <span
              className="dropdown-select__reset-search"
              onClick={() => {
                setSearchInput('');
                if (inputRef && inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            >
              X
            </span>
          )}
        </>
        {selectedItem && (
          <div className="dropdown-select__fake-input">
            <input type="text" value={selectedLabel} disabled />
            {!isDisabled && (
              <span
                className="dropdown-select__reset-search"
                onClick={() => {
                  setSelectedItem(null);
                  if (inputRef && inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
              >
                X
              </span>
            )}
          </div>
        )}
      </div>
      <div className="dropdown-select__panel">
        <ReactTable
          columns={columns}
          data={getNextItems(parsedItems, offset, limit)}
          next={() => {
            getNextItems(parsedItems, offset + limit, limit);
            setOffset(offset + limit);
          }}
          loadedRowCount={offset + limit}
          totalRowCount={getNextItems(parsedItems).length}
          enableArrowNavigation={isOpen}
          isLoading={isLoading}
        />
      </div>
    </DropdownSelectWrapper>
  );
};

DropdownSelect.propTypes = {
  itemList: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
};

export default DropdownSelect;
