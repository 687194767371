import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';
import 'ag-grid-enterprise';
import moment from 'moment';
import { analytics } from 'utils/analytics/tagmanager';
import { productCardActions } from 'actions/ProductCard';
import CatalogControls from 'components/CatalogControls/CatalogControls';
import Spinner from 'components/Core/Spinner/Spinner';
import ProductCard from 'components/ProductCard';
import {
  selectItemsFromCatalog,
  selectCurrentProduct,
  selectTopSales,
  selectIsLoadingFromCatalog,
} from 'selectors/product';
import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';
import { aggridConstants } from 'constants/Aggrid.constants';
import { selectCategories } from 'selectors/category';
import { selectAggridSearchValue } from 'selectors/search';
import { productActions } from 'actions/Product';
import { selectAggridFilter, selectAggridViewType } from 'selectors/aggridFilter';
import { selectMessageList } from 'selectors/message';
import { toastActions } from 'actions/Toast';
import { catalogActions, modalActions } from 'actions';
import { doFiltersPass } from 'helpers/Filter';
import { getValueOfAttribut, store } from 'helpers';
import { selectCurrentPageContext, selectCatalogCollectionCountByFilter } from 'selectors/catalog';
import { selectCurrentUser } from 'selectors/user';
import { toastConstants } from 'constants/Toast.constants';
import { messageConstants, platformConstants } from '../../constants';
import {
  availabilityCellRenderer,
  priceCellRenderer,
  packagePriceCellRenderer,
  imageCellRenderer,
} from './cellRenderers';
import AssortmentFilter from './AssortmentFilter';
import AggridTableView from './TableView/AggridTableView';
import AggridListView from './ListView/AggridListView';
import { setCookie, getCookie } from '../../helpers/cookies';

class Aggrid extends Component {
  constructor(props) {
    super(props);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.counter = 0;
    this.state = {
      rowCount: 0,
      filterType: aggridConstants.FILTER_NO_FILTER,
      savedSort: null,
    };
  }

  componentDidMount() {
    const { messageList, pushToast, fetchProductsTotalCount, platformId, user } = this.props;

    const currentMessageList = messageList
      .flat()
      .filter((item) => moment(item.date_end) >= moment());

    currentMessageList.map((item) => {
      if (item.type === messageConstants.TYPE_LOCKED) {
        if (user && user.client.locked) {
          return pushToast({
            type: 'warning',
            title: item.subject,
            message: item.content,
            duration: item.duration,
          });
        }

        return null;
      }

      return pushToast({
        type: toastConstants.TYPE_INFO,
        title: item.subject,
        message: item.content,
        duration: item.duration,
      });
    });

    const cookie = getCookie('mobileApp') === 'true' || false;
    if (isMobile && !cookie) {
      this.props.openAppStore();
      setCookie('mobileApp', true, 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(this.props.searchValue);
    }

    if (this.gridColumnApi && prevProps.categoryDisplay !== this.props.categoryDisplay) {
      this.gridColumnApi.setColumnVisible('department', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('zone', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('aisle', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('category', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('sub_category', this.props.categoryDisplay);
    }

    if (this.props.isItemsLoading === false && this.props.items.length < 1) {
      if (this.gridApi) {
        this.gridApi.hideOverlay();
        this.gridApi.showNoRowsOverlay();
      }
    }
    if (this.props.isItemsLoading === false && this.props.items.length > 0) {
      if (this.gridApi) {
        this.gridApi.hideOverlay();
      }
    }
  }

  sortByDefault = () =>
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'category',
          sort: 'asc',
        },
        {
          colId: 'name',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });

  sortByTopSales = () =>
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'top_sales_position',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });

  clearSort = () => this.gridColumnApi.applyColumnState({ defaultState: { sort: null } });

  saveSort = () => {
    const colState = this.gridColumnApi.getColumnState();
    const sortState = colState
      .filter((s) => {
        return s.sort != null;
      })
      .map((s) => {
        return {
          colId: s.colId,
          sort: s.sort,
          sortIndex: s.sortIndex,
        };
      });
    this.setState({ savedSort: sortState });
  };

  restoreFromSave = () => {
    this.gridColumnApi.applyColumnState({
      state: this.state.savedSort,
      defaultState: { sort: null },
    });
  };

  onFilterChanged = () => {
    if (this.gridApi) {
      this.setState({ rowCount: this.gridApi.getModel().rootNode.childrenAfterFilter.length });
    }
  };

  onGridReady = (params) => {
    const { currentFilters, fetchProducts, platformId } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const state = store.getState();
    const currentPlatform = selectCurrentPlatform(state);
    const { attributs } = currentPlatform;
    const shouldDisplayStock = !Number(
      getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
    );

    this.gridApi.hideOverlay();
    this.gridApi.showLoadingOverlay();

    if (!shouldDisplayStock) {
      this.gridColumnApi.setColumnVisible('availability', false);
    }

    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };
    fetchProducts(platformId);
    this.externalFilterChanged(currentFilters);
    this.sortByDefault();
    this.saveSort();
  };

  onSelectionChanged = (event) => {
    const { selectedPlatform } = this.props;
    if (aggridConstants.FIXED_COLUMNS.includes(event.column.colId)) {
      return;
    }

    event.node.setSelected(true);
    const { chooseCurrentGridProduct } = this.props;
    const currentProduct = this.gridApi.getSelectedRows();
    chooseCurrentGridProduct(currentProduct[0], selectedPlatform);
  };

  onFirstDataRendered = (params) => {
    const { fetchTopSalesItemsIdList, platformId } = this.props;
    const rowCount = this.gridApi.getModel().rootNode.childrenAfterFilter.length;
    const endDate = moment().format('YYYY-MM-DD HH:mm:00');
    const startDate = moment().subtract(3, 'months').format('YYYY-MM-DD HH:mm:00');

    params.api.sizeColumnsToFit();
    this.setState({ rowCount });
    fetchTopSalesItemsIdList(platformId, startDate, endDate, Math.ceil((rowCount * 20) / 100));

    // if (this.state.rowCount > 1) {
    //   this.gridApi.hideOverlay();
    // }
    // fin abdou
  };

  externalFilterChanged = (newValue) => {
    if (this.gridApi) {
      this.setState({ filterType: newValue }, () => {
        this.gridApi.onFilterChanged();
      });
    }
  };

  isExternalFilterPresent = () => this.state.filterType !== aggridConstants.FILTER_NO_FILTER;

  doesExternalFilterPass = (node) => {
    const { filterType } = this.state;
    const { topSales } = this.props;

    if (!filterType) {
      return true;
    }

    return doFiltersPass(
      filterType.quickList,
      filterType.selection,
      filterType.category,
      filterType.availabilityList,
      filterType.storeAvailabilityList,
      filterType.temperatureList,
      node.data,
      topSales.items
    );
  };

  navigateToNextCell = (params) => {
    const { chooseCurrentGridProduct, selectedPlatform } = this.props;

    let previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell + 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell - 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
        return suggestedNextCell;
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        return null;
    }
  };

  render() {
    const { rowCount } = this.state;
    const {
      items,
      topSales,
      selectedPlatform,
      currentProductCard,
      close,
      viewType,
      pageContext,
    } = this.props;
    let itemsWithTopSales = null;
    if (items.length && topSales.items && topSales.items.length) {
      itemsWithTopSales = items.map((item) => ({
        ...item,
        topPosition: topSales.items.includes(item.id) ? topSales.items.indexOf(item.id) + 1 : null,
      }));
    }

    const frameworkComponents = {
      availabilityCellRenderer,
      priceCellRenderer,
      packagePriceCellRenderer,
      imageCellRenderer,
      AssortmentFilter,
      Spinner,
    };
    const aggridProps = {
      rowData: itemsWithTopSales || items,
      onGridReady: this.onGridReady,
      onFirstDataRendered: this.onFirstDataRendered,
      onCellClicked: this.onSelectionChanged,
      onFilterChanged: this.onFilterChanged,
      frameworkComponents,
      doesExternalFilterPass: this.doesExternalFilterPass,
      isExternalFilterPresent: this.isExternalFilterPresent,
      handleFilter: this.externalFilterChanged,
      currentProduct: currentProductCard,
      close,
      columnApi: this.gridColumnApi,
      gridApi: this.gridApi,
      rowCount,
      navigateToNextCell: this.navigateToNextCell,
      // searchValue: selectAggridSearchValue(this.state),
      searchValue: this.props.searchValue,
      viewType,
      pageContext,
    };

    // "hack" from aurelien for display overlay no product after load overlay
    // this.counter = this.counter + 1;
    // if (rowCount === 0 && !this.props.isItemsLoading) {
    //   if (this.gridApi) {
    //     if (this.gridApi.getDisplayedRowCount() === 0 && this.counter < 10) {
    //       // this.gridApi.showNoRowsOverlay();
    //     }
    //   }
    // }

    return (
      <>
        {viewType && currentProductCard && (
          <div className="ProductCardContainer">
            <ProductCard
              product={currentProductCard}
              close={close}
              handleFilter={this.externalFilterChanged}
            />
          </div>
        )}
        {items && (
          <CatalogControls
            selectedPlatform={selectedPlatform}
            handleFilter={this.externalFilterChanged}
            rowData={items}
            viewType={viewType}
          />
        )}
        {viewType && <AggridTableView {...aggridProps} source="AggridTableView" />}
        {!viewType && <AggridListView {...aggridProps} />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const platformId = selectCurrentPlatformId(state);

  return {
    platformId,
    selectedPlatform: selectCurrentPlatform(state),
    items: selectItemsFromCatalog(state, { aggrid: true }),
    isItemsLoading: selectIsLoadingFromCatalog(state, { aggrid: true }),
    categories: selectCategories(state),
    searchValue: selectAggridSearchValue(state),
    currentProduct: selectCurrentProduct(state),
    currentFilters: selectAggridFilter(state),
    viewType: selectAggridViewType(state),
    messageList: selectMessageList(state, { platform: selectCurrentPlatformId(state) }),
    topSales: selectTopSales(state),
    user: selectCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: (platformId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 50000)),
  chooseCurrentGridProduct: (product, platform) => {
    analytics.productDetails(product);
    dispatch(productCardActions.chooseCurrentProduct(product, platform));
  },
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
  setToastList: (toastList) => dispatch(toastActions.setToastList(toastList)),
  fetchTopSalesItemsIdList: (platformId, startDate, endDate, limit) =>
    dispatch(catalogActions.fetchTopSalesItemIdList(platformId, startDate, endDate, limit)),
  openAppStore: () => {
    const modalType = 'appStore';
    const modalProps = {};
    dispatch(modalActions.open(modalType, modalProps));
  },
});

Aggrid.propTypes = {
  platformId: PropTypes.number,
  selectedPlatform: PropTypes.object,
  fetchProducts: PropTypes.func,
  chooseCurrentGridProduct: PropTypes.func,
  items: PropTypes.array,
  searchValue: PropTypes.string,
  categoryDisplay: PropTypes.bool,
  openAppStore: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Aggrid);
