import styled from 'styled-components';

export default (Component) => styled(Component)`
  width: 100%;
  max-width: 1280px;
  margin-top: 40px;

  .sk-fading-circle {
    float: right;
    margin: 40px;
  }

  .configurator-form:after {
    content: '';
    display: block;
    clear: both;
  }

  .configurator-text {
    margin-bottom: 10px;
    .configurator-text-label {
      display: inline-block;
      text-align: right;
      width: 37%;
      margin-right: 3%;
    }
    .configurator-text-input {
      width: 60%;
    }
  }

  .configurator-switch {
    margin-bottom: 10px;

    .configurator-switch-span {
      display: inline-block;
      text-align: right;
      width: 37%;
      margin-right: 3%;
    }

    /* Switch block */
    .switch {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 34px;
      height: 20px;
      margin: 5px 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    /**/
    .slider:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #01366b;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #01366b;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  .configurator-submit {
    padding: 10px 40px;
    float: right;
    margin-top: 40px;
  }
`;
