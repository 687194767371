import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body-head {
    margin-bottom: 20px;
    display: flex;
    width: 80%;
    margin-bottom: 50px;
    .container-name {
      color: #4a4a4a;
      font-size: 13px;
      font-weight: bold;
      min-width: 220px;
    }
    .gauge {
      width: 100%;
      height: 100%;
      border: 1px solid #4a4a4a;
      display: flex;
      align-items: center;
      .gauge-title {
        span {
          width: 65px;
          color: #4a4a4a;
          font-size: 12px;
          padding: 3px 10px;
          font-weight: 500;
        }
      }
      .gauge-bar {
        width: 100%;
        z-index: 10;
        position: relative;
        display: flex;
        .gauge-element {
          position: relative;
          width: 100%;
          .progress-bar {
            position: relative;
            width: 100%;
            border-bottom: 1px solid #f7f4f4;
            &::before {
              content: '';
              background-color: #f69696;
              width: 25%;
              height: 100%;
              position: absolute;
              left: 0px;
              top: 0;
              opacity: 0.3;
              z-index: -1;
            }
            &::after {
              content: '';
              background-color: #e5f9e7;
              width: 25%;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0px;
              opacity: 0.7;
              z-index: -1;
            }
          }
        }
        .gauge-bar-volume {
          padding: 3px 0;
        }
        .gauge-bar-weight {
          padding: 3px 0;
          border-left: 1px solid #4a4a4a;
        }
      }
    }
  }
  .message {
    text-align: center;
    font-size: 14px;
    margin-bottom: 50px;
    span {
      background: #f69696;
      color: #01366b;
      padding: 10px;
      font-weight: bold;
      border-radius: 4.5px;
    }
  }
  .references-head {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .references-list {
    padding-top: 10px;
    border-top: 1px solid #01366b;
    overflow: scroll;
    height: 400px;
    .item-product {
      border-radius: 4.5px;
      border: 1px solid #cecece;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-container {
        width: 30px;
        max-width: 30px;
        margin-right: 15px;
      }
      .photo {
        margin-right: 10px;
        max-width: 50px;
        flex: 1;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
      .product-title {
        font-size: 12px;
        font-weight: bold;
        flex: 4;
        max-width: 230px;
        padding-right: 20px;
      }
      .ref-ean-dlv {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        flex: 3;
        max-width: 140px;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
      .tarif {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        flex: 2;
        max-width: 120px;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
      .nb-colis {
        flex: 2;
        text-align: center;
        display: flex;
        justify-content: center;
        max-width: 160px;
        span {
          font-size: 12px;
          font-weight: 600;
          background: #cecece;
          padding: 8px;
          border-radius: 4.5px;
        }
      }
      .ref-ht {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        max-width: 100%;
        min-width: 120px;
        justify-content: flex-end;
      }
    }
  }
`;
