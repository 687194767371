import { orderService } from 'services';
import { getPath, history, saveFile } from 'helpers';
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { orderConstants, cartConstants, tableConstants } from '../constants';
import { orderItemsActions } from '.';

const { DIRECTION_REPLACE } = tableConstants;

export const orderActions = {
  fetchTotalRowsNumber,
  fetchItems,
  fetchItem,
  renewOrder,
  downloadOrderFormPdf,
  downloadPreorderXls,
  rateOrder,
};

function rateOrder(orderId, rating, comment) {
  return (dispatch) => {
    dispatch(request());

    orderService.rateOrder(orderId, rating, comment).then(
      (rating) => {
        dispatch(success(rating));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.RATING_REQUEST };
  }
  function success(rating) {
    return {
      type: orderConstants.RATING_SUCCESS,
      rating,
      orderId,
    };
  }
  function failure(error) {
    return { type: orderConstants.RATING_FAILURE, error };
  }
}

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    orderService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: orderConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: orderConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchItems(filter, offset, limit, direction = DIRECTION_REPLACE) {
  return (dispatch) => {
    dispatch(request());
    orderService.fetchItems(filter, offset, limit).then(
      (items) => {
        updateOrderItems(items);
        dispatch(success(items));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.FETCH_REQUEST, filter };
  }
  function success(items) {
    return {
      type: orderConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: orderConstants.FETCH_FAILURE, error };
  }
  function updateOrderItems(items) {
    items.forEach((order) => {
      const { order_items: orderItems, id } = order;
      if (orderItems) {
        orderItemsActions.updateItemsRowsNumber(orderItems.length, id);
        orderItemsActions.updateItems(orderItems, id);
      }
    });
  }
}

function fetchItem(filter, offset, limit, direction = DIRECTION_REPLACE) {
  return (dispatch) => {
    dispatch(request());
    orderService.fetchItem(filter, offset, limit).then(
      (items) => {
        updateOrderItem(items);
        dispatch(success(items));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderConstants.FETCH_REQUEST, filter };
  }
  function success(items) {
    return {
      type: orderConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: orderConstants.FETCH_FAILURE, error };
  }
  function updateOrderItem(items) {
    items.forEach((order) => {
      const { order_items: orderItems, id } = order;
      if (orderItems) {
        orderItemsActions.updateItemsRowsNumber(orderItems.length, id);
        orderItemsActions.updateItems(orderItems, id);
      }
    });
  }
}

function renewOrder(id, redirect = false) {
  return (dispatch) => {
    dispatch(request());

    orderService.renewOrder(id).then(
      (cart) => {
        dispatch(success());
        dispatch({ type: cartConstants.CART_UPDATE, cart });
        if (redirect === true) {
          const redirectTo = getPath('cart').replace(':id', cart.id).replace('/:temperature?', '');
          history.push(redirectTo);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: orderConstants.RENEW_REQUEST, filter: { id } };
  }
  function success() {
    return { type: orderConstants.RENEW_SUCCESS, filter: { id } };
  }
  function failure(error) {
    return { type: orderConstants.RENEW_FAILURE, error };
  }
}

function downloadOrderFormPdf(order) {
  return (dispatch) => {
    dispatch(request());
    orderService.downloadOrderFormPdf(order.id).then(
      (orderFormBlob) => {
        dispatch(success());
        const fileName = `BDC-${order.number}.pdf`;
        saveFile(fileName, orderFormBlob);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: orderConstants.DOWNLOAD_ORDER_FORM_REQUEST, order };
  }
  function success() {
    return { type: orderConstants.DOWNLOAD_ORDER_FORM_SUCCESS };
  }
  function failure(error) {
    return { type: orderConstants.DOWNLOAD_ORDER_FORM_FAILURE, error };
  }
}

function downloadPreorderXls(since, to) {
  return (dispatch) => {
    dispatch(request());
    orderService.downloadPreorderXls(since, to).then(
      (orderFormBlob) => {
        dispatch(success());
        const fileName = `Precommande-since-${since}-to-${to}.xls`;
        saveFile(fileName, orderFormBlob);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: orderConstants.DOWNLOAD_PREORDER_FORM_REQUEST, since, to };
  }
  function success() {
    return { type: orderConstants.DOWNLOAD_PREORDER_FORM_SUCCESS };
  }
  function failure(error) {
    return { type: orderConstants.DOWNLOAD_PREORDER_FORM_FAILURE, error };
  }
}
