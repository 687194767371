import { containerConstants } from 'constants/ContainerV2.constants';
import sum from 'lodash.sum';
import { v4 as uuidv4 } from 'uuid';

class Bin {
  name = '';

  width = 0;

  height = 0;

  length = 0;

  maxWeight = 0;

  maxVolume = 0;

  created = false;

  items = [];

  type = containerConstants.TYPE_CONTAINER_DRY;

  mode = containerConstants.MODE_VRAC;

  constructor(name, w, h, d, mw, mv, t, m, created = false) {
    this.id = uuidv4();
    this.name = name;
    this.width = w;
    this.height = h;
    this.length = d;
    this.maxWeight = mw;
    this.maxVolume = mv;
    this.type = t;
    this.mode = m;
    this.created = created || this.created;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  addItem(item) {
    this.items.push(item);
  }

  getItems() {
    return this.items;
  }

  getVolume() {
    return this.width * this.height * this.length;
  }

  setItems(items) {
    this.items = items;
  }

  getMaxWeight() {
    return this.maxWeight;
  }

  getMaxVolume() {
    // return this.width * this.height * this.length;
    return this.maxVolume;
  }

  getItemsVolume() {
    return sum(
      this.items.map((item) => {
        if (item.packVolume) {
          return item.packVolume * item.qty;
        }
        return item.width * item.height * item.length * item.qty;
      })
    );
  }

  getItemsWeight() {
    return sum(this.items.map((item) => item.weight * item.qty));
  }

  hasHeightCapacityFor(item) {
    return this.getItemsWeight() + item.weight <= this.maxWeight;
  }

  hasVolumeCapacityFor(item) {
    if (item.packVolume) {
      return this.getItemsVolume() + item.packVolume <= this.getMaxVolume();
    }
    return this.getItemsVolume() + item.width * item.height * item.length <= this.getMaxVolume();
  }

  getType() {
    return this.type;
  }

  getMode() {
    return this.mode;
  }
  // weighItem(item) {
  //   const maxWeight = this.getMaxWeight();
  //   return ! maxWeight || item.getWeight() + this.getPackedWeight() <= maxWeight;
  // }

  // getPackedWeight() {
  //   return this.items.reduce( ( weight, item ) => weight + item.getWeight(), 0 );
  // }
}

export default Bin;
