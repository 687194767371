import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/Alert/Alert';
import { ButtonPrimary } from 'components/Core/Button/Button';
import logo from 'styles/assets/images/Color@2x.svg';

function PasswordChangeForm(props) {
  const {
    className,
    error,
    newPassword,
    newPasswordConfirmation,
    isFirstLogin,
    handleChange,
    handleSubmit,
    onKeyPress,
  } = props;
  return (
    <div className={className}>
      <div className="password-recovery__background">
        <div className="password-all">
          <div className="password-confirm__container">
            <img className="password-confirm__logo" src={logo} alt="logo" />
            <p className="password-confirm__text-logo regular">Commande.com</p>
            {isFirstLogin && (
              <h2 className="password-confirm__text-label regular">
                Veuillez choisir votre mot de passe
              </h2>
            )}
            <input
              className="password-confirm__label  regular"
              type="password"
              name="newPassword"
              placeholder={isFirstLogin ? 'Mot de passe' : 'Nouveau mot de passe'}
              value={newPassword}
              onChange={handleChange}
              onKeyPress={onKeyPress}
            />

            <input
              className="password-confirm__label repeat regular"
              type="password"
              name="newPasswordConfirmation"
              placeholder="Confirmer le mot de passe"
              value={newPasswordConfirmation}
              onChange={handleChange}
              onKeyPress={onKeyPress}
            />
            <ButtonPrimary
              className="password-confirm__btn-connect"
              onClick={handleSubmit}
              disabled={
                !(newPassword && newPasswordConfirmation && newPassword === newPasswordConfirmation)
              }
            >
              <span>Enregistrer</span>
            </ButtonPrimary>
          </div>
          <div className="login__errors">
            {error && <Alert text={error} />}
            {newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation && (
              <Alert text="Les deux mots passe saisis sont différents" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PasswordChangeForm.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  newPassword: PropTypes.string.isRequired,
  newPasswordConfirmation: PropTypes.string.isRequired,
  isFirstLogin: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

export default PasswordChangeForm;
