import ModalContacts from 'components/Modal/ModalContacts';
import ModalAddProduct from 'components/Modal/ModalAddProduct';
import ModalRedirectClient from 'components/Modal/ModalRedirectClient';
import ModalDelete from 'components/Modal/ModalDelete';
import ModalAddPlanning from 'components/Modal/ModalAddPlanning';
import ModalNewMessage from 'components/Modal/ModalNewMessage';
import ModalSaveConfirmation from 'components/Modal/ModalSaveConfirmation';
import ModalBloqueClient from 'components/Modal/ModalBloqueClient';
import ModalNewDayOff from 'components/Modal/ModalNewDayOff';
import ModalUseCustomCart from 'components/Modal/ModalUseCustomCart';
import ModalFrancoAlert from 'components/Modal/ModalFrancoAlert';
import ModalAddTerms from 'components/Modal/ModalAddTerms';
import ModalAddSelection from 'components/Modal/ModalAddSelection';
import ModalAddCommercial from 'components/Modal/ModalAddCommercial';
import ModalUpdateCommercial from 'components/Modal/ModalUpdateCommercial';
import ModalNewPreorderParameters from 'components/Modal/ModalNewPreorderParameters';
import ModalErrorCart from 'components/Modal/ModalErrorCart';
import ModalImportMoq from 'components/Modal/ModalImportMoq';
import ModalAlertMoq from 'components/Modal/ModalAlertMoq';
import ModalValidateCart from 'components/Modal/ModalValidateCart';
import ModalNewContainers from 'components/Modal/ModalNewContainers';
import ModalContainersOptimal from 'components/Modal/ModalContainersOptimal';
import ModalContainerDetail from 'components/Modal/ModalContainerDetail';
import ModalContainersNotFull from 'components/Modal/ModalContainersNotFull';
import ModalValidateCredit from 'components/Modal/ModalValidateCredit';
import ModalEngagementStock from 'components/Modal/ModalEngagementStock';
import ModalOutOfStock from 'components/Modal/ModalOutOfStock';
import ModalExtractExcelPreorder from 'components/Modal/ModalExtractExcelPreorder';
import ModalAddCommercialOrder from 'components/Modal/ModalAddCommercialOrder';
import ModalProductCard from 'components/Modal/ModalProductCard';
import ModalAddCredit from 'components/Modal/ModalAddCredit';
import ModalAppStore from 'components/Modal/ModalAppStore';
import {
  ModalAddGroup,
  ModalConfirm,
  ModalImportPdfOrder,
  ModalOcrOrderProductSelect,
  ModalSaveRecurrentCart,
  ModalSubstitut,
  ModalUpdateRecurrentCart,
} from 'components/Modal';

export const modalConstants = {
  OPEN: 'MODAL_OPEN',
  CLOSE: 'MODAL_CLOSE',
};

export const MODAL_TYPES = {
  contacts: ModalContacts,
  cart: ModalAddProduct,
  confirmation: ModalSaveConfirmation,
  redirect: ModalRedirectClient,
  delete: ModalDelete,
  planning: ModalAddPlanning,
  messagerie: ModalNewMessage,
  bloque: ModalBloqueClient,
  dayOff: ModalNewDayOff,
  useCustomCart: ModalUseCustomCart,
  francoAlert: ModalFrancoAlert,
  addTerms: ModalAddTerms,
  selection: ModalAddSelection,
  addCommercial: ModalAddCommercial,
  updateCommercial: ModalUpdateCommercial,
  newPreorderParameters: ModalNewPreorderParameters,
  importMoq: ModalImportMoq,
  errorCart: ModalErrorCart,
  alertMoq: ModalAlertMoq,
  validateCart: ModalValidateCart,
  newContainers: ModalNewContainers,
  containersOptimal: ModalContainersOptimal,
  containerDetail: ModalContainerDetail,
  containersNotFull: ModalContainersNotFull,
  validateCredit: ModalValidateCredit,
  engagementStock: ModalEngagementStock,
  outOfStock: ModalOutOfStock,
  extractExcelPreorder: ModalExtractExcelPreorder,
  addCommercialOrder: ModalAddCommercialOrder,
  modalProductCard: ModalProductCard,
  addCredit: ModalAddCredit,
  importPdfOrder: ModalImportPdfOrder,
  ocrOrderProductSelect: ModalOcrOrderProductSelect,
  addGroup: ModalAddGroup,
  substitut: ModalSubstitut,
  saveRecurrentCart: ModalSaveRecurrentCart,
  updateRecurrentCart: ModalUpdateRecurrentCart,
  confirm: ModalConfirm,
  appStore: ModalAppStore,
};
