import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const deliveryPlanningService = {
  fetchItems,
  updateItem,
  deleteItem,
};

function fetchItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);

  return fetch(
    `${config.apiUrl}/api/delivery_planning?filter=${urlFilter}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function updateItem(item) {
  const body = JSON.stringify(item);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: item.id ? 'PUT' : 'POST',
    headers,
    body,
    mode: 'cors',
  };
  const call = item.id ? 'update' : 'add';

  return fetch(`${config.apiUrl}/api/delivery_planning/${call}`, requestOptions).then(
    handleResponse
  );
}

function deleteItem(item) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/delivery_planning/delete/${item.id}`, requestOptions).then(
    handleResponse
  );
}
