import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import withStyle from './TableSkeleton.style';
import { tableTypes } from '../tableTypes';

const {
  TABLE_TYPE_CATALOG,
  TABLE_TYPE_SEARCH,
  TABLE_TYPE_CART_ITEMS,
  TABLE_TYPE_ORDER_ITEMS,
} = tableTypes;

const TableSkeleton = withStyle(({ columnsCount, type, className }) => (
  <tr key={uuid()} className={className}>
    {[
      TABLE_TYPE_CATALOG,
      TABLE_TYPE_SEARCH,
      TABLE_TYPE_CART_ITEMS,
      TABLE_TYPE_ORDER_ITEMS,
    ].includes(type) ? (
      <>
        <td className="skeletom-image">
          <div className="skeleton-item" />
        </td>
        <td className="skeletom-wording wording-multipl-ligne">
          <div className="skeleton-item skeletom-wording-ligne" />
          <div className="skeleton-item skeletom-libelle-ligne" />
          <div className="skeleton-item skeletom-libelle-ligne" />
        </td>
      </>
    ) : (
      Array(2)
        .fill(null)
        .map(() => (
          <td key={uuid()} className="skeletom-wording">
            <div className="skeleton-item" />
          </td>
        ))
    )}
    {Array(columnsCount - 2)
      .fill(null)
      .map(() => (
        <td key={uuid()} className="skeletom-wording">
          <div className="skeleton-item" />
        </td>
      ))}
  </tr>
));

const TablesSkeleton = ({ columnsCount, type, linesCount = 3 }) =>
  Array(linesCount)
    .fill(null)
    .map(() => <TableSkeleton key={uuid()} columnsCount={columnsCount} type={type} />);

TablesSkeleton.propTypes = {
  columnsCount: PropTypes.number,
  type: PropTypes.string,
};

TableSkeleton.propTypes = {
  className: PropTypes.string,
  columnsCount: PropTypes.number,
  type: PropTypes.string,
  linesCount: PropTypes.number,
};

export default TablesSkeleton;
