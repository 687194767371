import { articleGroupItemService } from 'services';
import { articleGroupItemConstants } from '../constants';

export const articleGroupItemActions = {
  fetchArticleGroupItems,
  addArticleGroupItems,
  deleteArticleGroupItems,
};

function fetchArticleGroupItems(filter, limit = 1000) {
  return (dispatch) => {
    dispatch(request());
    articleGroupItemService.fetchAllArticleGroupItems(filter, 0, limit).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: articleGroupItemConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return {
      type: articleGroupItemConstants.GET_ALL_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: articleGroupItemConstants.GET_ALL_FAILURE };
  }
}

function addArticleGroupItems(data, successCallback = null) {
  return (dispatch) => {
    dispatch(request());

    return articleGroupItemService.addArticleGroupItems(data).then(
      (articleGroupItems) => {
        dispatch(success(articleGroupItems));
        if (successCallback) {
          successCallback();
        }
        return articleGroupItems;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );

    function request() {
      return {
        type: articleGroupItemConstants.ADD_REQUEST,
      };
    }

    function success(articleGroupItems) {
      return {
        type: articleGroupItemConstants.ADD_SUCCESS,
        articleGroupItems,
      };
    }

    function failure(error) {
      return {
        type: articleGroupItemConstants.ADD_FAILURE,
        error,
      };
    }
  };
}

function deleteArticleGroupItems(articleGroupId, articleGroupItemProductIdList, successCallback) {
  return (dispatch) => {
    dispatch(request());

    return articleGroupItemService
      .deleteArticleGroupItems(articleGroupId, articleGroupItemProductIdList)
      .then(
        (articleGroupItems) => {
          dispatch(success(articleGroupId, articleGroupItemProductIdList));
          if (successCallback) {
            successCallback();
          }

          return articleGroupItems;
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );

    function request() {
      return {
        type: articleGroupItemConstants.DELETE_REQUEST,
      };
    }

    function success(articleGroupId, articleGroupItemProductIdList) {
      return {
        type: articleGroupItemConstants.DELETE_SUCCESS,
        articleGroupId,
        articleGroupItemProductIdList,
      };
    }

    function failure(error) {
      return {
        type: articleGroupItemConstants.DELETE_FAILURE,
        error,
      };
    }
  };
}
