import { maintenanceService } from 'services/Maintenance';
import { maintenanceConstants } from 'constants/Maintenance.constants';

export const maintenanceActions = {
  checkHealth,
  success,
};

function checkHealth() {
  return (dispatch) => {
    dispatch(request());

    maintenanceService.fetchHealth().then(
      (data) => {
        dispatch(success({ data }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: maintenanceConstants.CHECK_HEALTH_REQUEST };
  }
  function success({ data }) {
    return {
      type: maintenanceConstants.CHECK_HEALTH_SUCCESS,
      data: data === 'ok' ? { is_alive: true } : data,
    };
  }
  function failure(error) {
    return { type: maintenanceConstants.CHECK_HEALTH_FAILURE, error };
  }
}

function success(data) {
  return {
    type: maintenanceConstants.CHECK_HEALTH_SUCCESS,
    data,
  };
}
