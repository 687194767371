import { containerConstants } from 'constants/ContainerV2.constants';

const containersTypeDry = [
  {
    name: '20 Dry',
    width: 244,
    height: 259,
    length: 605,
    volumePallet: 27 * 1e6,
    volumeVrac: 31 * 1e6,
    weight: 26000,
    type: containerConstants.TYPE_CONTAINER_DRY,
    mode: containerConstants.MODE_VRAC,
  },
  {
    name: '40 Dry',
    width: 244,
    height: 259,
    length: 1219,
    volumePallet: 60 * 1e6,
    volumeVrac: 69 * 1e6,
    weight: 26000,
    type: containerConstants.TYPE_CONTAINER_DRY,
    mode: containerConstants.MODE_VRAC,
  },
];

export default containersTypeDry;
