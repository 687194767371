import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { clientStatusConstants } from 'components/../constants/Client.constants';
import Icon from 'components/Icon/Icon';

const { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_BLOCKED } = clientStatusConstants;

const ClientStatusTD = styled.td`
  .bloque {
    line-height: 16px;
    color: white;
    background-color: #d0021b;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding: 4px;
  }
  .inactif {
    line-height: 16px;
    display: inline-block;
    color: white;
    background-color: #a4a4a4;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding: 4px;
  }
  .actif {
    line-height: 16px;
    color: white;
    background-color: #35e687;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding: 4px;
  }
  .icon-style-globale {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    i {
      color: #01366b;
      font-size: 17px;
    }
  }
  .icon_style {
    margin-right: 15px;
    font-size: 16px;
  }
  i[content*='\e941']:before {
    font-size: 22px;
  }
`;

const ClientStatusDIV = styled.div`
  // Override the width specified in the cell class name
  float: ${(props) => props.float || 'unset'};
  .bloque {
    line-height: 16px;
    color: white;
    background-color: #d0021b;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    font-size: 10px;
    vertical-align: middle;
    padding: 4px;
  }

  .inactif {
    line-height: 16px;
    display: inline-block;
    color: white;
    background-color: #a4a4a4;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding: 4px;
  }
  .actif {
    line-height: 16px;
    color: white;
    background-color: #35e687;
    width: 54px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding: 4px;
  }
  .icon-style-globale {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    i {
      color: #01366b;
      font-size: 16px;
    }
  }
  .icon_style {
    margin-right: 15px;
    font-size: 16px;
  }
`;

const ClientStatus = ({
  clientStatus,
  isPreorder,
  selectedPlatform,
  className,
  shouldShowResponsive,
  ...rest
}) => {
  const iconContent = isPreorder ? '\\e941' : '\\e94c';
  // const platformClientStatus = (clientStatus || []).find(
  //   (status) =>
  //     (status.platform_id || status.platform.id) &&
  //     Number(status.platform_id || status.platform.id) === Number(selectedPlatform)
  // );
  const { status_preorder: statusPreorder, status_catalog: statusCatalog } = clientStatus || {};
  const effectiveStatus = isPreorder ? statusPreorder : statusCatalog;

  let spanClass;
  let content;

  switch (effectiveStatus) {
    case STATUS_ACTIVE:
      spanClass = 'actif';
      content = 'Actif';
      break;
    case STATUS_BLOCKED:
      spanClass = 'bloque';
      content = 'Bloqué';
      break;
    case STATUS_INACTIVE:
      spanClass = 'inactif';
      content = 'Inactif';
      break;
    default:
      spanClass = 'inactif';
      content = 'Inactif';
      break;
  }

  const node = (
    <div className="client-status-block">
      <div className="icon-style-globale">
        <Icon content={iconContent} />
      </div>
      <div className={spanClass}>{content}</div>
    </div>
  );

  return !shouldShowResponsive ? (
    <ClientStatusTD className={className} {...rest}>
      {node}
    </ClientStatusTD>
  ) : (
    <ClientStatusDIV className={className} {...rest}>
      {node}
    </ClientStatusDIV>
  );
};

ClientStatus.propTypes = {
  selectedPlatform: PropTypes.number,
  className: PropTypes.string,
  clientStatus: PropTypes.array,
  isPreorder: PropTypes.bool,
  shouldShowResponsive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: platform.selectedId,
  };
};

export default connect(mapStateToProps)(ClientStatus);
