import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body__text-block {
    color: #01366b;
    text-align: center;
    padding: 0px 20px 20px 20px;
    font-size: 18px;
  }
  .modal-body__text-block:first-letter {
    text-transform: uppercase;
  }
  .modal-body__button-block {
    text-align: center;
  }
  .modal-body__button-block a {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 11px;
    margin-right: 20px;
  }
  .icon-check-ok:before {
    color: #29d24e;
    font-size: 20px;
  }

  .modal-confirm__container-icon {
    text-align: center;
  }

  .modal-confirm__icon {
    color: #29d24e;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
  }
`;
