import { productCardConstants } from 'constants/ProductCard.constants';
import { catalogService, productService } from 'services';

import { checkTechnicalSheetAvailability } from 'helpers/Product';
import { checkPlatformAllowsTechnicalSheets } from 'helpers/Platform';
import { element } from 'prop-types';

export const productCardActions = {
  chooseCurrentProduct,
  safoItemAnalytic,
};

function safoItemAnalytic(filters) {
  productService.safoItemAnalytic(filters);
}

function chooseCurrentProduct(product, platform) {
  return (dispatch) => {
    dispatch(request());

    // if currentProduct is null close productCard component
    if (product === null) {
      dispatch(success(null));
      return;
    }

    if (checkPlatformAllowsTechnicalSheets(platform)) {
      catalogService.fetchItems({ id: product.id, platform: platform.id }).then(
        (data) => {
          const file = data.items[0].document;
          checkTechnicalSheetAvailability(file).then((response) => {
            const document = response ? file : null;
            dispatch(success({ ...product, document }));
          });
        },
        (error) => {
          dispatch(failure(error));
        }
      );

      return;
    }

    productService.getLastOrdersByItemID(product.id, platform.id).then(
      (response) => {
        dispatch(success({ ...product, last_orders: response }));
      },
      (error) => {
        dispatch(failure(error));
      }
    );

    dispatch(success({ ...product, document: null }));
  };

  function request() {
    return { type: productCardConstants.CHOOSE_CURRENT_PRODUCT_REQUEST };
  }

  function success(product) {
    return {
      type: productCardConstants.CHOOSE_CURRENT_PRODUCT_SUCCESS,
      product,
    };
  }

  function failure(error) {
    return { type: productCardConstants.CHOOSE_CURRENT_PRODUCT_FAILURE, error };
  }
}
