import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { modalActions } from 'actions';
import Icon from 'components/Icon/Icon';

const MessageActionsTD = styled.td`
  color: #d0021b;
  font-size: 16px;
  .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }
  i {
    cursor: pointer;
  }
`;

const MessageActionsDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  color: #d0021b;
  display: block;
  text-align: center .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }

  i {
    cursor: pointer;
  }
`;

const MessageActions = ({
  className,
  shouldShowResponsive,
  message,
  openDeleteModal,
  openMessageModal,
}) =>
  !shouldShowResponsive ? (
    <MessageActionsTD className={className}>
      <div className="action-block">
        <Icon content="\e937" onClick={() => openMessageModal(message)} />
        <Icon content="\e916" onClick={() => openDeleteModal(message.id)} />
      </div>
    </MessageActionsTD>
  ) : (
    <MessageActionsDIV className={className}>
      <div className="action-block">
        <Icon content="\e937" onClick={() => openMessageModal(message)} />
        <Icon content="\e916" onClick={() => openDeleteModal(message.id)} />
      </div>
    </MessageActionsDIV>
  );

const mapDispatchToProps = (dispatch) => ({
  openDeleteModal: (messageId) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: messageId,
        deleteType: 'MESSAGE',
      })
    ),
  openMessageModal: (message) =>
    dispatch(
      modalActions.open('messagerie', {
        modalHeader: 'Envoyer le message à tous les clients actifs',
        message,
        width: '600',
      })
    ),
});

MessageActions.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  message: PropTypes.object,
  openDeleteModal: PropTypes.func,
  openMessageModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(MessageActions);
