import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectClientCollectionByFilter = (state, filter) => {
  const collections = get(state, 'client.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectClientCollectionCountByFilter = (state, filter) => {
  const collections = get(state, 'client.collectionsCount', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectClientDetail = (state) => get(state, 'client.clientDetail', null);
