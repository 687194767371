import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const maintenanceService = {
  fetchHealth,
};

function fetchHealth() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/health`, requestOptions).then(handleResponse);
}
