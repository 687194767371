import React, { useState, useEffect } from 'react';
import Moment from 'moment';

const Countdown = ({ limitDate }) => {
  const calculateTimeLeft = () => {
    const now = Moment();
    return Moment.duration(now.diff(limitDate));
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <span>
      {(Math.trunc(timeLeft.asHours() * -1) && (
        <span>{Math.trunc(timeLeft.asHours() * -1)}h </span>
      )) ||
        ''}
      {(timeLeft.minutes() * -1 && <span>{timeLeft.minutes() * -1}min </span>) || ''}
      <span>{timeLeft.seconds() * -1}s</span>
    </span>
  );
};

export default Countdown;
