import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'components/Core/Tooltip/Tooltip';
import { getProductType } from 'helpers/Product';
import { getIconClassByType } from 'helpers';
import './TypeIcon.css';

const TypeIcon = ({ type }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  return (
    <div className="type-icon__container">
      <Tooltip
        isVisible={isTooltipVisible}
        tooltipPosition="top"
        labelValue={getProductType(type)}
        parentElement={parentElement}
      />
      <i
        className={`${getIconClassByType(type)}`}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      />
    </div>
  );
};

export default TypeIcon;
