// Importing modalConstants from the absolute path (`constants`) bugs.
import { modalConstants } from '../constants/Modal.constants';

const initialState = {
  isOpen: false,
  modalType: null,
  modalProps: {},
};

export function modal(state = initialState, action) {
  switch (action.type) {
    case modalConstants.OPEN:
      return {
        isOpen: true,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case modalConstants.CLOSE:
      return initialState;
    default:
      return state;
  }
}
