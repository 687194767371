import get from 'lodash.get';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import { categoryConstants } from 'constants/Category.constants';

const {
  FILTER_FAVORITE,
  FILTER_PROMOTION,
  FILTER_NEW,
  FILTER_TOP_SALES,
  FILTER_DRY,
  FILTER_FRESH,
  FILTER_FROZEN,
  FILTER_AVAILABLE,
  FILTER_UNAVAILABLE,
  FILTER_PREORDER,
  FILTER_STORE_AVAILABLE,
  FILTER_STORE_UNAVAILABLE,
  FILTER_STORE_NO_ASSORTMENT,
} = aggridFilterConstants;

export const getEffectiveQuickFilterName = (filterValue) => {
  switch (filterValue) {
    case aggridFilterConstants.FILTER_FAVORITE:
      return 'favoris';
    case aggridFilterConstants.FILTER_NEW:
      return 'nouveautés';
    case aggridFilterConstants.FILTER_PROMOTION:
      return 'promotions';
    case aggridFilterConstants.FILTER_TOP_SALES:
      return 'top ventes';
    default:
      return null;
  }
};

export const getEffectiveAvailabilityFilterName = (filterValue) => {
  switch (filterValue) {
    case aggridFilterConstants.FILTER_AVAILABLE:
      return 'fournisseur: en stock';
    case aggridFilterConstants.FILTER_UNAVAILABLE:
      return 'fournisseur: indisponible';
    case aggridFilterConstants.FILTER_PREORDER:
      return 'fournisseur: en précommande';
    case aggridFilterConstants.FILTER_STORE_AVAILABLE:
      return 'magasin: en stock';
    case aggridFilterConstants.FILTER_STORE_UNAVAILABLE:
      return 'magasin: indisponible';
    case aggridFilterConstants.FILTER_STORE_NO_ASSORTMENT:
      return 'magasin: hors assortiment';
    default:
      return null;
  }
};

export function matchFilter(searchFilter, initialFilter) {
  if (!searchFilter) {
    return false;
  }
  return JSON.stringify(searchFilter) === JSON.stringify(initialFilter);
}

export function parseFilter(filterObject = {}) {
  return Object.keys(filterObject)
    .filter((key) => filterObject[key] && filterObject[key].length > 0)
    .reduce((effectiveFilter, key) => ({ ...effectiveFilter, [key]: filterObject[key] }), {});
}

export function parseCatalogFilter(filterObject = {}, slugs = {}, searchedGroup = {}) {
  const { type, groupItem, others, manufacturer } = filterObject;
  const { slugGroup, slug } = slugs;

  const finalGroupItem = groupItem ? [...groupItem] : [];
  const parsedOthers = {};
  const parsedSelection = [];

  if (slugGroup === 'is_new') {
    parsedOthers.is_new = true;
  } else if (slugGroup === 'has_promotion') {
    parsedOthers.has_promotion = true;
  } else if (slugGroup === 'is_favorite') {
    parsedOthers.is_favorite = true;
  } else if (
    slugGroup &&
    searchedGroup &&
    searchedGroup.status !== categoryConstants.STATUS_SELECTION
  ) {
    finalGroupItem.push(slugGroup);
  }
  if (searchedGroup && searchedGroup.status === categoryConstants.STATUS_SELECTION) {
    parsedSelection.push(searchedGroup.slug);
  }

  (others || []).forEach((other) => {
    switch (
      other // see list of keywords in src/constants/Filter.constants.js
    ) {
      case 'is_new':
        parsedOthers.is_new = true;
        break;
      case 'has_promotion':
        parsedOthers.has_promotion = true;
        break;
      case 'is_favorite':
        parsedOthers.is_favorite = true;
        break;
      case 'is_ordered':
        parsedOthers.is_ordered = true;
        break;
      case 'is_dangerous':
        parsedOthers.is_dangerous = true;
        break;
      default:
        parsedSelection.push(other);
        break;
    }
  });
  const finalFilter = {
    'categories.slug': slug,
    ...parseFilter({ groupItem: finalGroupItem }),
    ...parseFilter({ selection: parsedSelection }),
    ...parseFilter({ type }),
    ...parseFilter({ manufacturer }),
    ...parsedOthers,
  };
  const { 'categories.slug': categoriesSlug, ...finalFilterWithoutCategories } = finalFilter;
  return slug ? finalFilter : finalFilterWithoutCategories;
}

export const doFiltersPass = (
  quickFilterTypeList,
  selectionFilterType,
  categoryFilterType,
  availabilityFilterTypeList,
  storeAvailabilityFilterTypeList,
  temperatureFilterTypeList,
  item,
  topSalesItemIdList
) =>
  doesQuickFilterPass(quickFilterTypeList, item, topSalesItemIdList) &&
  doesSelectionFilterPass(selectionFilterType, item) &&
  doesCategoryFilterPass(categoryFilterType, item) &&
  doesAvailabilityFilterPass(availabilityFilterTypeList, item) &&
  doesStoreAvailabilityFilterPass(storeAvailabilityFilterTypeList, item) &&
  doesTemperatureFilterPass(temperatureFilterTypeList, item);

export const doesQuickFilterPass = (quickFilterTypeList, item, topSalesItemIdList) => {
  if (quickFilterTypeList.length <= 0) {
    return true;
  }

  let doesQuickFilterPass = true;

  if (quickFilterTypeList.includes(FILTER_FAVORITE)) {
    doesQuickFilterPass = doesQuickFilterPass && item.is_favorite;
  }
  if (quickFilterTypeList.includes(FILTER_NEW)) {
    doesQuickFilterPass = doesQuickFilterPass && item.is_new;
  }
  if (quickFilterTypeList.includes(FILTER_PROMOTION)) {
    doesQuickFilterPass = doesQuickFilterPass && item.has_promotion;
  }
  if (
    quickFilterTypeList.includes(FILTER_TOP_SALES) &&
    topSalesItemIdList &&
    topSalesItemIdList.length
  ) {
    doesQuickFilterPass = doesQuickFilterPass && topSalesItemIdList.includes(item.id);
  }

  return doesQuickFilterPass;
};

export const doesSelectionFilterPass = (selectionFilterType, item) => {
  if (selectionFilterType === null) {
    return true;
  }

  return get(item, 'group_item', []).includes(String(selectionFilterType));
};

export const doesCategoryFilterPass = (categoryFilterType, item) =>
  !categoryFilterType ||
  categoryFilterType === item.zone_name ||
  categoryFilterType === item.aisle_name ||
  categoryFilterType === item.category_name ||
  categoryFilterType === item.subcategory_name;

export const doesStoreAvailabilityFilterPass = (storeAvailabilityFilterTypeList, item) => {
  if (storeAvailabilityFilterTypeList.length <= 0) {
    return true;
  }

  let doesStoreAvailabilityFilterPass = false;

  if (storeAvailabilityFilterTypeList.includes(FILTER_STORE_AVAILABLE)) {
    doesStoreAvailabilityFilterPass =
      doesStoreAvailabilityFilterPass || item.client_stock_quantity > 0;
  }
  if (storeAvailabilityFilterTypeList.includes(FILTER_STORE_UNAVAILABLE)) {
    doesStoreAvailabilityFilterPass =
      doesStoreAvailabilityFilterPass ||
      (item.client_stock_quantity <= 0 && item.client_stock_quantity !== null);
  }
  if (storeAvailabilityFilterTypeList.includes(FILTER_STORE_NO_ASSORTMENT)) {
    doesStoreAvailabilityFilterPass =
      doesStoreAvailabilityFilterPass || item.client_stock_quantity === null;
  }

  return doesStoreAvailabilityFilterPass;
};

export const doesAvailabilityFilterPass = (availabilityFilterTypeList, item) => {
  if (availabilityFilterTypeList.length <= 0) {
    return true;
  }

  let doesAvailabilityFilterPass = false;

  if (availabilityFilterTypeList.includes(FILTER_AVAILABLE)) {
    doesAvailabilityFilterPass =
      doesAvailabilityFilterPass ||
      (item.stock && item.stock.value_cu >= item.pcb && !item.is_preorder);
  }
  if (availabilityFilterTypeList.includes(FILTER_UNAVAILABLE)) {
    doesAvailabilityFilterPass =
      doesAvailabilityFilterPass ||
      (item.stock && item.stock.value_cu < item.pcb && !item.is_preorder);
  }
  if (availabilityFilterTypeList.includes(FILTER_PREORDER)) {
    doesAvailabilityFilterPass = doesAvailabilityFilterPass || item.is_preorder;
  }

  return doesAvailabilityFilterPass;
};

export const doesTemperatureFilterPass = (temperatureFilterTypeList, item) => {
  if (temperatureFilterTypeList.length <= 0) {
    return true;
  }
  let doesTemperatureFilterPass = false;

  if (temperatureFilterTypeList.includes(FILTER_DRY)) {
    doesTemperatureFilterPass = doesTemperatureFilterPass || item.type === FILTER_DRY;
  }
  if (temperatureFilterTypeList.includes(FILTER_FRESH)) {
    doesTemperatureFilterPass = doesTemperatureFilterPass || item.type === FILTER_FRESH;
  }
  if (temperatureFilterTypeList.includes(FILTER_FROZEN)) {
    doesTemperatureFilterPass = doesTemperatureFilterPass || item.type === FILTER_FROZEN;
  }

  return doesTemperatureFilterPass;
};
