import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import { catalogConstants } from '../constants';

export const selectFirstTemp = (newOrder) => {
  if (newOrder && newOrder[catalogConstants.TEMPERATURE_DRY]) {
    return newOrder[catalogConstants.TEMPERATURE_DRY];
  }

  if (newOrder && newOrder[catalogConstants.TEMPERATURE_FRESH]) {
    return newOrder[catalogConstants.TEMPERATURE_FRESH];
  }

  if (newOrder && newOrder[catalogConstants.TEMPERATURE_FROZEN]) {
    return newOrder[catalogConstants.TEMPERATURE_FROZEN];
  }

  return null;
};

export const selectValidatedCart = (state) => get(state, 'cart.validated');

export const selectValidatedCartOrder = (state) => {
  const {
    cart: {
      validated: { newOrder },
    },
  } = state;

  return selectFirstTemp(newOrder);
};

export const selectValidatedCartOrderId = (state) =>
  get(selectValidatedCartOrder(state), 'id', null);

export const selectCurrentCartId = (state) => get(state, 'cart.current.id');
export const selectCurrentPreorderCartId = (state) => get(state, 'cart.currentPreorder.id');
export const selectCartError = (state) => get(state, 'cart.error');

export const selectCartCollectionByFilter = (state, filter) => {
  const collections = get(state, 'cart.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectCopiedCart = (state) => get(state, 'cart.copiedCart');
