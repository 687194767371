/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  parseItems,
  getPath,
  getFullPath,
  history,
  getPageFromUrl,
  setPageOfUrl,
  getElementsNameByType,
  objectsAreEqual,
} from 'helpers';
import { tableConstants } from 'pages/../constants';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Spinner from 'components/Core/Spinner/Spinner';
import CreditForm from 'components/Credit/CreditForm';
import CreditWording from './Cells/Credit/CreditWording';
import Cell from './Cells';
import { tableTypes } from './tableTypes';
import { mapStateToProps, mapDispatchToProps } from './mapToProps';
import withStyle from './Table.style';
import TableSkeleton from './Skeleton/TableSkeleton';

const {
  TABLE_TYPE_CATALOG,
  TABLE_TYPE_SEARCH,
  TABLE_TYPE_SEARCH_CLIENT,
  TABLE_TYPE_CART,
  TABLE_TYPE_CART_ITEMS,
  TABLE_TYPE_ORDER,
  TABLE_TYPE_CLIENT,
  TABLE_TYPE_ORDER_ITEMS,
  TABLE_TYPE_TERMS,
  TABLE_TYPE_COMMERCIAL,
  TABLE_TYPE_ASSORTMENT,
  TABLE_TYPE_CREDIT_ITEMS,
  TABLE_TYPE_INVOICE_ITEMS,
} = tableTypes;

const { DIRECTION_REPLACE, DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const getInitState = (rowsByPage) => {
  const requestedPage = Number(getPageFromUrl()) || 1;
  const offset = (requestedPage - 1) * rowsByPage;
  return {
    offsetTop: offset,
    offsetBottom: offset,
    hasInitialized: false,
    hasRefreshed: false,
  };
};

class Table extends React.Component {
  constructor(props) {
    super(props);
    const { rowsByPage, isInfinite } = props;
    this.state = getInitState(rowsByPage);
    // Add event listener on url change for pagination based tables
    if (!isInfinite) {
      history.listen(() => {
        const { offsetBottom } = this.state;
        const urlPage = Number(getPageFromUrl()) || 1;
        const urlOffset = (urlPage - 1) * rowsByPage;
        if (Number(offsetBottom) !== String(urlOffset)) {
          this.setState({
            offsetBottom: urlOffset,
          });
        }
      });
    }
  }

  componentDidMount() {
    const { fetchTotalRowsNumber, noRequests } = this.props;
    if (!noRequests) {
      fetchTotalRowsNumber(this.props);
    }
    this._isMounted = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { changeObject, filter, collectionCount } = this.props;
    const { changeObject: nextChangeObject, filter: nextFilter } = nextProps;
    const { hasRefreshed: nextHasRefreshed } = nextState;
    const { hasRefreshed } = this.state;

    if (nextHasRefreshed === true && hasRefreshed === false) {
      /* if completed a refresh, please render */
      return true;
    }

    if (!objectsAreEqual(filter, nextFilter)) {
      return true;
    }
    if (!objectsAreEqual(changeObject, nextChangeObject)) {
      return true;
    }
    if (collectionCount && collectionCount.totalRowsNumber === 0) {
      return true;
    }
    if (this.shouldRequest(nextState, nextProps, this.state)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchItems,
      fetchTotalRowsNumber,
      filter,
      isInfinite,
      rowsByPage,
      changeObject,
    } = this.props;
    const { checkData, isLoading, totalRowsNumber } = changeObject;

    const { filter: prevFilter, changeObject: prevChangeObject } = prevProps;
    const { checkData: prevCheckData, isLoading: prevIsLoading } = prevChangeObject;

    const { offsetTop, offsetBottom, hasInitialized, hasRefreshed } = this.state;

    if (totalRowsNumber < offsetTop) {
      setPageOfUrl(1);
      fetchItems(this.props, 0);
      this.setState({
        offsetTop: 0,
        offsetBottom: 0,
      });
    }

    const { offsetTop: prevOffsetTop, offsetBottom: prevOffsetBottom } = prevState;

    if (!objectsAreEqual(filter, prevFilter)) {
      fetchTotalRowsNumber(this.props);
      this.setState(getInitState(rowsByPage));
      return;
    }

    if (
      (!objectsAreEqual(checkData, prevCheckData) ||
        (isLoading === false && prevIsLoading === true)) &&
      hasRefreshed === false
    ) {
      // Is check data has changed, if yes render again
      this.setState({ hasRefreshed: true });
    }

    if (!this.shouldRequest(this.state, this.props, prevState)) {
      return;
    }
    if (isInfinite) {
      const goingTop = offsetTop < prevOffsetTop && offsetBottom === prevOffsetBottom;
      fetchItems(
        this.props,
        goingTop ? offsetTop : offsetBottom,
        !hasInitialized ? DIRECTION_REPLACE : goingTop ? DIRECTION_BEFORE : DIRECTION_AFTER,
        !hasInitialized // this one is used only for having the manufacturers (search/catalog)
      );
      this.setState({
        hasInitialized: true,
      });
    } else {
      fetchItems(this.props, offsetBottom, DIRECTION_REPLACE);
      this.setState({
        hasInitialized: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCells = () => {
    const { columns, dataLinks } = this.props;
    const collection = this.getCollection();
    if (collection) {
      if (collection.items && collection.items.length) {
        return parseItems(collection.items, columns, dataLinks);
      }
    }
    return [];
  };

  getCollection = (effectiveProps = null) => {
    const props = effectiveProps === null ? this.props : effectiveProps;
    // make a check on the initialisation status to refresh old datas
    const { hasRefreshed } = this.state;
    const { collection, noRequests } = props;

    if (collection && (hasRefreshed || noRequests)) {
      return collection;
    }
    return {};
  };

  getCountCollection = (effectiveProps = null) => {
    const props = effectiveProps === null ? this.props : effectiveProps;
    const { collectionCount } = props;
    if (collectionCount) {
      return collectionCount;
    }
    return {};
  };

  getIsLoading() {
    const { searchValue } = this.props;
    const { isLoading: isCountLoading } = this.getCountCollection();
    const { isLoading: isCollectionLoading, items } = this.getCollection();

    // when we are in the search page and we have 0 item
    // we are blocking the skeleton loader
    if (searchValue && items && items.length === 0) {
      return false;
    }

    if (
      isCountLoading ||
      isCountLoading === undefined ||
      isCollectionLoading ||
      isCollectionLoading === undefined
    ) {
      return true;
    }
    return false;
  }

  changeOffset = (increase = true) => {
    const { rowsByPage } = this.props;
    const { offsetTop, offsetBottom } = this.state;
    const { totalRowsNumber } = this.getCountCollection();
    const { items, isLoading } = this.getCollection();
    if (
      items &&
      isLoading !== true &&
      totalRowsNumber &&
      offsetTop >= 0 &&
      offsetBottom <= totalRowsNumber
    ) {
      this.setState(
        (prevState) => {
          if (increase) {
            // modify offsetBottom
            return {
              offsetBottom: Number(Number(prevState.offsetBottom) + Number(rowsByPage)),
            };
          }
          // modify offsetTop
          return {
            offsetTop: Number(Math.max(Number(prevState.offsetTop) - Number(rowsByPage), 0)),
          };
        },
        () => {
          const { offsetBottom: newOffsetBottom, offsetTop: newOffsetTop } = this.state;
          const page = increase
            ? 1 + Math.floor(Number(newOffsetBottom) / Number(rowsByPage))
            : 1 + Math.floor(Number(newOffsetTop) / Number(rowsByPage));
          setPageOfUrl(page || 1);
        }
      );
    }
  };

  selectRow = (row) => {
    const { handleItemClick, shouldRedirect } = this.props;
    if (handleItemClick) {
      this.props.handleItemClick(row);
    }

    if (shouldRedirect === false) {
      return false;
    }

    const { type, openUpdateCommercialModal, data } = this.props;
    let routeName;
    switch (type) {
      case TABLE_TYPE_ORDER:
        routeName = 'orderdetail';
        break;
      case TABLE_TYPE_ORDER_ITEMS:
        // routeName = 'product';
        break;
      case TABLE_TYPE_INVOICE_ITEMS:
        // history.push(getPath('product').replace(':id', row.data.product.id));
        break;
      case TABLE_TYPE_CART:
        routeName = 'cart';
        break;
      case TABLE_TYPE_CART_ITEMS:
        // routeName = 'product';
        break;
      case TABLE_TYPE_CATALOG:
        // routeName = 'product';
        break;
      case TABLE_TYPE_SEARCH:
        // routeName = 'product';
        break;
      case TABLE_TYPE_CLIENT:
        routeName = 'clientDetail';
        break;
      case TABLE_TYPE_SEARCH_CLIENT:
        routeName = 'clientDetail';
        break;
      case TABLE_TYPE_COMMERCIAL:
        openUpdateCommercialModal(row.data.product);
        return false;
      case TABLE_TYPE_TERMS:
        if (row.data.product.id) {
          window.open(getFullPath(row.data.product.full_filename), '_blank');
        }
        return false;
      case TABLE_TYPE_CREDIT_ITEMS:
        history.push(getPath('creditdetail').replace(':id', row.data.product.id));
        return false;
      case TABLE_TYPE_ASSORTMENT:
        history.push(
          getPath('assortment')
            .replace(':clientId', data.client.id)
            .replace(':ext_code', data.client.ext_code)
            .replace(':brandId', data.brandId)
            .replace(':assortmentId?', row.data.product.id)
        );
        return false;
      default:
        return false;
    }
    if (routeName) {
      // replace temperature is used for carts
      const url = getPath(routeName)
        .replace(':id', row.data.productId)
        .replace('/:temperature?', '');
      history.push(url);
    }
    return false;
  };

  groupRows = () => {
    const { groupBy } = this.props;
    const { comparisonDataKey, cellIndex } = groupBy || {};
    const cells = this.getCells();
    if (cells) {
      if (comparisonDataKey) {
        cells.sort((a, b) => {
          if (cellIndex) {
            return a.cells[cellIndex][comparisonDataKey] > b.cells[cellIndex][comparisonDataKey]
              ? 1
              : -1;
          }
          return a.data[comparisonDataKey] > b.data[comparisonDataKey] ? 1 : -1;
        });
      }
    }
  };

  generateHeaders = () => {
    const { columns } = this.props;
    const columnNodes = columns.map((column) => {
      const className = column.className ? `header ${column.className}` : 'header';
      return (
        <th key={`header-${column.type}:${column.name}`} className={className}>
          {column.name}
        </th>
      );
    });

    return (
      <thead>
        <tr>{columnNodes}</tr>
      </thead>
    );
  };

  generateRowNode = (row) => {
    const { shouldShowResponsive, columns, filter, type: tableType } = this.props;
    const node = row.cells.map((cell, index) => {
      const { type, className } = columns[index];
      // We use the header classname to specify the width of each cell as the header's width.
      // We keep the correct widths even if the headers are hidden.
      return (
        <Cell
          key={`column:${index}--${type}`}
          filter={filter}
          className={className}
          type={type}
          {...cell}
          shouldShowResponsive={shouldShowResponsive}
          {...row.data}
          onClick={() => this.selectRow(row)}
        />
      );
    });

    if (shouldShowResponsive) {
      return <td>{node}</td>;
    }
    return node;
  };

  generateGroupHeader = (row, groupBy, lastGroupValue) => {
    const { comparisonDataKey, cellIndex } = groupBy || {};
    const { columns } = this.props;
    if (
      comparisonDataKey &&
      ((row.data && row.data[comparisonDataKey] !== lastGroupValue) ||
        (cellIndex &&
          row.cells[cellIndex] &&
          row.cells[cellIndex][comparisonDataKey] !== lastGroupValue))
    ) {
      const groupValue =
        !row.data ||
        row.data[comparisonDataKey] === undefined ||
        (row.cells[cellIndex] && row.cells[cellIndex][comparisonDataKey] === undefined)
          ? 'NON DÉFINIE'
          : row.data[comparisonDataKey] || row.cells[cellIndex][comparisonDataKey];

      const groupHeader = (
        <tr
          key={`groupHeader:${groupValue}${Math.floor(Math.random() * 10000)}`}
          className="group-row"
        >
          <th colSpan={columns.length} className="span" scope="colgroup">
            {groupValue}
          </th>
        </tr>
      );
      return [groupHeader, groupValue];
    }

    return [null, null];
  };

  generateRows = () => {
    const { groupBy, columns, credit, type, creditShow, filter, rowsByPage } = this.props;
    const { invoice: invoiceId } = filter;
    const isOpen = credit && credit.isOpen;
    const creditProps = credit && creditProps;
    const isLoading = this.getIsLoading();
    const cells = this.getCells();
    let lastGroupValue = null;
    const rowNodes = [];
    if (cells) {
      cells.forEach((item) => {
        const [groupHeader, groupValue] = this.generateGroupHeader(item, groupBy, lastGroupValue);
        if (groupHeader && groupValue) {
          lastGroupValue = groupValue;
          rowNodes.push(groupHeader);
        }
        const rowNode = (
          <React.Fragment key={`rf:${item.data.productId}`}>
            <tr
              key={`row:${item.data.productId}`}
              className={`fade-in row ${!item.data.product.id ? 'unclickable' : ''}
            ${type === TABLE_TYPE_CREDIT_ITEMS ? 'credit_item' : ''}`}
            >
              {this.generateRowNode(item)}
            </tr>
            {creditShow && (
              <tr key={`row:${item.data.productId}-credit-data`}>
                <td colSpan={columns.length}>
                  <CreditWording credit={item.data.product} />
                </td>
              </tr>
            )}
          </React.Fragment>
        );
        rowNodes.push(rowNode);
      });
      cells.forEach((item, i) => {
        if (isOpen && creditProps === item.data.product.id) {
          rowNodes.splice(
            window.innerWidth > 530 && window.innerWidth <= 768
              ? !(i % 2)
                ? i + 2
                : i + 1
              : i + 1,
            0,
            <CreditForm
              key={`row:${item.data.productId}-credit`}
              creditValidate={false}
              index={i}
              invoiceId={invoiceId}
              idProduct={item.data.productId}
              product={item.data.product}
              orderItemId={creditProps}
              showWording={item.data.product.credits && item.data.product.credits.length}
              creditWorddingValues={item.data.product.credits}
            />
          );
        }
      });
      const length = (rowNodes && rowNodes.length) || 0;
      const skeletonLinesCount = rowsByPage > 10 ? 10 : rowsByPage;

      if (isLoading && length === 0) {
        rowNodes.push(
          <TableSkeleton
            key={uuid()}
            linesCount={skeletonLinesCount}
            columnsCount={columns.length}
            type={type}
          />
        );
      }
      if (length === 0 && !isLoading) {
        return (
          <tbody>
            <tr>
              <td colSpan={columns.length} className="table__message fade-in">
                <span>Aucun résultat trouvé</span>
              </td>
            </tr>
          </tbody>
        );
      }
      return <tbody key={uuid(invoiceId)}>{rowNodes}</tbody>;
    }
    return <tbody />;
  };

  shouldRequest(nextState, nextProps, prevState) {
    const { isInfinite, noRequests } = nextProps;

    const { offsetTop, offsetBottom, hasInitialized } = nextState;

    const { offsetTop: prevOffsetTop, offsetBottom: prevOffsetBottom } = prevState;

    const collectionCount = this.getCountCollection();
    const { totalRowsNumber } = collectionCount;

    if (!noRequests && this._isMounted === true) {
      if (isInfinite) {
        if (
          (totalRowsNumber || totalRowsNumber === 0) &&
          offsetTop >= 0 &&
          offsetBottom <= totalRowsNumber
        ) {
          if (!hasInitialized || offsetTop < prevOffsetTop || offsetBottom > prevOffsetBottom) {
            return true;
          }
        }
      } else if (
        (totalRowsNumber || totalRowsNumber === 0) &&
        offsetBottom >= 0 &&
        offsetBottom <= totalRowsNumber
      ) {
        if (!hasInitialized || offsetBottom !== prevOffsetBottom) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const { className, shouldShowHeaders, rowsByPage, isInfinite, type } = this.props;
    const { offsetTop, offsetBottom } = this.state;
    const { totalRowsNumber } = this.getCountCollection();
    const columnNodes = shouldShowHeaders ? this.generateHeaders() : null;
    const rowNodes = this.generateRows();
    const cells = this.getCells();
    const isLoading = this.getIsLoading();
    return (
      <div className={className}>
        {!!(isInfinite && totalRowsNumber && offsetTop >= 0) && (
          <div className="button-add-elements">
            {offsetTop > 0 && isLoading && (
              <div>
                <Spinner />
              </div>
            )}
            {offsetTop > 0 && (
              <div className="previous">
                <ButtonPrimary disabled={isLoading} onClick={() => this.changeOffset(false)}>
                  <span>Précédents</span>
                </ButtonPrimary>
              </div>
            )}
          </div>
        )}
        <div className="table-wrapper">
          <table className="table">
            {columnNodes && columnNodes}
            {cells && rowNodes && rowNodes}
          </table>
        </div>

        {!!(isInfinite && totalRowsNumber) && (
          <div className="information-percentage fade-in">
            <span>{`${Math.min(offsetBottom + rowsByPage, totalRowsNumber)} ${getElementsNameByType(
              type
            )} sur ${totalRowsNumber}`}</span>
            <div className="percentage">
              <div
                style={{
                  width: `${
                    (100 * Math.min(offsetBottom + rowsByPage, totalRowsNumber)) / totalRowsNumber
                  }%`,
                }}
              />
            </div>
          </div>
        )}
        {!!(isInfinite && totalRowsNumber && offsetBottom <= totalRowsNumber) && (
          <div className="button-add-elements">
            {isLoading && (
              <div>
                <Spinner />
              </div>
            )}
            {offsetBottom + rowsByPage < totalRowsNumber && (
              <div className="next">
                <ButtonPrimary disabled={isLoading} onClick={() => this.changeOffset()}>
                  <span>Suivants</span>
                </ButtonPrimary>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Table.propTypes = {
  filter: PropTypes.object,
  columns: PropTypes.array,
  groupBy: PropTypes.object,
  className: PropTypes.string,
  shouldShowHeaders: PropTypes.bool,
  noPagination: PropTypes.bool,
  rowsByPage: PropTypes.number,
  platform: PropTypes.object,
  collection: PropTypes.object,
  collectionCount: PropTypes.object,
  credit: PropTypes.object,
  fetchItems: PropTypes.func,
  fetchTotalRowsNumber: PropTypes.func,
  match: PropTypes.object,
  type: PropTypes.string,
  autoRequest: PropTypes.bool,
  showManufacturers: PropTypes.bool,
  showClients: PropTypes.bool,
  searchValue: PropTypes.string,
  dataLinks: PropTypes.object,
  data: PropTypes.object,
  isInfinite: PropTypes.bool,
  openUpdateCommercialModal: PropTypes.func,
  noRequests: PropTypes.bool,
  shouldShowResponsive: PropTypes.bool,
  creditShow: PropTypes.bool,
  activeCollection: PropTypes.object,
  changeObject: PropTypes.object,
  handleItemClick: PropTypes.func,
  shouldRedirect: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(Table));
