import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const ProductCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 !important;
  padding: 20px;
  float: none !important;
  background: #fff;
  border-radius: 10px;
`;

export const AgListWrapper = styled.div`
  padding-right: 5px;
  .ag-list-mobile__quantity-wrapper {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bold {
      white-space: nowrap;
      margin-left: 10px;
    }
    * {
      margin-bottom: 0 !important;
    }
  }

  @media ${screen.laptopL} {
    width: 100%;
    .ag-theme-custom-list {
      height: calc(100vh - 235px); /* for browsers that do not support Custom Properties */
      height: calc(
        (var(--vh, 1vh) * 100) - 258px
      ); /* use custom vh property to prevent vertical shift on mobile browsers */
    }

    & + .ag-list__product-card-container {
      border: 0;
    }
  }
  @media ${screen.large} {
    .ag-theme-custom-list {
      height: calc(100vh - 260px); /* for browsers that do not support Custom Properties */
      height: calc(
        (var(--vh, 1vh) * 100) - 260px
      ); /* use custom vh property to prevent vertical shift on mobile browsers */
    }
  }
  @media ${screen.tablet} {
    .ag-theme-custom-list {
      height: calc(100vh - 210px); /* for browsers that do not support Custom Properties */
      height: calc(
        (var(--vh, 1vh) * 100) - 210px
      ); /* use custom vh property to prevent vertical shift on mobile browsers */
    }
  }
  @media ${screen.mobileL} {
    .ag-row {
      overflow: hidden;
    }
  }
`;
