import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const ocrOrderPdfService = {
  fetchAllOcrJob,
  addOcrJob,
  updateOcrJob,
  deleteOcrJob,
  fetchOcrJobResult,
};

function fetchAllOcrJob(offset, limit) {
  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/ocr_order_pdf?&offset=${offset}&limit=${limit}&orderByDesc=dateCreate`,
    requestOptions
  ).then(handleResponse);
}

function addOcrJob(clientId, file) {
  const body = new FormData();

  body.append('file', file);
  body.append('client_id', clientId);

  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/ocr_order_pdf`, requestOptions).then(handleResponse);
}

function updateOcrJob(jobId, data) {
  const body = JSON.stringify({
    ...data,
  });

  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/ocr_order_pdf/${jobId}`, requestOptions).then(handleResponse);
}

function deleteOcrJob(jobId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/ocr_order_pdf/${jobId}`, requestOptions).then(handleResponse);
}

function fetchOcrJobResult(jobId) {
  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/ocr_order_pdf/${jobId}`, requestOptions).then(handleResponse);
}
