import styled from 'styled-components';

export default (Component) => styled(Component)`
  h2 {
    color: #01366b;
  }
  .assortment-actions {
    margin: 20px 0;
    a {
      /* corresponds to the buttons */
      margin: 0 5px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      text-align: center;
      padding: 10px 18px;
    }
    a:nth-of-type(1) {
      margin-left: 0px;
    }
  }
  .assortment-table {
    height: calc(50vh - 100px);
    display: inline-block;
    margin-bottom: 20px;
    overflow-x: auto;
    width: 100%;
    border-color: transparent;
    border-collapse: collapse;
    background-color: white;
    thead {
      display: inline-block;
      width: 100%;
      th {
        background-color: #e2e2e2;
        padding: 6px 19px;
        border: 1px solid #e2e2e2;
        text-align: left;
        font-weight: normal;
        display: inline-block;
        width: 15%;
        &:first-child {
          width: 100%;
        }
      }
      tr {
        width: 100%;
        display: inline-block;
      }
    }
    tr td {
      /* Checkbox Style */
      .checkContainer {
        position: relative;
        margin: 0px 1px;
        padding-left: 25px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        vertical-align: top;
      }
      .checkname {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.12px;
        line-height: 19px;
        color: #4a4a4a;
        display: inline-block;
        vertical-align: top;
        padding-left: 13px;
      }
      /* Hide the browser's default checkbox */
      .checkContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 1px solid rgba(120, 120, 120, 0.54);
        margin: 0 7px;
      }

      /* When the checkbox is checked, add a blue background */
      .checkContainer input:checked ~ .checkmark {
        background-color: white;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .checkContainer input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkContainer .checkmark:after {
        left: 5px;
        width: 7px;
        height: 14px;
        border: solid #01366b;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg) translateX(1px);
        -ms-transform: rotate(45deg) translateX(1px);
        transform: rotate(45deg) translateX(1px);
      }

      &.price {
        color: #01366b;
      }
    }
    tr {
      td:first-child {
        padding: 0 0 4px 16px;
        margin: 13px;
      }
      &:first-child {
        td {
          padding-top: 13px;
        }
      }
    }
  }

  .react-checkbox-tree {
    ol {
      li {
        .rct-text {
          button {
            i {
              &.icon-arrow-down {
                font-size: 24px;
              }
            }
          }
          label {
            .rct-node-icon {
              display: none;
            }
            .rct-checkbox {
              width: 24px;
              height: 24px;
              padding: 1px;
              margin: 6px;
              border: 1px solid;
              display: inline-block;
              .rct-icon {
                visibility: hidden;
              }
            }
            .rct-title {
              font-size: 16px;
              letter-spacing: 0.12px;
              font-weight: bold;
              line-height: 19px;
            }
          }
          .rct-collapse *,
          .rct-checkbox *,
          .rct-node-icon * {
            vertical-align: middle;
            display: inline-block;
            margin: 0px;
            width: 14px;
          }
        }
      }
    }
  }
`;
