import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Spinner from 'components/Core/Spinner/Spinner';
import sideBarDef from './sidebarDefinitions';
import localeText from './locales';

const Aggrid = ({
  ref,
  defaultColDef,
  columnDefs,
  rowData,
  frameworkComponents,
  rowSelection,
  onGridReadyCallback,
  onSelectionChanged,
  isExternalFilterPresent,
  externalFilterChanged,
  doesExternalFilterPass,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const resizeGrid = () => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeGrid);
  });

  const onGridReady = (params) => {
    if (params.api) {
      params.api.sizeColumnsToFit();
      setGridApi(params.api);
      onGridReadyCallback(params);
    }
  };

  const onFirstDataRendered = () => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  };

  const onToolPanelVisibleChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onColumnVisible = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="ag-theme-balham">
      <AgGridReact
        ref={ref}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        frameworkComponents={{ ...frameworkComponents, spinner: () => <Spinner /> }}
        sideBar={sideBarDef}
        localeText={localeText}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        onColumnVisible={onColumnVisible}
        width={100}
        height={100}
        loadingOverlayComponent="spinner"
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        rowMultiSelectWithClick
        isExternalFilterPresent={isExternalFilterPresent}
        externalFilterChanged={externalFilterChanged}
        doesExternalFilterPass={doesExternalFilterPass}
      />
    </div>
  );
};

export default Aggrid;
