import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { RiShoppingCartFill } from '@react-icons/all-files/ri/RiShoppingCartFill';
import { RiFileListLine } from '@react-icons/all-files/ri/RiFileListLine';
import { IoSettingsSharp } from '@react-icons/all-files/io5/IoSettingsSharp';
import { getPath, history } from 'helpers';
import { modalActions, userActions } from 'actions';
import logo from 'styles/assets/images/logo.png';
import { selectScreenWidth } from 'selectors/ui';
import { changePlatform } from 'helpers/Platform';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import Icon from 'components/Icon/Icon';
import { checkMatchRoute } from 'helpers/Routes';
import { size } from 'components/Core/Utils/Device';
import Hamburger from 'components/Core/Hamburger/Hamburger';
import { MenuItem, SubMenuItem } from './Header.style';
import { userConstants } from '../../../../../constants';

const CustomerHeader = ({ platforms, selectedId, user, screenWidth, openExtractExcel }) => {
  const [settingsListOpen, setSettingsListOpen] = useState(false);

  const handlePlatformChange = (e) => {
    changePlatform(e.target.value, user);
  };

  const goToDashboard = () => {
    history.push(getPath('clientList'));
  };

  const toggleSettingsDropDown = (isListOpen) => {
    setSettingsListOpen(isListOpen);
  };

  const settingsTitle = (
    <>
      <IoSettingsSharp />
      <span>PARAMÈTRES</span>
    </>
  );

  const { type } = user.information || null;

  const hamburgerElements = [
    {
      title: (
        <>
          <FaUser />
          <span>CLIENTS</span>
        </>
      ),
      routeName: 'clientList',
    },
    {
      title: (
        <>
          <Icon content="\e93f" />
          <span>HISTORIQUE</span>
        </>
      ),
      routeName: 'orderList',
    },
    {
      title: settingsTitle,
      children: [
        {
          title: 'Mes commerciaux',
          routeName: 'commercial',
        },
        {
          title: 'Plannings de livraison',
          routeName: 'planning',
        },
        {
          title: 'Jours fériés & fermeture',
          routeName: 'dayoff',
        },
        {
          title: 'CGV appliquées',
          routeName: 'terms',
        },
        {
          title: 'Paramètres précommande',
          routeName: 'settingsPreorder',
        },
        {
          title: 'Messagerie client',
          routeName: 'message',
        },
      ],
    },
    {
      title: (
        <>
          <RiShoppingCartFill />
          <span>SAISIE DE COMMANDE</span>
        </>
      ),
      routeName: 'commercialCartList',
    },
  ];

  // Remove settigns element if not admin type
  if (type !== userConstants.TYPE_ADMIN) {
    hamburgerElements.splice(2, 1);
  }

  return (
    <>
      <div className="header-container">
        <span onClick={goToDashboard}>
          <img src={logo} className="logo" alt="commande" />
        </span>
        <div className={`hr}`} />
        <div className={`header-soc}`}>
          <div className="header-soc--select">
            <select placeholder="Platformes" onChange={handlePlatformChange} value={selectedId}>
              {platforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="hr" />
      </div>
      {screenWidth <= size.tablet && <Hamburger menuElementList={hamburgerElements} />}
      {screenWidth > size.tablet && (
        <div className="header-container header-container__menu">
          <MenuItem
            onClick={() => {
              history.push(getPath('clientList').replace(':status', ''));
            }}
            active={checkMatchRoute(getPath('clientList'))}
          >
            <FaUser />
            <span>CLIENTS</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(getPath('articleList'));
            }}
            active={checkMatchRoute(getPath('articleList'))}
          >
            <RiFileListLine />
            <span>ARTICLES</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(getPath('orderList'));
            }}
            active={checkMatchRoute(getPath('orderList'))}
          >
            <Icon content="\e93f" />
            <span>
              <span className="nowrap">HISTORIQUE</span>
            </span>
          </MenuItem>
          {type === userConstants.TYPE_ADMIN && (
            <MenuItem
              active={
                checkMatchRoute(getPath('commercial')) ||
                checkMatchRoute(getPath('planning')) ||
                checkMatchRoute(getPath('dayoff')) ||
                checkMatchRoute(getPath('terms')) ||
                checkMatchRoute(getPath('settingsPreorder')) ||
                checkMatchRoute(getPath('message'))
              }
            >
              <Dropdown
                headerTitle={settingsTitle}
                listOpen={settingsListOpen}
                toggleDropDown={toggleSettingsDropDown}
                defaultIcon={false}
              >
                <ul className="dd-list">
                  <SubMenuItem>
                    <Link to={getPath('commercial')}>Mes commerciaux</Link>
                  </SubMenuItem>
                  <SubMenuItem>
                    <Link to={getPath('planning')}>Plannings de livraison</Link>
                  </SubMenuItem>
                  <SubMenuItem>
                    <Link to={getPath('dayoff')}>Jours fériés &amp; fermeture</Link>
                  </SubMenuItem>
                  <SubMenuItem>
                    <Link to={getPath('terms')}>CGV appliquées</Link>
                  </SubMenuItem>
                  <SubMenuItem>
                    <Link to={getPath('settingsPreorder')}>Paramètres précommande</Link>
                  </SubMenuItem>
                  <SubMenuItem>
                    <Link to={getPath('message')}>Messagerie client</Link>
                  </SubMenuItem>
                  <SubMenuItem onClick={() => openExtractExcel()}>
                    <span>Extract XLS preco</span>
                  </SubMenuItem>
                </ul>
              </Dropdown>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              history.push(getPath('commercialCartList'));
            }}
            active={checkMatchRoute(getPath('commercialCartList'))}
          >
            <RiShoppingCartFill />
            <span>SAISIE DE COMMANDE</span>
          </MenuItem>
        </div>
      )}
    </>
  );
};

CustomerHeader.propTypes = {
  platforms: PropTypes.array,
  selectedId: PropTypes.number,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { platform, user } = state;
  const { selectedId, platforms } = platform;
  return {
    platforms,
    selectedId,
    user,
    screenWidth: selectScreenWidth(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutAs: () => {
    dispatch(userActions.logoutAs());
  },
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
