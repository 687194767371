/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { cartActions, modalActions, dayOffActions } from 'actions';
import get from 'lodash.get';
import { RiRepeatFill } from '@react-icons/all-files/ri/RiRepeatFill';
import {
  numberToPrice,
  getCartsById,
  getCollection,
  getValueOfAttribut,
  dateTimeToFormatedDate,
  getPath,
  history,
} from 'helpers';
import { pageContextActions } from 'actions/PageContext';
import { selectCurrentPageContext } from 'selectors/catalog';
import { FaRegFilePdf } from '@react-icons/all-files/fa/FaRegFilePdf';
import { HiOutlineRefresh } from '@react-icons/all-files/hi/HiOutlineRefresh';
import { FiArrowRight } from '@react-icons/all-files/fi/FiArrowRight';
import frLocale from 'moment/locale/fr';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import StickyInCart from 'components/Core/StickyInCart/StickyInCart';
import Icon from 'components/Icon/Icon';
import TableWrapper from 'components/Table';
import { tableTypes } from 'components/Table/tableTypes';
import { PreorderOutstanding } from 'components/Catalog';
import Calendar from 'components/Core/Calendar/Calendar';
import { getFormatedDate } from 'helpers/Utils';
import { isValidDeliveryDay } from 'helpers/Cart';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { ListWrapper } from 'components/Core/Dropdown/Dropdown.style';
import {
  catalogConstants,
  userConstants,
  cartConstants,
  platformConstants,
  tableConstants,
} from 'pages/../constants';
import OrderBreadCrumb from 'pages/Order/OrderBreadcumb';
import {
  CART_COLUMNS_ITEMS,
  CUSTOMER_CURRENT_CART_COLUMNS_ITEMS,
  COMMERCIAL_CURRENT_CART_COLUMNS_ITEMS,
  GROUP_BY,
} from './TableOptions';
import OngletCart from './OngletCart';
import CartBreadCrumb from './CartBreadcumb';
import withStyleCart from './Cart.style';

const { TYPE_CUSTOMER } = userConstants;
const { TABLE_TYPE_CART_ITEMS } = tableTypes;
const {
  DELIVERY_MODE_KEY,
  PREORDER_DATE_BEGIN_KEY,
  PREORDER_DELAY_KEY,
  DELIVERY_DELAY_KEY,
} = platformConstants;
const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;

const {
  CART_STATUS_CURRENT,
  CART_STATUS_CURRENT_PREORDER,
  CART_STATUS_CUSTOM,
  CART_STATUS_CUSTOM_PREORDER,
  CART_STATUS_MOQ_PREORDER,
  CART_STATUS_MOQ_STAND_BY,
  CART_STATUS_CUSTOM_RECURRING,
} = cartConstants;

const { DIRECTION_REPLACE } = tableConstants;

const ErrorCartConstant = {
  outOfStock: 'You are not allowed to order one of those item',
  cartValidated: 'Cart already validated',
  unavailableItems: 'all items are unavailable !',
};

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      listOpen: false,
      firstload: true,
    };
    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handelChange = this.handelChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  componentDidMount() {
    const { getItemByID, fetchDayOff, selectedPlatform } = this.props;
    if (selectedPlatform && selectedPlatform.id) {
      // if (!this.getIsLoading()) {
      getItemByID(this.getFilter(), this.getCart());
      // }
      fetchDayOff({ platform: selectedPlatform.id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      getItemByID,
      selectedPlatform,
      fetchDayOff,
      dayOff,
      error,
      openErrorCartModal,
      openOutOfStockModal,
    } = this.props;
    const { selectedPlatform: prevSelectedPlatform, error: prevError } = prevProps;

    const newId = this.getRequestedId();
    let { id: oldId } = prevProps.match && prevProps.match.params;
    if (!oldId) {
      oldId = this.getDefaultId();
    }
    const cart = this.getCart();

    if (!this.getIsLoading()) {
      if (!cart && newId && selectedPlatform && selectedPlatform.id !== prevSelectedPlatform.id) {
        getItemByID(this.getFilter());
      }
      if (newId && oldId && newId !== oldId && selectedPlatform && selectedPlatform.id) {
        getItemByID(this.getFilter(), cart);
      }
    }

    const { comment: prevComment } = prevState;
    const { comment } = this.state;

    if (cart && !this.getIsLoading() && cart.comment && this.state.firstload) {
      this.setComment(cart.comment);
      this.setState({
        firstload: false,
      });
    }

    if ((!cart && !this.getIsLoading()) || (cart && cart.cart_items.length === 0)) {
      history.push(getPath('aggrid'));
    }

    if (
      selectedPlatform &&
      selectedPlatform.id !== prevSelectedPlatform.id &&
      dayOff.length === 0
    ) {
      fetchDayOff({ platform: selectedPlatform.id });
    }

    if (prevError.message !== error.message && error.message !== '') {
      if (error.message === ErrorCartConstant.outOfStock) {
        openOutOfStockModal(cart);
      } else {
        openErrorCartModal(error, cart);
      }
    }
  }

  getDefaultId() {
    const { cart } = this.props;
    const { current, currentPreorder } = cart;

    if (current) {
      return current.id;
    }
    if (currentPreorder) {
      return currentPreorder.id;
    }
    return null;
  }

  getRequestedId() {
    const { match, current, currentPreorder } = this.props;
    const { id } = match && match.params;
    if (!id && current) {
      return current.id;
    }
    if (!id && currentPreorder) {
      return currentPreorder.id;
    }
    return id;
  }

  getFilter() {
    const { selectedPlatform } = this.props;
    const requestedId = this.getRequestedId();

    return this.getIsCurrent()
      ? {
          status: this.getIsCurrentCart() ? CART_STATUS_CURRENT : CART_STATUS_CURRENT_PREORDER,
          platform: selectedPlatform.id,
        }
      : { id: requestedId, platform: selectedPlatform.id };
  }

  getCartTotals() {
    const cart = this.getCart();
    const temperatures = [TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN];
    const totals = {};

    temperatures.forEach((temperature) => {
      totals[temperature] = {
        total_price_vat: 0,
        total_price: 0,
        total_quantity: 0,
      };
    });
    if (cart) {
      return Object.assign(totals, cart.totals_by_type);
    }
    return totals;
  }

  getCart() {
    const { current, currentPreorder, cart } = this.props;
    const { collections } = cart;
    const id = this.getRequestedId();

    if (String(current.id) === String(id) || id === 'current') {
      return current;
    }

    if (String(currentPreorder.id) === String(id) || id === 'current_preorder') {
      return currentPreorder;
    }

    const carts = getCartsById(id, collections);
    if (carts && carts.length) {
      return carts[0];
    }

    return null;
  }

  getCartCountItems() {
    const cart = this.getCart();

    const temperatures = [TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN];
    const totals = {};

    temperatures.forEach((temperature) => {
      totals[temperature] = 0;
    });

    if (cart) {
      return Object.assign(totals, cart.count_items_by_type);
    }
    return totals;
  }

  getSelectedTemperature(countDry, countFresh, countFrozen) {
    const { match } = this.props;
    const { temperature } = match.params || {};
    switch (temperature) {
      case TEMPERATURE_DRY:
        if (countDry !== 0) {
          return TEMPERATURE_DRY;
        }
        if (countFresh !== 0) {
          return TEMPERATURE_FRESH;
        }
        if (countFrozen !== 0) {
          return TEMPERATURE_FROZEN;
        }
        return null;
      case TEMPERATURE_FRESH:
        if (countFresh !== 0) {
          return TEMPERATURE_FRESH;
        }
        if (countDry !== 0) {
          return TEMPERATURE_DRY;
        }
        if (countFrozen !== 0) {
          return TEMPERATURE_FROZEN;
        }
        return null;
      case TEMPERATURE_FROZEN:
        if (countFrozen !== 0) {
          return TEMPERATURE_FROZEN;
        }
        if (countDry !== 0) {
          return TEMPERATURE_DRY;
        }
        if (countFresh !== 0) {
          return TEMPERATURE_FRESH;
        }
        return null;
      default:
        if (countDry !== 0) {
          return TEMPERATURE_DRY;
        }
        if (countFresh !== 0) {
          return TEMPERATURE_FRESH;
        }
        if (countFrozen !== 0) {
          return TEMPERATURE_FROZEN;
        }
        return null;
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleDatesChange(date, type) {
    const { updateDeliveryDates } = this.props;
    const cart = this.getCart();
    const {
      delivery_date_dry: deliveryDateDry,
      delivery_date_fresh: deliveryDateFresh,
      delivery_date_frozen: deliveryDateFrozen,
    } = cart || {};
    const deliveryDates = {
      delivery_date_dry: type === 'dry' || type === 'all' ? getFormatedDate(date) : deliveryDateDry,
      delivery_date_fresh:
        type === 'fresh' || type === 'all' ? getFormatedDate(date) : deliveryDateFresh,
      delivery_date_frozen:
        type === 'frozen' || type === 'all' ? getFormatedDate(date) : deliveryDateFrozen,
    };

    updateDeliveryDates(cart, deliveryDates);
  }

  handleDeliveryModeChange(deliveryMode) {
    const { updateDeliveryMode, user } = this.props;
    const cart = this.getCart();
    if (user.type === TYPE_CUSTOMER) {
      updateDeliveryMode(cart, { delivery_mode: deliveryMode });
    }
  }

  getIsCurrentCart() {
    if (String(this.getRequestedId()) === 'current') {
      return true;
    }

    const { current } = this.props;

    if (current && current.status) {
      return String(current.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT;
    }
    return false;
  }

  getIsCurrentPreorderCart() {
    if (String(this.getRequestedId()) === 'current_preorder') {
      return true;
    }

    const { currentPreorder } = this.props;

    if (currentPreorder && currentPreorder.status) {
      return String(currentPreorder.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT_PREORDER;
    }
    return false;
  }

  getIsCurrent() {
    return this.getIsCurrentCart() || this.getIsCurrentPreorderCart();
  }

  handelChange(e) {
    this.setComment(e.target.value);
  }

  handleBlur() {
    const {
      state: { comment },
    } = this;
    this.setState({
      comment,
    });
    this.submitComment();
  }

  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  setComment(comment) {
    this.setState({
      comment,
    });
  }

  submitComment() {
    const { updateCart } = this.props;
    const cart = this.getCart();
    const { comment } = this.state;
    updateCart(cart, { comment });
  }

  convertStatusText = (status, userType) => {
    switch (status) {
      case CART_STATUS_MOQ_PREORDER:
        return 'en attente de validation';
      case CART_STATUS_MOQ_STAND_BY:
        return userType === TYPE_CUSTOMER ? 'en attente de validation' : 'à valider';
      default:
        return '';
    }
  };

  getIsLoading() {
    const cart = this.getCart();
    if (!cart) {
      return true;
    }
    const { cart_items: cartItems } = cart;
    const hasTempsCartItems = cartItems
      ? !!cartItems.find((ci) => ci.is_temps || ci.is_waiting_for_api)
      : true;

    return (
      Object.keys(cart).length === 0 ||
      cart.is_temps ||
      cart.is_waiting_for_api ||
      hasTempsCartItems
    );
  }

  toggleDropDown(listOpen) {
    this.setState({ listOpen });
  }

  render() {
    const {
      className,
      user,
      openSaveToCartModal,
      resetCart,
      openSaveModal,
      openDeleteModal,
      selectedPlatform,
      openValidateCartModal,
      isCopyCartLoading,
      dayOff,
      downloadCartPdf,
      openSaveRecurrentModal,
    } = this.props;

    const { attributs } = selectedPlatform;
    const cart = this.getCart();
    const {
      delivery_date_dry: deliveryDateDry,
      delivery_date_fresh: deliveryDateFresh,
      delivery_date_frozen: deliveryDateFrozen,
      user: cartUser,
      orderer,
    } = cart || {};

    const { comment } = this.state;

    const {
      client: { client_delivery_modes: clientDeliveryModes },
    } = cartUser || { client: { client_delivery_modes: [] } };
    const {
      [TEMPERATURE_DRY]: totalsDry,
      [TEMPERATURE_FRESH]: totalsFresh,
      [TEMPERATURE_FROZEN]: totalsFrozen,
    } = this.getCartTotals();

    const {
      [TEMPERATURE_DRY]: countDry,
      [TEMPERATURE_FRESH]: countFresh,
      [TEMPERATURE_FROZEN]: countFrozen,
    } = this.getCartCountItems();

    const isLoading = this.getIsLoading();

    const isCurrentCart = this.getIsCurrentCart();
    const isCurrentPreorderCart = this.getIsCurrentPreorderCart();
    const isCurrent = this.getIsCurrent();

    const deliveryData = [
      {
        total: totalsDry,
        count: countDry,
        temperature: TEMPERATURE_DRY,
        deliveryDate: deliveryDateDry,
        temperatureSpan: 'Sec',
        temperatureType: 'dry',
        class: 'dry icon-dry',
      },
      {
        total: totalsFresh,
        count: countFresh,
        temperature: TEMPERATURE_FRESH,
        deliveryDate: deliveryDateFresh,
        temperatureSpan: 'Frais',
        temperatureType: 'fresh',
        class: 'green icon-apple',
      },
      {
        total: totalsFrozen,
        count: countFrozen,
        temperature: TEMPERATURE_FROZEN,
        deliveryDate: deliveryDateFrozen,
        temperatureSpan: 'Surgelé',
        temperatureType: 'frozen',
        class: 'blue icon-snow',
      },
    ];

    const isPreorder = cart && cart.is_preorder;
    const selectedTemperature = this.getSelectedTemperature(countDry, countFresh, countFrozen);
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const { delivery_mode: clientDeliveryMode } =
      (clientDeliveryModes || []).find(
        (deliveryMode) => Number(deliveryMode.platform.id) === Number(selectedPlatform.id)
      ) || {};
    const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
    const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
    const preorderDateBegin = Moment(
      getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY) || undefined
    ).format('YYYY-MM-DD HH:mm:00');

    const { listOpen } = this.state;
    // disable button commande automatique
    const disableCommandeAuto = 0;
    return (
      <div className={className}>
        {!isLoading ? (
          <div className="page-cart fade-in">
            <div className="page-cart__heading">
              <div className="bloc-ariane__section-label">
                {cart &&
                [CART_STATUS_MOQ_STAND_BY, CART_STATUS_MOQ_PREORDER].includes(cart.status) ? (
                  <>
                    <OrderBreadCrumb
                      type="detail"
                      orderId={cart.id}
                      status={cart.status}
                      userType={user.type}
                      detailPreorder
                    />
                    <div className="page-cart__section-label">
                      <span className="bold">{`Precommande n°${cart.id}`}</span>
                    </div>
                  </>
                ) : user.type !== TYPE_CUSTOMER &&
                  [CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(cart.status) ? (
                  <OrderBreadCrumb
                    type="detail"
                    orderId={cart.id}
                    status={cart.status}
                    userType={user.type}
                    detailPreorder={cart.status === CART_STATUS_CURRENT_PREORDER}
                  />
                ) : (
                  <CartBreadCrumb
                    isCurrentCart={isCurrentCart}
                    isCurrentPreorderCart={isCurrentPreorderCart}
                    cartName={cart && cart.name}
                    isListCart={false}
                    isPreorder={isPreorder}
                  />
                )}

                <div className="page-cart__section-label" hidden={!isCurrent}>
                  <Icon content={isCurrentCart ? '\\e94c' : '\\e941'} />
                  <span className="bold">
                    {user.type !== TYPE_CUSTOMER ? 'Panier en cours' : 'Votre panier en cours'}
                  </span>
                  {user.type !== TYPE_CUSTOMER && (
                    <span className="client-name">
                      {cart &&
                        cart.user &&
                        cart.user.client_name &&
                        `Client N° ${cart.user.client.ext_code.slice(1)} ${cart.user.client_name}`}
                    </span>
                  )}
                </div>
                {cart &&
                  !isCurrent &&
                  ![CART_STATUS_MOQ_STAND_BY, CART_STATUS_MOQ_PREORDER].includes(cart.status) &&
                  cart.name && (
                    <div className="page-cart__section-label">
                      {isPreorder ? <Icon content="\e941" /> : <Icon content="\e94c" />}
                      <span className="bold">{cart ? cart.name : ''}</span>
                    </div>
                  )}
              </div>
              <div className="clearfix">&nbsp;</div>
            </div>
            <div>
              {isCurrentPreorderCart && <PreorderOutstanding selectedPlatform={selectedPlatform} />}
              <div className="page-cart__top__section">
                <div className="cart-date-creation" hidden={isCurrent}>
                  <div>
                    <div className="cart-date-creation__date-bloc">
                      {![CART_STATUS_CUSTOM, CART_STATUS_CUSTOM_PREORDER].includes(cart.status) && (
                        <div>
                          <span className="bold">
                            {cart.status === CART_STATUS_CUSTOM_RECURRING
                              ? 'Créée le : '
                              : 'Passée le : '}
                          </span>
                          <span>
                            {`
                              ${Moment(cart.date_create).format('DD/MM/YYYY')}
                              ${
                                orderer && (orderer.lastname || orderer.firstname)
                                  ? ` par ${orderer.firstname} ${orderer.lastname}`
                                  : ''
                              }
                            `}
                          </span>
                        </div>
                      )}
                      {(isCurrentPreorderCart ||
                        [CART_STATUS_MOQ_PREORDER, CART_STATUS_MOQ_STAND_BY].includes(
                          cart.status
                        )) && (
                        <div>
                          <span className="bold">Livraison indicative : </span>
                          <span>
                            {Moment(preorderDateBegin)
                              .locale('fr', frLocale)
                              .add(preorderDelay + deliveryDelay, 'days')
                              .format('DD/MM/YYYY')}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="cart-date-creation__status-bloc">
                      <span>{this.convertStatusText(cart.status, user.type)}</span>
                    </div>
                  </div>
                  <br />
                  {[
                    CART_STATUS_CUSTOM_RECURRING,
                    CART_STATUS_CUSTOM,
                    CART_STATUS_CUSTOM_PREORDER,
                  ].includes(cart.status) && (
                    <span className="italic information-not-available">
                      {`
                        Des références peuvent être indisponibles
                        depuis la dernière utilisation du panier
                      `}
                    </span>
                  )}
                </div>
                {isCurrent && platformDeliveryMode && (
                  <div className="summary delivery-mode">
                    <div className="delivery-mode-choice">
                      <span className="title-bold">Choix logistique</span>
                    </div>
                    {user.type === TYPE_CUSTOMER ? (
                      <>
                        <div className="delivery-mode-choice">
                          <div
                            className={`block__radio ${clientDeliveryMode && 'no_radio_button'}`}
                          >
                            <label onClick={() => this.handleDeliveryModeChange(1)}>
                              <input
                                type="radio"
                                checked={cart.delivery_mode || clientDeliveryMode}
                              />
                              <span className="bold">Préparation</span>
                              <span className="delivery-mode-price">
                                {` (+${numberToPrice(
                                  Math.min(Number(cart.total_price) * 0.02, 80)
                                )})`}
                              </span>
                              {!clientDeliveryMode && <span className="check" />}
                            </label>
                          </div>
                          {(clientDeliveryMode ||
                            (!!cart.delivery_mode && !clientDeliveryMode)) && (
                            <div className="delivery-date">
                              <span>Date de Retrait :</span>
                              <div className="delivery-date__calendar-bloc">
                                <Calendar
                                  onChange={(date) => {
                                    this.handleDatesChange(date, 'all');
                                  }}
                                  selected={
                                    deliveryDateDry &&
                                    Moment(deliveryDateDry).isSameOrAfter(
                                      Moment().format('YYYY-MM-DD')
                                    )
                                      ? Moment(deliveryDateDry).toDate()
                                      : ''
                                  }
                                  filterDate={(date) =>
                                    isValidDeliveryDay(date, true, user, dayOff, selectedPlatform)
                                  }
                                  InputClassName="permanent-cart-input__Date"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {!clientDeliveryMode && (
                          <div className="delivery-mode-choice">
                            <div className="block__radio">
                              <label onClick={() => this.handleDeliveryModeChange(0)}>
                                <input type="radio" checked={!cart.delivery_mode} />
                                <span className="bold">Préparation</span>
                                <span className="delivery-mode-price">
                                  {` (+${numberToPrice(
                                    Math.min(Number(cart.total_price) * 0.02, 80)
                                  )}) `}
                                </span>
                                <span className="bold">+ Livraison</span>
                                <span className="delivery-mode-price">
                                  {` (+${numberToPrice(
                                    Math.min(Number(cart.total_price) * 0.04, 200)
                                  )})`}
                                </span>
                                <span className="check" />
                              </label>
                            </div>
                            <div
                              className="delivery-date"
                              hidden={
                                user.type !== TYPE_CUSTOMER ||
                                Number(cart.delivery_mode) === 1 ||
                                [CART_STATUS_MOQ_STAND_BY, CART_STATUS_MOQ_PREORDER].includes(
                                  cart.status
                                )
                              }
                            >
                              <span>Date de livraison :</span>
                              <div className="delivery-date__calendar-bloc">
                                <Calendar
                                  onChange={(date) => {
                                    this.handleDatesChange(date, 'all');
                                  }}
                                  selected={
                                    deliveryDateDry &&
                                    Moment(deliveryDateDry).isSameOrAfter(
                                      Moment().format('YYYY-MM-DD')
                                    )
                                      ? Moment(deliveryDateDry).toDate()
                                      : ''
                                  }
                                  filterDate={(date) =>
                                    isValidDeliveryDay(date, false, user, dayOff, selectedPlatform)
                                  }
                                  InputClassName="permanent-cart-input__Date"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : !clientDeliveryMode ? (
                      cart.delivery_mode ? (
                        <div className="delivery-mode-choice">
                          <span className="bold">Préparation</span>
                          <span className="delivery-mode-price">
                            {` (+${numberToPrice(Math.min(Number(cart.total_price) * 0.02, 80))})`}
                          </span>
                          {cart.delivery_date_dry && (
                            <div className="delivery-date">
                              <span>Date de Retrait :</span>
                              <span className="delivery-date__text-bloc">
                                {dateTimeToFormatedDate(cart.delivery_date_dry)}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="delivery-mode-choice" hidden={clientDeliveryMode}>
                          <span className="bold">Préparation</span>
                          <span className="delivery-mode-price">
                            {` (+${numberToPrice(Math.min(Number(cart.total_price) * 0.02, 80))}) `}
                          </span>
                          <span className="bold">+ Livraison</span>
                          <span className="delivery-mode-price">
                            {` (+${numberToPrice(Math.min(Number(cart.total_price) * 0.04, 200))})`}
                          </span>
                          {cart.delivery_date_dry && (
                            <div className="delivery-date">
                              <span>Date de livraison :</span>
                              <span className="delivery-date__text-bloc">
                                {dateTimeToFormatedDate(cart.delivery_date_dry)}
                              </span>
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div className="delivery-mode-choice">
                        <span className="bold">Préparation</span>
                        <span className="delivery-mode-price">
                          {` (+${numberToPrice(Math.min(Number(cart.total_price) * 0.02, 80))})`}
                        </span>
                        {cart.delivery_date_dry && (
                          <div className="delivery-date">
                            <span>Date de Retrait :</span>
                            <span className="delivery-date__text-bloc">
                              {dateTimeToFormatedDate(cart.delivery_date_dry)}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="summary">
                  <table>
                    <tbody>
                      {deliveryData.map((data, i) => (
                        <tr key={`tr-${i}`} hidden={data.total.total_quantity === 0}>
                          <td>
                            <i className={`Circle__icon-${data.class}`} />
                            <span className="permanent__headline-temperature">
                              {data.temperatureSpan}
                            </span>
                            <span className="permanent-cart__ref">
                              {`${data.count} référence(s) `}
                              {!!cart.totals_by_type[data.temperature] &&
                                !!(
                                  cart.totals_by_type[data.temperature].total_volume_package ||
                                  cart.totals_by_type[data.temperature].total_weight_gross_package
                                ) && (
                                  <>
                                    (
                                    {!!cart.totals_by_type[data.temperature]
                                      .total_weight_gross_package &&
                                      `${Number.parseFloat(
                                        cart.totals_by_type[data.temperature]
                                          .total_weight_gross_package
                                      ).toFixed(2)}kg`}
                                    {!!cart.totals_by_type[data.temperature]
                                      .total_weight_gross_package &&
                                      !!cart.totals_by_type[data.temperature]
                                        .total_volume_package &&
                                      `, `}
                                    {!!cart.totals_by_type[data.temperature].total_volume_package &&
                                      `${Number.parseFloat(
                                        cart.totals_by_type[data.temperature].total_volume_package
                                      ).toFixed(2)}m³`}
                                    )
                                  </>
                                )}
                            </span>
                          </td>
                          <td className="td-permanent-card">
                            <div
                              className="permanent-cart"
                              hidden={
                                data.total.total_quantity === 0 ||
                                !isCurrentCart ||
                                platformDeliveryMode
                              }
                            >
                              <span className="bold nowrap">
                                Date de livraison<span className="red">*</span>{' '}
                              </span>
                              {user.type !== TYPE_CUSTOMER ? (
                                <span>
                                  {data.deliveryDate && dateTimeToFormatedDate(data.deliveryDate)}
                                </span>
                              ) : (
                                <div className="permanent-cart__calendar">
                                  <Calendar
                                    onChange={(date) => {
                                      this.handleDatesChange(date, data.temperatureType);
                                    }}
                                    selected={
                                      data.deliveryDate &&
                                      Moment(data.deliveryDate).isSameOrAfter(
                                        Moment().format('YYYY-MM-DD')
                                      )
                                        ? Moment(data.deliveryDate).toDate()
                                        : ''
                                    }
                                    filterDate={(date) =>
                                      isValidDeliveryDay(
                                        date,
                                        data.temperature,
                                        user,
                                        dayOff,
                                        selectedPlatform
                                      )
                                    }
                                    InputClassName="permanent-cart-input__Date"
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="td-permanent-cart__Total">
                            <span className="permanent-cart__Total">
                              {`${numberToPrice(data.total.total_price)} HT`}
                            </span>
                          </td>
                          <td className="endtd" />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {deliveryData.filter((data) => data.count > 0).length > 1 && (
                  <div className="permanent-cart__Total-all">
                    <span>{`Total : ${numberToPrice(cart.total_price)} HT`}</span>
                  </div>
                )}
                {!isCopyCartLoading && !isCurrent && (
                  <div className="use-panier">
                    <span
                      onClick={() => {
                        openSaveToCartModal(cart);
                      }}
                    >
                      Utiliser le panier
                    </span>
                  </div>
                )}
                {isCurrent ? (
                  user.type === TYPE_CUSTOMER ? (
                    <div className="cart-footer-comment">
                      <span>Saisissez votre commentaire ici</span>
                      <textarea
                        rows="5"
                        cols="33"
                        maxLength="40"
                        value={comment}
                        onChange={(e) => this.handelChange(e)}
                        onBlur={this.handleBlur}
                      />
                    </div>
                  ) : (
                    ![TYPE_CUSTOMER].includes(user.type) && (
                      <div className="cart-footer-comment">
                        <span>Commentaire du client :</span>
                        <span>{cart.comment}</span>
                      </div>
                    )
                  )
                ) : null}
                <div className="insertstickyCard">
                  <StickyInCart match={this.props.match || {}} />
                </div>
              </div>
              <div className="col-70" id="list-cart">
                <div className="sections">
                  {cart && (
                    <div>
                      {selectedTemperature && (
                        <div>
                          <OngletCart
                            id={cart.id}
                            countDry={countDry}
                            countFresh={countFresh}
                            countFrozen={countFrozen}
                            status={selectedTemperature}
                          />
                          <div className="actions-select">
                            <div className="dropdown-action">
                              <Dropdown
                                headerTitle="Actions"
                                listOpen={listOpen}
                                toggleDropDown={this.toggleDropDown}
                              >
                                {user.type === TYPE_CUSTOMER ? (
                                  <div
                                    hidden={[
                                      CART_STATUS_MOQ_PREORDER,
                                      CART_STATUS_MOQ_STAND_BY,
                                    ].includes(cart.status)}
                                  >
                                    <ul className="dd-list">
                                      <ListWrapper>
                                        {!isCopyCartLoading && !isCurrent && (
                                          <li
                                            onClick={() => {
                                              openSaveToCartModal(cart);
                                            }}
                                          >
                                            <FiArrowRight />
                                            <span>Utiliser le panier</span>
                                          </li>
                                        )}
                                        <li onClick={(event) => downloadCartPdf(cart)}>
                                          <FaRegFilePdf size="18" />
                                          <span>Télécharger en PDF</span>
                                        </li>
                                        <li
                                          hidden={!isCurrent}
                                          onClick={() => openSaveModal(isPreorder)}
                                        >
                                          <Icon content="\e93d" />
                                          <span>Enregistrer comme panier type</span>
                                        </li>
                                        {!disableCommandeAuto &&
                                          cart.status !== CART_STATUS_CUSTOM_RECURRING && (
                                            <li onClick={() => openSaveRecurrentModal(cart)}>
                                              <RiRepeatFill />
                                              <span>Enregistrer comme commande automatique</span>
                                            </li>
                                          )}
                                        <li hidden={!isCurrent} onClick={() => resetCart(cart)}>
                                          <HiOutlineRefresh size="17" />
                                          <span>Réinitialiser les quantités</span>
                                        </li>
                                        <li onClick={() => openDeleteModal(cart.id, isCurrent)}>
                                          <Icon content="\e916" />
                                          <span>Supprimer le panier</span>
                                        </li>
                                      </ListWrapper>
                                    </ul>
                                  </div>
                                ) : (
                                  <div
                                    hidden={
                                      ![
                                        CART_STATUS_MOQ_PREORDER,
                                        CART_STATUS_MOQ_STAND_BY,
                                      ].includes(cart.status)
                                    }
                                  >
                                    <ul className="dd-list">
                                      <ListWrapper>
                                        {cart.status === CART_STATUS_MOQ_PREORDER && (
                                          <li disabled>
                                            <span>Valider les moqs</span>
                                          </li>
                                        )}
                                        {cart.status === CART_STATUS_MOQ_STAND_BY && (
                                          <li onClick={() => openValidateCartModal(cart)}>
                                            <span>Valider</span>
                                          </li>
                                        )}
                                      </ListWrapper>
                                    </ul>
                                  </div>
                                )}
                              </Dropdown>
                            </div>
                          </div>
                          <div className="clearfix">&nbsp;</div>
                          <TableWrapper
                            filter={{ 'item.type': selectedTemperature, cart: cart.id }}
                            groupBy={GROUP_BY}
                            rowsByPage={60}
                            columns={
                              isCurrent
                                ? user.type === TYPE_CUSTOMER
                                  ? CUSTOMER_CURRENT_CART_COLUMNS_ITEMS
                                  : COMMERCIAL_CURRENT_CART_COLUMNS_ITEMS
                                : [CART_STATUS_MOQ_STAND_BY, CART_STATUS_MOQ_PREORDER].includes(
                                    cart.status
                                  )
                                ? COMMERCIAL_CURRENT_CART_COLUMNS_ITEMS
                                : CART_COLUMNS_ITEMS
                            }
                            type={TABLE_TYPE_CART_ITEMS}
                            shouldShowHeaders
                            isInfinite
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="cart-footer-notice" id="cart-page-notice">
                <span>
                  {`
                      Photos non contractuelles
                      , Prix HT (Hors frais de livraison)
                      , Poids et prix peuvent varier selon arrivage et découpe.
                    `}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    cart,
    dayOff,
    platform,
    user: { information },
  } = state;
  const { validated, current, currentPreorder, error, isLoading: isCopyCartLoading } = cart;
  const selectedPlatform = platform
    ? platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {}
    : {};
  const dayOffCollection = getCollection(
    dayOff.collections || [],
    selectedPlatform ? { platform: selectedPlatform.id } : {}
  );
  return {
    user: information,
    cart,
    platform,
    current,
    currentPreorder,
    validated,
    selectedPlatform,
    error,
    isCopyCartLoading,
    dayOff: dayOffCollection.items || [],
    pageContext: selectCurrentPageContext(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDayOff: (filter) => dispatch(dayOffActions.fetchDayOff(filter, 0, 100)),
  resetCart: (cart) => {
    dispatch(cartActions.resetCart(cart));
  },
  updateCart: (cart, data) => {
    dispatch(cartActions.updateCart(cart, data));
  },
  updateDeliveryDates: (cart, deliveryDates) => {
    dispatch(cartActions.updateDeliveryDates(cart, deliveryDates));
  },
  updateDeliveryMode: (cart, deliveryMode) => {
    dispatch(cartActions.updateDeliveryMode(cart, deliveryMode));
  },
  getItemByID: (filter, cart = null) => {
    dispatch(cartActions.fetchItems(filter, 1, 1, DIRECTION_REPLACE, cart));
  },
  openSaveModal: (isCartPreorder) =>
    dispatch(
      modalActions.open('cart', {
        modalHeader: 'Créez un Panier Type',
        showList: false,
        isCartPreorder,
      })
    ),
  openDeleteModal: (cartId, isCurrent) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        showList: false,
        id: cartId,
        deleteType: isCurrent ? 'CART_CURRENT' : 'CART',
      })
    ),
  openSaveToCartModal: (cart) =>
    dispatch(
      modalActions.open('useCustomCart', {
        modalHeader: '',
        cart,
      })
    ),
  openValidateCartModal: (cart) => {
    const modalType = 'validateCart';
    const modalProps = {
      cart,
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  openErrorCartModal: (error, cart) => {
    const modalType = 'errorCart';
    const validateErrorMessage = {
      [ErrorCartConstant.unavailableItems]:
        'Attention votre panier contient des articles indisponibles.',
      [ErrorCartConstant.cartValidated]: 'Cette commande a déjà été validée',
    };
    const modalProps = {
      text: get(validateErrorMessage, error.message, error.message),
      button: true,
      alreadyValid: Object.keys(validateErrorMessage)[1] === error.message,
    };
    if (Object.keys(validateErrorMessage)[1] === error.message) {
      dispatch({ type: cartConstants.DELETE_REQUEST, cartId: cart.id });
    }
    dispatch(modalActions.open(modalType, modalProps));
  },
  openOutOfStockModal: (cart) => {
    const modalType = 'outOfStock';
    const modalProps = {
      cart,
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  openSaveRecurrentModal: (cart) =>
    dispatch(
      modalActions.open('saveRecurrentCart', {
        modalHeader: 'Sauvegarder en tant que commande automatique',
        cart,
        withRedirect: true,
      })
    ),
  downloadCartPdf: (cart) => dispatch(cartActions.downloadCartPdf(cart)),
  pageContext: dispatch(pageContextActions.selectPageContext('PAGE_CART')),
});

Cart.propTypes = {
  cart: PropTypes.object,
  error: PropTypes.object,
  user: PropTypes.object,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  className: PropTypes.string,
  openSaveToCartModal: PropTypes.func,
  resetCart: PropTypes.func,
  updateDeliveryDates: PropTypes.func,
  updateDeliveryMode: PropTypes.func,
  match: PropTypes.object,
  getItemByID: PropTypes.func,
  openSaveModal: PropTypes.func,
  openDeleteModal: PropTypes.func,
  selectedPlatform: PropTypes.object,
  fetchDayOff: PropTypes.func,
  dayOff: PropTypes.array,
  updateCart: PropTypes.func,
  openValidateCartModal: PropTypes.func,
  openErrorCartModal: PropTypes.func,
  openOutOfStockModal: PropTypes.func,
  isCopyCartLoading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyleCart(Cart));
