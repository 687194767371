import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FiPlus } from '@react-icons/all-files//fi/FiPlus';
import { FiMinus } from '@react-icons/all-files//fi/FiMinus';

import debounce from 'lodash.debounce';
import { QuantityHandlerWrapper } from './QuantityHandler.style';

const QuantityHandler = ({
  quantity,
  handleMinus,
  handlePlus,
  handleChange,
  handleBlur,
  disablePlus,
  disableMinus,
  disabled,
}) => {
  const [value, setValue] = useState(quantity);

  const useDebounce = (callback) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
      const func = () => {
        if (ref.current) {
          ref.current();
        }
      };
      return debounce(func, 400);
    }, []);

    return debouncedCallback;
  };

  const debouncedRequest = useDebounce(() => {
    handleChange(value);
  });

  const onChangeDebounce = (e) => {
    setValue(e.target.value);
    debouncedRequest();
  };

  const handleMinusDebounce = (e) => {
    handleMinus();
    if (value) {
      setValue(Math.max(Number(value - 1), 0));
    } else {
      setValue(Math.max(Number(quantity - 1), 0));
    }
  };
  const handlePlusDebounce = (e) => {
    handlePlus();
    if (value) {
      setValue(Number(value) + Number(1));
    } else {
      setValue(Number(quantity) + Number(1));
    }
  };

  return (
    <QuantityHandlerWrapper disablePlus={disablePlus} disableMinus={disableMinus}>
      <span
        className="quantite-icon icon-less-button"
        onClick={disableMinus ? null : handleMinusDebounce}
      >
        <FiMinus />
      </span>
      <input
        type="number"
        className="input-quantite-cadre"
        placeholder="0"
        value={value || quantity || 0}
        onChange={onChangeDebounce}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <span
        className="quantite-icon icon-add-button"
        onClick={disablePlus ? null : handlePlusDebounce}
      >
        <FiPlus />
      </span>
    </QuantityHandlerWrapper>
  );
};

QuantityHandler.propTypes = {
  quantity: PropTypes.number,
  handleMinus: PropTypes.func,
  handlePlus: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default QuantityHandler;
