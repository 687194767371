// eslint-disable no-nested-ternary
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userActions, alertActions, maintenanceActions } from 'actions';
import Alert from 'components/Alert/Alert';
import LoginForm from 'components/Auth/Login/LoginForm';
import logo from 'styles/assets/images/Color@2x.svg';
import { getPath, history, store } from 'helpers';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { checkHealth, logoutUser, isLoggedIn } = this.props;

    checkHealth();

    // Check if user is loggedin then redirect into landing page
    if (isLoggedIn) {
      history.push(getPath('landingPage'));
    }

    // Check if storage exists then login user with his storage
    const u = JSON.parse(localStorage.getItem('user') || null);

    if (!isLoggedIn && u && u.refresh_token) {
      store.dispatch(userActions.loginRefresh(u.refresh_token));
    }
  }

  componentWillUnmount() {
    const { alertClear } = this.props;
    alertClear();
  }

  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    const { loginUser } = this.props;
    if (username && password) {
      loginUser(username, password);
    }
  }

  render() {
    const { className, isLoggedIn, isLoggingIn, storeError, authenticationError } = this.props;
    const { username, password } = this.state;

    return (
      <div className={className}>
        <div className="login__background fade-in">
          <div className="login__form">
            <div className="login__container">
              <div className="login__container-Logo">
                <img className="login__logo" src={logo} alt="logo" />
                <h5 className="login__logo-text regular">Commande.com</h5>
              </div>

              <LoginForm
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                username={username}
                password={password}
                isLoading={isLoggedIn || isLoggingIn}
                onKeyPress={this.keyPress}
              />
            </div>
            <div className="login__errors">
              {storeError && storeError.message && <Alert text={storeError.message} />}
              {authenticationError && <Alert text={authenticationError} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, alert } = state;
  const { isLoggedIn, isLoggingIn, user, error } = authentication;
  return {
    isLoggedIn,
    isLoggingIn,
    storeError: alert,
    authenticationError: error,
    user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  loginUser: (username, password) => {
    dispatch(userActions.login(username, password));
  },
  logoutUser: () => {
    dispatch(userActions.logout());
  },
  alertClear: () => {
    dispatch(alertActions.clear());
  },
  checkHealth: () => dispatch(maintenanceActions.checkHealth()),
});

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  alertClear: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  storeError: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  authenticationError: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
