import { updateLoadingCollection, canDisplayWebSite } from 'helpers';
// Importing alertConstants from the absolute path (`constants`) bugs.
import { loadingConstants } from '../constants';

const initState = {
  collections: [],
  userType: '',
  canDisplayWebSite: false,
};

export function loading(state = initState, action) {
  let {
    collections: stateCollections,
    userType: stateUserType,
    canDisplayWebSite: stateCanDisplayWebSite,
  } = state;

  switch (action.type) {
    case loadingConstants.UPDATE_STATUS:
      stateCollections = updateLoadingCollection(stateCollections, action.name, action.status);
      stateUserType = action.userType ? action.userType : stateUserType;
      stateCanDisplayWebSite =
        canDisplayWebSite(stateCollections, stateUserType) && !stateCanDisplayWebSite
          ? true
          : stateCanDisplayWebSite;

      return {
        collections: stateCollections,
        userType: stateUserType,
        canDisplayWebSite: stateCanDisplayWebSite,
      };
    default:
      return state;
  }
}
