import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoReload } from '@react-icons/all-files/io5/IoReload';
import { AiOutlineMail } from '@react-icons/all-files/ai/AiOutlineMail';
import Icon from 'components/Icon/Icon';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import OrderBreadCrumb from 'pages/Order/OrderBreadcumb';
import filter from 'lodash.filter';
import {
  creditActions,
  invoiceActions,
  modalActions,
  orderActions,
  containerActions,
} from 'actions';
import { getIconClassByType, getValueOfAttribut } from 'helpers';

import { getFormatedDate } from 'helpers/Utils';
import { containerConstants } from 'constants/ContainerV2.constants';
import containerDry from 'styles/assets/images/container-DRY.png';
import containerReefer from 'styles/assets/images/container-REEFER.png';
import { selectOrderListByFilter } from 'selectors/order';
import { selectCurrentPlatformId } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import { orderConstants, userConstants, platformConstants } from '../../../constants';
import Alert from '../../../components/Alert/Alert';

import { orderService } from '../../../services/Order';

import './OrderDetail.scss';

const {
  ORDER_STATUS_PENDING_VALIDATION,
  PREORDER_STATUS_PENDING_VALIDATION,
  ORDER_STATUS_APPROVED,
  PREORDER_STATUS_APPROVED,
  ORDER_STATUS_FACTURED,
  PREORDER_STATUS_FACTURED,
  STATUS_ORDER_DELIVERED,
} = orderConstants;

const OrderDetail = ({
  match,
  user,
  orderList,
  getItemByID,
  selectedPlatformId,
  fetchInvoiceOrder,
  downloadPdf,
  renewOrder,
  getContainers,
  container,
  containersDetail,
  platform: { attributs },
}) => {
  const [order, setOrder] = useState(null);
  const { TYPE_CUSTOMER: USER_TYPE_CUSTOMER } = userConstants;
  const { TYPE_ADMIN, TYPE_COMMERCIAL } = userConstants;
  const [nbContainers, setNbContainers] = useState(undefined);
  const [alertIsVisible, setIsVisible] = useState(false);
  const [orderDetailMailStatus, setOrderDetailMailStatus] = useState(false);

  const { KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;

  useEffect(() => {
    const { id } = (match && match.params) || {};
    // just for testing the credit validation modal
    if (selectedPlatformId) {
      getItemByID({ id, platform: selectedPlatformId });
      fetchInvoiceOrder({ order: Number(id), platform: selectedPlatformId });
    }
  }, []);

  useEffect(() => {
    if (orderList && orderList.items) {
      setOrder(orderList.items[0]);
    }
  }, [orderList]);

  useEffect(() => {
    const { id } = (match && match.params) || {};
    getContainers(setNbContainers, id);
  }, [nbContainers]);

  const sendOrderDetailMailHandler = () => {
    const { id } = order;

    orderService.sendOrderDetailMail(id).then((response) => {
      const { success } = response;
      setIsVisible(true);
      setOrderDetailMailStatus(success);

      setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    });
  };

  const getContainersInfoById = (containersDetail) => {
    const resultContainer = {};
    const temperature = order.count_items_by_type;
    if (containersDetail && temperature.SEC && temperature.SEC > 0) {
      const bins = filter(containersDetail, { type: containerConstants.TYPE_CONTAINER_DRY });
      resultContainer.count = bins.length;
      resultContainer.icon = containerDry;
      resultContainer.text = containerConstants.TYPE_CONTAINER_DRY;
    } else {
      const bins = filter(containersDetail, { type: containerConstants.TYPE_CONTAINER_REEFER });
      resultContainer.count = bins.length;
      resultContainer.icon = containerReefer;
      resultContainer.text = containerConstants.TYPE_CONTAINER_REEFER;
    }
    return resultContainer;
  };

  const columns = React.useMemo(() => [
    {
      id: 'image',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <img
          className="order-detail__react-table__img"
          src={value.item_images.small}
          alt={value.item_name}
        />
      ),
    },
    {
      id: 'name',
      Header: 'Libellé',
      accessor: (item) => item.item_name,
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item.item_reference,
    },
    {
      id: 'ean',
      Header: 'EAN',
      accessor: (item) => item.item_ean13,
    },
    {
      id: 'pcb',
      Header: 'PCB',
      accessor: (item) => item.item_pcb,
    },
    {
      id: 'packageQuantity',
      Header: 'Qtés colis',
      accessor: (item) => item.quantity,
      Cell: ({ value }) => <span className="order-detail__react-table__quantity">{value}</span>,
    },
    {
      id: 'unitQuantity',
      Header: 'Qtés UC',
      accessor: (item) => Number(item.quantity) * Number(item.item_pcb),
      Cell: ({ value }) => <span className="order-detail__react-table__quantity">{value}</span>,
    },
    {
      id: 'packagePrice',
      Header: 'Prix colix HT',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <span className="order-detail__react-table__quantity">
          {formatPrice(Number(value.item_price) * Number(value.item_pcb))} € HT
        </span>
      ),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.final_price,
      Cell: ({ value }) => (
        <span className="order-detail__react-table__total">{formatPrice(Number(value))} € HT</span>
      ),
    },
  ]);

  const convertOrderStatus = (isPreorder, status) => {
    if (!isPreorder) {
      return status;
    }
    switch (status) {
      case ORDER_STATUS_PENDING_VALIDATION:
        return PREORDER_STATUS_PENDING_VALIDATION;
      case ORDER_STATUS_APPROVED:
        return PREORDER_STATUS_APPROVED;
      case ORDER_STATUS_FACTURED:
        return PREORDER_STATUS_FACTURED;
      default:
        return null;
    }
  };

  const orderStatus = order ? convertOrderStatus(order.is_preorder, order.status.name) : null;
  const containersInfo = order && containersDetail ? getContainersInfoById(containersDetail) : null;
  return (
    <>
      {alertIsVisible && orderDetailMailStatus && (
        <Alert
          type="message"
          subject="La confirmation du bon de commande a été envoyée avec succès"
        />
      )}
      {alertIsVisible && !orderDetailMailStatus && (
        <Alert subject="La confirmation du bon de commande n'a pu être envoyée" />
      )}
      {order && (
        <div className="fade-in order-detail__container">
          <div className="order-detail__page-header">
            <OrderBreadCrumb
              type="detail"
              orderId={order.number}
              status={orderStatus}
              detailPreorder={order.is_preorder}
              userType={user.type}
            />
          </div>
          <div className="order-detail__page-content">
            <div className="order-detail__order-number">
              <span>
                Commande n° <strong>{order.number}</strong>
              </span>
              {user.type !== USER_TYPE_CUSTOMER && (
                <span>
                  Client n° <strong>{order.user.client_ext_code}</strong> - {order.user.client_name}
                </span>
              )}
            </div>
            <div className="order-detail__order-info">
              <div className="order-detail__order-info__type">
                <span className="order-detail__order-info__type-icon">
                  {order.is_preorder ? <Icon content="\e941" /> : <Icon content="\e94c" />}
                </span>
                {Object.entries(order.totals_by_type).map((temperature) => (
                  <span>
                    <i className={`${getIconClassByType(temperature[0])} temp-icon`} />
                  </span>
                ))}
              </div>
              <div className="order-detail__order-info__price-container">
                <div className="order-detail__order-info__price">
                  {formatPrice(order.total_price)} € HT
                </div>
                <div className="order-detail__order-info__package">
                  <span>{order.order_items.length}</span>{' '}
                  {order.order_items.length > 1 ? 'références' : 'référence'}
                </div>
                {Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) === 1 && (
                  <div>
                    {containersInfo && (
                      <div className="nbContainer">
                        <span className="nb">{containersInfo.count}</span>
                        <img src={containersInfo.icon} alt={containersInfo.text} />
                        <span className="type">{`(${containersInfo.text})`}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="order-detail__order-info__validator">
                <div className="order-detail__order-info__validator-name">
                  <div>Validateur</div>
                  <div className="order-detail__validator__underline">
                    {order.validator.firstname && order.validator.lastname
                      ? `${order.validator.firstname} ${order.validator.lastname}`
                      : ' - '}
                  </div>
                </div>
                <div className="order-detail__validator__date-container">
                  <div>
                    <div>date de validation</div>
                    <div className="order-detail__validator__underline">
                      {getFormatedDate(order.date_validate)}
                    </div>
                  </div>
                  <div>
                    <div>date de livraison</div>
                    <div className="order-detail__validator__underline">
                      {getFormatedDate(
                        order.is_preorder ? order.pre_order_delivery_date : order.date_delivery
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-detail__order-info__invoice">
                <div className="order-detail__order-info__invoice-title">
                  Facture(s) associé(es)
                </div>
                <div>
                  {order.invoices_order.map((invoice) => (
                    <div className="order-detail__order-info__invoice-number">{invoice.number}</div>
                  ))}
                  {order.invoices_order.length <= 0 && '-'}
                </div>
              </div>
              <div className="order-detail__order-info__actions">
                <span
                  onClick={() => {
                    downloadPdf(order);
                  }}
                >
                  <i className="icon-bdc" /> Télécharger le bon de commande (PDF)
                </span>
                {(user.type === TYPE_ADMIN || user.type === TYPE_COMMERCIAL) && (
                  <span
                    className="order-detail__order-info__btn"
                    onClick={() => {
                      sendOrderDetailMailHandler();
                    }}
                  >
                    <AiOutlineMail />
                    Renvoyer la confirmation du bon de commande par e-mail
                  </span>
                )}
                {user.type === USER_TYPE_CUSTOMER && (
                  <span
                    className="order-list__row-action__renew"
                    onClick={() => renewOrder(order.id)}
                  >
                    <IoReload /> Renouveler
                  </span>
                )}
              </div>
            </div>
            <ReactTable columns={columns} data={order.order_items} />
          </div>
        </div>
      )}
    </>
  );
};

OrderDetail.propTypes = {
  renewOrder: PropTypes.func,
  getContainers: PropTypes.func,
  platform: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const {
    order,
    user,
    platform,
    invoice,
    invoiceItems,
    nbContainers,
    container,
    credit: { creditList, isLoading: creditIsLoading },
  } = state;
  const orderId = (ownProps.match && ownProps.match.params.id) || {};
  const { collections: invoiceCollections } = invoice;
  const { collections: invoiceItemsCollections, isLoading: invoiceIsLoading } = invoiceItems;
  const currentPlatformId = selectCurrentPlatformId(state);
  const { containersDetail } = container;

  return {
    creditList,
    orderList: selectOrderListByFilter(state, { id: orderId, platform: currentPlatformId }),
    pdfIsLoading: order.pdfIsLoading || false,
    invoiceCollections,
    invoiceItemsCollections,
    user: selectCurrentUser(state),
    selectedPlatformId: currentPlatformId,
    isLoading: creditIsLoading || invoiceIsLoading,
    container,
    nbContainers,
    containersDetail,
    platform: platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItemByID: (filter) => dispatch(orderActions.fetchItem(filter, 0, 1)),
  fetchInvoiceOrder: (filter) => dispatch(invoiceActions.fetchInvoiceOrder(filter)),
  renewOrder: (id) => dispatch(orderActions.renewOrder(id, true)),
  downloadPdf: (order) => dispatch(orderActions.downloadOrderFormPdf(order)),
  openModalValidateCredits: (validateCredits) => {
    const modalType = 'validateCredit';
    const modalProps = {
      validateCredits,
      width: '337',
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  updateCreditList: (creditList) => dispatch(creditActions.updateCreditList(creditList)),
  createCredits: (creditList, invoiceFilter) =>
    dispatch(creditActions.createCredits(creditList, invoiceFilter)),
  getContainers: async (setNbContainers, id) => {
    const [containers] = await Promise.all([dispatch(containerActions.getContainers(id))]);
    if (containers) {
      setNbContainers(containers);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
