import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }

  to {
    transform: translateY(100%);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }

  to {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }
`;

export const DropdownSelectWrapper = styled.div`
  position: relative;

  .dropdown-select__search-container {
    position: relative;

    .dropdown-select__fake-input {
      position: absolute;
      top: 0;
      left: 0;
    }

    input[type='text'] {
      width: 350px;
      border-color: #ccc;
      border-radius: 3px;

      &:focus {
        border-color: green;
        box-shadow: 0 0 1px green;
        outline: 2px solid green !important;
      }
    }

    .dropdown-select__reset-search {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: bold;
      cursor: pointer;
    }
  }

  .dropdown-select__panel {
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    bottom: -12px;
    transform: translateY(100%);
    border-radius: 5px;
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.2s linear;
    transition: visibility 0.2s linear;

    .react-table__wrapper {
      max-height: 500px;
      margin: 0;

      .react-table__row-counter {
        display: none;
      }

      table {
        overflow: auto;
        thead {
          z-index: 1;
          position: relative;
        }
        th {
          white-space: nowrap;
        }
        tbody {
          white-space: nowrap;
          .react-table__row__active {
            background: #cccccc;
          }
          tr {
            &:hover {
              background: #efefef;
            }
          }
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 8px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }
    }
  }
`;
