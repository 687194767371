import React from 'react';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cartActions, modalActions, containerActions } from 'actions';
import { containerService } from 'services';
import { PreorderOutstanding } from 'components/Catalog';
import {
  getCartsById,
  getFullPath,
  getPath,
  getValueOfAttribut,
  history,
  numberToPrice,
  store,
} from 'helpers';
import {
  cartConstants,
  catalogConstants,
  platformConstants,
  userConstants,
  clientStatusConstants,
} from 'pages/../constants';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import Sticky from 'components/Core/Sticky/Sticky';
import { percentageRest, float2Digits } from 'components/ContainerizationV2/libraries/functions.js';
import withStyleStickyInCart from './StickyInCart.style';

const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;
const { TYPE_CUSTOMER } = userConstants;
const { DELIVERY_MODE_KEY, KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;
const { STATUS_ACTIVE } = clientStatusConstants;

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const TEMPERATURES = [TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN];

class StickyInCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCguChecked: false,
    };
  }

  getCart() {
    const { current, currentPreorder, cart } = this.props;
    const { collections } = cart;
    const id = this.getRequestedId();

    if (!id) {
      return false;
    }

    if (String(current.id) === String(id) || id === 'current') {
      return current;
    }

    if (String(currentPreorder.id) === String(id) || id === 'current_preorder') {
      return currentPreorder;
    }

    const carts = getCartsById(id, collections);
    if (carts && carts.length) {
      return carts[0];
    }

    return null;
  }

  getIsCurrentCart() {
    if (String(this.getRequestedId()) === 'current') {
      return true;
    }

    const { current } = this.props;

    if (current && current.status) {
      return String(current.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT;
    }
    return false;
  }

  getIsCurrentPreorderCart() {
    if (String(this.getRequestedId()) === 'current_preorder') {
      return true;
    }

    const { currentPreorder } = this.props;

    if (currentPreorder && currentPreorder.status) {
      return String(currentPreorder.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT_PREORDER;
    }
    return false;
  }

  getIsCurrent() {
    return this.getIsCurrentCart() || this.getIsCurrentPreorderCart();
  }

  getRequestedId() {
    const { match, current, currentPreorder } = this.props;
    if (!match || !match.params) {
      return false;
    }
    const { id } = match && match.params;
    if (!id && current) {
      return current.id;
    }
    if (!id && currentPreorder) {
      return currentPreorder.id;
    }
    return id;
  }

  getFrancosByTemperature = (clientFrancos) => {
    const { selectedPlatform } = this.props;
    const francos = clientFrancos
      .filter(
        ({ platform_id: platformId, enable }) =>
          enable && Number(platformId) === Number(selectedPlatform.id)
      )
      .sort((francoA, francoB) => (francoA.date_create < francoB.date_create ? 1 : -1));

    const francoDry = francos.find(({ temperature }) => temperature === TEMPERATURE_DRY);
    const francoFresh = francos.find(({ temperature }) => temperature === TEMPERATURE_FRESH);
    const francoFrozen = francos.find(({ temperature }) => temperature === TEMPERATURE_FROZEN);

    return {
      [TEMPERATURE_DRY]: francoDry,
      [TEMPERATURE_FRESH]: francoFresh,
      [TEMPERATURE_FROZEN]: francoFrozen,
    };
  };

  getFrancoStatus = () => {
    const { current, user } = this.props;
    const { information } = user;
    const clientFrancos = information && information.client && information.client.client_franco;
    const francoStatus = {
      [TEMPERATURE_DRY]: null,
      [TEMPERATURE_FRESH]: null,
      [TEMPERATURE_FROZEN]: null,
      shouldOpenPopUp: false,
    };
    if (current && clientFrancos.length > 0) {
      const francosByTemperature = this.getFrancosByTemperature(clientFrancos);
      TEMPERATURES.forEach((temperature) => {
        const franco = francosByTemperature[temperature];
        const total = current.totals_by_type && current.totals_by_type[temperature];
        if (franco && total && franco.amount > total.total_price) {
          francoStatus[temperature] = {
            status: franco.status,
            amount: franco.amount,
          };
          francoStatus.shouldOpenPopUp = true;
        }
      });
    }

    return francoStatus;
  };

  handleCheck = () => {
    this.setState((prevState) => ({
      isCguChecked: !prevState.isCguChecked,
    }));
  };

  handleClick = (
    isPreorder,
    withDeliveryAmount = false,
    deliveryAmount = 0,
    isPreparationOnly = 0
  ) => {
    const {
      validateCart,
      openModal,
      openModalContainersNotFull,
      current,
      currentPreorder,
      deliveryDates,
      selectedPlatform,
    } = this.props;
    const { isCguChecked } = this.state;
    const match = matchPath(window.location.pathname, {
      path: getPath('cart'),
    });
    const effectiveCart = isPreorder ? currentPreorder : current;
    const { delivery_dates_by_type: deliveryDatesByType, count_items_by_type: countItemsByType } =
      current || {};
    const areDeliveryDatesOk =
      deliveryDatesByType &&
      Object.keys(countItemsByType).every((temperature) => !!deliveryDatesByType[temperature]);
    if (
      isCguChecked &&
      effectiveCart &&
      Number(effectiveCart.total_quantity) > 0 &&
      (isPreorder || areDeliveryDatesOk)
    ) {
      const { attributs } = selectedPlatform;

      const platformHasContainerization = !!Number(
        getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)
      );
      let dataBins = '';
      if (match) {
        const francoStatus = this.getFrancoStatus();
        if (isPreorder || francoStatus.shouldOpenPopUp !== true) {
          if (Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) === 1) {
            const state = store.getState();
            const {
              container: { binsDry, binsReefer },
            } = state;
            dataBins = {
              cart: {
                id: effectiveCart.id,
              },
              bins: [...binsDry, ...binsReefer],
            };
            if (this.checkContainersNotFull()) {
              openModalContainersNotFull({
                effectiveCart,
                deliveryDates,
                withDeliveryAmount,
                deliveryAmount,
                isPreparationOnly,
                platformHasContainerization,
                dataBins,
              });
            } else {
              validateCart(
                effectiveCart,
                deliveryDates,
                withDeliveryAmount,
                deliveryAmount,
                isPreparationOnly,
                platformHasContainerization,
                dataBins
              );
            }
          } else {
            validateCart(
              effectiveCart,
              deliveryDates,
              withDeliveryAmount,
              deliveryAmount,
              isPreparationOnly,
              platformHasContainerization,
              dataBins
            );
          }
        } else {
          openModal(francoStatus, () =>
            validateCart(
              effectiveCart,
              deliveryDates,
              withDeliveryAmount,
              deliveryAmount,
              isPreparationOnly,
              platformHasContainerization,
              dataBins
            )
          );
        }
      } else {
        history.push(getPath('cart').replace(':id', effectiveCart.id));
      }
    }
  };

  checkContainersNotFull() {
    const state = store.getState();
    const {
      container: { packerDry, packerReefer },
    } = state;
    let notFull = false;
    let volumesDry = 0;
    let weightsDry = 0;

    let volumesReefer = 0;
    let weightsReefer = 0;
    if (packerDry !== false) {
      const binsDry = packerDry.getBins();
      if (binsDry.length > 0) {
        binsDry.forEach((b) => {
          const restV = percentageRest(float2Digits(b.getMaxVolume()), b.getItemsVolume());
          const restW = percentageRest(float2Digits(b.getMaxWeight()), b.getItemsWeight());
          volumesDry += restV;
          weightsDry += restW;
        });
      }
    }
    if (packerReefer !== false) {
      const binsReffer = packerReefer.getBins();
      if (binsReffer.length > 0) {
        binsReffer.forEach((b) => {
          const restV = percentageRest(float2Digits(b.getMaxVolume()), b.getItemsVolume());
          const restW = percentageRest(float2Digits(b.getMaxWeight()), b.getItemsWeight());
          volumesReefer += restV;
          weightsReefer += restW;
        });
      }
    }
    const TotalContainersVolume = volumesDry + volumesReefer;
    const TotalContainersWeight = weightsDry + weightsReefer;

    if (TotalContainersVolume > 20 && TotalContainersWeight > 20) {
      notFull = true;
    }
    return notFull;
  }

  goToCart(id) {
    if (id) {
      history.push(getPath('cart').replace(':id', id).replace('/:temperature?', ''));
    }
  }

  render() {
    const {
      className,
      current,
      user,
      selectedPlatform,
      cookie,
      loggedAs,
      cart: { isLoading },
      currentPreorder,
      saveBinsInCart,
      openModalContainersNotFull,
    } = this.props;

    const matchCart = matchPath(window.location.pathname, {
      path: getPath('cart'),
    });
    const matchFirstConnection = matchPath(window.location.pathname, {
      path: getPath('welcome'),
    });

    const cart = this.getCart() || {};
    const { information } = user || {};
    const { type, client, username } = information || {};
    const { terms } = client || {};
    const { isCguChecked } = this.state;
    const { attributs } = selectedPlatform;

    const effectiveTerm =
      (terms || []).find((term) => Number(term.platform_id) === Number(selectedPlatform.id)) || {};

    const isCurrentPreorderCart = this.getIsCurrentPreorderCart();
    const isCurrent = this.getIsCurrent();

    const { user: cartUser } = cart;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const {
      client: { client_delivery_modes: clientDeliveryModes },
    } = cartUser || { client: { client_delivery_modes: [] } };
    const { delivery_mode: clientDeliveryMode } =
      (clientDeliveryModes || []).find(
        (deliveryMode) => Number(deliveryMode.platform.id) === Number(selectedPlatform.id)
      ) || {};
    const deliveryAmount =
      clientDeliveryMode || cart.delivery_mode
        ? Math.min(cart.total_price * 0.02, 80)
        : Math.min(cart.total_price * (0.04 + 0.02), 200);
    const { delivery_dates_by_type: deliveryDatesByType, count_items_by_type: countItemsByType } =
      current || {};
    const areDeliveryDatesOk = isCurrent
      ? deliveryDatesByType &&
        Object.keys(countItemsByType).every((temperature) => !!deliveryDatesByType[temperature])
      : true;
    // const userCookies = checkCookie(username);

    const clientStatus = ((client && client.client_status) || []).find(
      (status) => status.platform_id && Number(status.platform_id) === Number(selectedPlatform.id)
    );
    const { status_preorder: statusPreorder, status_catalog: statusCatalog } = clientStatus || {};
    const allError = () => {
      return (!(isCurrentPreorderCart || areDeliveryDatesOk) && isCurrent) || !isCguChecked;
    };
    return (
      <>
        {(type === TYPE_CUSTOMER ||
          [CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(cart.status)) && (
          <div className={className}>
            <Sticky className="stickyInCart">
              {matchCart && (
                <div className="cart-footer">
                  {/* <Containerization /> */}
                  {type === TYPE_CUSTOMER && isCurrent ? (
                    <div className="home__section__footer">
                      <div className="footer__message-block">
                        <label htmlFor="checkCGV" className="container">
                          <input
                            name="checkCGV"
                            type="checkbox"
                            readOnly
                            checked={isCguChecked ? 'checked' : ''}
                          />
                          <span className="checkmark" onClick={this.handleCheck} />
                        </label>
                        <span className="permanent-cart__text footer">
                          <span>J&lsquo;accepte les </span>
                          <a
                            className="home__section__footer-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFullPath(effectiveTerm.full_filename)}
                          >
                            <span className="underline">conditions générales de vente</span>
                          </a>
                          <span className="red">*</span>
                        </span>
                      </div>
                      <div className="permanent-cart__button-container">
                        <span className="permanent-cart__Total--TTC permanent-cart__Total">
                          <span className="total">Total :</span>
                          <span>
                            {`${numberToPrice(
                              Number(cart.total_price) +
                                Number(platformDeliveryMode ? deliveryAmount : 0) || 0
                            )} HT`}
                          </span>
                        </span>
                        <span className="permanent-cart__button-span">
                          {isLoading ? (
                            <div className="permanent-cart__spinner">
                              <Spinner />
                            </div>
                          ) : (
                            <ButtonPrimary
                              disabled={
                                !isCguChecked ||
                                Number(cart.total_quantity) === 0 ||
                                !(isCurrentPreorderCart || areDeliveryDatesOk)
                              }
                              onClick={() => {
                                this.handleClick(
                                  isCurrentPreorderCart,
                                  platformDeliveryMode,
                                  deliveryAmount,
                                  cart.delivery_mode
                                );
                              }}
                            >
                              <div className="permanent-cart__button">
                                <Icon content="\e912" />
                                <span className="button-icon-label">VALIDER</span>
                              </div>
                            </ButtonPrimary>
                          )}
                          {((!(isCurrentPreorderCart || areDeliveryDatesOk) && isCurrent) ||
                            !isCguChecked) && (
                            <div className="home__section__footer-errors">
                              {!(isCurrentPreorderCart || areDeliveryDatesOk) && isCurrent && (
                                <span className="alert-bloc__label">
                                  Veuillez choisir une date de livraison
                                </span>
                              )}
                              {!isCguChecked && (
                                <span className="alert-bloc__label">Veuillez accepter les CGV</span>
                              )}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {[CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(
                        cart.status
                      ) && (
                        <div className="cart-footer-commercial">
                          <span className="bold">{`Total : ${numberToPrice(
                            Number(cart.total_price) +
                              Number(platformDeliveryMode ? deliveryAmount : 0) || 0
                          )} HT`}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Sticky>
          </div>
        )}
      </>
    );
  }
}

StickyInCart.propTypes = {
  className: PropTypes.string,
  cart: PropTypes.object,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  deliveryDates: PropTypes.object,
  validateCart: PropTypes.func,
  selectedPlatform: PropTypes.object,
  cookie: PropTypes.string,
  openModal: PropTypes.func,
  loggedAs: PropTypes.string,
  openModalContainersNotFull: PropTypes.func,
  saveBinsInCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    user,
    authentication,
    cart,
    platform: { selectedId, platforms },
  } = state;
  const { current, currentPreorder } = cart;
  const { cookie, loggedAs } = authentication;
  const selectedPlatform = platforms.find(({ id }) => Number(id) === Number(selectedId)) || {};
  return {
    cart,
    current,
    currentPreorder,
    deliveryDates: state.cart.deliveryDates,
    user,
    selectedPlatform,
    cookie,
    loggedAs,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModal: (francoStatus, validateCart) => {
    const modalType = 'francoAlert';
    const modalProps = {
      modalHeader: '',
      francoStatus,
      validateCart,
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  openModalContainersNotFull: (cart) => {
    const modalType = 'containersNotFull';
    const modalProps = {
      modalHeader: '',
      cart,
      width: '670',
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  validateCart: (
    cart,
    deliveryDates,
    withDeliveryAmount = false,
    deliveryAmount = 0,
    isPreparationOnly = 0,
    platformHasContainerization = false,
    dataBins
  ) => {
    if (platformHasContainerization) {
      const resultSave = new Promise((resolve) => {
        resolve(containerService.saveContainers(dataBins));
      });
      if (resultSave) {
        resultSave.then((response) => {
          if (response && response.status === true) {
            dispatch(
              cartActions.validateCart(
                cart,
                deliveryDates,
                withDeliveryAmount,
                deliveryAmount,
                isPreparationOnly,
                platformHasContainerization
              )
            );
            dispatch(containerActions.deleteContainers());
          }
        });
      }
    } else {
      dispatch(
        cartActions.validateCart(
          cart,
          deliveryDates,
          withDeliveryAmount,
          deliveryAmount,
          isPreparationOnly,
          platformHasContainerization
        )
      );
    }
  },
  openModalContainersOptimal: () =>
    dispatch(modalActions.open('containersOptimal', { width: '670' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyleStickyInCart(StickyInCart));
