import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'ag-grid-enterprise';
import moment from 'moment';
import { analytics } from 'utils/analytics/tagmanager';
import { productCardActions } from 'actions/ProductCard';
import CatalogControls from 'components/CatalogControlsV3/CatalogControls';
import Spinner from 'components/Core/Spinner/Spinner';
import ProductCard from 'components/ProductCard';
import {
  selectItemsFromCatalog,
  selectCurrentProduct,
  selectTopSales,
  selectIsLoadingFromCatalog,
  selectNewsPromotionsFavoritesCountersProducts,
} from 'selectors/product';
import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';
import { aggridConstants } from 'constants/Aggrid.constants';
import { selectCategories } from 'selectors/category';
import { selectAggridSearchValue } from 'selectors/search';
import { productActions } from 'actions/Product';
import { selectAggridFilter, selectAggridViewType } from 'selectors/aggridFilter';
import { selectMessageList } from 'selectors/message';
import { toastActions } from 'actions/Toast';
import { catalogActions } from 'actions';
import { doFiltersPass } from 'helpers/Filter';
import { getValueOfAttribut, store } from 'helpers';
import { selectCurrentPageContext, selectCatalogCollectionCountByFilter } from 'selectors/catalog';
import { selectCurrentUser } from 'selectors/user';
import { toastConstants } from 'constants/Toast.constants';
import { catalogService } from 'services';
import { messageConstants, platformConstants } from '../../constants';
import {
  availabilityCellRenderer,
  priceCellRenderer,
  packagePriceCellRenderer,
  imageCellRenderer,
} from './cellRenderers';
import AssortmentFilter from './AssortmentFilter';
import AggridTableView from './TableView/AggridTableView';
import AggridListView from './ListView/AggridListView';

class Aggrid extends Component {
  constructor(props) {
    super(props);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.counter = 0;
    this.state = {
      totalRowCount: 0,
      rowCount: 0,
      counters: { new: 0, promotions: 0, favorites: 0 },
      filterType: aggridConstants.FILTER_NO_FILTER,
    };
  }

  componentDidMount() {
    const { messageList, pushToast, fetchProductsTotalCount, platformId, user } = this.props;

    const currentMessageList = messageList
      .flat()
      .filter((item) => moment(item.date_end) >= moment());

    currentMessageList.map((item) => {
      if (item.type === messageConstants.TYPE_LOCKED) {
        if (user && user.client.locked) {
          return pushToast({
            type: 'warning',
            title: item.subject,
            message: item.content,
            duration: item.duration,
          });
        }

        return null;
      }

      return pushToast({
        type: toastConstants.TYPE_INFO,
        title: item.subject,
        message: item.content,
        duration: item.duration,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi && prevProps.categoryDisplay !== this.props.categoryDisplay) {
      this.gridColumnApi.setColumnVisible('department', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('zone', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('aisle', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('category', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('sub_category', this.props.categoryDisplay);
    }
  }

  getFilterModel = (aggridFilter, filterModelSource) => {
    let filterModel = filterModelSource;
    if (aggridFilter && aggridFilter.categoryId) {
      if (Array.isArray(aggridFilter.categoryId)) {
        const baseArr = [];
        const fn = (obj) => {
          obj.forEach((el) => {
            if (el.parentId) {
              baseArr.push(parseInt(el.parentId, 10));
            }
            baseArr.push(el.id);
            if (el.children.length > 0) {
              fn(el.children);
            }
          });
        };
        fn(aggridFilter.categoryId);

        filterModel = {
          ...filterModel,
          ...{
            category: {
              filterType: 'array',
              type: 'equals',
              filter: [...new Set(baseArr)],
            },
          },
        };
      } else {
        filterModel = {
          ...filterModel,
          ...{
            category: {
              filterType: 'integer',
              type: 'equals',
              filter: aggridFilter.categoryId,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.quickList) {
      const quickListArray = aggridFilter.quickList;
      let responseNew = 0;
      let responsePromotion = 0;
      let responseFavorite = 0;
      // NEW
      if (quickListArray.includes('new')) {
        responseNew = 1;
        filterModel = {
          ...filterModel,
          ...{
            new: {
              filterType: 'boolean',
              type: 'equals',
              filter: responseNew,
            },
          },
        };
      }

      // PROMOTION
      if (quickListArray.includes('promotion')) {
        responsePromotion = 1;
        filterModel = {
          ...filterModel,
          ...{
            promotion: {
              filterType: 'boolean',
              type: 'equals',
              filter: responsePromotion,
            },
          },
        };
      }
      // FAVORITE
      if (quickListArray.includes('favorite')) {
        responseFavorite = 1;
        filterModel = {
          ...filterModel,
          ...{
            favorite: {
              filterType: 'boolean',
              type: 'equals',
              filter: responseFavorite,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.availabilityList && aggridFilter.availabilityList.length > 0) {
      const availabilityListArray = aggridFilter.availabilityList;
      if (availabilityListArray.length === 1 && availabilityListArray.includes('AVAILABLE')) {
        filterModel = {
          ...filterModel,
          ...{
            availabilityList: {
              filterType: 'number',
              type: 'greaterThan',
              filter: 0,
            },
          },
        };
      }
      if (availabilityListArray.length === 1 && availabilityListArray.includes('UNAVAILABLE')) {
        filterModel = {
          ...filterModel,
          ...{
            availabilityList: {
              filterType: 'number',
              type: 'lessThan',
              filter: 0,
            },
          },
        };
      }
    }
    if (
      aggridFilter &&
      aggridFilter.storeAvailabilityList &&
      aggridFilter.storeAvailabilityList.length > 0
    ) {
      const storeAvailabilityListArray = aggridFilter.storeAvailabilityList;
      if (
        storeAvailabilityListArray.length === 1 &&
        storeAvailabilityListArray.includes('STORE_AVAILABLE')
      ) {
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'number',
              type: 'greaterThan',
              filter: 0,
            },
          },
        };
      }
      if (
        storeAvailabilityListArray.length === 1 &&
        storeAvailabilityListArray.includes('STORE_UNAVAILABLE')
      ) {
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'number',
              type: 'lessThan',
              filter: 0,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.temperatureList && aggridFilter.temperatureList.length > 0) {
      filterModel = {
        ...filterModel,
        ...{
          temperatureList: {
            filterType: 'array',
            type: 'equals',
            filter: aggridFilter.temperatureList,
          },
        },
      };
    }
    return filterModel;
  };

  getDataSource = (refGridApi) => {
    return {
      getRows: (params) => {
        const state = store.getState();
        const { platform, aggridFilter, aggridSearchBar } = state;
        const {
          startRow,
          endRow,
          filterModel: filterModelSource,
          sortModel: sortModelSource,
        } = params;
        // define default filterModel
        const filterModel = this.getFilterModel(aggridFilter, filterModelSource);
        let sortModel = sortModelSource;
        if (sortModel.length === 0) {
          // define default sortModel
          sortModel = [
            { sort: 'asc', colId: 'category_name' },
            { sort: 'asc', colId: 'name' },
          ];
        }
        refGridApi.showLoadingOverlay();
        const parameters = {
          platform: platform.selectedId, // need to put platformId
          clientId: null, // careful to this groupItem
          groupItem: [], // careful to this groupItem
        };

        let keywords;
        if (
          aggridSearchBar &&
          aggridSearchBar.searchValue &&
          aggridSearchBar.searchValue.length > 2
        ) {
          keywords = aggridSearchBar.searchValue;
        }
        const defaultLimit = 100;

        catalogService
          .fetchOptimizedItemsV3(
            parameters,
            startRow,
            defaultLimit,
            filterModel,
            sortModel,
            keywords
          )
          .then((response) => {
            let lastRow = -1;
            if (response.rowCount <= params.endRow) {
              lastRow = response.rowCount;
            }

            let dataProducts;
            let totalRowCount;
            let rowCount;
            totalRowCount = response.rowCount;
            rowCount = params.endRow;

            if ('promotion' in filterModel) {
              dataProducts = response.rowData.filter((item) => item.promotion.display_as_promotion);
              if (dataProducts.length === 0) lastRow = dataProducts.length;
              if (dataProducts.length < defaultLimit) lastRow = dataProducts.length;
              totalRowCount = response.rowCount - (response.rowData.length - dataProducts.length);
            } else {
              dataProducts = response.rowData;
            }

            if (params.endRow >= totalRowCount) {
              rowCount = totalRowCount;
            }

            if (totalRowCount === 0) refGridApi.showNoRowsOverlay();
            if (totalRowCount > 0) refGridApi.hideOverlay();
            this.setState({ rowCount });
            this.setState({ totalRowCount });
            if (response.counters) {
              this.setState({ counters: response.counters });
              const { setCountersFilters } = this.props;
              setCountersFilters(response.counters.favorites);
            }
            params.successCallback(dataProducts, lastRow);
          })
          .catch((error) => {
            params.failCallback();
          });
      },
    };
  };

  onGridReady = (params) => {
    const { currentFilters, fetchProducts, platformId, setCountersFilters } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const state = store.getState();
    const currentPlatform = selectCurrentPlatform(state);
    const { attributs } = currentPlatform;
    const shouldDisplayStock = !Number(
      getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
    );

    if (!shouldDisplayStock) {
      this.gridColumnApi.setColumnVisible('availability', false);
    }

    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };

    const datasource = this.getDataSource(this.gridApi);
    this.gridApi.setDatasource(datasource);
  };

  onSelectionChanged = (event) => {
    const { selectedPlatform } = this.props;
    if (aggridConstants.FIXED_COLUMNS.includes(event.column.colId)) {
      return;
    }

    event.node.setSelected(true);
    const { chooseCurrentGridProduct } = this.props;
    const currentProduct = this.gridApi.getSelectedRows();
    chooseCurrentGridProduct(currentProduct[0], selectedPlatform);
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  externalFilterChanged = (newValue) => {
    if (this.gridApi) {
      this.setState({ filterType: newValue }, () => {
        this.gridApi.onFilterChanged();
      });
    }
  };

  isExternalFilterPresent = () => this.state.filterType !== aggridConstants.FILTER_NO_FILTER;

  doesExternalFilterPass = (node) => {
    const { filterType } = this.state;
    const { topSales } = this.props;

    if (!filterType) {
      return true;
    }

    return doFiltersPass(
      filterType.quickList,
      filterType.selection,
      filterType.category,
      filterType.availabilityList,
      filterType.storeAvailabilityList,
      filterType.temperatureList,
      node.data,
      topSales.items
    );
  };

  navigateToNextCell = (params) => {
    const { chooseCurrentGridProduct, selectedPlatform } = this.props;

    let previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell + 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell - 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
        return suggestedNextCell;
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        return null;
    }
  };

  render() {
    const { rowCount } = this.state;
    const { counters } = this.state;
    const { totalRowCount } = this.state;
    const state = store.getState();
    const { favorites } = state.product;
    const counters2 = favorites.itemsCount;
    const {
      items,
      topSales,
      selectedPlatform,
      currentProductCard,
      close,
      viewType,
      pageContext,
    } = this.props;
    let itemsWithTopSales = null;
    if (items.length && topSales.items && topSales.items.length) {
      itemsWithTopSales = items.map((item) => ({
        ...item,
        topPosition: topSales.items.includes(item.id) ? topSales.items.indexOf(item.id) + 1 : null,
      }));
    }

    const frameworkComponents = {
      availabilityCellRenderer,
      priceCellRenderer,
      packagePriceCellRenderer,
      imageCellRenderer,
      AssortmentFilter,
      Spinner,
    };
    const aggridProps = {
      rowData: itemsWithTopSales || items,
      onGridReady: this.onGridReady,
      onFirstDataRendered: this.onFirstDataRendered,
      onCellClicked: this.onSelectionChanged,
      onFilterChanged: this.onFilterChanged,
      frameworkComponents,
      doesExternalFilterPass: this.doesExternalFilterPass,
      isExternalFilterPresent: this.isExternalFilterPresent,
      handleFilter: this.externalFilterChanged,
      currentProduct: currentProductCard,
      close,
      columnApi: this.gridColumnApi,
      gridApi: this.gridApi,
      rowCount,
      totalRowCount,
      navigateToNextCell: this.navigateToNextCell,
      // searchValue: selectAggridSearchValue(this.state),
      searchValue: this.props.searchValue,
      viewType,
      pageContext,
      counters,
      counters2,
    };

    return (
      <>
        {viewType && currentProductCard && (
          <div className="ProductCardContainer">
            <ProductCard
              product={currentProductCard}
              close={close}
              handleFilter={this.externalFilterChanged}
            />
          </div>
        )}
        {items && (
          <CatalogControls
            selectedPlatform={selectedPlatform}
            handleFilter={this.externalFilterChanged}
            rowData={items}
            viewType={viewType}
            counters={counters}
            counters2={counters2}
          />
        )}

        {viewType && <AggridTableView {...aggridProps} source="AggridTableView" />}
        {!viewType && <AggridListView {...aggridProps} />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const platformId = selectCurrentPlatformId(state);

  return {
    platformId,
    selectedPlatform: selectCurrentPlatform(state),
    items: selectItemsFromCatalog(state, { aggrid: true }),
    isItemsLoading: selectIsLoadingFromCatalog(state, { aggrid: true }),
    categories: selectCategories(state),
    searchValue: selectAggridSearchValue(state),
    currentProduct: selectCurrentProduct(state),
    currentFilters: selectAggridFilter(state),
    counters: selectNewsPromotionsFavoritesCountersProducts(state),
    viewType: selectAggridViewType(state),
    messageList: selectMessageList(state, { platform: selectCurrentPlatformId(state) }),
    topSales: selectTopSales(state),
    user: selectCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCountersFilters: (counters) => dispatch(productActions.setCountersFilters(counters)),
  fetchProducts: (platformId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 50000)),
  chooseCurrentGridProduct: (product, platform) => {
    analytics.productDetails(product);
    dispatch(productCardActions.chooseCurrentProduct(product, platform));
  },
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
  setToastList: (toastList) => dispatch(toastActions.setToastList(toastList)),
  fetchTopSalesItemsIdList: (platformId, startDate, endDate, limit) =>
    dispatch(catalogActions.fetchTopSalesItemIdList(platformId, startDate, endDate, limit)),
});

Aggrid.propTypes = {
  platformId: PropTypes.number,
  selectedPlatform: PropTypes.object,
  fetchProducts: PropTypes.func,
  chooseCurrentGridProduct: PropTypes.func,
  items: PropTypes.array,
  searchValue: PropTypes.string,
  categoryDisplay: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Aggrid);
