import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userConstants, filterConstants } from 'pages/../constants';
import FormSection from './FormSection';

const { SECTIONS } = filterConstants;
const { TYPE_ADMIN } = userConstants;
const { clientStatusCatalog, clientStatusPreorder, clientStatus } = SECTIONS;
const COMMERCIAL_SECTIONS = [clientStatusCatalog, clientStatusPreorder];
const ADMIN_SECTIONS = [clientStatus, ...COMMERCIAL_SECTIONS];

const ClientsFilterForm = ({ clientsFilter, updateFilter, user }) =>
  (user.type === TYPE_ADMIN
    ? ADMIN_SECTIONS
    : COMMERCIAL_SECTIONS
  ).map(({ name, sectionKey, options }) => (
    <FormSection
      name={name}
      sectionKey={sectionKey}
      options={options}
      selectedValues={clientsFilter[sectionKey] || []}
      updateFilter={updateFilter}
    />
  ));

ClientsFilterForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  clientsFilter: PropTypes.func,
  updateFilter: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    user: { information: userInformation },
  } = state;
  return {
    user: userInformation,
  };
};

export default connect(mapStateToProps)(ClientsFilterForm);
