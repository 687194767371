import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from 'actions';

import PasswordChangeForm from 'components/Auth/PasswordRecovery/PasswordChangeForm';
import PasswordChanged from 'components/Auth/PasswordRecovery/PasswordChanged';

const IS_FIRST_LOGIN_PARAM_KEY = 'isfirstLogin';

const isFirstLogin = !!window.location.href.match(`[?&]${IS_FIRST_LOGIN_PARAM_KEY}=(.+)`);

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  keyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { newPassword, newPasswordConfirmation } = this.state;
    const { userChangePassword, match } = this.props;
    const { confirmationToken } = match.params;
    if (newPassword && newPasswordConfirmation && newPassword === newPasswordConfirmation) {
      userChangePassword(newPassword, newPasswordConfirmation, confirmationToken, isFirstLogin);
    }
  }

  render() {
    const { newPassword, newPasswordConfirmation } = this.state;
    const { className, isSubmittingNewPassword, error, hasChangedPassword } = this.props;

    return hasChangedPassword ? (
      <PasswordChanged className={className} />
    ) : (
      <PasswordChangeForm
        className={className}
        isSubmittingNewPassword={isSubmittingNewPassword}
        error={error}
        newPassword={newPassword}
        newPasswordConfirmation={newPasswordConfirmation}
        isFirstLogin={isFirstLogin}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        onKeyPress={this.keyPress}
      />
    );
  }
}

function mapStateToProps(state) {
  const { error, isSubmittingNewPassword, hasChangedPassword } = state.authentication;
  return {
    isSubmittingNewPassword,
    hasChangedPassword,
    error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  userChangePassword: (newPassword, newPasswordConfirmation, confirmationToken, firstLogin) => {
    dispatch(
      userActions.changePassword(
        newPassword,
        newPasswordConfirmation,
        confirmationToken,
        firstLogin
      )
    );
  },
});

PasswordChange.propTypes = {
  className: PropTypes.string,
  isSubmittingNewPassword: PropTypes.bool,
  hasChangedPassword: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      confirmationToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  userChangePassword: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
