import React from 'react';
import styled from 'styled-components';

import './CatalogControls.scss';

const Wrapper = styled.div`
  padding: 3px 10px;
  display: flex;
  align-items: center;
`;

const ActiveWrapper = styled.div`
  background: ${(props) => props.activeBg}!important;
`;

function TabControl(props) {
  const handleClick = () => {
    props.handleTabClick(props.handleTabParameter);
  };

  if (!props.handleTabClick) {
    return <div className="catalog-controls__tab">{props.children}</div>;
  }

  return (
    <ActiveWrapper
      className="catalog-controls__tab"
      onClick={handleClick}
      activeBg={props.isActive ? '#EAEDF3' : '#FFF'}
    >
      <Wrapper>{props.children}</Wrapper>
    </ActiveWrapper>
  );
}

export default TabControl;
