import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { aggridSearchBarActions } from 'actions/AggridSearchBar';
import { searchActions } from 'actions/Search';
import Icon from 'components/Icon/Icon';

const SearchBar = (props) => (
  <>
    <span>
      <Icon content={'\\e93e'} />
    </span>
    <div className="aggrid-searchbar__wrapper">
      <input
        type="text"
        placeholder="Rechercher un produit"
        onChange={props.onChange}
        onBlur={(e) => props.onBlur(e, props.infosLogClient)}
        value={props.searchValue === null ? '' : props.searchValue}
        className="search-box"
      />
    </div>
  </>
);

const mapStateToProps = (state) => {
  const {
    aggridSearchBar,
    user,
    platform: { selectedId: platformId },
  } = state;
  const {
    information: { id, type, username },
  } = user;
  const infosLog = {
    id: user && id,
    username,
    type: user && type,
    platformId,
  };
  return {
    searchValue: aggridSearchBar && aggridSearchBar.searchValue,
    infosLogClient: infosLog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (event) => {
    dispatch(aggridSearchBarActions.searchForProduct(event.currentTarget.value));
  },
  onBlur: (event, obj) => {
    const valSearch = event.currentTarget.value;
    const objLog = {
      search_value: valSearch,
      username: obj.username,
      type: obj.type,
      platform: { id: obj.platformId },
    };
    if (valSearch.length > 3) {
      dispatch(searchActions.searchLogs(objLog));
    }
  },
});

SearchBar.propTypes = {
  searchValue: PropTypes.string,
  infosLogClient: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
