import styled from 'styled-components';

export default (Component) => styled(Component)`
  width: 100%;
  .progress-bar {
    padding: 3px 0;
    .progress-bar-fill {
      width: ${({ percent }) => `${percent}%`};
      height: 9px;
      background-color: #135589;
      transition: width 0.5s ease-in-out;
    }
  }
`;
