import React from 'react';
import PropTypes from 'prop-types';
import { convertListStatus, getPath } from 'helpers';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { userService } from 'services';
import { userConstants } from '../../constants';

const { TYPE_COMMERCIAL, TYPE_CUSTOMER, TYPE_ADMIN } = userConstants;

const getUrl = (path) => {
  if (path === '/order/:status?') {
    return getPath('orderList').replace(':status?', '');
  }
  if (path === '/preorder/:status?') {
    return getPath('preorderList').replace(':status?', '');
  }
  return '';
};

const InvoiceBreadCrumb = ({
  type,
  userType,
  user,
  matchPath,
  status,
  invoiceId,
  detailPreorder,
}) => {
  const homepagePath =
    userType === TYPE_CUSTOMER ? userService.getHomepagePath(user) : getPath('dashboardAdmin');
  const url = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
  ];
  const currentPage =
    userType === TYPE_CUSTOMER
      ? 'Historique de factures'
      : [TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType) && 'Suivi de factures';
  const urlStatus = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
    {
      text: userType === TYPE_CUSTOMER ? 'Historique de factures' : 'Suivi de factures',
      href: getUrl(matchPath),
    },
  ];
  const currentPageStatus = convertListStatus(status, userType);
  const urlDetail = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
    {
      text: userType === TYPE_CUSTOMER ? 'Historique de factures' : 'Suivi de commandes',
      href:
        userType === TYPE_CUSTOMER
          ? getPath('invoiceList')
          : getPath(detailPreorder ? 'preorderList' : 'orderList').replace(
              ':status?',
              detailPreorder ? 'PREORDER_STATUS_APPROVED' : 'STATUS_APPROVED'
            ),
    },
  ];
  if ([TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType)) {
    urlDetail.push({
      text: !detailPreorder ? 'Commandes (permanent)' : detailPreorder ? 'Précommandes' : '',
      href: getPath(detailPreorder ? 'preorderList' : 'orderList').replace(':status?', ''),
    });
  }
  urlDetail.push({
    text: convertListStatus(status, userType),
    href: getPath(detailPreorder ? 'preorderList' : 'orderList').replace(':status?', status),
  });

  const currentPageDetail = `Facture N° ${invoiceId}`;

  return (
    <div>
      {type === 'list' && <BreadCrumb urlTab={url} currentPage={currentPage} />}
      {type === 'list_status' && <BreadCrumb urlTab={urlStatus} currentPage={currentPageStatus} />}
      {type === 'detail' && <BreadCrumb urlTab={urlDetail} currentPage={currentPageDetail} />}
    </div>
  );
};

InvoiceBreadCrumb.propTypes = {
  type: PropTypes.string,
  userType: PropTypes.string,
  user: PropTypes.object,
  matchPath: PropTypes.object,
  status: PropTypes.string,
};

export default InvoiceBreadCrumb;
