export const messageConstants = {
  FETCH_REQUEST: 'MESSAGE_FETCH_REQUEST',
  FETCH_SUCCESS: 'MESSAGE_FETCH_SUCCESS',
  FETCH_FAILURE: 'MESSAGE_FETCH_FAILURE',

  FETCH_COUNT_REQUEST: 'MESSAGE_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'MESSAGE_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'MESSAGE_COUNT_FETCH_FAILURE',

  UPDATE_REQUEST: 'MESSAGE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'MESSAGE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MESSAGE_UPDATE_FAILURE',

  DELETE_REQUEST: 'MESSAGE_DELETE_REQUEST',
  DELETE_SUCCESS: 'MESSAGE_DELETE_SUCCESS',
  DELETE_FAILURE: 'MESSAGE_DELETE_FAILURE',

  CREATE_REQUEST: 'MESSAGE_CREATE_REQUEST',
  CREATE_SUCCESS: 'MESSAGE_CREATE_SUCCESS',
  CREATE_FAILURE: 'MESSAGE_CREATE_FAILURE',

  TYPE_LOCKED: 'LOCKED',
};
