import isEqual from 'lodash.isequal';
import { invoiceConstants } from '../constants';

export function invoice(
  state = {
    collections: [],
    collectionsCount: [],
    current: { item: null, isLoading: false },
    pdfIsLoading: false,
  },
  action
) {
  if (!Object.keys(invoiceConstants).find((key) => invoiceConstants[key] === action.type)) {
    return state;
  }

  const { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));
  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }
  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case invoiceConstants.FETCH_ONE_REQUEST: {
      return {
        ...state,
        current: {
          item: null,
          isLoading: true,
        },
      };
    }
    case invoiceConstants.FETCH_ONE_SUCCESS: {
      return {
        ...state,
        current: {
          item: action.invoice,
          isLoading: false,
        },
      };
    }
    case invoiceConstants.FETCH_ONE_FAILURE:
      return state;
    case invoiceConstants.FETCH_REQUEST: {
      const newCollection = { ...collection, isLoading: true };
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case invoiceConstants.FETCH_SUCCESS: {
      const newCollection = { ...collection, items: action.items, isLoading: false };
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case invoiceConstants.FETCH_FAILURE:
      return state;
    case invoiceConstants.FETCH_COUNT_REQUEST: {
      const newCollectionCount = { ...collectionCount, isLoading: true };
      const newCollectionsCount = [
        ...collectionsCount.filter((c) => !isEqual(newCollectionCount.filter, c.filter)),
        newCollectionCount,
      ];
      return {
        ...state,
        collectionsCount: newCollectionsCount,
      };
    }
    case invoiceConstants.FETCH_COUNT_SUCCESS: {
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      };
      const newCollectionsCount = [
        ...collectionsCount.filter((c) => !isEqual(newCollectionCount.filter, c.filter)),
        newCollectionCount,
      ];
      return {
        ...state,
        collectionsCount: newCollectionsCount,
      };
    }
    case invoiceConstants.FETCH_COUNT_FAILURE:
      return state;
    case invoiceConstants.DOWNLOAD_ORDER_FORM_REQUEST:
      return {
        ...state,
        pdfIsLoading: true,
        idInvoiceDownload: action.order.id,
      };
    case invoiceConstants.DOWNLOAD_ORDER_FORM_SUCCESS:
      return {
        ...state,
        pdfIsLoading: false,
      };
    case invoiceConstants.DOWNLOAD_ORDER_FORM_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
