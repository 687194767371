import styled from 'styled-components';

export default (Component) => styled(Component)`
  background: #f5f5f5;
  .page-settings {
    padding-top: 22px;
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 70px;
    .page-settings__section-label {
      font-size: 16px;
      color: #01366b;
      font-weight: bold;
      line-height: 19px;
      padding-left: 20px;
    }
    h3 {
      margin-bottom: 6px;
      color: #01366b;
    }
    .current_planning {
      padding: 30px 0;
      table {
        width: 100%;
        border-collapse: collapse;
        background: #fff;
        td,
        th {
          border: 1px solid rgba(1, 54, 107, 0.2);
          height: 40px;
          text-align: center;
          width: calc(100% / 3);
        }
        th {
          background: #dbdee7;
          border-right: 1px solid #fff;
          color: #01366b;
          font-size: 14px;
          letter-spacing: 0.12px;
          line-height: 19px;
          font-weight: bold;
        }
        td {
          color: #787878;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          padding: 16px;
        }
      }
    }
    .edit__preorder {
      & > div {
        width: calc(50% - 10px);
        display: inline-block;
        vertical-align: top;
        border: 1px solid rgba(1, 54, 107, 0.2);
        min-height: 244px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      h3 {
        margin-bottom: 20px;
      }
      .edit__preoder--bloc {
        background: #fff;
      }
      .entete {
        h3 {
          letter-spacing: 0.12px;
          line-height: 19px;
          font-weight: bold;
          background: #dbdee7;
          color: #01366b;
          font-size: 14px;
          padding: 10px 20px;
          border-bottom: 1px solid rgba(1, 54, 107, 0.2);
          margin-bottom: 0;
        }
      }
      .content--bloc {
        padding: 15px 18px;
        h4,
        span {
          color: #01366b;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
        }
        .content-date {
          color: #787878;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.15px;
          line-height: 15px;
          text-transform: capitalize;
        }
        input {
          border-bottom: 1px solid rgba(120, 120, 120, 0.6);
          border: 0;
          color: #4a4a4a;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          outline: none;
          &[type='number'] {
            width: 35px;
            border-bottom: 1px solid rgba(120, 120, 120, 0.6);
            text-align: center;
            margin-left: 11px;
            margin-right: 11px;
            padding-bottom: 5px;
          }
        }
        .ligne-bloc {
          margin-bottom: 20px;
          h4 {
            margin-bottom: 15px;
          }
          .react-datepicker-wrapper {
            border-bottom: 1px solid rgba(120, 120, 120, 0.6);
            input {
              text-align: center;
            }
          }
          .preorder-delay-input {
            input::placeholder {
              color: red;
              font-weight: 600;
            }
            i {
              font-size: 19px;
            }
            .preorder-delay-date {
              display: inline-block;
              input {
                width: 120px;
              }
            }
            span {
              margin: 0 30px 0 15px;
            }
            .preorder-delay-time {
              display: inline-block;
              input {
                width: 60px;
              }
            }
          }
        }
        .content-date {
          padding-top: 10px;
          display: block;
        }
      }
    }
    .newplanning {
      float: right;
      margin: 21px 0;
      padding: 12px 26px 10px 26px;
      letter-spacing: 1.06px;
      line-height: 16px;
      text-align: center;
    }
  }
  .page-profil__alert-bloc {
    margin-top: 20px;
    width: 100%;
    .alert-bloc__label {
      display: block;
      color: #d0021b;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      text-align: right;
      &:before {
        content: '*';
      }
    }
  }
`;
