import isEqual from 'lodash.isequal';
import Moment from 'moment';

export function saveFile(fileName, blob) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = data;
  link.download = fileName;
  link.click();
  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => {
    window.URL.revokeObjectURL(data);
  }, 100);
}

export function objectsAreEqual(obj1, obj2) {
  const forcedObj1 = forceValuesToNumber(obj1);
  const forcedObj2 = forceValuesToNumber(obj2);
  return isEqual(forcedObj1, forcedObj2);
}

function forceValuesToNumber(obj) {
  if (obj && obj.platform) {
    Object.assign(obj, { platform: Number(obj.platform) });
  }
  if (obj && obj.id) {
    Object.assign(obj, { id: String(obj.id) });
  }
  return obj;
}

export function getCollection(collections, filter) {
  const collection = collections.find((c) => objectsAreEqual(c.filter, filter));
  return collection || {};
}

export function getItemsByID(collections, id) {
  let matchingItems = [];
  collections.forEach((c) => {
    const { items } = c;
    const newItems = items ? items.filter((i) => String(i.id) === String(id)) : [];
    matchingItems = matchingItems.concat(newItems);
  });
  return matchingItems;
}

export function numberToPrice(number) {
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number);
}

export function dateTimeToFormatedDate(dateStr, time) {
  if (!dateStr) {
    return '-';
  }

  const momentDate = Moment(dateStr);
  if (time) {
    return momentDate.format('DD.MM.YYYY HH:mm');
  }

  return momentDate.format('DD.MM.YYYY');
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFormatedDate(date) {
  if (!date) {
    return null;
  }

  return Moment(date).format('YYYY-MM-DD');
}

// Return common values of two array
export function getArraysIntersection(array1, array2) {
  return array1.filter((arrayValue) => array2.indexOf(arrayValue) !== -1);
}
