import React from 'react';
import PropTypes from 'prop-types';

import { numberToPrice } from 'helpers';
import Table from './Table';
import Pagination from './Pagination/Pagination';
import { sectionsTypes } from './tableTypes';
import withStyleProductList from './TableWrapper.style';

const { SECTION_TYPE_TEMPERATURE } = sectionsTypes;

class TableWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListVisible: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isListVisible: !prevState.isListVisible,
    }));
  }

  render() {
    const {
      className,
      columns,
      rowsByPage,
      shouldShowHeaders,
      groupBy,
      noPagination,
      section,
      filter,
      type,
      searchValue,
      showManufacturers,
      showClients,
      dataLinks,
      data,
      isInfinite,
      noRequests,
      creditShow,
      orderBy,
      handleItemClick,
      shouldRedirect,
    } = this.props;

    const { label, type: sectionType, informations: sectionInformations } = section || {};

    const { isListVisible } = this.state;

    return (
      <section className={`${className} home-page__section`}>
        {label && sectionType === SECTION_TYPE_TEMPERATURE && (
          <div onClick={this.handleClick} className="cart__section-temperature">
            <i className="cart__section-temperature__icon icon-arrow-down" />
            <i className="Circle__icon-dry icon-dry" />
            <span className="permanent__headline-temperature">
              {`${label} (${sectionInformations.total_quantity})`}
            </span>
            <span className="permanent-cart__Total">{`Total : ${numberToPrice(
              sectionInformations.total_price
            )}`}</span>
          </div>
        )}
        <div hidden={!isListVisible}>
          {!noPagination && !isInfinite && (
            <Pagination rowsByPage={rowsByPage} filter={filter} type={type} />
          )}
          <Table
            columns={columns}
            type={type}
            rowsByPage={rowsByPage}
            shouldShowHeaders={shouldShowHeaders}
            groupBy={groupBy}
            noPagination={noPagination}
            filter={filter}
            searchValue={searchValue}
            showManufacturers={showManufacturers}
            showClients={showClients}
            dataLinks={dataLinks}
            isInfinite={isInfinite}
            noRequests={noRequests}
            data={data}
            creditShow={creditShow}
            orderBy={orderBy}
            handleItemClick={handleItemClick}
            shouldRedirect={shouldRedirect}
          />
          {!noPagination && !isInfinite && (
            <Pagination rowsByPage={rowsByPage} filter={filter} type={type} />
          )}
        </div>
      </section>
    );
  }
}

TableWrapper.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  columns: PropTypes.array,
  groupBy: PropTypes.object,
  shouldShowHeaders: PropTypes.bool,
  noPagination: PropTypes.bool,
  section: PropTypes.object,
  rowsByPage: PropTypes.number,
  type: PropTypes.string,
  searchValue: PropTypes.string,
  showManufacturers: PropTypes.bool,
  showClients: PropTypes.bool,
  dataLinks: PropTypes.object,
  data: PropTypes.object,
  isInfinite: PropTypes.bool,
  noRequests: PropTypes.bool,
  creditShow: PropTypes.bool,
  orderBy: PropTypes.string,
  handleItemClick: PropTypes.func,
  shouldRedirect: PropTypes.bool,
};

TableWrapper.defaultProps = {
  filter: {},
  rowsByPage: 10,
  isInfinite: false,
  noRequests: false,
  section: {},
};

export default withStyleProductList(TableWrapper);
