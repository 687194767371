import postcssFlexbugsFixes from 'postcss-flexbugs-fixes';
import {
  agListNameCellRenderer,
  agListReferencesCellRenderer,
  agListPricesCellRenderer,
  agListQuantityCellRenderer,
  favoriteCellRenderer,
  departmentCellRenderer,
  isNewRenderer,
  agListTabletRenderer,
  agListMobileRenderer,
  agListImageCellRenderer,
} from '../cellRenderers.jsx';

const columnDefs = [
  {
    colId: 'department',
    headerName: 'Département',
    field: 'type',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
    cellRenderer: departmentCellRenderer,
    filterParams: {
      cellRenderer: departmentCellRenderer,
    },
  },
  {
    colId: 'zone',
    headerName: 'Secteur',
    field: 'zone_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'aisle',
    headerName: 'Rayon',
    field: 'aisle_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'category',
    headerName: 'Famille',
    field: 'category_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'sub_category',
    headerName: 'Sous-Famille',
    field: 'subcategory_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'top_sales_position',
    headerName: 'Postion top vente',
    field: 'topPosition',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    headerName: 'Nouveauté',
    field: 'is_new',
    sortable: true,
    filter: true,
    floatingFilter: true,
    cellRenderer: isNewRenderer,
    filterParams: {
      cellRenderer: isNewRenderer,
    },
    hide: true,
  },
  {
    field: 'name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    field: 'reference',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    headerName: 'Libellé',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    headerName: 'Marque',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    field: 'ean13',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'imageCell',
    field: 'product',
    width: 100,
    sortable: true,
    cellRendererFramework: agListImageCellRenderer,
    suppressSizeToFit: true,
    autoHeight: true,
    wrapText: true,
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'agListNameCell',
    field: 'product',
    sortable: true,
    cellRendererFramework: agListNameCellRenderer,
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'agListReferenceCell',
    field: 'product',
    sortable: true,
    cellRendererFramework: agListReferencesCellRenderer,
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'agListPriceCell',
    field: 'product',
    sortable: true,
    filter: 'agNumberColumnFilter',
    cellRendererFramework: agListPricesCellRenderer,
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'tabletCell',
    field: 'product',
    cellRendererFramework: agListTabletRenderer,
    suppressFiltersToolPanel: true,
    hide: true,
  },
  {
    colId: 'mobileCell',
    field: 'product',
    cellRendererFramework: agListMobileRenderer,
    suppressFiltersToolPanel: true,
    hide: true,
    autoHeight: true,
    wrapText: true,
  },
  {
    colId: 'agListQuantityCell',
    field: 'product',
    cellRendererFramework: agListQuantityCellRenderer,
    suppressFiltersToolPanel: true,
    suppressSizeToFit: true,
  },
  {
    colId: 'favoriteCell',
    field: 'product',
    cellRendererFramework: favoriteCellRenderer,
    width: 50,
    suppressSizeToFit: true,
    cellStyle: {
      'justify-content': 'flex-end',
      'align-items': 'flex-start',
      padding: '12px 20px 0 0',
      'font-size': '20px',
    },
  },
];

export default columnDefs;
