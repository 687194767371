/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPath, history, getCollection } from 'helpers';
import { searchActions } from 'actions';
import { userConstants } from 'constants/User.constants';
import TableWrapper from 'components/Table';
import { tableTypes } from 'components/Table/tableTypes';
import Spinner from 'components/Core/Spinner/Spinner';
import withStyleAutoSuggestion from './AutoSuggestion.style';
import { PRODUCT_COLUMNS, CLIENT_COLUMNS } from './TableOptions';

const { TABLE_TYPE_SEARCH, TABLE_TYPE_SEARCH_CLIENT } = tableTypes;

const { TYPE_COMMERCIAL: USER_TYPE_COMMERCIAL, TYPE_ADMIN: USER_TYPE_ADMIN } = userConstants;

class AutoSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      hasRequested: false,
    };
    this.timer = null;
    this.handleClick = this.handleClick.bind(this);
    this.handleGlobalClick = this.handleGlobalClick.bind(this);

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.addEventListener('click', this.handleGlobalClick);
  }

  componentDidUpdate(prevProps) {
    const { searchValue, search, searchCount, isSearching, user, selectedPlatformId } = this.props;
    const { isOpen } = this.state;
    const { searchValue: prevSearchValue } = prevProps;
    if (searchValue === '' && isOpen) {
      this.setState({
        isOpen: false,
      });
    }
    if (searchValue && !isOpen && isSearching) {
      if (this._isMounted) {
        this.setState({
          isOpen: true,
        });
      }
    }

    if (isSearching && searchValue !== '' && prevSearchValue !== searchValue) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            hasRequested: true,
          });
          const clientsSearch = [USER_TYPE_ADMIN, USER_TYPE_COMMERCIAL].includes(user.type);
          const filter = clientsSearch
            ? { searchValue }
            : { platform: Number(selectedPlatformId), searchValue };
          searchCount(searchValue, filter, clientsSearch);
          search(searchValue, filter, clientsSearch, 0, clientsSearch ? 5 : 20);
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    document.body.removeEventListener('click', this.handleGlobalClick);
    this._isMounted = false;
  }

  handleGlobalClick() {
    this.setState({
      isOpen: false,
    });
  }

  handleClick(e) {
    const { searchValue } = this.props;
    e.stopPropagation();
    const url = getPath('search').replace(':searchValue', encodeURIComponent(searchValue));
    history.push(url);
  }

  render() {
    const {
      className,
      collections,
      collectionsClient,
      searchValue,
      user,
      selectedPlatformId,
      handleItemClick,
      shouldRedirect,
    } = this.props;
    const { hasRequested, isOpen } = this.state;
    const clientsSearch = [USER_TYPE_ADMIN, USER_TYPE_COMMERCIAL].includes(user.type);
    const filter = clientsSearch
      ? { searchValue }
      : { platform: Number(selectedPlatformId), searchValue };

    const collection = getCollection(collections, filter);
    const { isLoading: productsLoading, items: products } = collection;
    const collectionClient = getCollection(collectionsClient, filter);
    const { isLoading: clientsLoading, items: clients } = collectionClient;

    const hasResults = (products && products.length > 0) || (clients && clients.length > 0);

    /*
    The undefined check is used to display the spinner when there is a change in the searchValue
    without waiting for the 500ms of delay (setTimeout) happening before making the request
    */
    // eslint-disable-next-line
    const isLoading = clientsSearch
      ? typeof clientsLoading !== 'undefined'
        ? clientsLoading
        : true
      : typeof productsLoading !== 'undefined'
      ? productsLoading
      : true;
    return (
      <div className={className} hidden={!isOpen}>
        {hasResults && (
          <div>
            {clientsSearch ? (
              <div className="section-clients">
                <div className="client-type">
                  <TableWrapper
                    filter={filter}
                    rowsByPage={5}
                    columns={CLIENT_COLUMNS}
                    type={TABLE_TYPE_SEARCH_CLIENT}
                    shouldShowHeaders={false}
                    noPagination
                    showClients
                    noRequests
                    handleItemClick={handleItemClick}
                    shouldRedirect={shouldRedirect}
                  />
                </div>
              </div>
            ) : (
              <div className="section-produit">
                <div className="produit-type">
                  <TableWrapper
                    filter={filter}
                    rowsByPage={5}
                    columns={PRODUCT_COLUMNS}
                    type={TABLE_TYPE_SEARCH}
                    shouldShowHeaders={false}
                    noPagination
                    noRequests
                  />
                </div>
                <div className="button_show_more" onClick={(e) => this.handleClick(e)}>
                  <h5>Voir plus de produits</h5>
                </div>
              </div>
            )}
          </div>
        )}
        {isLoading && (
          <div className="center">
            <Spinner />
          </div>
        )}
        {!hasResults && hasRequested && !isLoading && (
          <div className="red">
            <span>Nous n&apos;avons trouvé aucun résultat à votre recherche.</span>
          </div>
        )}
      </div>
    );
  }
}

AutoSuggestion.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string,
  search: PropTypes.func,
  searchCount: PropTypes.func,
  user: PropTypes.object,
  selectedPlatformId: PropTypes.number,
  isSearching: PropTypes.bool,
  collections: PropTypes.array,
  collectionsClient: PropTypes.array,
  handleItemClick: PropTypes.func,
  shouldRedirect: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { search, user, platform } = state;
  const { collections, collectionsClient } = search;
  const selectedPlatformId = platform ? platform.selectedId : null;
  return {
    user: user.information,
    collections,
    collectionsClient,
    selectedPlatformId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  search: (searchValue, filter, showClients, offset, limit) =>
    dispatch(searchActions.search(searchValue, filter, false, showClients, offset, limit, true)),
  searchCount: (searchValue, filter, clientsSearch) =>
    dispatch(searchActions.searchTotalRowsNumber(searchValue, filter, clientsSearch)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyleAutoSuggestion(AutoSuggestion));
