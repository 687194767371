import React, { useState } from 'react';
import { getIconClassByType, getValueOfAttribut, store } from 'helpers';
import Icon from 'components/Icon/Icon';
import { ProductQuantity, ProductTotal } from 'components/Table/Cells/Products';
import ProductFavorite from 'components/ProductFavoriteV3/ProductFavorite';
import PromotionIcon from 'components/Icon/PromotionIcon';
import { productConstants } from 'constants/Product.constants';
import {
  getProductImageFromEan,
  getProductUnit,
  getPcbUnit,
  getProductType,
} from 'helpers/Product';
import Tooltip from 'components/Core/Tooltip/Tooltip';
import Moment from 'moment';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import { platformConstants } from '../../constants';

const formatPrice = (price) =>
  Number(price).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

const agListNameCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  const clientStockQuantity = product.data.client_stock_quantity;
  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;
  const shouldDisplayStock = !Number(
    getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
  );
  const currentUser = selectCurrentUser(store.getState());
  const shouldDisplayClientStock = currentUser.client.client_stock_display;

  return (
    <div className="ag-list__cell-wrapper">
      <div style={{ fontWeight: 'bold' }}>{product.data.name}</div>
      {!!shouldDisplayStock &&
        availabilityCellRenderer({ value: availabilityValueGetter(product) })}
      {shouldDisplayClientStock && clientStockCellRenderer(clientStockQuantity)}
    </div>
  );
};

const isPlatformLogigua = () => {
  if (parseInt(localStorage.getItem('platform'), 10) === 6) {
    return true;
  }
  return false;
};

const agListReferencesCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="ag-list__cell-wrapper">
      <div>Réf: {product.data.reference}</div>
      <div>EAN: {product.data.ean13}</div>
      <div>
        DLV:{' '}
        {isPlatformLogigua() === false && product.data.sale_deadline
          ? Moment(product.data.sale_deadline).format('DD.MM.Y')
          : ' - '}
      </div>
    </div>
  );
};

const agListPricesCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;
  const shouldDisplayOrderedBy = Number(
    getValueOfAttribut(attributs || [], platformConstants.ORDERED_BY_KEY)
  );
  return (
    <div className="ag-list__cell-wrapper">
      <div style={{ color: '#01366b', fontWeight: '700' }}>
        <span style={{ whiteSpace: 'nowrap' }}>{priceCellRenderer(product)}</span>
        <span style={{ whiteSpace: 'nowrap' }}> / {getProductUnit(product.data.unit)}</span>
      </div>
      {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
        <div>
          <span style={{ whiteSpace: 'nowrap' }}>{packagePriceCellRenderer(product)}</span>
          <span style={{ whiteSpace: 'nowrap' }}> / colis</span>
        </div>
      ) : (
        ''
      )}
      {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
        <div>
          PCB: {product.data.pcb} {getPcbUnit(product.data)}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const typeCellRenderer = (type) => (
  <div className="type-cell" style={{ display: 'flex', alignItems: 'center' }}>
    <i className={getIconClassByType(type)} />
    <span>{departmentCellRenderer({ value: type })}</span>
  </div>
);

const favoriteCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="favorite-cell__wrapper">
      <ProductFavorite
        shouldShowResponsive
        productId={product.data.product_id}
        isFavorite={product.data.is_favorite}
        handleClickFilter={product.agGridReact.props.handleFilter}
      />
      {product.data.has_promotion && <PromotionIcon />}
      {product.data.is_new && <Icon icon="icon-new" slug="is_new" color="red" />}
    </div>
  );
};

const agListImageCellRenderer = (product) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  if (product.data == null) {
    return null;
  }

  return (
    <div className="ag-list__image-cell__wrapper">
      {imageCellRenderer({ value: product.data.ean13 })}
      <div className="ag-list__image-cell__type-icon-container">
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="right"
          labelValue={getProductType(product.data.type)}
          parentElement={parentElement}
        />
        <i
          className={`${getIconClassByType(product.data.type)}`}
          onMouseEnter={(event) => {
            setIsTooltipVisible(true);
            setParentElement(event.target);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        />
      </div>
    </div>
  );
};

const imageCellRenderer = (ean13) => (
  <img
    src={getProductImageFromEan(ean13.value, productConstants.IMAGE_SIZE_SMALL)}
    alt={`${ean13.value}`}
    style={{ width: '35px', height: '35px' }}
    className="img-cell__img"
  />
);

const priceCellRenderer = (price) => {
  if (price.data == null) {
    return null;
  }
  if (price.data.has_promotion === true) {
    return (
      <>
        <strike style={{ color: 'red' }}>
          <span style={{ color: 'grey' }}>{formatPrice(price.data.price)} € HT</span>
        </strike>{' '}
        {formatPrice(price.data.applicable_price)} € HT
      </>
    );
  }

  return <>{formatPrice(price.data.price)} € HT</>;
};

const packagePriceCellRenderer = (price) => {
  if (price.data == null) {
    return null;
  }
  if (price.data.has_promotion === true) {
    return (
      <>
        <strike style={{ color: 'red' }}>
          <span style={{ color: 'grey' }}>
            {formatPrice(price.data.price * price.data.pcb)} € HT
          </span>
        </strike>{' '}
        {formatPrice(price.data.applicable_price * price.data.pcb)} € HT
      </>
    );
  }

  return <>{formatPrice(price.data.applicable_price * price.data.pcb)} € HT</>;
};

const availabilityCellRenderer = (params) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  let dotClassName = null;
  let availabilityLabel = null;
  const currentPlatform = selectCurrentPlatform(store.getState());
  const { attributs } = currentPlatform;
  const shouldDisplayStock = !Number(
    getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
  );

  if (!shouldDisplayStock) {
    return '-';
  }

  switch (params.value) {
    case 'preorder':
      dotClassName = 'orange_background';
      availabilityLabel = 'En Précommande';
      break;
    case 'ok':
      dotClassName = 'green_background';
      availabilityLabel = 'En Stock';
      break;
    default:
      dotClassName = 'red_background';
      availabilityLabel = 'Indisponible';
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={`aggrid__dot ${dotClassName}`} />
      <span
        style={{ position: 'relative' }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      >
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="top"
          labelValue="Stock du fournisseur"
        />
        {availabilityLabel}
      </span>
    </div>
  );
};

const clientStockCellRenderer = (clientStock) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  if (!clientStock && clientStock !== 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="aggrid__dot grey_background" />
        <span
          style={{ position: 'relative' }}
          onMouseEnter={(event) => {
            setIsTooltipVisible(true);
            setParentElement(event.target);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        >
          <Tooltip
            isVisible={isTooltipVisible}
            tooltipPosition="top"
            labelValue="Stock du magasin"
            parentElement={parentElement}
          />
          Votre stock: hors assortiment
        </span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span className={`aggrid__dot ${clientStock > 0 ? 'green_background' : 'red_background'}`} />
      <span
        style={{ position: 'relative' }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      >
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="top"
          labelValue="Stock du magasin"
          parentElement={parentElement}
        />
        Votre stock: {clientStock}
      </span>
    </div>
  );
};

const agTableQuantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="aggrid__quantity-container">
      <ProductFavorite
        shouldShowResponsive
        productId={product.data.product_id}
        isFavorite={product.data.is_favorite}
        handleClickFilter={product.agGridReact.props.handleFilter}
      />
      {quantityCellRenderer(product)}
    </div>
  );
};

const agListQuantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="ag-list__cell-wrapper ag-list-quantity-wrapper">
      {quantityCellRenderer(product)}
      <div className="ag-list__cell-wrapper__total-cell">{totalCellRenderer(product)}</div>
    </div>
  );
};

const agListTabletRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="ag-list__cell-wrapper ag-list-tablet-wrapper">
      <div style={{ fontWeight: 'bold' }}>{product.data.name}</div>
      {agListPricesCellRenderer(product)}
    </div>
  );
};

const agListMobileRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return (
    <div className="ag-list__cell-wrapper ag-list-mobile-wrapper">
      <div style={{ fontWeight: 'bold' }}>{product.data.name}</div>
      {agListPricesCellRenderer(product)}
      <div className="ag-list-mobile__quantity-wrapper">
        {quantityCellRenderer(product)}
        {totalCellRenderer(product)}
      </div>
    </div>
  );
};

const quantityCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return <ProductQuantity shouldShowResponsive product={product.data} />;
};

const totalCellRenderer = (product) => {
  if (product.data == null) {
    return null;
  }
  return <ProductTotal shouldShowResponsive product={product.data} float={null} />;
};

const isNewRenderer = (isNew) => (isNew.value === true || isNew.value === 'true' ? 'OUI' : 'NON');

const departmentCellRenderer = (department) => {
  return getProductType(department.value);
};

const availabilityValueGetter = (stock) => {
  if (stock.data == null) {
    return null;
  }

  if (stock.data && stock.data.is_preorder === true) {
    return 'preorder';
  }

  if (stock.data.stock && stock.data.stock.value_cu >= stock.data.pcb) {
    return 'ok';
  }

  return 'ko';
};

const availabilityFilterFormatter = (stock) => {
  if (stock == null) {
    return null;
  }

  if (stock.value === 'preorder') {
    return 'En Précommande';
  }

  if (stock.value === 'ok') {
    return 'En Stock';
  }

  return 'Indisponible';
};

const hasPromotionRenderer = (promotionId) =>
  promotionId.data.promotion_id === null ? 'NON' : 'OUI';

export {
  formatPrice,
  imageCellRenderer,
  priceCellRenderer,
  packagePriceCellRenderer,
  availabilityCellRenderer,
  quantityCellRenderer,
  totalCellRenderer,
  isNewRenderer,
  departmentCellRenderer,
  availabilityValueGetter,
  availabilityFilterFormatter,
  hasPromotionRenderer,
  agListNameCellRenderer,
  agListReferencesCellRenderer,
  agListPricesCellRenderer,
  agTableQuantityCellRenderer,
  agListQuantityCellRenderer,
  favoriteCellRenderer,
  agListTabletRenderer,
  agListMobileRenderer,
  agListImageCellRenderer,
  clientStockCellRenderer,
};
