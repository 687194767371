export const articleGroupItemConstants = {
  GET_ALL_REQUEST: 'ARTICLE_GROUP_ITEM_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'ARTICLE_GROUP_ITEM_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'ARTICLE_GROUP_ITEM_GET_ALL_FAILURE',

  GET_FROM_ITEM_REQUEST: 'ARTICLE_GROUP_ITEM_GET_FROM_ITEM_REQUEST',
  GET_FROM_ITEM_SUCCESS: 'ARTICLE_GROUP_ITEM_GET_FROM_ITEM_SUCCESS',
  GET_FROM_ITEM_FAILURE: 'ARTICLE_GROUP_ITEM_GET_FROM_ITEM_FAILURE',

  ADD_REQUEST: 'ARTICLE_GROUP_ITEM_ADD_REQUEST',
  ADD_SUCCESS: 'ARTICLE_GROUP_ITEM_ADD_SUCCESS',
  ADD_FAILURE: 'ARTICLE_GROUP_ITEM_ADD_FAILURE',

  UPDATE_REQUEST: 'ARTICLE_GROUP_ITEM_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ARTICLE_GROUP_ITEM_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ARTICLE_GROUP_ITEM_UPDATE_FAILURE',

  DELETE_REQUEST: 'ARTICLE_GROUP_ITEM_DELETE_REQUEST',
  DELETE_SUCCESS: 'ARTICLE_GROUP_ITEM_DELETE_SUCCESS',
  DELETE_FAILURE: 'ARTICLE_GROUP_ITEM_DELETE_FAILURE',
};
