import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { history } from 'helpers';
import Icon from 'components/Icon/Icon';

const goTo = (link) => {
  history.push(link);
};

const OngletItem = ({ link, name, type, active, icon, iconClassName }) => (
  <li key={type} className={active ? 'active' : ''} onClick={() => goTo(link)}>
    <Link href={link} to={link}>
      {icon && <Icon className={iconClassName} content={icon} />}
      <span className="bold">{name}</span>
    </Link>
  </li>
);

OngletItem.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  active: PropTypes.bool,
};

export default OngletItem;
