import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { getValueOfAttribut } from 'helpers';
import { platformActions, modalActions } from 'actions';
import { platformConstants } from 'pages/../constants';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import withStyleModalNewPreorderParameters from './ModalNewPreorderParameters.style';

const { PREORDER_DELAY_KEY, DELIVERY_DELAY_KEY, PREORDER_DATE_BEGIN_KEY } = platformConstants;

const ParametersTable = ({ parameters, className }) => {
  const { preorderDelay, deliveryDelay, preorderDateBegin } = parameters;
  const actualPreorderDate = Moment(preorderDateBegin);
  // We clone the actualPreorderDate in condition to not add twice
  while (Moment(actualPreorderDate).add(preorderDelay, 'days').isBefore(Moment(), 'day')) {
    actualPreorderDate.add(preorderDelay, 'days');
  }

  const arrayPreorderDates = [
    {
      startPreorder: Moment(actualPreorderDate),
      endPreorder: Moment(actualPreorderDate).add(preorderDelay, 'days'),
      delivery: Moment(actualPreorderDate).add(preorderDelay + deliveryDelay, 'days'),
    },
  ];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 4; i++) {
    arrayPreorderDates.push({
      startPreorder: Moment(arrayPreorderDates[i - 1].endPreorder),
      endPreorder: Moment(arrayPreorderDates[i - 1].endPreorder).add(preorderDelay, 'days'),
      delivery: Moment(arrayPreorderDates[i - 1].endPreorder).add(
        preorderDelay + deliveryDelay,
        'days'
      ),
    });
  }
  return (
    <table className={className}>
      <thead>
        <tr>
          <th>Début commande</th>
          <th>Fin commande</th>
          <th>Livraison</th>
        </tr>
      </thead>
      <tbody>
        {arrayPreorderDates.map((row) => (
          <tr>
            <td>{row.startPreorder.format('DD.MM.Y')}</td>
            <td>{row.endPreorder.format('DD.MM.Y')}</td>
            <td>{row.delivery.format('DD.MM.Y')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ModalNewPreorderParameters = ({
  className,
  newParameters,
  resetState,
  platform,
  saveNewParameters,
  closeModal,
}) => {
  const { attributs } = platform;
  if (!attributs) {
    closeModal();
  }
  const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
  const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
  const preorderDateBegin = Moment(
    getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY)
  ).format('YYYY-MM-DD HH:mm');
  const toSendData = [
    { key: PREORDER_DELAY_KEY, value: newParameters.preorderDelay },
    { key: DELIVERY_DELAY_KEY, value: newParameters.deliveryDelay },
    { key: PREORDER_DATE_BEGIN_KEY, value: newParameters.preorderDateBegin },
  ];
  return (
    <div className={className}>
      <div className="old-params-table">
        <span className="bold">Planning actuel</span>
        <ParametersTable parameters={{ preorderDelay, deliveryDelay, preorderDateBegin }} />
      </div>
      <div className="new-params-table">
        <span className="bold">Nouveau planning</span>
        <ParametersTable parameters={newParameters} />
      </div>
      <div className="actions">
        <ButtonSecondary
          inverted
          onClick={() => {
            resetState();
            closeModal();
          }}
        >
          <span>Annuler</span>
        </ButtonSecondary>
        <ButtonPrimary
          onClick={() => {
            saveNewParameters(platform, toSendData);
            closeModal();
          }}
        >
          <span>Valider le nouveau planning</span>
        </ButtonPrimary>
      </div>
    </div>
  );
};

ParametersTable.propTypes = {
  className: PropTypes.string,
  parameters: PropTypes.object,
};

ModalNewPreorderParameters.propTypes = {
  className: PropTypes.string,
  newParameters: PropTypes.object,
  platform: PropTypes.object,
  closeModal: PropTypes.func,
  saveNewParameters: PropTypes.func,
  resetState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  const selectedPlatform = platform
    ? platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {}
    : {};
  return {
    platform: selectedPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  saveNewParameters: (platform, newParameters) =>
    dispatch(platformActions.updatePlatform(platform, newParameters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyleModalNewPreorderParameters(ModalNewPreorderParameters));
