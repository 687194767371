/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import isEqual from 'lodash.isequal';
import map from 'lodash.map';
import has from 'lodash.has';
import findkey from 'lodash.findkey';
import pull from 'lodash.pull';
import last from 'lodash.last';
import Icon from 'components/Icon/Icon';
import priceLabelSvg from 'styles/assets/images/price_label.svg';
import {
  categoryFlattener,
  formatPriceWithCurrency,
  getCartItem,
  getDegressivePricesTable,
  getDiscountedPrice,
  getIconClassByType,
  getValueOfAttribut,
} from 'helpers';
import priceLabelPromoSvg from 'styles/assets/images/price_label_promo.svg';
import ProductAddToCustomCart from 'components/ProductAddToCustomCart/ProductAddToCustomCart';
import {
  getProductShortUnit,
  getPcbUnit,
  getProductImageFromEan,
  getProductType,
} from 'helpers/Product';
import { ProductQuantity } from 'components/Table/Cells/Products';
import { getFormatedDate } from 'helpers/Utils';
import { productConstants } from 'constants/Product.constants';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { aggridFilterActions } from 'actions/AggridFilter';
import { selectCategories } from 'selectors/category';
import Moment from 'moment';
import { selectCurrentUser } from 'selectors/user';
import { selectCurrentPlatform } from 'selectors/platform';
import Spinner from 'components/Core/Spinner/Spinner';
import SubstitutCellRenderer from 'components/Aggrid/SubstitutCellRenderer';
import { articleGroupActions, articleGroupItemActions } from 'actions';
import {
  selectArticleGroupItemData,
  selectArticleGroupItemSelectedItem,
} from 'selectors/articleGroupItem';
import { platformConstants } from '../../constants';
import withStyle from './ProductCard.style';

class ProductCard extends Component {
  componentDidMount() {
    const { product, getArticleGroupItemsFromItemId } = this.props;

    if (product && product.stock.value_cu < product.pcb) {
      getArticleGroupItemsFromItemId(product.id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      product,
      getArticleGroupItemsFromItemId,
      selectedItemArticleGroupIdList,
      getSelectedItemSimilarItemsFromGroup,
    } = this.props;
    if (product && product.id !== prevProps.product.id && product.stock.value_cu < product.pcb) {
      getArticleGroupItemsFromItemId(product.id);
    }

    if (
      selectedItemArticleGroupIdList &&
      selectedItemArticleGroupIdList.length &&
      !isEqual(selectedItemArticleGroupIdList, prevProps.selectedItemArticleGroupIdList)
    ) {
      getSelectedItemSimilarItemsFromGroup(selectedItemArticleGroupIdList);
    }
  }

  formatPrice = (price) =>
    Number(price).toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  availabilityCellRenderer = (product) => {
    if (product.is_preorder === true) {
      return (
        <>
          <td colSpan="2">
            <div>
              <span className="product-card__dot orange" /> En Pr&eacute;-Commande{' '}
            </div>
          </td>
        </>
      );
    }

    if (product.stock && product.stock.value_cu >= product.pcb) {
      return (
        <>
          <td colSpan="2">
            <div>
              <span className="product-card__dot green" /> En Stock{' '}
            </div>
          </td>
        </>
      );
    }

    return (
      <>
        <td>
          <div>
            <span className="product-card__dot red" /> Indisponible{' '}
          </div>
        </td>
      </>
    );
  };

  storeAvailabilityCellRenderer = (clientStockQuantity) => {
    if (!clientStockQuantity && clientStockQuantity !== 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="aggrid__dot grey_background" />
          <span>Votre stock: hors assortiment</span>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className={`aggrid__dot ${
            clientStockQuantity > 0 ? 'green_background' : 'red_background'
          }`}
        />
        <span>Votre stock: {clientStockQuantity}</span>
      </div>
    );
  };

  buildPrices = (product) => {
    const { id, pcb, price, unit } = product;
    const { currentPlatform } = this.props;
    const { attributs } = currentPlatform;
    const shouldDisplayOrderedBy = Number(
      getValueOfAttribut(attributs || [], platformConstants.ORDERED_BY_KEY)
    );
    const cartItem = getCartItem(id);
    const discountedPrice = getDiscountedPrice(product, cartItem);

    if (discountedPrice !== price) {
      return (
        <>
          <div className="product-card__price-wrapper product-card__price-striked">
            <div className="product-card__price-wrapper">
              <object
                className="product-card__price-container__svg"
                type="image/svg+xml"
                data={priceLabelSvg}
              >
                prix
              </object>
              <div className="product-card__price-container__text-container">
                <span className="product-card__price-container__text">
                  {this.formatPrice(price)}
                </span>
                <span className="product-card__price-container__small-text">
                  {' '}
                  €/{getProductShortUnit(unit)}
                </span>
              </div>
              <div className="product-card__price-container__sub-container">
                <span className="product-card__price-container__sub-text">H.T</span>
              </div>
            </div>
            {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
              <span className="product-card__price-container__box-text">
                soit {this.formatPrice(price * pcb)} € HT / colis
              </span>
            ) : (
              ''
            )}
          </div>
          <div className="product-card__price-wrapper">
            <div className="product-card__price-wrapper">
              <object
                className="product-card__price-container__svg"
                type="image/svg+xml"
                data={priceLabelPromoSvg}
              >
                prix
              </object>
              <div className="product-card__price-container__text-container">
                <span className="product-card__price-container__text">
                  {this.formatPrice(discountedPrice)}
                </span>
                <span className="product-card__price-container__small-text">
                  {' '}
                  €/{getProductShortUnit(unit)}
                </span>
              </div>
              <div className="product-card__price-container__sub-container">
                <span className="product-card__price-container__promo-text">Promo</span>
                <span className="product-card__price-container__sub-text">H.T</span>
              </div>
            </div>
            {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
              <span className="product-card__price-container__box-text">
                soit {this.formatPrice(discountedPrice * pcb)} € H.T / colis
              </span>
            ) : (
              ''
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="product-card__price-wrapper">
          <div className="product-card__price-wrapper">
            <object
              className="product-card__price-container__svg"
              type="image/svg+xml"
              data={priceLabelSvg}
            >
              prix
            </object>
            <div className="product-card__price-container__text-container">
              <span className="product-card__price-container__text">{this.formatPrice(price)}</span>
              <span className="product-card__price-container__small-text">
                {' '}
                €/{getProductShortUnit(unit)}
              </span>
            </div>
            <div className="product-card__price-container__sub-container">
              <span className="product-card__price-container__sub-text">H.T</span>
            </div>
          </div>
          {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
            <span className="product-card__price-container__box-text">
              soit {this.formatPrice(price * pcb)} € HT / colis
            </span>
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  flattenObject = (ob) => {
    const toReturn = {};
    // eslint-disable-next-line no-prototype-builtins, guard-for-in
    for (const i in ob) {
      if (has(ob, i)) {
        if (typeof ob[i] === 'object' && ob[i] !== null) {
          const flatObject = this.flattenObject(ob[i]);
          // eslint-disable-next-line no-restricted-syntax
          for (const x in flatObject) {
            if (has(flatObject, x)) {
              toReturn[`${i}.${x}`] = flatObject[x];
            }
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
    }
    return toReturn;
  };
  // function flattenObject(ob) {
  //   var toReturn = {};
  //   for (var i in ob) {
  //       if (!ob.hasOwnProperty(i)) continue;

  //       if ((typeof ob[i]) == 'object' && ob[i] !== null) {
  //           var flatObject = flattenObject(ob[i]);
  //           for (var x in flatObject) {
  //               if (!flatObject.hasOwnProperty(x)) continue;

  //               toReturn[i + '.' + x] = flatObject[x];
  //           }
  //       } else {
  //           toReturn[i] = ob[i];
  //       }
  //   }
  //   return toReturn;
  // }

  handleBreadCrumFilterClick = (categoryName, categoryId, product) => {
    const { handleFilter, currentFilters, setCurrentFilters, categories } = this.props;
    if (!handleFilter) {
      return null;
    }
    let newSelection = null;

    const objFlattend = this.flattenObject(categories);
    const b = findkey(objFlattend, (v) => {
      return v === categoryName;
    });
    // 0.categories.8630.children.8639.children.8737.children.9376.id
    const d = b.split('.');
    const f = pull(d, 'categories', 'name');
    const g = [];
    let baseCat = '';
    let categoryParentIsPreorder;
    f.forEach((element, index) => {
      if (index === 0) {
        baseCat = categories[parseInt(element, 10)];
        newSelection = categories[parseInt(element, 10)].id;
      } else if (index === 1) {
        baseCat = baseCat.categories[parseInt(element, 10)];
      }
      if (element === 'children' && index !== 0 && index !== 1) {
        baseCat = baseCat.children;
      } else if (element !== 'children' && index !== 0 && index !== 1) {
        baseCat = baseCat[parseInt(element, 10)];
      }
      g.push(element);
    });

    let categoryIdValue;
    if (baseCat.children && Object.keys(baseCat.children).length > 0) {
      const categoriesArr = [];
      map(baseCat.children, (element, index) => {
        categoriesArr.push(element);
      });
      categoryIdValue = categoriesArr;
    } else {
      const lastValue = last(g);
      categoryIdValue = parseInt(lastValue, 10);
    }

    const selectedCategoryList = this.rebuildSelectedCategoryList(
      [
        newSelection,
        product.zone_name,
        product.aisle_name,
        product.category_name,
        product.subcategory_name,
      ],
      categoryName
    );
    let categoryColumns = [];
    categoryColumns = [];
    const newFilters = {
      ...currentFilters,
      category: categoryName,
      selection: newSelection,
      categoryId: categoryIdValue,
      categoryColumns,
      selectedCategoryList,
    };

    setCurrentFilters(newFilters);
    return handleFilter(newFilters);
  };

  // getProductSelectionList = (product) => {
  //   const { categories } = this.props;
  //
  //   const selectionList = categories.filter(
  //     (category) => category.status === categoryConstants.STATUS_SELECTION
  //   );
  //
  //   const productSelectionList = [];
  //   selectionList.forEach((selection) => {
  //     if (this.isProductInSelection(product, selection)) {
  //       productSelectionList.push(selection);
  //     }
  //   });
  //
  //   return productSelectionList;
  // };
  // getPath = (object, search) => {
  //   if (object.id === search) return [object.id];
  //   if (object.children || Array.isArray(object)) {
  //     const children = Array.isArray(object) ? object : object.children;
  //     for (let child of children) {
  //       let result = getPath(child, search);
  //       if (result) {
  //           if (object.id )result.unshift(object.name);
  //           return result;
  //       }
  //     }
  //   }
  // };

  isProductInSelection = (product, selection) => {
    const flattenedCategories = categoryFlattener([], selection);
    const productCategorie = product.subcategory_name;

    return flattenedCategories.some((category) => productCategorie === category.name);
  };

  rebuildSelectedCategoryList(selectedCategoryList, currentCategoryName) {
    return selectedCategoryList.slice(
      0,
      Array.indexOf(selectedCategoryList, currentCategoryName) + 1
    );
  }

  buildBreadCrum(product) {
    const itemCategoryList = [
      product.zone_name,
      product.aisle_name,
      product.category_name,
      product.subcategory_name,
    ];

    return itemCategoryList.map((element, index) => (
      <React.Fragment key={uuid()}>
        <span
          onClick={() => {
            if (this.handleBreadCrumFilterClick) {
              this.handleBreadCrumFilterClick(element, product.category_id, product);
            }
          }}
        >
          {element}
        </span>
        {itemCategoryList.length > index + 1 && ' > '}
      </React.Fragment>
    ));
  }

  displayLastOrders(lastOrders) {
    const { currentPlatform, product } = this.props;
    // const productSelectionList = this.getProductSelectionList(product);
    const { attributs } = currentPlatform;
    const shouldDisplayOrderedBy = Number(
      getValueOfAttribut(attributs || [], platformConstants.ORDERED_BY_KEY)
    );
    const allLastOrders = (
      <>
        <tr>
          <td className="product-card__store-info__td-spacer" />
        </tr>
        <tr className="product-card__last-orders__title">
          <td>
            <span colSpan="2">Les dernières commandes : </span>
          </td>
        </tr>
        {lastOrders.map((order, index) => (
          <React.Fragment key={uuid()}>
            <tr className="product-card__last-orders__content">
              <td>
                {getFormatedDate(order.date_create)} : {order.quantity}{' '}
                {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1
                  ? 'colis'
                  : product.unit === productConstants.UNIT_KILO
                  ? 'kg'
                  : 'UC'}
              </td>
            </tr>
          </React.Fragment>
        ))}
      </>
    );

    const noOrders = (
      <>
        <tr>
          <td className="product-card__store-info__td-spacer" />
        </tr>
        <tr className="product-card__last-orders__content">
          <td>
            <span colSpan="2">Article jamais commandé.</span>
          </td>
        </tr>
      </>
    );
    if (lastOrders.length) return allLastOrders;

    return noOrders;
  }

  render = () => {
    const {
      className,
      close,
      product,
      productCard,
      currentPlatform,
      currentUser,
      selectedItemSimilarGroupItemList,
      searchValue,
    } = this.props;
    // const productSelectionList = this.getProductSelectionList(product);
    const { attributs } = currentPlatform;
    const shouldDisplayStock = !Number(
      getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
    );
    const shouldDisplayOrderedBy = Number(
      getValueOfAttribut(attributs || [], platformConstants.ORDERED_BY_KEY)
    );
    const shouldDisplayClientStock = currentUser.client && currentUser.client.client_stock_display;

    const degressivePricesTable = getDegressivePricesTable(product);

    const isPlatformLogigua = () => {
      if (parseInt(localStorage.getItem('platform'), 10) === 6) {
        return true;
      }
      return false;
    };

    return (
      <>
        {!productCard.isLoading && (
          <div className={`${className} fade-in`}>
            <div className="productCard__close" onClick={close}>
              X
            </div>
            <div className="product-card__container">
              <div>
                <img
                  className="product-card__product-image"
                  src={getProductImageFromEan(product.ean13, productConstants.IMAGE_SIZE_LARGE)}
                  alt={product.name}
                />
                <div className="product-card__substitut-button-container">
                  {product &&
                    product.stock.value_cu < product.pcb &&
                    !!selectedItemSimilarGroupItemList.length && (
                      <SubstitutCellRenderer
                        value={product}
                        similarGroupItemList={selectedItemSimilarGroupItemList}
                      />
                    )}
                </div>
              </div>
              <div className="product-card__container-type">
                <div className="product-card__name">
                  <span>{product.name}</span>
                </div>
                <div className="product-card__header__info">
                  <div className="product-card__type">
                    <span className="td_libelle-prod__icon-temperature">
                      <i className={getIconClassByType(product.type)} />
                    </span>
                    <span>{getProductType(product.type)}</span>
                  </div>
                  <Barcode
                    style={{ width: '100%' }}
                    value={product.ean13}
                    width={1.5}
                    height={80}
                    format="EAN13"
                  />
                </div>
              </div>
            </div>
            <div className="product-cart_quantity">
              <table>
                <tr>
                  <ProductQuantity product={product} />
                </tr>
              </table>
            </div>
            <hr />
            <div className="product-card__breadcrumb">{this.buildBreadCrum(product)}</div>
            <div className="product-card__container product-card__price-container">
              {this.buildPrices(product)}
            </div>
            {degressivePricesTable.length > 0 && (
              <div className="productCard__degressive">
                <table className="degressive-price__table">
                  <thead>
                    <tr>
                      <th>Quantité</th>
                      <th>Prix HT / unité</th>
                      <th>Prix HT / colis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {degressivePricesTable.map((step) => {
                      const { id, quantityRange, unitPrice, groupPrice } = step;
                      return (
                        <tr key={id}>
                          <td>{quantityRange}</td>
                          <td>{formatPriceWithCurrency(unitPrice)}</td>
                          <td>{formatPriceWithCurrency(groupPrice)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            <div className="product-card__info-detail">
              <div>
                <table className="product-card__supplier-info">
                  <tbody>
                    {shouldDisplayClientStock && (
                      <tr>
                        <td className="product-card__supplier-info__title bold" colSpan="2">
                          FOURNISSEUR
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="product-card__supplier-info__td-spacer" />
                    </tr>
                    <tr className="product-card__availability">
                      {shouldDisplayStock && this.availabilityCellRenderer(product)}
                    </tr>
                    <tr>
                      <td>Référence :</td>
                      <td>{product.reference}</td>
                    </tr>
                    <tr>
                      <td>EAN :</td>
                      <td>{product.ean13}</td>
                    </tr>
                    <tr>
                      <td>Marque :</td>
                      <td>{product && product.manufacturer && product.manufacturer.name}</td>
                    </tr>
                    {shouldDisplayOrderedBy && shouldDisplayOrderedBy === 1 ? (
                      <tr>
                        <td>PCB :</td>
                        <td>
                          {product.unit === productConstants.UNIT_KILO && 'Environ'} {product.pcb}{' '}
                          {getPcbUnit(product)} par colis
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    <tr>
                      <td>DLV :</td>
                      <td>
                        {isPlatformLogigua() === false && product.sale_deadline
                          ? Moment(product.sale_deadline).format('DD.MM.Y')
                          : ' - '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="product-card__store-info-wrapper">
                <table className="product-card__store-info">
                  <tbody>
                    <tr>
                      <td className="product-card__store-info__title bold" colSpan="2">
                        MAGASIN
                      </td>
                    </tr>

                    {shouldDisplayClientStock && (
                      <>
                        <tr>
                          <td className="product-card__store-info__td-spacer" />
                        </tr>
                        <tr className="product-card__availability">
                          {this.storeAvailabilityCellRenderer(product.client_stock_quantity)}
                        </tr>
                      </>
                    )}

                    {product.last_orders && <>{this.displayLastOrders(product.last_orders)}</>}

                    {!product.last_orders && (
                      <>
                        <tr>
                          <td className="product-card__store-info__td-spacer" />
                        </tr>
                        <tr>
                          <td>
                            <Spinner size={20} />
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {product.document && (
              <div className="productCard__download">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={product.document}
                  className="productCard__download__wrapper"
                >
                  <Icon className="productCard__download__icon" content="\e936" />
                  <span className="bold">Télécharger la fiche technique </span>
                </a>
              </div>
            )}

            <ProductAddToCustomCart product={product} itemId={product.id} />
          </div>
        )}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  const { productCard } = state;
  return {
    productCard,
    currentFilters: selectAggridFilter(state),
    categories: selectCategories(state),
    currentPlatform: selectCurrentPlatform(state),
    currentUser: selectCurrentUser(state),
    selectedItemArticleGroupIdList: selectArticleGroupItemSelectedItem(state),
    selectedItemSimilarGroupItemList: selectArticleGroupItemData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentFilters: (filters) => dispatch(aggridFilterActions.setCurrentFilters(filters)),
  getArticleGroupItemsFromItemId: (itemId) =>
    dispatch(articleGroupActions.fetchArticleGroupFromItemId(itemId)),
  getSelectedItemSimilarItemsFromGroup: (groupId) =>
    dispatch(articleGroupItemActions.fetchArticleGroupItems({ productGroup: groupId })),
});

ProductCard.propTypes = {
  className: PropTypes.string.isRequired,
  product: PropTypes.shape({
    ean13: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    pcb: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    zone_name: PropTypes.string.isRequired,
    aisle_name: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
    subcategory_name: PropTypes.string.isRequired,
    manufacturer: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    document: PropTypes.string,
  }).isRequired,
  close: PropTypes.func,
};

const ProductCardWrapper = (props) => {
  const { product, source } = props;

  // analytics.productDetails(product, source);

  return <ProductCard {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(ProductCardWrapper));
