import React from 'react';

import Layout from 'layouts/Layout';
import Dashboard from './Dashboard';
import DashboardAdmin from './DashboardAdmin';

const DashboardLayout = () => <Layout component={Dashboard} />;

const DashboardAdminLayout = () => <Layout component={DashboardAdmin} />;

export { DashboardAdminLayout };
export { DashboardLayout };
