import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import Icon from 'components/Icon/Icon';
import { history, getPath } from 'helpers';
import { userService } from 'services';
import { orderConstants, userConstants } from '../../constants';
import { ErrorDIV, ButtonP } from './withErrorStyle';

const { ORDER_STATUS_APPROVED } = orderConstants;
const { TYPE_CUSTOMER } = userConstants;

const Error = ({ className, user }) => (
  <ErrorDIV>
    <div className={className}>
      <div className="error-page__container">
        <div className="error-page__text bold">
          <span>Nous sommes désolés, la page demandée n’existe pas</span>
          <span>erreur 404</span>
        </div>
        <div className="error-page__button-block">
          <ButtonP
            inverted
            className="error-page__button "
            onClick={() =>
              user.information.type === TYPE_CUSTOMER
                ? history.push(userService.getHomepagePath(user))
                : history.push(getPath('dashboardAdmin'))
            }
          >
            <Icon content="\E927" />
            <span className="button-icon-save-label regular">REVENIR À L'ACCUEIL</span>
          </ButtonP>
          <ButtonP
            inverted
            className="error-page__button outlined-primary-button-icon"
            onClick={() =>
              user.information.type === TYPE_CUSTOMER
                ? history.push(getPath('orderList').replace(':status', ORDER_STATUS_APPROVED))
                : history.push(getPath('clientList').replace(':status', ''))
            }
          >
            <Icon content="\E952" />
            <span className="button-icon-save-label regular">
              {user.information.type === TYPE_CUSTOMER ? 'VOIR MES COMMANDES' : 'VOIR MES CLIENTS'}
            </span>
          </ButtonP>
        </div>
      </div>
    </div>
  </ErrorDIV>
);
Error.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};
const ErrorLayout = () => <Layout component={Error} />;

const mapStateToProps = (state) => {
  const {
    user: { information },
  } = state;
  return {
    user: information,
  };
};
export default connect(mapStateToProps)(ErrorLayout);
