import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { termsActions, modalActions } from 'actions';
import { termsConstants } from 'actions/../constants';
import Icon from 'components/Icon/Icon';

const { STATUS_ACTIVE, STATUS_INACTIVE } = termsConstants;

const TermsActionsTD = styled.td`
  color: #d0021b;
  font-size: 16px;
  .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 34px;
      margin: 5px 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  i {
    cursor: pointer;
  }
`;

const TermsActionsDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  color: #d0021b;
  display: block;
  text-align: center .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 34px;
      margin: 5px 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  i {
    cursor: pointer;
  }
`;

const TermsActions = ({
  className,
  shouldShowResponsive,
  term,
  openTermsModal,
  toggleTermsStatus,
}) =>
  !shouldShowResponsive ? (
    <TermsActionsTD className={className}>
      <div className="action-block">
        <label className="switch">
          <input
            type="checkbox"
            onClick={() => {
              if (term.count_clients === 0) {
                toggleTermsStatus(term, term.status);
              }
            }}
            checked={term.status === STATUS_ACTIVE}
          />
          <span className="slider round" />
        </label>
        <Icon content="\e937" onClick={() => openTermsModal(term)} />
      </div>
    </TermsActionsTD>
  ) : (
    <TermsActionsDIV className={className}>
      <div className="action-block">
        <label className="switch">
          <input
            type="checkbox"
            onClick={() => {
              if (term.count_clients === 0) {
                toggleTermsStatus(term, term.status);
              }
            }}
            checked={term.status === STATUS_ACTIVE}
          />
          <span className="slider round" />
        </label>
        <Icon content="\e937" onClick={() => openTermsModal(term)} />
      </div>
    </TermsActionsDIV>
  );

const mapDispatchToProps = (dispatch) => ({
  toggleTermsStatus: (term, termStatus) => {
    const status = termStatus === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;
    const dateUnactivated = status === STATUS_ACTIVE ? null : Moment().format('YYYY-MM-DD');
    if (term.id) {
      dispatch(
        termsActions.updateTerms(term.id, {
          id: term.id,
          status,
          date_unactivated: dateUnactivated,
        })
      );
    }
  },
  openTermsModal: (term) => {
    if (term.id) {
      dispatch(
        modalActions.open('addTerms', {
          modalHeader: 'Modifier CGV',
          term,
          width: '400',
        })
      );
    }
  },
});

TermsActions.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  term: PropTypes.object,
  openTermsModal: PropTypes.func,
  toggleTermsStatus: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(TermsActions);
