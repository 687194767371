export const size = {
  mobile: 320,
  mobileL: 544,
  tablet: 768,
  tabletR: 990,
  laptop: 1024,
  large: 1165,
  laptopL: 1280,
  laptopXL: 1380,
};

const screen = {
  mobile: `(max-width: ${size.mobile}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  tabletR: `(max-width: ${size.tabletR}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  large: `(max-width: ${size.large}px)`,
  laptopXL: `(max-width: ${size.laptopXL}px)`,
};

export default screen;
