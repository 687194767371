import styled from 'styled-components';

const CreditFormWrapper = styled.tr`
  form {
    background-color: #f5f5f5;
    box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
    padding: 20px 20px 5px 20px;
    position: relative;

    .credit-form__title {
      color: #4a4a4a;
      font-family: Montserrat;
      font-size: 14px;
      display: block;
      font-weight: 600;
    }
    hr {
      height: 1px;
      background-color: rgba(120, 120, 120, 0.6);
      margin: 20px auto;
    }
    .credit-form__button {
      padding: 10px 20px;
      box-sizing: border-box;
      margin-top: 2px;
      margin-bottom: 18px;
      &-close {
        position: absolute;
        width: 26px;
        height: 26px;
        top: 10px;
        right: 10px;
        line-height: 26px;
      }
      &-title {
        text-align: left;
        padding-left: 13px;
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &:before {
      content: '';
      width: 25px;
      height: 25px;
      background-color: #f5f5f5;
      border: solid transparent;
      box-shadow: inset 4px 5px 4px #ccc;
      border-bottom: 0;
      border-right: 0;
      position: absolute;
      right: 10%;
      top: -14px;
      transform: rotate(45deg);
      z-index: 1;
    }
    @media screen and (max-width: 768px) {
      &:before {
        right: ${({ index }) => (index % 2 === 0 ? '60%' : '10%')};
      }
    }

    @media screen and (max-width: 550px) {
      &:before {
        right: 10%;
      }
    }
  }
`;

export default CreditFormWrapper;
