import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  background: #fff;
  td {
    vertical-align: middle;
    border-bottom: 5px solid #f5f5f5;
  }
  .skeletom-image {
    width: 105px;
    padding: 10px 10px 10px 20px;
    .skeleton-item {
      width: 75px;
      height: 75px;
      display: inline-block;
    }
  }
  .skeletom-wording {
    padding: 20px 5px 20px 0px;
    &:first-child {
      padding-left: 20px;
    }
    &.wording-multipl-ligne {
      padding: 5px 5px 5px 20px;
    }
    .skeleton-item {
      padding: 10px;
      width: 90%;
      &.skeletom-libelle-ligne {
        width: 60%;
        padding: 8px;
      }
      &.skeletom-wording-ligne {
        padding: 10px;
      }
    }
  }
  .skeleton-item {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: -125%;
      left: -100%;
      width: 40px;
      height: 350%;
      opacity: 0;
      transform: rotate(45deg);
      background: rgba(255, 255, 255, 0.2);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: shine;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
    }
  }

  @keyframes shine {
    from {
      opacity: 0.5;
      left: -100%;
    }
    to {
      opacity: 1;
      left: 200%;
    }
  }

  /*----------------------------------------------responsive-----------------------------------------------------*/

  @media all and ${screen.tablet} {
    display: inline-block;
    width: calc(50vw - 10px);
    padding: 15px 30px 20px;
    margin-bottom: 15px;
    vertical-align: top !important;
    &:nth-child(even) {
      display: none;
    }
    td {
      border-bottom: none;
    }
    .skeletom-image {
      width: 100%;
      display: block;
      text-align: center;
      padding: 0;
      .skeleton-item {
        width: 140px;
        height: 140px;
      }
    }
    .skeletom-wording {
      width: 100%;
      display: block;
      padding: 0;
      margin-bottom: 20px;
      &:first-child {
        padding-left: 0;
      }
      .skeleton-item {
        width: 100%;
      }
      &.wording-multipl-ligne {
        padding: 20px 0 0 0;
      }
    }
  }

  @media all and ${screen.mobileL} {
    width: 100%;
  }
`;
