/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import { messageActions, modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ClassicSearchBar from 'components/Core/SearchBar/ClassicSearchBar';
import ModalNewMessagestyle from './ModalNewMessage.style';

class ModalNewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateBegin: '',
      dateEnd: '',
      content: '',
      subject: '',
      clientId: null,
    };
    this.updateInputValue = this.updateInputValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { message } = this.props;
    const defaultMessageParams = {
      subject: '',
      content: '',
    };
    const { date_begin: dateBegin, date_end: dateEnd, subject, content, client } =
      message && message.id ? message : defaultMessageParams;
    const dateBeginLocale = dateBegin ? Moment(dateBegin).format('YYYY-MM-DD') : '';
    const dateEndLocale = dateEnd ? Moment(dateEnd).format('YYYY-MM-DD') : '';
    this.setState({
      dateBegin: dateBeginLocale,
      dateEnd: dateEndLocale,
      subject,
      content,
    });
    if (client && client.id) {
      this.setState({ clientId: client.id });
    }
  }

  handleSubmit() {
    const {
      message: propMessage,
      createMessage,
      updateMessage,
      closeModal,
      tableFilter,
    } = this.props;
    const { dateBegin, dateEnd, content, subject, clientId } = this.state;
    if (
      content &&
      content.trim() &&
      subject &&
      subject.trim() &&
      dateBegin &&
      dateEnd &&
      Moment(dateBegin).format('YYYY-MM-DD') < Moment(dateEnd).format('YYYY-MM-DD')
    ) {
      const message = {
        date_begin: Moment(dateBegin).format('YYYY-MM-DD'),
        date_end: Moment(dateEnd).format('YYYY-MM-DD'),
        content,
        subject,
        clientId,
      };
      if (propMessage && propMessage.id) {
        updateMessage(propMessage.id, { ...message, id: propMessage.id });
      } else {
        createMessage(message, tableFilter);
      }
      closeModal();
    }
  }

  updateInputValue(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDatesChange(date, type) {
    let stringDate = '';
    if (date) {
      stringDate = Moment(date).format('YYYY-MM-DD');
    }
    if (type === 'date_begin') {
      this.setState({
        dateBegin: stringDate,
      });
    } else if (type === 'date_end') {
      this.setState({
        dateEnd: stringDate,
      });
    }
  }

  render() {
    const { className, message } = this.props;
    const { dateBegin, dateEnd, content, subject } = this.state;

    return (
      <div className={className}>
        <div className="new__message">
          <div className="new__message-destination">
            <h5>Destinataire(s)</h5>
            <div className="new__message-idclient">
              {!message && (
                <>
                  <i className="icon-search" />
                  <ClassicSearchBar
                    handleItemClick={(item) => this.setState({ clientId: item.data.product.id })}
                    shouldRedirect={false}
                  />
                </>
              )}
              {message && !message.client && 'Tous'}
              {message && message.client && message.client.name}
            </div>
          </div>
          <h5>
            Date d&apos;affichage du Bandeau
            <span className="required-icon">*</span>
          </h5>
          <div className="new__message_date">
            <div className="new__message_date debut">
              <DatePicker
                selected={dateBegin ? Moment(dateBegin).toDate() : ''}
                onChange={(date) => {
                  this.handleDatesChange(date, 'date_begin');
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="Date de début de diffusion"
                locale="fr"
              />
              <i className="icon-calendar" />
              <span className="required-icon">*</span>
            </div>
            <div className="new__message_date fin">
              <DatePicker
                selected={dateEnd ? Moment(dateEnd).toDate() : ''}
                onChange={(date) => {
                  this.handleDatesChange(date, 'date_end');
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="Date de fin de diffusion"
                locale="fr"
              />
              <i className="icon-calendar" />
              <span className="required-icon">*</span>
            </div>
          </div>
          <h5>Objet</h5>
          <div className="new__message-objet">
            <textarea
              cols="50"
              rows="2"
              name="subject"
              placeholder="Objet"
              maxLength="50"
              value={subject}
              onChange={this.updateInputValue}
            />
          </div>
          <h5>Message (1 à 360 caractères) </h5>
          <div className="new__message-msg">
            <textarea
              cols="50"
              rows="2"
              placeholder="Contenu"
              maxLength="360"
              name="content"
              value={content}
              onChange={this.updateInputValue}
            />
          </div>
          <div>
            <small className="modal-new-message__red-text">
              * Les champs marqués d’un astérix sont obligatoires
            </small>
            <div className="page-profil__alert-bloc">
              {(!content || !content.trim() || !subject || !subject.trim()) && (
                <span className="alert-bloc__label">Objet et/ou message non renseigné.</span>
              )}
              {(!dateBegin || !dateEnd) && (
                <span className="alert-bloc__label">
                  La période de diffusion est non sélectionnée.
                </span>
              )}
              {dateBegin &&
                dateEnd &&
                Moment(dateBegin).format('YYYY-MM-DD') >= Moment(dateEnd).format('YYYY-MM-DD') && (
                  <span className="alert-bloc__label">
                    La période de diffusion sélectionnée est invalide.
                  </span>
                )}
            </div>

            <ButtonPrimary
              onClick={this.handleSubmit}
              disabled={
                !content ||
                !content.trim() ||
                !subject ||
                !subject.trim() ||
                !dateBegin ||
                !dateEnd ||
                (dateBegin &&
                  dateEnd &&
                  Moment(dateBegin).format('YYYY-MM-DD') >= Moment(dateEnd).format('YYYY-MM-DD'))
              }
            >
              <span>Envoyer</span>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}

ModalNewMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  tableFilter: PropTypes.object,
  createMessage: PropTypes.func,
  updateMessage: PropTypes.func,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createMessage: (message, tableFilter) =>
    dispatch(messageActions.createMessage(message, tableFilter)),
  updateMessage: (id, message) => dispatch(messageActions.updateMessage(id, message)),
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(ModalNewMessagestyle(ModalNewMessage));
