import React from 'react';
import styled from 'styled-components';
import PromotionIcon from 'components/Icon/PromotionIcon';

const ProductImageTD = styled.td`
  width: 105px;
  padding-left: 20px;
  padding-right: 30px;

  figure {
    display: inline-block;
    height: 75px;
    width: 75px;
    background-size: cover;
    background-position: center;
    background-color: #ffffff;
    cursor: pointer;
  }
`;

const ProductImageDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  position: relative;
  text-align: center;

  figure {
    height: 80px;
    width: 80px;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
  }

  .td-icon-new {
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .td-icon-new::before {
    color: #cd242e;
  }
`;

const ProductImage = ({
  urlPath,
  isNew,
  hasProductPromotion,
  displayAsPromotion,
  className,
  shouldShowResponsive,
  ...rest
}) => {
  let cleanUrlPath = urlPath;

  cleanUrlPath = cleanUrlPath.replace('//wsse', '/wsse');
  if (cleanUrlPath.indexOf('http') === -1) {
    cleanUrlPath = `https:${urlPath}`;
  } else if (cleanUrlPath.indexOf('https') === -1) {
    cleanUrlPath = cleanUrlPath.replace('http', 'https');
  }

  return !shouldShowResponsive ? (
    <ProductImageTD className={className} {...rest}>
      <figure style={{ backgroundImage: `url(${cleanUrlPath})` }} />
    </ProductImageTD>
  ) : (
    <ProductImageDIV className={className} {...rest}>
      <figure style={{ backgroundImage: `url(${cleanUrlPath})` }} />
      <i className="td-icon-new icon-new" hidden={!isNew} />
      {hasProductPromotion && displayAsPromotion && <PromotionIcon className="td-icon-new" />}
    </ProductImageDIV>
  );
};

export default ProductImage;
