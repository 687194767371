import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  position: relative;
  top: 1px;
  border-bottom: 10px solid #f5f5f5;
  width: auto;
  cursor: auto;
  .credit-content {
    padding-top: 20px;
    border-bottom: 2px solid #f5f5f5;
  }
  .credit-title {
    color: #01366b;
    font-weight: bold;
    letter-spacing: 0.1px;
    padding-right: 15px;
  }
  .credit-quantity-reason,
  .credit-comment {
    padding: 0 20px;
  }
  .credit-quantity-reason {
    display: flex;
    .credit-reason {
      margin: 0 0 10px 20px;
    }
  }
  .credit-comment {
    margin: 10px 0;
    .credit-comment-content {
      position: relative;
      margin-top: 5px;
      word-wrap: break-word;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  /*----------------------------------------------responsive-----------------------------------------------------*/
  @media all and ${screen.tablet} {
  }
`;
