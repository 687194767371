import styled from 'styled-components';

export default (Component) => styled(Component)`
  .page-commercial {
    width: calc(100% - 45px);
    margin: 0 auto;
    padding-top: 22px;
    padding-bottom: 20px;
    .page-commercial__heading {
      .page-commercial__section-label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 11px 0;
        span {
          color: #01366b;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    .react-table__wrapper {
      border: 0;
      border-radius: 4.5px;
      border: none;
      max-height: calc(100vh - 226px);
      overflow: auto;
      box-shadow: none;

      .react-table__row-counter {
        background: #dbdee7;
        color: #01366b;
        font-weight: 600;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      .page-commercial__total-container {
        display: flex;
        color: #01366b;
        white-space: nowrap;
      }

      table {
        border: 0;
        box-shadow: none;

        thead {
          color: #01366b;

          th {
            white-space: nowrap;
            background: #dbdee7;
            border-right: 1px solid #fff;
          }
          th:last-child {
            border-right: none;
          }
        }

        tbody {
          background: #fff;

          .icon-bdc {
            font-size: 29px;
            cursor: pointer;
          }
          tr {
            cursor: pointer;
            &:hover {
              background: rgb(249, 250, 251);
            }

            td {
              padding: 9px 24px;
            }
            td.actions {
              text-align: center;
              .edit {
                padding: 7px;
                cursor: pointer;
                font-weight: bold;
                color: #01366b;
                border-radius: 4.5px;
                background: #ededed;
                font-size: 12px;
              }
            }
          }

          .react-table__no-result {
            cursor: default;
          }
        }
      }
    }
  }
`;
