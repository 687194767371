import styled from 'styled-components';

const ModalAddArticleGroupWrapper = styled.div`
  h3 {
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 10px;
  }

  .add-group-form__buttons-container {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 5px;
      border-radius: 5px;
      padding: 5px;
    }
  }
`;

export default ModalAddArticleGroupWrapper;
