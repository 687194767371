/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { getPath, convertListStatus } from 'helpers';
import { userService } from 'services';
import Moment from 'moment';
import { cartConstants, userConstants, orderConstants } from 'pages/../constants';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { connect } from 'react-redux';

const {
  CART_STATUS_MOQ_PREORDER,
  CART_STATUS_MOQ_STAND_BY,
  CART_STATUS_CURRENT,
  CART_STATUS_CURRENT_PREORDER,
} = cartConstants;
const { PREORDER_STATUS_APPROVED } = orderConstants;
const { TYPE_COMMERCIAL, TYPE_CUSTOMER, TYPE_ADMIN } = userConstants;

const getUrl = (path) => {
  if (path === '/order/:status?') {
    return getPath('orderList').replace(':status?', '');
  }
  if (path === '/preorder/:status?') {
    return getPath('preorderList').replace(':status?', '');
  }
  return '';
};

const OrderBreadCrumb = ({
  type,
  status,
  orderId,
  matchPath,
  detailPreorder,
  userType,
  creditDate,
  user,
}) => {
  const homepagePath =
    userType === TYPE_CUSTOMER ? userService.getHomepagePath(user) : getPath('dashboardAdmin');

  const url = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
  ];
  const currentPage =
    userType === TYPE_CUSTOMER
      ? 'Historique de commandes'
      : [TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType) && 'Suivi de commandes';
  const urlStatus = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
    {
      text: userType === TYPE_CUSTOMER ? 'Historique de commandes' : 'Suivi de commandes',
      href: getUrl(matchPath),
    },
  ];
  if ([TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType)) {
    urlStatus.push({
      text:
        matchPath === '/order/:status?'
          ? 'Commandes (permanent)'
          : matchPath === '/preorder/:status?'
          ? 'Précommandes'
          : '',
      href: getUrl(matchPath),
    });
  }

  const currentPageStatus = convertListStatus(status, userType);
  const urlDetail = [
    {
      text: 'Accueil ',
      href: homepagePath,
    },
    {
      text: userType === TYPE_CUSTOMER ? 'Historique de commandes' : 'Suivi de commandes',
      href: getPath(detailPreorder ? 'preorderList' : 'orderList').replace(
        ':status?',
        detailPreorder ? 'PREORDER_STATUS_APPROVED' : 'STATUS_APPROVED'
      ),
    },
  ];
  if ([TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType)) {
    urlDetail.push({
      text: !detailPreorder ? 'Commandes (permanent)' : detailPreorder ? 'Précommandes' : '',
      href: getPath(detailPreorder ? 'preorderList' : 'orderList').replace(':status?', ''),
    });
  }
  urlDetail.push({
    text: convertListStatus(status, userType),
    href: getPath(detailPreorder ? 'preorderList' : 'orderList').replace(':status?', status),
  });
  const urlCreditDetail = [
    {
      text: 'Accueil ',
      href: getPath('dashboard'),
    },
    {
      text: userType === TYPE_CUSTOMER ? 'Historique de commandes' : 'Suivi de commandes',
      href: getPath('orderList').replace(':status?', ''),
    },
  ];
  if ([TYPE_COMMERCIAL, TYPE_ADMIN].includes(userType)) {
    urlCreditDetail.push(
      {
        text: 'Commandes (permanent)',
        href: getPath('orderList').replace(':status?', ''),
      },
      {
        text: "Demandes d'avoir ",
        href: getPath('orderList').replace(':status?', status),
      }
    );
  } else {
    urlCreditDetail.push({
      text: "Suivi de demandes d'avoir",
      href: getPath('orderList').replace(':status?', status),
    });
  }
  const currentPageDetail = [
    CART_STATUS_MOQ_STAND_BY,
    CART_STATUS_MOQ_PREORDER,
    PREORDER_STATUS_APPROVED,
  ].includes(status)
    ? `Précommande n° ${orderId}`
    : [CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(status)
    ? 'Détail panier permanent'
    : `Commande N° ${orderId}`;
  return (
    <div>
      {type === 'list' && <BreadCrumb urlTab={url} currentPage={currentPage} />}
      {type === 'list_status' && <BreadCrumb urlTab={urlStatus} currentPage={currentPageStatus} />}
      {type === 'detail' && <BreadCrumb urlTab={urlDetail} currentPage={currentPageDetail} />}
      {type === 'creditDetail' && (
        <BreadCrumb
          urlTab={urlCreditDetail}
          currentPage={`Demande d'avoir du  ${Moment(creditDate).format('DD/MM/YYYY')}`}
        />
      )}
    </div>
  );
};

OrderBreadCrumb.propTypes = {
  status: PropTypes.string,
  orderId: PropTypes.string,
  matchPath: PropTypes.string,
  detailPreorder: PropTypes.bool,
  userType: PropTypes.string,
  type: PropTypes.string,
  creditDate: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    user: { information },
  } = state;
  return {
    user: information,
  };
};

export default connect(mapStateToProps)(OrderBreadCrumb);
