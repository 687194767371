// Importing filterConstants from the absolute path (`constants`) bugs.
import { filterConstants } from 'pages/../constants';

export const filterActions = {
  open,
  close,
  updateFilter,
  deleteFilter,
  deleteAllFilter,
};

function open(isClientsFilter, props) {
  return { type: filterConstants.OPEN, isClientsFilter, props };
}

function close() {
  return { type: filterConstants.CLOSE };
}

function updateFilter(filterData) {
  return { type: filterConstants.UPDATE_FILTER, filterData };
}

function deleteFilter(filterType, sectionKey, value) {
  return {
    type: filterConstants.DELETE_FILTER,
    filterType,
    sectionKey,
    value,
  };
}

function deleteAllFilter() {
  return { type: filterConstants.DELETE_ALL };
}
