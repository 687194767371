import React from 'react';

import Icon from 'components/Icon/Icon';
import withStyle from './ProspectBloc.styles';

const Planning = ({ className, planningDry, planningFresh, planningFrozen, handleChange }) => (
  <div className={className}>
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e94c" />
      <span className="entete__label">Plannings de livraison</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut">Sec :</span>
        <select
          className="ligne__value-input ligne__value"
          name="planningDry"
          onChange={handleChange}
          value={planningDry || undefined}
        >
          <option value="1">A</option>
          <option value="2">AB</option>
          <option value="3">ABC</option>
        </select>
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Frais :</span>
        <select
          className="ligne__value-select ligne__value"
          name="planningFresh"
          onChange={handleChange}
          value={planningFresh || undefined}
        >
          <option value="1">A</option>
          <option value="2">AB</option>
          <option value="3">ABC</option>
        </select>
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Surgelé :</span>
        <select
          className="ligne__value-select ligne__value"
          name="planningFrozen"
          onChange={handleChange}
          value={planningFrozen || undefined}
        >
          <option value="1">A</option>
          <option value="2">AB</option>
          <option value="3">ABC</option>
        </select>
      </div>
    </div>
  </div>
);

export default withStyle(Planning);
