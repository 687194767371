import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPath, history } from 'helpers';
import { userActions } from 'actions';
import logo from 'styles/assets/images/logo.png';
import { selectScreenWidth } from 'selectors/ui';
import { changePlatform } from 'helpers/Platform';

const CustomerHeader = ({ platforms, selectedId, user }) => {
  const handlePlatformChange = (e) => {
    changePlatform(e.target.value, user);
  };

  const goToDashboard = () => {
    history.push(getPath('configuration'));
  };

  return (
    <>
      <div className="header-container">
        <span onClick={goToDashboard}>
          <img src={logo} className="logo" alt="commande" />
        </span>
        <div className={`hr}`} />
        <div className={`header-soc}`}>
          <div className="header-soc--select">
            <select placeholder="Platformes" onChange={handlePlatformChange} value={selectedId}>
              {platforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="hr" />
      </div>
    </>
  );
};

CustomerHeader.propTypes = {
  platforms: PropTypes.array,
  selectedId: PropTypes.number,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { platform, user } = state;
  const { selectedId, platforms } = platform;
  return {
    platforms,
    selectedId,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutAs: () => {
    dispatch(userActions.logoutAs());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
