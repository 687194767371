import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import { productConstants } from 'constants/Product.constants';

export function generatePcbTextBlocks(item) {
  const { variable_weight: hasVariableWeight, pcb } = item;
  const list = [];
  if (hasVariableWeight) {
    list.push({
      content: 'Environ',
      isSmall: true,
    });
  }
  list.push({
    content:
      pcb > 1
        ? `${pcb} ${hasVariableWeight ? 'kilos' : 'unités'}`
        : `${pcb} ${hasVariableWeight ? 'kilo' : 'unité'}`,
    isBold: true,
  });
  list.push({
    content: 'par colis',
    isSmall: true,
  });
  return list;
}

export function checkTechnicalSheetAvailability(file) {
  const requestOptions = {
    method: 'GET',
    mode: 'cors',
  };

  return fetch(`https:${file}`, requestOptions)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return false;
    });
}

export function getProductImageFromEan(ean, size) {
  return `https://photos.groupesafo.com/wsse/cb8f190153190ebe8598ac49e86ebf37/${size}/${size}/${ean}`;
}

export function getPcbUnit(item) {
  const { pcb, unit } = item;
  const effectiveUnit = getProductUnit(unit);

  return pcb > 1 ? `${effectiveUnit}s` : effectiveUnit;
}

export function getProductUnit(unit) {
  if (unit === productConstants.UNIT_KILO) {
    return 'kilo';
  }

  return 'unité';
}

export function getProductShortUnit(unit) {
  if (unit === productConstants.UNIT_KILO) {
    return 'kg';
  }

  return 'u';
}

export function getProductType(type) {
  return type === aggridFilterConstants.FILTER_FRESH ? 'frais' : type.toLowerCase();
}
