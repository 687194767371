import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { articleGroupActions, modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ModalAddArticleGroupWrapper from 'components/Modal/ModalAddArticleGroup.style';
import { selectCurrentPlatformId } from 'selectors/platform';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';

const ModalAddArticleGroup = ({ closeModal, createArticleGroup, currentPlatformId, pushToast }) => {
  const [groupName, setGroupName] = useState(null);

  const handleAddArticleGroup = () => {
    if (!groupName.match('^[a-zA-Z0-9_ -]{3,25}$')) {
      return pushToast(
        toastConstants.TYPE_ERROR,
        "Caractères alphanumériques, '-' et '_'  uniquement, entre 3 et 25 caractères"
      );
    }

    return createArticleGroup({ name: groupName }, currentPlatformId, successCallback);
  };

  const successCallback = () => {
    pushToast(toastConstants.TYPE_SUCCESS, 'Groupe ajouté avec succès');
    closeModal();
  };

  return (
    <ModalAddArticleGroupWrapper>
      <form>
        <h3>Ajouter un groupe</h3>
        <input
          type="text"
          placeholder="Nom du nouveau groupe"
          onChange={(event) => {
            setGroupName(event.target.value);
          }}
        />
        <div className="add-group-form__buttons-container">
          <ButtonPrimary className="add-group-form__cancel-button" onClick={closeModal}>
            Fermer
          </ButtonPrimary>
          <ButtonPrimary
            disabled={!groupName}
            className="add-group-form__submit-button"
            onClick={handleAddArticleGroup}
          >
            Envoyer
          </ButtonPrimary>
        </div>
      </form>
    </ModalAddArticleGroupWrapper>
  );
};

ModalAddArticleGroup.propTypes = {
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentPlatformId: selectCurrentPlatformId(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  createArticleGroup: (data, platformId, successCallback = null) =>
    dispatch(articleGroupActions.createArticleGroup(data, platformId, successCallback)),
  pushToast: (type, message, title = '') =>
    dispatch(toastActions.addToast({ type, message, title, duration: 1 })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddArticleGroup);
