import styled from 'styled-components';

export const CommercialOrderFormWrapper = styled.div`
  .commercial-order-form__title {
    color: #01366b;
    font-weight: bold;
  }

  .commercial-order-form__flex {
    display: flex;
    justify-content: space-between;

    .commercial-order-form__product-image {
      position: relative;
      width: 200px;
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 200px;
      }
    }
  }

  form {
    input,
    select {
      height: 29px;
      padding: 5px;
      border-color: #ccc;
      border: 2px solid transparent;
      box-shadow: 0 0 1px #000;
      border-radius: 3px;

      &:focus {
        border-color: #01366b;
      }
    }

    select {
      min-width: 409px;
    }

    .commercial-order-form__bold {
      font-weight: 600;
      padding-bottom: 5px;
    }

    .commercial-order-form__client-line {
      display: flex;
      padding: 10px 0 20px 0;

      .commercial-order__locked-icon {
        min-width: 30px;
        padding-left: 0;
        margin-bottom: 8px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 15px;
        min-width: 150px;

        &:first-child {
          padding-left: 0;
        }

        .commercial-order-form__client-values {
          height: 29px;
          display: flex;
          align-items: center;
        }
      }
    }

    .commercial-order-form__product-line {
      display: flex;
      padding: 10px 0 20px 0;

      .commercial-order-form__bold {
        white-space: nowrap;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0 15px;

        &:first-child {
          padding-left: 0;
        }

        .commercial-order-form__product-values {
          height: 29px;
          display: flex;
          align-items: center;

          .commercial-order-form__negociable-input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
          }

          .price-cell {
            text-align: center;
          }
        }

        .commercial-order-form__quantity-input {
          width: 75px;
          text-align: center;
        }
      }

      .commercial-order-form__warning-icon {
        svg {
          cursor: help;
        }
      }

      .commercial-order-form__pallet_info {
        color: #01366b;
      }

      .commercial-order-form__select-container {
        align-items: flex-start;
      }
    }

    input[type='button'] {
      background: #01366b;
      color: #ffffff;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
    }
    input[type='button']:disabled {
      background: grey;
      border-color: grey;
      cursor: not-allowed;
    }
  }

  .product-select__icon-container {
    margin-right: 12px;
    i {
      &:before {
        width: 20px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`;
