import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'components/Core/Spinner/Spinner';
import { assortmentActions } from 'actions';
import Moment from 'moment';
import { getCollection } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import withStyle from './ModalAddSelection.style';

class ModalAddSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  selectFile = (e) => {
    this.setState({
      file: e.target.files[0] || null,
    });
  };

  handleSubmit = () => {
    const { file } = this.state;
    if (!file) {
      return null;
    }
    const { uploadSelection, clientId, dateEnd, label, redirectLink } = this.props;
    const dateEndLocale = dateEnd ? Moment(dateEnd).format('YYYY-MM-DD') : null;
    const data = {
      label,
      file,
      date_end: dateEndLocale,
      client: Number(clientId),
    };
    if (label && file && clientId) {
      uploadSelection(data, redirectLink);
    }
    return true;
  };

  deleteFile = () => {
    this.setState({
      file: null,
    });
  };

  render() {
    const { className, collections } = this.props;
    const { isLoading } = getCollection(collections, {});

    const { file } = this.state;
    return (
      <div className={className}>
        <div className="file">
          <label htmlFor="fileInput" className="btn__file">
            <span>Parcourir</span>
            <input
              type="file"
              id="fileInput"
              className="input-file"
              onChange={this.selectFile}
              hidden
            />
          </label>
        </div>
        <div className="oldFile" hidden={!file}>
          <span className="bold">{file && file.name}</span>
          <Icon onClick={this.deleteFile} content="\e915" />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="validate">
            <div className="page-profil__alert-bloc">
              {file === null && (
                <span className="alert-bloc__label">Aucun document n’a été importé</span>
              )}
            </div>
            <ButtonPrimary onClick={this.handleSubmit} disabled={file === null}>
              <span>Valider</span>
            </ButtonPrimary>
          </div>
        )}
      </div>
    );
  }
}

ModalAddSelection.propTypes = {
  className: PropTypes.string,
  uploadSelection: PropTypes.func,
  clientId: PropTypes.number,
  dateEnd: PropTypes.string,
  label: PropTypes.string,
  redirectLink: PropTypes.func,
  collections: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
  uploadSelection: (data, redirectLink) => {
    dispatch(assortmentActions.uploadSelection(data, redirectLink));
  },
});

const mapStateToProps = (state) => {
  const {
    assortment: { collections },
  } = state;
  return {
    collections,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(ModalAddSelection));
