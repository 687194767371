import TagManager from 'react-gtm-module';
import get from 'lodash.get';
import snakeCase from 'lodash.snakecase';
import { store } from '../../helpers';
import { productCardActions } from '../../actions/ProductCard';
// import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';

const createPayloadForAnalytic = (state, event) => {
  const { aggridFilter, aggridSearchBar, platform, user, cart } = state;
  const arrFilter = [];
  if (aggridFilter && aggridFilter.category) {
    arrFilter.push(snakeCase(aggridFilter.category).toUpperCase());
  }
  if (aggridFilter && aggridFilter.quickList && aggridFilter.quickList.length > 0) {
    aggridFilter.quickList.map((item) => arrFilter.push(snakeCase(item).toUpperCase()));
  }
  if (aggridFilter && aggridFilter.availabilityList && aggridFilter.availabilityList.length > 0) {
    aggridFilter.availabilityList.map((item) => arrFilter.push(snakeCase(item).toUpperCase()));
  }
  if (
    aggridFilter &&
    aggridFilter.storeAvailabilityList &&
    aggridFilter.storeAvailabilityList.length > 0
  ) {
    aggridFilter.storeAvailabilityList.map((item) => arrFilter.push(snakeCase(item).toUpperCase()));
  }
  if (aggridFilter && aggridFilter.temperatureList && aggridFilter.temperatureList.length > 0) {
    aggridFilter.temperatureList.map((item) => arrFilter.push(snakeCase(item).toUpperCase()));
  }
  const filters = {
    current_filter: arrFilter.toString(),
    search_value:
      aggridSearchBar && aggridSearchBar.searchValue ? snakeCase(aggridSearchBar.searchValue) : '',
    event,
    username: user.information.username,
    platform: {
      id: platform.selectedId,
    },
    item: {},
    source: 'CATALOG_LIST',
  };
  if (cart && cart.current && cart.current.id) {
    filters.cart = { id: cart.current.id };
  }
  if (aggridFilter && aggridFilter.viewType) {
    filters.source = aggridFilter.viewType === true ? 'CATALOG_TABLE' : 'CATALOG_LIST';
  }
  return filters;
};

export const productDetails = (product, source = null) => {
  const state = store.getState();
  const payload = createPayloadForAnalytic(state, 'view_item');
  if (source) {
    payload.source = source;
    if (source === 'PROMOTION_CURRENT' || source === 'PROMOTION_FUTURE') {
      payload.current_filter = '';
      payload.search_value = '';
    }
  }
  payload.item.id = product.id;
  productCardActions.safoItemAnalytic(payload);
  TagManager.dataLayer({
    dataLayer: {
      event: 'productDetail',
      ecommerce: {
        detail: {
          products: [
            {
              name: product.name,
              id: product.ean13,
              price: product.price,
              brand: get(product, 'manufacturer.name'),
              category: product.category_name,
            },
          ],
        },
      },
    },
    dataLayerName: 'PageDataLayer',
  });
};

export const validateCart = ({ id, order_items: items }) => {
  if (typeof id === 'undefined') {
    return;
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: { id },
          products: items.map((product) => ({
            name: product.item_name,
            id: product.item_ean13,
            price: product.item_price,
            brand: product.item_manufacturer_name,
            category: product.item_category_name,
            quantity: product.quantity,
          })),
        },
      },
    },
    dataLayerName: 'PageDataLayer',
  });
};

export const addToCart = (item, quantity) => {
  const state = store.getState();
  const payload = createPayloadForAnalytic(state, 'add_to_cart');

  if (state.pageContext && state.pageContext.page === 'PAGE_PROMOTION') {
    if (state.promotion.currentTab === 'Current') {
      payload.source = 'PROMOTION_CURRENT';
    } else {
      payload.source = 'PROMOTION_FUTURE';
    }
    payload.current_filter = '';
    payload.search_value = '';
  }
  if (state.productCard && state.productCard.currentProduct) {
    if (item.id === state.productCard.currentProduct.id) {
      payload.source = 'PRODUCT_DETAIL';
    }
  }

  payload.item.id = item.id;
  productCardActions.safoItemAnalytic(payload);
  TagManager.dataLayer({
    dataLayer: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              name: item.name,
              id: item.ean13,
              price: item.price,
              brand: get(item, 'manufacturer.name'),
              category: get(item, 'category.name'),
              quantity,
            },
          ],
        },
      },
    },
    dataLayerName: 'PageDataLayer',
  });
};

export const removeFromCart = (product) => {
  const state = store.getState();
  const payload = createPayloadForAnalytic(state, 'remove_from_cart');
  if (state.pageContext && state.pageContext.page === 'PAGE_PROMOTION') {
    if (state.promotion.currentTab === 'Current') {
      payload.source = 'PROMOTION_CURRENT';
    } else {
      payload.source = 'PROMOTION_FUTURE';
    }
    payload.current_filter = '';
    payload.search_value = '';
  }
  if (state.pageContext && state.pageContext.page === 'PAGE_CART') {
    payload.source = 'CART_DETAIL';
    payload.current_filter = '';
    payload.search_value = '';
  }
  if (state.productCard && state.productCard.currentProduct) {
    if (product.id === state.productCard.currentProduct.id) {
      payload.source = 'PRODUCT_DETAIL';
    }
  }
  payload.item.id = product.id;
  productCardActions.safoItemAnalytic(payload);
};
