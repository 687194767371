// Importing assortmentConstants from the absolute path (`constants`) bugs.
import { assortmentConstants } from '../constants';

const initState = {
  assortment: {
    name: '',
    label: '',
    date: '',
    checked: [],
  },
  collections: [],
  collectionsCount: [],
};

export function assortment(state = initState, action) {
  if (!Object.keys(assortmentConstants).find((key) => assortmentConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: false };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case assortmentConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case assortmentConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case assortmentConstants.FETCH_COUNT_FAILURE:
      return state;
    case assortmentConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case assortmentConstants.FETCH_SUCCESS:
      collection = Object.assign(collection, { items: action.items, isLoading: false });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case assortmentConstants.FETCH_FAILURE:
      return state;
    case assortmentConstants.UPDATE_REQUEST:
      collections
        .filter((c) => {
          const { items } = c;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.assortment.id)) > -1
            : false;
        })
        .forEach((c) => {
          const { items } = c;
          const oldItem = items
            ? items.find((i) => Number(i.id) === Number(action.assortment.id))
            : {};
          const newItems = items
            ? items.filter((i) => Number(i.id) !== Number(action.assortment.id))
            : [];
          newItems.push({ ...oldItem, ...action.assortment });
          Object.assign(c, {
            items: newItems.sort((a, b) => Number(a.id) - Number(b.id)),
            isLoading: false,
          });
        });
      return {
        ...state,
        collections,
      };
    case assortmentConstants.UPDATE_SUCCESS:
      collections
        .filter((c) => {
          const { items } = c;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.assortment.id)) > -1
            : false;
        })
        .forEach((c) => {
          const { items } = c;
          const newItems = items
            ? items.filter((i) => Number(i.id) !== Number(action.assortment.id))
            : [];
          newItems.push(action.assortment);
          Object.assign(c, {
            items: newItems.sort((a, b) => Number(a.id) - Number(b.id)),
            isLoading: false,
          });
        });
      return {
        ...state,
        collections,
      };
    case assortmentConstants.UPDATE_FAILURE:
      return { ...state };
    case assortmentConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case assortmentConstants.CREATE_SUCCESS:
      collection = Object.assign(collection, {
        items: [...(collection.items || []), action.assortment],
        isLoading: false,
      });
      collections = Object.assign([collection], collections);

      return {
        ...state,
        isLoading: false,
        collections,
      };
    case assortmentConstants.CREATE_FAILURE:
      return {
        ...state,
      };
    case assortmentConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case assortmentConstants.DELETE_SUCCESS:
      collections.forEach((c) => {
        const { items } = c;
        const newItems = items
          ? items.filter((i) => Number(i.id) !== Number(action.assortmentId))
          : [];
        collections = Object.assign(
          [
            Object.assign(c, {
              items: newItems,
              isLoading: false,
            }),
          ],
          collections
        );
      });
      return {
        ...state,
        isLoading: false,
        collections,
      };
    case assortmentConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case assortmentConstants.UPLOAD_REQUEST:
      collection = Object.assign(collection, {
        items: collection.items,
        isLoading: true,
      });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case assortmentConstants.UPLOAD_SUCCESS:
      collection = Object.assign(collection, {
        items: [...(collection.items || []), action.selection],
        isLoading: false,
      });
      collections = Object.assign([collection], collections);

      return {
        ...state,
        collections,
      };
    case assortmentConstants.UPLOAD_FAILURE:
      return {
        ...state,
      };
    case assortmentConstants.UPDATE_STORE:
      return {
        ...state,
        assortment: {
          ...state.assortment,
          [action.key]: action.value,
        },
      };
    case assortmentConstants.RESET_STORE:
      return {
        ...state,
        assortment: {
          name: '',
          date: '',
          label: '',
          checked: [],
        },
      };
    default:
      return state;
  }
}
