import { orderConstants, cartConstants, userConstants } from '../constants';

const {
  CART_STATUS_CURRENT,
  CART_STATUS_CURRENT_PREORDER,
  CART_STATUS_MOQ_STAND_BY,
  CART_STATUS_MOQ_PREORDER,
} = cartConstants;
const {
  ORDER_STATUS_APPROVED,
  ORDER_STATUS_PENDING_VALIDATION,
  ORDER_STATUS_FACTURED,
  PREORDER_STATUS_APPROVED,
  STATUS_ORDER_DELIVERED,
  ORDER_FACTURED_ITEMS,
  STATUS_CREDIT,
} = orderConstants;
const { TYPE_CUSTOMER } = userConstants;

export function convertListStatus(status, userType) {
  switch (status) {
    case ORDER_STATUS_PENDING_VALIDATION:
      return 'Commandes en attente de validation';
    case ORDER_STATUS_APPROVED:
      return 'Commandes validées';
    case ORDER_STATUS_FACTURED:
      return 'Commandes facturées';
    case STATUS_ORDER_DELIVERED:
      return 'Commandes livrées';
    case CART_STATUS_CURRENT:
      return 'Paniers en cours';
    case PREORDER_STATUS_APPROVED:
      return 'Précommandes validées';
    case CART_STATUS_CURRENT_PREORDER:
      return 'Paniers en cours';
    case CART_STATUS_MOQ_STAND_BY:
      return 'Précommandes à valider';
    case STATUS_CREDIT:
      return "Demandes d'avoir";
    case CART_STATUS_MOQ_PREORDER:
      return userType === TYPE_CUSTOMER ? 'Précommandes à valider' : 'MOQ à valider';
    case ORDER_FACTURED_ITEMS:
      return 'RÉFÉRENCES FACTURÉES';
    default:
      return '';
  }
}
