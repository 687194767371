import styled from 'styled-components';

const ReccurentCartListWrapper = styled.div`
  padding: 30px;

  .page-cart__navigation-buttons {
    display: flex;

    button {
      height: 35px;
      padding: 7px 15px;
      cursor: pointer;
      box-shadow: rgb(0, 0, 0) 0 0 1px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background: #ffffff;
      margin-right: 20px;
      border: 1px solid rgb(1, 54, 107);
    }

    .active-button {
      background: #2aa952;
      color: white;
      border: 1px solid #2aa952;
    }
  }

  .page-cart__section-label {
    margin-bottom: 15px;
  }

  .react-table__wrapper {
    max-height: calc(100vh - 342px);
    overflow: auto;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #fefefe;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }
    table {
      tbody {
      }
      tr {
        background: #fff;
        cursor: pointer;
        &:hover {
          background: #f9fafb;
        }
      }
      th {
        background: #f2f3f4;
        border-right: none;
        border-left: none;
        color: #01366b;
      }
      td {
        background: none;
        border-right: none;
        border-left: none;
      }
    }
    .cart-list__ref-line {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
      i {
        font-size: 12px;
        margin-right: 5px;
      }
      i:before {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
    .cart-list__total-cell {
      color: #01366b;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
    font-size: 16px;

    .update-icon {
      color: #00a6ff;
      margin-right: 15px;
    }

    .delete-icon {
      color: red;
    }

    .play-icon {
      color: green;
      margin-right: 15px;
    }

    .pause-icon {
      color: grey;
      margin-right: 15px;
    }
  }
`;

export default ReccurentCartListWrapper;
