import { articleService } from 'services';
import { articleConstants } from '../constants';

export const articleActions = {
  fetchAllArticles,
};

function fetchAllArticles(platformId, limit = 1000) {
  return (dispatch) => {
    dispatch(request());
    articleService.fetchAllArticle(platformId, 0, limit).then(
      (articles) => {
        dispatch(success(articles));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: articleConstants.GET_ALL_REQUEST };
  }
  function success(data) {
    return {
      type: articleConstants.GET_ALL_SUCCESS,
      data,
    };
  }
  function failure() {
    return { type: articleConstants.GET_ALL_FAILURE };
  }
}
