// Importing messageConstants from the absolute path (`constants`) bugs.
import isEqual from 'lodash.isequal';
import { messageConstants } from '../constants';

const initState = {
  collections: [],
  collectionsCount: [],
};

export function message(state = initState, action) {
  if (!Object.keys(messageConstants).find((key) => messageConstants[key] === action.type)) {
    return state;
  }

  const { collections } = state;
  let { collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((m) => JSON.stringify(m.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case messageConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case messageConstants.FETCH_COUNT_SUCCESS: {
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      };
      const newCollectionsCount = [
        ...collectionsCount.filter((c) => !isEqual(newCollectionCount.filter, c.filter)),
        newCollectionCount,
      ];
      return {
        ...state,
        collectionsCount: newCollectionsCount,
      };
    }
    case messageConstants.FETCH_COUNT_FAILURE:
      return state;
    case messageConstants.FETCH_REQUEST: {
      const newCollection = { ...collection, isLoading: true };
      const newCollections = [
        ...collections.filter((collection) => !isEqual(newCollection.filter, collection.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case messageConstants.FETCH_SUCCESS: {
      const newCollection = {
        ...collection,
        items: collection.items || [],
        isLoading: false,
      };
      newCollection.items[action.offset] = action.items;
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];

      return {
        ...state,
        collections: newCollections,
      };
    }
    case messageConstants.FETCH_FAILURE:
      return state;
    case messageConstants.DELETE_REQUEST:
      return {
        ...state,
      };
    case messageConstants.DELETE_SUCCESS: {
      let newCollectionsCount = [...collectionsCount];
      const newCollections = collections.map((collection) => {
        if (collection.items) {
          const newCollectionCount = newCollectionsCount.find((collectionCount) =>
            isEqual(collectionCount.filter, collection.filter)
          );
          newCollectionsCount = [
            ...newCollectionsCount.filter(
              (collectionCount) => !isEqual(collectionCount.filter, collection.filter)
            ),
            {
              ...newCollectionCount,
              totalRowsNumber: newCollectionCount.totalRowsNumber - 1 || 0,
            },
          ];
          return {
            ...collection,
            items: collection.items.map((page) => [
              ...page.filter((item) => item.id !== action.messageId),
            ]),
          };
        }

        return collection;
      });

      return {
        ...state,
        collections: newCollections,
        collectionsCount: newCollectionsCount,
      };
    }
    case messageConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case messageConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case messageConstants.UPDATE_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items) {
          return {
            ...collection,
            items: collection.items.map((page) => {
              const newPage = [...page];
              const itemIndex = page.findIndex((item) => item.id === action.message.id);
              if (itemIndex !== -1) {
                newPage[itemIndex] = action.message;
              }

              return newPage;
            }),
          };
        }

        return collection;
      });

      return {
        ...state,
        collections: newCollections,
      };
    }
    case messageConstants.UPDATE_FAILURE:
      return { ...state };
    case messageConstants.CREATE_REQUEST:
      return {
        ...state,
      };
    case messageConstants.CREATE_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items && collection.items[0]) {
          collection.items[0].unshift(action.message);
        }

        return { ...collection };
      });
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: collectionCount.totalRowsNumber + 1,
      };

      return {
        ...state,
        collections: newCollections,
        collectionsCount: [newCollectionCount],
      };
    }
    case messageConstants.CREATE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
