import React from 'react';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cartActions, modalActions, containerActions } from 'actions';
import { PreorderOutstanding } from 'components/Catalog';
import {
  checkCookie,
  getCartItem,
  getCartsById,
  getCookie,
  getDiscountedPrice,
  getPath,
  getValueOfAttribut,
  history,
  numberToPrice,
} from 'helpers';
import { getShouldDisplayOrderedBy } from 'helpers/Platform';
import {
  cartConstants,
  catalogConstants,
  platformConstants,
  userConstants,
  clientStatusConstants,
} from 'pages/../constants';
import Spinner from 'components/Core/Spinner/Spinner';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import { ModalCookies } from 'components/Modal';
import Sticky from 'components/Core/Sticky/Sticky';
// import Containerization from 'components/Containerization';
import ContainerizationV2 from 'components/ContainerizationV2';
import { ButtonP } from 'components/Core/Utils/Navigation/Header/Header.style';
import withStyleFooter from './Footer.style';

const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;
const { TYPE_CUSTOMER } = userConstants;
const { DELIVERY_MODE_KEY, KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;
const { STATUS_ACTIVE } = clientStatusConstants;

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const TEMPERATURES = [TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN];

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCguChecked: false,
    };
  }

  getCart() {
    const { current, currentPreorder, cart } = this.props;
    const { collections } = cart;
    const id = this.getRequestedId();

    if (!id) {
      return false;
    }

    if (String(current.id) === String(id) || id === 'current') {
      return current;
    }

    if (String(currentPreorder.id) === String(id) || id === 'current_preorder') {
      return currentPreorder;
    }

    const carts = getCartsById(id, collections);
    if (carts && carts.length) {
      return carts[0];
    }

    return null;
  }

  getIsCurrentCart() {
    if (String(this.getRequestedId()) === 'current') {
      return true;
    }

    const { current } = this.props;

    if (current && current.status) {
      return String(current.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT;
    }
    return false;
  }

  getIsCurrentPreorderCart() {
    if (String(this.getRequestedId()) === 'current_preorder') {
      return true;
    }

    const { currentPreorder } = this.props;

    if (currentPreorder && currentPreorder.status) {
      return String(currentPreorder.id) === String(this.getRequestedId());
    }
    const cart = this.getCart();
    if (cart) {
      return cart.status === CART_STATUS_CURRENT_PREORDER;
    }
    return false;
  }

  getIsCurrent() {
    return this.getIsCurrentCart() || this.getIsCurrentPreorderCart();
  }

  getRequestedId() {
    const { match, current, currentPreorder } = this.props;
    if (!match || !match.params) {
      return false;
    }
    const { id } = match && match.params;
    if (!id && current) {
      return current.id;
    }
    if (!id && currentPreorder) {
      return currentPreorder.id;
    }
    return id;
  }

  getFrancosByTemperature = (clientFrancos) => {
    const { selectedPlatform } = this.props;
    const francos = clientFrancos
      .filter(
        ({ platform_id: platformId, enable }) =>
          enable && Number(platformId) === Number(selectedPlatform.id)
      )
      .sort((francoA, francoB) => (francoA.date_create < francoB.date_create ? 1 : -1));

    const francoDry = francos.find(({ temperature }) => temperature === TEMPERATURE_DRY);
    const francoFresh = francos.find(({ temperature }) => temperature === TEMPERATURE_FRESH);
    const francoFrozen = francos.find(({ temperature }) => temperature === TEMPERATURE_FROZEN);

    return {
      [TEMPERATURE_DRY]: francoDry,
      [TEMPERATURE_FRESH]: francoFresh,
      [TEMPERATURE_FROZEN]: francoFrozen,
    };
  };

  getFrancoStatus = () => {
    const { current, user } = this.props;
    const { information } = user;
    const clientFrancos = information && information.client && information.client.client_franco;
    const francoStatus = {
      [TEMPERATURE_DRY]: null,
      [TEMPERATURE_FRESH]: null,
      [TEMPERATURE_FROZEN]: null,
      shouldOpenPopUp: false,
    };
    if (current && clientFrancos.length > 0) {
      const francosByTemperature = this.getFrancosByTemperature(clientFrancos);
      TEMPERATURES.forEach((temperature) => {
        const franco = francosByTemperature[temperature];
        const total = current.totals_by_type && current.totals_by_type[temperature];
        if (franco && total && franco.amount > total.total_price) {
          francoStatus[temperature] = {
            status: franco.status,
            amount: franco.amount,
          };
          francoStatus.shouldOpenPopUp = true;
        }
      });
    }

    return francoStatus;
  };

  getTotalPrice = () => {
    const { current } = this.props;
    const { cart_items: cartItems } = current || [];
    const shouldDisplayOrderedBy = getShouldDisplayOrderedBy();
    let totalPrice = 0;

    if (cartItems) {
      if (cartItems.some((item) => item.item.degressive_prices)) {
        cartItems.forEach((item) => {
          const itemEvents = { item: false };
          const itemKey = 'item';
          const productItem = Object.prototype.hasOwnProperty.call(itemEvents, itemKey)
            ? item.item
            : item;
          const { id, pcb, price } = item.item;
          const { quantity } = item;

          // Vérifier que pcb et quantity sont des nombres
          if (typeof pcb === 'number' && typeof quantity === 'number') {
            const cartItem = getCartItem(id);

            const itemPrice =
              shouldDisplayOrderedBy === 0
                ? price * quantity
                : getDiscountedPrice(productItem, cartItem) * pcb * quantity;

            totalPrice += itemPrice;
          } else {
            console.warn(`Invalid pcb or quantity for item with id ${id}`);
          }
        });
      } else {
        totalPrice = current.total_price;
      }
    }

    return numberToPrice(Number(totalPrice));
  };

  handleCheck = () => {
    this.setState((prevState) => ({
      isCguChecked: !prevState.isCguChecked,
    }));
  };

  handleClick = (
    isPreorder,
    withDeliveryAmount = false,
    deliveryAmount = 0,
    isPreparationOnly = 0
  ) => {
    const { validateCart, openModal, current, currentPreorder, deliveryDates, selectedPlatform } =
      this.props;
    const { isCguChecked } = this.state;
    const match = matchPath(window.location.pathname, {
      path: getPath('cart'),
    });
    const effectiveCart = isPreorder ? currentPreorder : current;
    const { delivery_dates_by_type: deliveryDatesByType, count_items_by_type: countItemsByType } =
      current || {};
    const areDeliveryDatesOk =
      deliveryDatesByType &&
      Object.keys(countItemsByType).every((temperature) => !!deliveryDatesByType[temperature]);
    if (
      isCguChecked &&
      effectiveCart &&
      Number(effectiveCart.total_quantity) > 0 &&
      (isPreorder || areDeliveryDatesOk)
    ) {
      const { attributs } = selectedPlatform;
      const platformHasContainerization = !!Number(
        getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)
      );
      if (match) {
        const francoStatus = this.getFrancoStatus();
        if (isPreorder || francoStatus.shouldOpenPopUp !== true) {
          validateCart(
            effectiveCart,
            deliveryDates,
            withDeliveryAmount,
            deliveryAmount,
            isPreparationOnly,
            platformHasContainerization
          );
        } else {
          openModal(francoStatus, () =>
            validateCart(
              effectiveCart,
              deliveryDates,
              withDeliveryAmount,
              deliveryAmount,
              isPreparationOnly,
              platformHasContainerization
            )
          );
        }
      } else {
        history.push(getPath('cart').replace(':id', effectiveCart.id));
      }
    }
  };

  goToCart(id) {
    if (id) {
      history.push(getPath('cart').replace(':id', id).replace('/:temperature?', ''));
    }
  }

  render() {
    const {
      className,
      current,
      user,
      selectedPlatform,
      cookie,
      loggedAs,
      cart: { isLoading },
      currentPreorder,
    } = this.props;

    const matchCart = matchPath(window.location.pathname, {
      path: getPath('cart'),
    });
    const matchFirstConnection = matchPath(window.location.pathname, {
      path: getPath('welcome'),
    });

    const cart = this.getCart() || {};
    const { information } = user || {};
    const { type, client, username } = information || {};
    const { terms } = client || {};
    const { isCguChecked } = this.state;
    const { attributs } = selectedPlatform;

    const effectiveTerm =
      (terms || []).find((term) => Number(term.platform_id) === Number(selectedPlatform.id)) || {};

    const isCurrentPreorderCart = this.getIsCurrentPreorderCart();
    const isCurrent = this.getIsCurrent();

    const { user: cartUser } = cart;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const {
      client: { client_delivery_modes: clientDeliveryModes },
    } = cartUser || { client: { client_delivery_modes: [] } };
    const { delivery_mode: clientDeliveryMode } =
      (clientDeliveryModes || []).find(
        (deliveryMode) => Number(deliveryMode.platform.id) === Number(selectedPlatform.id)
      ) || {};
    const deliveryAmount =
      clientDeliveryMode || cart.delivery_mode
        ? Math.min(cart.total_price * 0.02, 80)
        : Math.min(cart.total_price * (0.04 + 0.02), 200);
    const { delivery_dates_by_type: deliveryDatesByType, count_items_by_type: countItemsByType } =
      current || {};
    const areDeliveryDatesOk = isCurrent
      ? deliveryDatesByType &&
        Object.keys(countItemsByType).every((temperature) => !!deliveryDatesByType[temperature])
      : true;
    const userCookies = checkCookie(username);

    const clientStatus = ((client && client.client_status) || []).find(
      (status) => status.platform_id && Number(status.platform_id) === Number(selectedPlatform.id)
    );
    const { status_preorder: statusPreorder, status_catalog: statusCatalog } = clientStatus || {};

    return (
      <>
        <div>
          {matchFirstConnection &&
            getCookie('username', cookie) !== userCookies &&
            userCookies !== loggedAs && <ModalCookies />}
        </div>
        {(type === TYPE_CUSTOMER ||
          [CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(cart.status)) && (
          <div className={className}>
            <div>
              {!!Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) && (
                <ContainerizationV2 className="bloc-containeurisation" />
              )}
              <div id="footer-block" className="footer-block fade-in">
                {!matchCart && (
                  <div className="footer-block__link">
                    <div className="home__section__footer-link">
                      <div className="home_section__preorder-outstanding">
                        {statusPreorder === STATUS_ACTIVE && (
                          <PreorderOutstanding selectedPlatform={selectedPlatform} />
                        )}
                      </div>
                      <div className="footer-client_button-container">
                        {statusPreorder === STATUS_ACTIVE && (
                          <div className="footer__button-container">
                            <ButtonP
                              className="footer__button"
                              disabled={!currentPreorder.id}
                              onClick={() => {
                                this.goToCart(currentPreorder.id);
                              }}
                            >
                              <div className="footer__button-content footer__button-preorder-cart">
                                <Icon content="\e941" />
                                <span className="footer__button-title">
                                  <span className="footer__button-title-small">
                                    panier precommande
                                  </span>
                                  <span className="footer_button-title-amount">
                                    {numberToPrice(Number(currentPreorder.total_price) || 0)}
                                  </span>
                                </span>
                              </div>
                            </ButtonP>
                          </div>
                        )}
                        {statusCatalog === STATUS_ACTIVE && (
                          <div className="footer__button-container">
                            <ButtonP
                              className="footer__button"
                              disabled={!current.id}
                              onClick={() => {
                                this.goToCart(current.id);
                              }}
                            >
                              <div className="footer__button-content footer__button-permanent-cart">
                                <Icon content="\e94c" />
                                <span className="footer__button-title">
                                  <span className="footer__button-title-small">
                                    panier permanent
                                  </span>
                                  <span className="footer_button-title-amount">
                                    {this.getTotalPrice()}
                                  </span>
                                </span>
                              </div>
                            </ButtonP>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  cart: PropTypes.object,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  deliveryDates: PropTypes.object,
  validateCart: PropTypes.func,
  selectedPlatform: PropTypes.object,
  cookie: PropTypes.string,
  openModal: PropTypes.func,
  loggedAs: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    user,
    authentication,
    cart,
    platform: { selectedId, platforms },
  } = state;
  const { current, currentPreorder } = cart;
  const { cookie, loggedAs } = authentication;
  const selectedPlatform = platforms.find(({ id }) => Number(id) === Number(selectedId)) || {};
  return {
    cart,
    current,
    currentPreorder,
    deliveryDates: state.cart.deliveryDates,
    user,
    selectedPlatform,
    cookie,
    loggedAs,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModal: (francoStatus, validateCart) => {
    const modalType = 'francoAlert';
    const modalProps = {
      modalHeader: '',
      francoStatus,
      validateCart,
    };
    dispatch(modalActions.open(modalType, modalProps));
  },
  validateCart: (
    cart,
    deliveryDates,
    withDeliveryAmount = false,
    deliveryAmount = 0,
    isPreparationOnly = 0,
    platformHasContainerization = false
  ) => {
    dispatch(
      cartActions.validateCart(
        cart,
        deliveryDates,
        withDeliveryAmount,
        deliveryAmount,
        isPreparationOnly,
        platformHasContainerization
      )
    );
    dispatch(containerActions.deleteContainers());
  },
  openModalContainersOptimal: () =>
    dispatch(modalActions.open('containersOptimal', { width: '670' })),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyleFooter(Footer));
