import React from 'react';
import { connect } from 'react-redux';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { selectItemsFromCatalog, selectTopSales } from 'selectors/product';
import { doFiltersPass } from 'helpers/Filter';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';

import './CatalogControls.scss';

function NewControl(props) {
  const { counters, newCounter, notifCounterColor } = props;

  return (
    <>
      <Icon slug="is_new" color={notifCounterColor} />
      <span>Nouveautés</span>
      {/* <div className="catalog-controls__tab-notif" style={{ background: notifCounterColor }}>
        ({counters.new > 99 ? '99+' : counters.new})
      </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  const filter = selectAggridFilter(state);
  const topSales = selectTopSales(state);

  return {
    newCounter: selectItemsFromCatalog(state, { aggrid: true }).filter((item) =>
      doFiltersPass(
        aggridFilterConstants.FILTER_NEW,
        filter.selection,
        filter.category,
        filter.availabilityList,
        filter.storeAvailabilityList,
        filter.temperatureList,
        item,
        topSales.items
      )
    ).length,
  };
};

NewControl.propTypes = {
  notifCounterColor: PropTypes.string,
  newCounter: PropTypes.number,
};

export default connect(mapStateToProps, null)(NewControl);
