import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  .page-assortiment__section-info {
    padding: 20px 0;
    width: 100%;
    & > div {
      width: calc(33.33% - 10px);
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;
    }
    .page-assortiment__section-info-element {
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      &.last {
        margin-right: 0;
        .block-info__entete {
          .entete__icon {
            transform: rotate(270deg);
          }
        }
        .block-info__content {
          padding: 13px 24px 7px;
          .content__ligne {
            position: relative;
            .ligne__attribut {
              width: 170px;
            }
            .ligne__value {
              width: calc(100% - 200px);
            }
            i {
              right: -8px;
              font-size: 24px;
              top: 7px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .page-assortiment__section-info__account-info_validity {
      margin-bottom: 20px;
      .block-info__entete {
        i {
          line-height: 20px;
          margin: 0px 10px;
        }
      }
      .block-info__content {
        padding: 10px 21px;
        .content__ligne {
          position: relative;
          .ligne__attribut {
            width: 30px;
          }
          .ligne__value {
            width: calc(100% - 60px);
            height: 37px;
          }
          input[type='date'] {
            position: relative;
            &::-webkit-calendar-picker-indicator {
              color: transparent;
              background: none;
              z-index: 1;
            }
          }
          i {
            right: 1px;
            top: 12px;
            font-size: 18px;
            color: #4a4a4a;
          }
        }
      }
    }
    .page-assortiment__section-info__account-info_planning {
      .block-info__content {
        padding: 9px 20px 5px;
        .content__ligne {
          .ligne__attribut {
            width: 67px;
          }
          .ligne__value {
            width: calc(100% - 93px);
          }
        }
      }
    }
  }
  .assortiment-action {
    text-align: right;
    overflow: hidden;
    a {
      padding: 11px 15px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      text-align: center;
    }
    &-spinner {
      float: right;
    }
    span {
      color: #d0021b;
      display: block;
    }
  }
  .assortiment-page {
    background-color: #f5f5f5;
    padding: 20px;
    .assortiment-page__header {
      padding-bottom: 40px;
      .assortiment-page__header-label {
        color: #01366b;
        font-size: 16px;
        line-height: 19px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .assortiment-page__content {
      .assortiment-page__select-block {
        width: 100%;
        display: inline-block;
        margin-bottom: 40px;
        .select-block {
          width: 260px;
          display: inline-block;
          margin-right: 20px;

          &__select {
            position: relative;
            &:after {
              content: '\\25bc';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              font-size: 9px;
              text-align: center;
              vertical-align: middle;
              pointer-events: none;
              color: #00a6ff;
            }
          }

          &:last-child {
            width: auto;
            margin-right: 0px;
            float: right;
          }
          .react-datepicker-wrapper {
            display: inline-block;
            width: 86%;
            .react-datepicker__input-container {
              display: inline-block;
              width: 100%;
              input[type='text'] {
                width: 100%;
              }
            }
          }
          input::placeholder {
            color: #00a6ff;
          }

          input[type='text'],
          select {
            width: calc(100% - 35px);
            border: 0;
            border-bottom: 1px solid rgba(0, 166, 255, 1);
            padding: 11px;
            color: #00a6ff;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
            letter-spacing: 1.06px;
            display: inline-block;
            text-align: center;
            outline: 0;
            background: none;
          }
          i {
            display: inline-block;
            color: #00a6ff;
          }
          select {
            background-image: url('../images/dropdown.jpg');
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          a {
            padding: 3px 20px 3px 20px;
            display: inline-block;
            width: 100%;
            i {
              font-size: 30px;
              margin-right: 5px;
              width: 30px;
              display: inline-block;
            }
            span {
              position: relative;
              top: -7px;
              line-height: 16px;
              letter-spacing: 1.06px;
              width: calc(100% - 35px);
              display: inline-block;
            }
          }
        }
      }
      .assortiment-page__selection-block {
        margin-top: 40px;
        .assortment-actions {
          display: inline-block;
          width: 100%;
          .assortment-actions__button-block {
            display: inline-block;
          }
          .assortment-actions__span-block {
            display: inline-block;
            float: right;
            position: relative;
            top: 8px;
            span:first-child {
              color: #01366b;
              font-weight: bold;
            }
            span {
              color: #4a4a4a;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  @media ${screen.laptop} {
    .assortiment-page {
      .assortiment-page__content {
        .assortiment-page__select-block {
          .select-block {
            &:last-child {
              width: 144px;
            }
            a {
              span {
                vertical-align: middle;
                position: unset;
              }
              i {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  @media ${screen.tablet} {
    .assortiment-page {
      .assortiment-page__content {
        .assortiment-page__select-block {
          .select-block {
            &:nth-child(3) {
              margin-top: 20px;
            }
            &:last-child {
              width: auto;
              margin-top: 20px;
            }
            a {
              span {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  @media ${screen.mobileL} {
    .assortiment-page {
      .assortiment-page__content {
        .assortiment-page__select-block {
          .select-block {
            &:nth-child(2) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
`;
