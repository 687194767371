export const userConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  RECOVER_PASSWORD_REQUEST: 'USER_RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS: 'USER_RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE: 'USER_RECOVER_PASSWORD_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'USER_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USER_CHANGE_PASSWORD_FAILURE',

  CHANGE_DASHBOARD_SUCCESS: 'USER_CHANGE_DASHBOARD_SUCCESS',
  CHANGE_DASHBOARD_FAILURE: 'USER_CHANGE_DASHBOARD_FAILURE',

  FETCH_INFORMATION_REQUEST: 'USER_FETCH_INFORMATION_REQUEST',
  FETCH_INFORMATION_SUCCESS: 'USER_FETCH_INFORMATION_SUCCESS',
  FETCH_INFORMATION_FAILURE: 'USER_FETCH_INFORMATION_ERROR',

  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'USER_UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'USER_UPDATE_PASSWORD_FAILURE',

  LOGIN_AS: 'USER_LOGIN_AS',
  LOGOUT_AS: 'USER_LOGOUT_AS',

  LOGOUT: 'USER_LOGOUT',

  TYPE_COMMERCIAL: 'commercial',
  TYPE_CUSTOMER: 'customer',
  TYPE_ADMIN: 'admin',
  TYPE_SUPER_ADMIN: 'superAdmin',
};
