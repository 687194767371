import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Aggrid from 'components/AggridV3/Aggrid';
import { productCardActions } from 'actions/ProductCard';
import { selectCurrentPlatformId } from 'selectors/platform';
import { pageContextActions } from 'actions/PageContext';
import { selectCurrentPageContext } from 'selectors/catalog';

import './Catalog.scss';

const AggridPage = ({ currentProduct, close, currentPlatformId }) => (
  <>
    {/* key is currentPlatformId to reload component on platform change */}
    <Aggrid key={currentPlatformId} currentProductCard={currentProduct} close={close} />
  </>
);

const mapStateToProps = (state) => {
  const { productCard } = state;
  return {
    currentProduct: productCard.currentProduct,
    currentPlatformId: selectCurrentPlatformId(state),
    pageContext: selectCurrentPageContext(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(productCardActions.chooseCurrentProduct(null)),
  pageContext: dispatch(pageContextActions.selectPageContext('PAGE_CATALOG')),
});

Aggrid.propTypes = {
  currentProduct: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AggridPage);
