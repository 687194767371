import styled from 'styled-components';

export default (Component) => styled(Component)`
  padding-bottom: 1px;
  .page-profil__alert-block {
    display: inline-block;
    border-radius: 8px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 100%;
  }

  .alert-block__icon-alert {
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
  }

  .alert-block__label {
    display: inline-block;
    width: 89%;
    font-weight: 500;
    p {
      margin: 0px;
      text-align: justify;
    }
  }
`;
