import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const FilterTagBarWrapper = styled.div`
  display: flex;
  align-items: center;

  .filter-tag-bar__tag-container {
    width: calc(100vw - 550px);
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

  .filter-tag-bar__row-counter {
    color: #01366b;
    font-weight: 700;
    margin-right: 10px;
    white-space: nowrap;
  }

  .filter-tag-bar__v-separator {
    width: 0;
    height: 16px;
    border: 0.5px solid #01366b;
    margin: 0 10px;
  }

  .filter-tag-bar__reset-button {
    display: flex;
    align-items: center;
    color: #01366b;
    font-weight: 700;
    margin-left: 10px;
    margin-right: 15px;
    span {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
  
  .filter-tag-bar__tag-list {
    display: flex;
    align-items: auto;
    overflow: auto;
    
    &:hover {
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar {
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      height: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: none;
      background-color: transparent;
    }
  }

  @media ${screen.laptopL} {
    .filter-tag-bar__tag-container {
      width: calc(100vw - 45px);
    }
  }
`;

export const FilterTagWrapper = styled.div`
  margin: 0 7px;
  padding-bottom: 5px;
  white-space: nowrap;
  .filter-tag__dismiss-button {
    padding-left: 10px;
    color: #ccc;
    cursor: pointer;
    &:hover {
      color: #4a4a4a;
    }
  }
`;
