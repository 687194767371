export const orderConstants = {
  FETCH_COUNT_REQUEST: 'ORDER_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'ORDER_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'ORDER_COUNT_FETCH_FAILURE',
  FETCH_REQUEST: 'ORDER_FETCH_REQUEST',
  FETCH_SUCCESS: 'ORDER_FETCH_SUCCESS',
  FETCH_FAILURE: 'ORDER_FETCH_FAILURE',
  RENEW_REQUEST: 'ORDER_RENEW_REQUEST',
  RENEW_SUCCESS: 'ORDER_RENEW_SUCCESS',
  RENEW_FAILURE: 'ORDER_RENEW_FAILURE',
  DOWNLOAD_ORDER_FORM_REQUEST: 'ORDER_DOWNLOAD_ORDER_FORM_REQUEST',
  DOWNLOAD_ORDER_FORM_SUCCESS: 'ORDER_DOWNLOAD_ORDER_FORM_SUCCESS',
  DOWNLOAD_ORDER_FORM_FAILURE: 'ORDER_DOWNLOAD_ORDER_FORM_FAILURE',
  DOWNLOAD_PREORDER_FORM_REQUEST: 'ORDER_DOWNLOAD_PREORDER_FORM_REQUEST',
  DOWNLOAD_PREORDER_FORM_SUCCESS: 'ORDER_DOWNLOAD_PREORDER_FORM_SUCCESS',
  DOWNLOAD_PREORDER_FORM_FAILURE: 'ORDER_DOWNLOAD_PREORDER_FORM_FAILURE',
  RATING_REQUEST: 'ORDER_RATING_REQUEST',
  RATING_SUCCESS: 'ORDER_RATING_SUCCESS',
  RATING_FAILURE: 'ORDER_RATING_FAILURE',
  ORDER_STATUS_PENDING_VALIDATION: 'STATUS_PENDING',
  ORDER_STATUS_APPROVED: 'STATUS_APPROVED',
  STATUS_ORDER_DELIVERED: 'STATUS_ORDER_DELIVERED',
  STATUS_ORDER_DELETED: 'STATUS_DELETED',
  STATUS_CREDIT: 'STATUS_CREDIT',
  PREORDER_STATUS_APPROVED: 'PREORDER_STATUS_APPROVED',
  ORDER_STATUS_PROCESSED: 'STATUS_PROCESSED',
  PREORDER_ORDER_STATUS_PROCESSED: 'PREORDER_STATUS_PROCESSED',
  ORDER_STATUS_PENDING_PREPARE: 'STATUS_PENDING_PREPARE',
  PREORDER_ORDER_STATUS_PENDING_PREPARE: 'PREORDER_STATUS_PENDING_PREPARE',
  ORDER_STATUS_FACTURED: 'STATUS_FACTURED',
  PREORDER_ORDER_STATUS_FACTURED: 'PREORDER_STATUS_FACTURED',
  ORDER_STATUS_REJECTED: 'STATUS_REJECTED',
  PREORDER_ORDER_STATUS_REJECTED: 'PREORDER_STATUS_REJECTED',
  ORDER_STATUS_ORDER_TO_BE_REVALIDATE: 'STATUS_TO_BE_REVALIDATE',
  PREORDER_STATUS_ORDER_TO_BE_REVALIDATE: 'PREORDER_STATUS_TO_BE_REVALIDATE',
  ORDER_FACTURED_ITEMS: 'FACTURED_ITEMS',
};
