import styled from 'styled-components';

export default (Component) => styled(Component)`
  height: 46px;
  background-color: #ebf7a2;
  border: 1px solid #a1d245;
  border-radius: 8px;
  padding: 0 20px;
  .icon,
  .label {
    display: inline-block;
    padding: 0 10px;
    i,
    p {
      font-weight: bold;
    }
  }
`;
