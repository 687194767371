import { authHeader, getPath, handleResponse } from 'helpers';
import { config } from './Config';

function login(username, password) {
  return makeLoginWith(username, password);
}

function loginWithRefreshToken(refreshToken) {
  return makeLoginWith(null, null, refreshToken);
}

function makeLoginWith(username, password, refreshToken = '') {
  const formData =
    refreshToken === ''
      ? `grant_type=${config.password_grantType}` +
        `&username=${username}` +
        `&password=${password}` +
        `&client_id=${config.apiClientId}` +
        `&client_secret=${config.apiSecret}`
      : `grant_type=${config.refreshToken_grantType}` +
        `&refresh_token=${refreshToken}` +
        `&client_id=${config.apiClientId}` +
        `&client_secret=${config.apiSecret}`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formData,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/oauth/v2/token`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      const datetime = new Date();
      const userTokens = user.expires_in
        ? { ...user, expires_at: datetime.setSeconds(datetime.getSeconds() + user.expires_in) }
        : user;

      if (user.access_token) {
        // store user details and token in local storage to keep user logged in
        localStorage.setItem('user', JSON.stringify(userTokens));
      }
      return userTokens;
    });
}

function recoverPassword(username) {
  const callback = `${window.location.protocol}//${window.location.hostname}/password-change`;

  const body = JSON.stringify({
    username,
    callback,
  });

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/oauth/resetting/sendemail`, requestOptions).then(handleResponse);
}

function changePassword(newPassword, newPasswordConfirmation, confirmationToken, isFirstLogin) {
  const body = JSON.stringify({
    newPassword,
    newPasswordConfirmation,
  });

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/oauth/resetting/reset/${confirmationToken}?isfirstLogin=${isFirstLogin}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('platform');
}

function fetchInformation() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/user/me`, requestOptions).then(handleResponse);
}

function updatePassword(oldPassword, newPassword, newPasswordConfirmation) {
  const body = JSON.stringify({
    id: 'me',
    plainPassword: newPassword,
    plainPasswordConfirm: newPasswordConfirmation,
    oldPassword,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/user/update/password`, requestOptions).then(handleResponse);
}

function updateInfo(user) {
  const body = JSON.stringify(user);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/user/update`, requestOptions).then(handleResponse);
}

function loginAs(username) {
  const userTokens = JSON.parse(localStorage.getItem('user'));
  userTokens.loggedAs = username;
  localStorage.setItem('user', JSON.stringify(userTokens));
}

function logoutAs() {
  const userTokens = JSON.parse(localStorage.getItem('user'));
  delete userTokens.loggedAs;
  localStorage.setItem('user', JSON.stringify(userTokens));
}

function toggleDashboard() {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PATCH',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/user/dashboard`, requestOptions).then(handleResponse);
}

function getHomepagePath() {
  return getPath('aggrid');
}

export const userService = {
  login,
  loginAs,
  loginWithRefreshToken,
  logout,
  logoutAs,
  recoverPassword,
  changePassword,
  updatePassword,
  fetchInformation,
  toggleDashboard,
  getHomepagePath,
  updateInfo,
};
