export const categoryConstants = {
  FETCH_REQUEST: 'CATEGORY_FETCH_REQUEST',
  FETCH_SUCCESS: 'CATEGORY_FETCH_SUCCESS',
  FETCH_FAILURE: 'CATEGORY_FETCH_FAILURE',

  TYPE_GROUP: 'group',
  TYPE_SECTOR: 'Secteur',
  TYPE_SHELF: 'Rayon',
  TYPE_FAMILY: 'Famille',
  TYPE_SUB_FAMILY: 'Sous Famille',

  STATUS_CATALOG: 'catalog',
  STATUS_PREORDER: 'preorder',
  STATUS_SELECTION: 'selection',
  STATUS_ORDER: 'order',
};
