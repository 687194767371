import { toastConstants } from 'constants/Toast.constants';

export const toastActions = {
  setToastList,
  addToast,
  removeToast,
};

function setToastList(toastList) {
  return (dispatch) => dispatch({ type: toastConstants.SET_TOAST_LIST, toastList });
}

function addToast(toast) {
  return (dispatch) => dispatch({ type: toastConstants.ADD_TOAST, toast });
}

function removeToast(toastId) {
  return (dispatch) => dispatch({ type: toastConstants.REMOVE_TOAST, toastId });
}
