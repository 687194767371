import React from 'react';
import PropTypes from 'prop-types';
import { updateFields } from './updateTableFields';
import DashboardSectionDiv from './DashboardSectionDiv';
import DashboardSectionTd from './DashboardSectionTd';

const DashboardSection = ({ type, label, header, data, responsive }) => (
  <div className="section-label">
    <span>{label}</span>
    {responsive ? (
      <DashboardSectionDiv header={header} data={updateFields(data, type)} />
    ) : (
      <DashboardSectionTd type={type} header={header} data={data} />
    )}
  </div>
);

DashboardSection.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.array,
  data: PropTypes.array,
  responsive: PropTypes.bool,
};

export default DashboardSection;
