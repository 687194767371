import styled from 'styled-components';

export default (Component) => styled(Component)`
  .onglet-menu {
    border-bottom: 1px solid #01366b;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      li {
        border-top: 1px solid #01366b;
        border-left: 1px solid #01366b;
        border-right: 1px solid #01366b;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin-right: 4px;
        display: flex;
        a,
        span {
          font-size: 13px;
          line-height: 16px;
          padding: 8px 10px;
          color: #01366b;
          display: block;
          text-decoration: none;
          cursor: pointer;
          font-weight: 600;
        }
        &:first-child {
          border-left: 1px solid #01366b;
        }
        &.active {
          a {
            background: #01366b;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .onglet-menu {
      overflow-x: scroll;
    }
  }
`;
