/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterConstants } from 'pages/../constants';
import LinearLoader from 'components/Core/Spinner/LinearLoader';
import CheckBox from './CheckBox';

const INIT_LIMIT = 10;
const {
  SECTIONS: {
    manufacturer: { sectionKey: manufacturerSectionKey },
  },
} = filterConstants;

class ManufacturersFilter extends React.Component {
  state = {
    nameManufacturer: '',
    limit: INIT_LIMIT,
  };

  updateNameManufacturer = (e) => {
    this.setState({
      nameManufacturer: e.target.value,
    });
  };

  increaseLimit = () => {
    this.setState((prevState) => {
      const { limit } = prevState;
      return {
        limit: limit + INIT_LIMIT,
      };
    });
  };

  getFilteredManufacturers = () => {
    const { manufacturers } = this.props;
    const { nameManufacturer, limit } = this.state;
    // Regex can be changed to have a better search
    const regex = nameManufacturer.toUpperCase();
    const effectiveList = !nameManufacturer
      ? [...manufacturers]
      : manufacturers.filter(({ name }) => name.toUpperCase().search(regex) > -1);
    // sort
    effectiveList.sort(
      (manufacturerA, manufacturerB) =>
        Number(manufacturerB.numberDataAvailable) - Number(manufacturerA.numberDataAvailable)
    );
    return effectiveList.slice(0, Number(limit));
  };

  render() {
    const { isLoading, selectedValues, updateFilter } = this.props;
    const { limit } = this.state;
    const filteredManufacturers = this.getFilteredManufacturers();
    const canSeeMore = Number(filteredManufacturers.length) > Number(limit);
    return (
      <div className="filteropen__catalogue">
        <h5>Marques</h5>
        {isLoading ? (
          <LinearLoader />
        ) : (
          <React.Fragment>
            <input
              type="text"
              placeholder="Nom de la marque"
              onChange={this.updateNameManufacturer}
              className="filteropen__catalogue_input-name-manufacturer"
            />
            <React.Fragment>
              <div className="manufacturer-checkboxes">
                {filteredManufacturers.map(({ numberDataAvailable, name }) => {
                  const isChecked = selectedValues.includes(name);
                  const updateValues = isChecked
                    ? selectedValues.filter((v) => v !== name)
                    : [...selectedValues, name];
                  return (
                    <CheckBox
                      handleCheck={() => updateFilter(manufacturerSectionKey, updateValues)}
                      name={name}
                      value={name}
                      count={numberDataAvailable}
                      isChecked={isChecked}
                    />
                  );
                })}
              </div>
              {!filteredManufacturers.length && (
                <div className="bold">
                  <span>Aucune marque trouvée</span>
                </div>
              )}
            </React.Fragment>
            {canSeeMore && (
              <div className="bold see-more" onClick={this.increaseLimit}>
                <span>Voir plus</span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

ManufacturersFilter.propTypes = {
  isLoading: PropTypes.bool,
  manufacturers: PropTypes.array,
  selectedValues: PropTypes.array,
  updateFilter: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    manufacturer: { isLoading, filterManufacturers },
  } = state;
  return {
    isLoading,
    manufacturers: filterManufacturers,
  };
};

export default connect(mapStateToProps)(ManufacturersFilter);
