import styled from 'styled-components';

export default (Component) => styled(Component)`
  .actions {
    margin-top: 15px;
    text-align: center;
    a {
      margin: 10px 5px;
      height: 40px;
      padding: 10px 20px;
    }
  }
  .nodes {
    text-align: center;
  }
`;
