import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { IoReload } from '@react-icons/all-files/io5/IoReload';
import reject from 'lodash.reject';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import InvoiceOrderListControl from 'components/InvoiceOrderListControl/InvoiceOrderListControl';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import Icon from 'components/Icon/Icon';
import { AiOutlineRobot } from '@react-icons/all-files/ai/AiOutlineRobot';
import OrderBreadCrumb from 'pages/Order/OrderBreadcumb';
import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';
import { orderActions, containerActions } from 'actions';
import { selectOrderCollections, selectOrderCollectionsCount } from 'selectors/order';
import containerDry from 'styles/assets/images/container-DRY.png';
import containerReefer from 'styles/assets/images/container-REEFER.png';
import {
  dateTimeToFormatedDate,
  getIconClassByType,
  getPath,
  history,
  getValueOfAttribut,
} from 'helpers';
import {
  categoryConstants,
  orderConstants,
  userConstants,
  platformConstants,
} from '../../../constants';
import './OrderList.scss';

const OrderList = ({
  user,
  match,
  currentPlatformId,
  fetchOrderList,
  fetchOrderListTotalRowCount,
  orderCollections,
  orderCollectionsCount,
  downloadPdf,
  renewOrder,
  getContainers,
  platform: { attributs },
}) => {
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [currentCollection, setCurrentCollection] = useState(null);
  const [orderTotalRowCount, setOrderTotalRowCount] = useState(null);
  const [preorderFilter, setPreorderFilter] = useState([]);
  const limit = 25;
  const { TYPE_CUSTOMER: USER_TYPE_CUSTOMER } = userConstants;

  let filter = {
    platformId: currentPlatformId,
    limit: 25, // Default
    offset, // Default
    status: [3, 14, 13], // Default
    or: [['o.number', 'like', `%25${searchInput}%25`]],
  };

  if (
    preorderFilter.length > 0 &&
    !(
      preorderFilter.includes(categoryConstants.STATUS_PREORDER) &&
      preorderFilter.includes(categoryConstants.STATUS_ORDER)
    )
  ) {
    filter = { ...filter, isPreorder: preorderFilter.includes(categoryConstants.STATUS_PREORDER) };
  }

  if (user.type !== USER_TYPE_CUSTOMER) {
    filter = {
      ...filter,
      or: [
        ...filter.or,
        ['c.name', 'like', `%25${searchInput}%25`],
        ['c.ext_code', 'like', `%25${searchInput}%25`],
      ],
    };
  }

  useEffect(() => {
    if (orderCollections) {
      const orderCollection = orderCollections.find((collection) =>
        isEqual(collection.filter, filter)
      );
      setCurrentCollection(orderCollection);
      if (
        orderCollection &&
        !orderCollection.isLoading &&
        !isEqual(orderCollection, currentCollection)
      ) {
        const newItems = orderCollection.items.map((item) => ({
          ...item,
          handleRowClick: () => redirectToOrderDetail(item.id),
        }));
        setItems([...items, ...newItems]);
      }
    }
  }, [orderCollections]);

  useEffect(() => {
    if (orderCollectionsCount) {
      const currentCount = orderCollectionsCount.find((count) => isEqual(count.filter, filter));
      if (currentCount && !currentCount.isLoading) {
        setOrderTotalRowCount(currentCount.totalRowsNumber);
      }
    }
  }, [orderCollectionsCount]);

  useEffect(() => {
    setOffset(0);
    setItems([]);
    fetchOrderListTotalRowCount(filter);
    fetchOrderList(filter, 0, limit);
  }, [searchInput, preorderFilter]);

  // const { type: userType } = user !== {} && user;
  const { type: userType } = user;

  const labelStatus = {
    3: 'En préparation',
    5: 'Annulé',
    14: 'Livré',
  };

  const columns = React.useMemo(() => [
    {
      id: 'validationDate',
      Header: 'Date de validation',
      accessor: (item) => item.date_validate,
      Cell: ({ value }) => dateTimeToFormatedDate(value, 1),
    },
    {
      id: 'OrderNumber',
      Header: 'N° de commande',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <>
          {value.number}{' '}
          {value.comment && value.comment.includes('[commande automatique]') ? (
            <>
              {'('}
              <AiOutlineRobot />
              {') '}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      id: 'invoiceNumber',
      Header: 'N° de facture',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <>
          {!value.invoice && '-'}
          {value.invoice && <div>{value.invoice}</div>}
        </>
      ),
    },
    {
      id: 'ClientCode',
      Header: 'Code client',
      isHidden: user.type === USER_TYPE_CUSTOMER,
      accessor: (item) => item.client_ext_code,
    },
    {
      id: 'ClientName',
      Header: 'Nom Client',
      isHidden: user.type === USER_TYPE_CUSTOMER,
      accessor: (item) => item.name,
    },
    {
      id: 'nbContainers',
      Header: 'Nb containers',
      accessor: (item) => item,
      Cell: ({ value }) => {
        if (value.temperature === 'SEC') {
          if (value.containers_V2 && value.containers_V2.DRY > 0) {
            return (
              <div className="col-container">
                <span>{value.containers_V2.DRY}</span>
                <span className="type sec">
                  {' '}
                  <img src={containerDry} alt="SEC" />
                </span>
              </div>
            );
          }
        }

        if (value.temperature === 'POSITIF' || value.temperature === 'FROID') {
          if (value.containers_V2 && value.containers_V2.REEFER > 0) {
            return (
              <div className="col-container">
                <span>{value.containers_V2.REEFER}</span>
                <span className="type reefer">
                  {' '}
                  <img src={containerReefer} alt="FROID" />
                </span>
              </div>
            );
          }
        }
        return '';
      },
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div>
          <div>{value.references} ref.</div>
          <div>{value.packages} colis</div>
        </div>
      ),
    },
    {
      id: 'deliveryDate',
      Header: 'Livraison',
      accessor: (item) =>
        item.is_preorder === '1' ? item.preorder_delivery_date : item.date_delivery,
      Cell: ({ value }) => dateTimeToFormatedDate(value),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.price,
      Cell: ({ value }) => (
        <div className="order-list__total-container">{formatPrice(value)} € HT</div>
      ),
    },
    {
      id: 'callToAction',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="order-list__row-action__container">
          <div className="order-list__row-action__separator" />
          <div className="order-list__row-action__action-container">
            <span
              onClick={(event) => {
                event.stopPropagation();
                downloadPdf(value);
              }}
            >
              <i className="icon-bdc" />
            </span>
            {user.type === USER_TYPE_CUSTOMER && (
              <span
                className="order-list__row-action__renew"
                onClick={(event) => {
                  event.stopPropagation();
                  renewOrder(value.id);
                }}
              >
                <IoReload />
                Renouveler
              </span>
            )}
          </div>
          <div className="order-list__row-action__separator" />
        </div>
      ),
    },
    {
      id: 'type',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="order-list__label-container">
          <i className={`${getIconClassByType(value.temperature)} temp-icon`} />
          {Number(value.is_preorder) === 1 && <Icon content="\e941" />}
          {Number(value.is_preorder) === 0 && <Icon content="\e94c" />}
        </div>
      ),
    },
    {
      id: 'label',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item.status,
      Cell: ({ value }) => (
        <div>
          {value === '3' && (
            <div className="order-list__label order-list__approved-label">En préparation</div>
          )}
          {value === '14' && (
            <div className="order-list__label order-list__delivered-label">Livré</div>
          )}
          {value === '5' && (
            <div className="order-list__label order-list__deleted-label">Annulé</div>
          )}
        </div>
      ),
    },
  ]);

  const detectContainerisation = (columns) => {
    let result = columns;
    const { KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;
    if (Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) !== 1) {
      result = reject(columns, ({ id }) => id === 'nbContainers');
    }
    return result;
  };

  const handleSearchEnterPress = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value !== searchInput) {
        setSearchInput(event.target.value);
      }
    }
  };

  const redirectToOrderDetail = (orderId) =>
    history.push(getPath('orderdetail').replace(':id', orderId));

  const toggleItem = (value) => {
    const newFilter = preorderFilter.includes(value)
      ? preorderFilter.filter((item) => item !== value)
      : [...preorderFilter, value];
    setPreorderFilter(newFilter);
  };

  const data = React.useMemo(() => items, [items]);

  return (
    <div className="order-list__container fade-in">
      <div className="order-list__breadcrum">
        <OrderBreadCrumb
          type="list_status"
          matchPath={match.path}
          userType={userType !== undefined ? userType : ''}
        />
      </div>
      <div className="order-list__content">
        <InvoiceOrderListControl
          match={match}
          filters={preorderFilter}
          toggleItem={toggleItem}
          handleSearchEnterPress={handleSearchEnterPress}
        />
        <ReactTable
          columns={detectContainerisation(columns)}
          data={data}
          isLoading={currentCollection && currentCollection.isLoading && !data.length}
          next={() => {
            fetchOrderList(filter, offset + limit, limit);
            setOffset(offset + limit);
          }}
          totalRowCount={orderTotalRowCount}
          loadedRowCount={data.length}
        />
      </div>
    </div>
  );
};

OrderList.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  orderCollections: PropTypes.arrayOf(PropTypes.object),
  currentPlatformId: PropTypes.number,
  fetchOrderList: PropTypes.func,
  fetchOrderListTotalRowCount: PropTypes.func,
  downloadPdf: PropTypes.func,
  renewOrder: PropTypes.func,
  orderCollectionsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  platform: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, platform } = state;
  const currentPlatformId = selectCurrentPlatformId(state);
  return {
    user: user.information,
    selectedPlatform: selectCurrentPlatform(state),
    currentPlatformId,
    orderCollections: selectOrderCollections(state),
    orderCollectionsCount: selectOrderCollectionsCount(state),
    platform: platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrderList: (filter, offset, limit) =>
    dispatch(orderActions.fetchItems(filter, offset, limit)),
  fetchOrderListTotalRowCount: (filter) => dispatch(orderActions.fetchTotalRowsNumber(filter)),
  downloadPdf: (order) => dispatch(orderActions.downloadOrderFormPdf(order)),
  renewOrder: (id) => dispatch(orderActions.renewOrder(id, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
