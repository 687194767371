import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectCurrentInvoice = (state) => {
  return get(state, 'invoice.current', []);
};

export const selectInvoiceCollections = (state) => {
  return get(state, 'invoice.collections', []);
};

export const selectInvoiceListByFilter = (state, filter) => {
  const collections = get(state, 'invoice.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectInvoiceCollectionsCount = (state) => {
  return get(state, 'invoice.collectionsCount', []);
};

export const selectInvoiceTotalRowCountByFilter = (state, filter) => {
  const collections = get(state, 'invoice.collectionsCount', []);
  const collectionCount = collections.find((collection) => isEqual(collection.filter, filter));

  return get(collectionCount, 'totalRowsNumber', 0);
};
