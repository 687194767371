import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LayoutWithModal } from 'hocs';
import { userActions } from 'actions';
import Navigation from 'components/Core/Utils/Navigation';
import FilterMenu from 'components/Filter/FilterMenu';
import Footer from 'components/Core/Utils/Footer';
import { userConstants } from '../constants';
import Loading from '../pages/Loading/Loading';

const ContentWrapper = styled.div`
  margin-top: 61px;
  min-height: calc(
    (var(--vh, 1vh) * 100) - ${(props) => (props.hasFooter ? '121px' : '61px')}
      /* use custom vh property to prevent vertical shift on mobile browsers */
  );
`;

class Layout extends React.Component {
  componentDidMount() {
    const { user, fetchUserInformation } = this.props;
    const { information: userInformation, isLoading: userLoading } = user;
    if (!Object.keys(userInformation).length && userLoading !== true) {
      fetchUserInformation();
    }
  }

  hasFooter() {
    const { user } = this.props;

    return user.information.type === userConstants.TYPE_CUSTOMER;
  }

  render() {
    const { component: Component, filter, loading, hasGrid, ...props } = this.props;
    const { isOpen, ...filterProps } = filter;
    const { canDisplayWebSite } = loading;

    return (
      <main>
        {isOpen && <FilterMenu {...filterProps} />}
        {canDisplayWebSite && (
          <div className="navigation fade-in">
            <Navigation />
          </div>
        )}
        {canDisplayWebSite && (
          <ContentWrapper hasFooter={this.hasFooter()} className="content fade-in">
            <Component {...props} />
          </ContentWrapper>
        )}
        {canDisplayWebSite && <Footer match={props.match || {}} />}
        {!canDisplayWebSite && (
          <div className="fade-in">
            <Loading {...props} />
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, filter, platform, message, loading } = state;
  return {
    user,
    filter,
    platform,
    message,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserInformation: () => dispatch(userActions.fetchInformation()),
});

Layout.propTypes = {
  user: PropTypes.object,
  filter: PropTypes.object,
  fetchUserInformation: PropTypes.func,
  component: PropTypes.func,
  loading: PropTypes.object,
  hasGrid: PropTypes.bool,
};

Layout.defaultProps = {
  hasGrid: false,
};

const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);

const WithModal = (props) => <LayoutWithModal layout={ConnectedLayout} {...props} />;

export default WithModal;
