import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body {
    text-align: center;
    span {
      font-size: 18px;
      font-weight: bold;
    }
    .modal-body__button-block {
      margin-top: 35px;
      a {
        padding: 13px;
        margin: 0 10px;
        width: 100px;
      }
      a:nth-child(1) {
        border: 1px solid #00a6ff;
        color: #00a6ff;
      }
    }
  }
`;
