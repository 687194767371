import React from 'react';
import PropTypes from 'prop-types';

const DashboardSectionDiv = ({ data }) => (
  <div className="home-page__data-section">
    {data.map((datas) => (
      <div className="data-section">
        {Object.keys(datas).map((Key) => (
          <div className="data-section__content">
            <span>{convertKey(Key)}</span>
            <span>{datas[Key]}</span>
          </div>
        ))}
      </div>
    ))}
  </div>
);

const convertKey = (key) => {
  switch (key) {
    case 'lastname':
      return 'Vendeur';
    case 'monthly_ca':
      return 'CA Mois';
    case 'monthly_edge':
      return 'Marge mois';
    case 'percent':
      return '% Marge';
    case 'ratioCA':
      return 'R/0 CA';
    case 'ratioEdge':
      return 'R/O Marge';
    case 'commandNumber':
      return 'NB Commandes mois';
    case 'clientNumber':
      return 'NB Clients';
    case 'cartEdge':
      return 'Panier moyen';
    default:
      return '';
  }
};
DashboardSectionDiv.propTypes = {
  data: PropTypes.array,
};

export default DashboardSectionDiv;
