import { productConstants } from 'constants/Product.constants';

const initState = {
  product: {},
  favorites: {},
  promotion: {},
  isLoading: false,
};

export function product(state = initState, action) {
  switch (action.type) {
    case productConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case productConstants.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        product: action.product,
      };
    case productConstants.FETCH_FAILURE:
      return state;
    case productConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case productConstants.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotion: action.promotion,
      };
    case productConstants.UPDATE_FAILURE:
      return state;
    case productConstants.FETCH_FAVORITE_LIST_REQUEST:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: true,
        },
      };
    case productConstants.FETCH_FAVORITE_LIST_SUCCESS:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: false,
          items: action.favorites,
          itemsCount: action.favorites.length,
        },
      };
    case productConstants.FETCH_FAVORITE_LIST_FAILURE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: false,
          error: action.error,
        },
      };
    case productConstants.SET_FAVORITE_COUNT:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          itemsCount: action.counters,
        },
      };
    case productConstants.LIKE_REQUEST:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: true,
        },
      };
    case productConstants.LIKE_SUCCESS:
      return {
        ...state,
        favorites: {
          isLoading: false,
          items: action.favorites,
          // itemsCount: action.favorites.length,
          itemsCount: state.favorites.itemsCount + 1,
        },
      };
    case productConstants.LIKE_FAILURE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: false,
          error: action.error,
        },
      };
    case productConstants.UNLIKE_REQUEST:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: true,
        },
      };
    case productConstants.UNLIKE_SUCCESS:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: false,
          itemsCount: state.favorites.itemsCount - 1,
        },
      };
    case productConstants.UNLIKE_FAILURE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
