import styled from 'styled-components';

export default (Component) => styled(Component)`
  h5 {
    color: #01366b;
    padding-bottom: 20px;
    .required-icon {
      width: 1%;
    }
  }
  .required-icon {
    display: inline-block;
    text-align: right;
    position: relative;
    top: 3px;
    line-height: 18px;
    font-weight: bold;
    font-size: 14px;
    color: #d0021b;
  }
  .new__message-send {
    position: relative;
    label {
      cursor: pointer;
    }
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid rgba(120, 120, 120, 0.54);
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 2px;
        width: 5px;
        height: 12px;
        border: solid #01366b;
        border-width: 0 2px 2px 0px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    input[type='checkbox']:checked ~ .checkmark:after {
      display: block;
    }
    h5 {
      padding-left: 25px;
    }
  }
  .modal-new-message__red-text {
    color: red;
  }
  .page-profil__alert-bloc {
    width: 100% .alert-bloc__label {
      display: block;
      text-align: center;
      color: #d0021b;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      text-align: center;
      &:before {
        content: '*';
      }
    }
  }
  input {
    border: 0;
    border-bottom: 1px solid rgba(120, 120, 120, 0.6) !important;
    color: #787878;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    outline: none;
  }
  .new__message-idclient {
    position: relative;
    margin-bottom: 20px;
    input {
      width: 100%;
      padding: 10px 30px;
    }
    i {
      position: absolute;
      left: 10px;
      padding: 10px 0;
      &:before {
        color: #4a4a4a;
      }
    }
  }
  .new__message_date {
    margin-bottom: 20px;
    position: relative;
    div {
      display: inline-block;
      position: relative;
      i {
        position: absolute;
        top: 15px;
        right: 22px;
        font-size: 18px;
        display: inline-block;
        width: 10%;
      }
      &.debut {
        width: 47%;
      }
      &.fin {
        width: 47%;
        margin-left: 20px;
      }
      .required-icon {
        width: 12%;
      }

      .react-datepicker-wrapper {
        width: 80%;
        .react-datepicker__input-container {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
    input {
      padding: 10px;
      width: calc(100% - 30px);
    }
  }
  textarea {
    border: 1px solid #aeaeae;
    width: 100%;
    padding: 10px;
    color: #787878;
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 15px;
    font-family: Montserrat;
    resize: none;
    overflow: hidden;
  }
  .new__message-objet {
    margin-bottom: 20px;
    textarea {
      height: 40px;
    }
  }
  .new__message-msg {
    margin-bottom: 40px;
    textarea {
      height: 113px;
    }
  }
  a {
    padding: 11px 15px;
    width: 135px;
    margin: 20px auto 10px auto;
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.06px;
    line-height: 16px;
    text-align: center;
  }

  @media (min-width: 320px) and (max-width: 544px) {
    .new__message_date {
      div {
        &.debut {
          width: 100%;
        }
        &.fin {
          width: 100%;
          margin-left: 0;
        }
        .required-icon {
          width: 12%;
        }
      }
    }
  }
  @media screen and (max-width: 320px) {
    .new__message-objet {
      textarea {
        height: 50px;
      }
    }
  }
`;
