export const commercialConstants = {
  FETCH_REQUEST: 'COMMERCIAL_FETCH_REQUEST',
  FETCH_SUCCESS: 'COMMERCIAL_FETCH_SUCCESS',
  FETCH_FAILURE: 'COMMERCIAL_FETCH_FAILURE',

  FETCH_COUNT_REQUEST: 'COMMERCIAL_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'COMMERCIAL_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'COMMERCIAL_COUNT_FETCH_FAILURE',

  FETCH_SALES_NUMBERS_REQUEST: 'FETCH_SALES_NUMBERS_REQUEST',
  FETCH_SALES_NUMBERS_SUCCESS: 'FETCH_SALES_NUMBERS_SUCCESS',
  FETCH_SALES_NUMBERS_FAILURE: 'FETCH_SALES_NUMBERS_FAILURE',

  ADD_REQUEST: 'COMMERCIAL_ADD_REQUEST',
  ADD_SUCCESS: 'COMMERCIAL_ADD_SUCCESS',
  ADD_FAILURE: 'COMMERCIAL_ADD_FAILURE',

  UPDATE_REQUEST: 'COMMERCIAL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMMERCIAL_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMMERCIAL_UPDATE_FAILURE',
};
