import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ModalBloqueClientStyle from './ModalDelete.style';

const handleClick = (callBack, closeModal) => {
  callBack();
  closeModal();
};

const BloquageClient = ({ className, callBack, closeModal }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__button-block">
        <ButtonPrimary onClick={() => closeModal()}>NON</ButtonPrimary>
        <ButtonPrimary onClick={() => handleClick(callBack, closeModal)}>Oui</ButtonPrimary>
      </div>
    </div>
  </div>
);
BloquageClient.propTypes = {
  className: PropTypes.string,
  callBack: PropTypes.func,
  closeModal: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});
export default connect(null, mapDispatchToProps)(ModalBloqueClientStyle(BloquageClient));
