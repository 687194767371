import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const promotionService = {
  fetchPromotionList,
};

function fetchPromotionList(platformId) {
  const headers = {
    ...authHeader(),
  };

  const options = JSON.stringify({
    platformId,
    sorted: true,
  });

  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/v2.0/promotions?options=${options}`, requestOptions).then(
    handleResponse
  );
}
