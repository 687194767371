import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { getPath, history } from 'helpers';
import { checkMatchRoute } from 'helpers/Routes';
import {
  HamburgerMenuItem,
  HamburgerSubMenuItem,
  HamburgerTitleWrapper,
  DropdownWrapper,
  SubDropdownWrapper,
} from './Hamburger.style';

const HeaderHamburger = ({ menuElementList }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [subListOpen, setSubListOpen] = useState([]);

  const toggleDropdown = (isOpen) => {
    setIsListOpen(isOpen);
  };

  const toggleSubDropdown = (isOpen, index) => {
    const newSublistOpen = [...subListOpen];
    newSublistOpen[index] = isOpen;
    setSubListOpen(newSublistOpen);
  };

  const dropdownTitle = (
    <HamburgerTitleWrapper listOpen={isListOpen}>
      <div className="hamburger__title-container__line" />
      <div className="hamburger__title-container__line" />
      <div className="hamburger__title-container__line" />
    </HamburgerTitleWrapper>
  );

  return (
    <DropdownWrapper listOpen={isListOpen}>
      <Dropdown
        headerTitle={dropdownTitle}
        defaultIcon={false}
        toggleDropDown={toggleDropdown}
        listOpen={isListOpen}
      >
        <div className="dd-list">
          {menuElementList.map((element, index) =>
            !element.children ? (
              <HamburgerMenuItem
                onClick={() => {
                  history.push(getPath(element.routeName));
                }}
                active={checkMatchRoute(getPath(element.routeName))}
              >
                {element.title}
              </HamburgerMenuItem>
            ) : (
              <HamburgerMenuItem active={checkMatchRoute(null)} noPaddingBottom>
                <SubDropdownWrapper listOpen={subListOpen[index]}>
                  <Dropdown
                    headerTitle={element.title}
                    defaultIcon={false}
                    toggleDropDown={(isOpen) => {
                      toggleSubDropdown(isOpen, index);
                    }}
                    listOpen={subListOpen[index]}
                  >
                    <ul className="dd-list">
                      {element.children.map((child) => (
                        <HamburgerSubMenuItem>
                          <Link to={getPath(child.routeName)}>{child.title}</Link>
                        </HamburgerSubMenuItem>
                      ))}
                    </ul>
                  </Dropdown>
                </SubDropdownWrapper>
              </HamburgerMenuItem>
            )
          )}
        </div>
      </Dropdown>
    </DropdownWrapper>
  );
};

HeaderHamburger.propTypes = {
  menuElementList: PropTypes.arrayOf(PropTypes.object),
};

export default HeaderHamburger;
