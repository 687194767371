import React from 'react';
import PropTypes from 'prop-types';
import { getPath } from 'helpers';
import { catalogConstants } from 'components/../constants';
import Onglets from 'components/Onglet/OngletList';
import withCartStyle from './Cart.style';

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;

const getListFromDataNumber = (id, countDry, countFresh, countFrozen) => {
  const ongletList = [];
  if (countDry !== 0) {
    ongletList.push({
      link: getPath('cart').replace(':id', id).replace(':temperature?', TEMPERATURE_DRY),
      icon: '\\e91a',
      iconClassName: 'icon-dry',
      name: `SEC (${countDry})`,
      type: TEMPERATURE_DRY,
    });
  }
  if (countFresh !== 0) {
    ongletList.push({
      link: getPath('cart').replace(':id', id).replace(':temperature?', TEMPERATURE_FRESH),
      icon: '\\e922',
      iconClassName: 'icon-apple',
      name: `FRAIS (${countFresh})`,
      type: TEMPERATURE_FRESH,
    });
  }
  if (countFrozen !== 0) {
    ongletList.push({
      link: getPath('cart').replace(':id', id).replace(':temperature?', TEMPERATURE_FROZEN),
      icon: '\\e914',
      iconClassName: 'icon-snow',
      name: `SURGELE (${countFrozen})`,
      type: TEMPERATURE_FROZEN,
    });
  }
  return ongletList;
};

const OngletCart = ({ className, id, countDry, countFresh, countFrozen, status }) => (
  <div className={className}>
    <div className="page">
      <div className="page-content">
        <Onglets
          list={getListFromDataNumber(id, countDry, countFresh, countFrozen)}
          active={status}
        />
      </div>
    </div>
  </div>
);

OngletCart.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  countDry: PropTypes.number,
  countFresh: PropTypes.number,
  countFrozen: PropTypes.number,
};
export default withCartStyle(OngletCart);
