import styled from 'styled-components';

export default (Component) => styled(Component)`
  margin-bottom: 15px;
  .home-page {
    background-color: #f5f5f5;
    height: 100vh;
    display: inline-block;
  }

  .heading__path div {
    display: inline-block;
  }

  .heading__icon-home {
    color: #01366b;
    padding-right: 5px;
    font-size: 10px;
  }

  .heading__label {
    color: #01366b;
    height: 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: none !important;
    display: inline-block;
  }

  .heading__icon-arrow {
    font-size: 9px;
    color: #01366b;
    margin-right: 5px;
    margin-left: 5px;
  }

  @media only screen and (max-width: 320px) {
    .heading__icon-arrow {
      font-size: 7px;
      margin-right: 7px;
      margin-left: 3px;
    }
  }
`;
