import styled from 'styled-components';

export const MoqQuantityDIV = styled.div`
// Override the width specified in the cell class name
width: unset!important;

display: inline-block;

.td-libelle-quantite {
  min-width: 150px;
}

.input-quantite-cadre {
  box-sizing: border-box;
  width: 63px;
  height: 25px;
  border: 1px solid #54BAFC;
  border-radius: 4px;
  text-align: center;
  padding: 0px 5px;
}

.input {
  color: #00A6FF;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-right: 4px;
  margin-left: 4px;
}

.quantite-icon {
  margin: 0 2px;
  cursor: pointer;
`;
