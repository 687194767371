import styled from 'styled-components';

export default (Component) => styled(Component)`
  .cart__section-temperature {
    padding: 15px;
  }
  .permanent-cart__ref {
    color: #01366b;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 35px;
    margin: 50px;
  }
  .cart__section-temperature {
    padding: 15px;
  }
`;
