import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tableTypes } from 'components/Table/tableTypes';
import TableWrapper from 'components/Table';
import { creditActions } from 'actions';
import { getCollection } from 'helpers';
import Moment from 'moment';
import OrderBreadCrumb from '../Order/OrderBreadcumb';
import withOrderDetailStyle from '../Order/OrderDetail/withOrderDetailStyle';
import { COLUMNS } from './TableOptionsDetails';
import { userConstants, orderConstants } from '../../constants';

const { TABLE_TYPE_CREDIT_ITEMS } = tableTypes;
const { TYPE_CUSTOMER } = userConstants;
const { STATUS_CREDIT } = orderConstants;

class CreditDetail extends React.Component {
  componentDidMount = () => {
    const { getItemByID, match, platformId } = this.props;
    const { id } = (match && match.params) || {};
    if (platformId) {
      getItemByID({ platform: platformId, id });
    }
  };

  componentDidUpdate(prevProps) {
    const { getItemByID, match, platformId } = this.props;
    const { platformId: prevPlatformId } = prevProps;
    const { id } = (match && match.params) || {};
    if (platformId && platformId !== prevPlatformId) {
      getItemByID({ platform: platformId, id });
    }
  }

  getCredit = () => {
    const { credit, match, platformId } = this.props;
    const { collections } = credit;
    const { id } = (match && match.params) || {};
    const collection = getCollection(collections, { platform: platformId, id });
    if (collection && collection.items && collection.items.length) {
      return collection.items[0];
    }
    return null;
  };

  render() {
    const { match, className, user, platformId } = this.props;
    const { id } = (match && match.params) || {};
    const items = this.getCredit();

    return (
      <div className={className}>
        {items && (
          <div className="page-order">
            <div className="page-order__heading">
              <OrderBreadCrumb
                type="creditDetail"
                status={STATUS_CREDIT}
                orderId={items.id}
                creditDate={items.date_create}
                userType={user.type}
              />
            </div>
            <div>
              <span className="page-name bold">
                {`Demande d'avoir du ${Moment(items.date_create).format('DD/MM/YYYY')}`}
              </span>
              {user.type && user.type !== TYPE_CUSTOMER && (
                <span className="page__name-client">
                  {`Client N° ${items.client_code.slice(1)} ${items.user_firstname} ${
                    items.user_lastname
                  }`}
                </span>
              )}
            </div>
            <div className="page-order__container">
              <div className="container-box">
                <div className="container__entete">
                  <div className="container-entete__validate-date">
                    <div className="container-entete__validate">
                      <span className="container-entete__status_invoiced">
                        {`FACTURE N. ${items.invoice_number}`}
                      </span>
                    </div>
                    <div className="container__entete__info-order">
                      <span className="bold">Passée le : </span>
                      <span>
                        {`${Moment(items.date_create).format('DD/MM/YYYY')} par ${
                          items.client_name
                        }`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="container__info-section">
                  <div className="info-price">
                    <span className="bold">
                      {`Total facturé : ${items.invoice_item.final_price} € TTC`}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <TableWrapper
                  filter={{ platform: platformId, id: Number(id) }}
                  groupBy={{}}
                  noPagination
                  columns={COLUMNS}
                  type={TABLE_TYPE_CREDIT_ITEMS}
                  creditShow
                  shouldShowHeaders
                  dataLinks={{}}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CreditDetail.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
  getItemByID: PropTypes.func,
  user: PropTypes.object,
  platformId: PropTypes.string,
  credit: PropTypes.object,
};

const mapStateToProps = ({ credit, user, platform }) => {
  const { information } = user;
  const { selectedId: platformId } = platform;

  return {
    credit,
    user: information,
    platformId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItemByID: (filter) => dispatch(creditActions.fetchItems(filter, 0, 1)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withOrderDetailStyle(CreditDetail));
