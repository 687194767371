import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import Icon from 'components/Icon/Icon';
import { history, getPath } from 'helpers';
import { userService } from 'services';
import OrderRating from 'components/OrderRating/OrderRating';
import { selectValidatedCartOrder } from 'selectors/cart';
import { orderConstants } from '../../constants';
import { ConfirmationCommandeDIV, ButtonP } from './withConfirmationCommandeStyle';

const { ORDER_STATUS_APPROVED, PREORDER_STATUS_APPROVED } = orderConstants;

const ConfirmationCommande = ({ className, match, user }) => {
  const { params } = match || {};
  const { type } = params || {};
  const { containerization } = params || {};

  return (
    <ConfirmationCommandeDIV>
      <div className={className}>
        <div className="cart-confirm__container">
          <div className="cart-confirm__container-icon">
            <div className="cart-confirm__icon">
              <Icon className="icon-check-ok" content="\e912" />
            </div>
          </div>
          <div>
            <span className="cart-confirm__text bold">
              {`Merci. Votre ${
                !containerization ? 'proforma' : type === 'preorder' ? 'précommande' : 'commande'
              } a été transmise avec succès`}
            </span>
          </div>
          <div className="cart-confirmation__button-block">
            <ButtonP
              inverted
              className="cart-confirm__button "
              onClick={() => history.push(userService.getHomepagePath(user))}
            >
              <Icon content="\E927" />
              <span className="button-icon-save-label regular">REVENIR À L'ACCUEIL</span>
            </ButtonP>
            <ButtonP
              inverted
              className="cart-confirm__button outlined-primary-button-icon"
              onClick={() =>
                history.push(
                  getPath('orderList').replace(
                    ':status',
                    type === 'preorder' ? PREORDER_STATUS_APPROVED : ORDER_STATUS_APPROVED
                  )
                )
              }
            >
              <Icon content="\E952" />
              <span className="button-icon-save-label regular">VOIR MES COMMANDES</span>
            </ButtonP>
          </div>
          <OrderRating />
        </div>
      </div>
    </ConfirmationCommandeDIV>
  );
};

ConfirmationCommande.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const ConfirmationCommandeLayout = ({ match }) => (
  <Layout component={connect(mapStateToProps)(ConfirmationCommande)} match={match} />
);

ConfirmationCommandeLayout.propTypes = {
  match: PropTypes.object,
};

export default ConfirmationCommandeLayout;
