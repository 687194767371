/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const getInputType = (key) => {
  if (
    key === 'email_credit' ||
    key === 'email_commitment' ||
    key === 'email_technical' ||
    key === 'email_accounting'
  ) {
    return 'email';
  }
  if (key === 'negociable-percentage') {
    return 'number';
  }
  // if (key === 'tel_technical'
  // || key === 'fax_technical'
  // || key === 'fax_accounting'
  // || key === 'tel_accounting') {
  // return 'number';
  // }
  return 'text';
};

const ConfiguratorText = ({ attribut, label, onChange }) => (
  <div className="configurator-text">
    <label className="configurator-text-label">{label}</label>
    <input
      className="configurator-text-input"
      name={attribut.key}
      type={getInputType(attribut.key)}
      value={attribut.value}
      onChange={(e) => onChange(attribut.key, e.target.value)}
    />
  </div>
);

ConfiguratorText.propTypes = {
  attribut: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default ConfiguratorText;
