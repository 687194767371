import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { modalActions } from 'actions';

import { cartConstants } from 'pages/../constants';
import Icon from 'components/Icon/Icon';

const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;

const ProductActionDeleteTD = styled.td`
  color: #d0021b;
  font-size: 16px;
  .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }
  i {
    cursor: pointer;
  }
`;

const ProductActionDeleteDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  color: #d0021b;
  display: block;
  text-align: center .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }

  i {
    cursor: pointer;
  }
`;

const ProductActionDelete = ({
  className,
  shouldShowResponsive,
  cartItemId,
  openDeleteModal,
  status,
}) =>
  !shouldShowResponsive ? (
    <ProductActionDeleteTD className={className}>
      <Icon content="\e916" onClick={() => openDeleteModal(cartItemId, status)} />
    </ProductActionDeleteTD>
  ) : (
    <ProductActionDeleteDIV className={className}>
      <Icon content="\e916" onClick={() => openDeleteModal(cartItemId, status)} />
    </ProductActionDeleteDIV>
  );

const mapDispatchToProps = (dispatch) => ({
  openDeleteModal: (cartItemId, status) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: cartItemId,
        deleteType: [CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER].includes(status)
          ? 'CART_ITEM'
          : 'CART_TYPE_ITEM',
      })
    ),
});

ProductActionDelete.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  cartItemId: PropTypes.number,
  openDeleteModal: PropTypes.func,
  status: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(ProductActionDelete);
