import styled from 'styled-components';

export default (Component) => styled(Component)`
  .day-off-calendar {
    .day-off-calendar__valide {
      text-align: center;
    }
    .react-datepicker {
      border: none;
    }
    .react-datepicker__month-container {
    }
    .day-off__info {
      text-align: center;
      padding-top: 20px;
      .day-off__valid {
        padding: 11px 39px;
      }
    }
  }
`;
