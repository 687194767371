import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import TooltipWrapper from './Tooltip.style.jsx';

const Tooltip = ({
  children,
  className,
  labelValue,
  isVisible,
  parentElement,
  tooltipPosition,
}) => {
  const parentBouncingRect = parentElement && parentElement.getBoundingClientRect();

  return ReactDOM.createPortal(
    <TooltipWrapper
      className={className}
      isVisible={isVisible}
      coordX={parentBouncingRect && parentBouncingRect.x}
      coordY={parentBouncingRect && parentBouncingRect.y}
      tooltipPosition={tooltipPosition}
    >
      <>
        {children}
        {labelValue && <div className="tooltip__label">{labelValue}</div>}
      </>
    </TooltipWrapper>,
    document.getElementById('root')
  );
};

Tooltip.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  labelValue: PropTypes.string,
  parentElement: PropTypes.element,
  tooltipPosition: PropTypes.string,
};

export default Tooltip;
