import React from 'react';
import PropTypes from 'prop-types';
import { getPath } from 'helpers';
import { userService } from 'services';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';

const RecurrentCartBreadCrumb = ({ user }) => {
  const url = [
    {
      text: 'Accueil ',
      href: userService.getHomepagePath(user),
    },
    {
      text: 'Commande express ',
      href: getPath('cartList'),
    },
  ];

  const currentPage = 'Mes commandes automatiques';

  return (
    <div>
      <BreadCrumb urlTab={url} currentPage={currentPage} />
    </div>
  );
};

RecurrentCartBreadCrumb.propTypes = {
  user: PropTypes.object,
};

export default RecurrentCartBreadCrumb;
