/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { numberToPrice } from 'helpers';
import Icon from 'components/Icon/Icon';
import { STATUS_FRANCO_BLOCKED, STATUS_FRANCO_NORMAL } from 'pages/../constants';

const PollOption = ({ temperature, selected, onChange }) => (
  <div className="block-option">
    <div className="block__radio" key={`${temperature}-yes`}>
      <label htmlFor="vote" onClick={() => onChange(true)}>
        <input type="radio" name={`${temperature}-yes`} checked={selected === true} />
        <span>Oui</span>
        <span className="check" />
      </label>
    </div>
    <div className="block__radio" key={`${temperature}-no`}>
      <label htmlFor="vote" onClick={() => onChange(false)}>
        <input type="radio" name={`${temperature}-no`} checked={selected === false} />
        <span>Non</span>
        <span className="check" />
      </label>
    </div>
  </div>
);

class Franco extends React.Component {
  getFrancosByTemperature = () => {
    const { clientFranco, selectedPlatform, temperatures } = this.props;
    const francos = clientFranco
      .filter(
        ({ enable, platform }) =>
          enable && platform && Number(platform.id) === Number(selectedPlatform)
      )
      .sort((francoA, francoB) => (francoA.date_create < francoB.date_create ? 1 : -1));

    const francosByTemperature = {};
    temperatures.forEach((temperature) => {
      const francoByTemperature = francos.find((franco) => franco.temperature === temperature);
      francosByTemperature[temperature] = francoByTemperature;
    });
    return francosByTemperature;
  };

  handleAddFranco = (status, temperature) => {
    const { updateState, clientFranco, selectedPlatform, id } = this.props;
    const francoByTemperature = this.getFrancosByTemperature();
    const effectiveFranco = francoByTemperature[temperature];
    if (!effectiveFranco) {
      // add a franco to the list
      const francos = [...clientFranco];
      francos.push({
        temperature,
        enable: true,
        platform: { id: selectedPlatform },
        client: { id },
        status: STATUS_FRANCO_NORMAL,
      });
      updateState('client_franco', francos);
    } else {
      // update a franco enable field in the list
      const francos = effectiveFranco.id
        ? clientFranco.filter((franco) => franco.id !== effectiveFranco.id)
        : clientFranco.filter(
            (franco) =>
              !!franco.id ||
              franco.temperature !== effectiveFranco.temperature ||
              franco.platform.id !== effectiveFranco.platform.id
          );
      francos.push({
        ...effectiveFranco,
        enable: status,
      });
      updateState('client_franco', francos);
    }
  };

  handleAmountFranco = (e, temperature) => {
    const { updateState, clientFranco } = this.props;
    const francoByTemperature = this.getFrancosByTemperature();
    const effectiveFranco = francoByTemperature[temperature];
    const amount = Number(e.target.value);
    if (effectiveFranco && amount) {
      const francos = effectiveFranco.id
        ? clientFranco.filter((franco) => franco.id !== effectiveFranco.id)
        : clientFranco.filter(
            (franco) =>
              !!franco.id ||
              franco.temperature !== effectiveFranco.temperature ||
              franco.platform.id !== effectiveFranco.platform.id
          );
      francos.push({
        ...effectiveFranco,
        amount,
      });
      updateState('client_franco', francos);
    }
  };

  handleStatusFranco = (status, temperature) => {
    const { updateState, clientFranco } = this.props;
    const francoByTemperature = this.getFrancosByTemperature();
    const effectiveFranco = francoByTemperature[temperature];
    const newStatus = status ? STATUS_FRANCO_BLOCKED : STATUS_FRANCO_NORMAL;
    if (effectiveFranco) {
      const francos = effectiveFranco.id
        ? clientFranco.filter((franco) => franco.id !== effectiveFranco.id)
        : clientFranco.filter(
            (franco) =>
              !!franco.id ||
              franco.temperature !== effectiveFranco.temperature ||
              franco.platform.id !== effectiveFranco.platform.id
          );
      francos.push({
        ...effectiveFranco,
        status: newStatus,
      });
      updateState('client_franco', francos);
    }
  };

  render() {
    const { readOnly, temperatures } = this.props;
    const francosByTemperature = this.getFrancosByTemperature();
    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon devise" content="\e91c" />
          <span className="entete__label">Franco </span>
        </div>
        {readOnly ? (
          <div className="block-info__content">
            {temperatures.map((temperature) => {
              const amount = francosByTemperature[temperature]
                ? francosByTemperature[temperature].amount
                : '-';
              const status = francosByTemperature[temperature]
                ? francosByTemperature[temperature].status === STATUS_FRANCO_BLOCKED
                  ? 'Bloquant'
                  : 'Non bloquant'
                : '-';
              return (
                <div className="content__ligne">
                  <span className="total__attribut">{temperature}</span>
                  <span className="total__attribut">
                    {amount !== '-' ? numberToPrice(amount) : amount}
                  </span>
                  <span className="total__attribut">{status}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="block-info__content total">
            {temperatures.map((temperature) => (
              <div className="total__type-block">
                <div className="total__type">
                  <div className="content__ligne">
                    <span className="bold ligne__attribut">{`${temperature} :`}</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name={`${temperature}-add`}
                        onClick={() => {
                          this.handleAddFranco(!francosByTemperature[temperature], temperature);
                        }}
                        checked={!!francosByTemperature[temperature]}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {!!francosByTemperature[temperature] && (
                    <div>
                      <div className="content__ligne">
                        <span className="bold ligne__attribut">Montant :</span>
                        <input
                          name="montant"
                          type="text"
                          value={
                            francosByTemperature[temperature]
                              ? francosByTemperature[temperature].amount
                              : ''
                          }
                          onChange={(e) => this.handleAmountFranco(e, temperature)}
                        />
                      </div>
                      <div className="content__ligne">
                        <span className="bold ligne__attribut">Bloquant : </span>
                        <PollOption
                          key={`${temperature}-status`}
                          temperature={`${temperature}-status`}
                          onChange={(status) => this.handleStatusFranco(status, temperature)}
                          selected={
                            francosByTemperature[temperature]
                              ? francosByTemperature[temperature].status === STATUS_FRANCO_BLOCKED
                              : null
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <hr className="block__radio-hr" />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: platform.selectedId,
  };
};

PollOption.propTypes = {
  temperature: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

Franco.propTypes = {
  readOnly: PropTypes.bool,
  clientFranco: PropTypes.array,
  updateState: PropTypes.func,
  selectedPlatform: PropTypes.number,
  id: PropTypes.number,
  temperatures: PropTypes.array,
};

export default connect(mapStateToProps)(Franco);
