import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }

  to {
    transform: translateY(100%);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(100%);
    opacity: 1;
  }

  to {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }
`;
const pathnamePage = window.location.pathname.split('/');
let widthComponent = 426;
if (pathnamePage[0] === 'aggrid') {
  widthComponent = 489;
}
export const WrapperContainer = styled.div`
  transform: translateY(100%);
  z-index: 10;
  visibility: ${(props) => (props.containersOpen ? 'visible' : 'hidden')};
  animation: ${(props) => (props.containersOpen ? fadeIn : fadeOut)} 0.2s linear;
  transition: visibility 0.2s linear;
`;
export const WithStyle = styled.div`
  position: relative;
  width: ${(props) => (props.pathName[1] === 'aggrid' ? '68.9%' : '70%')};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  .conteneurisation-content {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    background: #fff;
    box-shadow: 0px -10px 25px -10px rgba(0, 0, 0, 0.8);
    max-height: 250px;
    overflow: auto;
    // max-width: calc(100% - 487px);
    position: fixed;
    bottom: 0;
    left: 0;
    .info {
      font-size: 12px;
      margin: 0;
      padding: 5px 10px;
      border-bottom: 1px solid #4a4a4a;
    }
  }
  .toggle-containeurisation {
    display: flex;
    align-items: center;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    text-decoration: none;
    margin-right: 20px;
    position: relative;
    z-index: 10;
    top: 0;
    left: 10px;
    padding: 20px 0;
    cursor: pointer;
    float: left;
    .nb-container {
      margin-right: 20px;
    }
    .dropUpDown {
      border-left: 1px solid #4a4a4a;
      border-right: 1px solid #4a4a4a;
      padding: 2px 14px;
    }
  }
  @media screen and (max-width: 544px) {
    display: none;
  }
`;

export const WrapperContainerItem = styled.div`
  .container-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4a4a4a;
    padding-left: 10px;
    strong {
      width: 150px;
      color: #4a4a4a;
      font-size: 12px;
    }
    .select-block {
      display: flex;
      padding-left: 10px;
      select {
        color: #4a4a4a;
        font-size: 14px;
        line-height: 19px;
        margin-right: 10px;
        padding: 3px 10px;
        border: 1px solid #4a4a4a;
        border-radius: 6px;
        width: 120px;
      }
    }
    .gauge {
      width: 100%;
      height: 100%;
      // flex: 3;
      border-left: 1px solid #4a4a4a;
      border-right: 1px solid #4a4a4a;
      display: flex;
      align-items: center;
      .gauge-title {
        span {
          width: 65px;
          color: #4a4a4a;
          font-size: 12px;
          padding: 3px 10px;
          font-weight: 500;
        }
      }
      .gauge-bar {
        width: 100%;
        z-index: 10;
        // flex: 8;
        position: relative;
        display: flex;
        .gauge-element {
          position: relative;
          width: 100%;
          .progress-bar {
            position: relative;
            width: 100%;
            border-bottom: 1px solid #f7f4f4;
            &::before {
              content: '';
              background-color: #f69696;
              width: 25%;
              height: 100%;
              position: absolute;
              left: 0px;
              top: 0;
              opacity: 0.3;
              z-index: -1;
            }
            &::after {
              content: '';
              background-color: #e5f9e7;
              width: 25%;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0px;
              opacity: 0.7;
              z-index: -1;
            }
          }
        }
        .gauge-bar-volume {
          padding: 3px 0;
        }
        .gauge-bar-weight {
          padding: 3px 0;
          border-left: 1px solid #4a4a4a;
        }
      }
    }
    .bloc-detail {
      padding: 0 10px;
      display: flex;
      .bt-detail {
        border-radius: 6px;
        text-decoration: none;
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
        background: #01366b;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
    .fill-rate {
      flex: 1;
      font-size: 12px;
      font-weight: 500;
      .fill-rate-volume {
        padding: 0 6px;
        border-bottom: 1px solid #e0e0e0;
      }
      .fill-rate-weight {
        padding: 0 6px;
      }
    }
  }
`;
