import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body__text-block {
    color: #01366b;
    text-align: center;
    padding: 0px 20px 20px 20px;
    font-size: 18px;
  }
  .actions {
    margin-top: 15px;
    text-align: center;
    a {
      margin: 10px 5px;
      height: 40px;
      padding: 10px 20px;
    }
  }
`;
