import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const CommercialOrderWrapper = styled.div`
  .commercial-order-form__product-line {
    .react-select__group {
      padding-top: 0;
      padding: bottom: 0;
    }
    .react-select__group-heading {
      height: auto;
      padding: 0 0 0 10px;
      white-space: nowrap;
      color: #01366B;
      font-weight: 700;
      font-size: inherit;
    }
  }
  .commercial-order-form__react-select {
    width: 350px;
    min-height: 29px;
    max-height: 29px;

    .react-select__control--is-focused {
      outline: 2px solid black!important;
    }

    .react-select__control {
      min-height: 29px;
      max-height: 29px;
      border: 1px solid #01366B;

      .react-select__value-container {
        max-height: 29px;
        min-height: 29px;

        .react-select__single-value {
          margin-top: 5px;
        }
      }

      .react-select__indicators {
        max-height: 29px;
        min-height: 29px;
        margin-right: 5px;
        .react-select__clear-indicator {
          padding: 4.5px;
        }
      }
    }

    .react-select__menu {
      width: auto;
      min-width: 100%;
      font-family: monospace, sans-serif;
      word-spacing: normal;

      .react-select__menu-list {
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
          background-color: #fefefe;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #ccc;
        }

        .react-select__option {
          white-space: nowrap;
        }
      }
    }
    input {
      border: none;
      box-shadow: none;
    }
  }
  .commercial-order__form-container {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #ccc;
  }

  .commercial-order__table-container {
    display: flex;

    .commercial-order__table {
      width: 100%;
      height: calc(100vh - 312px);
      overflow: auto;
      table {
        th {
          white-space: nowrap;
          &:last-child, &:first-child {
            cursor: initial;
            svg {
              display: none;
            }
          }
        }
        tr {
          td {
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .commercial-order__table {
      .react-table__wrapper {
        max-height: calc(100vh - 340px);
        overflow: auto;
        margin: 0;
        border-radius: 0;
        border: 0;
        box-shadow: none;

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
          background-color: #fefefe;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #ccc;
        }

        table {
          font-weight: 600;
          border: 0;
          box-shadow: 0;
          .commercial-order__table-product-quantity__container {
            display: flex;
            align-items: center;
            .commercial-order__table-product-quantity {
              height: 29px;
              width: 50px;
              padding: 5px;
              border-color: #ccc;
              border: 1px solid #01366b;
              box-shadow: 0 0 1px #000;
              border-radius: 4px;
              text-align: center;
            }
            .warning-icon__container {
              margin-left: 5px;
            }
          }

          thead {
            background: #ffffff;
            color: #01366B;
          }

          .type-icon__container {
            i {
              &:before {
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .commercial-order__summary {
      width: 450px;
      min-height: calc(100vh - 340px);
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid #ccc;

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      .commerial-order__line-counter {
        display: flex;
        margin: 10px 0;
        font-weight: 700;
        color: #01366b;
        &>div {
          width: 50%;
        }
      }
      input[type='button'] {
        width: 100%;
        padding: 0;
        text-align: center;
      }
      input[type='button']:disabled {
        background: grey;
        border-color: grey;
        cursor: not-allowed;
      }
      .commercial-order__action-container {
        margin: 10px 0;
        .commercial-order__sub-action-container {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;

          a {
            width: 50%;
            border-radius: 5px;
            font-size: 8px;

            &:first-child {
              margin-right: 3px;
            }
            &:last-child {
              margin-left: 3px;
            }
          }
        }

        .commercial-order__validate-button {
          width: 100%;
          border-radius: 5px;
          font-size: 10px;
        }
      }
      .commercial-order__delivery-container,
      .commercial-order__comment-container {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 600;
        }
      }
      .commercial-order__delivery-container {
        margin: 10px 0;
        input {
          width: 100px;
        }
        .commercial-order__delivery-title {
          margin-bottom: 15px;
        }
        .commercial-order__delivery-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;

          .react-datepicker-popper {
            top: -235px!important;
          }
        }
      }
      .commercial-order__comment-container {
        margin-bottom: 20px;
      }
    }
  }

  form {
    input,
    select {
      height: 29px;
      padding: 5px;
      border: 1px solid #01366b;
      border-color: #ccc;
      border-radius: 4px;
      outline: 1px solid transparent;

      &:focus {
        border-color: green;
        box-shadow: 0 0 1px green;
        outline: 2px solid green;
      }
    }

    textarea {
      height: 60px
      border: 1px solid #01366b;
      border-color: #ccc;
      border-radius: 4px;
      resize: none;
      &:focus {
        border-color: green;
        box-shadow: 0 0 1px green;
        outline: 2px solid green;
      }
    }

    select {
      min-width: 409px;
    }

    .commercial-order__bold {
      font-weight: 600;
      padding-bottom: 5px;
    }

    input[type='button'] {
      background: #01366b;
      color: #ffffff;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
    }
  }

  .commercial-order__bold {
    font-weight: 600;
    padding: 5px 10px 5px 0;
  }
`;
