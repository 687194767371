import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddCreditForm from 'components/Form/AddCreditForm/AddCreditForm';
import CommercialOrderDropdownSelect from 'components/CommercialOrderForm/CommercialOrderDropdownSelect/CommercialOrderDropdownSelect';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { modalActions } from 'actions';
import styled from 'styled-components';

const ModalOcrOrderProductSelectWrapper = styled.div`
  .modal-ocr-order-product-select__info-wrap {
    padding: 2px 0;
  }

  .modal-ocr-order-product-select__dropdown-wrap {
    padding: 10px 0;
  }

  .modal-ocr-order-product-select__footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    a:first-child {
      margin-right: 10px;
    }
  }
`;

const ModalOcrOrderProductSelect = ({
  className,
  jobItem,
  productList,
  closeModal,
  index,
  callback,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <ModalOcrOrderProductSelectWrapper className={className}>
      <div>
        <div className="modal-ocr-order-product-select__info-wrap">
          <span className="bold">Reference:</span> {jobItem.reference || '-'}
        </div>
        <div className="modal-ocr-order-product-select__info-wrap">
          <span className="bold">EAN:</span> {jobItem.ean || '-'}
        </div>
        <div className="modal-ocr-order-product-select__info-wrap">
          <span className="bold">Libellé:</span> {jobItem.product_name || '-'}
        </div>
        <div className="modal-ocr-order-product-select__info-wrap">
          <span className="bold">Quantité:</span> {jobItem.quantity || '-'}
        </div>
        <div className="modal-ocr-order-product-select__info-wrap">
          <span className="bold">PCB:</span> {jobItem.pcb || '-'}
        </div>
      </div>
      <div className="modal-ocr-order-product-select__dropdown-wrap">
        <CommercialOrderDropdownSelect
          itemList={productList}
          isDisabled={false}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
      <div className="modal-ocr-order-product-select__footer">
        <ButtonPrimary onClick={closeModal}>Annuler</ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            callback(selectedItem, index);
            closeModal();
          }}
          disabled={!selectedItem}
        >
          Valider
        </ButtonPrimary>
      </div>
    </ModalOcrOrderProductSelectWrapper>
  );
};

ModalOcrOrderProductSelect.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(ModalOcrOrderProductSelect);
