import styled from 'styled-components';

export const ImportPdfOrderFormWrapper = styled.div`
  .import-pdf-order__client-select-container {
    display: flex;
    align-items: center;
    .import-pdf-order__client-label {
      margin-right: 10px;
      font-weight: bold;
      color: #01366b;
    }
    .dropdown-select__search-container {
      input {
        padding: 5px;
        box-shadow: none;
        border: 1px solid #01366b;
        border-radius: 4.5px;
      }
    }
    .dropdown-select__panel {
      z-index: 5;
      min-width: 100%;
      thead {
        display: none;
      }
    }
  }

  .import-pdf-order__input-container {
    width: 100%;
    min-width: 300px;
    height: 250px;
    margin: 25px auto;
    position: relative;

    .import-pdf-order__file-input,
    .import-pdf-order__fake-file-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .import-pdf-order__fake-file-input {
      border: 1px solid #01366b;
      border-radius: 4.5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #01366b;
      svg {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        fill: #01366b;
      }
    }

    .import-pdf-order__file-input {
      opacity: 0;
      z-index: 4;
      cursor: pointer;
    }
  }

  .import-pdf-order__buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4.5px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      background: #01366b;
      &:focus {
        outline: 0;
      }

      &[disabled] {
        cursor: not-allowed;
        background-color: #65676b;
      }
    }

    .import-pdf-order__cancel-button {
      background-color: #65676b;
      margin-right: 20px;
    }

    .import-pdf-order__submit-button {
      background-color: #01366b;
    }
  }
`;
