import get from 'lodash.get';

export const selectCurrentPlatform = (state) => {
  const {
    platform: { platforms, selectedId },
  } = state;

  return platforms.find(({ id }) => Number(id) === Number(selectedId)) || {};
};

export const selectCurrentPlatformId = (state) => {
  const {
    platform: { selectedId },
  } = state;

  return selectedId;
};

export const selectPlatformList = (state) => get(state, 'platform.platforms', []);
