import { toastConstants } from 'constants/Toast.constants';

const initState = {
  items: [],
};

export const toast = (state = initState, action) => {
  switch (action.type) {
    case toastConstants.ADD_TOAST: {
      if (
        state.items.some(
          (item) =>
            item.title === action.toast.title &&
            item.message === action.toast.message &&
            item.toasted === false
        )
      ) {
        return state;
      }
      return {
        ...state,
        items: [...state.items, { ...action.toast, id: state.items.length, toasted: false }],
      };
    }
    case toastConstants.REMOVE_TOAST: {
      const myItems = [...state.items];
      const itemIndex = myItems.findIndex((item) => item.id === action.toastId);
      if (itemIndex >= 0 && myItems[itemIndex]) {
        myItems[itemIndex].toasted = true;
      }
      return { ...state, items: myItems };
    }
    case toastConstants.SET_TOAST_LIST:
      return {
        ...state,
        items: action.toastList.map((item, index) => ({ ...item, id: index })),
      };
    default:
      return state;
  }
};
