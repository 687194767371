import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { cartActions, cartItemsActions, modalActions } from 'actions';
import { getItemCartQuantity, getNewTempsCartId } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Spinner from 'components/Core/Spinner/Spinner';
import ModalAddProductWithStyle from './ModalAddProduct.style';
import { cartConstants } from '../../constants';

const { CART_STATUS_CUSTOM, CART_STATUS_CUSTOM_PREORDER } = cartConstants;

const CustomCart = ({
  name,
  totalQuantity,
  id,
  cart,
  product,
  current,
  currentPreorder,
  checked,
  addToCart,
  deleteFromCart,
  cartItemId,
}) => {
  const handleClick = () => {
    const effectiveCart = product && product.is_preorder ? currentPreorder : current;
    const apiQuantity = product ? getItemCartQuantity(product.id, effectiveCart) : null;
    if (checked) {
      deleteFromCart(cartItemId);
    } else {
      addToCart(cart.id, product, apiQuantity || 1);
    }
  };
  return (
    <div className="checkbox-container" onClick={handleClick}>
      <label htmlFor="check-cart" className="checkbox-block">
        <span className="bold">{`${name} (${totalQuantity})`}</span>
        <div className="checkbox__icon">
          <input name="check-cart" type="checkbox" readOnly value={id} checked={checked} />
          <span className="checkmark" />
        </div>
      </label>
    </div>
  );
};

CustomCart.propTypes = {
  name: PropTypes.string,
  totalQuantity: PropTypes.number,
  id: PropTypes.number,
  cartItemId: PropTypes.string,
  cart: PropTypes.object,
  currentPreorder: PropTypes.object,
  current: PropTypes.object,
  product: PropTypes.object,
  checked: PropTypes.bool,
  addToCart: PropTypes.func,
  deleteFromCart: PropTypes.func,
};

class ModalAddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    const { fetchItems, itemIsPreorder, showList, selectedPlatform } = this.props;
    if (showList !== false) {
      fetchItems({
        status: itemIsPreorder ? CART_STATUS_CUSTOM_PREORDER : CART_STATUS_CUSTOM,
        platform: selectedPlatform,
      });
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.getRows = this.getRows.bind(this);
  }

  getCarts() {
    const { collections, itemIsPreorder, selectedPlatform } = this.props;
    if (collections && collections !== undefined) {
      const status = itemIsPreorder ? CART_STATUS_CUSTOM_PREORDER : CART_STATUS_CUSTOM;
      const collection =
        collections.find(
          ({ filter }) => filter.platform === selectedPlatform && filter.status === status
        ) || {};
      const { items } = collection || [];
      if (collections && items) {
        return { carts: items, isLoading: collection.isLoading };
      }
    }
    return { carts: [], isLoading: true };
  }

  getRows() {
    const { itemId, current, currentPreorder, addToCart, deleteFromCart, product } = this.props;
    const { carts } = this.getCarts();
    const rows = [];
    if (carts && carts.length) {
      carts
        .sort((cartA, cartB) => {
          if (cartA.date_create === cartB.date_create) {
            return Number(cartA.id) < Number(cartB.id) ? 1 : -1;
          }
          return Moment(cartA.date_create).format() < Moment(cartB.date_create).format() ? 1 : -1;
        })
        .forEach((cart) => {
          const { name, id, cart_items: cartItems } = cart;
          const cartItem = cartItems.find((ci) => String(ci.item_id) === String(itemId));
          const isChecked = !!cartItem;
          const cartItemId = isChecked ? cartItem.id : null;
          rows.push(
            <CustomCart
              key={id}
              name={name}
              totalQuantity={cartItems.length}
              cart={cart}
              current={current}
              currentPreorder={currentPreorder}
              cartItemId={String(cartItemId)}
              product={product}
              checked={isChecked}
              addToCart={addToCart}
              deleteFromCart={deleteFromCart}
            />
          );
        });
    }
    return rows;
  }

  handleCreate = () => {
    const {
      showList,
      copyCart,
      openConfirmationModal,
      addToCart,
      current,
      currentPreorder,
      isCartPreorder,
      product,
    } = this.props;
    const { value } = this.state;
    if (value) {
      if (showList !== false) {
        const effectiveCart = product && product.is_preorder ? currentPreorder : current;
        const apiQuantity = product ? getItemCartQuantity(product.id, effectiveCart) : null;
        const status =
          product && product.is_preorder ? CART_STATUS_CUSTOM_PREORDER : CART_STATUS_CUSTOM;
        addToCart(getNewTempsCartId(status), product, apiQuantity || 1, value);
      } else {
        copyCart(isCartPreorder ? currentPreorder.id : current.id, isCartPreorder, value);
        openConfirmationModal(value);
      }
    }
  };

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    const { className, showList } = this.props;
    const { value } = this.state;
    const rows = showList !== false ? this.getRows() : null;
    const { isLoading } = this.getCarts();
    return (
      <div className={className}>
        {!isLoading || showList === false ? (
          <div>
            <div className="modal-header" hidden={showList === false}>
              <span className="bold">Ajouter le produit à un panier type</span>
            </div>
            <div className="modal-headerline" hidden={showList === false}>
              <span>
                Créez un panier type ou sélectionnez le(s) panier(s) type(s) au(x)quel(s) vous
                souhaitez ajouter ce produit
              </span>
            </div>
            <div className="modal-body">
              <div className="modal-body-content" hidden={showList === false}>
                {rows}
              </div>
              <div className="modal-body__create-panier-block">
                <span className="bold" hidden={showList === false}>
                  Créez un Panier Type
                </span>
                <div className="modal-body__create-panier">
                  <input
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus
                    type="text"
                    className="create-panier__input-element"
                    placeholder="Nom du nouveau Panier Type"
                    value={value}
                    onChange={this.handleChange}
                  />
                  <ButtonPrimary onClick={this.handleCreate} disabled={!value}>
                    Créer
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

ModalAddProduct.propTypes = {
  className: PropTypes.string,
  fetchItems: PropTypes.func,
  itemIsPreorder: PropTypes.bool,
  isCartPreorder: PropTypes.bool,
  collections: PropTypes.array,
  showList: PropTypes.bool,
  itemId: PropTypes.string,
  addToCart: PropTypes.func,
  deleteFromCart: PropTypes.func,
  copyCart: PropTypes.func,
  openConfirmationModal: PropTypes.func,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  product: PropTypes.object,
  selectedPlatform: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { cart, platform } = state;
  const { collections, current, currentPreorder } = cart;
  return {
    collections,
    current,
    currentPreorder,
    selectedPlatform: platform.selectedId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (cartId, item, quantity, cartName) =>
    dispatch(cartItemsActions.addItem(cartId, item, Number(quantity), {}, cartName)),
  deleteFromCart: (cartItemId) => dispatch(cartItemsActions.deleteItem(cartItemId)),
  fetchItems: (filter) => dispatch(cartActions.fetchItems(filter, 1, 100)),
  copyCart: (id, isCustomPreorder, newName) =>
    dispatch(
      cartActions.copyCart(
        id,
        isCustomPreorder ? CART_STATUS_CUSTOM_PREORDER : CART_STATUS_CUSTOM,
        newName
      )
    ),
  openConfirmationModal: (cartName) =>
    dispatch(modalActions.open('confirmation', { modalHeader: '', cartName })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAddProductWithStyle(ModalAddProduct));
