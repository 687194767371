import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonSecondary } from 'components/Core/Button/Button';
import { modalActions } from 'actions';
import Icon from 'components/Icon/Icon';
import { clientStatusConstants } from '../../constants';

const { STATUS_ACTIVE, STATUS_BLOCKED, STATUS_INACTIVE } = clientStatusConstants;

class ClientAccess extends React.Component {
  constructor(props) {
    super(props);
    this.changeStatus = this.changeStatus.bind(this);
  }

  getTextFromStatus(status) {
    switch (status) {
      case STATUS_ACTIVE:
        return {
          labelText: 'Non bloqué',
          labelClassName: 'active',
          buttonText: 'Bloquer',
        };
      case STATUS_BLOCKED:
        return {
          labelText: 'Bloqué',
          labelClassName: 'blocked',
          buttonText: 'Débloquer',
        };
      case STATUS_INACTIVE:
        return {
          labelText: 'Inactif',
          labelClassName: 'inactive',
          buttonText: 'Débloquer',
        };
      default:
        return {
          labelText: 'Non bloqué',
          labelClassName: 'active',
          buttonText: 'Bloquer',
        };
    }
  }

  convertStatus = (status) => {
    switch (status) {
      case STATUS_BLOCKED:
        return STATUS_ACTIVE;
      case STATUS_ACTIVE:
        return STATUS_BLOCKED;
      case STATUS_INACTIVE:
        return STATUS_ACTIVE;
      default:
        return status;
    }
  };

  handleClick = (statusKey, statusValue) => {
    if (statusValue === STATUS_ACTIVE) {
      const { openBloqueModal } = this.props;
      openBloqueModal(statusKey === 'statusPreorder', () =>
        this.changeStatus(statusKey, statusValue)
      );
    } else {
      this.changeStatus(statusKey, statusValue);
    }
  };

  changeStatus = (key, prevStatus) => {
    const { updateState, id, clientStatus, selectedPlatform } = this.props;
    const { status_preorder: statusPreorder, status_catalog: statusCatalog } = clientStatus || {};
    const modifiedData = {
      statusPreorder: statusPreorder || STATUS_BLOCKED,
      statusCatalog: statusCatalog || STATUS_BLOCKED,
    };
    modifiedData[key] = this.convertStatus(prevStatus);
    const {
      statusPreorder: modifiedStatusPreorder,
      statusCatalog: modifiedStatusCatalog,
    } = modifiedData;
    if (clientStatus) {
      const newClientStatus = {
        status: clientStatus.status,
        status_catalog: modifiedStatusCatalog,
        status_preorder: modifiedStatusPreorder,
        platform: { id: Number(selectedPlatform) },
        client: { id: Number(id) },
      };
      updateState(
        'client_status',
        clientStatus.id
          ? [
              {
                id: Number(clientStatus.id),
                ...newClientStatus,
              },
            ]
          : [newClientStatus]
      );
    } else {
      updateState('client_status', [
        {
          status_catalog: modifiedStatusCatalog,
          status_preorder: modifiedStatusPreorder,
          platform: { id: Number(selectedPlatform) },
          client: { id: Number(id) },
        },
      ]);
    }
  };

  render() {
    const { clientStatus } = this.props;
    const { status_preorder: statusPreorder, status_catalog: statusCatalog } = clientStatus || {};
    const statusCatalogText = this.getTextFromStatus(statusCatalog || STATUS_BLOCKED);
    const statusPreorderText = this.getTextFromStatus(statusPreorder || STATUS_BLOCKED);
    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon" content="\e92e" />
          <span className="entete__label">Gérer les accès </span>
        </div>
        <div className="block-info__content">
          <div className="page-profil__block-info-access__item">
            <div className="page-profil__block-info-access__item-prop">
              <span className="item__label">Catalogue permanent</span>
              <span className={`item__state ${statusCatalogText.labelClassName}`}>
                {statusCatalogText.labelText}
              </span>
            </div>
            <div className="page-profil__block-info-access__item-action">
              <ButtonSecondary
                inverted
                className="item-action__button"
                onClick={() => this.handleClick('statusCatalog', statusCatalog || STATUS_BLOCKED)}
              >
                <Icon content="\e92e" />
                <span>{statusCatalogText.buttonText}</span>
              </ButtonSecondary>
            </div>
          </div>
          <div className="page-profil__block-info-access__item">
            <div className="page-profil__block-info-access__item-prop">
              <span className="item__label">Catalogue précommandes</span>
              <span className={`item__state ${statusPreorderText.labelClassName}`}>
                {statusPreorderText.labelText}
              </span>
            </div>
            <div className="page-profil__block-info-access__item-action">
              <ButtonSecondary
                inverted
                className="item-action__button"
                onClick={() => this.handleClick('statusPreorder', statusPreorder || STATUS_BLOCKED)}
              >
                <Icon content="\e92e" />
                <span>{statusPreorderText.buttonText}</span>
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ClientAccess.propTypes = {
  clientStatus: PropTypes.object,
  selectedPlatform: PropTypes.number,
  id: PropTypes.number,
  updateState: PropTypes.func,
  openBloqueModal: PropTypes.func,
};
const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: platform.selectedId || null,
  };
};
const mapDispatchToProps = (dispatch) => ({
  openBloqueModal: (isStatusPreorder, callBack) => {
    const modalHeader = `Êtes-vous sûr de vouloir bloquer le client ? 
    Il n’aura alors plus accès au catalogue ${isStatusPreorder ? 'précommande' : 'permanent'}
    `;
    dispatch(
      modalActions.open('bloque', {
        modalHeader,
        callBack,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientAccess);
