import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-wrapper {
    z-index: 1000;
    padding: 20px 20px 20px 40px;
    width: 100%; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(74, 74, 74, 0.3); /* Black w/ opacity */

    /* Add Animation */
    @-webkit-keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }
      to {
        top: 0;
        opacity: 1;
      }
    }

    @keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }
      to {
        top: 0;
        opacity: 1;
      }
    }
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    width: 100%;
    height: 20%;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  .modal-component {
    padding: 10px 20px 10px 20px;
  }

  .modal-body {
    .modal-body__text-block {
      color: #01366b;
      font-size: 14px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    a {
      color: #00a6ff;
    }
  }

  @media screen and (max-width: 1095px) {
    .modal-wrapper {
      bottom: 115px;
    }
  }
  @media screen and (max-width: 546px) {
    .modal-wrapper {
      bottom: 133px;
    }
  }
  @media screen and (max-width: 479px) {
    .modal-wrapper {
      bottom: 150px;
    }
  }
  @media screen and (max-width: 398px) {
    .modal-wrapper {
      bottom: 169px;
    }
  }
`;
