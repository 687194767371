import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import InvoiceList from './InvoiceList';

const OrderListLayout = ({ match }) => (
  <div>
    <Layout component={InvoiceList} match={match} />
  </div>
);
OrderListLayout.propTypes = {
  match: PropTypes.object,
};
export default OrderListLayout;
