import flatten from 'lodash.flatten';
import merge from 'lodash.merge';
import trim from 'lodash.trim';

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = d.toGMTString();
  document.cookie = `${cname}=${cvalue};expires=${expires} ;path=/`;
};

export const getCookie = (cname, cookie = document.cookie) =>
  merge(...flatten(cookie.split(';').map((c) => ({ [trim(c.split('=')[0])]: c.split('=')[1] }))))[
    cname
  ];

export const checkCookie = (username) => {
  const user = getCookie('username');
  if (!user) {
    if (username !== '' && username != null) {
      return setCookie('username', username, 395);
    }
  } else {
    return user;
  }
  return '';
};
