import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import { ImCross } from '@react-icons/all-files/im/ImCross';
import './CatalogControls.scss';
import Tooltip from 'components/Core/Tooltip/Tooltip';
import { size } from 'components/Core/Utils/Device';

const resetCurrentFilters = (setCurrentFilters, handleFilter) => {
  const newFilters = {
    quickList: [],
    temperatureList: [],
    availabilityList: [],
    storeAvailabilityList: [],
    category: null,
    categoryId: null,
    selectedCategoryList: [],
    categoryColumns: null,
    viewType: false,
    selection: null,
  };

  setCurrentFilters(newFilters);
  handleFilter(newFilters);
};

const ResetControl = (props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);
  const { setCurrentFilters, handleFilter } = props;

  return (
    <>
      <span
        onClick={() => {
          resetCurrentFilters(setCurrentFilters, handleFilter);
        }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      >
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="right"
          labelValue="Réinitialiser les filtres"
          parentElement={parentElement}
        />
        <FaFilter
          style={{
            width: '15px',
            height: '15px',
          }}
        />
        <ImCross
          style={{
            color: 'red',
            position: 'absolute',
            right: '-2px',
            bottom: '1px',
            width: '9px',
            height: '9px',
            stroke: '#FFFFFF',
            strokeWidth: '1px',
          }}
        />
      </span>
    </>
  );
};

ResetControl.propTypes = {
  setCurrentFilters: PropTypes.func,
};

export default ResetControl;
