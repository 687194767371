import { searchService } from 'services';
import { aggridSearchBarConstants, searchConstants, tableConstants } from '../constants';
import { manufacturerActions } from './Manufacturer';

const { DIRECTION_REPLACE } = tableConstants;

export const searchActions = {
  search,
  searchTotalRowsNumber,
  searchLogs,
};

function searchLogs(obj) {
  return (dispatch) => {
    dispatch(request());
    searchService.searchLogs(obj).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: aggridSearchBarConstants.SEARCH_LOGS };
  }
  function success(result) {
    if (result) {
      return {
        type: aggridSearchBarConstants.LOGS_SUCCESS,
      };
    }
    return {
      type: aggridSearchBarConstants.LOGS_SUCCESS,
    };
  }
  function failure(error) {
    return { type: searchConstants.FETCH_COUNT_FAILURE, error };
  }
}
function searchTotalRowsNumber(searchValue, filter, countClients = false) {
  return (dispatch) => {
    dispatch(request());
    searchService.searchTotalRowsNumber(searchValue, filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success(numberDataAvailable));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: searchConstants.FETCH_COUNT_REQUEST, filter, countClients };
  }
  function success(numberItemsAvailable) {
    if (countClients) {
      return {
        type: searchConstants.FETCH_COUNT_SUCCESS,
        numberClientDataAvailable: Number(numberItemsAvailable),
        countClients,
        filter,
      };
    }
    return {
      type: searchConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberItemsAvailable),
      countClients,
      filter,
    };
  }
  function failure(error) {
    return { type: searchConstants.FETCH_COUNT_FAILURE, error };
  }
}

function search(
  searchValue,
  filter,
  showManufacturers,
  showClients,
  offset = 0,
  limit = 10,
  fromAutoSuggest = false,
  direction = DIRECTION_REPLACE
) {
  return (dispatch) => {
    dispatch(request());
    if (showManufacturers && !fromAutoSuggest) {
      dispatch(manufacturerActions.fetchRequest());
    }
    searchService.search(searchValue, filter, showManufacturers, showClients, offset, limit).then(
      (result) => {
        const items = !showManufacturers ? result : result.items;
        dispatch(success(items));
        if (showManufacturers) {
          if (fromAutoSuggest) {
            dispatch(
              manufacturerActions.updateCollections({
                manufacturers: result.manufacturers,
                filter,
              })
            );
          } else {
            dispatch(
              manufacturerActions.fetchSuccess({
                manufacturers: result.manufacturers,
              })
            );
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (showManufacturers) {
          dispatch(manufacturerActions.fetchError());
        }
      }
    );
  };
  function request() {
    return { type: searchConstants.FETCH_REQUEST, filter, showClients };
  }
  function success(items) {
    return {
      type: searchConstants.FETCH_SUCCESS,
      items,
      showClients,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: searchConstants.FETCH_FAILURE, error };
  }
}
