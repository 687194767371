import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { ButtonSecondary } from 'components/Core/Button/Button';
import { getPath, history } from 'helpers';
import TableWrapper from 'components/Table';
import { tableTypes } from 'components/Table/tableTypes';
import { ASSORTMENT_COLUMNS } from 'pages/Client/TableOptions';
import { categoryConstants } from 'constants/Category.constants';

const { TABLE_TYPE_ASSORTMENT } = tableTypes;
const goToAssortment = (clientId, clientExtCode) => {
  let url;
  if (clientId) {
    url = getPath('assortment')
      .replace(':clientId', clientId)
      .replace(':ext_code', clientExtCode)
      .replace(':assortmentId?', '');
    history.push(url);
  }
};

const Selection = ({ client }) => {
  const filter = {
    client: Number(client && client.id),
    status: categoryConstants.STATUS_SELECTION,
  };
  const name = client && client.group_client && client.group_client.name;
  const code = client && client.group_client && client.group_client.code;
  return (
    <div className="page-profil__block-info">
      <div className="block-info__entete">
        <Icon className="entete__icon" content="\e945" />
        <span className="entete__label">Sélections</span>
      </div>
      <div className="block-info__content selection" hidden={!client.id}>
        <div className="selection__label">
          <span>{name}</span>
          <span>{code}</span>
        </div>
        <div className="selection__buton">
          <ButtonSecondary
            inverted
            onClick={() => goToAssortment(client.id, client.ext_code && client.ext_code.slice(1))}
            disabled={!client}
          >
            <Icon content="\e945" />
            <span>Créer une sélection</span>
          </ButtonSecondary>
        </div>
      </div>
      <div className="page-profil__table-bloc">
        {client.id && (
          <TableWrapper
            filter={filter}
            rowsByPage={60}
            columns={ASSORTMENT_COLUMNS}
            type={TABLE_TYPE_ASSORTMENT}
            shouldShowHeaders
            noPagination
            dataLinks={{}}
            data={{ client }}
          />
        )}
      </div>
    </div>
  );
};

Selection.propTypes = {
  client: PropTypes.object,
};

export default Selection;
