/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clientStatusConstants } from 'pages/../constants';
import Icon from 'components/Icon/Icon';

const { STATUS_ACTIVE, STATUS_BLOCKED, STATUS_INACTIVE } = clientStatusConstants;

class ClientActivation extends React.Component {
  updateStatus = (status) => {
    const { id, selectedPlatform, clientStatus, updateState } = this.props;
    const formattedClientStatus = [
      {
        status,
        status_catalog: STATUS_BLOCKED,
        status_preorder: STATUS_BLOCKED,
        platform: { id: selectedPlatform },
        client: { id },
      },
    ];
    if (clientStatus && clientStatus.id) {
      formattedClientStatus[0].id = clientStatus.id;
    }
    updateState('client_status', formattedClientStatus);
  };

  render() {
    const { clientStatus, originalClientStatus, clientEmail } = this.props;
    const isFirstTime = !originalClientStatus;
    const { status } = clientStatus || {};
    const nextStatus = status === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;
    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon" content="\e92e" />
          <span className="entete__label">Activation client</span>
        </div>
        {isFirstTime ? (
          <div className="block-info__content">
            <span className="bold ligne__attribut">
              {clientEmail
                ? 'Activer le client'
                : 'Ce client n’a pas d’email renseigné. Il ne peut être activé .'}
            </span>
            {clientEmail && (
              <label className="switch">
                <input
                  type="checkbox"
                  name={status}
                  onClick={() => this.updateStatus(nextStatus)}
                  checked={status === STATUS_ACTIVE}
                />
                <span className="slider round" />
              </label>
            )}
          </div>
        ) : (
          <div className="block-info__content">
            <span className="ligne__value">Client activé</span>
          </div>
        )}
      </div>
    );
  }
}

ClientActivation.propTypes = {
  id: PropTypes.number,
  selectedPlatform: PropTypes.number,
  originalClientStatus: PropTypes.object,
  clientStatus: PropTypes.object,
  updateState: PropTypes.func,
  clientEmail: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: platform.selectedId || null,
  };
};

export default connect(mapStateToProps)(ClientActivation);
