import styled from 'styled-components';

export const CommercialOrderDropdownSelectWrapper = styled.div`
  display: flex;
  align-items: center;

  .dd-header-title {
    border-radius: 3px;
    background: #798fac;
    color: white;
    padding: 7px;
    box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
  }

  .react-table__wrapper {
    tr {
      td,
      th {
        padding: 5px;
      }
    }
  }

  .dropdown__radio-container {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 5px 0 10px 0;
    li {
      display: flex;
      align-items: center;
      margin-right: 15px;
      input[type='radio'] {
        appearance: auto;
        height: auto;
        border: 0;
        margin: 0 5px 0 0;

        &:focus {
          outline: none;
        }
      }
    }
    input[type='radio']:first-child {
      margin-left: 0;
    }
  }

  .commercial-order-dropdown__temperature-control {
    list-style: none;
    padding: 0;
    margin: 5px 0 10px 0;
    display: flex;
    align-items: center;
    li {
      margin-right: 15px;
      input[type='checkbox'] {
        width: 12px;
        min-width: 12px;
        height: 12px;
        margin-right: 5px;
        margin-left: 0;
        appearance: checkbox;
        &:focus {
          outline: none;
        }
      }
    }
    i {
      &:before {
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
`;
