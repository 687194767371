import styled from 'styled-components';

export default (Component) => styled(Component)`
  h5 {
    color: #01366b;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    padding: 20px 0;
  }
  em {
    color: #787878;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 15px;
    font-style: normal;
  }
  input[type='text'],
  select {
    appearance: none;
    border: 0;
    border-bottom: 1px solid rgba(120, 120, 120, 0.6);
    padding: 11px;
    color: #787878;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display: inline-block;
    outline: 0;
  }

  .planning__type {
    label {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      vertical-align: top;
    }
    .planning__type-radio {
      display: inline-block;
      vertical-align: middle;
      padding-right: 40px;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .check {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        border: 1px solid #4a4a4a;
        cursor: pointer;

        &:after {
          top: 3px;
          left: 3px;
          border-radius: 100%;
          background: #01366b;
          content: '';
          position: absolute;
          display: none;
          padding: 6px;
        }
      }

      input:checked ~ .check:after {
        display: block;
      }
    }
    .planning__name {
      margin-top: 20px;
      input[type='text'] {
        margin-right: 60px;
        width: calc(50% - 35px);
      }
      .planning__type-temperature {
        display: inline-block;
        position: relative;
        width: calc(50% - 35px);
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: rgba(120, 120, 120, 0.6) transparent transparent transparent;
          line-height: 0px;
          top: 50%;
          position: absolute;
          right: 0;
        }
        select {
          width: 95%;
        }
      }
    }
    .planning__substitution {
      margin-top: 20px;
      & > div:not([hidden]) {
        width: calc(50% - 40px);
        display: inline-block;
        position: relative;
      }
      .icon-calendar {
        position: absolute;
        top: 32%;
        font-size: 18px;
      }
      input.calender {
        width: 100%;
        margin-right: 0;
      }
      .planning__substitution-valide {
        margin-right: 60px;
      }
      .planning__substitution-valide,
      .planning__substitution-to {
        .react-datepicker-wrapper {
          width: 100%;
          .react-datepicker__input-container {
            width: 100%;
            input {
              width: 100%;
            }
          }
        }
      }
      .planning__substitution-choose {
        width: 100% !important;
        select {
          width: 100%;
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-color: rgba(120, 120, 120, 0.6) transparent transparent transparent;
            line-height: 0px;
            top: 50%;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .planning__calender {
    padding-bottom: 20px;
    .checkbox {
      margin-bottom: 20px;
      position: relative;
      label {
        color: #4a4a4a;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        padding-right: 20px;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
      }
      input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid rgba(120, 120, 120, 0.54);
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 7px;
          top: 2px;
          width: 5px;
          height: 12px;
          border: solid #01366b;
          border-width: 0 2px 2px 0px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      input[type='checkbox']:checked ~ .checkmark:after {
        display: block;
      }
    }
    .planning__calender_detais {
      padding-left: 20px;
      color: #4a4a4a;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      white-space: nowrap;
      .time {
        position: relative;
        width: 40px;
        text-align: center;
        padding: 0 3px;
        &:after {
          content: '';
          height: 1px;
          width: 25px;
          background-color: rgba(120, 120, 120, 0.6);
          position: absolute;
          bottom: -2px;
          left: 0;
        }
      }
    }
    em {
      padding-bottom: 30px;
      display: block;
    }
  }
  .planning__info {
    .red {
      color: #d0021b;
    }
    p {
      text-align: center;
      font-size: 12px;
    }
    .planning__info-delete {
      background: none;
      padding: 9px 21px;
      margin: 10px 0;
      span {
        color: #00a6ff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.06px;
        line-height: 16px;
        vertical-align: middle;
      }
      i.icon-delete {
        font-size: 20px;
        padding-right: 5px;
        vertical-align: middle;
        &:before {
          color: #00a6ff;
        }
      }
    }
    .planning__info_valid {
      padding: 11px 39px;
      text-align: center;
      margin: 0 auto;
      display: block;
      width: fit-content;
    }
  }

  .page-profil__alert-bloc {
    border: 1px solid rgba(208, 2, 27, 0.2);
    border-radius: 8px;
    background-color: #f9d9dd;
    padding: 12px 20px;
    margin-bottom: 31px;
  }
  .alert-bloc__label {
    display: inline-block;
    width: 85%;
    color: #d0021b;
    font-weight: 500;
  }
  /*************Responsive*******************/
  @media (min-width: 320px) and (max-width: 544px) {
    .planning__type {
      .planning__type-radio {
        margin-bottom: 20px;
      }
      .planning__name {
        input[type='text'],
        .planning__type-temperature {
          width: 100%;
        }
      }
      .planning__substitution > div,
      .planning__name input[type='text'] {
        width: 95%;
        margin-right: 0;
        margin-bottom: 15px;
      }
      .planning__substitution > div:not([hidden]) {
        width: 100%;
        display: block;
        .react-datepicker-wrapper {
          width: 95%;
          .react-datepicker__input-container {
            display: block;
            input {
              width: 100%;
            }
          }
        }
      }
    }
    .planning__info {
      em {
        clear: both;
        padding: 15px 0;
      }
      .planning__info_valid {
        width: 135px;
      }
    }
    .planning__calender {
      .planning__calender_detais {
        white-space: normal;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .planning__info {
      display: flex;
      flex-direction: column;
      .planning__info_valid {
        order: 3;
        margin: 0 auto;
        float: none;
      }
      em {
        order: 2;
      }
      .planning__info-delete {
        padding: 9px;
      }
    }
  }
`;
