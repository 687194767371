import { moqService } from 'services';
// Importing moqConstants from the absolute path (`constants`) bugs.
import { moqConstants } from '../constants/Moq.constants';
import { modalActions } from './Modal';

export const moqActions = {
  uploadMoqFile,
  fetchItems,
  validate,
};

function uploadMoqFile(platformId, file) {
  return (dispatch) => {
    dispatch(request());
    moqService.upload(platformId, file).then(
      () => {
        dispatch(success());
        dispatch(modalActions.close());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: moqConstants.UPLOAD_REQUEST, platformId, file };
  }
  function success() {
    return { type: moqConstants.UPLOAD_SUCCESS };
  }
  function failure(error) {
    return { type: moqConstants.UPLOAD_FAILURE, error };
  }
}

function fetchItems() {
  return (dispatch) => {
    dispatch(request());
    moqService.fetchItems().then(
      (moqs) => {
        dispatch(success(moqs));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: moqConstants.FETCH_REQUEST };
  }
  function success(moqs) {
    return { type: moqConstants.FETCH_SUCCESS, moqs };
  }
  function failure(error) {
    return { type: moqConstants.FETCH_FAILURE, error };
  }
}

function validate(quantitiesByCartItem, itemId) {
  return (dispatch) => {
    dispatch(request());
    moqService.validate(quantitiesByCartItem).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: moqConstants.VALIDATE_REQUEST, itemId, quantitiesByCartItem };
  }
  function success() {
    return { type: moqConstants.VALIDATE_SUCCESS };
  }
  function failure(error) {
    return { type: moqConstants.VALIDATE_FAILURE, error };
  }
}
