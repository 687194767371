export const ocrOrderPdfConstants = {
  ADD_JOB_REQUEST: 'OCR_ORDER_PDF_ADD_JOB_REQUEST',
  ADD_JOB_SUCCESS: 'OCR_ORDER_PDF_ADD_JOB_SUCCESS',
  ADD_JOB_FAILURE: 'OCR_ORDER_PDF_ADD_JOB_FAILURE',

  GET_ALL_JOB_REQUEST: 'OCR_ORDER_PDF_GET_ALL_JOB_REQUEST',
  GET_ALL_JOB_SUCCESS: 'OCR_ORDER_PDF_GET_ALL_JOB_SUCCESS',
  GET_ALL_JOB_FAILURE: 'OCR_ORDER_PDF_GET_ALL_JOB_FAILURE',

  UPDATE_JOB_REQUEST: 'OCR_ORDER_PDF_UPDATE_JOB_REQUEST',
  UPDATE_JOB_SUCCESS: 'OCR_ORDER_PDF_UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAILURE: 'OCR_ORDER_PDF_UPDATE_JOB_FAILURE',

  DELETE_JOB_REQUEST: 'OCR_ORDER_PDF_DELETE_JOB_REQUEST',
  DELETE_JOB_SUCCESS: 'OCR_ORDER_PDF_DELETE_JOB_SUCCESS',
  DELETE_JOB_FAILURE: 'OCR_ORDER_PDF_DELETE_JOB_FAILURE',

  GET_JOB_RESULT_REQUEST: 'OCR_ORDER_PDF_GET_JOB_RESULT_REQUEST',
  GET_JOB_RESULT_SUCCESS: 'OCR_ORDER_PDF_GET_JOB_RESULT_SUCCESS',
  GET_JOB_RESULT_FAILURE: 'OCR_ORDER_PDF_GET_JOB_RESULT_FAILURE',

  STATUS_PROCESSING: 'PROCESSING',
  STATUS_DONE: 'DONE',
  STATUS_ARCHIVED: 'ARCHIVED',
};
