export const ConstantItems = {
  promotions: {
    id: 'const_1',
    slug: 'has_promotion',
    name: 'Promotions',
    level: 1,
    iconColor: 'red',
  },
  news: {
    id: 'const_2',
    slug: 'is_new',
    name: 'Nouveautés',
    level: 1,
    iconColor: 'red',
  },
  favorites: {
    id: 'const_3',
    slug: 'is_favorite',
    name: 'Favoris',
    level: 1,
  },
  seeAll: {
    id: 'const_5',
    slug: '',
    name: 'Voir tout',
    level: 1,
    iconColor: '#4A4A4A',
  },
  goBack: {
    id: 'const_6',
    slug: '',
    name: 'Revenir en arrière',
    level: 1,
    iconColor: '#4A4A4A',
  },
};
