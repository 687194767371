import React from 'react';

import Icon from 'components/Icon/Icon';
import withStyle from './ProspectBloc.styles';

const PasswordForm = ({
  className,
  plainPassword,
  isPlainPasswordVisible,
  plainPasswordConfirmation,
  isPlainPasswordConfirmationVisible,
  handleChange,
  makePasswordVisible,
}) => (
  <div className={className}>
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e934" />
      <span className="entete__label">Modifier le mot de passe</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut">Mot de Passe :</span>
        <input
          type={isPlainPasswordVisible ? 'text' : 'password'}
          className="ligne__value-input ligne__value"
          name="plainPassword"
          onChange={handleChange}
          value={plainPassword}
          required
        />
        <Icon
          className="content-ligne__icon"
          content="\e952"
          onClick={() => makePasswordVisible('')}
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Répéter Mot de passe :</span>
        <input
          type={isPlainPasswordConfirmationVisible ? 'text' : 'password'}
          className="ligne__value-input ligne__value"
          name="plainPasswordConfirmation"
          onChange={handleChange}
          value={plainPasswordConfirmation}
          required
        />
        <Icon
          className="content-ligne__icon"
          content="\e952"
          onClick={() => makePasswordVisible('confirmation')}
        />
      </div>
    </div>
  </div>
);

export default withStyle(PasswordForm);
