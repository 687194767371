import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Spinner from 'components/Core/Spinner/Spinner';

const LoginForm = ({ handleSubmit, handleChange, username, password, isLoading, onKeyPress }) => (
  <form name="form" onSubmit={handleSubmit}>
    <div className="login__container-label">
      <Icon content="\e94a" className="login__icon" />
      <span className="login__form-label">Identifiant</span>
      <input
        name="username"
        value={username}
        className="login__form-input"
        type="text"
        onChange={handleChange}
        onKeyPress={onKeyPress}
        required
      />
    </div>
    <div className="login__container-label">
      <Icon content="\e935" className="login__icon" />
      <span className="login__form-label">Mot de passe</span>
      <input
        name="password"
        value={password}
        className="login__form-input password"
        type="password"
        onChange={handleChange}
        onKeyPress={onKeyPress}
        required
      />
    </div>

    <Link href="#" to="/password-recovery" className="login__form-link">
      Mot de passe oublié
    </Link>
    {isLoading ? (
      <Spinner />
    ) : (
      <ButtonPrimary className="login__form-button" onClick={handleSubmit}>
        <span className="login__form-button--label">SE CONNECTER</span>
      </ButtonPrimary>
    )}
  </form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default LoginForm;
