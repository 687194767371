import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFullPath } from 'helpers';
import { termsConstants } from 'constants/Terms.constants';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import get from 'lodash.get';

const TermList = ({ user, currentPlatform }) => {
  const { client } = user;
  // const { terms } = get(client, 'terms', {});
  let terms = [];
  if (client && client.terms) {
    ({ terms } = client);
  }
  const effectiveTerm =
    (terms || []).find((term) => Number(term.platform_id) === Number(currentPlatform.id)) || {};

  return (
    <ul>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            (currentPlatform.cookie_path &&
              currentPlatform.cookie_path.replace(
                termsConstants.TERM_STORAGE_URL,
                process.env.REACT_APP_GCP_GS_URL
              )) ||
            ''
          }
        >
          <span>CGU & Cookies</span>
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={getFullPath(effectiveTerm.full_filename)}
        >
          <span>CGV</span>
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            (currentPlatform.privacy_path &&
              currentPlatform.privacy_path.replace(
                termsConstants.TERM_STORAGE_URL,
                process.env.REACT_APP_GCP_GS_URL
              )) ||
            ''
          }
        >
          <span>Données personnelles</span>
        </a>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => ({
  user: selectCurrentUser(state),
  currentPlatform: selectCurrentPlatform(state),
});

TermList.propTypes = {
  user: PropTypes.object.isRequired,
  currentPlatform: PropTypes.object,
};

export default connect(mapStateToProps, null)(TermList);
