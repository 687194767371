import styled from 'styled-components';

export default (Component) => styled(Component)`
position: absolute;
width: 100%;
max-height: calc(100vh - 70px);
min-height: 70px;
z-index: 100;
background-color: #fff;
overflow: hidden;
top: 43px;
left: 0;

.center {
  text-align: center;
  padding: 35px 0px;
}
.red {
  color: red;
  font-weight: 500;
}

.section-clients {
  table {
    tr {
      .header_client-number {
        width:10%;
      }
      .header_order-number {
          width: 20%;
        &:nth-child(2) {
          width:30%;
          padding: 20px;
        }
      }
      .header_product-pcb {
        width: 20%;
      }
      &.row {
        height: 80px;
        td {
          &:first-child {
            padding-left: 20px;
            color: #4A4A4A;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.section-marque {
  background-color: #FFFFFF;
}

.section-produit, .section-clients {
  background-color: #F5F5F5;
  display: inline-block;
  margin-right: 0px;
  width: 100%;
}

.section-produit {
  max-height: calc(100vh - 58px);
  overflow-y: auto;
  .home-page__section {
    table {
      padding-bottom: 80px;
      padding-top: 0px;
      .header_product-image {
        width: 10%;
      }
    }
  }
  .button_show_more {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #E2E2E2;
    width: 100%;
    color: #01366B;
    text-align: center;
    box-shadow: 0 -5px 4px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    h5 {
      padding: 24px;
    }
  }
  ::-webkit-scrollbar {
    width: 11px;
    background-color: #D8D8D8;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    padding: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #A4A4A4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
      background: #0FA3F0;
  }
}

.search-input {
  height: 22px;
  padding: 20px;
  padding-left: 20px;
  padding-bottom: 24px;
  border: 1px solid;
  border-width: 0px 0px 1px 0px;
  border-color:rgba(191,170,143,0.2);
}

.input__icon-delete {
  margin-right: 10px;
  font-size: 15px;
  color: #4A4A4A;
}

.input-produit {
  height: 18px;
  color: #787878;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  border: none;
  padding-right: 10px;
}

.search-result {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border: solid;
  border-width: 0px 0px 1px 0px;
  border-color: rgba(191,170,143,0.2);
  cursor:pointer;
  &:hover {
    .label-produit {
      font-weight: bold;
      color: #01366B;
    }
  }
}

.label-produit {
  width: 231px;
  color: #787878;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  transition: .5s;
}

.produit-number {
  border-radius: 20px;
  line-height: 20px;
  min-width: 20px;
  height: 20px;
  background-color: #D0021B;
  color: #FFFFFF; 
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;  
  text-align: center;
  font-style: normal;    
  display:inline-block;
  position:relative;
  top:-10px;
  margin-left:10px;
}

.label-produit:hover {
  width: 231px; 
  font-weight: bold;
  color: #01366B;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
}

.produit-type, .client-type {
  background-color: #F5F5F5;
  margin-right: 0px;
  display: inline-block;
  width: 100%;
  float: left;
}

.produit-type__label {
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 10px;
  border: 1px solid;
  border-width: 0px 1px 0px 0px;
  border-color:rgba(191,170,143,0.2);
}

.produit-type__info {
  background-color: #FFFFFF;
  width: 100%;
  margin-left: -1px;
  border: 1px solid;
  border-color: rgba(191,170,143,0.2);
}

.produit-type__info figure {
  height: 90px;
  width: 118px;
  position: relative;
  left: 20px;
  top: 20px;
  display: inline-block;
}

.produit__image {
  padding:0;
  margin:0;  
  background-size:cover;
  background-position:center;
}

.produit-info {
  margin-bottom: 20px;
  height: 90px;
  position: relative;
  left: 10px;
  display: inline-block;
}

.produit-info__ligne {
  height: 33%;
}

.produit-info__icon-new {
  font-size: 23px;
  position: relative;
  top: 5px;
  right: -2px;
}

.client-status-block {
  text-align: center;
}

.produit-info__label-h5{
  font-family: Montserrat;
  font-size: 14px;
  color: #787878;
}

.produit-info__icon {
  border-radius: 24px;  
  padding: 6px;
  font-size: 10px;
  text-align: center;
  position: relative;
  top: 3px;
  left: 23px;
  margin-right: 5px;
}

.produit-info__icon-van{
  position: relative;
  top: 6px;
  left: 19px;
  color: #787878;
  font-size: 15px;
  margin-right: 10px;
}

.produit-info__stock-label {
  font-size: 14px;
  color: #29D24E;
  position: relative;
  top: 5px;
  left: 8px;
}

.produit-info__etat {
  font-size: 18px;
  color: #29D24E;
  position: relative;
  top: 7px;
  left: 11px;
  margin-right: 10px;
}

.produit-info__ref {
  height: 18px;
  width: 251px;
  color: #787878;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  top: 10px;
  left: 31px;
}

.header-search {
  width: 100%;
  border-bottom: 1px solid rgba(1, 54, 107, 0.2);
  height: 70px;
  &>i {
    padding: 28px 24px;
    display: inline-block;
    vertical-align: middle;
    border-right: 1px solid rgba(1, 54, 107, 0.2);
  }
  .header--search-group {
    width: calc(100% - 75px);
    padding-left: 23px;
    display: inline-block;
    vertical-align: middle;
  }
}

.produit-type, .client-type {
  background-color: #F5F5F5;
  margin-right: 0px;
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 768px) {
    top: 67px;
    .section-clients {
      .table-wrapper {
        table {
          tr {
            .header_client-number {
              width:100%;
              display: inline-block;
            }
            .header_order-number {
                width: 100%;
                display: inline-block;
              &:nth-child(2) {
                width: 100%;
                display: inline-block;
                padding: 0px 0px 10px;
              }
            }
            .header_product-pcb {
              width: 32%;
              display: inline-block;
              float: right;
            }
            &.row {
              height: auto;
              width: 100%;
              margin: 10px 0px 0px 0px;
              &:first-child {
                margin: 0px !important;
              }
              &:nth-child(2) {
                margin-top:10px;
              }
              td {
                padding-bottom: 10px;
                &:first-child {
                  padding-left: 0px;
                }
              }
            }
          }
        }
      }
    }
    .section-produit {
      .home-page__section {
        .table-wrapper {
          table {
            padding-bottom: 60px;
            margin-bottom: 0px;
            tbody {
              tr {
                width: 100%;
                padding: 15px 0px ;
                margin: 1px 0px;
                &:nth-child(even) {
                  margin-right: auto;
                  width: 100%;
                }
              }
              .header_product-image {
                display: inline-block;
                width: 25%;
                figure {
                  width: 75px;
                  height: 75px;
                }
              }
              .header_product-wording {
                width: 75%;
                display: inline-block;
                margin-bottom: 0px;
                .icon-new {
                  position: static;
                  font-size: 20px;
                }
                .td__libelle-prod-span {
                    margin-left: 5px;
                    min-height: auto;
                }
                .td-libelle-prod__ref {
                  display: inline-block;
                  min-height: auto;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
}

@media screen and (max-width: 544px)   {
  height: calc(100vh - 70px);
  left: 0;
  width: 100%;
  .section-clients {
    .row:after {
      margin-top: calc(22px + 24px); // 22px corresponding to the height of labels + 24px of padding
    }
  }
}

@media screen and (max-width: 320px)   {
  height: calc(100vh - 70px);
  left: 0;
  width: 100%;
  top: 70px;
  .section-clients {
    .header_product-pcb { // corresponding to active/inactive labels
      width: 35% !important;
    }
  }
  .section-produit {
      .home-page__section {
        table {
          tr {
            display: block;
            width: 100%;
            padding: 20px;
          }
          .header_product-image {
            width: 100%;
            display: block;
            text-align: center;
            padding: 0;
            figure {
              width: 80px;
              height: 80px;
            }
          }
          .header_product-wording {
            width: 100%;
            display: block;
            margin-bottom: 20px;
          }
        }
      }
    }
`;
