import { commercialService } from 'services';
import { commercialConstants, userConstants } from '../constants';

const { TYPE_COMMERCIAL } = userConstants;

export const commercialActions = {
  fetchCommercials,
  fetchTotalRowsNumber,
  addCommercial,
  updateCommercial,
  fetchSalesNumbers,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    commercialService.fetchTotalRowNumber(TYPE_COMMERCIAL).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: commercialConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: commercialConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: commercialConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchCommercials(filter, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    commercialService.fetchCommercials(TYPE_COMMERCIAL, offset * limit, limit).then(
      (items) => {
        dispatch(success({ items, offset }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: commercialConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: commercialConstants.FETCH_SUCCESS, items, offset, filter };
  }
  function failure(error) {
    return { type: commercialConstants.FETCH_FAILURE, error, filter };
  }
}

function fetchSalesNumbers() {
  return (dispatch) => {
    dispatch(request());

    commercialService.fetchSalesNumbers().then(
      (salesNumbers) => {
        dispatch(success(salesNumbers));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: commercialConstants.FETCH_SALES_NUMBERS_REQUEST };
  }
  function success(salesNumbers) {
    return { type: commercialConstants.FETCH_SALES_NUMBERS_SUCCESS, salesNumbers };
  }
  function failure(error) {
    return { type: commercialConstants.FETCH_SALES_NUMBERS_FAILURE, error };
  }
}

function addCommercial(data, onSuccessCallback = null, onFailureCallback = null) {
  return (dispatch) => {
    dispatch(request());

    commercialService.addCommercial(data).then(
      (client) => {
        dispatch(success(client));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: commercialConstants.ADD_REQUEST, data };
  }
  function success(commercial) {
    if (onSuccessCallback) {
      onSuccessCallback();
    }
    return { type: commercialConstants.ADD_SUCCESS, commercial };
  }
  function failure(error) {
    if (onFailureCallback) {
      onFailureCallback();
    }
    return { type: commercialConstants.ADD_FAILURE, error };
  }
}

function updateCommercial(id, data, onSuccessCallback = null, onFailureCallback = null) {
  return (dispatch) => {
    dispatch(request());

    commercialService.updateCommercial(id, data).then(
      (client) => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        dispatch(success(client));
      },
      (error) => {
        if (onFailureCallback) {
          onFailureCallback();
        }
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: commercialConstants.UPDATE_REQUEST, data, filter: { id } };
  }
  function success(commercial) {
    return { type: commercialConstants.UPDATE_SUCCESS, commercial, filter: { id } };
  }
  function failure(error) {
    return { type: commercialConstants.UPDATE_FAILURE, error, filter: { id } };
  }
}
