import get from 'lodash.get';

export const selectArticleGroupItem = (state) => get(state, 'articleGroupItem');

export const selectArticleGroupItemData = (state) => get(state, 'articleGroupItem.data');

export const selectArticleGroupItemSelectedItem = (state) =>
  get(state, 'articleGroupItem.selectedItem.groups', []);

export const selectCrossSellingItemSimilarItemIdList = (state) =>
  get(state, 'articleGroupItem.selectedItem.CrossSellingItems', []);
