import React from 'react';
import PropTypes from 'prop-types';
import { numberToPrice } from 'helpers';
import sumBy from 'lodash.sumby';
import { updateFields } from './updateTableFields';

const DashboardSectionTd = ({ type, header, data }) => (
  <table>
    <thead>
      <tr>
        {header.map((name) => (
          <th>{name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {type === 'monthly'
        ? updateFields(data, type).map((value) => (
            <tr>
              <td>{value.lastname}</td>
              <td>{value.monthly_ca}</td>
              <td>{value.monthly_edge}</td>
              <td>{`${value.percent} %`}</td>
              <td>{`${value.ratioCA} % `}</td>
              <td>{`${value.ratioEdge} %`}</td>
            </tr>
          ))
        : updateFields(data, type).map((value) => (
            <tr>
              <td>{value.lastname}</td>
              <td>{value.monthly_ca}</td>
              <td>{value.commandNumber}</td>
              <td>{value.clientNumber}</td>
              <td>{value.cartEdge}</td>
            </tr>
          ))}
      {type === 'monthly' && (
        <tr className="calcul">
          <td />
          <td>{numberToPrice(sumBy(data, 'monthly_turnover') || 0)}</td>
          <td>{numberToPrice(sumBy(data, 'month_margin') || 0)}</td>
          <td>{`${sumBy(updateFields(data, type), 'percent')} %`}</td>
          <td>{`${sumBy(updateFields(data, type), 'ratioCA')} %`}</td>
          <td>{`${sumBy(updateFields(data, type), 'ratioEdge')} %`}</td>
        </tr>
      )}
    </tbody>
  </table>
);

DashboardSectionTd.propTypes = {
  type: PropTypes.string,
  header: PropTypes.array,
  data: PropTypes.array,
};

export default DashboardSectionTd;
