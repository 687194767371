import {
  priceCellRenderer,
  packagePriceCellRenderer,
  agTableQuantityCellRenderer,
  totalCellRenderer,
  isNewRenderer,
  departmentCellRenderer,
  availabilityFilterFormatter,
  availabilityValueGetter,
  hasPromotionRenderer,
} from '../cellRenderers.jsx';

const numberComparator = (a, b) => a - b;
const packagePriceComparator = (valueA, valueB, nodeA, nodeB) =>
  nodeA.data.applicable_price * nodeA.data.pcb - nodeB.data.applicable_price * nodeB.data.pcb;

const columnDefs = [
  {
    colId: 'department',
    headerName: 'Département',
    field: 'type',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
    cellRenderer: departmentCellRenderer,
    filterParams: {
      cellRenderer: departmentCellRenderer,
    },
  },
  {
    colId: 'zone',
    headerName: 'Secteur',
    field: 'zone_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'aisle',
    headerName: 'Rayon',
    field: 'aisle_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'category',
    headerName: 'Famille',
    field: 'category_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'sub_category',
    headerName: 'Sous-Famille',
    field: 'subcategory_name',
    sortable: true,
    filter: true,
    floatingFilter: true,
    hide: true,
  },
  {
    colId: 'top_sales_position',
    headerName: 'Postion top vente',
    field: 'topPosition',
    sortable: true,
    filter: true,
    hide: true,
  },
  {
    headerName: 'Nouveauté',
    field: 'is_new',
    sortable: true,
    filter: true,
    floatingFilter: true,
    cellRenderer: isNewRenderer,
    filterParams: {
      cellRenderer: isNewRenderer,
    },
    hide: true,
  },
  {
    headerName: '',
    field: 'ean13',
    sortable: true,
    cellRenderer: 'imageCellRenderer',
    cellClass: 'image-cell',
    width: 50,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Référence',
    field: 'reference',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFiltersToolPanel: true,
    width: 138,
    suppressSizeToFit: true,
  },
  {
    headerName: 'EAN',
    field: 'ean13',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFiltersToolPanel: true,
    width: 161,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Libellé',
    field: 'name',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    minWidth: 170,
    suppressFiltersToolPanel: true,
  },
  {
    headerName: 'Marque',
    field: 'manufacturer.name',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    hide: true,
  },
  {
    headerName: 'PCB',
    field: 'pcb',
    comparator: numberComparator,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressFiltersToolPanel: true,
    cellStyle: { 'text-align': 'center' },
    width: 100,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Prix unitaire',
    field: 'applicable_price',
    sortable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: 'priceCellRenderer',
    floatingFilter: true,
    suppressFiltersToolPanel: true,
    cellStyle: { 'text-align': 'center' },
    width: 140,
    suppressSizeToFit: true,
    comparator: numberComparator,
  },
  {
    headerName: 'Prix colis',
    field: 'applicable_price',
    sortable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: 'packagePriceCellRenderer',
    floatingFilter: true,
    suppressFiltersToolPanel: true,
    cellStyle: { 'text-align': 'center' },
    width: 150,
    suppressSizeToFit: true,
    comparator: packagePriceComparator,
  },
  {
    headerName: 'Promotion',
    field: 'promotion_id',
    sortable: true,
    filter: true,
    hide: true,
    valueGetter: hasPromotionRenderer,
    colId: 'promotion',
    lockVisible: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: 'Sélection',
    field: 'group_item',
    sortable: true,
    filter: 'AssortmentFilter',
    hide: true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: 'Quantités',
    colId: 'quantity',
    field: 'product',
    cellRendererFramework: agTableQuantityCellRenderer,
    pinned: 'right',
    cellStyle: { 'background-color': '#FBFBFD' },
    suppressFiltersToolPanel: true,
    suppressSizeToFit: true,
    width: 150,
  },
  {
    headerName: 'Total',
    colId: 'total',
    field: 'product',
    cellRendererFramework: totalCellRenderer,
    pinned: 'right',
    cellStyle: {
      'background-color': '#FBFBFD',
      'text-align': 'center',
    },
    suppressFiltersToolPanel: true,
    suppressSizeToFit: true,
    width: 138,
  },
  {
    headerName: 'Disponibilité',
    colId: 'availability',
    field: 'stock',
    sortable: true,
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    valueGetter: availabilityValueGetter,
    keyCreator: availabilityFilterFormatter,
    cellRenderer: 'availabilityCellRenderer',
    pinned: 'right',
    cellStyle: { 'background-color': '#FBFBFD' },
    suppressSizeToFit: true,
    width: 138,
  },
];

export default columnDefs;
