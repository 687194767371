import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { termsActions } from 'actions';
import { getFullPath, getCollection } from 'helpers';
import { termsConstants } from 'pages/../constants';
import Icon from 'components/Icon/Icon';

const { STATUS_ACTIVE } = termsConstants;
class Terms extends React.Component {
  componentDidMount() {
    const { fetchTerms, selectedPlatform } = this.props;
    if (selectedPlatform) {
      fetchTerms(selectedPlatform);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchTerms, selectedPlatform } = this.props;
    const { selectedPlatform: prevSelectedPlatform } = prevProps;
    if (selectedPlatform && selectedPlatform !== prevSelectedPlatform) {
      fetchTerms(selectedPlatform);
    }
  }

  getTerms = () => {
    const { termsCollections, selectedPlatform } = this.props;
    const filter = {
      platform: Number(selectedPlatform),
      status: STATUS_ACTIVE,
    };
    const collection = getCollection(termsCollections, filter);
    if (collection && collection.items && collection.items.length) {
      return collection.items.flat();
    }
    return [];
  };

  onChange = (event) => {
    const { updateState, selectedPlatform, clientTerms } = this.props;
    const selectedTermId = Number(event.target.value);
    if (selectedPlatform && clientTerms) {
      const filteredClientTerms = clientTerms.filter(
        (clientTerm) => Number(clientTerm.platform_id) !== Number(selectedPlatform)
      );
      filteredClientTerms.push({ id: selectedTermId });
      updateState('terms', filteredClientTerms);
    }
  };

  render() {
    const { readOnly, clientTerms, selectedPlatform } = this.props;
    const clientTerm = clientTerms.find((term) => term.platform_id === selectedPlatform) || {};
    const { id: clientTermId } = clientTerm || {};
    const terms = this.getTerms();
    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon icon-file" content="\e903" />
          <span className="entete__label">{readOnly ? 'CGV' : 'CGV Client applicable'}</span>
        </div>
        {readOnly ? (
          <div className="block-info__content">
            <div className="content__ligne">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getFullPath(clientTerm.full_filename)}
                className="download__link"
              >
                <span className="bold"> Lien PDF : </span>
                <Icon content="\e936" />
              </a>
            </div>
          </div>
        ) : (
          <div className="block-info__content download">
            <div className="content__ligne">
              <div className="bloc__select">
                <span className="bold ligne__attribut">Sélectionner CGV :</span>
                <select onChange={this.onChange}>
                  <option hidden selected disabled />
                  {terms.map((term) => (
                    <option value={term.id} selected={term.id === clientTermId}>
                      {term.title || term.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { terms, platform } = state;
  return {
    termsCollections: terms.collections || [],
    selectedPlatform: platform.selectedId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTerms: (platform) => {
    dispatch(termsActions.fetchTerms({ platform, status: STATUS_ACTIVE }));
  },
});

Terms.propTypes = {
  readOnly: PropTypes.bool,
  fetchTerms: PropTypes.func,
  termsCollections: PropTypes.array,
  clientTerms: PropTypes.array,
  updateState: PropTypes.func,
  selectedPlatform: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
