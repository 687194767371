import { history } from 'helpers/History';

export const PAGE_URL_KEY = 'page';

export function getPageFromUrl() {
  const url = window.location.href;
  const match = url.match(`[?&]${PAGE_URL_KEY}=(.+)`);
  return match ? match[1] : null;
}

export function setPageOfUrl(page) {
  const url = window.location.pathname;
  const match = url.match(`[?&]${PAGE_URL_KEY}=(.+)`);
  if (match) {
    const value = match[1];
    const newUrl = url.replace(`${PAGE_URL_KEY}=${value}`, `${PAGE_URL_KEY}=${page}`);
    history.push(newUrl);
  } else {
    // Check if other parameters are here
    const isThereOtherParameters = url.indexOf('?') > -1;
    const newUrl = isThereOtherParameters
      ? `${url}&${PAGE_URL_KEY}=${page}`
      : `${url}?${PAGE_URL_KEY}=${page}`;
    history.push(newUrl);
  }
}
