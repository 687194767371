import { orderItemsService } from 'services';
import { store } from 'helpers';
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { orderItemsConstants } from '../constants';

export const orderItemsActions = {
  fetchTotalRowsNumber,
  fetchItems,
  updateItemsRowsNumber,
  updateItems,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    orderItemsService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderItemsConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: orderItemsConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: orderItemsConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchItems(filter, offset, limit) {
  return (dispatch) => {
    dispatch(request());
    orderItemsService.fetchItems(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: orderItemsConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: orderItemsConstants.FETCH_SUCCESS, items, filter };
  }
  function failure(error) {
    return { type: orderItemsConstants.FETCH_FAILURE, error };
  }
}

function updateItemsRowsNumber(numberDataAvailable, orderId) {
  const filter = orderId ? { order: orderId } : {};
  store.dispatch({
    type: orderItemsConstants.FETCH_COUNT_SUCCESS,
    numberDataAvailable: Number(numberDataAvailable),
    filter,
  });
}

function updateItems(items, orderId) {
  const filter = orderId ? { order: orderId } : {};
  store.dispatch({ type: orderItemsConstants.FETCH_SUCCESS, items, filter });
}
