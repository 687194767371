import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  position: relative;
  .delivery-date-popper {
    top: -10px !important;
    left: -520px !important;
    transform: initial !important;
    margin: 0;
    background: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
  }
  .delivery-date-calendar-content {
    border: 0;
    border-radius: 0;
    padding: 20px;
    position: relative;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__navigation {
      top: 35px;
    }
    .react-datepicker__navigation--previous {
      left: 90px;
    }
    .react-datepicker__navigation--next {
      right: 90px;
    }
    .react-datepicker__month-container {
      font-family: Montserrat;
      text-transform: capitalize;
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            width: 58px;
            height: 26px;
          }
        }
      }
    }
    .react-datepicker__header {
      background-color: #fff;
      border-bottom: 0px;
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 20px;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          width: 58px;
          height: 26px;
        }
      }
    }
    i {
      position: absolute;
      top: 20px;
      right: 25px;
      color: #a4a4a4;
      cursor: pointer;
    }
  }
  /**************Responsive*************/
  @media ${screen.tablet} {
    .delivery-date-popper {
      left: -380px !important;
    }
    .delivery-date-calendar-content {
      padding: 20px 0px;
      .react-datepicker__navigation--previous {
        left: 60px;
      }
      .react-datepicker__navigation--next {
        right: 60px;
      }
      .react-datepicker__month-container {
        font-family: Montserrat;
        text-transform: capitalize;
        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              width: 45px;
            }
          }
        }
      }
      .react-datepicker__header {
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            width: 45px;
          }
        }
      }
      i {
        right: 20px;
      }
    }
  }
  @media (max-width: 674px) {
    .delivery-date-popper {
      top: 20px !important;
      left: -173px !important;
    }
  }
  @media ${screen.mobileL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (max-width: 370px) {
    .delivery-date-calendar-content {
      .react-datepicker__navigation--previous {
        left: 30px;
      }
      .react-datepicker__navigation--next {
        right: 30px;
      }
      .react-datepicker__month-container {
        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              width: 35px;
            }
          }
        }
      }
      .react-datepicker__header {
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            width: 35px;
          }
        }
      }
      i {
        top: 13px;
        right: 0px;
      }
    }
  }
  @media ${screen.mobile} {
    .delivery-date-popper {
      width: 100vw;
      left: -157px !important;
    }
    .delivery-date-calendar-content {
      .react-datepicker__navigation--previous {
        left: 20px;
      }
      .react-datepicker__navigation--next {
        right: 20px;
      }
      .react-datepicker__month-container {
        font-family: Montserrat;
        text-transform: capitalize;
        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              width: 30px;
            }
          }
        }
      }
      .react-datepicker__header {
        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
          font-size: 20px;
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            width: 30px;
          }
        }
      }
    }
  }
`;
