import React from 'react';
import { connect } from 'react-redux';
import { catalogActions } from 'actions';
import { store, getValueOfAttribut } from 'helpers';
import Spinner from 'components/Core/Spinner/Spinner';
import { platformConstants } from '../../../constants';
import { selectCurrentPlatform } from '../../../selectors/platform';

const ExportCatalogFileLink = ({ isLoadingCatalogFile, exportCatalogFile }) => {
  const state = store.getState();
  const currentPlatform = selectCurrentPlatform(state);
  const { attributs } = currentPlatform;
  const { KEY_HAS_EXPORT_CATALOG_FILE } = platformConstants;
  const shouldDisplayCatalogFileLink = getValueOfAttribut(attributs, KEY_HAS_EXPORT_CATALOG_FILE);

  return (
    <div>
      {isLoadingCatalogFile && <Spinner size="10" />}
      {Number(shouldDisplayCatalogFileLink) === 1 && (
        <div
          style={{ float: 'right', cursor: isLoadingCatalogFile ? 'default' : 'pointer' }}
          onClick={isLoadingCatalogFile ? null : exportCatalogFile}
        >
          <i className="icon-bdc" />
          <button type="button">Télécharger le BDC (Excel)</button>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoadingCatalogFile: state.catalog.exportCatalogFile.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  exportCatalogFile: () => {
    dispatch(catalogActions.exportCatalogFile(store.getState().platform.selectedId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportCatalogFileLink);
