import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import { connect } from 'react-redux';
import { creditActions } from 'actions';

const CreditActionTD = styled.td`
  .credit-action__button {
    width: 152px;
    padding: 4px 20px;
    box-sizing: border-box;
    position: relative;

    &-icon {
      font-size: 20px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-top: 0;
    }

    &-title {
      width: 90px;
      text-align: left;
      padding-left: 13px;
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  @media screen and (max-width: 768px) {
    float: right;
    text-align: right;
  }
`;

const icon = {
  check: '\\e909',
  delivery: '\\e918',
};

const CreditAction = ({ className, openCredit, creditId, creditShow, product: { credits } }) => (
  <CreditActionTD show={creditShow} className={className}>
    <ButtonPrimary inverted className="credit-action__button" onClick={() => openCredit(creditId)}>
      <Icon
        className="credit-action__button-icon"
        content={credits && credits.length ? icon.check : icon.delivery}
      />
      <span className="credit-action__button-title">
        {credits && credits.length ? 'Demande envoyée' : "Demande d'avoir"}
      </span>
    </ButtonPrimary>
  </CreditActionTD>
);

const mapStateToProps = (state) => ({
  creditShow: state.credit,
});

const mapDispatchToProps = (dispatch) => ({
  openCredit: (creditId) => dispatch(creditActions.open(creditId)),
});

CreditAction.propTypes = {
  className: PropTypes.string,
  openCredit: PropTypes.func,
  creditId: PropTypes.number,
  creditShow: PropTypes.object,
  product: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditAction);
