import styled from 'styled-components';

export default (Component) => styled(Component)`
  display: flex;
  flex-direction: column;
  height: 100%;
  .product-card__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .green {
    background-color: green;
  }
  .orange {
    background-color: orange;
  }
  .red {
    background-color: red;
  }
  .product-card__container {
    display: flex;
    justify-content: space-around;
  }
  .product-card__container-type {
    font-weight: 700;
    border-collapse: collapse;
    width: calc(100% - 150px);
  }
  .product-card__container__column {
    display: flex;
    flex-direction: column;
  }
  .productCard__close {
    width: 30px;
    height: 30px;
    background-color: #01366b;
    color: white;
    border-radius: 3px;
    font-size: 25px;
    cursor: pointer;
    padding-left: 7px;
  }
  .product-card__product-image {
    display: block;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  .product-card__substitut-button-container {
    display: flex;
    justify-content: center;
    .substitut-button {
      padding: 10px;
      border-radius: 5px;
    }
  }
  .product-card__name {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  .product-card__header__info {
    width: 80%;
    margin: 0 auto;
    .product-card__type {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 14px;
      text-transform: capitalize;
      .td_libelle-prod__icon-temperature {
        margin-right: 15px;
        border-radius: 100%;
        .icon-dry:before,
        .icon-apple:before,
        .icon-snow:before {
          width: 18px;
          height: 18px;
          line-height: 18px;
          font-size: 10px;
        }
      }
      & + svg {
        width: 100%;
      }
    }
    .product-card__selection {
      .icon-assortment {
        margin-right: 15px;
        color: rgb(237, 221, 12);
        font-size: 18px;
      }
    }
  }
  .product-card__price-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 15px;
    .product-card__price-wrapper {
      height: 75px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .product-card__price-container__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      .product-card__price-container__title {
        font-size: 10px;
        font-weight: 700;
        color: #01366b;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-14px - 100%));
      }
      .product-card__price-container__text-container {
        font-weight: 700;
        color: #01366b;
        white-space: nowrap;
        .product-card__price-container__text {
          font-size: 26px;
        }
        .product-card__price-container__small-text {
          font-size: 16px;
        }
      }
      .product-card__price-container__sub-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #01366b;
        font-size: 14px;
      }
      .product-card__price-container__sub-text {
        font-weight: 400;
        white-space: nowrap;
        text-align: right;
        margin-left: auto;
      }
      .product-card__price-container__promo-text {
        font-weight: 700;
      }
      .product-card__price-container__box-text {
        margin-top: 5px;
        font-size: 10px;
        font-weight: 400;
        color: #65676b;
      }
    }
  }
  hr {
    margin: 20px 0 10px 0;
  }
  .product-card__breadcrum-hr {
    margin: 20px 0 10px 0;
  }

  .product-card__last-orders__title {
    display: flex;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
  }

  .product-card__last-orders__content {
    display: flex;
    margin: 0px;
    padding: 0px;
  }

  .product-card__availability {
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;
    .productCard__important {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 14px !important;
      font-weight: 700;
    }
    .product-card__dot {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      display: block;
      float: left;
      margin-right: 10px;
    }
  }
  .product-card__info-detail {
    display: flex;
    font-size: 12px;
    margin-bottom: 20px;
    .product-card__store-info-wrapper {
      border-left: 1px solid #ccc;
      margin-left: 5px;
      padding-left: 5px;
    }
    & > div {
      width: 50%;
      display: flex;
      table {
        width: 100%;
        tr {
          td {
            padding-bottom: 5px;
          }
          .product-card__supplier-info__title,
          .product-card__store-info__title {
            height: 25px;
            background: #f0f2f5;
            border-radius: 5px;
            padding-bottom: 5px;
            padding-top: 5px;
            text-align: center;
          }
          .product-card__supplier-info__td-spacer,
          .product-card__store-info__td-spacer {
            padding-bottom: 15px;
            height: 0;
          }
        }
      }
    }
  }
  .productCard__download {
    clear: both;
    text-align: center;
  }
  .productCard__download__wrapper {
    cursor: pointer;
    color: #4a4a4a;
    text-decoration: none;
  }
  .productCard__download__icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .product-card__breadcrumb {
    width: 100%;
    text-align: center;
    font-size: 10px;
    margin-bottom: 20px;
    span {
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  .action-bloc__favorie-add {
    margin-top: auto !important;
  }
  .product-cart_quantity {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .productCard__degressive {
    margin-bottom: 20px;

    .degressive-price-cell__table {
      td,
      th {
        font-size: 12px;
      }

      th {
        color: black;
      }

      td {
        font-weight: 700;
      }
    }
  }
`;
