import React from 'react';
import get from 'lodash.get';

export default (data) => {
  const clientCode = get(data, 'product.client.ext_code', null);
  if (clientCode) {
    return <td>{data.product.client.ext_code}</td>;
  }

  return <td>Tous</td>;
};
