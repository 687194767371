import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuantityHandler from 'components/Core/QuantityHandler/QuantityHandler';
import { creditActions, invoiceActions, modalActions } from 'actions';
import { selectCurrentPlatformId } from 'selectors/platform';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import { AddCreditFormWrapper } from './AddCreditForm.style';

const AddCreditForm = ({
  closeModal,
  createCredits,
  selectedPlatformId,
  invoice,
  invoiceItem,
  getInvoiceByID,
  pushToast,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState(null);
  const [litigation, setLitigation] = useState(false);
  const [image, setImage] = useState(null);
  const [quantityError, setQuantityError] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [commentError, setCommentError] = useState(null);
  // const [imageError, setImageError] = useState(null);

  const countCreditedItem = () => {
    let creditCount = 0;
    invoiceItem.credits.forEach((credit) => {
      creditCount += credit.quantity;
    });

    return creditCount;
  };

  const handleQuantityChange = (event) => {
    if (
      (event.target.value <= 999 &&
        event.target.value > 0 &&
        event.target.value <= invoiceItem.quantity_invoiced_u_c - countCreditedItem()) ||
      !event.target.value
    ) {
      setQuantity(event.target.value);
      setQuantityError(null);
    }
  };

  const handleQuantityControl = (value) => {
    if (Number(quantity) + value > 0 && Number(quantity) + value <= 999) {
      setQuantity(Number(quantity) + value);
      setQuantityError(null);
    }
  };

  const handleReasonChange = (event) => {
    setReasonError(null);
    setReason(event.target.value);
  };

  const handleCommentChange = (event) => {
    setCommentError(null);
    setComment(event.target.value);
  };

  // const handleImageChange = (event) => {
  //   const image = event.target.files[0];
  //   const allowedTypes = ['image/png', 'image/jpeg'];
  //
  //   if (allowedTypes.indexOf(image.type) === -1) {
  //     setImage(null);
  //     setImageError('Veuillez selectionner une image valide');
  //
  //     return;
  //   }
  //   setImageError(null);
  //   setImage(event.target.files[0]);
  // };

  const handleLitigationChange = () => {
    setLitigation(!litigation);
  };

  const handleQuantityBlur = (event) => {
    if (!quantity) {
      setQuantityError('Quantité éronée');
    }
  };

  const handleReasonBlur = (event) => {
    if (!reason) {
      setReasonError('Veuillez renseigner une raison');
    }
  };

  const handleCommentBlur = (event) => {
    if (!comment) {
      setCommentError('Veuillez indiquer un commentaire');
    }
  };

  const isFormValid = () => {
    let isValid = true;
    if (quantity < 0 || quantity > 999 || !quantity) {
      setReasonError('Veuillez renseigner une quantité valide');
      isValid = false;
    }
    if (!reason) {
      setReasonError('Veuillez renseigner une raison');
      isValid = false;
    }
    if (!comment) {
      setCommentError('Veuillez indiquer un commentaire');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      const creditList = {};
      creditList[invoiceItem.id] = [
        {
          comment,
          reason,
          quantity,
          image,
          checked: litigation,
        },
      ];
      createCredits(creditList, { invoice: Number(invoice.id), platform: selectedPlatformId });
      getInvoiceByID(invoice.id);
      closeModal();
      pushToast({
        type: toastConstants.TYPE_SUCCESS,
        message: "Demande d'avoir envoyée",
      });
    }
  };

  // const formatImageName = (imageName) => {
  //   return imageName.length > 50 ? `${imageName.slice(0, 47)}...` : imageName;
  // };

  return (
    <AddCreditFormWrapper>
      <span className="add-credit-form__title">
        Indiquer les quantités (en unité) et raison de l'avoir demandé sur cet article
      </span>
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="add-credit-form__quantity-container">
          <label>Quantité</label>
          <div className="add-credit-form__quantity-handler">
            <QuantityHandler
              quantity={quantity}
              handleMinus={() => handleQuantityControl(-1)}
              handlePlus={() => handleQuantityControl(+1)}
              handleChange={(event) => {
                handleQuantityChange(event, invoiceItem);
              }}
              handleBlur={handleQuantityBlur}
              disableMinus={quantity <= 1}
              disablePlus={quantity >= invoiceItem.quantity_invoiced_u_c - countCreditedItem()}
            />
            {quantityError && <small className="form__error-message">{quantityError}</small>}
          </div>
          <div className="add-credit-form__reason-container">
            <select
              name="reason"
              value={reason || ''}
              onChange={handleReasonChange}
              onBlur={(event) => handleReasonBlur(event)}
            >
              <option className="add-credit-form__reason-list__disabled" value="">
                -- Motif
              </option>
              <option value="Prix HT erroné">Prix HT erroné</option>
              <option value="Quantité erronée">Quantité erronée</option>
              <option value="Inversion produit">Inversion produit</option>
              <option value="Marchandise abîmée">Marchandise abîmée</option>
              <option value="DLV dépassée">DLV dépassée</option>
            </select>
            {reasonError && <small className="form__error-message">{reasonError}</small>}
          </div>
        </div>
        <div className="add-credit-form__comment-container">
          <textarea
            onChange={handleCommentChange}
            onBlur={(event) => handleCommentBlur(event)}
            placeholder="Ajouter un commentaire"
          />
          {commentError && <small className="form__error-message">{commentError}</small>}
        </div>
        {/* <div className="add-credit-form__file-input-container"> */}
        {/*  <input */}
        {/*    id="add-credit-form__file-input" */}
        {/*    type="file" */}
        {/*    accept="image/png, image/jpeg" */}
        {/*    onChange={handleImageChange} */}
        {/*  /> */}
        {/*  <label */}
        {/*    className="add-credit-form__file-input-label" */}
        {/*    htmlFor="add-credit-form__file-input" */}
        {/*  > */}
        {/*    <BiPlusMedical />{' '} */}
        {/*    {(image && formatImageName(image.name)) || 'Ajouter une pièce jointe (photo)'} */}
        {/*  </label> */}
        {/*  {imageError && <small className="form__error-message">{imageError}</small>} */}
        {/* </div> */}
        <div className="add-credit-form__litigation">
          <input
            id="add-credit-form__litigation-checkbox"
            type="checkbox"
            onChange={handleLitigationChange}
            checked={litigation}
          />
          <label htmlFor="add-credit-form__litigation-checkbox">
            Litiges vus avec le livreur et mentionnés sur le bon de réception
          </label>
        </div>
        <div className="add-credit-form__buttons-container">
          <button className="add-credit-form__cancel-button" type="button" onClick={closeModal}>
            Fermer
          </button>
          <button className="add-credit-form__submit-button" type="button" onClick={handleSubmit}>
            Envoyer
          </button>
        </div>
      </form>
    </AddCreditFormWrapper>
  );
};

AddCreditForm.propTypes = {
  closeModal: PropTypes.func,
  createCredits: PropTypes.func,
  selectedPlatformId: PropTypes.number,
  invoice: PropTypes.object,
  invoiceItem: PropTypes.object,
  getInvoiceByID: PropTypes.func,
  pushToast: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  createCredits: (creditList, invoiceFilter) =>
    dispatch(creditActions.createCredits(creditList, invoiceFilter)),
  getInvoiceByID: (invoiceId) => dispatch(invoiceActions.fetchInvoice(invoiceId)),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
});

const mapStateToProps = (state) => ({
  selectedPlatformId: selectCurrentPlatformId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditForm);
