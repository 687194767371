import last from 'lodash.last';
import first from 'lodash.first';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import compact from 'lodash.compact';
import Bin from './Bin.js';
import containersTypeDry from './containersTypeDry.js';
import containersTypeReefer from './containersTypeReefer.js';

const containers = {
  containersTypeReefer,
  containersTypeDry,
};

class Packer {
  bins = [];

  items = [];

  weightByItems = {};

  volumeByItems = {};

  totalVolume = 0;

  totalWeight = 0;

  totalBinsVolume = 0;

  totalBinsWeight = 0;

  optimization = false;

  debug = false;

  containersType = null;

  defaultContainerIndex = 0; // 20inch

  marginError = 0.01;

  constructor(items = [], containersType = 'containersTypeReefer') {
    if (items.length > 0) {
      this.containersType = containers[containersType];
      this.setItems(items);
      this.parse();
      this.calculateTotals();
    }
  }

  outputDebug(...args) {
    // if (this.debug === true) console.log(...args);
    if (this.debug === true) console.log('[algo]', ...args);
  }

  setOptimization(state = true) {
    this.optimization = state;
  }

  getOptimization() {
    return this.optimization;
  }

  setDebug(state = true) {
    this.debug = state;
  }

  addBin(bin) {
    this.bins.push(bin);
  }

  addItem(item) {
    this.items.push(item);
  }

  setItems(items) {
    const previousAllItemsQty = this.items.reduce((total, item) => total + item.qty, 0);
    this.outputDebug('Fn::setItems - Sum of qty of previous items:', previousAllItemsQty);
    // this.outputDebug('Fn::setItems', this.items);
    this.items = items;
    this.parse();
    this.calculateTotals();
    const newAllItemsQty = this.items.reduce((total, item) => total + item.qty, 0);
    this.outputDebug('Fn::setItems - Sum of qty of new items:', newAllItemsQty);
    // Call resetBins() only if items are removed and not added
    if (this.items.length > 0) {
      if (newAllItemsQty < previousAllItemsQty) {
        this.outputDebug('Fn::setItems - resetBins');
        this.resetBins();
      }
    }
  }

  getItems() {
    return this.items;
  }

  resetBins() {
    this.bins = [];
    this.createMoreBins(1);
    this.getTotalsBins();
  }

  getBins() {
    return this.bins;
  }

  setBins(bins) {
    this.bins = bins;
  }

  getDefaultBinProps() {
    const { name, width, height, length, weight, volumeVrac, type, mode } = this.containersType[
      this.defaultContainerIndex
    ];
    return {
      name,
      width,
      height,
      length,
      weight,
      volumeVrac,
      type,
      mode,
    };
  }

  createMoreBins(n = 1) {
    this.outputDebug(`Fn::createMoreBins - Adding ${n} new bin(s)`);
    [...Array(n).keys()].forEach((element) => {
      this.outputDebug(`Fn::createMoreBins - Add bin`);
      const b = this.getDefaultBinProps();
      this.addBin(
        new Bin(b.name, b.width, b.height, b.length, b.weight, b.volumeVrac, b.type, b.mode)
      );
    });
  }

  calculateMissingBin() {
    const restVolume = this.totalVolume - this.totalBinsVolume;
    const restWeight = this.totalWeight - this.totalBinsWeight;

    const { name, width, height, length, weight } = this.getDefaultBinProps();

    const calcVol = restVolume / (width * height * length);
    const calcWeight = restWeight / weight;
    const calcVolRounded = calcVol > 0 ? (calcVol > 1 ? Math.ceil(calcVol) : 1) : 0;
    const calcWeightRounded = calcWeight > 0 ? (calcWeight > 1 ? Math.ceil(calcWeight) : 1) : 0;
    const binsCalc = [];
    if (calcVolRounded > 0) {
      binsCalc.push(calcVolRounded);
    }
    if (calcWeightRounded > 0) {
      binsCalc.push(calcWeightRounded);
    }

    if (binsCalc.length > 0) {
      // Sort by highest value
      binsCalc.sort((a, b) => b - a);

      return Math.round(binsCalc[0]);
    }

    return 0;
  }

  getVolumeByItems() {
    return this.volumeByItems;
  }

  getWeightByItems() {
    return this.weightByItems;
  }

  getTotalWeight() {
    return this.totalWeight;
  }

  getTotalVolume() {
    return this.totalVolume;
  }

  setTotalsItems(key, value) {
    this[key] = value;
  }

  calculateTotals() {
    this.makeSum();
    this.makeSum('volume');
  }

  sortBins() {
    this.outputDebug('Fn::sortBins');
    if (this.bins && this.bins.length > 0) {
      this.bins.sort((a, b) => {
        const weighA = a.height * a.width * a.length;
        const weighB = b.height * b.width * b.length;
        return weighA - weighB;
      });
    }
  }

  getTotalsBins() {
    if (this.bins && this.bins.length > 0) {
      this.totalBinsVolume = 0;
      this.totalBinsWeight = 0;

      this.outputDebug('Fn::getTotalsBins', this.bins, this.bins.length);
      this.bins.forEach((bin) => {
        // this.totalBinsVolume += (bin.height * bin.width * bin.length) / 1000 / 1000;
        // abdou
        if (bin.maxVolume) {
          this.totalBinsVolume += bin.maxVolume;
        } else {
          this.totalBinsVolume += bin.height * bin.width * bin.length;
        }
        // fin abdou
        this.totalBinsWeight += bin.maxWeight;
      });
    }
  }

  getBiggerValue(arr) {
    return arr.reduce((largest, current) => (current > largest ? current : largest), arr[0]);
  }

  // eslint-disable-next-line consistent-return
  chooseBin(volume, weight, rest = false) {
    const restVolume = volume;
    const restWeight = weight;

    if (restVolume > 0 || restWeight > 0) {
      const arrQty = [];

      for (let i = 0; i < this.containersType.length; i += 1) {
        const c = this.containersType[i];
        // abdou
        let cVolume;
        if (c.volumeVrac) {
          cVolume = c.volumeVrac;
        } else {
          cVolume = c.height * c.width * c.length;
        }
        const cWeight = c.weight;
        // fin abdou

        const dVolume = restVolume > 0 ? restVolume / cVolume : 0; // Math.floor(restVolume / cVolume);
        const dWeight = restWeight > 0 ? restWeight / cWeight : 0; // Math.floor(restWeight / cWeight);

        arrQty[i] = Math.max(...[dVolume, dWeight]);
        // const max = Math.max(...([dVolume, dWeight].filter((v) => v > 0)));
        // if (max > 0) {
        //   arrQty[i] = max;
        //   // this.outputDebug(c.name, dVolume, dWeight);
        //   this.outputDebug('Best ->', Math.max(...([dVolume, dWeight].filter((v) => v > 0))));
        //   // this.outputDebug('- rest ->', Math.max(...[dVolume, dWeight]));
        // }
      }
      this.outputDebug('Containers array ', arrQty);

      // let qtyContainer;
      // if (rest) {
      //   const newQty = arrQty.filter((v) => v > 0.9);
      //   qtyContainer = Math.min(...arrQty);
      //   // const qtyContainer = Math.max(...arrQty);
      // } else {
      //   const newQty = arrQty.filter((v) => v <= 1);
      //   this.outputDebug('Containers qty min', newQty);
      //   qtyContainer = Math.min(...newQty);
      // }
      let qtyContainer = rest ? Math.max(...arrQty) : Math.min(...arrQty);
      const qtyContainerFloor = Math.floor(qtyContainer);
      this.outputDebug('Containers qty', qtyContainer);
      this.outputDebug('Containers qty floor', qtyContainerFloor);
      if (qtyContainerFloor > 0) {
        const cIndex = arrQty.findIndex((nIndex) => nIndex === qtyContainer);

        this.outputDebug('Containers fit the best is', this.containersType[cIndex]);
        this.outputDebug(
          `For best proposal we need x${qtyContainerFloor} of "${this.containersType[cIndex].name}"`
        );

        const newContainer = this.containersType[cIndex];
        // abdou
        let ncVolume;
        if (newContainer.volumeVrac) {
          ncVolume = newContainer.volumeVrac;
        } else {
          ncVolume = newContainer.height * newContainer.width * newContainer.length;
        }
        // fin abdou
        const ncWeight = newContainer.weight;

        const rVolume = restVolume - ncVolume * qtyContainerFloor;
        const rWeight = restWeight - ncWeight * qtyContainerFloor;

        this.outputDebug('• Volume items', restVolume);
        this.outputDebug('• Weight items', restWeight);
        this.outputDebug(`Volume containers x${qtyContainerFloor}`, ncVolume * qtyContainerFloor);
        this.outputDebug(`Weight containers x${qtyContainerFloor}`, ncWeight * qtyContainerFloor);
        this.outputDebug('Do we have rest volume?', rVolume > 0 ? rVolume : 0);
        this.outputDebug('Do we have rest weight?', rWeight > 0 ? rWeight : 0);

        if (rVolume > 0 || rWeight > 0) {
          this.outputDebug('We need to add more bins');
          this.chooseBin(rVolume, rWeight, true);
        }

        return {
          container: newContainer,
          qty: qtyContainer,
          rest: 0,
        };
        // eslint-disable-next-line no-else-return
      } else {
        const cIndex = arrQty.findIndex((nIndex) => nIndex === qtyContainer);
        // Force quantity
        qtyContainer = 1;
        this.outputDebug('Containers fit the best is', this.containersType[cIndex]);
        this.outputDebug(
          `For best proposal we need x${Math.floor(qtyContainer)} of "${
            this.containersType[cIndex].name
          }"`
        );

        const newContainer = this.containersType[cIndex];
        // abdou
        let ncVolume;
        if (newContainer.volumeVrac) {
          ncVolume = newContainer.volumeVrac;
        } else {
          ncVolume = newContainer.height * newContainer.width * newContainer.length;
        }
        // abdou
        const ncWeight = newContainer.weight;

        const rVolume = restVolume - ncVolume * qtyContainer;
        const rWeight = restWeight - ncWeight * qtyContainer;

        this.outputDebug('• Volume items', restVolume);
        this.outputDebug('• Weight items', restWeight);
        this.outputDebug(`Volume containers x${qtyContainer}`, ncVolume * qtyContainer);
        this.outputDebug(`Weight containers x${qtyContainer}`, ncWeight * qtyContainer);
        this.outputDebug('Do we have rest volume?', rVolume > 0 ? rVolume : 0);
        this.outputDebug('Do we have rest weight?', rWeight > 0 ? rWeight : 0);
      }
    }
  }

  chooseRightBins(volume, weight, ctnrs = null) {
    this.outputDebug('Fn::chooseRightBins - Entering');
    let tempBin = [];

    /**
     * •••••••••••••••••••••••••••••••••••••••••••••••••••
     * FINDING BEST FIT ORDER FOR CONTAINER
     *    bigger to smaller
     *      OR
     *    smaller to bigger
     * •••••••••••••••••••••••••••••••••••••••••••••••••••
     */
    // LOOKUP FOR A FUNCTION WHICH FIND BEST ORDER DEPENDING ON CONTAINER SPLIT
    // -> option 1 (actual) => x3 -> 60
    // OR
    // -> option 2 (TO DEVELOP) => x2 -> 60 + x1 -> 40 + x1 -> 20

    // Order by bigger to smaller container
    const tempContainers = (ctnrs === null ? this.containersType : ctnrs).sort((a, b) => {
      // abdou
      if (a.volumeVrac && b.volumeVrac) {
        return a.weight + a.volumeVrac - (b.weight + b.volumeVrac);
      }
      return a.weight + a.height * a.width * a.length - (b.weight + b.height * b.width * b.length);
      // fin abdou
    });

    this.outputDebug(`Fn::chooseRightBins - Containers`, tempContainers);

    const findI = (name) => tempContainers.findIndex((o) => o.name === name);

    let restVolume = volume;
    let restWeight = weight;

    // eslint-disable-next-line no-restricted-syntax
    for (const c of tempContainers) {
      if (restVolume > 0 && restWeight > 0) {
        this.outputDebug(`Fn::chooseRightBins - Lookup for ${c.name}`);
        this.outputDebug(`Fn::chooseRightBins - v/w ${restVolume}, ${restWeight}`);
        // abdou
        let cVolume;
        if (c.volumeVrac) {
          cVolume = c.volumeVrac;
        } else {
          cVolume = c.height * c.width * c.length;
        }
        // fin abdou

        const dividerVolume = Math.ceil(restVolume / cVolume);
        const dividerWeight = Math.ceil(restWeight / c.weight);
        if (cVolume >= restVolume && c.weight >= restWeight) {
          /**
           * Volume and weight are inferior
           */
          this.outputDebug(`Fn::chooseRightBins - Choosed A -> ${c.name}`);
          tempBin.push(c);
          restVolume -= cVolume;
          restWeight -= c.weight;

          if (restVolume > 0 || restWeight > 0) {
            // Cleanup current container for recursive loop
            const index = findI(c.name);
            delete tempContainers[index];
            let tempContainers = [...tempContainers];

            // Loop to find best
            tempBin = [...tempBin, ...this.chooseRightBins(restVolume, restWeight, tempContainers)];
          }
        } else if (dividerVolume >= 1 && dividerWeight >= 1) {
          /**
           * Volume and weight can be splitted into multiples
           */
          this.outputDebug(`Fn::chooseRightBins - Choosed B -> ${c.name}`);

          // Sort by highest value finding num containers we need
          const loopNums = [dividerVolume, dividerWeight].sort((a, b) => b - a);
          const biggerNum = loopNums[0];
          this.outputDebug(`Fn::chooseRightBins - Choosed B -> with ${biggerNum} containers`);
          // eslint-disable-next-line no-loop-func
          [...Array(biggerNum).keys()].forEach(() => {
            tempBin.push(c);
          });
          restVolume -= cVolume * biggerNum;
          restWeight -= c.weight * biggerNum;

          if (restVolume > 0 || restWeight > 0) {
            // Cleanup current container for recursive loop
            const index = findI(c.name);
            delete tempContainers[index];
            let tempContainers = [...tempContainers];

            // Loop to find best
            tempBin = [...tempBin, ...this.chooseRightBins(restVolume, restWeight, tempContainers)];
          }
        }
      }
    }
    this.outputDebug('Fn::chooseRightBins - Exiting');
    return tempBin;
  }

  parse() {
    if (this.items.length > 0) {
      this.setTotalsItems('volumeByItems', []);
      this.setTotalsItems('totalVolume', 0);
      this.setTotalsItems('weightByItems', []);
      this.setTotalsItems('totalWeight', 0);

      this.items.forEach((item, i) => {
        for (let qty = 0; qty < item.qty; qty += 1) {
          if (!this.weightByItems[item.name]) {
            this.weightByItems[item.name] = 0;
          }
          this.weightByItems[item.name] += item.weight;

          if (!this.volumeByItems[item.name]) {
            this.volumeByItems[item.name] = 0;
          }
          if (item.packVolume) {
            this.volumeByItems[item.name] += item.packVolume;
          } else {
            // abdou
            let volume;
            if (item.volumeVrac) {
              volume = item.volumeVrac;
            } else {
              volume = item.height * item.width * item.length;
            }
            this.volumeByItems[item.name] += volume;
            // fin abdou
          }
        }
      });
    }
  }

  makeSum(key = 'weight') {
    let keyValue;
    let keyObject;
    if (key === 'weight') {
      keyValue = 'totalWeight';
      keyObject = 'weightByItems';
    }
    if (key === 'volume') {
      keyValue = 'totalVolume';
      keyObject = 'volumeByItems';
    }

    this[keyValue] = Object.values(this[keyObject]).reduce((total, value) => total + Number(value));
  }

  packv1(sort = true) {
    this.outputDebug('Entering pack');

    // Default sorting our bins no matter if it's user choices or not
    if (sort) {
      this.sortBins();
    }
    this.getTotalsBins();

    this.outputDebug('Volume', this.totalVolume);
    this.outputDebug('Weight', this.totalWeight);

    if (this.totalBinsVolume > this.totalVolume && this.totalBinsWeight > this.totalWeight) {
      this.outputDebug('Fn::pack - Volume ok');
      this.outputDebug('Fn::pack - bins sorted', this.bins);

      if (this.optimization) {
        // •••••••••••• Search for best ••••••••••••
        this.chooseBin(this.totalVolume, this.totalWeight);
        // // 1. First thing first choose the right bins
        // const newBins = this.chooseRightBins(this.totalVolume, this.totalWeight);
        // console.log(newBins);

        // // 2. Reset bins with line above
        // this.bins = newBins;

        // // 3. Loop on itself pack() with optimization desactivated
        // this.pack(false);
      } else {
        // ••••• Feed items by looping on them •••••
        // Call a function (not created) to dispatch() items into bins
      }
    } else {
      this.outputDebug('Fn::pack - Total items is too heavy or too big');
      this.outputDebug(
        `Volume ${this.totalBinsVolume} > ${this.totalVolume}`,
        this.totalBinsVolume > this.totalVolume
      );
      this.outputDebug(
        `Weight ${this.totalBinsWeight} > ${this.totalWeight}`,
        this.totalBinsWeight > this.totalWeight
      );

      const numBins = this.calculateMissingBin();
      if (numBins > 0) {
        this.createMoreBins(numBins);
      }

      // If we add container then
      //  -> If optimization default sort
      //  -> else no sort let's user inital choice make his job
      //    (default sort from function top then add container withtout order)
      this.pack(this.optimization || false);
    }
  }

  sortItemsRatioVolumeWeight(items) {
    this.outputDebug('Fn::sortItemsRatioVolumeWeight');
    if (!Array.isArray(items)) {
      throw new Error('sortItems: items is not an array');
    }
    if (items.length === 1) {
      this.outputDebug('Fn::sortItemsRatioVolumeWeight with only one item');
      return items;
    }

    return items.sort((a, b) => {
      let weightA;
      let weightB;
      if (a.packVolume && b.packVolume) {
        weightA = a.packVolume / a.weight;
        weightB = b.packVolume / b.weight;
      } else {
        // abdou volumeVrac
        let volumeA;
        let volumeB;
        if (a.volumeVrac && b.volumeVrac) {
          volumeA = a.volumeVrac;
          volumeB = b.volumeVrac;
        } else {
          volumeA = a.height * a.width * a.length;
          volumeB = b.height * b.width * b.length;
        }
        weightA = volumeA / a.weight;
        weightB = volumeB / b.weight;
        // fin abdou
      }
      return weightB - weightA;
    });
  }

  dispatchItemsIntoBins() {
    this.bins.forEach((bin) => {
      bin.setItems([]);
    });

    this.itemsSorted = this.sortItemsRatioVolumeWeight(this.getItems());
    const containerItems = [];
    const tempSortedItems = cloneDeep(this.itemsSorted);
    if (!this.optimization) {
      const storeBins = cloneDeep(this.bins);
      this.outputDebug('Fn::dispatchItemsIntoBins without optimization');

      this.outputDebug(
        'Fn::dispatchItemsIntoBin Items',
        tempSortedItems,
        'to pack in ',
        this.bins,
        'bins'
      );

      while (tempSortedItems.length > 0) {
        const firstItem = first(tempSortedItems);
        const lastItem = last(tempSortedItems);

        // const indexFirst = findIndex(tempSortedItems, { name: firstItem.name });
        // const indexLast = findIndex(tempSortedItems, { name: lastItem.name });

        // this.outputDebug(
        //   'Fn::dispatchItemsIntoBin indexFirst and indexLast from tempSortedItems',
        //   indexFirst,
        //   indexLast
        // );
        let loopItems = [firstItem, lastItem];
        if (tempSortedItems.length === 1) {
          loopItems = [firstItem];
        }
        if (loopItems.length > 0) {
          // eslint-disable-next-line no-loop-func
          loopItems.forEach((item, i) => {
            const itemDuplicate = cloneDeep(item);
            const clone = cloneDeep(item);
            // console.log('item.qty => ',item.qty );
            if (itemDuplicate.qty > 0) {
              while (itemDuplicate.qty > 0 && storeBins.length > 0) {
                // eslint-disable-next-line no-loop-func, no-plusplus
                for (let j = 0; j < storeBins.length; j++) {
                  const bin = this.bins[j];
                  if (itemDuplicate.qty > 0) {
                    const itemVolume =
                      itemDuplicate.packVolume !== undefined
                        ? itemDuplicate.packVolume
                        : itemDuplicate.height * itemDuplicate.width * itemDuplicate.length;
                    if (
                      bin.getItemsVolume() + itemVolume <= bin.getMaxVolume() &&
                      bin.getItemsWeight() + itemDuplicate.weight <= bin.getMaxWeight()
                    ) {
                      if (!containerItems[j]) {
                        containerItems[j] = [];
                      }
                      // console.log(container.getId(), container.getName(), '----', container.getItemsVolume(), container.getMaxVolume());
                      // container.getItemsWeight(), container.getMaxWeight());
                      // console.log(container.hasCapacityVolume(), container.hasCapacityWeight());
                      // console.log('getPercentVolumeOccupancy', container.getPercentVolumeOccupancy())
                      clone.qty = 1;
                      const index = findIndex(containerItems[j], { id: clone.id });
                      if (index >= 0) {
                        const temp = cloneDeep(containerItems[j][index]);
                        temp.qty += 1;
                        containerItems[j][index] = temp;
                        itemDuplicate.qty -= 1;
                      } else {
                        containerItems[j].push(clone);
                        itemDuplicate.qty -= 1;
                      }
                      bin.setItems(containerItems[j]);
                    } else {
                      this.outputDebug('Fn::dispatchItemsIntoBin - Bin is full');
                      // delete storeBins[j];
                      // eslint-disable-next-line no-continue
                      continue;
                      // storeBins = compact(storeBins);
                    }
                  }
                  //  if (item.qty === 0) {
                  //    // console.log('\n', item.name, 'cleared');
                  //  }
                }
              }
            }
          });
        }
        // Remove first & last element
        tempSortedItems.shift();
        tempSortedItems.pop();
      }
    } else {
      this.outputDebug('dispatchItemsIntoBins with optimization');
      const containerItems = [];
      let currentBinIndex = 0;

      while (tempSortedItems.length > 0) {
        const firstItem = first(tempSortedItems);
        const lastItem = last(tempSortedItems);

        let loopItems = [firstItem, lastItem];
        if (tempSortedItems.length === 1) {
          loopItems = [firstItem];
        }

        // eslint-disable-next-line no-loop-func
        loopItems.forEach((item) => {
          const itemDuplicate = cloneDeep(item);
          const clone = cloneDeep(item);

          while (itemDuplicate.qty > 0 && currentBinIndex < this.bins.length) {
            // console.log('currentBinIndex', currentBinIndex);

            const bin = this.bins[currentBinIndex];

            if (bin.hasHeightCapacityFor(clone) && bin.hasVolumeCapacityFor(clone)) {
              if (!containerItems[currentBinIndex]) {
                containerItems[currentBinIndex] = [];
              }

              clone.qty = 1;
              const index = findIndex(containerItems[currentBinIndex], { name: clone.name });
              if (index >= 0) {
                const temp = cloneDeep(containerItems[currentBinIndex][index]);
                temp.qty += 1;
                containerItems[currentBinIndex][index] = temp;
              } else {
                containerItems[currentBinIndex].push(clone);
              }
              bin.setItems(containerItems[currentBinIndex]);
              itemDuplicate.qty -= 1;
            } else {
              currentBinIndex += 1;
            }
          }
        });

        // Remove first & last element
        if (tempSortedItems.length > 1) {
          tempSortedItems.pop();
        }
        tempSortedItems.shift();
      }
    }
    this.bins.forEach((bin) => {
      this.outputDebug(' items in bins after fn::dispatchItemsIntoBins', bin.getItems());
    });

    // this.outputDebug('• bins after packing', JSON.stringify(this.bins, null, 2));
  }

  pack(sort = true) {
    this.outputDebug('                                       ');
    this.outputDebug('••••••••••••••• Packing •••••••••••••••');

    // Default sorting our bins no matter if it's user choices or not
    if (sort) {
      this.sortBins();
    }
    this.getTotalsBins();
    let binsSizing = false;
    if (this.bins.length > 0) {
      this.outputDebug('• Bins exists');
      this.outputDebug(
        `• Volume ${this.totalBinsVolume} > ${this.totalVolume}`,
        this.totalBinsVolume > this.totalVolume
      );
      this.outputDebug(
        `• Weight ${this.totalBinsWeight} > ${this.totalWeight}`,
        this.totalBinsWeight > this.totalWeight
      );

      if (this.totalBinsVolume > this.totalVolume && this.totalBinsWeight > this.totalWeight) {
        this.outputDebug('• Volume ok');
        // this.outputDebug('• bins sorted', this.bins);
        // console.log('optimisation', this.optimization);

        if (this.optimization) {
          // •••••••••••• Search for best ••••••••••••
          this.chooseBin(this.totalVolume, this.totalWeight);
          // 1. First thing first choose the right bins
          const newBins = this.chooseRightBins(this.totalVolume, this.totalWeight);
          const bins = [];
          if (newBins.length > 0) {
            newBins.forEach((bin) => {
              // 3. Create new bins
              bins.push(
                new Bin(
                  bin.name,
                  bin.width,
                  bin.height,
                  bin.length,
                  bin.weight,
                  bin.volumeVrac,
                  bin.type,
                  bin.mode,
                  false
                )
              );
            });
          }
          this.outputDebug(this.bins);

          this.setBins(bins);

          this.dispatchItemsIntoBins();
        } else {
          this.dispatchItemsIntoBins();
        }
      } else {
        binsSizing = true;
      }
    } else {
      binsSizing = true;
    }

    if (binsSizing) {
      this.outputDebug('• Total items is too heavy or too big');
      this.outputDebug(
        `• Volume ${this.totalBinsVolume} > ${this.totalVolume}`,
        this.totalBinsVolume > this.totalVolume
      );
      this.outputDebug(
        `• Weight ${this.totalBinsWeight} > ${this.totalWeight}`,
        this.totalBinsWeight > this.totalWeight
      );

      let i = 1;
      let numBins = this.calculateMissingBin();
      while (numBins > 0) {
        // Update total
        this.getTotalsBins();

        this.outputDebug(
          `• Volume ${this.totalBinsVolume} > ${this.totalVolume}`,
          this.totalBinsVolume > this.totalVolume
        );
        this.outputDebug(
          `• Weight ${this.totalBinsWeight} > ${this.totalWeight}`,
          this.totalBinsWeight > this.totalWeight
        );

        numBins = this.calculateMissingBin();

        if (numBins > 0) {
          this.outputDebug(`• ${i === 1 ? 'Creating' : 'Adding'} ${numBins} bin(s)`);

          // Create bins
          this.createMoreBins(numBins);
        }
        i += 1;

        // If we add container then
        //  -> If optimization default sort
        //  -> else no sort let's user inital choice make his job
        //    (default sort from function top then add container withtout order)
        this.pack(this.optimization || false);
      }
    }
  }
}

export default Packer;
