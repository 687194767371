import React from 'react';

import Layout from 'layouts/Layout';
import CommercialCartList from './CommercialCartList';

const CommercialCartListLayout = ({ match }) => (
  <Layout component={CommercialCartList} match={match} />
);

export default CommercialCartListLayout;
