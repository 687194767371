/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import frLocale from 'moment/locale/fr';
import { getValueOfAttribut, getPath } from 'helpers';
import { modalActions } from 'actions';
import { platformConstants } from 'pages/../constants';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { ButtonPrimary } from 'components/Core/Button/Button';
import WithSettingsPreorderStyle from './WithSettingsPreorderStyle';

const { PREORDER_DELAY_KEY, DELIVERY_DELAY_KEY, PREORDER_DATE_BEGIN_KEY } = platformConstants;

class SettingsPreorder extends React.Component {
  state = {
    preorderDelay: '',
    deliveryDelay: '',
    preorderDateBegin: '',
  };

  componentDidMount() {
    const { platform } = this.props;
    if (platform.id) {
      const { attributs } = platform;
      const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
      const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
      const preorderDateBegin = Moment(
        getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY) || undefined
      ).format('YYYY-MM-DD HH:mm:00');
      this.setState({
        preorderDelay,
        deliveryDelay,
        preorderDateBegin,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { platform } = this.props;
    const { platform: prevPlatform } = prevProps;
    if (platform.id && platform.id !== prevPlatform.id) {
      const { attributs } = platform;
      const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
      const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
      const preorderDateBegin = Moment(
        getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY) || undefined
      ).format('YYYY-MM-DD HH:mm:00');
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        preorderDelay,
        deliveryDelay,
        preorderDateBegin,
      });
    }
  }

  resetState = () => {
    const { platform } = this.props;
    const { attributs } = platform;
    const preorderDelay = Number(getValueOfAttribut(attributs || [], PREORDER_DELAY_KEY));
    const deliveryDelay = Number(getValueOfAttribut(attributs || [], DELIVERY_DELAY_KEY));
    const preorderDateBegin = Moment(
      getValueOfAttribut(attributs || [], PREORDER_DATE_BEGIN_KEY) || undefined
    ).format('YYYY-MM-DD HH:mm:00');
    this.setState({
      preorderDelay,
      deliveryDelay,
      preorderDateBegin,
    });
  };

  handleDelayChange = (e) => {
    const value = Number(e.target.value);
    this.setState({
      [e.target.name]: Number(parseInt(value, 10)) || 0,
    });
  };

  handleDateChange = (date) => {
    this.setState(() => {
      if (!date) {
        return {
          preorderDateBegin: '',
        };
      }
      const newPreorderDateBegin = Moment(date).set({
        year: Moment(date).year(),
        month: Moment(date).month(),
        date: Moment(date).date(),
      });
      return {
        preorderDateBegin: newPreorderDateBegin.format('YYYY-MM-DD HH:mm:00'),
      };
    });
  };

  handleTimeChange = (date) => {
    this.setState(() => {
      if (!date) {
        return {
          preorderDateBegin: '',
        };
      }
      const newPreorderDateBegin = Moment(date).set({
        hour: Moment(date).hour(),
        minute: Moment(date).minute(),
      });
      return {
        preorderDateBegin: newPreorderDateBegin.format('YYYY-MM-DD HH:mm:00'),
      };
    });
  };

  handelsubmit = () => {
    const { openModal } = this.props;
    const { deliveryDelay, preorderDelay, preorderDateBegin } = this.state;
    if (Number(deliveryDelay) && Number(preorderDelay) && preorderDateBegin) {
      openModal({ ...this.state }, this.resetState);
    }
  };

  handelExtractExcel = () => {
    const { openExtractExcel } = this.props;
    openExtractExcel();
  };

  render() {
    const { className } = this.props;
    const url = [
      {
        text: 'Accueil ',
        href: getPath('dashboardAdmin'),
      },
    ];
    const currentPage = 'Paramètres précommande';

    const ongletList = [
      {
        text: 'Mes commerciaux',
        href: getPath('commercial'),
      },
      {
        text: 'Plannings de livraison',
        href: getPath('planning'),
      },
      {
        text: 'Jours fériés & fermeture',
        href: getPath('dayoff'),
      },
      {
        text: 'CGV appliquées',
        href: getPath('terms'),
      },
      {
        text: 'Paramètres précommande',
        href: getPath('settingsPreorder'),
        status: 'active',
      },
      {
        text: 'Messagerie client',
        href: getPath('message'),
      },
      {
        text: 'Extract XLS preco',
        handle: this.handelExtractExcel,
      },
    ];

    const { preorderDelay, deliveryDelay, preorderDateBegin } = this.state;
    const actualPreorderDate = Moment(preorderDateBegin);
    if (preorderDelay) {
      // We clone the actualPreorderDate in condition to not add twice
      while (Moment(actualPreorderDate).add(preorderDelay, 'days').isBefore(Moment(), 'day')) {
        actualPreorderDate.add(preorderDelay, 'days');
      }
    }

    const arrayPreorderDates = [
      {
        startPreorder: Moment(actualPreorderDate),
        endPreorder: Moment(actualPreorderDate).add(preorderDelay, 'days'),
        delivery: Moment(actualPreorderDate).add(preorderDelay + deliveryDelay, 'days'),
      },
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 4; i++) {
      arrayPreorderDates.push({
        startPreorder: Moment(arrayPreorderDates[i - 1].endPreorder),
        endPreorder: Moment(arrayPreorderDates[i - 1].endPreorder).add(preorderDelay, 'days'),
        delivery: Moment(arrayPreorderDates[i - 1].endPreorder).add(
          preorderDelay + deliveryDelay,
          'days'
        ),
      });
    }
    return (
      <div className={className}>
        <div className="page-settings">
          <div className="page-settings__heading">
            <BreadCrumb urlTab={url} currentPage={currentPage} />
            <OngletMenu list={ongletList} />
          </div>
          <div className="current_planning">
            <h3>Planning actuel</h3>
            <table>
              <thead>
                <tr>
                  <th>Début de précommande</th>
                  <th>Fin de précommande</th>
                  <th>Livraison</th>
                </tr>
              </thead>
              <tbody>
                {arrayPreorderDates.map((row, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td>{row.startPreorder.format('DD.MM.Y')}</td>
                    <td>{row.endPreorder.format('DD.MM.Y')}</td>
                    <td>{row.delivery.format('DD.MM.Y')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="edit__preorder">
            <h3>Modifier les délais de précommande et de livraison</h3>
            <div className="edit__preoder--bloc details_preorder">
              <div className="entete">
                <h3>Délais de précommande</h3>
              </div>
              <div className="content--bloc">
                <div className="ligne-bloc">
                  <span>Délais :</span>
                  <input
                    id="number"
                    type="number"
                    name="preorderDelay"
                    value={preorderDelay}
                    onChange={this.handleDelayChange}
                  />
                  <span>Jour (s)</span>
                </div>
                <div className="ligne-bloc">
                  <h4>À partir du</h4>
                  <div className="preorder-delay-input">
                    <div className="preorder-delay-date">
                      <DatePicker
                        selected={preorderDateBegin ? Moment(preorderDateBegin).toDate() : ''}
                        onChange={(date) => this.handleDateChange(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="*"
                        locale="fr"
                      />
                      <i className="icon-calendar" />
                    </div>
                    <span>À</span>
                    <div className="preorder-delay-time">
                      <DatePicker
                        selected={preorderDateBegin ? Moment(preorderDateBegin).toDate() : ''}
                        onChange={(date) => this.handleTimeChange(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        timeCaption=""
                        placeholderText="*"
                        locale="fr"
                      />
                      <i className="icon-time" />
                    </div>
                  </div>
                </div>
                <h4>Soit fin de commande le </h4>
                <span className="content-date">
                  {preorderDateBegin &&
                    Moment(preorderDateBegin)
                      .locale('fr', frLocale)
                      .add(preorderDelay, 'days')
                      .format('dddd DD/MM/YYYY à HH[H]mm')}
                </span>
              </div>
            </div>
            <div className="edit__preoder--bloc details_livraison">
              <div className="entete">
                <h3>Délais de livraison</h3>
              </div>
              <div className="content--bloc">
                <div className="ligne-bloc">
                  <span>Délais :</span>
                  <input
                    id="number"
                    type="number"
                    name="deliveryDelay"
                    value={deliveryDelay}
                    onChange={this.handleDelayChange}
                  />
                  <span>Jour (s) à partir de la fin de commande </span>
                </div>
                <h4>Soit livraison indicative le </h4>
                <span className="content-date">
                  {preorderDateBegin &&
                    Moment(preorderDateBegin)
                      .locale('fr', frLocale)
                      .add(preorderDelay + deliveryDelay, 'days')
                      .format('dddd DD/MM/YYYY')}
                </span>
              </div>
            </div>
          </div>
          <div className="page-profil__alert-bloc">
            {(!Number(preorderDelay) || !preorderDateBegin) && (
              <span className="alert-bloc__label">Délais de précommande mal paramétré.</span>
            )}
            {!Number(deliveryDelay) && (
              <span className="alert-bloc__label">Délais de livraison mal paramétré.</span>
            )}
          </div>
          <ButtonPrimary
            className="newplanning"
            onClick={() => this.handelsubmit()}
            disabled={!Number(deliveryDelay) || !Number(preorderDelay) || !preorderDateBegin}
          >
            <span>Voir le nouveau planning</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { platform } = state;
  const selectedPlatform = platform
    ? platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {}
    : {};
  return {
    platform: selectedPlatform,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (newParameters, resetState) => {
    dispatch(
      modalActions.open('newPreorderParameters', {
        modalHeader: 'Vous êtes en train de modifier les délais du planning actuel',
        newParameters,
        resetState,
        width: '761',
      })
    );
  },
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});

SettingsPreorder.propTypes = {
  className: PropTypes.string,
  platform: PropTypes.object,
  openModal: PropTypes.func,
  openExtractExcel: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithSettingsPreorderStyle(SettingsPreorder));
