import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalContainersOptimal.style';

const handleClick = (closeModal) => {
  closeModal();
};

const ModalContainersOptimal = ({ className, closeModal }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block">
        <span>Voici la configuration optimale des conteneurs pour votre commande :</span>
      </div>
      <div className="modal-body__configuration-block">
        <table>
          <tr>
            <th>SEC</th>
            <th>Frais</th>
            <th>Froid</th>
          </tr>
          <tr>
            <td>1x20 Pieds DRY</td>
            <td>1x40 Pieds</td>
            <td />
          </tr>
          <tr>
            <td>1x20 Pieds DRY</td>
            <td />
            <td />
          </tr>
        </table>
      </div>
      <div className="modal-body__button-block">
        <ButtonPrimary onClick={() => handleClick(closeModal)}>
          Adopter la configuration optimale
        </ButtonPrimary>
        <ButtonPrimary inverted onClick={() => handleClick(closeModal)}>
          Conserver la configuration actuelle
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalContainersOptimal.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});
export default connect(null, mapDispatchToProps)(withStyle(ModalContainersOptimal));
