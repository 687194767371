/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';

class DeliveryMode extends React.Component {
  setClientDeliveryMode = (value) => {
    const { updateState, clientDeliveryModes, selectedPlatform, clientId } = this.props;
    if (clientDeliveryModes) {
      const clientDeliveryMode = clientDeliveryModes.find(
        (deliveryMode) => Number(deliveryMode.platform.id) === Number(selectedPlatform)
      );
      const otherDeliveryModes = clientDeliveryMode
        ? clientDeliveryModes.filter(
            (deliveryMode) =>
              deliveryMode.id && Number(deliveryMode.id) !== Number(clientDeliveryMode.id)
          )
        : [...clientDeliveryModes];
      const finalDeliveryModes = clientDeliveryMode
        ? [
            ...otherDeliveryModes,
            { id: clientDeliveryMode.id, delivery_mode: value, platform: { id: selectedPlatform } },
          ]
        : [
            ...otherDeliveryModes,
            { platform: { id: selectedPlatform }, client: { id: clientId }, delivery_mode: value },
          ];
      updateState('client_delivery_modes', finalDeliveryModes);
    }
  };

  render() {
    const { clientDeliveryModes, selectedPlatform } = this.props;
    const clientDeliveryMode = (clientDeliveryModes || []).find(
      (deliveryMode) => Number(deliveryMode.platform.id) === Number(selectedPlatform)
    );

    return (
      <div className="page-profil__block-info">
        <div className="block-info__entete">
          <Icon className="entete__icon retrait" content="\e956" />
          <span className="entete__label">Choix prestation</span>
        </div>
        <div className="block-info__content livraison">
          <div className="block-info__content-radio block__radio ">
            <label
              onClick={(e) => {
                e.preventDefault();
                this.setClientDeliveryMode(1);
              }}
            >
              <input
                type="radio"
                checked={clientDeliveryMode && clientDeliveryMode.delivery_mode}
              />
              <span>Préparation</span>
              <span className="check" />
            </label>
          </div>
          <div className="block-info__content-radio block__radio ">
            <label
              onClick={(e) => {
                e.preventDefault();
                this.setClientDeliveryMode(0);
              }}
            >
              <input
                type="radio"
                checked={!clientDeliveryMode || !clientDeliveryMode.delivery_mode}
              />
              <span>Préparation + livraison</span>
              <span className="check" />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { platform } = state;
  return {
    selectedPlatform: Number(platform.selectedId),
  };
};

DeliveryMode.propTypes = {
  clientId: PropTypes.number,
  clientDeliveryModes: PropTypes.array,
  updateState: PropTypes.func,
  selectedPlatform: PropTypes.number,
};

export default connect(mapStateToProps)(DeliveryMode);
