import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPencilAlt } from '@react-icons/all-files/fa/FaPencilAlt';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { getPath } from 'helpers';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { commercialActions, modalActions } from 'actions';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { selectCommercialByFilter, selectCommercialCountByFilter } from 'selectors/commercial';
import WithCommercialStyles from './WithCommercialStyles';

const filter = {};
const limit = 25;

const Commercial = ({
  className,
  fetchCommercialList,
  fetchCommercialTotalRowCount,
  commercialList,
  commercialCount,
  openAddCommercialModal,
  openUpdateCommercialModal,
  openExtractExcel,
}) => {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [commercialTotalRowCount, setCommercialTotalRowCount] = useState(null);

  useEffect(() => {
    fetchCommercialTotalRowCount(filter);
    fetchCommercialList(filter, offset, limit);
  }, []);

  useEffect(() => {
    if (commercialList && !commercialList.isLoading) {
      setData(commercialList.items.flat());
    }
  }, [commercialList]);

  useEffect(() => {
    if (commercialCount) {
      if (commercialCount && !commercialCount.isLoading) {
        setCommercialTotalRowCount(commercialCount.totalRowsNumber);
      }
    }
  }, [commercialCount]);

  const url = [
    {
      text: 'Accueil ',
      href: getPath('dashboardAdmin'),
    },
    {
      text: 'Paramètres',
    },
  ];
  const currentPage = 'Mes commerciaux';

  const ongletList = [
    {
      text: 'Mes commerciaux',
      href: getPath('commercial'),
      status: 'active',
    },
    {
      text: 'Plannings de livraison',
      href: getPath('planning'),
    },
    {
      text: 'Jours fériés & fermeture',
      href: getPath('dayoff'),
    },
    {
      text: 'CGV appliquées',
      href: getPath('terms'),
    },
    {
      text: 'Paramètres précommande',
      href: getPath('settingsPreorder'),
    },
    {
      text: 'Messagerie client',
      href: getPath('message'),
    },
    {
      text: 'Extract XLS preco',
      handle: openExtractExcel,
    },
  ];

  const columns = React.useMemo(() => [
    {
      id: 'name',
      Header: 'Nom',
      accessor: (item) => item,
      Cell: ({ value }) => (
        <>
          {value.id} <br />
          {value.firstname || ''} {value.lastname}
        </>
      ),
    },
    {
      id: 'mobile',
      Header: 'Mobile',
      accessor: (item) => item.tel_number2 || '-',
    },
    {
      id: 'fixe',
      Header: 'Fixe',
      accessor: (item) => item.tel_number1 || '-',
    },
    {
      id: 'fax',
      Header: 'Fax',
      accessor: (item) => item.fax || '-',
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: (item) => item.email || '-',
    },
    {
      id: 'actions',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <span
          onClick={() => {
            openUpdateCommercialModal(value);
          }}
          className="edit"
        >
          <FaPencilAlt /> Editer
        </span>
      ),
      className: 'actions',
    },
  ]);

  return (
    <div className={className}>
      <div className="page-commercial">
        <div className="page-commercial__heading">
          <BreadCrumb urlTab={url} currentPage={currentPage} />
          <OngletMenu list={ongletList} />
          <div className="page-commercial__section-label">
            <ButtonPrimary onClick={openAddCommercialModal}>Créer un commercial</ButtonPrimary>
          </div>
        </div>
        <ReactTable
          columns={columns}
          data={data}
          isLoading={commercialList && commercialList.isLoading}
          next={() => {
            fetchCommercialList(filter, offset + 1, limit);
            setOffset(offset + 1);
          }}
          totalRowCount={commercialTotalRowCount}
          loadedRowCount={data.length}
        />
      </div>
    </div>
  );
};

Commercial.propTypes = {
  className: PropTypes.string,
  openExtractExcel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  commercialList: selectCommercialByFilter(state, filter),
  commercialCount: selectCommercialCountByFilter(state, filter),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommercialList: (filter, offset, limit) =>
    dispatch(commercialActions.fetchCommercials(filter, offset, limit)),
  fetchCommercialTotalRowCount: (filter) =>
    dispatch(commercialActions.fetchTotalRowsNumber(filter)),
  openAddCommercialModal: () =>
    dispatch(
      modalActions.open('addCommercial', {
        modalHeader: 'Ajouter commercial',
        width: '400',
      })
    ),
  openUpdateCommercialModal: (commercial) =>
    dispatch(
      modalActions.open('updateCommercial', {
        modalHeader: 'Fiche Commercial',
        commercial,
        width: '400',
      })
    ),
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithCommercialStyles(Commercial));
