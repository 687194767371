import { authHeader, handleResponse } from 'helpers';
import { getFormatedDate } from 'helpers/Utils';
import { catalogConstants } from '../constants';
import { config } from './Config';

export const recurrentCartService = {
  fetchRecurrentCartList,
  createRecurentCart,
  deleteRecurrentCart,
  updateRecurrentCart,
};

function fetchRecurrentCartList(offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  const filter = JSON.stringify({
    status: true,
    platform: localStorage.getItem('platform'),
  });

  return fetch(
    `${config.apiUrl}/api/cartrecurring?filter=${filter}&offset=${offset}&limit=${limit}&orderByDesc=dateCreated`,
    requestOptions
  ).then(handleResponse);
}

function createRecurentCart(data) {
  const jsonBody = JSON.stringify(data);

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body: jsonBody,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cartrecurring/add`, requestOptions).then(handleResponse);
}

function deleteRecurrentCart(cartId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/cartrecurring/delete/${cartId}`, requestOptions).then(
    handleResponse
  );
}

function updateRecurrentCart(data) {
  const body = JSON.stringify(data);
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  const url = `${config.apiUrl}/api/cartrecurring/update`;
  return fetch(url, requestOptions).then(handleResponse);
}
