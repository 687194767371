import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withModalErrorStyle from './ModalErrorCart.style';

const ModalErrorCart = ({ className, text, button, closeModalErrorCart }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>{text}</span>
      </div>
      {button && (
        <div className="modal-body__button-block">
          <ButtonPrimary onClick={() => closeModalErrorCart()}>Ok</ButtonPrimary>
        </div>
      )}
    </div>
  </div>
);

ModalErrorCart.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.bool,
  closeModalErrorCart: PropTypes.func,
};

export default withModalErrorStyle(ModalErrorCart);
