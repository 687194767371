import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectCatalogCollectionCountByFilter = (state, filter) => {
  const collections = get(state, 'catalog.collectionsCount', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectCurrentPageContext = (state) => get(state, 'pageContext.page');
