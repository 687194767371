import React from 'react';
import PropTypes from 'prop-types';
import withStyle from './withCreditWordingStyle';

const CreditWording = ({ credit, className }) => (
  <div className={className}>
    <div className="credit-content">
      <div className="credit-quantity-reason">
        <div className="credit-quantity">
          <span className="credit-title">Quantité :</span>
          <span className="credit-quantity-content">{credit.quantity}</span>
        </div>
        <div className="credit-reason">
          <span className="credit-title">Raison :</span>
          <span className="credit-reason-content">{credit.reason}</span>
        </div>
      </div>
      <div className="credit-comment">
        <span className="credit-title">Commentaire :</span>
        <div className="credit-comment-content">{credit.comment}</div>
      </div>
    </div>
  </div>
);

CreditWording.propTypes = {
  credit: PropTypes.object,
  className: PropTypes.string,
};

export default withStyle(CreditWording);
