const convertMm3VolumeToM3 = (v) => Number(v) / 1e9; // 1e9 = 1 * 10^9 OR 10 ** -9

const convertCm3VolumeToM3 = (v) => Number(v) / 1e6; // 1e9 = 1 * 10^9 OR 10 ** -6

const convertM3VolumeToCm3 = (v) => Number(v) * 1e6; // 1e9 = 1 * 10^9 OR 10 ** -6

const percentage = (total, partial) => Math.round((partial / total) * 100);

const percentageRest = (total, partial) => 100 - percentage(total, partial);

const float10Digits = (n) => parseFloat(n).toFixed(10);

const float2Digits = (n) => parseFloat(n).toFixed(2);

const millimeterToCentimeter = (n) => n / 10;

const gramToKilogram = (n) => n / 10;

export {
  convertMm3VolumeToM3,
  convertCm3VolumeToM3,
  convertM3VolumeToCm3,
  percentage,
  percentageRest,
  float2Digits,
  millimeterToCentimeter,
  gramToKilogram,
};
