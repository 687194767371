import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';
import { ButtonPrimary } from 'components/Core/Button/Button';

export const ButtonP = styled(ButtonPrimary)`
  border-radius: 20px;
  padding: 4px 20px;

  .header__button-title {
    padding-left: 9px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    .header__button-title-small {
      line-height: 13px;
      font-size: 10px;
    }
    .header__button-title-amount {
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

export const HeaderStyle = styled.div`
  // General Section
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  a {
    margin-right: 20px;
  }

  .header-container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .header-container__menu {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    height: 40px;
    padding-right: 20px;
    cursor: pointer;
  }

  .hr {
    height: 36px;
    width: 1px;
    border: 0;
    background: rgba(1, 54, 107, 0.2);
    margin: 0;
  }

  // Select Company Section

  .header-soc {
    padding-left: 20px;
    margin-top: -5px;
    font-weight: bold;
  }

  .header-soc--select {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .header-soc--select > select {
    position: relative;
    z-index: 2;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    color: #65676b;
    padding-right: 20px;
    appearance: none;
    background: none;
  }

  .header-soc--select > svg {
    height: 18px;
    width: 18px;
    position: relative;
    fill: #01366b;
    right: 20px;
    z-index: 0;
  }

  .header-soc--select > select:focus {
    outline: none;
  }

  // Search Section

  .header--search-group {
    width: 100%;
    padding-left: 23px;
    display: flex;
    position: relative;
  }

  .header--search-icon {
    color: #01366b;
    text-decoration: none;
    margin-right: 15px;
    cursor: pointer;
    i {
      font-size: 16px;
    }
  }

  .header--search-input {
    width: 92%;
    display: inline-block;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
  }

  .header--search-input > input {
    width: 100%;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 19px;
    border: 0 !important;
  }

  .header--search-input > input:focus {
    outline: none;
  }

  .hr-profil {
    display: none;
  }

  .preheader_logout_wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    text-align: center;
    margin-right: 5px;
  }

  .preheader_logout-container {
    display: flex;
    align-items: center;
  }

  .preheader_logout {
    color: #ff0066;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0.15px;
    display: inline-block;
  }

  .button-circular__icon {
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    height: 40px;
    width: 40px;
    background-color: #ff0066;
    border-radius: 40px;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
  }

  .icon-logout-preheader:before {
    color: #f5f5f5;
    content: '\\e92f';
  }

  // fix IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .header--search-input {
      margin-left: 20px;
    }
    .header-soc--select {
      margin-right: calc(100px + 30%);
      select::-ms-expand {
        display: none;
      }
    }
  }
  @media ${screen.tabletR} {
    .header-container__menu {
      position: initial;
      top: 0;
      left: 0;
      transform: translate(0);
    }
  }

  @media ${screen.tablet} {
    width: 100%;
    .header--search-input > input {
      width: 85%;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 19px;
      border: none;
    }
    .header--search-group {
      width: 100vw;
      padding-left: 0;
      position: static;
    }
  }

  @media ${screen.mobileL} {
    height: 70px;
    .header--search-icon {
      padding-top: 5px;
      padding-left: 4px;
      margin-right: 0;
    }
    .header--search-group {
      padding-left: 20px;
      align-items: center;
      .header--search-input {
        width: 80%;
        margin-left: 10px;
        input {
          width: 0;
          transition: width 0.55s ease;
          font-size: 14px;
        }
        input.responsive {
          width: 100%;
        }
      }
    }
    .hidden {
      display: none;
    }
    .header--search-group.responsive {
      padding-left: 0px;
    }
    .header--search-icon.responsive {
      border-right: 1px solid rgba(1, 54, 107, 0.2);
      padding: calc((70px - 23px) / 2);
      margin-left: -20px;
    }
  }

  @media ${screen.mobile} {
    // padding: 8px 15px 3px 26px;
    height: 70px;
    .logo {
      height: 30px;
    }
    .header--search-icon {
      padding-top: 5px;
      padding-left: 4px;
      margin-right: 0;
    }
    .header--search-group {
      padding-left: 20px;
      .header--search-input {
        width: 0;
        margin-left: 10px;
        &.responsive {
          width: calc(100% - 70px - 10px); // 70px for the square like button in resp. 10 as padding
        }
        input {
          width: 0;
          transition: width 0.55s ease;
          font-size: 14px;
        }
        input.responsive {
          width: 100%;
        }
      }
    }
    .hidden {
      display: none;
    }
    .header--search-group.responsive {
      padding-left: 0px;
    }
    .header--search-icon.responsive {
      border-right: 1px solid rgba(1, 54, 107, 0.2);
      padding: calc((70px - 16px) / 2);
      margin-left: -20px;
    }
    .header-soc--select {
      & > select {
        font-size: 14px;
      }
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  height: 100%;
  padding: 0 10px;
  margin: 0 10px;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-weight: bold;
  border-color: ${(props) => (props.active ? '#01366B' : 'none')};
  color: ${(props) => (props.active ? '#01366B' : 'none')};
  &:hover {
    color: #01366B;
  }
  i {
    font-size: 22px
    margin-right: 10px;
  }
  svg {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    margin-right: 10px;
  }
  span {
    text-align: center;
  }

  @media ${screen.tabletR} {
    margin: 0;
    padding: 0 5px;
  }
`;

export const SubMenuItem = styled.li`
  height: 42px;
  min-width: 200px;
  border-left: 4px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4a4a4a;

  border-color: ${(props) => (props.active ? 'rgb(15, 163, 240)' : 'none')};
  background-color: ${(props) => (props.active ? 'rgb(245, 245, 245)' : '#FFF')};

  .submenu-item__name {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  & > *:first-child {
    padding-left: 15px;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #4a4a4a;
    text-decoration: none;
  }

  .submenu-item__name + i {
    font-size: 14px !important;
    margin: 0 10px;
    margin-left: auto;
  }
  &:hover {
    border-color: rgb(15, 163, 240);
    background-color: rgb(245, 245, 245);
  }
  .show-all {
    font-weight: bold;
  }
`;
