import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { SubMenuItem } from 'components/Core/Utils/Navigation/Header/Header.style';
import { CategoryColumnWrapper } from 'components/CatalogControls/ProductCategoryControl.style';
import { catalogActions } from 'actions';
import './CatalogControls.scss';
import { selectCurrentPlatformId } from 'selectors/platform';
import { categoryConstants } from 'constants/Category.constants';
import { v4 as uuid } from 'uuid';

class ProductCategoryMenuColumn extends Component {
  isSelection(itemStatus) {
    return itemStatus === categoryConstants.STATUS_SELECTION;
  }

  hasChildren(item) {
    // return !!item.categories || item.children.length > 0;
    return !!item.categories || Object.keys(item.children).length > 0;
  }

  buildSelectionSubMenuItem(item) {
    const {
      handleSelectionFilterClick,
      handleDisplayNextSections,
      index,
      currentFilters,
      ispreOrder,
    } = this.props;

    if (this.isSelection(item.status)) {
      return (
        <SubMenuItem
          onClick={() => {
            // let isPreorder = null;
            // if (item.is_preorder) {
            //   isPreorder = item.is_preorder;
            // }
            const newFilters = handleDisplayNextSections(
              index,
              item.categories || item.children,
              item.name,
              ispreOrder
            );
            handleSelectionFilterClick(item.id, newFilters, ispreOrder);
          }}
          active={
            currentFilters.selectedCategoryList.includes(item.name) ||
            currentFilters.selection === item.id
          }
        >
          {this.isSelection(item.status) && <Icon className="icon-assortment" content="\e945" />}
          <span className="submenu-item__name">{item.name.toLowerCase()}</span>
          {this.hasChildren(item) && <Icon content="\e907" />}
        </SubMenuItem>
      );
    }

    return <></>;
  }

  handleCategoryItemClick(item) {
    const {
      index,
      handleDisplayNextSections,
      handleCategoryFilterClick,
      handleSelectionFilterClick,
    } = this.props;
    if (this.hasChildren(item)) {
      const newFilters = handleDisplayNextSections(
        index,
        item.categories || item.children,
        item.name
      );
      if (
        item.status === categoryConstants.STATUS_CATALOG ||
        item.status === categoryConstants.STATUS_PREORDER
      ) {
        handleSelectionFilterClick(item.id, newFilters, item.is_preorder);
      }
      return;
    }

    handleCategoryFilterClick(item.name, item.id, index);
  }

  buildSubMenuItem(item) {
    const { currentFilters } = this.props;

    if (!this.isSelection(item.status)) {
      return (
        <SubMenuItem
          onClick={() => {
            this.handleCategoryItemClick(item);
          }}
          active={
            currentFilters.selectedCategoryList.includes(item.name) ||
            currentFilters.selection === item.id ||
            currentFilters.selection === item.status
          }
        >
          {item.status === categoryConstants.STATUS_CATALOG && <Icon content="\e94c" />}
          {item.status === categoryConstants.STATUS_PREORDER && <Icon content="\e941" />}
          <span className="submenu-item__name">{item.name.toLowerCase()}</span>
          {this.hasChildren(item) && <Icon content="\e907" />}
        </SubMenuItem>
      );
    }

    return <></>;
  }

  render = () => {
    const { index, items, parentName, isPreorder, handleCategoryFilterClick } = this.props;
    return (
      <CategoryColumnWrapper>
        {index !== 0 && (
          <SubMenuItem
            onClick={() => {
              handleCategoryFilterClick(parentName, items, index, isPreorder);
            }}
          >
            <a className="show-all">VOIR TOUT</a>
          </SubMenuItem>
        )}
        {items.map((item) => (
          <span key={uuid()}>{this.buildSubMenuItem(item)}</span>
        ))}
        {index === 0 &&
          items.map((item) => <span key={uuid()}>{this.buildSelectionSubMenuItem(item)}</span>)}
      </CategoryColumnWrapper>
    );
  };
}

const mapStateToProps = (state) => ({
  currentPlatformId: selectCurrentPlatformId(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (filter) => {
    dispatch(catalogActions.fetchItems(filter, 0, 4200));
  },
});

ProductCategoryMenuColumn.propTypes = {
  index: PropTypes.number,
  items: PropTypes.array,
  handleCategoryFilterClick: PropTypes.func,
  handleSelectionFilterClick: PropTypes.func,
  handleDisplayNextSections: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryMenuColumn);
