import styled from 'styled-components';
import colors from 'styles/colors';

export const Button = styled.a`
  display: inline-block;
  padding: 11px 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  transition-property: background-color;
  transition-duration: 0.5s;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const getBgColor = (color, hover, disabled) => {
  if (disabled) {
    return color.disabled;
  }
  if (hover) {
    return color.hover;
  }
  return color.default;
};

const ButtonPrimarySecondary = styled(Button)`
  color: #ffffff;
  background-color: white;

  background-color: ${(props) =>
    props.inverted ? '#ffffff' : getBgColor(colors[props.type], false, props.disabled)};
  border: 1px solid ${(props) => getBgColor(colors[props.type], false, props.disabled)};
  color: ${(props) =>
    props.inverted ? getBgColor(colors[props.type], false, props.disabled) : '#ffffff'};
  border-radius: 4.25px;
  &:hover {
    background-color: ${(props) =>
      props.inverted ? '#ffffff' : getBgColor(colors[props.type], true, props.disabled)};
    border: 1px solid ${(props) => getBgColor(colors[props.type], true, props.disabled)};
    color: ${(props) =>
      props.inverted ? getBgColor(colors[props.type], true, props.disabled) : '#ffffff'};
  }
`;

export const ButtonPrimary = styled(ButtonPrimarySecondary)``;
ButtonPrimary.defaultProps = {
  type: 'primary',
};

export const ButtonSecondary = styled(ButtonPrimarySecondary)``;
ButtonSecondary.defaultProps = {
  type: 'secondary',
};

export const ButtonTertiary = styled(Button)`
  color: #ffffff;
  padding: 7px;
  font-size: 8px;
  border-radius: 4px;
  background-color: ${(props) => getBgColor(colors.tertiary[props.type], false, props.disabled)};
  &:hover {
    background-color: ${(props) => getBgColor(colors.tertiary[props.type], true, props.disabled)};
  }
`;
ButtonTertiary.defaultProps = {
  type: 0,
};

const ButtonPrimarySecondaryCircular = styled(ButtonPrimarySecondary)`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  padding: 0;
  line-height: ${(props) => props.size}px;
`;
ButtonPrimarySecondaryCircular.defaultProps = {
  size: 40,
};

export const ButtonPrimaryCircular = styled(ButtonPrimarySecondaryCircular)``;
ButtonPrimaryCircular.defaultProps = {
  type: 'primary',
};

export const ButtonSecondaryCircular = styled(ButtonPrimarySecondaryCircular)``;
ButtonSecondaryCircular.defaultProps = {
  type: 'secondary',
};

// export default Button
