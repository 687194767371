/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import menuWrapperWithStyle from './MenuWrapper.style';
import Menu from './Menu';

const reduceState = (state, level, data, k) => {
  const newState = state.filter((_, index) => (k ? index < level : index <= level));
  return data ? [...newState, data] : newState;
};

class MenuWrapper extends React.Component {
  state = {
    columns: [],
    trails: [],
    parents: [],
    previousState: {},
    isInitialized: false,
  };

  componentDidUpdate(prevProps) {
    const { sideMenuState, categoriesLoading, categories, selectedPlatform } = this.props;
    const { selectedPlatform: prevSelectedPlatform } = prevProps;
    const { isInitialized } = this.state;
    if (
      sideMenuState === true &&
      categories.length > 0 &&
      !categoriesLoading &&
      isInitialized === false
    ) {
      this.setState({
        columns: [categories],
        isInitialized: true,
      });
    }
    // Reset on platform change
    if (Number(selectedPlatform) !== Number(prevSelectedPlatform)) {
      this.setState({
        columns: [],
        isInitialized: false,
      });
    }
  }

  handleTrail = (id, level) => {
    this.setState((prevState) => ({
      trails: reduceState(prevState.trails, level, id, true),
    }));
  };

  handleClick = (e, data, level, id, parent) => {
    e.preventDefault();
    this.setState((prevState) => ({
      previousState: prevState,
    }));
    this.handleTrail(id, level);
    this.setState((prevState) => {
      const stateParents = [...prevState.parents];
      stateParents[level] = parent;
      return {
        parents: stateParents,
        columns: reduceState(prevState.columns, level, data),
      };
    });
  };

  handleClickGoBack = () => {
    this.setState((prevState) => {
      const oldState = prevState.previousState;
      return { ...oldState };
    });
  };

  reset = () => {
    this.setState({
      trails: [],
      parents: [],
      previousState: {},
      isInitialized: false,
    });
  };

  render() {
    const { className, handleOpenMenu } = this.props;
    const { trails, columns, parents } = this.state;

    return (
      <div className={className}>
        <Menu
          handleClick={this.handleClick}
          handleClickGoBack={this.handleClickGoBack}
          trails={trails}
          columns={columns}
          parents={parents}
          handleOpenMenu={handleOpenMenu}
          reset={this.reset}
        />
      </div>
    );
  }
}

MenuWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  sideMenuState: PropTypes.bool.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  categoriesLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPlatform: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { category, platform } = state;
  const { categories, isLoading } = category;
  return {
    selectedPlatform: platform.selectedId,
    categories,
    categoriesLoading: isLoading,
  };
};

export default connect(mapStateToProps)(menuWrapperWithStyle(MenuWrapper));
