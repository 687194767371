export const creditConstants = {
  OPEN: 'CREDIT_OPEN',
  CLOSE: 'CREDIT_CLOSE',
  ADD_CREDIT: 'ADD_CREDIT',
  UPDATE_CREDIT_LIST: 'UPDATE_CREDIT_LIST',

  FETCH_REQUEST: 'CREDIT_FETCH_REQUEST',
  FETCH_SUCCESS: 'CREDIT_FETCH_SUCCESS',
  FETCH_FAILURE: 'CREDIT_FETCH_FAILURE',

  FETCH_COUNT_ITEMS_REQUEST: 'CREDIT_COUNT_ITEMS_FETCH_REQUEST',
  FETCH_COUNT_ITEMS_SUCCESS: 'CREDIT_COUNT_ITEMS_FETCH_SUCCESS',
  FETCH_COUNT_ITEMS_FAILURE: 'CREDIT_COUNT_ITEMS_FETCH_FAILURE',

  CREATE_ITEMS_REQUEST: 'CREDIT_CREATE_ITEMS_REQUEST',
  CREATE_ITEMS_SUCCESS: 'CREDIT_CREATE_ITEMS_SUCCESS',
  CREATE_ITEMS_FAILURE: 'CREDIT_CREATE_ITEMS_FAILURE',
};
