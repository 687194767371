/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { moqActions } from 'actions';
import LinearLoader from 'components/Core/Spinner/LinearLoader';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import Icon from 'components/Icon/Icon';
import withStyle from './ModalImportMoq.style';

class ModalImportMoq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  selectFile = (e) => {
    this.setState({
      file: e.target.files[0] || null,
    });
  };

  handleSubmit = () => {
    const { uploadMoq, platformId } = this.props;
    const { file } = this.state;
    if (file && platformId) {
      uploadMoq(platformId, file);
    }
  };

  deleteFile = () => {
    this.setState({
      file: null,
    });
  };

  render() {
    const { className, isLoading, error } = this.props;
    const { file } = this.state;
    return (
      <div className={className}>
        <div className="text">
          <span>
            {`Veuillez choisir un fichier CSV dont la colonne A est
            l'EAN13 et la colonne B est la quantité minimale.`}
          </span>
        </div>
        <div className="file">
          <label>
            <input type="file" className="input-file" onChange={this.selectFile} hidden />
            <ButtonSecondary inverted>
              <span>Parcourir</span>
            </ButtonSecondary>
          </label>
        </div>
        <div className="oldFile" hidden={!file}>
          <span className="bold">{file && file.name}</span>
          <Icon onClick={this.deleteFile} content="\e915" />
        </div>
        <div className="validate">
          {!!isLoading && <LinearLoader />}
          <div className="page-profil__alert-bloc">
            {file === null && (
              <span className="alert-bloc__label">Aucun document n’a été importé</span>
            )}
            {!!error && (
              <span className="alert-bloc__label">
                Une erreur s&apos;est produite pendant l&apos;import.
              </span>
            )}
          </div>
          <ButtonPrimary onClick={this.handleSubmit} disabled={file === null || isLoading}>
            <span>Valider</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalImportMoq.propTypes = {
  className: PropTypes.string,
  uploadMoq: PropTypes.func,
  platformId: PropTypes.number,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    moq: { isLoading, error },
  } = state;
  return {
    isLoading,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadMoq: (platformId, file) => {
    dispatch(moqActions.uploadMoqFile(platformId, file));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyle(ModalImportMoq));
