import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions, cartActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withStyle from './ModalAlertMoq.style';

const ModalAddCommercialOrder = ({ className, closeModal, validateCart, cart }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>Êtes-vous certains de valider cette commande ?</span>
      </div>
      <div className="actions">
        <ButtonPrimary inverted onClick={() => closeModal()}>
          <span>Annuler</span>
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            validateCart(cart);
            closeModal();
          }}
        >
          <span>Valider</span>
        </ButtonPrimary>
      </div>
    </div>
  </div>
);

ModalAddCommercialOrder.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  validateCart: (cart) => dispatch(cartActions.validateCart(cart)),
});

export default connect(null, mapDispatchToProps)(withStyle(ModalAddCommercialOrder));
