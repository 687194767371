import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions, productActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import MoqQuantity from 'components/MOQ/MoqQuantity';
import ModalEngagementStockWithStyle from './ModalEngagementStock.style';

class ModalEngagementStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 0 };
  }

  render() {
    const { className, closeModal, product, submitEngagementStock, hasCommitted } = this.props;
    const { quantity } = this.state;
    return (
      <div className={className}>
        <div className="modal-header">
          {`Demande de stock engagement supplémentaire pour le produit " ${
            product && product.name
          } " (${product.reference})`}
        </div>

        <div className="modal-body">
          <div className="modal-body__stock">
            <span className="modal-body__text"> Stock engagement initial (colis) :</span>
            <div className="modal-body__quantity">
              {product.promotion && product.promotion.stock_commitment}
            </div>
          </div>

          <div className="modal-body__stock">
            <span className="modal-body__text"> Demande de stock supplémentaire (colis) :</span>
            <div className="modal-body__quantity">
              <MoqQuantity
                quantity={quantity || 0}
                updateQuantity={(updatedQuantity) => this.setState({ quantity: updatedQuantity })}
              />
            </div>
          </div>
        </div>

        <div className="modal__button">
          <ButtonPrimary
            inverted
            className="modal__button--cancel"
            onClick={() => {
              closeModal();
            }}
          >
            <span>Annuler</span>
          </ButtonPrimary>
          <ButtonPrimary
            className="modal__button--send"
            onClick={() => {
              if (quantity) {
                submitEngagementStock({
                  id: product.promotion.id,
                  stock_commitment_request: quantity,
                });
                hasCommitted();
                closeModal();
              }
            }}
            disabled={!quantity}
          >
            <span>Envoyer</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalEngagementStock.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
  submitEngagementStock: PropTypes.func,
  product: PropTypes.object,
  hasCommitted: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
  submitEngagementStock: (data) => dispatch(productActions.updateStockPromotion(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(ModalEngagementStockWithStyle(ModalEngagementStock));
