import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const ProfilMenuStyle = styled.div`
  .header--profil-dropdown-container {
    text-align: center;
  }

  .dd-header {
    padding:0;
  }

  .header--profil-icon {
    color: #01366b;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240, 242, 245);
    border-radius: 20px;
    padding: 10px;
    .icon-profil {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      svg {
        height: 18px;
        width: 18px
        display: block;
      }
    }
    .header--profil-username{
      font-weight: bold;
      word-break: keep-all;
      white-space: nowrap;
    }
  }
  
  .header--profil-group {
    cursor: pointer;
  }

  .dd-panel-wrapper {
    left: unset;
    right: 0;
    .dd-list {
      margin-top: 10px;
    }
    
    hr {
      width: calc(100% - 20px);
      margin: 10px auto;
      border-color: #C4C4C4;
      border-bottom: 0;
    }

    .profile-menu__content {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-weight: bold;
      background-color: white;
      z-index: 1;
      li span {
        color: #4a4a4a;
        text-decoration: none;
        margin-right: 0;
        cursor: pointer;
      }
    
      li {
        display: flex;
        align-items: center;
        padding: 10px 13px;
        color: #4a4a4a;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        position: relative;
        transition: background-color 0.3s ease,
                    color 0.3s ease;
        white-space: nowrap;
        cursor: pointer;
        i {
          font-size: 17px;
          font-weight: normal;
          vertical-align: middle;
          display: inline-block;
          padding-right: 13px;
          transition: color 0.3s ease;
        }
        .profile-menu__profile-link {
          display: flex;
          flex-direction: column;
          .profile-menu__my-profile {
            font-size: 10px;
          }
        }
      }

      li:last-child {
        border-bottom: none;
      }
    
      .contact i {
        font-size: 17px;
      }
    
      li:hover {
        span {
          color: #00a6ff;
        }
        i {
          color: #00a6ff;
        }
      }
  
    }
  
    ul.header--profil-group--list li:hover span {
      color: #00a6ff;
    }
  
    .profil-menu__footer {
      padding: 10px;
      padding-top: 0;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          padding: 0;
          a {
            font-size: 10px;
            color: #65676B;
            margin: 0;
          }
        }
      }
      
    }
  }

  @media ${screen.tablet} {
    .header--profil-group {
      padding-right: 3px;
    }
  }
  @media ${screen.mobile} {
    .header--profil-icon {
      i {
        font-size: 30px;
        margin-top: -2px;
        margin-left: 0;
      }
    }
  }
`;
