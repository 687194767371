import styled from 'styled-components';

export default (Component) => styled(Component)`
  .new-params-table,
  .old-params-table {
    padding: 30px 0;
    span {
      color: #01366b;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.12px;
      line-height: 19px;
    }
    table {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;
      td,
      th {
        border: 1px solid rgba(1, 54, 107, 0.2);
        height: 40px;
        width: calc(100% / 3);
      }
      th {
        background: #fff;
        color: #787878;
        font-size: 16px;
        letter-spacing: 0.12px;
        line-height: 19px;
        font-weight: normal;
        text-align: center;
      }
      td {
        background: #f0fafd;
        color: #787878;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        padding: 16px;
      }
    }
  }
  .new-params-table table td {
    color: #29d24e;
  }
  .actions {
    margin-top: 15px;
    text-align: center;
    a {
      margin: 10px 5px;
      height: 40px;
      padding: 10px 20px;
    }
  }
`;
