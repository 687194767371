import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { ListWrapper } from 'components/Core/Dropdown/Dropdown.style';
import './CatalogControls.scss';
import { selectCurrentUser } from 'selectors/user';

class AvailabilityControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list,
      storeList: props.storeList,
      isAllToggled: false,
      searchValue: '',
      listOpen: false,
    };
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list) {
      this.setState({ list: this.props.list });
    }
  }

  toggleAll() {
    const { isAllToggled, list, storeList } = this.state;
    const { handleClickFilter, setCurrentFilters, currentFilters } = this.props;

    const newAvailabilityFilters = [];
    const newStoreAvailabilityFilters = [];

    if (!isAllToggled) {
      list.forEach((item) => {
        newAvailabilityFilters.push(item.value);
      });
      storeList.forEach((item) => {
        newStoreAvailabilityFilters.push(item.value);
      });
    }

    const newActiveFilters = {
      ...currentFilters,
      availabilityList: newAvailabilityFilters,
      storeAvailabilityList: newStoreAvailabilityFilters,
    };

    this.setState({ isAllToggled: !isAllToggled });
    setCurrentFilters(newActiveFilters);
    handleClickFilter(newActiveFilters);
  }

  toggleItem(itemValue) {
    const { currentFilters, setCurrentFilters, handleClickFilter } = this.props;
    const availabilityFilters = currentFilters.availabilityList || [];

    let newAvailabilityFilters = [...availabilityFilters, itemValue];
    if (availabilityFilters.includes(itemValue)) {
      newAvailabilityFilters = newAvailabilityFilters.filter((item) => item !== itemValue);
    }

    const newActiveFilters = { ...currentFilters, availabilityList: newAvailabilityFilters };
    setCurrentFilters(newActiveFilters);
    handleClickFilter(newActiveFilters);
  }

  toggleStoreItem(itemValue) {
    const { currentFilters, setCurrentFilters, handleClickFilter } = this.props;
    const storeAvailabilityFilters = currentFilters.storeAvailabilityList || [];

    let newStoreAvailabilityFilters = [...storeAvailabilityFilters, itemValue];
    if (storeAvailabilityFilters.includes(itemValue)) {
      newStoreAvailabilityFilters = newStoreAvailabilityFilters.filter(
        (item) => item !== itemValue
      );
    }

    const newActiveFilters = {
      ...currentFilters,
      storeAvailabilityList: newStoreAvailabilityFilters,
    };
    setCurrentFilters(newActiveFilters);
    handleClickFilter(newActiveFilters);
  }

  toggleDropDown(listOpen) {
    this.setState({ listOpen });
  }

  handleSearchChange(event) {
    const searchValue = event.target.value;
    const { list } = this.props;

    const newList = list.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    this.setState({ list: newList, searchValue });
  }

  render() {
    const { currentFilters, currentUser } = this.props;
    const { isAllToggled, list, storeList, searchValue, listOpen } = this.state;
    const availabilityFilters = currentFilters.availabilityList || [];
    const storeAvailabilityFilters = currentFilters.storeAvailabilityList || [];
    const shouldDisplayClientStock = currentUser.client && currentUser.client.client_stock_display;

    return (
      <Dropdown
        headerTitle="Disponibilité"
        listOpen={listOpen}
        toggleDropDown={this.toggleDropDown}
      >
        <ul className="dd-list">
          <ListWrapper>
            {/* <li>
              <input
                className="dd-search"
                type="text"
                onChange={(event) => {
                  this.handleSearchChange(event);
                }}
                value={searchValue}
                placeholder="Recherche..."
              />
            </li> */}
            {/* <li>
              <label>
                <input type="checkbox" onChange={this.toggleAll} checked={isAllToggled} />
                (Tout selectionner)
              </label>
            </li> */}
            <li className="bold">Fournisseur</li>
            <li>
              <hr />
            </li>
            {list &&
              list.map((item, key) => (
                <li className="dd-list-item" key={key}>
                  <label>
                    <input
                      onChange={() => {
                        this.toggleItem(item.value);
                      }}
                      checked={availabilityFilters.includes(item.value)}
                      type="checkbox"
                    />
                    {item.title}
                  </label>
                </li>
              ))}
            <br />
            {shouldDisplayClientStock && (
              <>
                <li className="bold">Magasin</li>
                <hr />
                {storeList &&
                  storeList.map((item, key) => (
                    <li className="dd-list-item" key={key}>
                      <label>
                        <input
                          onChange={() => {
                            this.toggleStoreItem(item.value);
                          }}
                          checked={storeAvailabilityFilters.includes(item.value)}
                          type="checkbox"
                        />
                        {item.title}
                      </label>
                    </li>
                  ))}
              </>
            )}
          </ListWrapper>
        </ul>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

AvailabilityControl.propTypes = {
  handleClickFilter: PropTypes.func,
  setCurrentFilters: PropTypes.func,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default connect(mapStateToProps, null)(AvailabilityControl);
