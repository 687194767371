/* eslint-disable no-nested-ternary */
import { objectsAreEqual } from 'helpers';
import isEqual from 'lodash.isequal';
import { clientConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const initState = {
  collections: [],
  collectionsCount: [],
  clientDetail: { isLoading: false, data: null, error: null },
};

export function client(state = initState, action) {
  if (!Object.keys(clientConstants).find((key) => clientConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  const typesToCreateCollection = [
    clientConstants.FETCH_COUNT_REQUEST,
    clientConstants.FETCH_COUNT_SUCCESS,
    clientConstants.FETCH_REQUEST,
    clientConstants.FETCH_SUCCESS,
  ];

  let collection = collections.find((c) => objectsAreEqual(filter, c.filter));

  if (!collection && typesToCreateCollection.includes(action.type)) {
    collection = { filter, isLoading: true, items: [] };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find((c) => objectsAreEqual(filter, c.filter));

  if (!collectionCount && typesToCreateCollection.includes(action.type)) {
    collectionCount = { filter, isLoading: true, items: [] };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case clientConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign({}, collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case clientConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case clientConstants.FETCH_COUNT_FAILURE:
      return state;
    case clientConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case clientConstants.FETCH_SUCCESS: {
      const newCollection = {
        ...collection,
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
      };
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case clientConstants.FETCH_FAILURE:
      return state;
    case clientConstants.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        clientDetail: { ...state.clientDetail, isLoading: true },
      };
    case clientConstants.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        clientDetail: { isLoading: false, data: action.client, error: null },
      };
    case clientConstants.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        clientDetail: { ...state.clientDetail, isLoading: false, error: action.error },
      };
    case clientConstants.FETCH_DETAIL_REQUEST:
      return {
        ...state,
        clientDetail: { isLoading: true, data: null, error: null },
      };
    case clientConstants.FETCH_DETAIL_SUCCESS: {
      return {
        ...state,
        clientDetail: { isLoading: false, data: action.client, error: null },
      };
    }
    case clientConstants.FETCH_DETAIL_FAILURE:
      return { ...state, clientDetail: { isLoading: false, data: null, error: action.error } };
    case clientConstants.UPDATE_EMAIL: {
      collections.map((c) => {
        const { items } = c;
        const newItems = items.map((i) => {
          if (String(i.id) === String(action.id)) {
            return Object.assign(i, action.email);
          }
          return i;
        });
        return Object.assign(c, { items: newItems, isLoading: false });
      });
      return {
        ...state,
        collections,
      };
    }
    default:
      return state;
  }
}
