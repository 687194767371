import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { store, getValueOfAttribut, getCartsById, numberToPrice } from 'helpers';
import { containerActions, modalActions } from 'actions';
import sum from 'lodash.sum';
import find from 'lodash.find';
import sortby from 'lodash.sortby';
import findindex from 'lodash.findindex';
import isequal from 'lodash.isequal';
import differenceby from 'lodash.differenceby';
import ProgressBar from 'components/Core/ProgressBar';
import { toastActions } from 'actions/Toast';
import { platformConstants } from 'pages/../constants';
import { BsCaretUpFill } from '@react-icons/all-files/bs/BsCaretUpFill';
import { BsCaretDownFill } from '@react-icons/all-files/bs/BsCaretDownFill';
import { containerConstants } from 'constants/ContainerV2.constants';
import { envConstants } from 'constants/Env.constants';
import containersTypeDry from './libraries/containersTypeDry.js';
import containersTypeReefer from './libraries/containersTypeReefer.js';
import Packer from './libraries/Packer.js';
import Bin from './libraries/Bin.js';
import { convertCm3VolumeToM3, convertM3VolumeToCm3, float2Digits } from './libraries/functions.js';
import { WithStyle, WrapperContainer, WrapperContainerItem } from './Containerization.style';

const { KEY_HAS_CONTAINERIZATION_V2 } = platformConstants;
class ContainerizationV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: window.location.pathname.split('/'),
      containersOpen: false,
      productItems: [],
      nbProductsTotal: 0,
      nbContainerDry: props.binsDry.length,
      nbContainerReefer: props.binsReefer.length,
      parsingProducts: [],
      containerName: [],
      containerMode: [],
      orderBinsDry: [],
      orderBinsReefer: [],
      containerModeList: [
        { name: 'PALETTE', maxVolume: 69, maxWeight: 25500 },
        { name: 'VRAC', maxVolume: 77.04, maxWeight: 25500 },
      ],
    };

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.statusContainers = this.statusContainers.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { nbProductsTotal } = this.state;
    const itemsCart = this.getCart();

    if (
      itemsCart &&
      itemsCart.cart_items &&
      this.getProductQuantity(itemsCart.cart_items) !== nbProductsTotal &&
      (prevState.nbProductsTotal !== nbProductsTotal || prevProps.itemsCart !== itemsCart)
    ) {
      const parsingProducts = this.parsingProducts(itemsCart.cart_items);
      this.getListContainers(parsingProducts);
      this.setState({
        parsingProducts,
        productItems: itemsCart.cart_items,
        nbProductsTotal: this.getProductQuantity(itemsCart.cart_items),
      });
    }
  }

  getRequestedId() {
    const { cartId, current, currentPreorder } = this.props;
    if (!cartId && current) {
      return current.id;
    }
    if (!cartId && currentPreorder) {
      return currentPreorder.id;
    }
    return cartId;
  }

  getCart() {
    const { current, currentPreorder, cart } = this.props;
    const { collections } = cart;
    const id = this.getRequestedId();

    if (String(current.id) === String(id) || id === 'current') {
      return current;
    }

    if (String(currentPreorder.id) === String(id) || id === 'current_preorder') {
      return currentPreorder;
    }

    const carts = getCartsById(id, collections);
    if (carts && carts.length) {
      return carts[0];
    }

    return null;
  }

  getProductQuantity(productItems) {
    return productItems.reduce((total, p) => total + p.quantity, 0);
  }

  getListContainers(newParsingProducts) {
    const {
      statePackerDry,
      statePackerReefer,
      stateContainersUpdateDry,
      stateContainersUpdateReefer,
      binsDry,
      binsReefer,
      packerDry,
      packerReefer,
    } = this.props;
    const { nbContainerDry, nbContainerReefer } = this.state;

    const { parsingProductsDry, parsingProductsReefer } = newParsingProducts;
    const nodeEnv = process.env.NODE_ENV;

    if (parsingProductsDry.length > 0) {
      if (packerDry === false) {
        const setPackerDry = new Packer(parsingProductsDry, 'containersTypeDry');
        if (nodeEnv === envConstants.ENV_DEVELOPPEMENT) {
          setPackerDry.setDebug(false);
        }
        setPackerDry.pack();
        const listContainersDry = new Promise((resolve) => {
          resolve(setPackerDry.getBins());
        });
        if (listContainersDry) {
          listContainersDry.then((containersDry) => {
            if (containersDry.length !== binsDry.length) {
              this.statusContainers(containersDry.length, binsDry.length, 'SEC');
            }
            this.setOrderBins(containersDry, containerConstants.TYPE_CONTAINER_DRY);
            stateContainersUpdateDry(containersDry);
          });
          statePackerDry(setPackerDry);
        }
      } else {
        let redraw = false;
        if (
          parsingProductsDry.reduce((total, p) => total + p.qty, 0) !==
          packerDry.items.reduce((total, p) => total + p.qty, 0)
        ) {
          redraw = true;
        }
        if (redraw) {
          packerDry.setItems(parsingProductsDry);
          packerDry.pack();
        }

        if (nodeEnv === envConstants.ENV_DEVELOPPEMENT) {
          packerDry.setDebug(false);
        }

        const listContainersDry = new Promise((resolve) => {
          resolve(packerDry.getBins());
        });
        if (listContainersDry) {
          listContainersDry.then((containersDry) => {
            if (containersDry.length !== nbContainerDry) {
              this.statusContainers(containersDry.length, nbContainerDry, 'SEC');
            }
            this.setOrderBins(containersDry, containerConstants.TYPE_CONTAINER_DRY);
            stateContainersUpdateDry(containersDry);
          });
        }
      }
    } else {
      statePackerDry(false);
      stateContainersUpdateDry([]);
      this.setState({
        nbContainerDry: 0,
      });
      this.statusContainers(0, nbContainerDry, 'SEC');
    }

    if (parsingProductsReefer.length > 0) {
      if (packerReefer === false) {
        const setPackerReefer = new Packer(parsingProductsReefer, 'containersTypeReefer');
        if (nodeEnv === envConstants.ENV_DEVELOPPEMENT) {
          setPackerReefer.setDebug(false);
        }
        setPackerReefer.pack();

        const listContainersReefer = new Promise((resolve) => {
          resolve(setPackerReefer.getBins());
        });
        if (listContainersReefer) {
          listContainersReefer.then((containersReefer) => {
            if (containersReefer.length !== binsReefer.length) {
              this.statusContainers(containersReefer.length, binsReefer.length, 'FROID');
            }
            this.setOrderBins(containersReefer, containerConstants.TYPE_CONTAINER_REEFER);
            stateContainersUpdateReefer(containersReefer);
          });
          statePackerReefer(setPackerReefer);
        }
      } else {
        let redraw = false;
        if (
          parsingProductsReefer.reduce((total, p) => total + p.qty, 0) !==
          packerReefer.items.reduce((total, p) => total + p.qty, 0)
        ) {
          redraw = true;
        }
        if (redraw) {
          packerReefer.setItems(parsingProductsReefer);
          packerReefer.pack();
        }

        if (nodeEnv === envConstants.ENV_DEVELOPPEMENT) {
          packerReefer.setDebug(false);
        }
        const listContainersReefer = new Promise((resolve) => {
          resolve(packerReefer.getBins());
        });
        if (listContainersReefer) {
          listContainersReefer.then((containersReefer) => {
            if (containersReefer.length !== nbContainerReefer) {
              this.statusContainers(containersReefer.length, nbContainerReefer, 'FROID');
            }
            this.setOrderBins(containersReefer, containerConstants.TYPE_CONTAINER_REEFER);
            stateContainersUpdateReefer(containersReefer);
          });
        }
      }
    } else {
      statePackerReefer(false);
      stateContainersUpdateReefer([]);
      this.setState({
        nbContainerReefer: 0,
      });
      this.statusContainers(0, nbContainerReefer, 'FROID');
    }
  }

  setContainerName(index, name) {
    const { containerName } = this.state;
    containerName[index] = name;
    this.setState({ containerName });
  }

  setContainerMode(index, name) {
    const { containerMode } = this.state;
    containerMode[index] = name;
    this.setState({ containerMode });
  }

  setOrderBins(bins, type) {
    const newOrderBins = [];
    bins.forEach((bin, index) => {
      newOrderBins[index] = bin.id;
    });
    if (type === containerConstants.TYPE_CONTAINER_DRY) {
      const { orderBinsDry } = this.state;
      if (bins.length !== orderBinsDry.length || !isequal(newOrderBins, orderBinsDry)) {
        this.setState({ orderBinsDry: newOrderBins });
      }
    } else {
      const { orderBinsReefer } = this.state;
      if (bins.length !== orderBinsReefer.length || !isequal(newOrderBins, orderBinsReefer)) {
        this.setState({ orderBinsReefer: newOrderBins });
      }
    }
  }

  getOrderBins(bins) {
    const newOrderBins = [];
    bins.forEach((bin, index) => {
      newOrderBins[index] = bin.id;
    });
    return newOrderBins;
  }

  applyOrderBins(listContainer, binId, type) {
    const { orderBinsDry, orderBinsReefer } = this.state;
    const newOrderBins = this.getOrderBins(listContainer);
    let orderBins;
    if (type === containerConstants.TYPE_CONTAINER_DRY) {
      orderBins = orderBinsDry;
    } else {
      orderBins = orderBinsReefer;
    }

    const diffBins = differenceby(newOrderBins, orderBins, Math.double);
    const fromIndex = findindex(listContainer, (c) => c.id === diffBins[0]);
    const toIndex = findindex(orderBins, (c) => c === binId);

    orderBins.splice(toIndex, 0, diffBins[0]);
    if (toIndex !== -1 && fromIndex !== -1) {
      const binsReorderByKey = sortby(listContainer, (c) => orderBins.indexOf(c.id));
      this.setOrderBins(binsReorderByKey, type);
      return binsReorderByKey;
    }
    return listContainer;
  }

  parsingProducts(products) {
    const parsingProductsReefer = [];
    const parsingProductsDry = [];
    if (products && products.length > 0) {
      products.forEach((product) => {
        const {
          item,
          item: { package: itemPackage },
        } = product;
        if (product.quantity > 0) {
          const itemP = {
            id: item.id,
            reference: item.reference,
            name: item.name,
            ean: item.ean13,
            type: product.item_type,
            pcb: item.pcb,
            price: item.price,
            dlv: item.sale_deadline,
            width:
              itemPackage && itemPackage.width_dimensions_uc
                ? itemPackage.width_dimensions_uc * product.item_pcb
                : 0,
            height:
              itemPackage && itemPackage.height_dimensions_uc
                ? itemPackage.height_dimensions_uc * product.item_pcb
                : 0,
            length:
              itemPackage && itemPackage.length_dimensions_uc
                ? itemPackage.length_dimensions_uc * product.item_pcb
                : 0,
            weight:
              itemPackage && itemPackage.weight_gross_package
                ? parseFloat(itemPackage.weight_gross_package)
                : 0,
            qty: product.quantity,
            packVolume:
              itemPackage && itemPackage.volume_package
                ? convertM3VolumeToCm3(parseFloat(itemPackage.volume_package))
                : 0,
          };
          if (product.item_type === 'SEC') {
            parsingProductsDry.push(itemP);
          } else {
            parsingProductsReefer.push(itemP);
          }
        }
      });
    }
    const nbParsingProducts = parsingProductsDry.length + parsingProductsReefer.length;
    return { parsingProductsDry, parsingProductsReefer, nbParsingProducts };
  }

  statusContainers(countContainer, nbContainer, type) {
    const { pushToast } = this.props;
    const pushToastMessage = (message) => {
      return pushToast({
        type: 'success',
        message,
        duration: 200,
      });
    };

    let returnToast = '';
    if (countContainer !== nbContainer) {
      if (countContainer > nbContainer) {
        const diff = countContainer - nbContainer;
        if (diff === 1) {
          returnToast = pushToastMessage(`1 container ${type} a été ajouté`);
        } else {
          returnToast = pushToastMessage(`Plusieurs containers ${type} ont été ajoutés`);
        }
      } else if (countContainer < nbContainer && countContainer !== 0) {
        const diff = nbContainer - countContainer;
        if (diff === 1) {
          returnToast = pushToastMessage(`1 container ${type} a été retiré`);
        } else {
          returnToast = pushToastMessage(`Plusieurs containers ${type} ont été retirés`);
        }
      } else if (countContainer === 0 && nbContainer === 1) {
        returnToast = pushToastMessage(`Tous les containers ${type} ont été retirés`);
      }
      if (type === 'SEC') {
        this.setState({ nbContainerDry: countContainer });
      } else {
        this.setState({ nbContainerReefer: countContainer });
      }
    }
    return returnToast;
  }

  toggleDropDown(containersOpen) {
    this.setState({ containersOpen });
  }

  updateContainer(container) {
    const {
      stateContainersUpdateDry,
      stateContainersUpdateReefer,
      statePackerDry,
      statePackerReefer,
      bins,
      packerDry,
      packerReefer,
    } = this.props;
    const { parsingProducts } = this.state;
    const { parsingProductsDry, parsingProductsReefer } = parsingProducts;
    const { type, index, name, mode } = container;

    const bin = bins[index];
    const cm =
      type === containerConstants.TYPE_CONTAINER_DRY
        ? find(containersTypeDry, { name })
        : find(containersTypeReefer, { name });
    const binsToUpdate =
      type === containerConstants.TYPE_CONTAINER_DRY ? packerDry.getBins() : packerReefer.getBins();
    const myBinIndex = findindex(binsToUpdate, (c) => c.id === bin.id);
    const binId = bin.id;

    binsToUpdate[myBinIndex] = new Bin(
      cm.name,
      cm.width,
      cm.height,
      cm.length,
      cm.weight,
      mode === containerConstants.MODE_VRAC ? cm.volumeVrac : cm.volumePallet,
      cm.type,
      mode,
      true
    );

    if (type === containerConstants.TYPE_CONTAINER_DRY) {
      packerDry.setBins(binsToUpdate);
      packerDry.setItems(parsingProductsDry);
      packerDry.pack();

      let listContainerDry = [];
      if (packerDry && packerDry.getBins()) {
        listContainerDry = packerDry.getBins();
        stateContainersUpdateDry(this.applyOrderBins(listContainerDry, binId, type));
        statePackerDry(packerDry);
      }
    } else {
      packerReefer.setBins(binsToUpdate);
      packerReefer.setItems(parsingProductsReefer);
      packerReefer.pack();

      let listContainerReefer = [];
      if (packerReefer && packerReefer.getBins()) {
        listContainerReefer = packerReefer.getBins();
        stateContainersUpdateReefer(this.applyOrderBins(listContainerReefer, binId, type));
        statePackerReefer(packerReefer);
      }
    }
  }

  calculateMaxMode(maxWeight, binMode) {
    let resultMaxWeight = maxWeight;
    if (binMode === containerConstants.MODE_PALETTE) {
      resultMaxWeight = maxWeight - (maxWeight - 6.6) / 100;
    }
    return resultMaxWeight;
  }

  render() {
    const {
      className,
      platform: { attributs },
      bins,
      openDetailContainerModal,
    } = this.props;
    const { pathName, containersOpen, containerMode, containerModeList, productItems } = this.state;

    return (
      !!Number(getValueOfAttribut(attributs || [], KEY_HAS_CONTAINERIZATION_V2)) && (
        <WithStyle pathName={pathName}>
          <div className={className}>
            <WrapperContainer containersOpen={containersOpen} productItems={productItems}>
              {bins && bins.length > 0 && (
                <div className="conteneurisation-content">
                  <div className="info">
                    Répartition à titre indicatif, par conteneur, selon une optimisation
                    poids/volume.
                  </div>
                  {(bins || []).map((c, index) => (
                    <WrapperContainerItem index={index}>
                      <div className="container-item">
                        <strong className="container-name">{`Conteneur ${index + 1}`}</strong>
                        <div className="select-block">
                          <select
                            placeholder={c.name}
                            value={c.name}
                            onChange={(e) => {
                              if (c.id) {
                                this.updateContainer({
                                  index,
                                  type: c.getType(),
                                  name: e.target.value,
                                  mode: containerMode[index] || c.mode,
                                });
                              }
                            }}
                          >
                            {(c.getType() === 'DRY' ? containersTypeDry : containersTypeReefer).map(
                              (type, i) => (
                                <option value={type.name} key={type.name} index={i}>
                                  {type.name}
                                </option>
                              )
                            )}
                          </select>
                          <select
                            placeholder={c.mode}
                            value={c.mode}
                            onChange={(e) => {
                              if (c.id) {
                                this.updateContainer({
                                  index,
                                  type: c.getType(),
                                  name: c.name,
                                  mode: e.target.value,
                                });
                              }
                            }}
                          >
                            {containerModeList.map((type, i) => (
                              <option value={type.name} key={type.name} index={i}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="gauge">
                          <div className="gauge-bar">
                            <div className="gauge-element gauge-bar-volume">
                              <ProgressBar
                                className="progress-bar"
                                percent={
                                  (float2Digits(convertCm3VolumeToM3(c.getItemsVolume())) * 100) /
                                  float2Digits(convertCm3VolumeToM3(c.getMaxVolume()))
                                }
                              />
                              <div className="gauge-title gauge-title-volume">
                                <span>
                                  Volume :{' '}
                                  {`${float2Digits(
                                    convertCm3VolumeToM3(c.getItemsVolume())
                                  )} m3 / ${float2Digits(
                                    convertCm3VolumeToM3(c.getMaxVolume())
                                  )} m3`}
                                </span>
                              </div>
                            </div>
                            <div className="gauge-element gauge-bar-weight">
                              <ProgressBar
                                className="progress-bar"
                                percent={
                                  (float2Digits(c.getItemsWeight()) * 100) /
                                  float2Digits(c.getMaxWeight())
                                }
                              />
                              <div className="gauge-title gauge-title-weight">
                                <span>
                                  Poids :{' '}
                                  {`${float2Digits(c.getItemsWeight())} Kg / ${float2Digits(
                                    c.getMaxWeight()
                                  )} kg`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bloc-detail">
                          <span
                            className="bt-detail"
                            onClick={() =>
                              openDetailContainerModal({
                                index,
                                containerName: c.name,
                                loadingType: c.getType(),
                                volume: float2Digits(convertCm3VolumeToM3(c.getItemsVolume())),
                                maxVolume: float2Digits(convertCm3VolumeToM3(c.getMaxVolume())),
                                weight: float2Digits(c.getItemsWeight()),
                                maxWeight: float2Digits(c.getMaxWeight()),
                                items: c.items,
                                type: c.getType(),
                                mode: c.mode,
                              })
                            }
                          >
                            Détail
                          </span>
                        </div>
                      </div>
                    </WrapperContainerItem>
                  ))}
                </div>
              )}
            </WrapperContainer>
            {bins && bins.length > 0 && (
              <div
                className="toggle-containeurisation"
                onClick={(event) => {
                  this.toggleDropDown(!containersOpen);
                }}
              >
                <span className="nb-container">{bins.length} Conteneur (s)</span>
                <span className="dropUpDown">
                  {containersOpen ? <BsCaretDownFill /> : <BsCaretUpFill />}
                </span>
              </div>
            )}
          </div>
        </WithStyle>
      )
    );
  }
}

ContainerizationV2.propTypes = {
  cart: PropTypes.object,
  className: PropTypes.string,
  platform: PropTypes.object,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  pushToast: PropTypes.func,
  statePackerDry: PropTypes.func,
  statePackerReefer: PropTypes.func,
  stateContainersUpdateDry: PropTypes.func,
  stateContainersUpdateReefer: PropTypes.func,
  bins: PropTypes.array,
  binsDry: PropTypes.array,
  binsReefer: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    platform,
    cart,
    container: { binsDry, binsReefer, packerDry, packerReefer },
  } = state;
  const {
    current,
    currentPreorder,
    error,
    isLoading: isCopyCartLoading,
    id: cartId,
    containers,
  } = cart;
  const bins = [...binsDry, ...binsReefer];

  return {
    cart,
    cartId: cartId || 0,
    platform: platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)) || {},
    containers,
    bins,
    binsDry,
    binsReefer,
    packerDry,
    packerReefer,
    current,
    currentPreorder,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  statePackerDry: (packerDry) => {
    dispatch(containerActions.statePackerDry(packerDry));
  },
  statePackerReefer: (packerReefer) => {
    dispatch(containerActions.statePackerReefer(packerReefer));
  },
  stateContainersUpdateDry: (bins) => {
    dispatch(containerActions.stateContainersDry(bins));
  },
  stateContainersUpdateReefer: (bins) => {
    dispatch(containerActions.stateContainersReefer(bins));
  },
  openDetailContainerModal: (containerDetail) => {
    dispatch(
      modalActions.open('containerDetail', {
        width: '900',
        modalHeader: '',
        containerDetail,
      })
    );
  },
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerizationV2);
