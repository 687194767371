import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { productActions, catalogActions } from 'actions';

import { BsHeart } from '@react-icons/all-files/bs/BsHeart';
import { BsHeartFill } from '@react-icons/all-files/bs/BsHeartFill';

const ProductFavoriteTD = styled.td`
  svg {
    width: 25px;
    height: 25px;
    fill: #01366b;
  }
`;

class ProductFavorite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorite: this.props.isFavorite,
    };
  }

  toggleIsFavorite = () => {
    const { isFavorite } = this.state;

    this.setState({ isFavorite: !isFavorite });
  };

  handleSetFavoriteClick = (productId) => {
    const { likeProduct, toggleProductIsFavorite } = this.props;

    likeProduct(productId, toggleProductIsFavorite);
    this.toggleIsFavorite();
  };

  handleSetUnfavoriteClick = (productId) => {
    const { unlikeProduct, toggleProductIsFavorite } = this.props;

    unlikeProduct(productId, toggleProductIsFavorite);
    this.toggleIsFavorite();
  };

  buildHeart = (productId) => {
    const { isFavorite } = this.state;

    if (isFavorite) {
      return (
        <BsHeartFill
          onClick={() => {
            this.handleSetUnfavoriteClick(productId);
          }}
        />
      );
    }
    return (
      <BsHeart
        onClick={() => {
          this.handleSetFavoriteClick(productId);
        }}
      />
    );
  };

  render() {
    const { shouldShowResponsive, productId } = this.props;

    if (shouldShowResponsive) {
      return <div className="product-favorite__heart-container">{this.buildHeart(productId)}</div>;
    }
    return <ProductFavoriteTD>{this.buildHeart(productId)}</ProductFavoriteTD>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  likeProduct: (productId, callback) => {
    dispatch(productActions.addFavoriteProduct(productId, callback));
  },
  unlikeProduct: (productId, callback) => {
    dispatch(productActions.removeFavoriteProduct(productId, callback));
  },
  toggleProductIsFavorite: (productId, filter) =>
    dispatch(catalogActions.toggleProductIsFavorite(productId, filter)),
});

ProductFavorite.propTypes = {
  likeProduct: PropTypes.func,
  unlikeProduct: PropTypes.func,
  productId: PropTypes.number,
  shouldShowResponsive: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(ProductFavorite);
