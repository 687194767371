import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CommercialCartListWrapper } from 'pages/CommercialCartList/CommercialCartList.style';
import { dateTimeToFormatedDate, getPath, history } from 'helpers';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { AiOutlineDownload } from '@react-icons/all-files/ai/AiOutlineDownload';
import { AiOutlineClockCircle } from '@react-icons/all-files/ai/AiOutlineClockCircle';
import { AiOutlineDoubleRight } from '@react-icons/all-files/ai/AiOutlineDoubleRight';
import { IoMdPlay } from '@react-icons/all-files/io/IoMdPlay';
import { IoReloadOutline } from '@react-icons/all-files/io5/IoReloadOutline';
import { BsFileEarmarkPlus } from '@react-icons/all-files/bs/BsFileEarmarkPlus';
import { BsTrash } from '@react-icons/all-files/bs/BsTrash';
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';
import { selectCurrentPlatformId } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import { cartActions, modalActions, ocrOrderPdfActions } from 'actions';
import { selectCartCollectionByFilter } from 'selectors/cart';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { ButtonSecondary } from 'components/Core/Button/Button';
import { Link } from 'react-router-dom';
import { selectOcrOrderPdf } from 'selectors/ocrOrderPdf';
import { cartConstants, ocrOrderPdfConstants } from '../../constants';

const CommercialCartList = ({
  selectedPlatformId,
  currentUser,
  fetchCartList,
  cartList,
  openDeleteCartModal,
  openDeleteJobModal,
  openImportOrderModal,
  ocrOrderPdf,
  fetchAllOcrJob,
}) => {
  useEffect(() => {
    fetchCartList(cartFilter);
    fetchAllOcrJob();
  }, []);

  const cartFilter = {
    platform: selectedPlatformId,
    orderer: currentUser.id,
    status: cartConstants.CART_STATUS_PENDING_VALIDATION,
  };

  const importListColumns = React.useMemo(() => [
    {
      id: 'dateCreation',
      Header: 'Date',
      accessor: (item) => item.date_started,
      Cell: ({ value }) => (value ? dateTimeToFormatedDate(value) : '-'),
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: (item) => item.client,
      Cell: ({ value }) => (value && value.name ? value.name : '-'),
    },
    {
      id: 'filename',
      Header: 'Fichier',
      accessor: (item) => item.pdf_filename,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'status',
      Header: "Statut d'import",
      accessor: (item) => item.job_status,
      Cell: ({ value }) => {
        switch (value) {
          case ocrOrderPdfConstants.STATUS_PROCESSING:
            return (
              <div className="commercial-cart-list__processing">
                <AiOutlineClockCircle /> En traitement
              </div>
            );
          case ocrOrderPdfConstants.STATUS_DONE:
            return (
              <div className="commercial-cart-list__done">
                <span>
                  <BsCheck /> Terminé
                </span>
              </div>
            );
          default:
            return value || '-';
        }
      },
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="commercial-cart-list__actions">
          {value && value.job_status === 'DONE' && (
            <ButtonSecondary
              className="commercial-cart-list__actions__continue"
              inverted
              onClick={() => {
                history.push(getPath('ocrOrderMatch').replace(':id', value.id));
              }}
            >
              <AiOutlineDoubleRight fontSize="14" /> Utiliser
            </ButtonSecondary>
          )}
          <span
            className="commercial-cart-list__actions__delete"
            onClick={() => {
              openDeleteJobModal(value.id);
            }}
          >
            <BsTrash /> Supprimer
          </span>
        </div>
      ),
    },
  ]);

  const cartListColumns = React.useMemo(() => [
    {
      id: 'dateCreation',
      Header: 'Date',
      accessor: (item) => item.date_create,
      Cell: ({ value }) => dateTimeToFormatedDate(value) || '-',
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: (item) => item.user.client,
      Cell: ({ value }) =>
        value && value.ext_code && value.name ? `${value.ext_code} - ${value.name}` : '-',
    },
    {
      id: 'total',
      Header: 'Montant',
      accessor: (item) => item.total_price,
      Cell: ({ value }) =>
        value ? <>{value.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} € HT</> : '-',
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="commercial-cart-list__actions">
          <Link
            to={{
              pathname: getPath('commercialOrder'),
              state: { cart: value },
            }}
          >
            <ButtonSecondary className="commercial-cart-list__actions__continue" inverted>
              <IoMdPlay font-size="14" /> Continuer
            </ButtonSecondary>
          </Link>
          <span
            className="commercial-cart-list__actions__delete"
            onClick={() => {
              openDeleteCartModal(value.id);
            }}
          >
            <BsTrash /> Supprimer
          </span>
        </div>
      ),
    },
  ]);

  const url = [
    {
      text: 'Accueil ',
      href: getPath('dashboardAdmin'),
    },
  ];
  const currentPage = 'Saisi de commande';

  return (
    <CommercialCartListWrapper>
      <div className="page-commercial-cart-list">
        <div className="page-commercial__heading">
          <BreadCrumb urlTab={url} currentPage={currentPage} />
        </div>
        <div className="commercial-cart-list__action-column">
          <div
            className="commercial-cart-list__action-column__button"
            onClick={() => {
              history.push(getPath('commercialOrder'));
            }}
          >
            <BsFileEarmarkPlus />
            <span>Saisir une commande</span>
          </div>
          <div
            className="commercial-cart-list__action-column__button"
            onClick={openImportOrderModal}
          >
            <AiOutlineDownload />
            <span>Importer un bon de commande</span>
          </div>
        </div>
        <div className="commercial-cart-list__table-column">
          <div className="table-column__table-container">
            <div className="commercial-cart-list__title-container">
              <h4>IMPORTS EN COURS ({ocrOrderPdf && ocrOrderPdf.data.length})</h4>
              <ButtonSecondary
                className="commercial-cart-list__title-container__refresh-button"
                inverted
                onClick={() => {
                  fetchAllOcrJob();
                }}
              >
                <IoReloadOutline fontSize="18" /> Rafraîchir
              </ButtonSecondary>
            </div>
            <ReactTable
              columns={importListColumns}
              data={ocrOrderPdf && ocrOrderPdf.data}
              isLoading={!ocrOrderPdf || ocrOrderPdf.isLoading}
            />
          </div>
          <div className="table-column__table-container">
            <div className="commercial-cart-list__title-container">
              <h4>COMMANDES EN ATTENTE ({cartList && cartList.items.length})</h4>
            </div>
            <ReactTable
              columns={cartListColumns}
              data={(cartList && cartList.items) || []}
              isLoading={!cartList || cartList.isLoading}
            />
          </div>
        </div>
      </div>
    </CommercialCartListWrapper>
  );
};

const mapStateToProps = (state) => {
  const selectedPlatformId = selectCurrentPlatformId(state);
  const currentUser = selectCurrentUser(state);

  return {
    selectedPlatformId,
    currentUser,
    cartList: selectCartCollectionByFilter(state, {
      platform: selectedPlatformId,
      orderer: currentUser.id,
      status: cartConstants.CART_STATUS_PENDING_VALIDATION,
    }),
    ocrOrderPdf: selectOcrOrderPdf(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCartList: (filter) => dispatch(cartActions.fetchItems(filter, 1, 4000)),
  openDeleteCartModal: (cartId) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: cartId,
        deleteType: 'COMMERCIAL_CART',
      })
    ),
  openDeleteJobModal: (jobId) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: jobId,
        deleteType: 'OCR_JOB',
      })
    ),
  openImportOrderModal: () =>
    dispatch(
      modalActions.open('importPdfOrder', {
        modalHeader: "Importation d'un bon de commande",
        width: '700',
      })
    ),
  fetchAllOcrJob: () => dispatch(ocrOrderPdfActions.fetchAllOcrJob()),
});

CommercialCartList.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommercialCartList);
