export const aggridFilterConstants = {
  FILTER_NO_FILTER: 'all',
  FILTER_FAVORITE: 'favorite',
  FILTER_PROMOTION: 'promotion',
  FILTER_TOP_SALES: 'topSales',
  FILTER_NEW: 'new',
  FILTER_DRY: 'SEC',
  FILTER_FRESH: 'POSITIF',
  FILTER_FROZEN: 'FROID',
  FILTER_AVAILABLE: 'AVAILABLE',
  FILTER_UNAVAILABLE: 'UNAVAILABLE',
  FILTER_PREORDER: 'PREORDER',
  FILTER_STORE_AVAILABLE: 'STORE_AVAILABLE',
  FILTER_STORE_UNAVAILABLE: 'STORE_UNAVAILABLE',
  FILTER_STORE_NO_ASSORTMENT: 'STORE_NO_ASSORTMENT',
  SET_FILTERS: 'AGGRID_SET_FILTERS',
  TOGGLE_VIEW: 'AGGROD_TOGGLE_VIEW',
};
