import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon/Icon';
import withStyle from './ProspectBloc.styles';

const TYPOLOGIES = [
  'GMS - ENTREPRISES PRIVÉES',
  'GMS - BOUCH. / POISS. / BOUL.',
  'GMS - SUPÉRETTES SANS ENSEIGNE',
  'GMS - SUPÉRETTES SOUS ENSEIGNE',
  'GMS - STATION SERVICE',
  'GMS - GRANDS SUPERMARCHÉS',
  'GMS - HARD-DISCOUNT',
  'RHF - ASSOCIATIF / CARITATIF / ÉDUCATIF',
  'RHF - CAISSES ET ORGANISMES SOCIAUX',
  'RHF - FOYERS ET MAISON DE RETRAITE',
  'RHF - ETABLISSEMENT SCOLAIRE',
  'RHF - CATERING / SERVICE CROISIERE',
  'RHF - HOTEL',
  'RHF - RESTAURANT / SNACK',
  'RHF - HÔPITAUX / CLINIQUES',
  'RHF - ARMEES / GENDARMERIE',
  'AUTRE',
];

const PersonalInformation = ({
  className,
  companyName,
  managerName,
  phoneNumber,
  faxNumber,
  email,
  typology,
  brand,
  handleChange,
  brandItems,
}) => (
  <div className={className}>
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e92e" />
      <span className="entete__label">Informations personnelles</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut">Nom de la société :</span>
        <input
          className="ligne__value-input ligne__value"
          name="companyName"
          onChange={handleChange}
          value={companyName}
          required
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Nom du gérant :</span>
        <input
          className="ligne__value-input ligne__value"
          name="managerName"
          onChange={handleChange}
          value={managerName}
          required
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Téléphone :</span>
        <input
          className="ligne__value-input ligne__value"
          name="phoneNumber"
          onChange={handleChange}
          value={phoneNumber}
          required
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Fax :</span>
        <input
          className="ligne__value-input ligne__value"
          name="faxNumber"
          onChange={handleChange}
          value={faxNumber}
          required
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Email :</span>
        <input
          className="ligne__value-input ligne__value"
          name="email"
          onChange={handleChange}
          value={email}
          required
        />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Typologie :</span>
        <select
          className="ligne__value-input ligne__value"
          name="typology"
          onChange={handleChange}
          value={typology}
        >
          {TYPOLOGIES.map((typologyString) => (
            <option key={typologyString} value={typologyString}>
              {typologyString}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

export default withStyle(PersonalInformation);
