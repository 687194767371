import { termsConstants } from 'helpers/../constants';
import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

const { STATUS_ACTIVE } = termsConstants;

export const termsService = {
  fetchTotalRowNumber,
  fetchItems,
  createTerm,
  updateTerm,
  deleteTerm,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/term/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);

  return fetch(
    `${config.apiUrl}/api/term?filter=${urlFilter}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function createTerm(data) {
  const { title, file, platform } = data;
  const body = new FormData();
  body.append('file', file);
  body.append('title', title);
  body.append('platform', JSON.stringify(platform));
  const headers = {
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/term/upload`, requestOptions).then(handleResponse);
}

function updateTerm(id, data, hasChangedFile = false) {
  if (!hasChangedFile) {
    const dataToJson = {
      id,
    };
    if (data.title) {
      dataToJson.title = data.title;
    }
    if (data.status) {
      dataToJson.status = data.status;
    }
    if (data.date_unactivated) {
      dataToJson.date_unactivated = data.date_unactivated;
    } else if (data.status === STATUS_ACTIVE) {
      dataToJson.date_unactivated = null;
    }
    const body = JSON.stringify(dataToJson);

    const headers = {
      'Content-Type': 'application/json',
      ...authHeader(false),
    };
    const requestOptions = {
      method: 'PUT',
      headers,
      body,
      mode: 'cors',
    };
    return fetch(`${config.apiUrl}/api/term/update`, requestOptions).then(handleResponse);
  }
  const { title, file } = data;
  const body = new FormData();
  body.append('file', file);
  body.append('title', title);
  const headers = {
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/term/upload/${id}`, requestOptions).then(handleResponse);
}

function deleteTerm(termId) {
  const headers = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers,
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/term/delete/${termId}`, requestOptions).then(handleResponse);
}
