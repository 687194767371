import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OrderFromCsv from 'components/OrderFromCsv/OrderFromCsv';

const OrderFromCsvPage = () => <OrderFromCsv />;

const mapStateToProps = (state) => {
  const { productCard } = state;

  return { currentProduct: productCard.currentProduct };
};

OrderFromCsv.propTypes = {
  currentProduct: PropTypes.object,
};

export default connect(mapStateToProps)(OrderFromCsvPage);
