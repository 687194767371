import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userActions, alertActions } from 'actions';
import Messages from 'components/Messages/Messages';
import { PersonalInformation, Address, PasswordForm } from 'components/Profil';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import { getPath } from 'helpers';
import { userService } from 'services';
import { userConstants } from '../../constants';
import withProfilstyle from './withProfilStyle';

const { TYPE_CUSTOMER } = userConstants;

const currentPage = 'Mon profil';

class ProfilPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      isOldPasswordVisible: false,
      newPassword: '',
      isNewPasswordVisible: false,
      newPasswordConfirmation: '',
      isNewPasswordConfirmationVisible: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  makePasswordVisible = (type) => {
    switch (type) {
      case 'old':
        this.setState((prevState) => ({
          isOldPasswordVisible: !prevState.isOldPasswordVisible,
        }));
        break;
      case 'new':
        this.setState((prevState) => ({
          isNewPasswordVisible: !prevState.isNewPasswordVisible,
        }));
        break;
      case 'newConfirmation':
        this.setState((prevState) => ({
          isNewPasswordConfirmationVisible: !prevState.isNewPasswordConfirmationVisible,
        }));
        break;
      default:
        this.setState({
          isOldPasswordVisible: false,
          isNewPasswordVisible: false,
          isNewPasswordConfirmationVisible: false,
        });
    }
  };

  submitPassword = () => {
    const { updatePassword } = this.props;
    const { oldPassword, newPassword, newPasswordConfirmation } = this.state;
    if (
      oldPassword &&
      newPassword &&
      newPasswordConfirmation &&
      newPassword === newPasswordConfirmation
    ) {
      updatePassword(oldPassword, newPassword, newPasswordConfirmation);
      this.setState({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      });
    }
  };

  render() {
    const {
      className,
      alert,
      firstname,
      lastname,
      telephone,
      fax,
      email,
      personInCharge,
      address,
      address2,
      zipcode,
      client,
      city,
      country,
      type,
      username,
    } = this.props;
    const {
      isOldPasswordVisible,
      oldPassword,
      isNewPasswordVisible,
      newPassword,
      isNewPasswordConfirmationVisible,
      newPasswordConfirmation,
    } = this.state;
    const url = [
      {
        text: 'Accueil ',
        href: type === TYPE_CUSTOMER ? userService.getHomepagePath() : getPath('dashboardAdmin'),
      },
    ];
    return (
      <div className={className}>
        <div className="page-profil">
          <div className="page-profil__heading">
            <BreadCrumb urlTab={url} currentPage={currentPage} />
          </div>
          {type === TYPE_CUSTOMER ? <Messages /> : null}

          <div className="page-profil__section-info">
            <PersonalInformation
              title="Informations personnelles"
              type="personnal"
              user={type}
              name={client ? client.name : ''}
              firstname={firstname}
              lastname={lastname}
              email={email}
              telephone={telephone}
              username={username}
              fax={fax}
              personInCharge={personInCharge}
            />
            {type === TYPE_CUSTOMER && (
              <Address
                address={address}
                complementaryAddress={address2}
                zipCode={zipcode}
                city={city}
                country={country}
              />
            )}
            <PasswordForm
              oldPassword={oldPassword}
              isOldPasswordVisible={isOldPasswordVisible}
              newPassword={newPassword}
              isNewPasswordVisible={isNewPasswordVisible}
              newPasswordConfirmation={newPasswordConfirmation}
              isNewPasswordConfirmationVisible={isNewPasswordConfirmationVisible}
              handleChange={this.handleChange}
              submitPassword={this.submitPassword}
              makePasswordVisible={this.makePasswordVisible}
              alert={alert}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProfilPage.propTypes = {
  className: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  username: PropTypes.string,
  telephone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  type: PropTypes.string,
  personInCharge: PropTypes.string,
  client: PropTypes.object,
  address: PropTypes.string,
  address2: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  updatePassword: PropTypes.func,
  alert: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const { user } = state;
  const { information, alert } = user;
  const { email, firstname, lastname, tel_number1: telephone, fax, client, type, username } =
    information || {};

  const { address, address2, city, zipcode, country, commercials } = client || {};
  const personInCharge = commercials && commercials.length > 0 ? String(commercials.pop()) : null;

  return {
    alert,
    email,
    firstname,
    lastname,
    telephone,
    fax,
    address,
    address2,
    city,
    zipcode,
    country,
    personInCharge,
    client,
    type,
    username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (oldPassword, newPassword, newPasswordConfirmation) =>
    dispatch(userActions.updatePassword(oldPassword, newPassword, newPasswordConfirmation)),
  clearAlerts: () => dispatch(alertActions.clear()),
  raiseError: (errorMessage) => {
    dispatch(alertActions.error(errorMessage));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withProfilstyle(ProfilPage));
