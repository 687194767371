import { containerService } from 'services';
import { containerConstants } from '../constants';

export const containerActions = {
  stateContainersDry,
  stateContainersReefer,
  saveContainers,
  getContainers,
  deleteContainers,
  statePackerDry,
  statePackerReefer,
};

function stateContainersDry(bins) {
  return { type: containerConstants.STATE_CONTAINERS_DRY, bins };
}

function stateContainersReefer(bins) {
  return { type: containerConstants.STATE_CONTAINERS_REEFER, bins };
}

function statePackerDry(packerDry) {
  return { type: containerConstants.STATE_PACKER_DRY, packerDry };
}

function statePackerReefer(packerReefer) {
  return { type: containerConstants.STATE_PACKER_REEFER, packerReefer };
}

function deleteContainers() {
  return { type: containerConstants.DELETE_CONTAINERS };
}

function saveContainers(data) {
  return (dispatch) => {
    dispatch(request());

    containerService.saveContainers(data).then(
      (containers) => {
        dispatch(success(containers));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: containerConstants.SAVE_REQUEST, data };
  }
  function success(containers) {
    return { type: containerConstants.SAVE_SUCCESS, containers };
  }
  function failure(error) {
    return { type: containerConstants.SAVE_FAILURE, error };
  }
}

function getContainers(data) {
  return (dispatch) => {
    dispatch(request());

    containerService.getContainers(data).then(
      (containers) => {
        dispatch(success(containers));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: containerConstants.GET_REQUEST, data };
  }
  function success(containers) {
    return { type: containerConstants.GET_SUCCESS, containers };
  }
  function failure(error) {
    return { type: containerConstants.GET_FAILURE, error };
  }
}
