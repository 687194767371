import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const articleService = {
  fetchAllArticle,
};

function fetchAllArticle(platform, offset, limit) {
  const headers = {
    ...authHeader(),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/v2.0/product?&platform=${platform}&offset=${offset}&limit=${limit}&orderByDesc=dateCreate`,
    requestOptions
  ).then(handleResponse);
}
