import { v4 as uuid } from 'uuid';
import { deliveryPlanningService } from 'services';
import { deliveryPlanningConstants } from '../constants';

export const deliveryPlanningActions = {
  fetchItems,
  updateItem,
  deleteItem,
};

function fetchItems(filter = {}, offset = 0, limit = 100) {
  return (dispatch) => {
    dispatch(request());
    deliveryPlanningService.fetchItems(filter, offset, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: deliveryPlanningConstants.FETCH_REQUEST };
  }
  function success({ items }) {
    return { type: deliveryPlanningConstants.FETCH_SUCCESS, items };
  }
  function failure(error) {
    return { type: deliveryPlanningConstants.FETCH_FAILURE, error };
  }
}

function updateItem(item) {
  return (dispatch) => {
    const uid = uuid();
    dispatch(request({ ...item, uid }));
    deliveryPlanningService.updateItem(item).then(
      (updatedItem) => {
        dispatch(success({ ...updatedItem, uid }));
      },
      (error) => {
        dispatch(failure(error.toString()), uid);
      }
    );
  };

  function request(newItem) {
    return { type: deliveryPlanningConstants.UPDATE_REQUEST, item: newItem, isUpdate: !!item.id };
  }
  function success(updatedItem) {
    return {
      type: deliveryPlanningConstants.UPDATE_SUCCESS,
      item: updatedItem,
      isUpdate: !!item.id,
    };
  }
  function failure(error, uid) {
    return { type: deliveryPlanningConstants.UPDATE_FAILURE, error, uid };
  }
}

function deleteItem(item) {
  return (dispatch) => {
    dispatch(request());
    deliveryPlanningService.deleteItem(item).then(
      () => {
        if (item.children) {
          item.children.forEach((child) => {
            dispatch(deleteItem(child));
          });
        }
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: deliveryPlanningConstants.DELETE_REQUEST, item };
  }
  function success() {
    return { type: deliveryPlanningConstants.DELETE_SUCCESS, item };
  }
  function failure(error) {
    return { type: deliveryPlanningConstants.DELETE_FAILURE, error };
  }
}
