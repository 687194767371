import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import Dropdown from 'components/Core/Dropdown/Dropdown';
import { selectCategories } from 'selectors/category';
import './CatalogControls.scss';
import { selectCurrentPlatformId } from 'selectors/platform';
import { selectCurrentSelection } from 'selectors/aggridFilter';
import Spinner from 'components/Core/Spinner/Spinner';
import ProductCategoryMenuColumn from './ProductCategoryMenuColumn';

class ProductCategoryControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      selection: null,
    };
    this.handleDisplayNextSections = this.handleDisplayNextSections.bind(this);
    this.handleCategoryFilterClick = this.handleCategoryFilterClick.bind(this);
    this.handleSelectionFilterClick = this.handleSelectionFilterClick.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  componentDidUpdate() {
    this.initCategoryColumn();
  }

  initCategoryColumn() {
    const { currentFilters, categories } = this.props;

    if (isEmpty(currentFilters.categoryColumns) && !isEmpty(categories)) {
      this.props.setCurrentFilters({ ...currentFilters, categoryColumns: [{ items: categories }] });
    }
  }

  toggleDropDown(listOpen) {
    this.initCategoryColumn();
    this.setState({ listOpen });
  }

  handleDisplayNextSections(columnNumber, itemChildren, parentName) {
    const { setCurrentFilters, currentFilters } = this.props;
    const columns = currentFilters.categoryColumns;
    const newColumns = columns.filter((item, index) => index < columnNumber + 1);

    // newColumns.push({ items: Array.from(itemChildren), parentName });
    const itemChildrenArr = [];
    map(itemChildren, (item, index) => itemChildrenArr.push(item));
    newColumns.push({ items: itemChildrenArr, parentName });

    let selectedCategoryList = currentFilters.selectedCategoryList || [];
    selectedCategoryList = columnNumber > 0 ? selectedCategoryList.slice(0, columnNumber) : [];
    selectedCategoryList[columnNumber] = parentName;

    const newFilters = { ...currentFilters, selectedCategoryList, categoryColumns: newColumns };
    setCurrentFilters(newFilters);
    return newFilters;
  }

  handleCategoryFilterClick(filterValue, columnNumber) {
    const { selection } = this.state;
    const { handleClickFilter, setCurrentFilters, currentFilters } = this.props;
    const columns = currentFilters.categoryColumns;
    let selectedCategoryList = currentFilters.selectedCategoryList || [];
    selectedCategoryList = selectedCategoryList.slice(0, columnNumber);
    selectedCategoryList[columnNumber] = filterValue;

    const newFilters = {
      ...currentFilters,
      selection,
      category: filterValue,
      selectedCategoryList,
      categoryColumns: columns.slice(0, columnNumber + 1),
    };

    setCurrentFilters(newFilters);
    handleClickFilter(newFilters);
    this.toggleDropDown(false);
  }

  handleSelectionFilterClick(filterValue) {
    this.setState({ selection: filterValue });
  }

  compareProductName(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  render() {
    const { currentFilters } = this.props;
    const { listOpen } = this.state;
    const columns = currentFilters.categoryColumns;

    return (
      <Dropdown headerTitle="Rayons" listOpen={listOpen} toggleDropDown={this.toggleDropDown}>
        <div className="dd-list product-section-control__wrapper">
          {columns &&
            columns.map((item, index) => (
              <ProductCategoryMenuColumn
                key={`ProductCategoryMenuColumn-${index}`}
                index={index}
                items={item.items.sort(this.compareProductName)}
                parentName={index > 1 && item.parentName}
                handleDisplayNextSections={this.handleDisplayNextSections}
                handleCategoryFilterClick={this.handleCategoryFilterClick}
                handleCatalogFilterClick={this.handleCatalogFilterClick}
                handleSelectionFilterClick={this.handleSelectionFilterClick}
                currentFilters={currentFilters}
              />
            ))}
          {(!columns || columns.length < 0) && (
            <div className="spinner-icon">
              <Spinner />
            </div>
          )}
        </div>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: selectCategories(state),
    currentPlatformId: selectCurrentPlatformId(state),
    currentSelection: selectCurrentSelection(state),
  };
};

ProductCategoryControl.propTypes = {
  handleClickFilter: PropTypes.func,
  setCurrentFilters: PropTypes.func,
  currentFilters: PropTypes.shape({
    quickList: PropTypes.arrayOf(PropTypes.string),
    temperatureList: PropTypes.arrayOf(PropTypes.string),
    availabilityList: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.string,
    selectedCategoryList: PropTypes.array,
    categoryColumns: PropTypes.array,
  }),
};

export default connect(mapStateToProps, null)(ProductCategoryControl);
