export const articleGroupConstants = {
  GET_ALL_REQUEST: 'ARTICLE_GROUP_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'ARTICLE_GROUP_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'ARTICLE_GROUP_GET_ALL_FAILURE',

  ADD_REQUEST: 'ARTICLE_GROUP_ADD_REQUEST',
  ADD_SUCCESS: 'ARTICLE_GROUP_ADD_SUCCESS',
  ADD_FAILURE: 'ARTICLE_GROUP_ADD_FAILURE',

  UPDATE_REQUEST: 'ARTICLE_GROUP_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ARTICLE_GROUP_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ARTICLE_GROUP_UPDATE_FAILURE',

  GET_ALL_GROUP_ITEMS_REQUEST: 'ARTICLE_GROUP_GET_ALL_GROUP_ITEMS_REQUEST',
  GET_ALL_GROUP_ITEMS_SUCCESS: 'ARTICLE_GROUP_GET_ALL_GROUP_ITEMS_SUCCESS',
  GET_ALL_GROUP_ITEMS_FAILURE: 'ARTICLE_GROUP_GET_ALL_GROUP_ITEMS_FAILURE',

  ADD_GROUP_ITEMS_REQUEST: 'ARTICLE_GROUP_ADD_GROUP_ITEMS_REQUEST',
  ADD_GROUP_ITEMS_SUCCESS: 'ARTICLE_GROUP_ADD_GROUP_ITEMS_SUCCESS',
  ADD_GROUP_ITEMS_FAILURE: 'ARTICLE_GROUP_ADD_GROUP_ITEMS_FAILURE',

  DELETE_GROUP_ITEMS_REQUEST: 'ARTICLE_GROUP_DELETE_GROUP_ITEMS_REQUEST',
  DELETE_GROUP_ITEMS_SUCCESS: 'ARTICLE_GROUP_DELETE_GROUP_ITEMS_SUCCESS',
  DELETE_GROUP_ITEMS_FAILURE: 'ARTICLE_GROUP_DELETE_GROUP_ITEMS_FAILURE',
};
