import { brandConstants } from '../constants';

const initState = {
  items: [],
  isLoading: false,
};

export function brand(state = initState, action) {
  switch (action.type) {
    case brandConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case brandConstants.FETCH_SUCCESS:
      return {
        ...state,
        items: action.brands,
        isLoading: false,
      };
    case brandConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
