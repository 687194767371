import styled from 'styled-components';

export default (Component) => styled(Component)`
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 10px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(74, 74, 74, 0.3); /* Black w/ opacity */

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fff;
    margin: 50px auto;
    width: ${(props) => props.width || '330'}px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.2);
    border-radius: 4.5px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  .modal-component {
    padding: ${(props) => (props.padding ? '0' : '20')}px;
  }

  /* The Close Button */
  .close {
    background-color: #01366b;
    border-radius: 24px;
    height: 40px;
    width: 40px;
    line-height: 24px;
    font-size: 17px;
    position: absolute;
    text-align: center;
    top: -20px;
    right: -20px;
    padding-top: 8px;
  }
  .modal-header {
    color: ${(props) => props.color || '#01366B'};
    font-size: 18px;
    font-weight: ${(props) => props.fontWeight || 'bold'};
    line-height: 22px;
    text-align: center;
    margin: 0;
    padding: 18px 10px;
    border-bottom: ${(props) => (props.fontWeight ? '1px solid rgba(1,54,107,0.2)' : 'none')};
  }
  .icon-cross::before {
    color: white;
  }

  .close:hover,
  .close:focus {
    cursor: pointer;
  }

  .modal-header {
    span {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #01366b;
    }
  }
  @media (min-width: 320px) and (max-width: 768px) {
    .modal-content {
      width: calc(95% - 30px);
    }
  }
`;
