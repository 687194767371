import styled from 'styled-components';

export default (Component) => styled(Component)`
  .page-configuration {
    padding: 20px;
    .page-configuration__heading {
      .page-configuration__section-label {
        margin-bottom: 10px;
      }
      .page-configuration__actions {
        height: 40px;
        text-align: right;
        a {
          padding: 10px;
        }
      }
    }
  }
`;
