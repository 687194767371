import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  .page-order {
    background-color: #f5f5f5;
    padding-top: 20px;
  }
  .page-order__heading {
    padding-left: 20px;
  }
  .page-order__heading-back {
    color: #01366b;
    padding-top: 10px;
    padding-bottom: 10px;
    i {
      margin-right: 7px;
      padding-left: 5px;
    }
    span {
      text-transform: uppercase;
    }
    i,
    span {
      cursor: pointer;
    }
  }

  .page-order__container {
    height: auto;
    background-color: #f5f5f5;
    min-height: calc(100vh - 280px);
  }
  .page-order__heading {
    .page-order__heading-back {
      padding-bottom: 30px;
      padding-top: 15px;
      color: #01366b;
      i {
        margin-right: 10px;
      }
      span {
        text-transform: uppercase;
      }
    }
  }
  .container-box {
    background-color: #fff;
    margin-top: 10px;
    padding: 0 40px;
    margin-bottom: 22px;
  }
  .container__entete__info-order {
    span {
      font-size: 12px;
      letter-spacing: 0.15px;
      color: #4a4a4a;
      line-height: 19px;
    }
  }
  .container__entete {
    padding: 20px 0 10px;
    border-bottom: 1px solid rgba(1, 54, 107, 0.2);
    margin: 0 auto;
  }
  .page-name {
    color: #01366b;
    font-size: 16px;
    line-height: 19px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 11px;
    padding-left: 40px;
  }
  .page__name-client {
    display: inline-block;
    float: right;
    letter-spacing: 0.15px;
    font-size: 12px;
    padding-right: 40px;
    line-height: 19px;
    font-weight: 500;
    text-align: right;
    color: #4a4a4a;
    margin-top: 11px;
    vertical-align: middle;
  }
  .container-entete__validate {
    float: right;
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 15px;
    font-weight: 500;
    padding-top: 3px;
  }
  .container-entete__validate-label {
    color: #29d24e;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-transform: uppercase;
  }
  .container-entete__status_invoiced {
    color: #4a4a4a;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
  .container-entete__validate-date {
    display: inline-block;
    width: 100%;
  }
  .total-invoice-block {
    white-space: pre-line;
    display: inherit;
  }
  .count-invoice-block {
    white-space: pre-line;
    display: inherit;
    position: relative;
    &.dry {
      left: 80px;
      top: -25px;
    }
    &.fresh {
      left: 87px;
      top: -25px;
    }
    &.frozen {
      left: 106px;
      top: -25px;
    }

    padding-bottom: 5px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
  .container__info-section {
    padding-bottom: 20px;
    min-height: 60px;
  }
  .info-referance {
    display: inline-block;
    color: #4a4a4a;
  }
  .info-referance__ref {
    margin-top: 12px;
  }
  .info-referance-ref__icon-dry {
    background-color: #eddd0c;
    color: #ffffff;
    border-radius: 100%;
    width: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
    margin-left: 2px;
    vertical-align: middle;
  }
  .info-referance-ref__icon-apple {
    background-color: #35e687;
    color: #ffffff;
    border-radius: 100%;
    width: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
    margin-left: 3px;
  }
  .info-referance-ref__icon-snow {
    background-color: #63c8ff;
    color: #ffffff;
    border-radius: 100%;
    width: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
    margin-left: 3px;
  }
  .info-referance__ref-sec,
  .info-referance__ref-frais,
  .info-referance__ref-surgele {
    display: inline-block;
  }
  .info-price {
    float: right;
    color: #01366b;
    padding-top: 18px;
  }
  .container-box-button {
    margin-bottom: 12px;
    text-align: right;
    padding-right: 40px;
    a {
      margin-left: 5px;
      padding: 11px 26px 10px 22px;
    }
    span {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      padding-left: 7px;
      vertical-align: middle;
    }
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
    }
    .spinner__div {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 200px;
    }
  }
  .button__icon-block {
    width: 9%;
    font-size: 16px;
    margin-right: 5px;
    display: inline-block;
  }
  .button__span-block {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    letter-spacing: 1.06px;
  }

  .btn-secondary {
    padding-bottom: 9px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: #00a6ff;
  }

  .btn-primary {
    padding: 13px 20px;
    color: #ffffff;
    span {
      margin-left: 5px;
    }
  }

  .container__button-validation {
    padding: 30px 20px;
    display: flex;
    justify-content: flex-end;
    .container__button-validation-spinner {
      padding-right: 70px;
    }
  }

  .container_reminder {
    background: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 15px 26px;
    color: #01366b;
    .container_reminder_alert {
      margin-top: 10px;
    }
  }
  /**********Responsive********/

  @media ${screen.tablet} {
    .container-box-button a:first-child {
      margin-bottom: 15px;
    }
  }

  @media screen and (width: 544px) {
    .container-box-button a:first-child {
      margin-bottom: 15px;
    }
  }
  @media screen and (width: 320px) {
    .page-name {
      width: 100%;
      padding-left: 20px;
    }
    .container-box {
      padding: 0 20px;
    }
    .container-entete__validate {
      float: none;
      order: 5;
      text-align: right;
    }
    .container-entete__validate-date,
    .container__info-section {
      display: flex;
      flex-direction: column;
    }
    .info-referance {
      display: block;
      order: 2;
    }
    .info-price {
      text-align: right;
    }
    .container-box-button {
      padding-right: 20px;
      a:first-child {
        margin-bottom: 15px;
      }
    }
  }
  .header_product-wording {
    div:not(:first-child) {
      margin-left: 3px;
    }
  }
`;
