import styled from 'styled-components';

export const AddCreditFormWrapper = styled.div`
  .add-credit-form__title {
    font-weight: bold;
  }

  .add-credit-form__quantity-container {
    display: flex;
    align-items: center;
    margin: 10px 0;

    label {
      margin-right: 10px;
    }

    .add-credit-form__quantity-handler {
      margin-right: 10px;
    }

    .add-credit-form__reason-container {
      width: 100%;

      select {
        width: 100%;
        border: 1px solid #01366b;
        height: 25px;
        ligne-height: 25px;
        color: ${({ selectValue }) => (selectValue === null ? '#01366B' : '')};
        .add-credit-form__reason-list__disabled {
          color: #cccccc;
        }
      }
    }
  }

  .add-credit-form__comment-container {
    padding: 10px 0;
    textarea {
      width: 100%;
      height: 110px;
      padding: 5px;
      border: 1px solid #01366b;
    }
  }

  .add-credit-form__file-input-container {
    margin-bottom: 10px;
    input[type='file'] {
      display: none;
    }

    .add-credit-form__file-input-label {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        fill: #01366b;
        margin-right: 5px;
      }
    }
  }

  .add-credit-form__litigation {
    font-size: 13px;
    input[type='checkbox'] {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin: 0;
      margin-right: 5px;
      appearance: checkbox;
      position: relative;
      bottom: -3px;
    }
  }

  .add-credit-form__buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 20px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .add-credit-form__cancel-button {
      background-color: #65676b;
      margin-right: 20px;
    }

    .add-credit-form__submit-button {
      background-color: #01366b;
    }
  }

  .form__error-message {
    color: red;
  }
`;
