export const GROUP_BY = {
  cellIndex: null,
  comparisonDataKey: null,
};

export const COLUMNS = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Client',
    className: 'header_order-number header_clientInfo',
    dataType: 'client_informations',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Enseigne',
    className: 'header_order-number header_brand',
    dataType: 'brand.name',
  },
  {
    type: 'CLIENT_STATUS',
    name: 'Permanent',
    className: 'header_product-pcb permenant',
    dataType: 'status_catalog',
  },
  {
    type: 'CLIENT_STATUS',
    name: 'Precommande',
    className: 'header_product-pcb preco',
    dataType: 'status_preorder',
  },
  {
    type: 'CLIENT_BUTTONS',
    name: '',
    className: 'header_product-default header_action',
  },
];

export const ASSORTMENT_COLUMNS = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Réferences',
    className: 'header_order-number header_clientInfo',
    dataType: 'assortment_reference',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Nom de la Sélection',
    className: 'header_order-number header_brand',
    dataType: 'assortment_name',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Date de fin de validité',
    className: 'header_product-pcb',
    dataFormat: 'date',
    dataType: 'date_end',
    style: {
      isSmall: true,
    },
  },
  {
    type: 'ASSORTMENT_ACTIONS',
    name: 'Actions',
    className: 'header_message-action',
  },
];
