import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history, getPageFromUrl, PAGE_URL_KEY } from 'helpers';
import { mapStateToProps } from '../mapToProps';

import paginationWithStyle from './Pagination.style';

const PAGINATION_DOTS_STRING = '...';

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: Number(getPageFromUrl()) || 1,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getCollection = this.getCollection.bind(this);
  }

  componentDidMount() {
    history.listen(() => {
      const { currentPage: statePage } = this.state;
      const currentPage = getPageFromUrl();
      if (String(currentPage) !== String(statePage)) {
        this.handlePageChange(currentPage);
      }
    });
  }

  getCollection() {
    const { collectionCount } = this.props;
    return collectionCount;
  }

  getPaginationList() {
    const { currentPage } = this.state;
    const { rowsByPage } = this.props;
    const { totalRowsNumber } = this.getCollection();

    if (totalRowsNumber) {
      const numberOfPages = Math.ceil(Number(totalRowsNumber) / Number(rowsByPage));

      const delta = 10;
      const step = 3;
      const range = [];
      const rangeWithDots = [];

      if (numberOfPages <= 1) {
        return range;
      }

      for (let i = 1; i <= numberOfPages; i += 1) {
        range.push(i);
      }

      range.forEach((i) => {
        if (currentPage < step) {
          if (i === numberOfPages) {
            rangeWithDots.push(i);
          } else if (i < delta) {
            rangeWithDots.push(i);
          } else if (i === delta) {
            rangeWithDots.push(PAGINATION_DOTS_STRING);
          }
        } else if (currentPage > numberOfPages - step) {
          if (i === 1) {
            rangeWithDots.push(i);
          } else if (i > numberOfPages - delta) {
            rangeWithDots.push(i);
          } else if (i === numberOfPages - delta) {
            rangeWithDots.push(PAGINATION_DOTS_STRING);
          }
        } else if (i === 1 || i === numberOfPages) {
          rangeWithDots.push(i);
        } else if (i >= currentPage - step && i <= currentPage + step) {
          rangeWithDots.push(i);
        } else if (i === numberOfPages - 1 || i === 2) {
          rangeWithDots.push(PAGINATION_DOTS_STRING);
        }
      });
      return rangeWithDots;
    }

    return [];
  }

  generatePagination() {
    const pages = this.getPaginationList();
    const { currentPage } = this.state;

    return pages.map((pageNumber) => {
      if (pageNumber === PAGINATION_DOTS_STRING) {
        return (
          <Link
            key={pageNumber}
            className="pagination_disabled"
            to={`?${PAGE_URL_KEY}=${currentPage}`}
          >
            {pageNumber}
          </Link>
        );
      }
      const className =
        Number(currentPage) === Number(pageNumber) ? 'pagination_selected' : 'pagination_enabled';
      return (
        <Link key={pageNumber} className={className} to={`?${PAGE_URL_KEY}=${pageNumber}`}>
          {pageNumber}
        </Link>
      );
    });
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (Number(pageNumber) !== Number(currentPage)) {
      this.setState({
        currentPage: Number(pageNumber),
      });
    }
  }

  render() {
    const pagination = this.generatePagination();
    const { className } = this.props;
    return (
      <div className={className} hidden={pagination.length === 0}>
        {pagination}
      </div>
    );
  }
}

Pagination.propTypes = {
  rowsByPage: PropTypes.number,
  className: PropTypes.string,
  collectionCount: PropTypes.object,
};

Pagination.defaultProps = {
  rowsByPage: 10,
};

export default connect(mapStateToProps)(paginationWithStyle(Pagination));
