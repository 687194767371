import styled from 'styled-components';

export default (Component) => styled(Component)`
  .title {
    .bold {
      color: #01366b;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
      padding: 11px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      color: #4a4a4a;
    }
  }
  .file {
    a {
      height: 40px;
      padding: 10px 20px;
    }
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    input {
      display: none;
    }
  }
  .oldFile {
    text-align: center;
    span {
      color: #01366b;
      padding-right: 10px;
    }
    i {
      color: #01366b;
    }
    margin-bottom: 15px;
  }
  .validate {
    a {
      height: 40px;
      padding: 10px 20px;
    }
    margin: 0px auto;
    text-align: center;
  }
  .page-profil__alert-bloc {
    width: 100%;
    .alert-bloc__label {
      display: block;
      color: #d0021b;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
      text-align: center;
      &:before {
        content: '*';
      }
    }
  }
`;
