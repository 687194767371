import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const moqService = {
  upload,
  fetchItems,
  validate,
};

function upload(platformId, file) {
  const body = new FormData();
  body.append('file', file);
  body.append('platform', platformId);
  const headers = {
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'POST',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/item/moq/upload`, requestOptions).then(handleResponse);
}

function fetchItems() {
  const headers = {
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'GET',
    headers,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/cart/moq`, requestOptions).then(handleResponse);
}

function validate(quantitiesByCartItem) {
  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const body = JSON.stringify({
    ...quantitiesByCartItem,
  });
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(`${config.apiUrl}/api/cart/moq/validate`, requestOptions).then(handleResponse);
}
