import { cartConstants } from './Cart.constants';
import { userConstants } from './User.constants';

export const loadingConstants = {
  UPDATE_STATUS: 'LOADING_UPDATE_STATUS',
  UPDATE_USER_TYPE: 'LOADING_UPDATE_USER_TYPE',
  STATUS_STARTED: 'LOADING_STATUS_STARTED',
  STATUS_LOADED: 'LOADING_STATUS_LOADED',
  STATUS_ERROR: 'LOADING_STATUS_ERROR',
  NEEDED_TO_DISPLAY_WEB_SITE: {
    [userConstants.TYPE_CUSTOMER]: [
      'user',
      'platform',
      `cart_${cartConstants.CART_STATUS_CURRENT}`,
      `cart_${cartConstants.CART_STATUS_CURRENT_PREORDER}`,
      'message',
    ],
    [userConstants.TYPE_COMMERCIAL]: ['user', 'platform'],
    [userConstants.TYPE_ADMIN]: ['user', 'platform'],
    [userConstants.TYPE_SUPER_ADMIN]: ['user', 'platform'],
  },
};
