import { articleConstants } from 'constants/Article.constants';

const initState = {
  data: [],
  isLoading: false,
  error: null,
};

export function article(state = initState, action) {
  switch (action.type) {
    case articleConstants.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case articleConstants.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
