import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { modalActions } from 'actions';
import { clientStatusConstants } from 'pages/../constants';
import { ButtonP } from 'components/Core/Utils/Navigation/Header/Header.style';

const { STATUS_BLOCKED, STATUS_INACTIVE } = clientStatusConstants;

const ClientButtonTD = styled.td`
  .button-container__btn {
    height: 39px;
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button-content {
    margin: auto;
    font-size: 16px;
    padding: 0 20px;
  }
  .button-content__title {
    vertical-align: middle;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 15px;
    text-align: left;
    padding-left: 8px;
    white-space: nowrap;
  }
  .button-container__btn {
    margin-right: 10px;
  }
  .button__icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    padding: 0 4px;
  }
  .button-selection {
    padding: 4px 22px 4px 20px;
    .button-content__title {
      padding-left: 5px;
    }
  }
`;

// const ClientButtonDIV = styled.div`
// // Override the width specified in the cell class name
// width: unset!important;

// display: inline-block;
// .button-content {
//   margin: auto;
//   font-size: 16px;
//   padding: 0 20px;
// }
// .button-content__title {
//   padding: 0px 10px;
//   font-size: 12px;
//   line-height: 15px;
//   font-family: Montserrat;
//   font-weight: bold;
//   display: inline-block;
//   position: relative;
//   bottom: 22px;
//   padding-left: 24px;

// }
// .button-container__btn {
//   display: inline-block;
//   width: 160px;
//   margin-right: 10px;
//   padding-bottom: 10px;
// }
// .button__icon {
//   width: 24px;
//     display: inline-block;
//     padding-top: 7px;
//     margin-left: -10px;
// }
// `;

const ClientButtons = ({
  className,
  shouldShowResponsive,
  openRedirectModal,
  username,
  clientStatus: clientStatusList,
  selectedPlatform,
  ...rest
}) => {
  const clientStatus = (clientStatusList || []).find(
    (status) => status.platform && Number(status.platform.id) === selectedPlatform
  );
  const { status_preorder: statusPreorder, status_catalog: statusCatalog, status } =
    clientStatus || {};
  const isClientNotActive =
    !clientStatus ||
    status === STATUS_INACTIVE ||
    (statusPreorder === STATUS_BLOCKED && statusCatalog === STATUS_BLOCKED);

  return (
    <ClientButtonTD className={className} {...rest}>
      <div className="button-container">
        <ButtonP
          inverted
          className="button-container__btn header__button-express-order"
          disabled={isClientNotActive}
          onClick={(e) => (!isClientNotActive ? openRedirectModal(e, username) : {})}
        >
          <Icon content="\e910" className="button__icon" />
          <span className="button-content__title">Espace client</span>
        </ButtonP>
      </div>
    </ClientButtonTD>
  );
};

const mapStateToProps = (state) => {
  const { platform } = state;
  const selectedPlatform = Number(platform.selectedId) || null;
  return { selectedPlatform };
};

const mapDispatchToProps = (dispatch) => {
  const modalType = 'redirect';
  const modalProps = {
    modalHeader: '',
  };
  return {
    openRedirectModal: (e, username) => {
      e.stopPropagation();
      return dispatch(modalActions.open(modalType, { ...modalProps, username }));
    },
  };
};

ClientButtons.propTypes = {
  shouldShowResponsive: PropTypes.bool,
  className: PropTypes.string,
  username: PropTypes.string,
  openRedirectModal: PropTypes.func,
  clientStatus: PropTypes.array,
  selectedPlatform: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientButtons);
