/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';

const Address = ({ address, complementaryAddress, zipCode, city, country }) => (
  <div className="page-profil__block-info">
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e927" />
      <span className="entete__label">Mon Adresse</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut">Adresse :</span>
        <span className="ligne__value">{address}</span>
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Complément d'adresse :</span>
        <span className="ligne__value">{complementaryAddress}</span>
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">C.P et Ville : </span>
        <span className="ligne__value">{`${zipCode || ''} ${city || ''}`.trim()}</span>
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Pays : </span>
        <span className="ligne__value">{country}</span>
      </div>
    </div>
  </div>
);
Address.propTypes = {
  country: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  complementaryAddress: PropTypes.string,
  address: PropTypes.string,
};
export default Address;
