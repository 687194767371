import styled, { keyframes } from 'styled-components';
import screen from 'components/Core/Utils/Device';

const fadeIn = keyframes`
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 60px;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    top: 60px;
    opacity: 1;
  }
  to {
    top: -100px;
    opacity: 0;
  }
`;

const subFadeIn = keyframes`
  from {
    height: 0;
  }
  to {
    height: 140px;
  }
`;

const subFadeOut = keyframes`
  from {
    height: 140px;
  }
  to {
    height: 0;
  }
`;

export const HamburgerMenuItem = styled.div`
  display: flex;
  height: 100%;
  padding: ${(props) => (props.noPaddingBottom ? '20px 10px 0 10px' : '20px 10px')};
  margin: 0 10px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  color: ${(props) => (props.active ? '#01366B' : 'none')};
  &:hover {
    color: #01366B;
  }
  i {
    font-size: 22px
    margin-right: 10px;
  }
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
  span {
    text-align: center;
  }
`;
export const HamburgerSubMenuItem = styled.div`
  a {
    color: #4a4a4a;
    text-decoration: none;
  }
`;

export const HamburgerTitleWrapper = styled.div`
  height: 20px;
  width: 30px;

  position: relative;
  .hamburger__title-container__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 1px;
    border: 1px solid #01366B;
    opacity: ${(props) => (props.listOpen ? '0' : '1')}
    transform-origin: center;
    transition: transform 0.3s linear, opacity 0.2s linear, top 0.1s linear;
  }

  .hamburger__title-container__line:first-child {
    opacity: 1;
    top: ${(props) => (props.listOpen ? '50%' : '0')};
    transform: 
      rotate(${(props) => (props.listOpen ? '45deg' : '0')})
    ;
  }
  .hamburger__title-container__line:last-child {
    opacity: 1;
    bottom: 0;
    top: ${(props) => (props.listOpen ? '50%' : '100%')};
    transform: 
      rotate(${(props) => (props.listOpen ? '-45deg' : '0')})
    ;
  }
`;

export const DropdownWrapper = styled.div`
  margin-right: auto;

  .dd-wrapper {
    .dd-header {
      padding: 5px !important;
    }
    .dd-panel-wrapper {
      width: 100vw;
      height: 0;
      position: fixed;
      top: ${(props) => (props.listOpen ? '60px' : '-100px')};
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      visibility: ${(props) => (props.listOpen ? 'visible' : 'hidden')};
      animation: ${(props) => (props.listOpen ? fadeIn : fadeOut)} 0.2s linear;
      transition: visibility 0.2s linear;
    }
  }
`;

export const SubDropdownWrapper = styled.div`
  .dd-wrapper {
    flex-direction: column;
    .dd-header {
      justify-content: center;
      padding-bottom: 20px !important;
    }
    .dd-panel-wrapper {
      width: 100vw;
      z-index: 1;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      height: ${(props) => (props.listOpen === true ? '140' : '0')}px;
      position: initial;
      visibility: ${(props) => (props.listOpen === true ? 'visible' : 'hidden')};
      animation: ${(props) => (props.listOpen === true ? subFadeIn : subFadeOut)} 0.2s linear;
      transition: visibility 0.2s linear;
      transform: translateX(0);
      .dd-list {
        height: 100%;
        box-shadow: inset 0 1px 16px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 16px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
`;
