import { ocrOrderPdfService } from 'services';
import { ocrOrderPdfConstants } from '../constants';

export const ocrOrderPdfActions = {
  fetchAllOcrJob,
  addOcrJob,
  deleteOcrJob,
  fetchOcrJobResult,
  updateOcrJob,
};

function addOcrJob(clientId, file) {
  return (dispatch) => {
    dispatch(request());

    ocrOrderPdfService.addOcrJob(clientId, file).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: ocrOrderPdfConstants.ADD_JOB_REQUEST };
  }
  function success(data) {
    return { type: ocrOrderPdfConstants.ADD_JOB_SUCCESS, data };
  }
  function failure(error) {
    return { type: ocrOrderPdfConstants.ADD_JOB_FAILURE, error };
  }
}

function fetchAllOcrJob(offset, limit) {
  return (dispatch) => {
    dispatch(request());

    ocrOrderPdfService.fetchAllOcrJob(offset, limit).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: ocrOrderPdfConstants.GET_ALL_JOB_REQUEST };
  }
  function success(data) {
    return { type: ocrOrderPdfConstants.GET_ALL_JOB_SUCCESS, data };
  }
  function failure(error) {
    return { type: ocrOrderPdfConstants.GET_ALL_JOB_FAILURE, error };
  }
}

function deleteOcrJob(jobId) {
  return (dispatch) => {
    dispatch(request());

    ocrOrderPdfService.deleteOcrJob(jobId).then(
      () => {
        dispatch(success(jobId));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: ocrOrderPdfConstants.DELETE_JOB_REQUEST };
  }
  function success(jobId) {
    return { type: ocrOrderPdfConstants.DELETE_JOB_SUCCESS, jobId };
  }
  function failure(error) {
    return { type: ocrOrderPdfConstants.DELETE_JOB_FAILURE, error };
  }
}

function fetchOcrJobResult(jobId) {
  return (dispatch) => {
    dispatch(request());

    ocrOrderPdfService.fetchOcrJobResult(jobId).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: ocrOrderPdfConstants.GET_JOB_RESULT_REQUEST };
  }
  function success(data) {
    return { type: ocrOrderPdfConstants.GET_JOB_RESULT_SUCCESS, data };
  }
  function failure(error) {
    return { type: ocrOrderPdfConstants.GET_JOB_RESULT_FAILURE, error };
  }
}

function updateOcrJob(jobId, data) {
  return (dispatch) => {
    dispatch(request());

    ocrOrderPdfService.updateOcrJob(jobId, data).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: ocrOrderPdfConstants.UPDATE_JOB_REQUEST };
  }
  function success(data) {
    return { type: ocrOrderPdfConstants.UPDATE_JOB_SUCCESS, data };
  }
  function failure(error) {
    return { type: ocrOrderPdfConstants.UPDATE_JOB_FAILURE, error };
  }
}
