export const assortmentConstants = {
  CREATE_REQUEST: 'ASSORTMENT_CREATE_REQUEST',
  CREATE_SUCCESS: 'ASSORTMENT_CREATE_SUCCESS',
  CREATE_FAILURE: 'ASSORTMENT_CREATE_FAILURE',

  FETCH_REQUEST: 'ASSORTMENT_FETCH_REQUEST',
  FETCH_SUCCESS: 'ASSORTMENT_FETCH_SUCCESS',
  FETCH_FAILURE: 'ASSORTMENT_FETCH_FAILURE',

  FETCH_COUNT_REQUEST: 'ASSORTMENT_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'ASSORTMENT_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'ASSORTMENT_COUNT_FETCH_FAILURE',

  DELETE_REQUEST: 'ASSORTMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'ASSORTMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'ASSORTMENT_DELETE_FAILURE',

  UPDATE_REQUEST: 'ASSORTMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ASSORTMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ASSORTMENT_UPDATE_FAILURE',

  UPLOAD_REQUEST: 'ASSORTMENT_UPLOAD_REQUEST',
  UPLOAD_SUCCESS: 'ASSORTMENT_UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'ASSORTMENT_UPLOAD_FAILURE',

  UPDATE_STORE: 'ASSORTMENT_UPDATE_STORE',
  RESET_STORE: 'ASSORTMENT_RESET_STORE',
};
