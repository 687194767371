import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body {
    margin: 30px;
    text-align: center;
    .modal-body__text-block {
      padding: 0px 40px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
    }

    .modal-body__button-block {
      margin-top: 35px;
      a {
        padding: 13px;
        letter-spacing: 1.2px;
      }
      a:nth-child(1) {
        margin-right: 20px;
      }
      a:nth-child(2) {
      }
    }
  }
`;
