import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  bottom: 0;
  width: 100%;
  #sticky-block {
    background: #fff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    padding-top: 8px;
  }

  .cart-footer-comment {
    padding: 20px;
    background-color: #ffffff;
    width: 100%;
    display: inline-block;
    span:first-child {
      color: #4a4a4a;
      line-height: 18px;
      width: 15%;
      display: inline-block;
    }
    span:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 15px;
    }
  }
  .cart-footer-commercial {
    background-color: #ffffff;
    text-align: right;
    padding: 14px 20px;
    span {
      color: #01366b;
      line-height: 22px;
      font-size: 18px;
    }
  }
  .footer-block {
    display: inline-block;
    color: #01366b;
    width: 100%;
    background-color: #ffffff;
    text-align: center;
    box-shadow: 0 0 0 0 transparent, 0px 5px 15px rgba(0, 0, 0, 0.5);
    .footer-block__link {
      padding: 0 20px;
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .home__section__footer-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        a {
          display: flex;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 18px;
          margin: 0 30px;

          .footer__button-content {
            display: flex;
            align-items: center;
          }

          i {
            font-size: 24px;
          }
          .footer__button-title {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .footer__button-title-small {
              font-size: 10px;
              line-height: 13px;
              font-weight: 400px;
            }
          }
        }
      }
      .footer-client_button-container {
        display: flex;
        align-items: center;
        margin-left: auto;
        .footer__button-container {
          height: 40px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          margin-right: 5px;
          min-width: 160px;
          a {
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0;
            margin-left: 10px;
            text-align: left;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
    .footer-block__span-information {
      span {
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
      }
    }
  }
  .home__section__footer {
    width: 100%;
    padding: 10px 22px;
    min-height: 60px;
    .home__section__footer-errors {
      width: 100%;
      text-align: right;
      padding-top: 10px;
      .alert-bloc__label {
        display: block;
        color: #d0021b;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.15px;
        line-height: 15px;
        padding-bottom: 10px;
        &:before {
          content: '*';
        }
      }
    }
  }
  .permanent-cart__total-details {
    background-color: white;
    padding: 30px;
  }

  .permanent-cart__Total {
    color: #01366b;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    margin-right: 60px;
  }

  .permanent-cart__Total--TTC {
    padding-right: 30px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .permanent-cart__text {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: bold;
    margin-left: 32px;
    vertical-align: middle;
    .underline {
      text-decoration: underline;
      color: #4a4a4a;
    }
  }

  .container {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid rgba(120, 120, 120, 0.54);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid #01366b;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg) translateX(-3px);
    -ms-transform: rotate(45deg) translateX(-3px);
    transform: rotate(45deg) translateX(-3px);
  }
  .footer__message-block {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    padding-top: 10px;
  }
  .permanent-cart__button-container {
    display: inline-block;
    float: right;
  }

  .permanent-cart__button-span {
    vertical-align: middle;
  }

  .permanent-cart__spinner {
    vertical-align: middle;
    display: inline-block;
  }

  .permanent-cart__button {
    letter-spacing: 2px;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
    padding: 12px 14px;
    i {
      padding-left: 5px;
    }
  }
  span.total {
    padding-right: 35px;
  }
  .button-icon-label {
    margin: 0 8px;
    font-size: 12px;
    letter-spacing: 1.06px;
  }
  .insertstickyCard {
    #sticky-block {
      width: 100%;
      position: relative !important;
      top: 0;
      left: inherit !important;
      padding-top: 0;
      .permanent-cart__Total {
        display: none;
      }
      .home__section__footer {
        width: 100%;
        padding: 0 10px 10px 10px;
        .footer__message-block {
          padding-top: 8px;
          margin-bottom: 15px;
        }
        .permanent-cart__Total--TTC {
          display: none;
        }
        .home__section__footer-errors {
          text-align: center;
          .alert-bloc__label {
            padding-bottom: 5px;
          }
        }
      }
      .permanent-cart__button-container {
        float: inherit;
        display: inherit;
      }
      .permanent-cart__button-span {
        text-align: center;
        display: block;
        a {
          padding: 4px 7px;
          margin-bottom: 10px;
          .button-icon-label {
            font-size: 17px;
          }
        }
      }
    }
  }

  @media screen and ${screen.tabletR} {
    .home_section__preorder-outstanding {
      display: none;
    }
  }

  @media screen and ${screen.tablet} {
    .permanent-cart__Total {
      margin-right: 0;
    }
    .cart-footer-comment {
      span:first-child {
        width: 30%;
        display: inline-block;
      }
      span:nth-child(2) {
        width: 70%;
        display: inline-block;
      }
    }
    .permanent-cart__button-span {
      text-align: right;
    }
  }
  @media screen and ${screen.mobileL} {
    .footer-client_button-container {
      margin-right: auto;
    }
    .permanent-cart__button-container {
      margin-top: 20px;
    }
    .footer-block {
      .footer-block__link {
        .home__section__footer-link {
          a {
            display: block;
            text-align: left;
          }
        }
      }
    }
  }
  @media screen and ${screen.mobile} {
    .permanent-cart__button-container {
      text-align: right;
      .permanent-cart__button-span {
        margin-top: 20px;
      }
    }
  }
`;
