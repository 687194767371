import React from 'react';

import Icon from 'components/Icon/Icon';
import withStyle from './ProspectBloc.styles';

const Validity = ({ className, accessDayBegin, accessDayEnd, handleChange }) => (
  <div className={className}>
    <div className="block-info__entete">
      <Icon className="entete__icon" content="\e90e" />
      <span className="entete__label">Date de validité d&apos;accès</span>
    </div>
    <div className="block-info__content">
      <div className="content__ligne">
        <span className="bold ligne__attribut">De :</span>
        <input
          className="ligne__value-input ligne__value"
          name="accessDayBegin"
          onChange={handleChange}
          value={accessDayBegin}
          type="date"
          required
        />
        <Icon content="\e90e" />
      </div>
      <div className="content__ligne">
        <span className="bold ligne__attribut">Au :</span>
        <input
          className="ligne__value-input ligne__value"
          name="accessDayEnd"
          onChange={handleChange}
          value={accessDayEnd}
          type="date"
          required
        />
        <Icon content="\e90e" />
      </div>
    </div>
  </div>
);

export default withStyle(Validity);
