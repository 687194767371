import styled from 'styled-components';

export default (Component) => styled(Component)`
  padding: 22px 0px 20px 0px;
  width: calc(100% - 45px);
  margin: 0 auto;

  .page-terms__content {
    margin-top: 28px;
  }

  .switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 27px;
    margin: 5px 0;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .react-table__wrapper {
    border: 0;
    border-radius: 4.5px;
    border: none;
    max-height: calc(100vh - 226px);
    overflow: auto;
    box-shadow: none;

    .react-table__row-counter {
      background: #dbdee7;
      color: #01366b;
      font-weight: 600;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #fefefe;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }

    .page-commercial__total-container {
      display: flex;
      color: #01366b;
      white-space: nowrap;
    }

    .page-message__table__actions {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    table {
      border: 0;
      box-shadow: none;

      thead {
        color: #01366b;

        th {
          white-space: nowrap;
          background: #dbdee7;
          border-right: 1px solid #fff;
        }
        th:last-child {
          border-right: none;
        }
      }

      tbody {
        background: #fff;

        .icon-bdc {
          font-size: 29px;
          cursor: pointer;
        }

        tr {
          cursor: pointer;
          &:hover {
            background: rgb(249, 250, 251);
          }

          td {
            padding: 9px 24px;
          }
          td.actions {
            text-align: center;
            .edit {
              padding: 7px;
              cursor: pointer;
              font-weight: bold;
              color: #01366b;
              border-radius: 4.5px;
              background: #ededed;
              font-size: 12px;
            }
          }
        }

        .react-table__no-result {
          cursor: default;
        }
      }
    }
  }

  .page-terms__heading {
    .page-terms__section-label {
      padding-left: 20px;
      span {
        color: #01366b;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .page-terms__actions {
    text-align: right;
    padding: 11px 0;
    .page-terms___add-button {
      color: #fff;
      background-color: #01366b;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      padding: 11px 17px;

      i {
        font-size: 32px;
        padding-right: 5px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
`;
