import React from 'react';
import { getPath, history } from 'helpers';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { AiOutlineEnter } from '@react-icons/all-files/ai/AiOutlineEnter';
import { AiOutlineRobot } from '@react-icons/all-files/ai/AiOutlineRobot';
import { categoryConstants } from '../../constants';
import withInvoiceOrderListControlStyle from './InvoiceOrderListControl.style.jsx';

const InvoiceOrderListControl = ({
  className,
  match,
  filters,
  toggleItem,
  handleSearchEnterPress,
}) => {
  const catalogTypeList = [
    { title: 'Permanent', value: categoryConstants.STATUS_ORDER },
    { title: 'Précommande', value: categoryConstants.STATUS_PREORDER },
  ];

  return (
    <div className={className}>
      <div className="onglet-menu">
        <ul>
          <li
            className={`invoice-order-list__button ${
              match.path === getPath('orderList') && 'active'
            }`}
          >
            <span
              type="button"
              onClick={() => {
                history.push(getPath('orderList'));
              }}
            >
              <i className="icon-bdc" /> Commandes
            </span>
          </li>
          <li
            className={`invoice-order-list__button ${
              match.path === getPath('invoiceList') && 'active'
            }`}
            active={match.path === getPath('invoiceList')}
          >
            <span
              type="button"
              onClick={() => {
                history.push(getPath('invoiceList'));
              }}
            >
              <i className="icon-fac" /> Factures
            </span>
          </li>
        </ul>
      </div>
      <div className="invoice-order-list__table-controls">
        <div className="invoice-order-list__search-container">
          <input
            type="text"
            placeholder="Rechercher"
            onKeyPress={handleSearchEnterPress}
            className="search-box"
          />
          <AiOutlineEnter />
        </div>
        <div className="invoice-order-list__table-controls__button-container">
          {filters && toggleItem && (
            <div className="invoice-order-list__preorder-filter">
              <ul>
                <li className="dd-list-item">
                  (<AiOutlineRobot />) Commande automatique
                </li>
                {catalogTypeList &&
                  catalogTypeList.map((item, key) => (
                    <li className="dd-list-item" key={key}>
                      <label>
                        <input
                          onChange={() => {
                            toggleItem(item.value);
                          }}
                          checked={filters.includes(item.value)}
                          type="checkbox"
                        />
                        {item.value === categoryConstants.STATUS_PREORDER && (
                          <Icon content="\e941" />
                        )}
                        {item.value === categoryConstants.STATUS_ORDER && <Icon content="\e94c" />}
                        Catalogue {item.title.toLowerCase()}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InvoiceOrderListControl.propTypes = {
  match: PropTypes.object,
  filters: PropTypes.array,
  toggleItem: PropTypes.func,
  handleSearchEnterPress: PropTypes.func,
};

export default withInvoiceOrderListControlStyle(InvoiceOrderListControl);
