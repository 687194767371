import { recurrentCartService } from 'services/RecurrentCart';
import { recurrentCartConstants } from 'constants/RecurrentCart.constants';

export const recurrentCartActions = {
  fetchRecurrentCartList,
  createRecurrentCart,
  updateRecurrentCart,
  deleteRecurentCart,
  toggleRecurentCartActive,
};

function fetchRecurrentCartList(offset, limit) {
  return (dispatch) => {
    dispatch(request());
    return recurrentCartService.fetchRecurrentCartList(offset, limit).then(
      (data) => {
        dispatch(success(data));

        return data;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: recurrentCartConstants.FETCH_REQUEST };
  }

  function success(data) {
    return { type: recurrentCartConstants.FETCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: recurrentCartConstants.FETCH_FAILURE, error };
  }
}

function createRecurrentCart(data, days, successCallback) {
  return (dispatch) => {
    days.map((day) => {
      dispatch(request());
      return recurrentCartService.createRecurentCart({ ...data, day }).then(
        (recurrentCart) => {
          dispatch(success(recurrentCart));

          if (successCallback) {
            successCallback();
          }

          return recurrentCart;
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    });
  };

  function request() {
    return { type: recurrentCartConstants.CREATE_REQUEST };
  }

  function success(data) {
    return { type: recurrentCartConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: recurrentCartConstants.CREATE_FAILURE, error };
  }
}

function toggleRecurentCartActive(recurrentCartId, isActive) {
  return (dispatch) => {
    dispatch(request());
    return recurrentCartService.updateRecurrentCart({ id: recurrentCartId, active: isActive }).then(
      (recurrentCart) => {
        dispatch(success(recurrentCart));

        return recurrentCart;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: recurrentCartConstants.UPDATE_REQUEST };
  }

  function success(recurrentCart) {
    return { type: recurrentCartConstants.UPDATE_SUCCESS, recurrentCart };
  }
  function failure(error) {
    return { type: recurrentCartConstants.UPDATE_FAILURE, error };
  }
}

function deleteRecurentCart(recurrentCartId) {
  return (dispatch) => {
    dispatch(request());
    return recurrentCartService.updateRecurrentCart({ id: recurrentCartId, status: 0 }).then(
      (recurrentCart) => {
        dispatch(success(recurrentCart));

        return recurrentCart;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: recurrentCartConstants.DELETE_REQUEST };
  }

  function success() {
    return { type: recurrentCartConstants.DELETE_SUCCESS, recurrentCartId };
  }
  function failure(error) {
    return { type: recurrentCartConstants.DELETE_FAILURE, error };
  }
}

function updateRecurrentCart(recurrentCartId, data) {
  return (dispatch) => {
    dispatch(request());
    return recurrentCartService.updateRecurrentCart({ id: recurrentCartId, ...data }).then(
      (recurrentCart) => {
        dispatch(success(recurrentCart));

        return recurrentCart;
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: recurrentCartConstants.UPDATE_REQUEST };
  }

  function success(recurrentCart) {
    return { type: recurrentCartConstants.UPDATE_SUCCESS, recurrentCart };
  }
  function failure(error) {
    return { type: recurrentCartConstants.UPDATE_FAILURE, error };
  }
}
