import { recurrentCartConstants } from 'constants/RecurrentCart.constants';

const initState = {
  data: [],
  isLoading: false,
  error: null,
};

export function recurrentCart(state = initState, action) {
  switch (action.type) {
    case recurrentCartConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case recurrentCartConstants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: null,
      };
    case recurrentCartConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case recurrentCartConstants.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case recurrentCartConstants.CREATE_SUCCESS:
      return {
        ...state,
        data: [action.data, ...state.data],
        isLoading: false,
      };
    case recurrentCartConstants.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case recurrentCartConstants.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case recurrentCartConstants.DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.recurrentCartId),
        isLoading: true,
        error: null,
      };
    case recurrentCartConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case recurrentCartConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case recurrentCartConstants.UPDATE_SUCCESS: {
      const recurrentCartIndex = state.data.findIndex(
        (recurrentCart) => recurrentCart.id === action.recurrentCart.id
      );
      const newRecurrentCartList = [...state.data];
      newRecurrentCartList[recurrentCartIndex] = { ...action.recurrentCart };

      return {
        ...state,
        data: newRecurrentCartList,
        isLoading: false,
      };
    }
    case recurrentCartConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
