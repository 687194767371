import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pull from 'lodash.pull';
import { aggridSearchBarActions } from 'actions/AggridSearchBar';
import { searchActions } from 'actions/Search';
import { catalogActions } from 'actions/Catalog';
import { aggridFilterConstants } from 'constants/AggridFilter.constants';
import Icon from 'components/Icon/Icon';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { selectCurrentPlatformId } from 'selectors/platform';
import { catalogService } from 'services';
import { store, getValueOfAttribut } from 'helpers';
import SuggestionList from './SuggestionList';

const SearchBar = ({
  currentPlatformId,
  handleClickFilter,
  currentFilters,
  setCurrentFilters,
  infosLogClient,
  onBlur,
  onClearInput,
  onChange,
  aggridSearchBar,
  fetchTopSellingItems,
}) => {
  const [suggestionListIsVisible, setSuggestionListIsVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [topSellingItems, setTopSellingItems] = useState([]);

  useEffect(() => {
    const aggridSearchBarValue = (aggridSearchBar && aggridSearchBar.searchValue) || '';
    setSearchValue(aggridSearchBarValue);
  }, []);

  useEffect(() => {
    fetchTopSellingItems(currentPlatformId).then((response) => {
      if (response && response.topSellingItems) {
        const { topSellingItems } = response;
        setTopSellingItems(topSellingItems);
      }
    });
  }, [fetchTopSellingItems, currentPlatformId]);

  const updateSearchValue = (value) => {
    onChange(
      value,
      handleClickFilter,
      currentFilters,
      setCurrentFilters,
      setSuggestionListIsVisible,
      setSearchValue
    );
  };

  const onFocusHandler = () => {
    setSuggestionListIsVisible(true);
  };

  return (
    <>
      <span>
        <Icon content={'\\e93e'} />
      </span>
      <div className="aggrid-searchbar__wrapper">
        <input
          id="inputSearch"
          type="text"
          placeholder="Rechercher un produit"
          onChange={(e) =>
            onChange(
              e.target.value,
              handleClickFilter,
              currentFilters,
              setCurrentFilters,
              setSuggestionListIsVisible,
              setSearchValue
            )
          }
          onBlur={(e) => onBlur(e, infosLogClient, setSuggestionListIsVisible)}
          onFocus={() => onFocusHandler()}
          value={searchValue}
          className="search-box"
          autoComplete="off"
        />
        {searchValue && (
          <span>
            <IoMdClose
              fontSize="18px"
              id="clearSearch"
              onClick={() => onClearInput(handleClickFilter, setSearchValue, searchValue)}
            />
          </span>
        )}
      </div>

      <SuggestionList
        isVisible={suggestionListIsVisible}
        items={topSellingItems}
        updateSearchValue={updateSearchValue}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    aggridSearchBar,
    user,
    platform: { selectedId: platformId },
  } = state;
  const {
    information: { id, type, username },
  } = user;
  const infosLog = {
    id: user && id,
    username,
    type: user && type,
    platformId,
  };

  return {
    aggridSearchBar,
    infosLogClient: infosLog,
    currentPlatformId: selectCurrentPlatformId(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (
    inputValue,
    handleClickFilter,
    currentFilters,
    setCurrentFilters,
    setSuggestionListIsVisible,
    setSearchValue
  ) => {
    if (inputValue.length === 0) {
      setSearchValue('');
      handleClickFilter('');
      dispatch(aggridSearchBarActions.setSearchBar(''));
      return;
    }
    setSearchValue(inputValue);
    dispatch(aggridSearchBarActions.searchForProduct(inputValue));
    if (inputValue.length > 2) {
      setSuggestionListIsVisible(false);
      let newActiveFilters = {};
      const newQuickList = pull(
        currentFilters.quickList,
        aggridFilterConstants.FILTER_FAVORITE,
        aggridFilterConstants.FILTER_PROMOTION,
        aggridFilterConstants.FILTER_TOP_SALES
      );
      newActiveFilters = {
        ...currentFilters,
        quickList: newQuickList,
      };
      setCurrentFilters(newActiveFilters);
      handleClickFilter(inputValue);
    }
  },
  onBlur: (event, obj, setSuggestionListIsVisible) => {
    setSuggestionListIsVisible(false);
    const valSearch = event.currentTarget.value;
    const objLog = {
      search_value: valSearch,
      username: obj.username,
      type: obj.type,
      platform: { id: obj.platformId },
    };
    if (valSearch.length > 3) {
      dispatch(searchActions.searchLogs(objLog));
    }
  },
  onClearInput: (handleClickFilter, setSearchValue, searchValue) => {
    setSearchValue('');
    handleClickFilter(searchValue);
    dispatch(aggridSearchBarActions.setSearchBar(searchValue));
  },
  fetchTopSellingItems: (platformId) => dispatch(catalogActions.fetchTopSellingItems(platformId)),
});

SearchBar.propTypes = {
  infosLogClient: PropTypes.object,
  handleClickFilter: PropTypes.func,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
