import React from 'react';
import PropTypes from 'prop-types';
import { IoIosTrendingUp } from '@react-icons/all-files/io/IoIosTrendingUp';
import { StyledWrapper, StyledList } from './SuggestionList.style';

const SuggestionList = ({ isVisible, items, updateSearchValue }) => {
  const handleSuggestionClick = (value) => {
    updateSearchValue(value);
  };

  const listItems = items.map(({ reference, name }) => {
    const ref = reference.toString();
    return (
      <li onClick={() => handleSuggestionClick(ref)} key={ref}>
        <IoIosTrendingUp />
        <span>{name}</span>
      </li>
    );
  });
  if (items.length > 0) {
    return (
      <StyledWrapper isVisible={isVisible}>
        <StyledList>{listItems}</StyledList>
      </StyledWrapper>
    );
  }
  return null;
};

SuggestionList.propTypes = {
  isVisible: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  updateSearchValue: PropTypes.func.isRequired,
};

export default React.memo(SuggestionList);
