import React, { useState } from 'react';
import { RiErrorWarningFill } from '@react-icons/all-files/ri/RiErrorWarningFill';
import Tooltip from 'components/Core/Tooltip/Tooltip';

const WarningIcon = ({ withTooltip }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [parentElement, setParentElement] = useState(null);

  return (
    <div
      className="warning-icon__container"
      style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px', position: 'relative' }}
    >
      <RiErrorWarningFill
        style={{ fill: 'red', width: '20px', height: '20px', minHeight: '20px', minWidth: '20px' }}
        onMouseEnter={(event) => {
          setIsTooltipVisible(true);
          setParentElement(event.target);
        }}
        onMouseLeave={() => {
          setIsTooltipVisible(false);
        }}
      />{' '}
      {!withTooltip && <span style={{ color: 'red' }}>Quantité demandée > stock disponible</span>}
      {withTooltip && (
        <Tooltip
          isVisible={isTooltipVisible}
          tooltipPosition="top"
          labelValue="Quantité demandée supérieur au stock disponible"
          parentElement={parentElement}
        />
      )}
    </div>
  );
};

export default WarningIcon;
