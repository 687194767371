import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductCategoryControl from 'components/CatalogControls/ProductCategoryControl';
import AvailabilityControl from 'components/CatalogControls/AvailabilityControl';
import TemperatureControl from 'components/CatalogControls/TemperatureControl';
import FavoriteControl from 'components/CatalogControls/FavoriteControl';
import NewControl from 'components/CatalogControls/NewControl';
import PromotionControl from 'components/CatalogControls/PromotionControl';
import TopSalesControl from 'components/CatalogControls/TopSalesControl';
import TabControl from 'components/CatalogControls/TabControl';
import { BiListUl } from '@react-icons/all-files/bi/BiListUl';
import { BiTable } from '@react-icons/all-files/bi/BiTable';
import { aggridFilterActions } from 'actions/AggridFilter';
import { selectAggridFilter } from 'selectors/aggridFilter';
import { selectScreenWidth } from 'selectors/ui';
import { size } from 'components/Core/Utils/Device';
import AggridSearchBar from 'components/Core/SearchBar/AggridSearchBar';
import { getAvailableTemperatures } from 'helpers/Platform';

import { aggridFilterConstants } from '../../constants';
import { CatalogControlsWrapper } from './CatalogControls.style';
import './CatalogControls.scss';

const {
  FILTER_FAVORITE,
  FILTER_PROMOTION,
  FILTER_NEW,
  FILTER_TOP_SALES,
  FILTER_AVAILABLE,
  FILTER_UNAVAILABLE,
  FILTER_PREORDER,
  FILTER_STORE_AVAILABLE,
  FILTER_STORE_UNAVAILABLE,
  FILTER_STORE_NO_ASSORTMENT,
} = aggridFilterConstants;

class CatalogControls extends Component {
  availableStoreAvailabilityFilters = [
    {
      title: 'En stock',
      value: FILTER_STORE_AVAILABLE,
    },
    {
      title: 'Indisponible',
      value: FILTER_STORE_UNAVAILABLE,
    },
    {
      title: 'Hors assortiment',
      value: FILTER_STORE_NO_ASSORTMENT,
    },
  ];

  constructor(props) {
    super(props);
    this.handleQuickFilterClick = this.handleQuickFilterClick.bind(this);
  }

  getAvailableAvailabilities(rowData) {
    const availableAvailabilityFilters = [
      {
        title: 'En stock',
        value: FILTER_AVAILABLE,
      },
      {
        title: 'Indisponible',
        value: FILTER_UNAVAILABLE,
      },
    ];

    if (this.hasPreorder(rowData)) {
      availableAvailabilityFilters.push({ title: 'Précommande', value: FILTER_PREORDER });
    }

    return availableAvailabilityFilters;
  }

  hasPreorder(rowData) {
    return rowData.filter((item) => item.is_preorder).length > 0;
  }

  handleQuickFilterClick(filterValue) {
    const { currentFilters, setCurrentFilters, handleFilter } = this.props;
    const quickFilters = currentFilters.quickList || [];

    let newQuickFilters = [...quickFilters, filterValue];
    if (quickFilters.includes(filterValue)) {
      newQuickFilters = newQuickFilters.filter((item) => item !== filterValue);
    }

    const newActiveFilters = { ...currentFilters, quickList: newQuickFilters };
    setCurrentFilters(newActiveFilters);
    handleFilter(newActiveFilters);
  }

  render() {
    const {
      rowData,
      selectedPlatform,
      handleFilter,
      setCurrentFilters,
      currentFilters,
      viewType,
      toggleView,
      screenWidth,
    } = this.props;
    const newProducts = rowData.filter((item) => item.is_new);
    const promotionProducts = rowData.filter((item) => item.has_promotion);
    const quickFilterList = currentFilters.quickList || [];
    const availableAvailabilityFilters = this.getAvailableAvailabilities(rowData) || [];

    return (
      <CatalogControlsWrapper className="catalog-controls__controls-container" viewType={viewType}>
        {screenWidth > size.tablet && (
          <>
            <TabControl>
              <ProductCategoryControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
              />
            </TabControl>
            <TabControl>
              <AvailabilityControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
                list={availableAvailabilityFilters}
                storeList={this.availableStoreAvailabilityFilters}
              />
            </TabControl>
            <TabControl>
              <TemperatureControl
                handleClickFilter={handleFilter}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
                list={getAvailableTemperatures(selectedPlatform)}
              />
            </TabControl>
            <div className="catalog-controls__v-separator" />
            <TabControl
              handleTabClick={this.handleQuickFilterClick}
              handleTabParameter={FILTER_NEW}
              isActive={quickFilterList.includes(FILTER_NEW)}
            >
              <NewControl notifCounterColor="red" count={newProducts.length} />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickFilterClick}
              handleTabParameter={FILTER_PROMOTION}
              isActive={quickFilterList.includes(FILTER_PROMOTION)}
            >
              <PromotionControl count={promotionProducts.length} />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickFilterClick}
              handleTabParameter={FILTER_FAVORITE}
              isActive={quickFilterList.includes(FILTER_FAVORITE)}
            >
              <FavoriteControl notifCounterColor="#01366B" />
            </TabControl>
            <TabControl
              handleTabClick={this.handleQuickFilterClick}
              handleTabParameter={FILTER_TOP_SALES}
              isActive={quickFilterList.includes(FILTER_TOP_SALES)}
            >
              <TopSalesControl />
            </TabControl>
            <div className="catalog-controls__v-separator" />
          </>
        )}
        <div className="catalog-controls__searchbar">
          <AggridSearchBar />
        </div>
        {screenWidth > size.tablet && (
          <div className="catalog-controls__view-switch" onClick={toggleView}>
            {viewType && (
              <>
                <BiListUl />
                <span>Vue liste</span>
              </>
            )}
            {!viewType && (
              <>
                <BiTable />
                <span>Vue tableau</span>
              </>
            )}
          </div>
        )}
      </CatalogControlsWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentFilters: (filters) => dispatch(aggridFilterActions.setCurrentFilters(filters)),
  toggleView: () => dispatch(aggridFilterActions.toggleAggridView()),
});

const mapStateToProps = (state) => ({
  currentFilters: selectAggridFilter(state),
  screenWidth: selectScreenWidth(state),
});

CatalogControls.propTypes = {
  selectedPlatform: PropTypes.shape({
    attributs: PropTypes.arrayOf(PropTypes.object),
  }),
  handleFilter: PropTypes.func,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      is_new: PropTypes.bool,
    })
  ),
  aggridFilter: PropTypes.shape({
    quickList: PropTypes.arrayOf(PropTypes.string),
    temperatureList: PropTypes.arrayOf(PropTypes.string),
  }),
  viewType: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogControls);
