import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LinearLoaderDiv = styled.div`
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: ${(props) => (props.size ? `${props.size}px` : '8px')};
    height: ${(props) => (props.size ? `${props.size}px` : '8px')};
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: ${(props) => props.color || '#01366B'};
    font-size: 10px;
    margin: 0 auto;
    margin-bottom: 40px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -16px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 16px;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;

const LinearLoader = ({ color, size }) => (
  <LinearLoaderDiv color={color} size={size}>
    <div className="loader" />
  </LinearLoaderDiv>
);

LinearLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default LinearLoader;
