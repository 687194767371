import { moqConstants } from 'constants/Moq.constants';

const initState = {
  error: '',
  moqCartItems: [],
  isLoading: false,
};

export function moq(state = initState, action) {
  switch (action.type) {
    case moqConstants.UPLOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case moqConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case moqConstants.UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case moqConstants.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        moqCartItems: [],
      };
    case moqConstants.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        moqCartItems: [...action.moqs],
      };
    case moqConstants.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        moqCartItems: [],
      };
    case moqConstants.VALIDATE_REQUEST:
      return {
        ...state,
        moqCartItems: state.moqCartItems.filter(
          ({ item }) => Number(item.id) !== Number(action.itemId)
        ),
        error: '',
      };
    case moqConstants.VALIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case moqConstants.VALIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
