import React from 'react';
import ClientBreadCrumb from 'pages/Client/ClientBreadcumb';
import ClientAggrid from 'components/ClientAggrid/ClientAggrid';
import { ClientListWrapper } from './ClientList.style';

const ClientList = () => {
  return (
    <ClientListWrapper>
      <div className="page-client-list">
        <div className="page-client-list__header">
          <ClientBreadCrumb type="list" />
        </div>
        <ClientAggrid />
      </div>
    </ClientListWrapper>
  );
};

export default ClientList;
