import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from 'components/Core/DropdownSelect/DropdownSelect';

const ClientDropdownSelect = ({
  clientList,
  isLoading,
  selectedItem,
  setSelectedItem,
  inputRef,
  isDisabled,
}) => {
  const clientDropdownColumns = [
    {
      id: 'locked',
      Header: '',
      disableSortBy: true,
      accessor: (item) => item.locked,
      Cell: ({ value }) => '',
    },
    {
      id: 'siret',
      Header: '',
      disableSortBy: true,
      accessor: (item) => item.siret,
      Cell: ({ value }) => value,
    },
    {
      id: 'name',
      Header: '',
      disableSortBy: true,
      accessor: (item) => item.name,
      Cell: ({ value }) => value,
    },
  ];

  const handleFilterItems = (list, searchInput) => {
    let newList = list;
    if (searchInput) {
      const lowerSearchInput = searchInput.toLowerCase();
      newList = newList.filter(
        (item) =>
          item.siret.toLowerCase().includes(lowerSearchInput) ||
          item.name.toLowerCase().includes(lowerSearchInput)
      );
    }

    return newList;
  };

  return (
    <DropdownSelect
      columns={clientDropdownColumns}
      itemList={clientList}
      selectedItem={selectedItem}
      selectedLabel={selectedItem && `${selectedItem.siret} ${selectedItem.name}`}
      setSelectedItem={setSelectedItem}
      handleFilterItems={handleFilterItems}
      inputRef={inputRef}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  );
};

ClientDropdownSelect.propTypes = {};

export default ClientDropdownSelect;
