import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import ClientList from './ClientList';

const ClientListLayout = ({ match }) => <Layout component={ClientList} match={match} />;

ClientListLayout.propTypes = {
  match: PropTypes.object,
};

export default ClientListLayout;
