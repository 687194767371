import { cartItemsService } from 'services';
import {
  store,
  getRealCartId,
  getRealCart,
  getCartItemByItemId,
  updateCartFromCartItems,
  getCartItemById,
  getUniqCartItems,
  getCartsById,
} from 'helpers';
import { analytics } from 'utils/analytics/tagmanager';
import { cartItemsConstants, tableConstants } from '../constants';
import { cartActions } from '.';

const { DIRECTION_REPLACE } = tableConstants;

export const cartItemsActions = {
  fetchTotalRowsNumber,
  fetchItems,
  updateItemsRowsNumber,
  refreshItems,
  updateItem,
  addItem,
  saveItem,
  deleteItems,
  deleteItem,
  resetItems,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    cartItemsService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: cartItemsConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: cartItemsConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: cartItemsConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchItems(filter, offset, limit, direction = DIRECTION_REPLACE) {
  return (dispatch) => {
    dispatch(request());
    cartItemsService.fetchItems(filter, offset, limit).then(
      (items) => {
        afterRequest(items);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
    function afterRequest(items) {
      dispatch(success({ items }));
    }
  };
  function request() {
    return { type: cartItemsConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return {
      type: cartItemsConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: cartItemsConstants.FETCH_FAILURE, error };
  }
}

function updateItem(cartItem) {
  return () => {
    store.dispatch(request());
    cartItemsService.updateItem(cartItem).then(
      (returnedCartItem) => {
        store.dispatch(success(returnedCartItem));
      },
      (error) => {
        store.dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: cartItemsConstants.UPDATE_REQUEST, cartItem };
  }
  function success(returnedCartItem) {
    return { type: cartItemsConstants.UPDATE_SUCCESS, cartItem: returnedCartItem };
  }
  function failure(error) {
    return { type: cartItemsConstants.UPDATE_FAILURE, error };
  }
}

function saveItem(cartId, item) {
  return () => {
    const realCartId = getRealCartId(cartId);
    const cartItem = getCartItemByItemId(item.id, realCartId);
    if (cartItem && !cartItem.is_waiting_for_api) {
      cartItemsService.updateItem({ id: cartItem.id, quantity: cartItem.quantity });
    }
  };
}

function createAllTempsCartItems(
  realCartId,
  platform,
  dispatch,
  filter,
  updateStoreCarts,
  request,
  success
) {
  const cartItems = getUniqCartItems(realCartId);
  cartItems
    .filter(
      ({ is_temps: isTemps, is_waiting_for_api: isWaitingForApi }) => isTemps && !isWaitingForApi
    )
    .forEach((cartItemTemps) => {
      dispatch(
        request({
          realCartId,
          itemRequest: cartItemTemps.item,
          quantityRequest: cartItemTemps.quantity,
          cartItemFilter: filter,
          platform,
        })
      );
      cartItemsService
        .addItem(realCartId, cartItemTemps.item_id, cartItemTemps.quantity)
        .then((createdCartItem) => {
          // we will update datas on reducer after Api response
          dispatch(
            success({
              realCartId,
              createdCartItem,
              cartItemFilter: filter,
              platform,
            })
          );
          updateStoreCarts(getCartsById(realCartId));
        });
    });
}

/* cartId can be a status or an ID */
function addItem(cartId, item, quantity, filter, cartName = '') {
  return (dispatch, getState) => {
    const realCart = getRealCart(cartId, true);
    const { platform } = getState();
    if (!realCart || realCart.is_temps) {
      if (!realCart || (realCart.is_temps && !realCart.is_waiting_for_api)) {
        cartActions.createCart(cartId, cartName).then((apiCart) => {
          if (apiCart) {
            // Ok new Cart is saved on Database,
            // we can update cartItems Store and create all Items to set def Ids
            dispatch(tempsCartSuccess({ realCartId: apiCart.id, tempsCartId: cartId, platform }));
            createAllTempsCartItems(
              apiCart.id,
              platform,
              dispatch,
              filter,
              updateStoreCarts,
              request,
              success
            );
            // dispatch(containerActions.fetchItems({ cartId: apiCart.id }));
          }
        });
      }
      // In this case,  we continue with the temps cart created on createCart.
      // the cart will be updated after the server response
      add(dispatch, cartId, platform, true);
    } else {
      add(dispatch, realCart.id, platform);
      // dispatch(containerActions.fetchItems({ cartId: realCart.id }));
    }
  };

  function add(dispatch, realCartId, platform, isCartTemps = false) {
    dispatch(
      addUpdateTemps({
        realCartId,
        platform,
      })
    );
    const cartItem = getCartItemByItemId(item.id, realCartId, true);
    // We Only try to Api create the cart item if it's temps or the cart is Not Temps One
    if ((!cartItem || (cartItem.is_temps && !cartItem.is_waiting_for_api)) && !isCartTemps) {
      dispatch(
        request({
          realCartId,
          itemRequest: item,
          quantityRequest: quantity,
          cartItemFilter: filter,
          platform,
        })
      );

      cartItemsService.addItem(realCartId, item.id, quantity, item.price_negociated).then(
        (createdCartItem) => {
          // we will update cartItems on reducer after Api response
          dispatch(
            success({
              realCartId,
              createdCartItem,
              cartItemFilter: filter,
              platform,
            })
          );
          analytics.addToCart(createdCartItem.item, createdCartItem.quantity);
          // we dispatch manually cart values after the Api return
          updateStoreCarts(getCartsById(realCartId));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    }
    // we Update and dispatch manually cart values before the Api return
    updateStoreCarts(updateCartFromCartItems(realCartId));
  }

  function tempsCartSuccess({ realCartId, tempsCartId, platform }) {
    return {
      type: cartItemsConstants.ADD_TEMPS_CART_SUCCESS,
      cartId: realCartId,
      tempsCartId,
      platform,
    };
  }

  function addUpdateTemps({ realCartId, platform }) {
    return {
      type: cartItemsConstants.ADD_UPDATE_TEMPS,
      cartId: realCartId,
      item,
      quantity,
      filter,
      platform,
    };
  }
  function request({ realCartId, itemRequest, quantityRequest, cartItemFilter, platform }) {
    return {
      type: cartItemsConstants.ADD_REQUEST,
      cartId: realCartId,
      item: itemRequest,
      quantity: quantityRequest,
      filter: cartItemFilter,
      platform,
    };
  }
  function success({ realCartId, createdCartItem, cartItemFilter, platform }) {
    return {
      type: cartItemsConstants.ADD_SUCCESS,
      cartId: realCartId,
      cartItem: createdCartItem,
      item: createdCartItem.item,
      filter: cartItemFilter,
      platform,
    };
  }
  function failure(error) {
    return { type: cartItemsConstants.ADD_FAILURE, error };
  }
  function updateStoreCarts(carts) {
    carts.forEach((cart) => {
      updateStoreCart(cart);
    });
  }
  function updateStoreCart(cart) {
    return cartActions.updateStoreCart(cart);
  }
}

function deleteItem(cartItemId) {
  return (dispatch, getState) => {
    const { cartItems } = getState();
    const { collections: collectionsCartItems } = cartItems;
    const cartItem = getCartItemById(cartItemId, collectionsCartItems);

    if (!cartItem) {
      return;
    }

    const { cart_id: cartId } = cartItem;

    dispatch(request());

    const carts = updateCartFromCartItems(cartId);
    carts.forEach((c) => {
      updateStoreCart(c);
    });

    cartItemsService.deleteItem(cartItemId);
    /* .then(
        (cart) => {
          // updateCart(cart);
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      ); */
  };
  function request() {
    return { type: cartItemsConstants.DELETE_REQUEST, cartItemId };
  }
  /* function success() {
    return { type: cartItemsConstants.DELETE_SUCCESS, cartItemId };
  } */
  /* function failure(error) {
    return { type: cartItemsConstants.DELETE_FAILURE, error };
  } */
  function updateStoreCart(cart) {
    return cartActions.updateStoreCart(cart);
  }
}

function updateItemsRowsNumber(numberDataAvailable, cartId) {
  store.dispatch({
    type: cartItemsConstants.FETCH_COUNT_SUCCESS,
    numberDataAvailable: Number(numberDataAvailable),
    cartId,
  });
}

function refreshItems(cartItems, cartId) {
  store.dispatch({ type: cartItemsConstants.FETCH_SUCCESS, items: cartItems, cartId });
}

function deleteItems(cartItems) {
  cartItems.forEach((cartItem) => {
    cartItemsService.deleteItem(cartItem.id);
    store.dispatch({ type: cartItemsConstants.DELETE_REQUEST, cartItemId: cartItem.id });
  });
}

function resetItems(cartItems) {
  cartItems.forEach((cartItem) => {
    const resetedCartItem = {
      quantity: 0,
      total_price_vat: 0,
      total_price: 0,
      id: cartItem.id,
    };
    const newResetedCartItem = Object.assign(cartItem, resetedCartItem);
    store.dispatch({ type: cartItemsConstants.UPDATE_SUCCESS, cartItem: newResetedCartItem });
  });
}
