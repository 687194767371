import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from './Header';
import Menu from './Menu';
import { NavigationDiv } from './Navigation.style';

class Navigation extends React.Component {
  state = {
    isMenuOpen: false,
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  closeMenu = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  render() {
    const { isMenuOpen } = this.state;
    const { hasGrid } = this.props;

    return (
      <NavigationDiv isMenuOpen={isMenuOpen}>
        <header className="navigation--header">
          <div>
            <Header hasGrid={hasGrid} />
          </div>
          {/* <Header isMenuOpen={isMenuOpen} handleOpenMenu={this.handleClick} /> */}
        </header>
        <nav className="navigation--menu-wrapper">
          <Menu sideMenuState={isMenuOpen} handleOpenMenu={this.closeMenu} />
        </nav>
      </NavigationDiv>
    );
  }
}

Navigation.propTypes = {
  hasGrid: PropTypes.bool,
};

Navigation.defaultProps = {
  hasGrid: false,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapStateToProps)(Navigation);
