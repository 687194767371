export const promotionConstants = {
  FETCH_REQUEST: 'PROMOTION_FETCH_REQUEST',
  FETCH_SUCCESS: 'PROMOTION_FETCH_SUCCESS',
  FETCH_FAILURE: 'PROMOTION_FETCH_FAILURE',
  CHOOSE_TAB: 'PROMOTION_CHOOSE_TAB',

  CHOOSE_CURRENT_PRODUCT_REQUEST: 'PROMOTION_CHOOSE_CURRENT_PRODUCT_REQUEST',
  CHOOSE_CURRENT_PRODUCT_SUCCESS: 'PROMOTION_CHOOSE_CURRENT_PRODUCT_SUCCESS',
  CHOOSE_CURRENT_PRODUCT_FAILURE: 'PROMOTION_CHOOSE_CURRENT_PRODUCT_FAILURE',
};
