export const GROUP_BY = {
  cellIndex: null,
  comparisonDataKey: 'category',
};

export const CUSTOMER_CURRENT_CART_COLUMNS_ITEMS = [
  {
    type: 'PRODUCT_IMAGE',
    dataType: 'image',
    name: 'Image',
    className: 'header_product-image',
  },
  {
    type: 'PRODUCT_WORDING',
    dataType: 'image',
    name: 'Libellé produit',
    className: 'header_product-wording',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Marque',
    className: 'header_product-brand',
    dataType: 'manufacturer',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'PCB',
    className: 'header_product-pcb',
    dataType: 'pcb',
  },
  {
    type: 'PRODUCT_PRICE',
    name: 'Prix unitaire HT',
    className: 'header_product-price',
    dataType: 'price',
  },
  {
    type: 'PRODUCT_QUANTITY',
    name: 'Quantités',
    className: 'header_product-quantity',
    dataType: 'quantity',
  },
  {
    type: 'PRODUCT_PRICE_TOTAL',
    name: 'Total HT',
    className: 'header_product-total',
    dataType: 'final_price',
  },
  {
    type: 'PRODUCT_ACTION_DELETE',
    name: '',
    className: 'header_product-action',
  },
];

export const COMMERCIAL_CURRENT_CART_COLUMNS_ITEMS = [
  {
    type: 'PRODUCT_IMAGE',
    dataType: 'image',
    name: 'Image',
    className: 'header_product-image',
  },
  {
    type: 'PRODUCT_WORDING',
    dataType: 'image',
    name: 'Libellé produit',
    className: 'header_product-wording',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Marque',
    className: 'header_product-brand',
    dataType: 'manufacturer',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'PCB',
    className: 'header_product-pcb',
    dataType: 'pcb',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Prix unitaire HT',
    className: 'header_product-price',
    dataType: 'price',
  },
  {
    type: 'PRODUCT_QUANTITY_TEXT',
    name: 'Quantités',
    className: 'header_product-quantity',
    dataType: 'quantity_text',
  },
  {
    type: 'PRODUCT_PRICE_TOTAL',
    name: 'Total HT',
    className: 'header_product-total',
    dataType: 'final_price',
  },
];

export const CART_COLUMNS_ITEMS = [
  {
    type: 'PRODUCT_IMAGE',
    dataType: 'image',
    name: 'Image',
    className: 'header_product-image',
  },
  {
    type: 'PRODUCT_WORDING',
    dataType: 'image',
    name: 'Libellé produit',
    className: 'header_product-wording',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Marque',
    className: 'header_product-brand',
    dataType: 'manufacturer',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'PCB',
    className: 'header_product-pcb',
    dataType: 'pcb',
  },
  {
    type: 'PRODUCT_PRICE',
    name: 'Prix unitaire HT',
    className: 'header_product-price',
    dataType: 'price',
  },
  {
    type: 'PRODUCT_QUANTITY_TEXT',
    name: 'Quantités',
    className: 'header_product-quantity',
    dataType: 'quantity_text',
  },
  {
    type: 'PRODUCT_PRICE_TOTAL',
    name: 'Total HT',
    className: 'header_product-total',
    dataType: 'final_price',
  },
  {
    type: 'PRODUCT_ACTION_DELETE',
    name: '',
    className: 'header_product-action',
  },
];

export const COLUMNS_LIST = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    dataType: 'name',
    name: 'Nom du panier',
    className: 'header_product-wording',
    style: {
      isBold: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    dataType: 'is_preorder',
    name: 'Type',
    className: 'header_product-wording',
    style: {
      isBold: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    dataType: 'date_create',
    dataFormat: 'date',
    name: 'Date de création',
    className: 'header_product-brand',
    style: {
      isBold: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    dataType: 'totals_by_type',
    name: 'Références',
    className: 'header_product-pcb',
    style: {
      isBold: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Total',
    className: 'header_product-price',
    dataType: 'total_price',
    style: {
      isBold: true,
    },
  },
];
