import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BsTrash } from '@react-icons/all-files//bs/BsTrash';
import { BsPencil } from '@react-icons/all-files//bs/BsPencil';
import { BsFillPlayFill } from '@react-icons/all-files//bs/BsFillPlayFill';
import { BsFillPauseFill } from '@react-icons/all-files//bs/BsFillPauseFill';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { modalActions, recurrentCartActions } from 'actions';
import { dateTimeToFormatedDate, getIconClassByType, getPath, history } from 'helpers';
import RecurrentCartBreadCrumb from 'pages/RecurentCart/RecurentCartBreadcumb';
import RecurrentCartListWrapper from 'pages/RecurentCart/RecurrentCartList.style';
import { selectRecurentCartData } from 'selectors/recurrentCart';
import { recurrentCartConstants } from 'constants/RecurrentCart.constants';
import withStyleCart from '../Cart/Cart.style';

const RecurrentCartList = ({
  recurrentCartList,
  fetchItems,
  openConfirmModal,
  deleteRecurrentCart,
  openUpdateRecurringModal,
  toggleRecurrentCartActive,
}) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    fetchItems(0, 4000);
  }, []);

  useEffect(() => {
    if (recurrentCartList) {
      setRowData(getRecurrentCartByCartId());
    }
  }, [recurrentCartList]);

  const handleRecurrentCartDeleteClick = useCallback(
    (recurrentCartIdList) => {
      openConfirmModal(null, 'Veuillez confirmer la suppression de la commande automatique', () => {
        recurrentCartIdList.map((recurrentCartId) => deleteRecurrentCart(recurrentCartId));
      });
    },
    [deleteRecurrentCart, openConfirmModal]
  );

  const columns = React.useMemo(() => [
    {
      id: 'name',
      Header: 'Nom du panier',
      accessor: (item) => item.recurrentCartList[0].name,
    },
    {
      id: 'frequency',
      Header: 'Fréquence',
      accessor: (item) => item.recurrentCartList[0].frequency,
    },
    {
      id: 'day',
      Header: 'Jour',
      accessor: (item) => item,
      Cell: (row) =>
        row.value.recurrentCartList
          .sort((a, b) => a.day - b.day)
          .map(
            (recurrentCart, index) =>
              `${recurrentCartConstants.DAYS[recurrentCart.day - 1]}${
                (index + 1 < row.value.recurrentCartList.length && ', ') || ''
              }`
          ),
    },
    {
      id: 'dateCreation',
      Header: 'Date création',
      accessor: (item) => dateTimeToFormatedDate(item.recurrentCartList[0].right_date_created),
    },
    {
      id: 'dateBegin',
      Header: 'Date de début',
      accessor: (item) => dateTimeToFormatedDate(item.recurrentCartList[0].begin_date),
    },
    {
      id: 'reference',
      Header: 'Références',
      accessor: (item) => item.recurrentCartList[0],
      Cell: (row) =>
        Object.keys(row.value.cart.count_items_by_type).map((key) => (
          <div className="cart-list__ref-line">
            <i className={getIconClassByType(key)} /> {key} :{' '}
            {row.value.cart.count_items_by_type[key]} Réf.
          </div>
        )),
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (item) => item.recurrentCartList[0].cart.total_price,
      Cell: (row) => <>{row.value.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} HT</>,
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      disableClick: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="action-wrapper">
          {value.recurrentCartList[0].active && (
            <BsFillPauseFill
              className="pause-icon"
              onClick={() => {
                handleToggleRecurrentActive(value.cartId);
              }}
            />
          )}
          {!value.recurrentCartList[0].active && (
            <BsFillPlayFill
              className="play-icon"
              onClick={() => {
                handleToggleRecurrentActive(value.cartId);
              }}
            />
          )}
          <BsPencil
            className="update-icon"
            onClick={() => {
              openUpdateRecurringModal(value.cartId);
            }}
          />
          <BsTrash
            className="delete-icon"
            onClick={() => {
              handleRecurrentCartDeleteClick(
                value.recurrentCartList.map((recurrentCart) => recurrentCart.id)
              );
            }}
          />
        </div>
      ),
    },
  ]);

  const goToCustomCartList = () => {
    history.push(getPath('cartList'));
  };

  const goToCartDetail = (cartId) => {
    history.push(getPath('cart').replace(':id', cartId));
  };

  const getRecurrentCartByCartId = () => {
    const grouped = [];
    recurrentCartList.forEach((recurrentCart) => {
      const index = grouped.findIndex((item) => item.cartId === recurrentCart.cart.id);
      if (index > -1) {
        grouped[index].recurrentCartList.push(recurrentCart);
      } else {
        grouped.push({
          cartId: recurrentCart.cart.id,
          handleRowClick: () => {
            goToCartDetail(recurrentCart.cart.id);
          },
          recurrentCartList: [recurrentCart],
        });
      }
    });

    return grouped;
  };

  const handleToggleRecurrentActive = (cartId) => {
    const currentCartRowData = rowData.find((item) => item.cartId === cartId);
    const message = currentCartRowData.recurrentCartList[0].active
      ? 'Confirmez-vous la mise en pause de la commande automatique ?'
      : 'Confirmez-vous la reprise de la commande automatique ?';
    openConfirmModal('Confirmation', message, () => {
      currentCartRowData.recurrentCartList.map((recurrentCart) =>
        toggleRecurrentCartActive(recurrentCart.id, !recurrentCart.active)
      );
    });
  };

  return (
    <RecurrentCartListWrapper>
      <div className="page-cart">
        <div className="page-cart__heading">
          <RecurrentCartBreadCrumb isCurrentCart={false} cartName="Mes paniers types" isListCart />
          <div className="page-cart__section-label">
            <span className="bold">Mes commandes automatiques</span>
          </div>
          <div className="page-cart__navigation-buttons">
            <button type="button" onClick={goToCustomCartList}>
              Paniers types
            </button>
            <button type="button" className="active-button">
              Commandes automatiques
            </button>
          </div>
        </div>
        <div className="panier__type">
          <ReactTable columns={columns} data={rowData} />
        </div>
      </div>
    </RecurrentCartListWrapper>
  );
};

RecurrentCartList.propTypes = {
  recurrentCartList: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  recurrentCartList: selectRecurentCartData(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchItems: () => dispatch(recurrentCartActions.fetchRecurrentCartList(0, 4000)),
  deleteRecurrentCart: (recurrentCartId) =>
    dispatch(recurrentCartActions.deleteRecurentCart(recurrentCartId)),
  openConfirmModal: (title, message, callback) =>
    dispatch(modalActions.open('confirm', { title, message, callback })),
  openUpdateRecurringModal: (cartId) =>
    dispatch(
      modalActions.open('updateRecurrentCart', {
        modalHeader: 'Modifier votre commande automatique',
        cartId,
      })
    ),
  toggleRecurrentCartActive: (recurrentCartId, isActive) =>
    dispatch(recurrentCartActions.toggleRecurentCartActive(recurrentCartId, isActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyleCart(RecurrentCartList));
