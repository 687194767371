import { aggridSearchBarConstants } from 'constants/AggridSearchBar.constants';

export const aggridSearchBarActions = {
  searchForProduct,
  setSearchBar,
};

function searchForProduct(query) {
  return (dispatch) => dispatch({ type: aggridSearchBarConstants.SEARCH_FOR_PRODUCT, query });
}
function setSearchBar(query) {
  return (dispatch) => dispatch({ type: aggridSearchBarConstants.SET_SEARCH, query });
}
