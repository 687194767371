import { promotionService, catalogService, productService } from 'services';
import { checkPlatformAllowsTechnicalSheets } from 'helpers';
import { checkTechnicalSheetAvailability } from 'helpers/Product';
import { promotionConstants } from '../constants';

export const promotionActions = {
  fetchPromotionList,
  chooseCurrentProduct,
  chooseTabPromotionList,
};

function chooseTabPromotionList(currentTab) {
  return (dispatch) => {
    dispatch(success(currentTab));
  };
  function success(currentTab) {
    return { type: promotionConstants.CHOOSE_TAB, currentTab };
  }
}

function fetchPromotionList(platformId) {
  return (dispatch) => {
    dispatch(request());
    promotionService.fetchPromotionList(platformId).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: promotionConstants.FETCH_REQUEST };
  }
  function success(data) {
    return { type: promotionConstants.FETCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: promotionConstants.FETCH_FAILURE, error };
  }
}

function chooseCurrentProduct(product, platform) {
  return (dispatch) => {
    dispatch(request());

    // if currentProduct is null close productCard component
    if (product === null) {
      dispatch(success(null));
      return;
    }

    if (checkPlatformAllowsTechnicalSheets(platform)) {
      catalogService.fetchItems({ id: product.id, platform: platform.id }).then(
        (data) => {
          const file = data.items[0].document;
          checkTechnicalSheetAvailability(file).then((response) => {
            const document = response ? file : null;
            dispatch(success({ ...product, document }));
          });
        },
        (error) => {
          dispatch(failure(error));
        }
      );

      return;
    }

    productService.getLastOrdersByItemID(product.id, platform.id).then(
      (response) => {
        dispatch(success({ ...product, last_orders: response }));
      },
      (error) => {
        dispatch(failure(error));
      }
    );

    dispatch(success({ ...product, document: null }));
  };

  function request() {
    return { type: promotionConstants.CHOOSE_CURRENT_PRODUCT_REQUEST };
  }

  function success(product) {
    return {
      type: promotionConstants.CHOOSE_CURRENT_PRODUCT_SUCCESS,
      product,
    };
  }

  function failure(error) {
    return { type: promotionConstants.CHOOSE_CURRENT_PRODUCT_FAILURE, error };
  }
}
