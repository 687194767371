export const deliveryPlanningConstants = {
  FETCH_REQUEST: 'DELIVERY_PLANNING_FETCH_REQUEST',
  FETCH_SUCCESS: 'DELIVERY_PLANNING_FETCH_SUCCESS',
  FETCH_FAILURE: 'DELIVERY_PLANNING_FETCH_FAILURE',
  UPDATE_REQUEST: 'DELIVERY_PLANNING_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DELIVERY_PLANNING_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DELIVERY_PLANNING_UPDATE_FAILURE',
  DELETE_REQUEST: 'DELIVERY_PLANNING_DELETE_REQUEST',
  DELETE_SUCCESS: 'DELIVERY_PLANNING_DELETE_SUCCESS',
  DELETE_FAILURE: 'DELIVERY_PLANNING_DELETE_FAILURE',
  DAYS: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
  DAYS_SHORT: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
};
