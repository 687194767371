import styled from 'styled-components';
import { MapIconsNameContent } from './MapIconsNameContent';

const getContentByName = (slug) => {
  const iconContent = MapIconsNameContent[slug];
  return iconContent || '';
};

const Icon = styled.i`
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  &:before {
    content : "${(props) => (props.slug ? getContentByName(props.slug) : props.content)}";
  }
  &:hover {
    background-color:${(props) => props.background};
  }
}
`;
Icon.defaultProps = {
  type: 'main',
  background: 'none',
  color: 'inherit',
  slug: '',
};
export default Icon;
