const localeText = {
  // Set Filter
  selectAll: 'Tout sélectionner',
  selectAllSearchResults: 'Sélectionner tous les résultats',
  searchOoo: 'Recherche...',
  blanks: 'Vide',
  noMatches: 'Aucun résultat.',

  // Number Filter & Text Filter
  filterOoo: 'Filtrer...',
  equals: 'Égal à',
  notEqual: 'Différent de',
  // Number Filter
  lessThan: 'Moins que',
  greaterThan: 'Plus que',
  lessThanOrEqual: 'Moins que ou égal à',
  greaterThanOrEqual: 'Plus que ou égal à',
  inRange: 'Entre',
  inRangeStart: '',
  inRangeEnd: 'et',

  // columns tool panel
  rowGroupColumnsEmptyMessage: 'Faites glisser ici pour regrouper les lignes',

  // Other
  noRowsToShow: 'Aucun produit trouvé',
};

export default localeText;
