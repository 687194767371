import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cartActions, modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ModalUseCustomCartWithStyle from './ModalUseCustomCart.style';
import { cartConstants } from '../../constants';

const { CART_STATUS_CURRENT, CART_STATUS_CURRENT_PREORDER } = cartConstants;

class ModalUseCustomCart extends React.Component {
  useCart = () => {
    const { cart, useCart, closeModal } = this.props;
    useCart(cart);
    closeModal();
  };

  render() {
    const { className, closeModal } = this.props;
    return (
      <div className={className}>
        <div className="modal-body">
          <div className="modal-body__text-block bold">
            <span>
              Êtes-vous sûr de vouloir utiliser ce panier type ? Le contenu de votre panier en cours
              sera remplacé
            </span>
          </div>
          <div className="modal-body__button-block">
            <ButtonPrimary inverted onClick={closeModal}>
              NON
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() => {
                this.useCart();
              }}
            >
              Oui
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}

ModalUseCustomCart.propTypes = {
  className: PropTypes.string,
  cart: PropTypes.object,
  useCart: PropTypes.func,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  useCart: (cart) => {
    dispatch(
      cartActions.copyCart(
        cart.id,
        cart.is_preorder ? CART_STATUS_CURRENT_PREORDER : CART_STATUS_CURRENT,
        '',
        true
      )
    );
  },
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(ModalUseCustomCartWithStyle(ModalUseCustomCart));
