import isEqual from 'lodash.isequal';
/* eslint-disable no-nested-ternary */
import { orderConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

export function order(state = { collections: [], collectionsCount: [], rating: {} }, action) {
  if (!Object.keys(orderConstants).find((key) => orderConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));
  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }
  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case orderConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case orderConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case orderConstants.FETCH_COUNT_FAILURE:
      return state;
    case orderConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case orderConstants.FETCH_SUCCESS: {
      const newCollection = {
        ...collection,
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
      };
      const newCollections = [
        ...collections.filter((item) => !isEqual(item.filter, newCollection.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case orderConstants.FETCH_FAILURE:
      return state;
    case orderConstants.RENEW_REQUEST:
      return {
        ...state,
      };
    case orderConstants.RENEW_SUCCESS:
      return {
        ...state,
      };
    case orderConstants.RENEW_FAILURE:
      return { ...state };
    case orderConstants.DOWNLOAD_ORDER_FORM_REQUEST:
      return {
        ...state,
        pdfIsLoading: true,
        idOrderDownload: action.order.id,
      };
    case orderConstants.DOWNLOAD_ORDER_FORM_SUCCESS:
      return {
        ...state,
        pdfIsLoading: false,
      };
    case orderConstants.DOWNLOAD_ORDER_FORM_FAILURE:
      return { ...state };

    case orderConstants.DOWNLOAD_PREORDER_FORM_REQUEST:
      return {
        ...state,
        xlsIsLoading: true,
      };
    case orderConstants.DOWNLOAD_PREORDER_FORM_SUCCESS:
      return {
        ...state,
        xlsIsLoading: false,
      };
    case orderConstants.DOWNLOAD_PREORDER_FORM_FAILURE:
      return { ...state };
    case orderConstants.RATING_REQUEST:
      return {
        ...state,
        rating: {
          ...state.rating,
          isLoading: true,
        },
      };
    case orderConstants.RATING_SUCCESS:
      return {
        ...state,
        rating: {
          ...state.rating,
          isLoading: false,
          [action.orderId]: { ...action.rating },
        },
      };
    case orderConstants.RATING_FAILURE:
      return {
        ...state,
        rating: {
          ...state.rating,
          isLoading: false,
          error: {
            message: action.error,
          },
        },
      };
    default:
      return state;
  }
}
