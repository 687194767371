import { maintenanceConstants } from 'constants/Maintenance.constants';

const initState = {
  isAlive: null,
  title: null,
  message: null,
};

export function maintenance(state = initState, action) {
  switch (action.type) {
    case maintenanceConstants.CHECK_HEALTH_REQUEST:
      return {
        ...state,
      };
    case maintenanceConstants.CHECK_HEALTH_SUCCESS:
      return {
        isAlive: action.data.is_alive,
        title: action.data.title,
        message: action.data.message,
      };
    case maintenanceConstants.CHECK_HEALTH_FAILURE:
      return {
        ...state,
        isAlive: false,
      };
    default:
      return state;
  }
}
