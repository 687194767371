import styled from 'styled-components';

export default (Component) => styled(Component)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 101;

  -webkit-box-shadow: 0px 0px 0px 3000px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 0px 3000px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 0px 3000px rgba(0, 0, 0, 0.2);

  @keyframes open {
    from {
      transform: translateX(-10%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  animation-name: open;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  transform: translateX(-10%);

  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  overflow: auto;
  background: rgba(0, 0, 0, 0.25);
  .filter {
    width: 295px;
    background: #fff;
    position: relative;
    overflow-y: hidden;
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      width: 280px;
    }
    @media screen and (min--moz-device-pixel-ratio: 0) {
      width: 295px;
    }
    .filteropen_header {
      .filteropen_header_icon {
        cursor: pointer;
      }
      background: #f5f5f5;
      height: 40px;
      line-height: 40px;
      > * {
        display: inline-block;
        vertical-align: middle;
      }
      h5 {
        margin-left: 60px;
      }
      i {
        font-size: 12px;
        margin-left: 25px;
      }
    }
    .filteropen_content {
      height: calc(100% - 80px); // 40px for the title, 40px for the submit button

      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
      }
      ::-webkit-scrollbar-thumb {
        background: #01366b;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #0fa3f0;
      }

      h5 {
        padding-bottom: 18px;
      }
      .filteropen__catalogue,
      .filteropen__statuts {
        border-bottom: 1px solid rgba(1, 54, 107, 0.2);
        padding: 20px;
      }
      .filteropen__catalogue:last-child {
        border-bottom: none;
      }
      .manufacturer-checkboxes {
        margin: 10px 0;
      }
      .filteropen__catalogue_input-name-manufacturer {
        width: 100%;
      }
      .see-more {
        margin: 10px 0;
        color: #01366b;
      }
      .checkbox {
        position: relative;
        label {
          color: #4a4a4a;
          font-size: 14px;
          line-height: 18px;
          padding-right: 20px;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
        }
        input[type='checkbox'] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark:after {
            display: block;
          }
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          border: 1px solid rgba(120, 120, 120, 0.54);
          &:after {
            content: '';
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 5px;
            height: 12px;
            border: solid #01366b;
            border-width: 0 2px 2px 0px;
            transform: rotate(45deg);
          }
        }
      }
      .filter-item {
        margin-bottom: 20px;
      }
    }
    .filteropen_footer {
      position: absolute;
      bottom: 0;
      height: 40px;
      width: 100%;
      cursor: pointer;
      h5 {
        color: #fff;
        font-weight: 600;
        text-align: center;
        min-height: 40px;
        line-height: 40px;
      }
      .btn-primary {
        width: 100%;
        color: #ffffff;
        border-radius: 0px;
      }
    }
  }
`;
