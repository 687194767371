/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-mixed-operators */
import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { deliveryPlanningActions, modalActions } from 'actions';
import { getValueOfAttribut } from 'helpers';
import { ButtonPrimary, ButtonSecondary } from 'components/Core/Button/Button';
import { catalogConstants, deliveryPlanningConstants, platformConstants } from '../../constants';
import ModalAddPlanningStyle from './ModalAddPlanning.style';
import 'react-datepicker/dist/react-datepicker.css';

const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const {
  HAS_DRY_PRODUCTS_KEY,
  HAS_FROZEN_PRODUCTS_KEY,
  HAS_FRESH_PRODUCTS_KEY,
  DELIVERY_MODE_KEY,
} = platformConstants;
const { DAYS_SHORT } = deliveryPlanningConstants;

class ModalAddPlanning extends React.Component {
  constructor(props) {
    super(props);

    const { platform, item: propsItem } = this.props;

    const newItem = {
      platform: {
        id: platform.id,
      },
      name: '',
      date_begin: Moment().format('YYYY-MM-DD'),
      date_end: Moment().format('YYYY-MM-DD'),
      temperature: '',
      delivery_mode: '',
      days: [],
      parent: {
        id: null,
      },
      parent_id: null,
      is_child: false,
    };

    const item = propsItem || newItem;
    this.state = {
      item,
      parentSelectItems: [],
    };
  }

  getListItemDays = () => {
    const { item } = this.state;
    const listItemDay = [];
    for (let i = 1; i < 8; i += 1) {
      const day = item.days.find((d) => Number(d.week_day) === i);
      listItemDay.push(
        <div className="checkbox">
          <label>
            <input
              name="check"
              type="checkbox"
              value={i}
              onChange={this.daysHandler}
              defaultChecked={!!day}
            />
            {DAYS_SHORT[i - 1]}
            <span className="checkmark" />
            {day && (
              <span className="planning__calender_detais">
                <span>Delais de </span>
                <input
                  type="text"
                  className="time"
                  value={day.hour}
                  onChange={(e) => this.changeHoursOfDay(e, i)}
                />
                <span>H Commande imperative avant : </span>
                {day.hour ? (
                  <span>
                    {Moment()
                      .day(i)
                      .hours(12)
                      .minutes(0)
                      .subtract(Number(day.hour), 'hours')
                      .format('ddd HH[h]mm')}
                  </span>
                ) : (
                  <span>-</span>
                )}
              </span>
            )}
          </label>
        </div>
      );
    }
    return <div>{listItemDay}</div>;
  };

  getParentSelectItems = () => {
    const {
      deliveryPlanning,
      platform: { attributs },
    } = this.props;
    const { item } = this.state;
    const { items } = deliveryPlanning;
    const { delivery_mode: deliveryMode, temperature } = item;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const listOptions = items.map((i) => {
      if (platformDeliveryMode && i.delivery_mode === deliveryMode) {
        return <option value={i.id}>{i.name}</option>;
      }
      if (!platformDeliveryMode && i.temperature === temperature) {
        return <option value={i.id}>{i.name}</option>;
      }
      return null;
    });
    return listOptions;
  };

  getTemperaturesSelectItems = () => {
    const { item } = this.state;
    const { temperature: itemTemperature } = item;
    const { platform } = this.props;
    const { attributs } = platform || [];
    const hasDryProducts = !!Number(getValueOfAttribut(attributs || [], HAS_DRY_PRODUCTS_KEY));
    const hasFreshProducts = !!Number(getValueOfAttribut(attributs || [], HAS_FRESH_PRODUCTS_KEY));
    const hasFrozenProducts = !!Number(
      getValueOfAttribut(attributs || [], HAS_FROZEN_PRODUCTS_KEY)
    );
    const temperatures = [];
    if (hasDryProducts) {
      temperatures.push(TEMPERATURE_DRY);
    }
    if (hasFrozenProducts) {
      temperatures.push(TEMPERATURE_FROZEN);
    }
    if (hasFreshProducts) {
      temperatures.push(TEMPERATURE_FRESH);
    }
    const listOptions = temperatures.map((temperature) => (
      <option value={temperature} checked={temperature === itemTemperature}>
        {temperature}
      </option>
    ));
    return listOptions;
  };

  temperatureDeliveryHandler = (e, platformDeliveryMode) => {
    const { item } = this.state;

    if (!platformDeliveryMode) {
      item.temperature = e.target.value;
    } else {
      item.delivery_mode = JSON.parse(e.target.value);
    }
    this.setState(() => ({
      item,
    }));

    const parentSelectItems = this.getParentSelectItems();
    this.setState(() => ({
      parentSelectItems,
    }));
  };

  daysHandler = (e) => {
    const { item } = this.state;
    const { days } = item;
    const weekDay = Number(e.target.value);

    const isChecked = days.find((day) => Number(day.week_day) === weekDay);
    if (!isChecked || isChecked === undefined) {
      days.push({
        week_day: weekDay,
        hour: 0,
      });
      item.days = days;
    } else {
      const newDays = days.filter((day) => Number(day.week_day) !== Number(weekDay));
      item.days = newDays || [];
    }

    this.setState(() => ({
      item,
    }));
  };

  changeHoursOfDay = (e, weekDay) => {
    const { item } = this.state;
    const hour = Number(e.target.value);
    if (hour || hour === 0) {
      const day = item.days.find((d) => Number(d.week_day) === Number(weekDay));
      day.hour = hour;
      this.setState(() => ({
        item,
      }));
    }
  };

  updateState = (field, value) => {
    const { item } = this.state;
    item[field] = value;
    this.setState(() => ({
      item,
    }));
  };

  submitHandler = () => {
    const { updateItem, closePlanningModal, platform } = this.props;
    const { item } = this.state;
    const data = {
      id: item.id,
      date_begin: item.date_begin,
      date_end: item.is_child ? item.date_end : null,
      parent_id: item.is_child ? item.parent_id : null,
      name: item.name,
      temperature: item.temperature,
      delivery_mode: item.delivery_mode,
      platform: item.platform,
      days: [...item.days],
    };
    /* Format & check */
    if (data.date_begin === '' || !data.parent_id) {
      data.date_begin = null;
    }
    if (data.date_end === '' || !data.parent_id) {
      data.date_end = null;
    }
    if (!data.parent_id) {
      data.parent = null;
    } else {
      data.parent = { id: item.parent_id };
      delete data.parent_id;
    }
    if (!data.platform || !data.platform.id) {
      data.platform = {
        id: platform.id,
      };
    }
    if (this.isAbleToSave()) {
      data.days = data.days.map((day) => ({ week_day: day.week_day, hour: day.hour }));
      updateItem(data);
      closePlanningModal();
    }
  };

  deleteHandler = () => {
    const { deleteItem, closePlanningModal } = this.props;
    const { item } = this.state;
    if (item.id && Number(item.count_clients) === 0) {
      deleteItem(item);
      closePlanningModal();
    }
  };

  isAbleToSave = () => {
    const {
      platform: { attributs },
    } = this.props;
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    const { item } = this.state;
    if (item.name === '') {
      return false;
    }
    if (!platformDeliveryMode && item.temperature === '') {
      return false;
    }
    if (platformDeliveryMode && item.delivery_mode === '') {
      return false;
    }
    if (
      item.is_child &&
      (!item.date_begin || !item.date_end || item.date_begin > item.date_end || !item.parent_id)
    ) {
      return false;
    }
    if (item.days.length === 0 || item.days.find((i) => !i.hour)) {
      return false;
    }
    return true;
  };

  render() {
    const {
      className,
      platform: { attributs },
    } = this.props;
    const { item, parentSelectItems: stateParentSelectItems } = this.state;
    const temperaturesSelectItems = this.getTemperaturesSelectItems();
    const parentSelectItems =
      !stateParentSelectItems.length && item.is_child
        ? this.getParentSelectItems()
        : stateParentSelectItems;
    const listItemDays = this.getListItemDays();
    const platformDeliveryMode = !!Number(getValueOfAttribut(attributs || [], DELIVERY_MODE_KEY));
    return (
      <div className={className}>
        <div className="planning__type">
          <h5>Typologie de planning</h5>
          <div className="planning__type-radio">
            <label>
              <input
                placeholder=""
                name="radio"
                type="radio"
                value="false"
                defaultChecked={item.is_child === false}
                onChange={(e) => {
                  this.updateState('is_child', e.target.value === 'true');
                }}
              />
              <span>Par défaut</span>
              <span className="check" />
            </label>
          </div>
          <div className="planning__type-radio">
            <label>
              <input
                name="radio"
                type="radio"
                value="true"
                defaultChecked={item.is_child === true}
                onChange={(e) => {
                  this.updateState('is_child', e.target.value === 'true');
                }}
              />
              <span>Substitution</span>
              <span className="check" />
            </label>
          </div>
          <div className="planning__name">
            <input
              type="text"
              placeholder="Nom du planning"
              value={item.name}
              onChange={(e) => {
                this.updateState('name', e.target.value);
              }}
            />

            {!item.id &&
              (!platformDeliveryMode ? (
                <div className="planning__type-temperature">
                  <select
                    onChange={(e) => this.temperatureDeliveryHandler(e)}
                    value={item.temperature}
                  >
                    <option value="" disabled selected>
                      Température
                    </option>
                    {temperaturesSelectItems}
                  </select>
                </div>
              ) : (
                <div className="planning__type-temperature">
                  <select
                    onChange={(e) => this.temperatureDeliveryHandler(e, platformDeliveryMode)}
                    value={item.delivery_mode}
                  >
                    <option value="" disabled selected>
                      Mode de livraison
                    </option>
                    <option value={false}>Livraison</option>
                    <option value>Retrait</option>
                  </select>
                </div>
              ))}
          </div>
          <div className="planning__substitution" hidden={!item.is_child}>
            <div className="planning__substitution-valide">
              <DatePicker
                selected={item.date_begin ? Moment(item.date_begin).toDate() : ''}
                onChange={(date) => {
                  this.updateState('date_begin', date ? Moment(date).format('YYYY-MM-DD') : '');
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="Du"
                locale="fr"
              />
              <i className="icon-calendar" />
            </div>
            <div className="planning__substitution-to">
              <DatePicker
                selected={item.date_end ? Moment(item.date_end).toDate() : ''}
                onChange={(date) => {
                  this.updateState('date_end', date ? Moment(date).format('YYYY-MM-DD') : '');
                }}
                dateFormat="dd/MM/yyyy"
                placeholderText="Au"
                locale="fr"
              />
              <i className="icon-calendar" />
            </div>

            <h5 hidden={!item.is_child}>Selectionner le planning parent</h5>
            <div className="planning__substitution-choose" hidden={!item.is_child}>
              <select
                value={item.parent_id}
                onChange={(e) => {
                  this.updateState('parent_id', e.target.value);
                }}
              >
                <option value="" disabled selected hidden>
                  Selectionner le planning parent
                </option>
                {parentSelectItems}
              </select>
            </div>
          </div>
        </div>
        <div className="planning__calender">
          <h5>Selectionner les jours de livraison</h5>
          <em>Délais entre jour de livraison (base 12h00) et heure limite de commande</em>
          {listItemDays}
        </div>
        <div className="planning__info">
          <h4>
            <span>{`${item.count_clients || 0} client(s) affecté(s)`}</span>
            <span className="red">*</span>
          </h4>
          <ButtonSecondary
            className="planning__info-delete"
            onClick={() => this.deleteHandler()}
            disabled={Number(item.count_clients) > 0}
          >
            <i className="icon-delete" />
            <span>SUPPRIMER CE PLANNING</span>
          </ButtonSecondary>
          {!platformDeliveryMode && (item.name === '' || item.temperature === '') && (
            <p className="red right">*Nom et/ou température du planning invalides.</p>
          )}
          {platformDeliveryMode && (item.name === '' || item.delivery_mode === '') && (
            <p className="red right">*Nom et/ou mode de livraison du planning invalides.</p>
          )}
          {item.is_child &&
            (!item.date_begin || !item.date_end || item.date_begin > item.date_end) && (
              <p className="red right">*La période de validité sélectionnée est invalide.</p>
            )}
          {item.is_child && !item.parent_id && (
            <p className="red right">*Planning parent non renseigné.</p>
          )}
          {(item.days.length === 0 || item.days.find((i) => !i.hour)) && (
            <p className="red right">
              *Veuillez sélectionner des jours de livraison et indiquer le délai de commande.
            </p>
          )}
          {item.is_child === false && Number(item.count_clients) > 0 && (
            <p className="red right">
              *Un planning par défaut peut-être supprimé seulement si aucun client n&apos;y est
              affecté.
            </p>
          )}
          <ButtonPrimary
            className="planning__info_valid"
            onClick={this.submitHandler}
            disabled={!this.isAbleToSave()}
          >
            <span>Valider</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalAddPlanning.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  platform: PropTypes.object,
  deliveryPlanning: PropTypes.object,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  closePlanningModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { deliveryPlanning, platform } = state;
  return {
    deliveryPlanning,
    platform: platform.platforms.find((p) => Number(p.id) === Number(platform.selectedId)),
  };
};
const mapDispatchToProps = (dispatch) => ({
  updateItem: (item) => {
    dispatch(deliveryPlanningActions.updateItem(item));
  },
  deleteItem: (item) => {
    dispatch(deliveryPlanningActions.deleteItem(item));
  },
  closePlanningModal: () => {
    dispatch(modalActions.close());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAddPlanningStyle(ModalAddPlanning));
