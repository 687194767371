import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getValueOfAttribut } from 'helpers';
import {
  filterConstants,
  platformConstants,
  catalogConstants,
  categoryConstants,
} from 'pages/../constants';
import FormSection from './FormSection';
import ManufacturersFilter from './ManufacturersFilter';

const { STATUS_SELECTION, STATUS_PREORDER, STATUS_CATALOG } = categoryConstants;
const { TEMPERATURE_DRY, TEMPERATURE_FRESH, TEMPERATURE_FROZEN } = catalogConstants;
const { HAS_DRY_PRODUCTS_KEY, HAS_FROZEN_PRODUCTS_KEY, HAS_FRESH_PRODUCTS_KEY } = platformConstants;
const { SECTIONS } = filterConstants;

const { catalog, temperature, type } = SECTIONS;

const CatalogFilterForm = ({
  catalogFilter,
  updateFilter,
  filterProps,
  categories,
  isLoadingCategories,
  platform,
  isLoadingPlatform,
}) => {
  const {
    urlParams: { slugGroup },
  } = filterProps;
  const effectiveCategories = categories.filter(({ slug }) => slug !== slugGroup);
  const catalogs = effectiveCategories.filter(({ status }) =>
    [STATUS_CATALOG, STATUS_PREORDER].includes(status)
  );
  const selections = effectiveCategories.filter(({ status }) => status === STATUS_SELECTION);
  const catalogSection = {
    ...catalog,
    options: catalogs.map(({ name, slug }) => ({
      label: name,
      value: slug,
    })),
    isLoading: isLoadingCategories,
  };

  const { attributs } = platform;
  const platformHasDry = {
    label: 'Sec',
    key: TEMPERATURE_DRY,
    value: !!Number(getValueOfAttribut(attributs || [], HAS_DRY_PRODUCTS_KEY)),
  };
  const platformHasFresh = {
    label: 'Frais',
    key: TEMPERATURE_FRESH,
    value: !!Number(getValueOfAttribut(attributs || [], HAS_FRESH_PRODUCTS_KEY)),
  };
  const platformHasFrozen = {
    label: 'Surgelé',
    key: TEMPERATURE_FROZEN,
    value: !!Number(getValueOfAttribut(attributs || [], HAS_FROZEN_PRODUCTS_KEY)),
  };
  const effectiveTemperatures = [platformHasDry, platformHasFresh, platformHasFrozen]
    .filter((option) => !!option.value)
    .map(({ label, key }) => ({
      label,
      value: key,
    }));
  const temperatureSection = {
    ...temperature,
    options: effectiveTemperatures,
    isLoading: isLoadingPlatform,
  };

  const { options: typeOptions } = type;
  const typeSection = {
    ...type,
    options: [
      ...typeOptions.slice(0, 2).filter((option) => option.value !== slugGroup),
      ...selections.map(({ name, slug }) => ({
        label: name,
        value: slug,
        isSelection: true,
      })),
      ...typeOptions.slice(2),
    ],
    isLoading: isLoadingCategories,
  };

  // Hide the filters on catalogs if we are in catalog or preorder categories
  const isInPrimaryCatalogs = !!categories.find(
    ({ slug, status }) => [STATUS_CATALOG, STATUS_PREORDER].includes(status) && slug === slugGroup
  );
  const CATALOG_SECTIONS = isInPrimaryCatalogs
    ? [temperatureSection, typeSection]
    : [catalogSection, temperatureSection, typeSection];

  return (
    <React.Fragment>
      {CATALOG_SECTIONS.map(({ name, sectionKey, options, isLoading }) => (
        <FormSection
          key={name}
          name={name}
          sectionKey={sectionKey}
          options={options}
          selectedValues={catalogFilter[sectionKey] || []}
          updateFilter={updateFilter}
          isLoading={isLoading}
        />
      ))}
      <ManufacturersFilter
        updateFilter={updateFilter}
        selectedValues={catalogFilter.manufacturer || []}
      />
    </React.Fragment>
  );
};

CatalogFilterForm.propTypes = {
  filterProps: PropTypes.object,
  catalogFilter: PropTypes.object,
  updateFilter: PropTypes.func,
  categories: PropTypes.array,
  isLoadingCategories: PropTypes.bool,
  platform: PropTypes.object,
  isLoadingPlatform: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    filter: { props: filterProps },
    user: { information: userInformation },
    category: { categories, isLoading: isLoadingCategories },
    platform: { platforms, selectedId, isLoading: isLoadingPlatform },
  } = state;
  return {
    user: userInformation,
    filterProps,
    categories,
    isLoadingCategories,
    platform: platforms.find((p) => Number(p.id) === Number(selectedId)) || {},
    isLoadingPlatform,
  };
};

export default connect(mapStateToProps)(CatalogFilterForm);
