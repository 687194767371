import styled from 'styled-components';

export const OcrOrderMatchWrapper = styled.div`
  padding: 30px;

  .ocr-order-match__table-container {
    display: flex;
    justify-content: space-around;

    .ocr-order-match__table-wrapper {
      width: 60%;
      display: flex;
      flex-direction: column;

      &:first-child {
        padding-right: 30px;
        width: 40%;
      }

      .react-table__wrapper {
        width: 100%;
        white-space: nowrap;
        max-height: calc(100vh - 225px);
        overflow: auto;

        .ocr-order-match__product-select-button {
          padding: 0 10px;
          display: flex;
          align-items: center;
          width: fit-content;
        }

        .ocr-order-match__quantity-input {
          width: 40px;
          border-color: #ccc;
          border: 1px solid transparent;
          box-shadow: 0 0 1px #000;
          border-radius: 3px;
        }

        thead {
          position: relative;
          z-index: 2;
          white-space: initial;
        }

        tr {
          height: 50px;
          td,
          th {
            padding: 5px;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
          background-color: #fefefe;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #ccc;
        }
      }
    }
  }

  .ocr-order-match__footer {
    width: 100%;
    padding: 10px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;

    .ocr-order-match__delivery-form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ocr-order-match__delivery-container {
        display: flex;
        align-items: center;

        .ocr-order-match__delivery-title {
          margin-right: 5px;
          font-weight: bold;
          color: #01366b;
        }

        .ocr-order-match__delivery-item-container {
          display: flex;

          .ocr-order-match__delivery-item {
            display: flex;
            align-items: center;

            i {
              margin: 0 10px;
              &:before {
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
              }
            }
          }
        }

        .delivery-date-popper {
          left: 0 !important;
          top: -310px !important;
        }
      }

      .ocr-order-match__comment-container {
        display: flex;
        align-items: center;
      }
    }

    .ocr-order-match__footer-button-container {
      display: flex;
      align-items: center;

      a {
        min-width: 180px;
        padding: 10px;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
  }

  .ocr-order-match__title {
    margin-right: 5px;
    font-weight: bold;
    color: #01366b;
  }
`;
