import { numberToPrice } from 'helpers';

export const updateFields = (data, type) =>
  data.map((commercial) =>
    type === 'monthly'
      ? {
          lastname: commercial ? `${commercial.code || ''} ${commercial.lastname || ''}` : '',
          monthly_ca: numberToPrice(commercial.monthly_turnover || 0),
          monthly_edge: numberToPrice(commercial.month_margin || 0),
          percent:
            commercial.monthly_turnover && commercial.monthly_turnover !== 0
              ? Number(((commercial.month_margin * 100) / commercial.monthly_turnover).toFixed(2))
              : 0,
          ratioCA:
            commercial.goal_turnover && commercial.goal_turnover !== 0
              ? Number(((commercial.monthly_turnover * 100) / commercial.goal_turnover).toFixed(2))
              : 0,
          ratioEdge:
            commercial.goal_store && commercial.goal_store !== 0
              ? Number(((commercial.monthly_turnover * 100) / commercial.goal_store).toFixed(2))
              : 0,
        }
      : {
          lastname: commercial ? `${commercial.username || ''} ${commercial.lastname || ''}` : '',
          monthly_ca: numberToPrice(Number(commercial.total_orders_vat) || 0),
          commandNumber: commercial.count_orders || 0,
          clientNumber: commercial.count_clients || 0,
          cartEdge:
            commercial.total_orders_vat && commercial.count_orders !== 0
              ? numberToPrice((commercial.total_orders_vat / commercial.count_orders).toFixed(2))
              : 0,
        }
  );
