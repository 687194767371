import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withStyle from './Alert.style';

const TYPE_DANGER = 'danger';
const TYPE_MESSAGE = 'message';

const AlertIcons = {
  [TYPE_DANGER]: '\\e90b',
  [TYPE_MESSAGE]: '\\e930',
};

const AlertTypes = {
  [TYPE_DANGER]: {
    backgroundColor: '#F9D9DD',
    border: '1px solid rgba(208,2,27,0.2)',
    color: '#D0021B',
  },
  [TYPE_MESSAGE]: {
    backgroundColor: 'rgba(180, 228, 245, 0.2)',
    border: '1px solid #01366B',
    color: '#01366B',
  },
};

const Alert = ({ className, type, subject, text }) => (
  <div className={className}>
    <div className="page-profil__alert-block" style={AlertTypes[type]}>
      <div className="alert-block__icon-alert">
        <Icon content={AlertIcons[type]} />
      </div>
      <div className="alert-block__label">
        <p className="bold" hidden={!subject}>
          {subject}
        </p>
        <p className="regular">{text}</p>
      </div>
    </div>
  </div>
);

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.string,
};

Alert.defaultProps = {
  type: 'danger',
};

export default withStyle(Alert);
