export const containerConstants = {
  MODE_VRAC: 'VRAC',
  MODE_PALETTE: 'PALETTE',

  TYPE_CONTAINER_DRY: 'DRY',
  TYPE_CONTAINER_REEFER: 'REEFER',

  STATE_PACKER_DRY: 'STATE_PACKER_DRY',
  STATE_PACKER_REEFER: 'STATE_PACKER_REEFER',

  STATE_CONTAINERS_DRY: 'STATE_CONTAINERS_DRY',
  STATE_CONTAINERS_REEFER: 'STATE_CONTAINERS_REEFER',

  DELETE_CONTAINERS: 'DELETE_CONTAINERS',
  CHANGE_LOGISTIC: 'CONTAINER_LOGISTIC_CHOICE',
  CHANGE_STATUS: 'CONTAINER_STATUS_CHANGE',

  FETCH_REQUEST: 'CONTAINER_ITEMS_FETCH_REQUEST',
  FETCH_SUCCESS: 'CONTAINER_ITEMS_FETCH_SUCCESS',
  FETCH_FAILURE: 'CONTAINER_ITEMS_FETCH_FAILURE',

  SAVE_REQUEST: 'CONTAINER_ITEMS_SAVE_REQUEST',
  SAVE_SUCCESS: 'CONTAINER_ITEMS_SAVE_SUCCESS',
  SAVE_FAILURE: 'CONTAINER_ITEMS_SAVE_FAILURE',

  GET_REQUEST: 'CONTAINER_ITEMS_GET_REQUEST',
  GET_SUCCESS: 'CONTAINER_ITEMS_GET_SUCCESS',
  GET_FAILURE: 'CONTAINER_ITEMS_GET_FAILURE',

  DELETE_REQUEST: 'CONTAINER_ITEMS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CONTAINER_ITEMS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CONTAINER_ITEMS_DELETE_FAILURE',
};
