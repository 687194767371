// eslint-disable-next-line import/no-unresolved
import { v4 as uuid } from 'uuid';
import { termsService } from 'services';
import { termsConstants } from '../constants';

export const termsActions = {
  deleteTerms,
  fetchTerms,
  fetchTotalRowsNumber,
  updateTerms,
  createTerms,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    termsService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: termsConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: termsConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: termsConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchTerms(filter = {}, offset = 0, limit = 100) {
  return (dispatch) => {
    dispatch(request());
    termsService.fetchItems(filter, offset * limit, limit).then(
      (items) => {
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: termsConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return { type: termsConstants.FETCH_SUCCESS, items, filter, offset };
  }
  function failure(error) {
    return { type: termsConstants.FETCH_FAILURE, error };
  }
}

function createTerms(data) {
  return (dispatch) => {
    const uid = uuid();
    const {
      platform: { id: platformId },
    } = data;
    dispatch(request({ ...data, uid }, { platform: platformId }));
    return termsService.createTerm(data).then(
      (term) => {
        dispatch(success({ ...term, uid }, { platform: data.platform.id }));
      },
      (error) => {
        dispatch(failure(error.toString(), uid));
      }
    );
  };
  function request(term, filter) {
    return { type: termsConstants.CREATE_REQUEST, term, filter };
  }
  function success(term, filter) {
    return { type: termsConstants.CREATE_SUCCESS, term, filter };
  }
  function failure(error, uid) {
    return { type: termsConstants.CREATE_FAILURE, error, uid };
  }
}

function updateTerms(id, term, hasChangedFile) {
  return (dispatch) => {
    dispatch(request({ ...term, id }, { platform: term.platform_id }));
    termsService.updateTerm(id, term, hasChangedFile).then(
      (newTerm) => {
        dispatch(success({ ...newTerm }, { platform: newTerm.platform_id }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(newTerm, filter) {
    return { type: termsConstants.UPDATE_REQUEST, term: newTerm, filter };
  }
  function success(newTerm, filter) {
    return { type: termsConstants.UPDATE_SUCCESS, term: newTerm, filter };
  }
  function failure(error) {
    return { type: termsConstants.UPDATE_FAILURE, error };
  }
}

function deleteTerms(termId) {
  return (dispatch) => {
    dispatch(request());
    termsService.deleteTerm(termId).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: termsConstants.DELETE_REQUEST, termId };
  }
  function success() {
    return { type: termsConstants.DELETE_SUCCESS, termId };
  }
  function failure(error) {
    return { type: termsConstants.DELETE_FAILURE, error };
  }
}
