import isEqual from 'lodash.isequal';
import { commercialConstants } from '../constants';

const initState = {
  collections: [],
  collectionsCount: [],
  salesNumbers: [],
};

const addCommercialToCollections = (collections, commercial) => {
  collections.forEach((collection) => {
    const { items } = collection;
    const newItems = items ? items.filter((item) => Number(item.id) !== Number(commercial.id)) : [];
    newItems.push(commercial);
    Object.assign(collection, { items: newItems, isLoading: false });
  });

  return collections;
};

export function commercial(state = initState, action) {
  if (!Object.keys(commercialConstants).find((key) => commercialConstants[key] === action.type)) {
    return state;
  }

  const { collections } = state;
  let { collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case commercialConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case commercialConstants.FETCH_COUNT_SUCCESS: {
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      };
      const newCollectionsCount = [
        ...collectionsCount.filter((c) => !isEqual(newCollectionCount.filter, c.filter)),
        newCollectionCount,
      ];
      return {
        ...state,
        collectionsCount: newCollectionsCount,
      };
    }
    case commercialConstants.FETCH_COUNT_FAILURE:
      return state;
    case commercialConstants.FETCH_REQUEST: {
      const newCollection = { ...collection, isLoading: true };
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case commercialConstants.FETCH_SUCCESS: {
      const newCollection = {
        ...collection,
        items: collection.items || [],
        isLoading: false,
      };
      newCollection.items[action.offset] = action.items;
      const newCollections = [
        ...collections.filter((c) => !isEqual(newCollection.filter, c.filter)),
        newCollection,
      ];
      return {
        ...state,
        collections: newCollections,
      };
    }
    case commercialConstants.FETCH_FAILURE:
      return state;
    case commercialConstants.FETCH_SALES_NUMBERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case commercialConstants.FETCH_SALES_NUMBERS_SUCCESS:
      return {
        ...state,
        salesNumbers: action.salesNumbers,
        isLoading: false,
      };
    case commercialConstants.FETCH_SALES_NUMBERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case commercialConstants.ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case commercialConstants.ADD_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items && collection.items[0]) {
          collection.items[0].unshift(action.commercial);
        }
        return { ...collection };
      });
      const newCollectionCount = {
        ...collectionCount,
        totalRowsNumber: collectionCount.totalRowsNumber + 1,
      };
      return {
        ...state,
        isLoading: false,
        collections: newCollections,
        collectionsCount: [newCollectionCount],
      };
    }
    case commercialConstants.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case commercialConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case commercialConstants.UPDATE_SUCCESS: {
      const newCollections = collections.map((collection) => {
        if (collection.items) {
          return {
            ...collection,
            items: collection.items.map((page) => {
              const newPage = [...page];
              const itemIndex = page.findIndex((item) => item.id === action.commercial.id);
              if (itemIndex !== -1) {
                newPage[itemIndex] = action.commercial;
              }

              return newPage;
            }),
          };
        }

        return collection;
      });

      return {
        ...state,
        isLoading: false,
        collections: newCollections,
      };
    }
    case commercialConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
