/* eslint-disable no-nested-ternary */
// Importing catalogConstants from the absolute path (`constants`) bugs.
import { catalogConstants, tableConstants } from '../constants';

const { DIRECTION_AFTER, DIRECTION_BEFORE } = tableConstants;

const initState = {
  collections: [],
  collectionsCount: [],
  lastAction: {},
  topSales: {},
  exportCatalogFile: { isLoading: false },
  topSellingItems: {
    isLoading: false,
    count: 0,
    data: [],
    error: null,
  },
};

export function catalog(state = initState, action) {
  if (!Object.keys(catalogConstants).find((key) => catalogConstants[key] === action.type)) {
    return state;
  }

  let { collections, collectionsCount } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((c) => JSON.stringify(c.filter) === JSON.stringify(filter));

  if (!collection && action.type === catalogConstants.FETCH_REQUEST) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }

  let collectionCount = collectionsCount.find(
    (c) => JSON.stringify(c.filter) === JSON.stringify(filter)
  );

  if (!collectionCount && action.type === catalogConstants.FETCH_COUNT_REQUEST) {
    collectionCount = { filter, isLoading: true };
    collectionsCount.push(collectionCount);
  }

  switch (action.type) {
    case catalogConstants.FETCH_COUNT_REQUEST:
      collectionCount = Object.assign(collectionCount, { isLoading: true });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case catalogConstants.FETCH_COUNT_SUCCESS:
      collectionCount = Object.assign(collectionCount, {
        totalRowsNumber: action.numberDataAvailable,
        isLoading: false,
      });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case catalogConstants.FETCH_COUNT_FAILURE:
      collectionCount = Object.assign(collectionCount, { isLoading: false });
      collectionsCount = Object.assign([collectionCount], collectionsCount);
      return {
        ...state,
        collectionsCount,
      };
    case catalogConstants.FETCH_REQUEST:
      collection = Object.assign(collection, { isLoading: true });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case catalogConstants.FETCH_SUCCESS:
      collection = Object.assign(collection || { items: {} }, {
        items:
          action.direction === DIRECTION_AFTER
            ? (collection.items || []).concat(action.items)
            : action.direction === DIRECTION_BEFORE
            ? action.items.concat(collection.items || [])
            : action.items,
        isLoading: false,
      });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case catalogConstants.FETCH_FAILURE:
      collection = Object.assign(collection, { isLoading: false });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case catalogConstants.UPDATE_FAVORITE: {
      if (collection) {
        const productFavorite = collection.items.find(
          (item) => item.product_id === action.productId
        );
        productFavorite.is_favorite = productFavorite.is_favorite
          ? !productFavorite.is_favorite
          : true;
        collection.items = Object.assign(collection.items, productFavorite);
        collections = Object.assign([collection], collections);
      }
      return {
        ...state,
        collections,
      };
    }
    case catalogConstants.FETCH_TOP_SALES_LIST_REQUEST:
      return {
        ...state,
        topSales: {
          ...state.topSalesItemIdList,
          isLoading: true,
        },
      };
    case catalogConstants.FETCH_TOP_SALES_LIST_SUCCESS:
      return {
        ...state,
        topSales: {
          ...state.topSalesItemIdList,
          isLoading: false,
          items: action.topSalesItemIdList,
        },
      };
    case catalogConstants.FETCH_TOP_SALES_LIST_FAILURE:
      return {
        ...state,
        topSales: {
          ...state.topSalesItemIdList,
          isLoading: false,
          error: action.error,
        },
      };
    case catalogConstants.EXPORT_CATALOG_FILE_REQUEST:
      return {
        ...state,
        exportCatalogFile: {
          isLoading: true,
        },
      };
    case catalogConstants.EXPORT_CATALOG_FILE_SUCCESS:
      return {
        ...state,
        exportCatalogFile: {
          isLoading: false,
        },
      };
    case catalogConstants.EXPORT_CATALOG_FILE_FAILURE:
      return {
        ...state,
        exportCatalogFile: {
          isLoading: false,
          error: action.error,
        },
      };

    case catalogConstants.FETCH_TOP_SELLING_ITEMS_REQUEST:
      return {
        ...state,
        topSellingItems: {
          isLoading: true,
        },
      };
    case catalogConstants.FETCH_TOP_SELLING_ITEMS_SUCCESS:
      return {
        ...state,
        topSellingItems: {
          ...state.topSellingItemsList,
          isLoading: false,
          count: action.TopSellingItemsList.topSellingItems
            ? action.TopSellingItemsList.topSellingItems.length
            : 0,
          data: action.TopSellingItemsList.topSellingItems || [],
        },
      };
    case catalogConstants.FETCH_TOP_SELLING_ITEMS_FAILURE:
      return {
        ...state,
        topSellingItems: {
          isLoading: false,
          itemCount: 0,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
