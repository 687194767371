import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const creditService = {
  fetchItems,
  fetchTotalRowNumber,
  createItems,
};

function fetchItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify({ platform: filter.platform, id: filter.id });

  return fetch(
    `${config.apiUrl}/api/credit?filter=${urlFilter}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify({ platform: filter.platform });
  return fetch(`${config.apiUrl}/api/credit/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function createItems(creditList) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    mode: 'cors',
    body: JSON.stringify(creditList),
  };

  return fetch(`${config.apiUrl}/api/credit/add`, requestOptions).then(handleResponse);
}
