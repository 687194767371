import React from 'react';
import { Link } from 'react-router-dom';
import map from 'lodash.map';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withOngletMenuStyle from './OngletMenu.style';

function OngletMenu({ className, list }) {
  return (
    <div className={className}>
      <div className="onglet-menu">
        <ul>
          {map(list, (tab, index) => (
            <li className={tab.status} key={index}>
              {tab.href && (
                <Link
                  to={{
                    pathname: tab.href,
                  }}
                >
                  {tab.text}
                </Link>
              )}
              {tab.handle && <span onClick={() => tab.handle()}>{tab.text}</span>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
OngletMenu.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
};

export default withOngletMenuStyle(OngletMenu);
