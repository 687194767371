import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions, termsActions } from 'actions';
import { getPath } from 'helpers';
import Icon from 'components/Icon/Icon';
import BreadCrumb from 'components/Breadcrumb/Breadcrumb';
import OngletMenu from 'components/OngletMenu/OngletMenu';
import { ButtonPrimary } from 'components/Core/Button/Button';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { selectTermByFilter, selectTermCountByFilter } from 'selectors/term';
import { FaPencilAlt } from '@react-icons/all-files//fa/FaPencilAlt';
import Moment from 'moment';
import { termsConstants } from '../../constants';
import WithTermsStyles from './WithTermsStyles';

const limit = 25;
const { STATUS_ACTIVE, STATUS_INACTIVE } = termsConstants;

const Terms = ({
  className,
  selectedPlatformId,
  termList,
  termCount,
  fetchTermList,
  fetchTermTotalRowCount,
  openTermsModal,
  openExtractExcel,
  toggleTermsStatus,
  props,
}) => {
  const url = [
    {
      text: 'Accueil ',
      href: getPath('dashboardAdmin'),
    },
    {
      text: 'Paramètres',
    },
  ];
  const currentPage = 'CGV appliquées';

  const ongletList = [
    {
      text: 'Mes commerciaux',
      href: getPath('commercial'),
    },
    {
      text: 'Plannings de livraison',
      href: getPath('planning'),
    },
    {
      text: 'Jours fériés & fermeture',
      href: getPath('dayoff'),
    },
    {
      text: 'CGV appliquées',
      href: getPath('terms'),
      status: 'active',
    },
    {
      text: 'Paramètres précommande',
      href: getPath('settingsPreorder'),
    },
    {
      text: 'Messagerie client',
      href: getPath('message'),
    },
    {
      text: 'Extract XLS preco',
      handle: openExtractExcel,
    },
  ];

  const filter = { platform: selectedPlatformId };
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [termTotalRowCount, setTermTotalRowCount] = useState(null);

  useEffect(() => {
    fetchTermTotalRowCount(filter);
    fetchTermList(filter, offset, limit);
  }, []);

  useEffect(() => {
    if (termList && termList.items && !termList.isLoading) {
      setData(termList.items.flat());
    }
  }, [termList]);

  useEffect(() => {
    if (termCount) {
      if (termCount && !termCount.isLoading) {
        setTermTotalRowCount(termCount.totalRowsNumber);
      }
    }
  }, [termCount]);

  const columns = React.useMemo(() => [
    {
      id: 'cgv',
      Header: 'CGV',
      accessor: (item) => item.title || '-',
    },
    {
      id: 'dateCreate',
      Header: 'Mise en ligne',
      accessor: (item) => item.date_create || '-',
    },
    {
      id: 'endDate',
      Header: 'Fin de validité',
      accessor: (item) => item.date_unactivated || '-',
    },
    {
      id: 'client',
      Header: 'Clients',
      accessor: (item) => item.count_clients || '-',
    },
    {
      id: 'actions',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="page-message__table__actions">
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                if (value.count_clients === 0) {
                  toggleTermsStatus(value, value.status);
                }
              }}
              checked={value.status === STATUS_ACTIVE}
            />
            <span className="slider round" />
          </label>

          <span
            onClick={() => {
              openTermsModal(filter, selectedPlatformId, value);
            }}
            className="edit"
          >
            <FaPencilAlt /> Editer
          </span>
        </div>
      ),
      className: 'actions',
    },
  ]);

  return (
    <div className={className}>
      <div className="page-terms">
        <div className="page-terms__heading">
          <div className="heading__path">
            <BreadCrumb urlTab={url} currentPage={currentPage} />
            <OngletMenu list={ongletList} />
          </div>
        </div>
        <div className="page-terms__actions">
          <ButtonPrimary
            inverted
            className="page-terms___add-button"
            onClick={() => openTermsModal(filter, selectedPlatformId)}
          >
            <Icon content="\e900" />
            <span>AJOUTER CGV</span>
          </ButtonPrimary>
        </div>
        <div className="page-terms__content">
          {selectedPlatformId && (
            <ReactTable
              columns={columns}
              data={data}
              isLoading={termList && termList.isLoading}
              next={() => {
                fetchTermList(filter, offset + 1, limit);
                setOffset(offset + 1);
              }}
              totalRowCount={termTotalRowCount}
              loadedRowCount={data.length}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Terms.propTypes = {
  className: PropTypes.string,
  openTermsModal: PropTypes.func,
  selectedPlatformId: PropTypes.number,
  openExtractExcel: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { platform } = state;
  const selectedPlatformId = platform.selectedId;
  const filter = { platform: selectedPlatformId };
  return {
    selectedPlatformId,
    termList: selectTermByFilter(state, filter),
    termCount: selectTermCountByFilter(state, filter),
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchTermList: (filter, offset, limit) =>
    dispatch(termsActions.fetchTerms(filter, offset, limit)),
  fetchTermTotalRowCount: (filter) => dispatch(termsActions.fetchTotalRowsNumber(filter)),
  openTermsModal: (filter, platformId, term = null) =>
    dispatch(
      modalActions.open('addTerms', {
        width: '400',
        platformId,
        term,
        modalHeader: 'Importer nouvelles CGV',
      })
    ),
  toggleTermsStatus: (term, termStatus) => {
    const status = termStatus === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;
    const dateUnactivated = status === STATUS_ACTIVE ? null : Moment().format('YYYY-MM-DD');
    if (term.id) {
      dispatch(
        termsActions.updateTerms(term.id, {
          id: term.id,
          status,
          date_unactivated: dateUnactivated,
        })
      );
    }
  },
  openExtractExcel: () =>
    dispatch(
      modalActions.open('extractExcelPreorder', {
        dateSelected: new Date(),
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(WithTermsStyles(Terms));
