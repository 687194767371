import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'babel-polyfill';
import Dotenv from 'dotenv';
import { Router } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import frLocale from 'date-fns/locale/fr';

import { initializeTagManager } from 'utils/analytics/tagmanager';
import { store, history } from 'helpers';
import { uiActions } from 'actions/Ui';
import App from 'App.jsx';

import 'styles/assets/css/global-styles.css';
import 'styles/assets/css/iconStyle.css';

registerLocale('fr', frLocale);

Dotenv.config();

initializeTagManager();

// set a custom vh property equal to 1% of the innerHeight
// to prevent shift from mobile browser due to url bar
document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
  store.dispatch(uiActions.screenResize(window.innerWidth));
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
