import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import Color from 'styles/assets/images/Color.png';
import withModalErrorStyle from './ModalErrorCart.style';

const ModalAppStore = ({ className, closeModal }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold" style={{ paddingBottom: 0 }}>
        <h2>
          Application
          <br />
          Commande.com
        </h2>
        <br />
        <img src={Color} width={90} height={90} alt="Commande.com Mobile App" />
        <p style={{ fontSize: '90%' }}>
          Afin de vous proposer une expérience optimale, nous vous conseillons de vous rendre sur
          les stores de votre appareil préféré.
        </p>
        <p>
          <u>Téléchargement ci dessous:</u>
        </p>

        <div className="appStore" style={{ display: 'flex', padding: '10px 20px' }}>
          <div className="storeApple">
            <a
              href="https://apps.apple.com/fr/app/commande-com/id1524993649?itsct=apps_box_badge&amp;itscg=30200"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_new"
              style={{
                // display: 'inline-block',
                // overflow: 'hidden',
                borderRadius: '13px',
                width: '123px',
                height: '34px',
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?size=250x83&amp;releaseDate=1595721600"
                alt="Download on the App Store"
                style={{
                  borderRadius: '13px',
                  width: '123px',
                  height: '34px',
                  marginTop: '5px',
                }}
              />
            </a>
          </div>
          <div className="storeAndroid">
            <a
              href="https://play.google.com/store/apps/details?id=com.commande.app&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_new"
            >
              <img
                alt="Disponible sur Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                width={123}
                height={44}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ModalAppStore.propTypes = {
  className: PropTypes.string,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(withModalErrorStyle(ModalAppStore));
