import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BsStar } from '@react-icons/all-files/bs/BsStar';
import { BsStarFill } from '@react-icons/all-files/bs/BsStarFill';
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';
import { orderActions } from 'actions/Order.js';
import LinearLoader from 'components/Core/Spinner/LinearLoader';
import Alert from 'components/Alert/Alert';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { selectValidatedCartOrderId } from 'selectors/cart';
import './OrderRating.scss';

class OrderRating extends React.Component {
  constructor() {
    super();
    this.state = {
      rating: 0,
      comment: null,
    };

    this.handleSubmitOrderRating = this.handleSubmitOrderRating.bind(this);
  }

  handleStarClick = (rating) => {
    this.setState({ rating });
  };

  handleChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  handleSubmitOrderRating = () => {
    const { rating, comment } = this.state;
    const { orderId } = this.props;

    if (rating < 1) return;

    this.props.submitOrderRating(orderId, rating, comment);
  };

  buildStars = () => {
    const { rating } = this.state;
    const stars = [];

    for (let i = 0; i < 5; i += 1) {
      const starToPush =
        i < rating ? (
          <BsStarFill
            onClick={() => {
              this.handleStarClick(i + 1);
            }}
          />
        ) : (
          <BsStar
            onClick={() => {
              this.handleStarClick(i + 1);
            }}
          />
        );
      stars.push(starToPush);
    }

    return stars;
  };

  render() {
    const { isLoading, rating, error } = this.props;

    return (
      <div className="App OrderRating__App">
        {isLoading === true && (
          <div className="OrderRating__overlay">
            <LinearLoader />
          </div>
        )}

        {isLoading === false && rating && (
          <div className="OrderRating__overlay">
            <BsCheck />
            <span>Merci, votre commentaire a bien été pris en compte !</span>
          </div>
        )}

        <h2 className="OrderRating__title">Notez votre expérience de commande !</h2>
        <div className="OrderRating__errors">
          {error && error.message && <Alert text="Une erreur est survenue !" />}
        </div>
        <div className="OrderRating__stars-container">{this.buildStars()}</div>
        <div className="OrderRating__comment-container">
          <textarea
            placeholder="Votre commentaire..."
            onChange={(event) => {
              this.handleChange(event);
            }}
          />
        </div>
        <ButtonPrimary
          disabled={this.state.rating < 1}
          className="OrderRating__submit-button"
          onClick={this.handleSubmitOrderRating}
        >
          Valider la note
        </ButtonPrimary>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  submitOrderRating: (orderId, rating, comment) =>
    dispatch(orderActions.rateOrder(orderId, rating, comment)),
});

const mapStateToProps = (state) => {
  const {
    order: { rating },
  } = state;

  const orderId = selectValidatedCartOrderId(state);
  return {
    isLoading: rating.isLoading,
    error: rating.error,
    rating: rating[orderId],
    orderId,
  };
};

OrderRating.propTypes = {
  rating: PropTypes.shape({
    rating: PropTypes.number,
    comment: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  submitOrderRating: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRating);
