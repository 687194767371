import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal__button {
    text-align: center;
    &--cancel,
    &--send {
      padding: 13px 25px;
      display: inline-block;
      margin: 20px;
    }
  }

  .modal-body {
    span {
      color: #01366b;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      display: inline-block;
    }
    &__stock {
      margin: 30px 0;
    }
    &__quantity {
      color: #4a4a4a;
      width: 120px;
      float: right;
      text-align: center;
    }
    .icon-less-circle,
    .icon-add-circle {
      font-size: 20px;
      vertical-align: middle;
    }
  }
`;
