import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonPrimary } from 'components/Core/Button/Button';
import Calendar from 'components/Core/Calendar/Calendar';
import Moment from 'moment';
import { orderActions } from 'actions';
import { getFormatedDate } from 'helpers/Utils';
import withModalExtractExcelPreorderStyle from './ModalExtractExcelPreorder.style';

class ModalExtractExcelPreorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      since: getFormatedDate(new Date()),
      to: getFormatedDate(new Date()),
    };
  }

  handleDatesChangeSince = (since) => {
    this.setState({ since: getFormatedDate(since) });
  };

  handleDatesChangeTo = (to) => {
    this.setState({ to: getFormatedDate(to) });
  };

  render() {
    const { className, downloadXls } = this.props;
    const { since } = this.state;
    const { to } = this.state;

    return (
      <div className={className}>
        <div className="modal-body">
          <h4>Télécharger les précommandes d'une période au format XLS</h4>
          <div className="modal-body__message">
            <div className="date-bloc">
              <label>Date de début</label>
              <div className="delivery-date__calendar-bloc">
                <Calendar
                  onChange={(since) => this.handleDatesChangeSince(since)}
                  selected={since ? Moment(since).toDate() : ''}
                  InputClassName="permanent-cart-input__Date"
                />
              </div>
            </div>
            <div className="date-bloc">
              <label>Date de fin</label>
              <div className="delivery-date__calendar-bloc">
                <Calendar
                  onChange={(to) => this.handleDatesChangeTo(to)}
                  selected={to ? Moment(to).toDate() : ''}
                  InputClassName="permanent-cart-input__Date"
                />
              </div>
            </div>
          </div>
          <ButtonPrimary
            className="modal-body__button"
            onClick={() => downloadXls(this.state.since, this.state.to)}
          >
            <span className="bold">Télécharger</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalExtractExcelPreorder.propTypes = {
  className: PropTypes.string,
  downloadXls: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  downloadXls: (since, to) => dispatch(orderActions.downloadPreorderXls(since, to)),
});

export default connect(
  null,
  mapDispatchToProps
)(withModalExtractExcelPreorderStyle(ModalExtractExcelPreorder));
