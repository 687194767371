export default {
  main: {
    default: '#4A4A4A',
    inactive: '#656565',
    disabled: '#F5F5F5',
  },
  primary: {
    default: '#01366b',
    hover: '#335e88',
    inactive: 'rgba(1,54,107,0.6)',
    active: '#0264A2',
    disabled: '#6787a7',
  },
  secondary: {
    default: '#54BAFC',
    hover: '#24b1ff',
    inactive: '#B7DEFE',
    active: '#4FB6FE',
    disabled: '#66c9ff',
  },
  tertiary: [
    {
      // green
      default: '#29D24E',
      active: '#29D24E',
      hover: '#40E0D0',
      disabled: '#A4A4A4',
    },
    {
      // yellow
      default: '#F5A623',
      active: '#F5A623',
      hover: '#F5A623',
      disabled: '#A4A4A4',
    },
  ],
  background: {
    default: '#F5F5F5',
    light: '#FFFFFF',
    dark: '#E2E2E2',
    inactive: '#AAAAAA',
  },
  error: {
    default: '#D0021B',
    background: '#F9D9DD',
    border: 'rgba(208,2,27,0.2)',
  },
};
