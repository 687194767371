export const GROUP_BY = {
  cellIndex: null,
  comparisonDataKey: null,
};

export const COLUMNS_PRODUCT = [
  {
    type: 'PRODUCT_IMAGE',
    name: 'Image',
    className: 'header_product-image',
  },
  {
    type: 'PRODUCT_WORDING',
    name: 'Libellé produit',
    className: 'header_product-wording',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Marque',
    className: 'header_product-brand',
    dataType: 'manufacturer',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'PCB',
    className: 'header_product-pcb',
    dataType: 'pcb',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Prix unitaire HT',
    className: 'header_product-price',
    dataType: 'price',
  },
  {
    type: 'PRODUCT_QUANTITY',
    name: 'Quantités',
    className: 'header_product-quantity',
    dataType: 'quantity',
  },
  {
    type: 'PRODUCT_PRICE_TOTAL',
    name: 'Total HT',
    className: 'header_product-total',
    dataType: 'final_price',
  },
];

export const COLUMNS_ORDER_PENDING = [
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Date',
    className: 'header_product-date',
    dataType: 'date_create',
    dataFormat: 'date',
    style: {
      isBold: true,
      isSmall: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Client',
    className: 'header_order-number',
    dataType: 'user.client_name',
    style: {
      isBold: true,
      isSmall: true,
    },
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Temperature',
    className: 'header_order-temperature',
    dataType: 'temperature',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Références',
    className: 'header_product-pcb',
    dataType: 'order_references',
  },
  {
    type: 'PRODUCT_TEXT_BLOC',
    name: 'Total',
    className: 'header_product-total',
    dataType: 'total_price',
    dataFormat: 'price',
    style: {
      isBold: true,
    },
  },
];
