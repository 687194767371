import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BsBoxArrowInDown as UploadIcon } from '@react-icons/all-files/bs/BsBoxArrowInDown';
import { BsCheck as CheckIcon } from '@react-icons/all-files/bs/BsCheck';
import { selectClientCollectionByFilter } from 'selectors/client';
import { clientActions, modalActions, ocrOrderPdfActions } from 'actions';
import ClientDropdownSelect from 'components/Core/DropdownSelect/ClientDropdownSelect/ClientDropdownSelect';
import { selectCurrentPlatformId } from 'selectors/platform';
import Spinner from 'components/Core/Spinner/Spinner';
import { clientStatusConstants } from '../../../constants';
import { ImportPdfOrderFormWrapper } from './ImportPdfOrderForm.style';

const { STATUS_ACTIVE } = clientStatusConstants;

const ImportPdfOrderForm = ({
  fetchClientList,
  storeClientList,
  closeModal,
  addOcrJob,
  currentPlatformId,
}) => {
  const filesInput = useRef(null);
  const dropdownSelectRef = useRef(null);
  const [clientList, setClientList] = useState();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    fetchClientList({ platform: currentPlatformId });
    dropdownSelectRef.current.focus();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      dropdownSelectRef.current.blur();
    }
  });

  useEffect(() => {
    if (storeClientList && storeClientList.items) {
      setClientList({
        ...storeClientList,
        items: storeClientList.items.filter((client) => client.status === STATUS_ACTIVE),
      });
    }
  }, [storeClientList]);

  const handleClickCallOcrAPI = async (event) => {
    setUploading(true);
    const callOcrAPIwithDelay = (d) => {
      delay(2000).then((x) => {
        addOcrJob(selectedClient.id, file[d]);
        d += 1; // eslint-disable-line no-param-reassign
        if (d < file.length) callOcrAPIwithDelay(d);
        else closeModal();
      });
    };
    callOcrAPIwithDelay(0);
  };

  const delay = (milliseconds) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(milliseconds);
      }, milliseconds);
    });
  };

  const filesArr = file ? [...file] : [];

  return (
    <ImportPdfOrderFormWrapper>
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="import-pdf-order__client-select-container">
          <span className="import-pdf-order__client-label">Client : </span>
          <ClientDropdownSelect
            clientList={clientList && clientList.items}
            selectedItem={selectedClient}
            setSelectedItem={setSelectedClient}
            inputRef={dropdownSelectRef}
            isLoading={clientList && clientList.isLoading}
          />
        </div>
        <div className="import-pdf-order__input-container">
          <input
            className="import-pdf-order__file-input"
            type="file"
            ref={filesInput}
            name="file"
            multiple
            placeholder={null}
            onChange={(event) => {
              setFile(event.target.files);
            }}
            accept=".pdf,application/pdf"
          />
          <div className="import-pdf-order__fake-file-input">
            {!file ? (
              <>
                <UploadIcon />
                <span>Déposer ou Sélectionner un ou plusieurs fichier(s) PDF de commande.</span>
              </>
            ) : (
              <>
                <CheckIcon style={{ fill: '#28a745' }} />
                <span>
                  Votre(Vos) fichier(s) :<br />
                  <br />
                  {filesArr.map((filePdf, i) => (
                    <span key={i}>
                      {filePdf.name}
                      <br />
                    </span>
                  ))}
                  <br />
                  <br />
                  a(ont) bien été importé(s){' '}
                </span>
                {uploading && (
                  <>
                    Upload en cours : <Spinner size="20" />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="import-pdf-order__buttons-container">
          <button className="import-pdf-order__cancel-button" type="button" onClick={closeModal}>
            Fermer
          </button>
          <button
            className="import-pdf-order__submit-button"
            type="button"
            onClick={handleClickCallOcrAPI}
            disabled={!selectedClient || !file || uploading}
          >
            Envoyer
          </button>
        </div>
      </form>
    </ImportPdfOrderFormWrapper>
  );
};

ImportPdfOrderForm.propTypes = {};

const mapStateToProps = (state) => {
  const currentPlatformId = selectCurrentPlatformId(state);

  return {
    storeClientList: selectClientCollectionByFilter(state, { platform: currentPlatformId }),
    currentPlatformId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClientList: (filter) => dispatch(clientActions.fetchClients(filter, 0, 10000)),
  closeModal: () => dispatch(modalActions.close()),
  addOcrJob: (clientId, file) => dispatch(ocrOrderPdfActions.addOcrJob(clientId, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportPdfOrderForm);
