import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'layouts/Layout';
import InvoiceDetail from './InvoiceDetail';

const InvoiceDetailLayout = ({ match }) => <Layout component={InvoiceDetail} match={match} />;

InvoiceDetailLayout.propTypes = {
  match: PropTypes.object,
};
export default InvoiceDetailLayout;
