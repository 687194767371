import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { assortmentActions, modalActions } from 'actions';
import Icon from 'components/Icon/Icon';

const AssortmentActionsTD = styled.td`
  color: #d0021b;
  font-size: 16px;
  .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 34px;
      height: 20px;
      margin: 5px 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #01366b;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #01366b;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  i {
    cursor: pointer;
  }
`;

const AssortmentActionsDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;

  color: #d0021b;
  display: block;
  text-align: center .action-block {
    color: #01366b;
    font-size: 16px;
    i {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
    }

    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 60px;
      height: 34px;
      margin: 5px 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #01366b;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #01366b;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  i {
    cursor: pointer;
  }
`;

const AssortmentActions = ({
  className,
  shouldShowResponsive,
  assortment,
  toggleAssortment,
  openDeleteModal,
  filter,
}) =>
  !shouldShowResponsive ? (
    <AssortmentActionsTD className={className}>
      <div className="action-block">
        <label className="switch">
          <input
            type="checkbox"
            onClick={() => {
              toggleAssortment(assortment, filter);
            }}
            checked={Number(assortment.enabled) === 1}
          />
          <span className="slider round" />
        </label>
        <Icon
          content="\e916"
          onClick={() => {
            openDeleteModal(assortment);
          }}
        />
      </div>
    </AssortmentActionsTD>
  ) : (
    <AssortmentActionsDIV className={className}>
      <div className="action-block">
        <label className="switch">
          <input
            type="checkbox"
            onClick={() => {
              toggleAssortment(assortment, filter);
            }}
            checked={Number(assortment.enabled) === 1}
          />
          <span className="slider round" />
        </label>
        <Icon
          content="\e916"
          onClick={() => {
            openDeleteModal(assortment);
          }}
        />
      </div>
    </AssortmentActionsDIV>
  );

const mapDispatchToProps = (dispatch) => ({
  toggleAssortment: (assortment, filter) => {
    const enabled = !Number(assortment.enabled) ? 1 : 0;
    const dateUnactivated = enabled === Number(1) ? null : Moment().format('YYYY-MM-DD');
    dispatch(
      assortmentActions.updateAssortment(
        assortment.id,
        { enabled, date_end: dateUnactivated },
        filter
      )
    );
  },
  openDeleteModal: (assortment) =>
    dispatch(
      modalActions.open('delete', {
        modalHeader: '',
        id: assortment.id,
        deleteType: 'Assortment',
      })
    ),
});

AssortmentActions.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  assortment: PropTypes.object,
  filter: PropTypes.object,
  toggleAssortment: PropTypes.func,
  openDeleteModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AssortmentActions);
