import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body__text-block {
    color: #01366b;
    text-align: center;
    padding-bottom: 20px;
  }
  .modal-body__text-block:first-letter {
    text-transform: uppercase;
  }
  .modal-body__button-block {
    text-align: center;
  }
  .modal-body__button-block a {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 11px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
