import get from 'lodash.get';
import isEqual from 'lodash.isequal';

export const selectOrderCollections = (state) => {
  return get(state, 'order.collections', []);
};

export const selectOrderListByFilter = (state, filter) => {
  const collections = get(state, 'order.collections', []);

  return collections.find((collection) => isEqual(collection.filter, filter));
};

export const selectOrderCollectionsCount = (state) => {
  return get(state, 'order.collectionsCount', []);
};

export const selectOrderTotalRowCountByFilter = (state, filter) => {
  const collections = get(state, 'order.collectionsCount', []);
  const collectionCount = collections.find((collection) => isEqual(collection.filter, filter));

  return get(collectionCount, 'totalRowsNumber', 0);
};
