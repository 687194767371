import { clientService } from 'services';
import { history, getPath } from 'helpers';
import { clientConstants, tableConstants } from '../constants';

const { DIRECTION_REPLACE } = tableConstants;

export const clientActions = {
  fetchTotalRowsNumber,
  fetchClients,
  fetchAllClients,
  fetchClientDetail,
  updateClientDetail,
  updateClientEmail,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    clientService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.FETCH_COUNT_REQUEST, filter };
  }
  function success({ numberDataAvailable }) {
    return {
      type: clientConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return { type: clientConstants.FETCH_COUNT_FAILURE, error };
  }
}

function fetchAllClients(filter, limit) {
  return (dispatch) => {
    let count = 0;

    dispatch(request(filter));
    return fetchClientsChunk(filter, limit, count, tableConstants.DIRECTION_REPLACE).then(
      async (items) => {
        dispatch(success(filter, items, tableConstants.DIRECTION_REPLACE));
        while (items.length === limit) {
          dispatch(request(filter));
          items = await fetchClientsChunk(filter, limit, count += 1); // eslint-disable-line
          dispatch(success(filter, items, tableConstants.DIRECTION_AFTER));
        }
      },
      () => failure(filter)
    );
  };

  function request(filter) {
    return { type: clientConstants.FETCH_REQUEST, filter };
  }
  function success(filter, items, direction) {
    return {
      type: clientConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
      orderBy: '',
    };
  }
  function failure(filter) {
    return { type: clientConstants.FETCH_FAILURE, filter };
  }
}

function fetchClientsChunk(filter, limit, offset) {
  return clientService.fetchClients(filter, offset * limit, limit);
}

function fetchClients(filter, offset, limit, direction = DIRECTION_REPLACE) {
  return (dispatch) => {
    dispatch(request());
    clientService.fetchClients(filter, offset, limit).then(
      (items) => {
        if (filter.id && !items.length) {
          history.push(getPath('error'));
        }
        dispatch(success({ items }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: clientConstants.FETCH_REQUEST, filter };
  }
  function success({ items }) {
    return {
      type: clientConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
    };
  }
  function failure(error) {
    return { type: clientConstants.FETCH_FAILURE, error };
  }
}

function updateClientDetail(id, data, activate = false) {
  return (dispatch) => {
    dispatch(request());

    clientService.updateClientDetail(id, data, activate).then(
      (client) => {
        dispatch(success(client));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: clientConstants.UPDATE_STATUS_REQUEST, data, filter: { id } };
  }
  function success(client) {
    return {
      type: clientConstants.UPDATE_STATUS_SUCCESS,
      client,
      filter: { id },
    };
  }
  function failure(error) {
    return { type: clientConstants.UPDATE_STATUS_FAILURE, error, filter: { id } };
  }
}

function updateClientEmail(id, email) {
  return (dispatch) => dispatch(success(id, email));

  function success(id, email) {
    return {
      type: clientConstants.UPDATE_EMAIL,
      id,
      email,
    };
  }
}

function fetchClientDetail(id) {
  return (dispatch) => {
    dispatch(request());

    clientService.fetchClientDetail(id).then(
      (client) => {
        dispatch(success({ client }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: clientConstants.FETCH_DETAIL_REQUEST };
  }
  function success({ client }) {
    return {
      type: clientConstants.FETCH_DETAIL_SUCCESS,
      client,
    };
  }
  function failure(error) {
    return { type: clientConstants.FETCH_DETAIL_FAILURE, error };
  }
}
