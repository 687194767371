import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userActions, modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import withModalClientStyle from './ModalRedirectClient.style';

const ModalRedirectclient = ({ className, username, loginAs }) => (
  <div className={className}>
    <div className="modal-body">
      <span className="modal-body__message bold">
        Vous allez être redirigé(e) vers l’espace de votre client.
      </span>
      <ButtonPrimary
        className="modal-body__button"
        onClick={() => loginAs(username)}
        disabled={!username}
      >
        <span className="bold">continuer</span>
      </ButtonPrimary>
    </div>
  </div>
);

ModalRedirectclient.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string,
  loginAs: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  loginAs: (username) => {
    dispatch(userActions.loginAs(username));
    dispatch(modalActions.close());
  },
});

export default connect(null, mapDispatchToProps)(withModalClientStyle(ModalRedirectclient));
