import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import withModalSaveConfirmationStyle from './ModalSaveConfirmation.style';

const ModalSaveConfirmation = ({ className, cartName }) => (
  <div className={className}>
    <div className="modal-body">
      <div className="modal-body__text-block bold">
        <span>
          Votre panier type
          <br />"{cartName}"
          <br />a bien été créé.
        </span>
      </div>
      <div className="modal-confirm__container-icon">
        <div className="modal-confirm__icon">
          <Icon className="icon-check-ok" content="\e912" />
        </div>
      </div>
    </div>
  </div>
);

ModalSaveConfirmation.propTypes = {
  className: PropTypes.string,
  cartName: PropTypes.string,
};

export default withModalSaveConfirmationStyle(ModalSaveConfirmation);
