import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonPrimary } from 'components/Core/Button/Button';

function PasswordChanged(props) {
  const { className } = props;
  return (
    <div className={className}>
      <div className="password-recovery__background">
        <div className="password-recovery__connection-form">
          <h2 className="password-recovery-conexion__text-label regular">
            Merci !
            <br />
            Vous pouvez vous connecter.
          </h2>
          <Link href="#" to="/">
            <ButtonPrimary className="password-confirm__btn-connect">Connexion</ButtonPrimary>
          </Link>
        </div>
      </div>
    </div>
  );
}

PasswordChanged.propTypes = {
  className: PropTypes.string,
};

export default PasswordChanged;
