export const productConstants = {
  IMAGE_SIZE_SMALL: 75,
  IMAGE_SIZE_LARGE: 320,

  UNIT_KILO: 'KILOGRAMME',

  FETCH_REQUEST: 'PRODUCT_FETCH_REQUEST',
  FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
  FETCH_FAILURE: 'PRODUCT_FETCH_FAILURE',

  UPDATE_REQUEST: 'PROMOTION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROMOTION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROMOTION_UPDATE_FAILURE',

  FETCH_FAVORITE_LIST_REQUEST: 'PRODUCT_FETCH_FAVORITE_LIST_REQUEST',
  FETCH_FAVORITE_LIST_SUCCESS: 'PRODUCT_FETCH_FAVORITE_LIST_SUCCESS',
  FETCH_FAVORITE_LIST_FAILURE: 'PRODUCT_FETCH_FAVORITE_LIST_FAILURE',

  LIKE_REQUEST: 'PRODUCT_LIKE_REQUEST',
  LIKE_SUCCESS: 'PRODUCT_LIKE_SUCCESS',
  LIKE_FAILURE: 'PRODUCT_LIKE_FAILURE',

  UNLIKE_REQUEST: 'PRODUCT_UNLIKE_REQUEST',
  UNLIKE_SUCCESS: 'PRODUCT_UNLIKE_SUCCESS',
  UNLIKE_FAILURE: 'PRODUCT_UNLIKE_FAILURE',

  SET_FAVORITE_COUNT: 'PRODUCT_SET_FAVORITE_COUNT',
};
