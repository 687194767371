import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BsFillCaretRightFill } from '@react-icons/all-files/bs/BsFillCaretRightFill';
import { BsFillCaretDownFill } from '@react-icons/all-files/bs/BsFillCaretDownFill';
import { Wrapper, PanelWrapper } from './Dropdown.style';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleGlobalClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleGlobalClick, false);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleGlobalClick(event) {
    const { toggleDropDown } = this.props;
    const { current: refNode } = this.wrapperRef;

    if (!refNode.contains(event.target) || refNode === event.target) {
      toggleDropDown(false);
    }
  }

  render() {
    const { children, headerTitle, defaultIcon, listOpen, toggleDropDown } = this.props;

    return (
      <Wrapper className="dd-wrapper" innerRef={this.wrapperRef}>
        <div
          className="dd-header"
          onClick={(event) => {
            toggleDropDown(!listOpen);
          }}
        >
          {defaultIcon && (listOpen ? <BsFillCaretDownFill /> : <BsFillCaretRightFill />)}
          <div className="dd-header-title">{headerTitle}</div>
        </div>
        <PanelWrapper className="dd-panel-wrapper" listOpen={listOpen}>
          {children}
        </PanelWrapper>
      </Wrapper>
    );
  }
}

Dropdown.defaultProps = {
  defaultIcon: true,
};

Dropdown.propTypes = {
  listOpen: PropTypes.bool.isRequired,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  defaultIcon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Dropdown;
