import React from 'react';
import { hotjar } from 'react-hotjar';
import { Routes } from 'helpers/Routes';
import ToastList from 'components/Core/Toast/ToastList';

if (process.env.REACT_APP_HOTJAR === 'true') {
  hotjar.initialize(3575899, 6);
}

const App = () => (
  <main>
    <Routes />
    <ToastList />
  </main>
);

export default App;
