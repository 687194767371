import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalActions } from 'actions';
import ModalSubstitutWrapper from 'components/Modal/ModalSubstitut.style';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { selectItemsFromCatalog } from 'selectors/product';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import { getProductImageFromEan } from 'helpers/Product';
import { ProductQuantity } from 'components/Table/Cells/Products';
import { productConstants } from '../../constants';

const ModalSubstitut = ({ closeModal, title, item, catalogItems, similarGroupItemList }) => {
  const [similarItems, setSimilarItems] = useState([]);

  useEffect(() => {
    if (similarGroupItemList && similarGroupItemList.length) {
      const similarItemIdList = similarGroupItemList.map((item) => item.item.id);
      setSimilarItems([
        ...similarItems,
        ...catalogItems.filter(
          (catalogItem) =>
            similarItemIdList.includes(catalogItem.id) &&
            !similarItems.map((item) => item.id).includes(catalogItem.id) &&
            catalogItem.id !== item.id &&
            catalogItem.stock.value_packing
        ),
      ]);
    }
  }, [similarGroupItemList]);

  return (
    <ModalSubstitutWrapper>
      <h3 className="modal-substitution__title">{title}</h3>
      <div className="modal-substitut__container">
        {similarItems &&
          similarItems
            .filter((item) => item.stock.value_cu >= item.pcb)
            .map((item) => (
              <figure className="product-thumbnail">
                <img
                  src={getProductImageFromEan(item.ean13, productConstants.IMAGE_SIZE_LARGE)}
                  alt="coco"
                />
                <h3 className="bold">{item.name}</h3>
                <figcaption>
                  <table>
                    <tr>
                      <td className="bold">EAN:</td>
                      <td title={item.ean13}> {item.ean13}</td>
                    </tr>
                    <tr>
                      <td className="bold">Ref:</td>
                      <td title={item.reference}> {item.reference}</td>
                    </tr>
                    <tr>
                      <td className="bold">PCB:</td>
                      <td> {item.pcb}</td>
                    </tr>
                    <tr>
                      <td className="bold">Prix UC:</td>
                      <td> {formatPrice(item.applicable_price)} € HT</td>
                    </tr>
                  </table>
                  <div className="product-thumbnail__product-quantity">
                    <ProductQuantity shouldShowResponsive={false} product={item} />
                  </div>
                </figcaption>
              </figure>
            ))}
      </div>
      <ButtonPrimary onClick={closeModal}>Fermer</ButtonPrimary>
    </ModalSubstitutWrapper>
  );
};

ModalSubstitut.propTypes = {
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  catalogItems: selectItemsFromCatalog(state, { aggrid: true }),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSubstitut);
