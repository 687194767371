import React from 'react';
import get from 'lodash.get';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import sidebarDefs from './sidebarDefinitions';
import columnDefs from './columnDefinitions';
import localeText from '../locales';
import './AggridTableView.scss';

const AggridTableView = ({
  rowData,
  onGridReady,
  onFirstDataRendered,
  onCellClicked,
  onFilterChanged,
  frameworkComponents,
  doesExternalFilterPass,
  isExternalFilterPresent,
  handleFilter,
  searchValue,
  source,
}) => {
  return (
    <div className="AggridContainer ag-theme-balham">
      <AgGridReact
        sideBar={sidebarDefs}
        columnDefs={columnDefs}
        cacheBlockSize={100}
        rowModelType="infinite"
        maxConcurrentDatasourceRequests={2}
        overlayNoRowsTemplate=""
        rowSelection="single"
        onGridReady={onGridReady}
        suppressRowClickSelection
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        localeText={localeText}
        onCellClicked={onCellClicked}
        frameworkComponents={frameworkComponents}
        pivotMode={false}
        tooltipShowDelay={0}
        loadingOverlayComponent="Spinner"
        doesExternalFilterPass={doesExternalFilterPass}
        isExternalFilterPresent={isExternalFilterPresent}
        rowHeight={45}
        handleFilter={handleFilter}
        searchValue={searchValue}
        source={source}
      />
    </div>
  );
};
// export const selectScreenWidth = (state) => get(state, 'ui.screenWidth', null);
const mapStateToProps = (state) => ({
  source: get(state, 'source', 'AggridTableView'),
});
AggridTableView.propTypes = {
  rowData: PropTypes.array,
  onGridReady: PropTypes.func,
  onFirstDataRendered: PropTypes.func,
  onCellClicked: PropTypes.func,
  onFilterChanged: PropTypes.func,
  frameworkComponents: PropTypes.object,
  doesExternalFilterPass: PropTypes.func,
  isExternalFilterPresent: PropTypes.func,
  searchValue: PropTypes.string,
  source: PropTypes.string,
};

export default connect(mapStateToProps)(AggridTableView);
// export default AggridTableView;
