import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export const CatalogControlsWrapper = styled.div`
  padding-left: 15px;
  padding-right: 20px;

  .catalog-controls__view-switch {
    margin-right: 5px;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #65676b;
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      fill: #01366b;
    }
  }

  @media ${screen.laptopXL} {
    .catalog-controls__v-separator {
      display: none;
    }
  }

  @media ${screen.laptopL} {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-right: 20px;
  }

  @media ${screen.tablet} {
    .catalog-controls__searchbar {
      width: 350px;
      margin-bottom: 10px !important;
      padding-right: 10px;
    }
  }

  @media ${screen.mobileL} {
    .catalog-controls__searchbar {
      width: 100%;
    }
  }
`;
