/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const ConfiguratorSwitch = ({ attribut, label, onChange }) => (
  <div className="configurator-switch">
    <span className="configurator-switch-span">{label}</span>
    <label className="switch">
      <input
        type="checkbox"
        onChange={() => onChange(attribut.key)}
        checked={Number(attribut.value) === 1}
      />
      <span className="slider round" />
    </label>
  </div>
);

ConfiguratorSwitch.propTypes = {
  attribut: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default ConfiguratorSwitch;
