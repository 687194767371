export const invoiceConstants = {
  FETCH_REQUEST: 'INVOICE_FETCH_REQUEST',
  FETCH_SUCCESS: 'INVOICE_FETCH_SUCCESS',
  FETCH_FAILURE: 'INVOICE_FETCH_FAILURE',
  FETCH_ONE_REQUEST: 'INVOICE_FETCH_ONE_REQUEST',
  FETCH_ONE_SUCCESS: 'INVOICE_FETCH_ONE_SUCCESS',
  FETCH_ONE_FAILURE: 'INVOICE_FETCH_ONE_FAILURE',
  FETCH_COUNT_REQUEST: 'INVOICE_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'INVOICE_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'INVOICE_COUNT_FETCH_FAILURE',

  DOWNLOAD_INVOICE_FORM_REQUEST: 'INVOICE_DOWNLOAD_INVOICE_FORM_REQUEST',
  DOWNLOAD_INVOICE_FORM_SUCCESS: 'INVOICE_DOWNLOAD_INVOICE_FORM_REQUEST',
  DOWNLOAD_INVOICE_FORM_FAILURE: 'INVOICE_DOWNLOAD_INVOICE_FORM_REQUEST',

  TYPE_INVOICE: 'N',
  TYPE_CREDIT: 'A',
};
