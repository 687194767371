import { articleGroupConstants } from 'constants/ArticleGroup.constants';

const initState = {
  data: [],
  isLoading: false,
  error: null,
};

export function articleGroup(state = initState, action) {
  switch (action.type) {
    case articleGroupConstants.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case articleGroupConstants.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupConstants.ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupConstants.ADD_SUCCESS:
      return {
        ...state,
        data: [action.articleGroup, ...state.data],
        isLoading: false,
      };
    case articleGroupConstants.ADD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupConstants.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupConstants.UPDATE_SUCCESS: {
      const groupIndex = state.data.findIndex(
        (articleGroup) => articleGroup.id === action.articleGroup.id
      );
      const newGroupList = [...state.data];
      newGroupList[groupIndex] = { ...action.articleGroup };

      return {
        ...state,
        data: newGroupList,
        isLoading: false,
      };
    }
    case articleGroupConstants.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupConstants.GET_ALL_GROUP_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupConstants.GET_ALL_GROUP_ITEMS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case articleGroupConstants.GET_ALL_GROUP_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case articleGroupConstants.ADD_GROUP_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case articleGroupConstants.ADD_GROUP_ITEMS_SUCCESS:
      return {
        ...state,
        // data: [action.articleGroup, ...state.data],
        isLoading: false,
      };
    case articleGroupConstants.ADD_GROUP_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
