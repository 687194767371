import React from 'react';

const flatDeepByKey = (data, key) => {
  const ret = [];

  ret.push(data);
  if (data.children) {
    ret.push(...flatDeepByKey(data.children[0], key));
  }

  return ret;
};

const columnDefs = [
  {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    width: 100,
    headerCheckboxSelectionFilteredOnly: true,
  },
  {
    headerName: '',
    field: 'iean13',
    sortable: true,
    cellRenderer: 'imageCellRenderer',
    cellClass: 'image-cell',
    width: 50,
    suppressSizeToFit: true,
    resizable: false,
  },
  {
    colId: 'reference',
    headerName: 'Référence',
    field: 'ireference',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'ean',
    headerName: 'EAN',
    field: 'iean13',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'name',
    headerName: 'Libellé',
    field: 'name',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'status',
    headerName: 'Status',
    field: 'iactive',
    cellRenderer: 'statusCellRenderer',
    sortable: true,
    filter: true,
    floatingFilter: true,
  },
  {
    colId: 'pcb',
    headerName: 'Pcb',
    field: 'ipcb',
    sortable: true,
    filter: true,
    floatingFilter: true,
  },
  {
    colId: 'stock',
    headerName: 'Stock',
    field: 'value_packing',
    cellRenderer: 'stockCellRenderer',
    sortable: true,
    filter: true,
    floatingFilter: true,
    cellClass: 'status-cell',
  },
  {
    colId: 'aisle',
    headerName: 'Rayon',
    field: 'categories',
    valueGetter: ({ data }) => {
      return (
        flatDeepByKey(data.categories, 'children').find((item) => item.type === 'Rayon').name || '-'
      );
    },
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'category',
    headerName: 'Famille',
    field: 'categories',
    valueGetter: ({ data }) => {
      return (
        flatDeepByKey(data.categories, 'children').find((item) => item.type === 'Famille').name ||
        '-'
      );
    },
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'subCategory',
    headerName: 'Sous-famille',
    field: 'categories',
    valueGetter: ({ data }) => {
      return (
        flatDeepByKey(data.categories, 'children').find((item) => item.type === 'Sous Famille')
          .name || '-'
      );
    },
    sortable: true,
    filter: true,
    floatingFilter: true,
  },
  {
    colId: 'temperature',
    headerName: 'Température',
    field: 'itemperature',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  },
  {
    colId: 'nature',
    headerName: 'Nature',
    field: 'inature',
    sortable: true,
    filter: true,
    floatingFilter: true,
  },
];

export default columnDefs;
