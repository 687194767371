import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  background-color: #f5f5f5;
  .product-page {
    padding-bottom: ${() =>
      `${
        document.getElementById('sticky-block') &&
        document.getElementById('sticky-block').scrollHeight + 20
      }px`};
    width: 100%;
    background-color: #f5f5f5;
    .messages {
      padding: 5px 20px 0;
      .page-profil__alert-block {
        margin-bottom: 1px;
        word-break: break-all;
      }
    }
  }
  .home-page__button-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 38px 20px 20px 20px;
    .home-page__button-container {
      display: inline;
      text-align: left;
      position: relative;
      margin-right: 5px;
      .home-page__button {
        min-height: 40px;
        padding: 4px 17px;
        line-height: 30px;
        text-align: left;
        i {
          font-size: 20px;
          font-weight: normal;
          display: inline-block;
          vertical-align: middle;
          padding-top: 0;
        }
        span {
          text-align: left;
          padding-left: 7px;
          line-height: 15px;
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .data-number {
        min-width: 23px;
        background-color: #d0021b;
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: center;
        font-style: normal;
        padding: 2px 7px 3px 7px;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: 15px;
      }
    }
  }
  .home-page___content-section {
    display: inline-block;
    width: 100%;
    padding: 20px 0px;
    .section-label {
      span {
        padding: 20px 0px 20px 20px;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        color: #01366b;
      }
      table {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 20px;
        border-spacing: 0px;
        thead {
          background-color: #e2e2e2;
          tr {
            height: 32px;
            font-size: 14px;
            color: #787878;
            th {
              font-weight: 500;
              text-align: left;
              width: 14%;
              &:first-child {
                padding-left: 20px;
                width: 25%;
              }
            }
          }
        }
        tbody {
          tr {
            height: 80px;
            font-weight: 500;
            color: #4a4a4a;
            background: white;
            td {
              border-bottom: 5px solid #f5f5f5;
              &:first-child {
                padding-left: 20px;
                max-width: 290px;
              }
            }
          }
          .calcul {
            font-weight: bold;
          }
        }
      }
    }
  }
  /**************Responsive*************/
  @media ${screen.laptop} {
    .home-page__button-section {
      padding: 38px 5px 20px 5px;
      .home-page__button-container {
        display: inline-block;
        margin-right: 5px;
        .home-page__button {
          padding: 4px 7px;
          text-align: center;
          i {
            font-size: 18px;
          }
          span {
            font-size: 12px;
            padding-left: 4px;
          }
        }
        .data-number {
          top: -11px;
          right: 15px;
        }
      }
    }
  }
  @media ${screen.tablet} {
    .home-page__button-section {
      padding: 38px 5px 20px 5px;
      .home-page__button-container {
        /* width: 114px; */
        margin-right: 3px;
        .home-page__button {
          padding: 4px 5px;
          i {
            font-size: 18px;
          }
          span {
            width: 73%;
            padding-left: 5px;
          }
        }
        .data-number {
          top: -13px;
          right: 15px;
        }
      }
    }
  }
  @media ${screen.mobileL} {
    .home-page__button-section {
      .home-page__button-container {
        display: inline;
        .home-page__button {
          width: 65px;
          height: 40px;
          position: relative;
          i {
            font-size: 20px;
            font-weight: normal;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          span {
            display: none;
          }
        }
        .data-number {
          top: -10px;
          right: 10px;
        }
      }
    }
    .home-page___content-section {
      .section-label {
        .home-page__data-section {
          width: 100%;
          display: inline-block;
          padding-bottom: 20px;
          padding-top: 20px;
          .data-section {
            width: calc(100% / 2 - 12px);
            display: inline-block;
            &:nth-child(even) {
              margin-right: 0;
            }
            &:nth-child(odd) {
              margin-right: 24px;
            }
            .data-section__content {
              span {
                display: inline-block;
                width: 100%;
                height: 32px;
                padding: 7px 20px 7px;
                font-size: 14px;
                font-weight: 500;
                &:nth-child(odd) {
                  background-color: #e2e2e2;
                  color: #787878;
                }
                &:nth-child(even) {
                  color: #4a4a4a;
                  background-color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${screen.mobile} {
    .home-page___content-section {
      .section-label {
        .home-page__data-section {
          width: 100%;
          display: inline-block;
          padding-bottom: 20px;
          padding-top: 20px;
          .data-section {
            width: 100%;
            display: inline-block;
            margin-bottom: 20px;
            &:nth-child(even) {
              margin-right: 0px;
            }
            &:nth-child(odd) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
`;
