import React from 'react';
import PromotionTimer from 'components/PromotionTimer/PromotionTimer';

const promoListTimerCellRenderer = ({ data }) => {
  return (
    <>
      {data.promotion && (
        <PromotionTimer
          startDate={data.promotion.date_start_validity}
          endDate={data.promotion.date_end_validity}
        />
      )}
    </>
  );
};

export { promoListTimerCellRenderer };
