import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body {
    padding: 20px;
    text-align: center;
    h4 {
      font-size: 15px;
      color: #01366b;
      margin-bottom: 20px;
    }
  }

  .modal-body__message {
    color: #01366b;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .date-bloc:first-child {
      margin-bottom: 15px;
    }
    label {
      font-size: 14px;
      margin-bottom: 8px;
    }
    input {
      border: 1px solid #01366b;
      padding: 4px;
      text-align: center;
    }
  }
  .modal-body__button {
    padding: 10px;
    border-radius: 4.5px;
  }
  .modal-body__button span {
    text-transform: uppercase;
    letter-spacing: 1.06px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
`;
