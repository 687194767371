import { categoryService } from 'services';
import { loadingActions } from '.';

// Importing platformConstants from the absolute path (`constants`) bugs.
import { categoryConstants } from '../constants';

export const categoryActions = {
  fetchCategories,
  fetchCategoriesByBrand,
};

function fetchCategories(platformId, clientId = null, showItems = false, status = '') {
  return (dispatch) => {
    dispatch(request(platformId, clientId));
    dispatch(loadingActions.started('category'));

    categoryService.fetchCategoriesV2(platformId, clientId, showItems, status).then(
      (categories) => {
        dispatch(loadingActions.loaded('category'));
        dispatch(success(categories, platformId, clientId));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request(platform, client) {
    return { type: categoryConstants.FETCH_REQUEST, platform, client };
  }
  function success(categories, platform, client) {
    return {
      type: categoryConstants.FETCH_SUCCESS,
      categories,
      platform,
      client,
    };
  }
  function failure(error) {
    return { type: categoryConstants.FETCH_FAILURE, error };
  }
}

function fetchCategoriesByBrand(brand, status = '') {
  return (dispatch) => {
    dispatch(request());

    categoryService.fetchCategoriesByBrand(brand, status).then(
      (categories) => {
        dispatch(success(categories));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request() {
    return { type: categoryConstants.FETCH_REQUEST, brand };
  }
  function success(categories) {
    return { type: categoryConstants.FETCH_SUCCESS, categories, brand };
  }
  function failure(error) {
    return { type: categoryConstants.FETCH_FAILURE, error, brand };
  }
}
