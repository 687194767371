import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { commercialActions, modalActions } from 'actions';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import ModalUpdateCommercialstyle from './ModalUpdateCommercial.style';

class ModalUpdateCommercial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      mobile: '',
      phone: '',
      fax: '',
      email: '',
    };
  }

  componentDidMount() {
    const { commercial } = this.props;
    if (commercial) {
      this.setState({
        username: commercial.username || '',
        firstname: commercial.firstname || '',
        lastname: commercial.lastname || '',
        mobile: commercial.tel_number2 || '',
        phone: commercial.tel_number1 || '',
        fax: commercial.fax || '',
        email: commercial.email || '',
      });
    }
  }

  handleSubmit = () => {
    const { commercial: propCommercial, updateCommercial, closeModal, pushToast } = this.props;
    const { username, firstname, lastname, mobile, phone, fax, email } = this.state;
    if (username && firstname && lastname && email) {
      const commercial = {
        username,
        firstname,
        lastname,
        tel_number1: phone,
        tel_number2: mobile,
        fax,
        email,
      };
      if (propCommercial && propCommercial.id) {
        updateCommercial(
          propCommercial.id,
          {
            ...commercial,
            id: propCommercial.id,
          },
          () =>
            pushToast({
              type: toastConstants.TYPE_SUCCESS,
              message: 'Commercial modifié avec succès',
            }),
          () =>
            pushToast({
              type: 'error',
              message: "Une erreur s'est produite, veuillez réessayer plus tard",
            })
        );
      }
      closeModal();
    }
  };

  updateInputValue = (event) => {
    const numericRegex = /^[0-9\b]+$/;
    const inputToNumericlyCheck = ['fax', 'phone', 'mobile'];

    if (
      event.target.value &&
      inputToNumericlyCheck.includes(event.target.name) &&
      numericRegex.test(event.target.value) === false
    ) {
      return;
    }

    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getErrorMessage = () => {
    const { username, fax, phone, mobile, email } = this.state;
    if (username === '' || email === '') {
      return 'Les champs marqués d’un astérisque sont obligatoires.';
    }
    if (
      (fax && fax.toString().length !== 10) ||
      (phone && phone.toString().length !== 10) ||
      (mobile && mobile.toString().length !== 10)
    ) {
      return 'Les numéros de téléphone et/ou de fax sont invalides.';
    }
    // Email regex
    if (!/^\w+([\x2E-]?\w+)*@\w+([\x2E-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return 'L’adresse e-mail saisie est invalide.';
    }
    return false;
  };

  render() {
    const { className } = this.props;
    const { username, firstname, lastname, fax, phone, mobile, email } = this.state;
    const error = this.getErrorMessage();
    return (
      <div className={className}>
        <div className="modal-update-commercial__content-bloc">
          <div className="modal-update-commercial__content-bloc__field modal-update-commercial__content-bloc__field-mandatory">
            <span className="bold"> Identifiant </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Identifiant"
              name="username"
              value={username}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field modal-update-commercial__content-bloc__field-mandatory">
            <span className="bold"> Prénom </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Prénom"
              name="firstname"
              value={firstname}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field modal-update-commercial__content-bloc__field-mandatory">
            <span className="bold"> Nom </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Nom"
              name="lastname"
              value={lastname}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field">
            <span className="bold"> Mobile </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Mobile"
              name="mobile"
              value={mobile}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field ">
            <span className="bold"> Fixe </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Fixe"
              name="phone"
              value={phone}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field">
            <span className="bold"> Fax </span>
            <input
              type="text"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Fax"
              name="fax"
              value={fax}
            />
          </div>
          <div className="modal-update-commercial__content-bloc__field modal-update-commercial__content-bloc__field-mandatory">
            <span className="bold"> Email </span>
            <input
              type="email"
              onChange={(event) => this.updateInputValue(event)}
              placeholder="Email"
              name="email"
              value={email}
            />
          </div>
          {!!error && (
            <div className="modal-update-commercial__content-bloc__notification">
              <span className="alert-bloc__label">{error}</span>
            </div>
          )}
          <ButtonPrimary disabled={!!error} onClick={!error && this.handleSubmit}>
            <span>Valider</span>
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

ModalUpdateCommercial.propTypes = {
  className: PropTypes.string,
  commercial: PropTypes.object,
  updateCommercial: PropTypes.func,
  closeModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  updateCommercial: (id, commercial, onSuccessCallback, onFailureCallback) =>
    dispatch(
      commercialActions.updateCommercial(id, commercial, onSuccessCallback, onFailureCallback)
    ),
  closeModal: () => dispatch(modalActions.close()),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
});

export default connect(null, mapDispatchToProps)(ModalUpdateCommercialstyle(ModalUpdateCommercial));
