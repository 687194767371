import styled from 'styled-components';

export default (Component) => styled(Component)`
  .modal-body {
    margin: 30px;
    text-align: center;
    .modal-body__text-block {
      padding: 0px 40px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
    .modal-body__configuration-block {
      table {
        margin-top: 30px;
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        th {
          border: 1px solid #01366b52;
          padding: 10px;
          width: 150px;
        }
        td {
          border: 1px solid #01366b52;
          padding: 15px;
          background: #b4e4f552;
          font-weight: bold;
          color: #787878;
        }
      }
    }
    .modal-body__button-block {
      margin-top: 35px;
      a {
        padding: 13px;
        letter-spacing: 1.2px;
      }
      a:nth-child(1) {
        margin-bottom: 20px;
      }
      a:nth-child(2) {
        border: 1px solid #01366b52;
        color: #00a6ff;
      }
    }
  }
`;
