import { platformService } from 'services';
import { getPath, history } from 'helpers';
// Importing platformConstants from the absolute path (`constants`) bugs.
import { platformConstants, cartConstants } from '../constants';
import { messageActions, loadingActions, categoryActions } from '.';
import { cartActions } from './Cart';

export const platformActions = {
  fetchPlatforms,
  redirectToLanding,
  setPlatform,
  updatePlatform,
};

function fetchPlatforms() {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.started('platform'));

    platformService.fetchPlatforms().then(
      (platforms) => {
        dispatch(success(platforms));
        dispatch(loadingActions.loaded('platform'));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: platformConstants.FETCH_REQUEST };
  }
  function success(platforms) {
    return { type: platformConstants.FETCH_SUCCESS, platforms };
  }
  function failure(error) {
    return { type: platformConstants.FETCH_FAILURE, error };
  }
}

function setPlatform(platformId) {
  return (dispatch) => {
    localStorage.setItem('platform', Number(platformId));
    dispatch({ type: platformConstants.UPDATE_ACTIVE, platformId });
    dispatch(
      cartActions.fetchItems({
        status: cartConstants.CART_STATUS_CURRENT,
        platform: platformId,
      })
    );
    dispatch(
      cartActions.fetchItems({
        status: cartConstants.CART_STATUS_CURRENT_PREORDER,
        platform: platformId,
      })
    );
    dispatch(categoryActions.fetchCategories(platformId));
    dispatch(messageActions.fetchMessages({ platform: platformId }, 0, 100));
  };
}

function redirectToLanding() {
  localStorage.removeItem('platform');
  history.push(getPath('landingPage'));
}

function updatePlatform(platform, newAttributs = []) {
  return (dispatch) => {
    newAttributs.forEach((newAttribut) => {
      const { attributs: dataAttributs } = platform;
      const attribut = dataAttributs.find((a) => a.key === newAttribut.key);
      if (attribut) {
        attribut.value = newAttribut.value;
      } else {
        dataAttributs.push(newAttribut);
      }
    });

    dispatch(request(platform));
    platformService.updatePlatform(platform.id, platform).then(
      (newPlatform) => {
        dispatch(success(newPlatform));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request(newPlatform) {
    return { type: platformConstants.UPDATE_REQUEST, platform: newPlatform };
  }
  function success(newPlatform) {
    return { type: platformConstants.UPDATE_SUCCESS, platform: newPlatform };
  }
  function failure(error) {
    return { type: platformConstants.UPDATE_FAILURE, error };
  }
}
