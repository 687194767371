import styled from 'styled-components';

export default (Component) => styled(Component)`
  .red {
    color: #d0021b;
    font-weight: bold;
    margin-left: 5px;
  }
  .page-profil {
    background-color: #f5f5f5;
    padding: 20px 0px 20px 20px;
    min-height: calc(100vh - 186px);
    .page-profil__heading {
      padding: 2px 2px;
      .entete__icon-home {
        color: #01366b;
        display: inline-block;
        margin-right: 2px;
      }
      .entete__label-profil {
        color: #01366b;
        height: 18px;
        font-weight: 600;
      }
    }
    .page-profil__section-info {
      margin-top: 20px;
      margin-bottom: 20px;
      .page-profil__block-info {
        background-color: #ffffff;
        width: calc(33.3333% - 20px);
        display: inline-block;
        box-shadow: 0px 3px 4px 0px grey;
        &:not(:last-child) {
          margin-bottom: 13px;
          margin-right: 20px;
        }
        &:nth-of-type(1),
        &:nth-of-type(2) {
          vertical-align: top;
        }
      }
      .block-info__entete {
        border: 1px solid;
        padding-top: 8px;
        border-width: 0px 0px 1px 0px;
        border-color: rgba(1, 54, 107, 0.2);
        padding-left: 16px;
        padding-bottom: 8px;
        padding-right: 20px;
        .entete__icon {
          color: #00a6ff;
          font-size: 12px;
          background-color: #e1f4fb;
          border-radius: 24px;
          padding: 7px;
        }
        .entete__icon-user {
          color: #00a6ff;
          font-size: 17px;
          background-color: #e1f4fb;
          border-radius: 24px;
          padding: 6px;
        }
        .entete__label {
          color: #4a4a4a;
          font-weight: 600;
          -webkit-letter-spacing: 0.12px;
          -moz-letter-spacing: 0.12px;
          -ms-letter-spacing: 0.12px;
          letter-spacing: 0.12px;
          left: 6px;
          position: relative;
        }
      }
      .block-info__content {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        .content__ligne {
          margin-bottom: 20px;
          .ligne__attribut {
            margin-right: 10px;
            display: inline-block;
            color: #01366b;
            &.password {
              width: 50%;
              margin-right: 0px;
            }
          }
          .ligne__value {
            text-overflow: ellipsis;
            word-wrap: break-word;
            font-weight: 500;
            color: #787878;
          }
          input {
            border-width: 0px 0px 1px 0px;
            border-color: rgba(120, 120, 120, 0.6);
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 7px;
          }
          .content-ligne__icon {
            font-size: 22px;
            width: 15%;
            position: relative;
            top: 7px;
          }
          input::placeholder {
            color: #4a4a4a;
            width: 126px;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.12px;
            line-height: 19px;
          }
          .ligne__value-input {
            color: #4a4a4a;
            width: 40%;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.12px;
            line-height: 19px;
          }
        }
        .content__ligne-button {
          text-align: center;
          padding-bottom: 20px;
          .btn {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 13px;
            padding-bottom: 10px;
          }
        }
        .content__ligne-alert {
          margin-bottom: 20px;
          text-align: center;
          span {
            display: block;
            width: 100%;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 15px;
            &.error {
              color: #d0021b;
              &:before {
                content: '*';
                color: #d0021b;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .page-profil {
      .page-profil__section-info {
        .block-info__content {
          .content__ligne {
            .ligne__attribut {
              &.password {
                width: 45%;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .page-profil {
      .page-profil__section-info {
        .page-profil__block-info {
          width: calc(50% - 20px);
        }
      }
    }
  }
  @media screen and (max-width: 544px) {
    .page-profil {
      padding-right: 20px;
      .page-profil__section-info {
        .page-profil__block-info {
          width: 100%;
          .block-info__content {
            .content__ligne {
              .ligne__attribut {
                &.password {
                  width: 42%;
                }
              }
              .ligne__value-input {
                width: 47%;
              }
            }
          }
        }
      }
    }
  }

  /*bloc message*/
  .page-profil__section-message {
    width: 100%;
    height: 400px;
    background-color: #ffffff;
    box-shadow: 0px 3px 4px 0px grey;
    .entete-message__icon {
      color: #00a6ff;
      background-color: #e1f4fb;
      border-radius: 24px;
      font-size: 24px;
    }
    .entete-message__label {
      color: #4a4a4a;
      font-weight: 600;
      letter-spacing: 0.12px;
      left: 6px;
      position: relative;
      top: -4px;
    }
    .block-message__entete {
      border: 1px solid;
      padding-top: 6px;
      border-width: 0px 0px 1px 0px;
      border-color: rgba(1, 54, 107, 0.2);
      padding-left: 16px;
      padding-bottom: 6px;
      padding-right: 20px;
      .entete__link {
        float: right;
        text-decoration: none;
        color: #01366b;
        text-align: center;
        padding-top: 5px;
      }
    }
    .block-message__content {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      .bloc-message__content-ligne {
        width: 100%;
        display: inline-block;
        .bloc-message__content-value {
          span {
            overflow: hidden;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.4;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .content__ligne-message {
            width: 100%;
            display: inline-block;
            margin-bottom: 16px;
          }
          .ligne__date-attribut {
            color: #01366b;
            width: 8%;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
`;
