import { aggridFilterConstants } from 'constants/AggridFilter.constants';

const initState = {
  quickList: [],
  temperatureList: [],
  availabilityList: [],
  storeAvailabilityList: [],
  category: null,
  selectedCategoryList: [],
  categoryColumns: null,
  viewType: false,
  selection: null,
  isPreorder: null,
};

export function aggridFilter(state = initState, action) {
  switch (action.type) {
    case aggridFilterConstants.SET_FILTERS:
      return action.filters;
    case aggridFilterConstants.TOGGLE_VIEW:
      return {
        ...state,
        viewType: !state.viewType,
      };
    default:
      return state;
  }
}
