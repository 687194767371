import { loadingConstants } from '../constants';

export function updateLoadingCollection(collections, collectionName, collectionStatus) {
  if (!collections.find(({ name }) => collectionName === name)) {
    collections.push({ name: collectionName, status: collectionStatus });
    return collections;
  }
  return collections.map(({ name, status }) => {
    if (name === collectionName) {
      return { name, status: collectionStatus };
    }
    return { name, status };
  });
}

export function canDisplayWebSite(collections, userType) {
  if (!userType) {
    return false;
  }
  const neededToDisplayWebSite = loadingConstants.NEEDED_TO_DISPLAY_WEB_SITE[userType];
  return (
    collections.filter(
      ({ name, status }) =>
        neededToDisplayWebSite.includes(name) && status === loadingConstants.STATUS_LOADED
    ).length === neededToDisplayWebSite.length
  );
}
