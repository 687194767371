import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'components/Core/ReactTable/ReactTable';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { OcrOrderMatchWrapper } from 'pages/OcrOrderMatch/OcrOrderMatch.style';
import { BsTrash } from '@react-icons/all-files/bs/BsTrash';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import Calendar from 'components/Core/Calendar/Calendar';
import Moment from 'moment';
import { isValidDeliveryDay } from 'helpers/Cart';
import { getCollection, getPath, history, getIconClassByType } from 'helpers';
import { getAvailableTemperatures } from 'helpers/Platform';
import { selectCurrentPlatform } from 'selectors/platform';
import {
  cartActions,
  clientActions,
  dayOffActions,
  modalActions,
  ocrOrderPdfActions,
  productActions,
} from 'actions';
import { selectOcrOrderPdf, selectSelectedOcrOrderResult } from 'selectors/ocrOrderPdf';
import { selectItemsFromCatalog } from 'selectors/product';
import CommercialOrderDropdownSelect from 'components/CommercialOrderForm/CommercialOrderDropdownSelect/CommercialOrderDropdownSelect';
import { selectClientCollectionByFilter, selectClientDetail } from 'selectors/client';
import { userService } from 'services';
import { selectCartCollectionByFilter, selectCartError, selectValidatedCart } from 'selectors/cart';
import { toastActions } from 'actions/Toast';
import { ButtonSecondary } from 'components/Core/Button/Button';
import { formatPrice } from 'components/Aggrid/cellRenderers';
import PromotionIcon from 'components/Icon/PromotionIcon';
import Spinner from 'components/Core/Spinner/Spinner';
import { toastConstants } from 'constants/Toast.constants';
import { cartConstants, ocrOrderPdfConstants } from '../../constants';

const OcrOrderMatch = ({
  match,
  currentPlatform,
  fetchOcrJobResult,
  fetchDayOff,
  selectedOcrJobResult,
  dayOff,
  fetchCatalogItems,
  productList,
  fetchClientDetail,
  currentClient,
  openCommercialOrderValidationModal,
  createCartFromItems,
  cartCollection,
  cartError,
  validatedCart,
  pushToast,
  setErrorToasted,
  updateOcrJob,
  openOcrOrderProductSelectModal,
}) => {
  const [cartItemList, setCartItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState({});
  const [cartId, setCartId] = useState(null);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [comment, setComment] = useState(null);

  useEffect(() => {
    fetchOcrJobResult(match.params.id);
    fetchDayOff({ platform: currentPlatform.id });
  }, []);

  useEffect(() => {
    if (selectedOcrJobResult && selectedOcrJobResult.result.length) {
      fetchCatalogItems(currentPlatform.id, selectedOcrJobResult.client.id);
      fetchClientDetail(selectedOcrJobResult.client.id);
    }
  }, [selectedOcrJobResult]);

  useEffect(() => {
    if (
      productList &&
      productList.length &&
      selectedOcrJobResult &&
      selectedOcrJobResult.result.length
    ) {
      const newCartItemList = [];
      selectedOcrJobResult.result.forEach((jobItem) => {
        const matchedProductFromRef = productList.find(
          (product) => product.reference === jobItem.reference
        );
        if (matchedProductFromRef) {
          return newCartItemList.push({ ...matchedProductFromRef, quantity: jobItem.packages });
        }

        const matchedProductFromEan = productList.find((product) => {
          return product.ean13 === jobItem.ean;
        });
        if (matchedProductFromEan) {
          return newCartItemList.push({ ...matchedProductFromEan, quantity: jobItem.packages });
        }

        if (jobItem.product_name) {
          return newCartItemList.push({
            reference: null,
            ean13: null,
            name: null,
            quantity: 0,
            pcb: 0,
            price: 0,
          });
        }

        return false;
      });

      setCartItemList(newCartItemList);
    }
  }, [productList]);

  useEffect(() => {
    if (selectedItem.length) {
      const newCartItemList = [...cartItemList];
      selectedItem.forEach((item, index) => {
        if (item) {
          newCartItemList[index] = item;
        }
      });
      setCartItemList(newCartItemList);
      setSelectedItem([]);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (
      cartCollection &&
      cartCollection.items[0] &&
      !cartCollection.isLoading &&
      !cartError.message &&
      shouldShowModal
    ) {
      setCartId(cartCollection.items[0].id);
      openCommercialOrderValidationModal(cartCollection.items[0]);
      setShouldShowModal(false);
    }
  }, [cartCollection]);

  useEffect(() => {
    if (validatedCart && cartCollection && validatedCart.id === cartId) {
      updateOcrJob(selectedOcrJobResult.id, { job_status: ocrOrderPdfConstants.STATUS_ARCHIVED });
      pushToast({
        type: toastConstants.TYPE_SUCCESS,
        title: '',
        message: 'Commande validée !',
      });
      history.push(getPath('commercialCartList'));
    }
  }, [validatedCart]);

  useEffect(() => {
    if (cartError.message && !cartError.message.toasted) {
      pushToast({
        type: 'error',
        title: '',
        message: "Une erreur s'est produite, veullez réesayer plus tard",
      });
      setErrorToasted();
    }
  }, [cartError]);

  const pdfColumns = React.useMemo(() => [
    {
      id: 'pdf_ref',
      Header: 'Ref',
      accessor: (item) => item.reference,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'pdf_ean',
      Header: 'EAN',
      accessor: (item) => item.ean,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'pdf_name',
      Header: 'Libellé',
      accessor: (item) => item.product_name,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'pdf_packages',
      Header: 'QTE COLIS',
      accessor: (item) => item.packages,
      Cell: ({ value }) => value,
    },
    {
      id: 'pdf_pcb',
      Header: 'PCB',
      accessor: (item) => item.pcb,
      Cell: ({ value }) => value,
    },
    {
      id: 'pdf_quantity',
      Header: 'QTE',
      accessor: (item) => item.quantity,
      Cell: ({ value }) => value,
    },
    {
      id: 'priceUC',
      Header: 'PX UC',
      accessor: (item) => item,
      Cell: ({ value }) => {
        if (!value || !value.price_uc) {
          return '-';
        }

        return `${formatPrice(value.price_uc)} € HT`;
      },
    },
  ]);

  const cartColumns = React.useMemo(() => [
    {
      id: 'ref',
      Header: 'Ref',
      accessor: (item) => item.reference,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'ean',
      Header: 'EAN',
      accessor: (item) => item.ean13,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'name',
      Header: 'Libellé',
      accessor: (item) => item,
      Cell: ({ row, value }) => {
        return !value.name ? (
          <ButtonSecondary
            className="ocr-order-match__product-select-button"
            inverted
            onClick={() => {
              openOcrOrderProductSelectModal(
                selectedOcrJobResult.result[row.id],
                productList,
                row.id,
                handleProductSelection
              );
            }}
          >
            <FaSearch />
            Trouver un produit
          </ButtonSecondary>
        ) : (
          value.name
        );
      },
    },
    {
      id: 'quantity',
      Header: 'QTE',
      accessor: (item) => item,
      Cell: ({ value }) =>
        value && value.quantity ? (
          <input
            key={value.id}
            className="ocr-order-match__quantity-input"
            onBlur={(event) => {
              handleQuantityChange(event.target.value, value);
            }}
            defaultValue={value.quantity}
          />
        ) : (
          '-'
        ),
    },
    {
      id: 'priceUC',
      Header: 'PX UC',
      accessor: (item) => item,
      Cell: ({ value }) => {
        if (!value || !value.price) {
          return '-';
        }
        if (value.has_promotion) {
          return (
            <>
              {formatPrice(value.applicable_price)} € HT
              <PromotionIcon />
            </>
          );
        }

        return `${formatPrice(value.price)} € HT`;
      },
    },
    {
      id: 'pcb',
      Header: 'PCB',
      accessor: (item) => item.pcb,
      Cell: ({ value }) => value || '-',
    },
    {
      id: 'price',
      Header: 'PX VENTE',
      accessor: (item) => item,
      Cell: ({ value }) => {
        if (!value || !value.price || !value.pcb || !value.quantity) {
          return '-';
        }
        if (value.has_promotion) {
          return (
            <>
              {formatPrice(value.applicable_price * value.pcb * value.quantity)} € HT{' '}
              <PromotionIcon />
            </>
          );
        }
        return `${formatPrice(value.price * value.pcb * value.quantity)} € HT`;
      },
    },
    {
      id: 'stock',
      Header: 'STOCK',
      accessor: (item) => item.stock,
      Cell: ({ value }) => (value && value.value_packing) || '-',
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortBy: true,
      accessor: (item) => item,
      Cell: ({ value }) => (
        <div className="ocr-order-match__actions">
          {value.reference && value.price && value.quantity && (
            <BsTrash
              style={{ fill: 'red', cursor: 'pointer' }}
              onClick={() => {
                const newCartItemList = cartItemList.map((cartItem) => {
                  if (cartItem.id === value.id) {
                    return {
                      reference: null,
                      ean13: null,
                      name: null,
                      quantity: 0,
                      pcb: 0,
                      price: 0,
                    };
                  }

                  return cartItem;
                });
                setCartItemList(newCartItemList);
              }}
            />
          )}
        </div>
      ),
    },
  ]);

  const availableTemperatures = getAvailableTemperatures(currentPlatform);

  const handleProductSelection = (product, index) => {
    const newCartItemList = [...cartItemList];
    newCartItemList[index] = { ...product, quantity: selectedOcrJobResult.result[index].packages };
    setCartItemList(newCartItemList);
  };

  const handleDatesChange = (date, type) => {
    const newDate = { ...deliveryDate };
    newDate[type] = date;

    return setDeliveryDate(newDate);
  };

  const handleQuantityChange = (quantity, currentCartItem) => {
    const newCartItemList = cartItemList.map((cartItem) => {
      if (cartItem.id === currentCartItem.id) {
        return { ...cartItem, quantity };
      }

      return cartItem;
    });

    setCartItemList(newCartItemList);
  };

  const isAllDateFilled = () => {
    let ret = true;
    availableTemperatures.forEach((temperature, index) => {
      const tempsHasItem = cartItemList.find((cartItem) => cartItem.type === temperature.value);
      if (tempsHasItem && !(deliveryDate && deliveryDate[temperature.value])) {
        ret = false;
      }
    });

    return ret;
  };

  const createCart = () => {
    userService.loginAs(currentClient.data.customers[0].username);
    createCartFromItems(
      cartItemList.filter((cartItem) => cartItem.reference && cartItem.quantity > 0),
      deliveryDate,
      comment,
      cartConstants.CART_COMMERCIAL_TYPE
    );
    setShouldShowModal(true);
    userService.logoutAs();
  };

  const getTotalPrice = () => {
    let total = 0;
    cartItemList.forEach((cartItem) => {
      total += cartItem.price * cartItem.pcb * cartItem.quantity;
    });

    return formatPrice(total);
  };

  const getTotalItemCart = () => {
    const cartItemListNotEmpty = cartItemList.filter((cartItem) => {
      return cartItem.ean !== null && cartItem.reference !== null;
    });

    return cartItemListNotEmpty.length;
  };

  return (
    <OcrOrderMatchWrapper>
      <div>
        <span className="ocr-order-match__title">Client:</span>{' '}
        {selectedOcrJobResult &&
          `${selectedOcrJobResult.client.ext_code} ${selectedOcrJobResult.client.name} - ${selectedOcrJobResult.pdf_filename}`}
      </div>
      <ScrollSync>
        <div className="ocr-order-match__table-container">
          <div className="ocr-order-match__table-wrapper">
            <div className="ocr-order-match__title">
              REFERENCES IMPORTEES{' '}
              {selectedOcrJobResult && `(${selectedOcrJobResult.result.length})`}
            </div>
            <ScrollSyncPane>
              <ReactTable
                columns={pdfColumns}
                data={(selectedOcrJobResult && selectedOcrJobResult.result) || []}
                isLoading={!selectedOcrJobResult || selectedOcrJobResult.isLoading}
              />
            </ScrollSyncPane>
          </div>
          <div className="ocr-order-match__table-wrapper">
            <div className="ocr-order-match__title">
              COMMANDE PROPOSEE <span className="bold">({getTotalItemCart()})</span>
            </div>
            <ScrollSyncPane>
              <ReactTable
                columns={cartColumns}
                data={cartItemList}
                isLoading={!cartItemList || !cartItemList.length}
              />
            </ScrollSyncPane>
          </div>
        </div>
      </ScrollSync>
      <div className="ocr-order-match__footer">
        <form className="ocr-order-match__delivery-form">
          <div className="ocr-order-match__delivery-container">
            <div className="ocr-order-match__title">Livraison</div>
            <div className="ocr-order-match__delivery-item-container">
              {availableTemperatures.map((item) => (
                <div className="ocr-order-match__delivery-item">
                  <i className={getIconClassByType(item.value)} />
                  <Calendar
                    onChange={(date) => {
                      handleDatesChange(date, item.value);
                    }}
                    selected={
                      item && deliveryDate && deliveryDate[item.value]
                        ? Moment(deliveryDate[item.value]).toDate()
                        : ''
                    }
                    filterDate={(date) =>
                      isValidDeliveryDay(
                        date,
                        item.value,
                        { client: currentClient.data },
                        dayOff,
                        currentPlatform
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/aaaa"
                    disabled={!cartItemList.find((cartItem) => cartItem.type === item.value)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="ocr-order-match__comment-container">
            <label className="ocr-order-match__title">Commentaire</label>
            <textarea
              onChange={(event) => {
                setComment(event.target.value);
              }}
              value={comment}
            />
          </div>
          <div className="ocr-order-match__footer-button-container">
            <div>
              <span className="bold">TOTAL:</span> {getTotalPrice()} € HT
            </div>
            <ButtonSecondary
              disabled={
                !cartItemList.filter(
                  (cartItem) => !!cartItem.reference && !!cartItem.quantity && !!cartItem.price
                ).length
              }
              inverted
              onClick={() => {
                createCart();
                history.push(getPath('commercialCartList'));
              }}
            >
              Mettre en attente
            </ButtonSecondary>
            <ButtonSecondary
              className="commercial-order__validate-button"
              inverted
              onClick={() => {
                if (deliveryDate && isAllDateFilled()) {
                  createCart();
                }
              }}
              disabled={!isAllDateFilled()}
            >
              {cartCollection && cartCollection.isLoading ? (
                <Spinner size={20} />
              ) : (
                'Valider la commande'
              )}
            </ButtonSecondary>
          </div>
        </form>
      </div>
    </OcrOrderMatchWrapper>
  );
};

const mapStateToProps = (state) => {
  const { dayOff } = state;
  const currentPlatform = selectCurrentPlatform(state);
  const dayOffCollection = getCollection(
    dayOff.collections || [],
    currentPlatform ? { platform: currentPlatform.id } : {}
  );
  const selectedOcrJobResult = selectSelectedOcrOrderResult(state);
  const currentClient = selectClientCollectionByFilter(state, {
    id: selectedOcrJobResult && String(selectedOcrJobResult.client.id),
  });

  return {
    currentPlatform,
    selectedOcrJobResult,
    currentClient: selectClientDetail(state),
    productList: selectItemsFromCatalog(state, { aggrid: true }),
    dayOff: dayOffCollection.items || [],
    ocrOrderPdf: selectOcrOrderPdf(state),
    cartCollection: selectCartCollectionByFilter(state, {
      status: cartConstants.CART_STATUS_PENDING_VALIDATION,
      platform: currentPlatform.id,
    }),
    cartError: selectCartError(state),
    validatedCart: selectValidatedCart(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOcrJobResult: (jobId) => dispatch(ocrOrderPdfActions.fetchOcrJobResult(jobId)),
  fetchCatalogItems: (platformId, clientId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 4200, clientId)),
  fetchDayOff: (filter) => dispatch(dayOffActions.fetchDayOff(filter, 0, 100)),
  fetchClientDetail: (clientId) => dispatch(clientActions.fetchClientDetail(clientId)),
  createCartFromItems: (cartItems, deliveryDates, comment, cartType) =>
    dispatch(cartActions.createCartFromItems(cartItems, deliveryDates, comment, cartType)),
  openCommercialOrderValidationModal: (cart, setShouldBlockNavigation) =>
    dispatch(
      modalActions.open('addCommercialOrder', {
        width: '400',
        cart,
        setShouldBlockNavigation,
      })
    ),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
  setErrorToasted: () => dispatch(cartActions.setErrorToasted()),
  updateOcrJob: (jobId, data) => dispatch(ocrOrderPdfActions.updateOcrJob(jobId, data)),
  openOcrOrderProductSelectModal: (jobItem, productList, index, callback) =>
    dispatch(
      modalActions.open('ocrOrderProductSelect', {
        width: 500,
        jobItem,
        productList,
        index,
        callback,
      })
    ),
});

OcrOrderMatch.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(OcrOrderMatch);
