import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';

export default (Component) => styled(Component)`
  height: 100%;
  .menu {
    height: 100%;
    overflow: hidden;
    transition: all 0.8s;
    background: transparent;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    @keyframes open {
      from {
        transform: translateX(-10%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @media ${screen.tablet} {
      width: 100%;
      .see-all {
        span {
          padding-left: 10px;
        }
      }
      div {
        display: none;
      }
      div:last-child {
        display: contents;
        nav {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
`;
