// Importing categoryConstants from the absolute path (`constants`) bugs.
import { categoryConstants } from '../constants';

const initState = {
  categories: [],
  brand: null,
  isLoading: false,
};

export function category(state = initState, action) {
  switch (action.type) {
    case categoryConstants.FETCH_REQUEST:
      return {
        ...state,
        brand: action.brand || null,
        client: action.client || null,
        isLoading: true,
      };
    case categoryConstants.FETCH_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        brand: action.brand || null,
        client: action.client || null,
        isLoading: false,
      };
    case categoryConstants.FETCH_FAILURE:
      return {
        ...state,
        brand: action.brand || null,
        client: action.client || null,
        isLoading: false,
      };
    default:
      return state;
  }
}
