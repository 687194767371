import styled from 'styled-components';
import background from 'styles/assets/images/Connexion.png';

export default (Component) => styled(Component)`
padding: 22px;
position: relative;
width:100%;
background: url(${background}) ; 
min-height: 100vh;
background-size: cover;
background-attachment: fixed;
background-position: center center;

.loading__container{
    position:absolute;
    top: calc( 50% - 113px );
    left: calc( 50% - 103px );
    color:#FFFFFF;
    text-align: center;

    .spinner__container{
        margin-top:20px
    }
    .loading__logo {
        width: 100px;
        height: 100px;
    }
    
    .loading__logo-text {
        font-family: Montserrat;
        font-size: 24px;
        color: azure;
        display: block;
        font-weight:600;
        margin-top:15px;
    }
    
    .loading__span {
        font-size: 14px;
        color: azure;
        font-family: Montserrat;
        display: block;
        margin: 0;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 10px; 
    }
      
}
}
`;
