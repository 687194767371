import styled from 'styled-components';

export default (Component) => styled(Component)`
  text-align: right;
  padding: 10px 0;
  background: #fff;
  a,
  a:link:hover,
  a:visited,
  a:visited:hover {
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #a4a4a4;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    color: #a4a4a4;
    margin-right: 10px;
  }

  .pagination_selected {
    border-color: #00a6ff;
    color: #00a6ff;
  }
`;
