import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { modalActions } from 'actions';
import Icon from 'components/Icon/Icon';
import './ProductAddToCustomCart.scss';

class ProductAddToCustomCart extends React.Component {
  openModal = (e) => {
    e.preventDefault();
    const { openSaveModal, itemId, product } = this.props;
    const isPreorder = product && product.is_preorder === true;
    openSaveModal(itemId, product, isPreorder);
  };

  render() {
    return (
      <div className="action-bloc__favorie-add">
        <div className="action-bloc__add-favorie-item">
          <span onClick={this.openModal}>
            <Icon content="\e901" />
            <span>Ajouter à un panier type</span>
          </span>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSaveModal: (itemId, product, itemIsPreorder) =>
    dispatch(
      modalActions.open('cart', {
        modalHeader: '',
        itemId,
        product,
        itemIsPreorder,
        width: 600,
        showList: true,
      })
    ),
});

ProductAddToCustomCart.propTypes = {
  openSaveModal: PropTypes.func,
  product: PropTypes.object,
  itemId: PropTypes.number,
};

export default connect(null, mapDispatchToProps)(ProductAddToCustomCart);
