import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import screen from 'components/Core/Utils/Device';
import { getCartsById, getItemCartQuantity } from 'helpers';

const ProductQuantityTextTD = styled.td`
  span {
    display: none;
    @media all and ${screen.tablet} {
      display: inline-block;
      margin-right: 5px;
    }
  }
  .td-libelle-quantite {
    min-width: 150px;
  }
`;

const ProductQuantityTextDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;
  display: none;
  span {
    display: none;
    @media all and ${screen.tablet} {
      display: inline-block;
      margin-right: 5px;
    }
  }
`;

class ProductQuantityText extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  getEffectiveCart() {
    const { current, filter } = this.props;

    if (filter) {
      const { cart: cartId } = filter;
      if (cartId) {
        const carts = getCartsById(cartId);
        if (carts && carts.length) {
          return carts[0];
        }
      }
    }
    // Fall back to current
    return current;
  }

  render() {
    const { className, shouldShowResponsive, productId } = this.props;
    const effectiveCart = this.getEffectiveCart();
    const storeQuantity = getItemCartQuantity(productId, effectiveCart);
    return !shouldShowResponsive ? (
      <ProductQuantityTextTD className={className}>
        <div>
          <span>Quantités :</span>
          {storeQuantity || 0}
        </div>
      </ProductQuantityTextTD>
    ) : (
      <ProductQuantityTextDIV className={className}>
        <div>
          <span>Quantités :</span>
          {storeQuantity || 0}
        </div>
      </ProductQuantityTextDIV>
    );
  }
}

function mapStateToProps(state) {
  const { cart } = state;
  const { current } = cart;
  return {
    current,
  };
}

ProductQuantityText.propTypes = {
  filter: PropTypes.object,
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  productId: PropTypes.number,
  current: PropTypes.object,
};

export default connect(mapStateToProps)(ProductQuantityText);
