import React from 'react';
import { connect } from 'react-redux';
import { getPath } from 'helpers/Routes';
import { history } from 'helpers/History';
import { modalActions } from 'actions';
import modalWithStyle from './ModalWrapper.style';

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.modalWrapper = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleCloseErrorCartModal = (closeModal, alreadyValid) => {
    closeModal();
    if (alreadyValid) {
      history.push(getPath('orderList').replace(':status', 'STATUS_APPROVED'));
    }
  };

  handleClick = (e) => {
    const { current: refNode } = this.modalWrapper;
    const { closeModal, ...props } = this.props;
    if (!refNode.contains(e.target) || refNode === e.target) {
      this.handleCloseErrorCartModal(closeModal, props.alreadyValid);
    }
  };

  render() {
    const { className, component: ModalComponent, modalHeader, closeModal, ...props } = this.props;

    return (
      <div className={className} ref={this.modalWrapper}>
        <div className="modal-content">
          <div>
            {!props.hideClose && (
              <span
                className="close icon-cross"
                onClick={() => this.handleCloseErrorCartModal(closeModal, props.alreadyValid)}
              />
            )}
          </div>
          {modalHeader && <p className="modal-header">{modalHeader}</p>}
          <div className="modal-component">
            <ModalComponent
              {...props}
              closeModalErrorCart={() =>
                this.handleCloseErrorCartModal(closeModal, props.alreadyValid)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.close()),
});

export default connect(null, mapDispatchToProps)(modalWithStyle(ModalWrapper));
