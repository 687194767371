// Importing dayOffConstants from the absolute path (`constants`) bugs.
import { dayOffConstants } from '../constants';

const initState = {
  collections: [],
};

export function dayOff(state = initState, action) {
  if (!Object.keys(dayOffConstants).find((key) => dayOffConstants[key] === action.type)) {
    return state;
  }

  let { collections } = state;
  let { filter } = action;

  if (!filter) {
    filter = {};
  }

  let collection = collections.find((m) => JSON.stringify(m.filter) === JSON.stringify(filter));

  if (!collection) {
    collection = { filter, isLoading: true };
    collections.push(collection);
  }
  switch (action.type) {
    case dayOffConstants.FETCH_SUCCESS:
      collection = Object.assign(collection, { items: action.items, isLoading: false });
      collections = Object.assign([collection], collections);
      return {
        ...state,
        collections,
      };
    case dayOffConstants.FETCH_FAILURE:
      return state;
    case dayOffConstants.DELETE_REQUEST:
      collections
        .filter((m) => {
          const { items } = m;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.dayOffId)) > -1
            : false;
        })
        .forEach((m) => {
          const { items } = m;
          const newItems = items
            ? items.filter((i) => Number(i.id) !== Number(action.dayOffId))
            : [];
          collections = Object.assign(
            [Object.assign(m, { items: newItems, isLoading: false })],
            collections
          );
        });
      return {
        ...state,
        collections,
      };
    case dayOffConstants.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case dayOffConstants.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case dayOffConstants.UPDATE_REQUEST:
      collections
        .filter((m) => {
          const { items } = m;
          return items
            ? items.findIndex((i) => Number(i.id) === Number(action.dayOff.id)) > -1
            : false;
        })
        .forEach((m) => {
          const { items } = m;
          const newMessages = [...items];
          const newMessage = newMessages.find((i) => Number(i.id) === Number(action.dayOff.id));
          Object.assign(newMessage, action.dayOff);
          collections = Object.assign(
            [Object.assign(m, { items: newMessages, isLoading: false })],
            collections
          );
        });
      return {
        ...state,
        collections,
      };
    case dayOffConstants.UPDATE_SUCCESS:
      return {
        ...state,
      };
    case dayOffConstants.UPDATE_FAILURE:
      return { ...state };
    case dayOffConstants.CREATE_REQUEST:
      return {
        ...state,
      };
    case dayOffConstants.CREATE_SUCCESS:
      collection = Object.assign(collection, {
        items: [...(collection.items || []), action.dayOff],
        isLoading: false,
      });
      collections = Object.assign([collection], collections);

      return {
        ...state,
        dayOff: action.dayOff,
        collections,
      };
    case dayOffConstants.CREATE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
