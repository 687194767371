import styled from 'styled-components';

export default (Component) => styled(Component)`
  padding: 22px;
  background: #f5f5f5;
  .page-dayOff__section-label {
    padding-left: 26px;
    padding-right: 26px;
    span {
      color: #01366b;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .actions {
    text-align: right;
    padding: 11px 0;
    .dayOff {
      color: #fff;
      background-color: #01366b;
      border: none;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.06px;
      line-height: 16px;
      padding: 11px 17px;

      i {
        font-size: 18px;
        padding-right: 5px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  .group-dayOff__content {
    margin-top: 28px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    .page-dayOff__content {
      margin-bottom: 28px;
      width: 25%;
      padding: 0 15px;
      .dayOff__month {
        background: #fff;
        height: 100%;
        border: 1px solid #dbdee7;
        .head-dayOff {
          padding: 10px 15px;
          background: #dbdee7;
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            color: #01366b;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
          }
          .delete__all {
            display: inline-block;
            font-size: 11px;
            cursor: pointer;
            background: #a70000;
            height: 100%;
            top: 0;
            right: 0;
            color: #fff;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 4.5px;
          }
        }
        .dayOff__date {
          padding: 15px;
          span {
            color: #01366b;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1.06px;
            line-height: 12px;
            border-radius: 4.5px;
            background: #efefef;
            padding: 8px 38px 10px 10px;
            width: 100%;
            vertical-align: middle;
            display: inline-block;
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
          }
          i {
            vertical-align: middle;
            display: inline-block;
            font-size: 11px;
            cursor: pointer;
            position: absolute;
            background: #a70000;
            height: 100%;
            top: 0;
            right: 0;
            color: #fff;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            border-radius: 4.5px;
          }
        }
      }
    }
  }
`;
