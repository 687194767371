import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const clientService = {
  fetchTotalRowNumber,
  fetchClients,
  fetchClientsSSRM,
  updateClientDetail,
  fetchClientDetail,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/client/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchClients(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  // const urlFilter = JSON.stringify(filter);

  return fetch(
    `${config.apiUrl}/api/v2.0/client?platform=${filter.platform}&offset=${offset}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function fetchClientsSSRM(platform, filterModel, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };
  const jsonFilterModel = JSON.stringify(filterModel);
  return fetch(
    `${config.apiUrl}/api/v2.0/client?pg=1&platform=${platform}&offset=${offset}&limit=${limit}&filterModel=${jsonFilterModel}`,
    requestOptions
  ).then(handleResponse);
}

function updateClientDetail(id, data, activate = false) {
  const body = JSON.stringify({
    id,
    ...data,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...authHeader(false),
  };
  const requestOptions = {
    method: 'PUT',
    headers,
    body,
    mode: 'cors',
  };
  return fetch(
    `${config.apiUrl}/api/client/update?activate=${Number(activate)}`,
    requestOptions
  ).then(handleResponse);
}

function fetchClientDetail(clientId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(false),
    mode: 'cors',
  };

  return fetch(`${config.apiUrl}/api/client/${clientId}`, requestOptions).then(handleResponse);
}
