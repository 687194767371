import React, { useState, useEffect, useCallback } from 'react';
import ModalSaveRecurrentWrapper from 'components/Modal/ModalSaveRecurrentCart.style';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { cartActions, modalActions, recurrentCartActions } from 'actions';
import { connect } from 'react-redux';
import { recurrentCartConstants } from 'constants/RecurrentCart.constants';
import Calendar from 'components/Core/Calendar/Calendar';
import Moment from 'moment';
import { selectRecurentCartData } from 'selectors/recurrentCart';
import { selectCurrentUser } from 'selectors/user';
import { selectCurrentPlatform } from 'selectors/platform';
import { toastActions } from 'actions/Toast';
import { toastConstants } from 'constants/Toast.constants';
import { getFormatedDate } from 'helpers/Utils';
import { cartConstants } from '../../constants';

const ModalUpdateRecurrentCart = ({
  cartId,
  updateRecurrentCart,
  closeModal,
  recurrentCartList,
  fetchRecurrentCartList,
  user,
  selectedPlatform,
  addRecurrentCart,
  deleteRecurrentCart,
  pushToast,
}) => {
  const [currentRecurrentCartList, setCurrentRecurrentCartList] = useState([]);
  const [currentTemperature, setCurrentTemperature] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [days, setDays] = useState([]);
  const [initialDays, setInitialDays] = useState([]);
  const [beginDate, setBeginDate] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState(null);

  useEffect(() => {
    if (!recurrentCartList.length) {
      fetchRecurrentCartList();
    }
  }, []);

  useEffect(() => {
    if (!currentRecurrentCartList.length) {
      const parsedRecurrentListByCart = getRecurrentCartByCartId();
      const newCurrentRecurrentCartList = parsedRecurrentListByCart.find(
        (item) => item.cartId === cartId
      );
      setCurrentRecurrentCartList(newCurrentRecurrentCartList);

      if (
        newCurrentRecurrentCartList.recurrentCartList &&
        newCurrentRecurrentCartList.recurrentCartList.length
      ) {
        const currentRecurrentCart = newCurrentRecurrentCartList.recurrentCartList[0];
        const newDays = newCurrentRecurrentCartList.recurrentCartList.map(
          (recurrentCart) => recurrentCart.day
        );
        setName(currentRecurrentCart.name);
        setEmail(currentRecurrentCart.email);
        setFrequency(currentRecurrentCart.frequency);
        setBeginDate(currentRecurrentCart.begin_date);
        setDays(newDays);
        setInitialDays(newDays);
        setCurrentTemperature(currentRecurrentCart.cart.cart_items[0].item_type);
      }
    }
  }, [recurrentCartList]);

  const getRecurrentCartByCartId = () => {
    const grouped = [];
    recurrentCartList.forEach((recurrentCart) => {
      const index = grouped.findIndex((item) => item.cartId === recurrentCart.cart.id);
      if (index > -1) {
        grouped[index].recurrentCartList.push(recurrentCart);
      } else {
        grouped.push({
          cartId: recurrentCart.cart.id,
          recurrentCartList: [recurrentCart],
        });
      }
    });

    return grouped;
  };

  const getCurrentDeliveryPlanningDays = useCallback(
    (temperature) => {
      return user.client.delivery_plannings
        .find(
          (deliveryPlanning) =>
            deliveryPlanning.temperature === temperature &&
            deliveryPlanning.platform_id === selectedPlatform.id
        )
        .days.map((day) => day.week_day);
    },
    [user]
  );

  const handleNameChange = useCallback((event) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setName(event.target.value);
      setErrorDisplay(null);
    } else {
      setName(event.target.value);
      setErrorDisplay('Merci de donner un nom à cette commande');
    }
  });

  const handleEmailChange = useCallback((event) => {
    if (
      event.target.value &&
      event.target.value !== '' &&
      /^\w+([\x2E-]?\w+)*@\w+([\x2E-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
    ) {
      setEmail(event.target.value);
      setErrorDisplay(null);
    } else {
      setEmail(event.target.value);
      setErrorDisplay('Votre email est invalide');
    }
  });

  const handleFrequencyChange = useCallback((event) => {
    if (event.target.value && event.target.value !== '' && event.target.value > 0) {
      setFrequency(event.target.value);
      setErrorDisplay(null);
    } else {
      setErrorDisplay('Vous devez insérer une fréquence supérieure à zéro');
    }
  });

  const handleBeginDateChange = useCallback((date) => {
    if (date && date !== '' && Moment(date, 'YYYY-MM-DD').isValid()) {
      setBeginDate(Moment(date).format('YYYY-MM-DD'));
    } else {
      setBeginDate('');
      setErrorDisplay('Merci de sélectionner une date de démarrage');
    }
  }, []);

  const handleDaysChange = useCallback((value) => {
    if (days.includes(value)) {
      return setDays(days.filter((day) => day !== value));
    }
    return setDays([...days, value]);
  });

  const handleUpdateRecurrentCart = () => {
    const daysToAdd = days.filter((day) => !initialDays.includes(day));
    const dayToRemove = initialDays.filter((day) => !days.includes(day));
    const recurrentCartIdListToRemove = currentRecurrentCartList.recurrentCartList
      .map((recurrentCart) => (dayToRemove.includes(recurrentCart.day) ? recurrentCart.id : null))
      .filter((value) => value);
    const recurrentCartIdListToUpdate = currentRecurrentCartList.recurrentCartList
      .map((recurrentCart) =>
        !recurrentCartIdListToRemove.includes(recurrentCart.id) ? recurrentCart.id : null
      )
      .filter((value) => value);

    addRecurrentCart(
      { name, frequency, begin_date: beginDate, cart: { id: cartId }, email },
      daysToAdd
    );
    recurrentCartIdListToRemove.map((recurrentCartId) => deleteRecurrentCart(recurrentCartId));
    recurrentCartIdListToUpdate.map((recurrentCartId) =>
      updateRecurrentCart(recurrentCartId, { name, frequency, begin_date: beginDate, email })
    );

    closeModal();
    pushToast({
      type: toastConstants.TYPE_SUCCESS,
      message: `Commande automatique mise à jour avec succès`,
    });

    return null;
  };

  const isErrorDisplayed = () => (errorDisplay && errorDisplay !== '') || false;

  const errorDsp = () => {
    if (errorDisplay && errorDisplay !== '') {
      return (
        <div
          className=""
          style={{
            color: 'red',
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          {errorDisplay}
        </div>
      );
    }
    return false;
  };

  return (
    <ModalSaveRecurrentWrapper>
      <div className="modal-body">
        {errorDsp()}
        <form>
          <label className="modal-save-recurrent__checkbox-title">
            Intitulé commande automatique *
          </label>
          <input onChange={handleNameChange} value={name} type="text" placeholder="Nom du panier" />
          <label className="modal-save-recurrent__checkbox-title">Email d'information *</label>
          <input
            onChange={handleEmailChange}
            value={email}
            type="text"
            placeholder="Email de notification"
          />
          <div className="modal-save-recurrent__checkbox-title">Jours de livraison *</div>
          <div className="modal-save-recurrent__checkbox-list">
            {currentTemperature &&
              recurrentCartConstants.DAYS.map((value, index) => (
                <div className="modal-save-recurrent__checkbox-wrapper">
                  <input
                    id={`${value}`}
                    type="checkbox"
                    onChange={() => handleDaysChange(index + 1)}
                    checked={days.includes(index + 1)}
                    disabled={
                      !getCurrentDeliveryPlanningDays(currentTemperature).includes(
                        String(index + 1)
                      )
                    }
                  />
                  <label
                    htmlFor={`${value}`}
                    className={
                      !getCurrentDeliveryPlanningDays(currentTemperature).includes(
                        String(index + 1)
                      ) && 'disabled-text'
                    }
                  >
                    {value}
                  </label>
                </div>
              ))}
          </div>
          <div>
            <label className="modal-save-recurrent__checkbox-title">Récurrence *</label>
            <br />
            Toutes les{' '}
            <input
              type="text"
              onChange={handleFrequencyChange}
              value={frequency}
              className="modal-save-recurrent__week-input"
            />{' '}
            semaines
          </div>
          <div>
            <label className="modal-save-recurrent__checkbox-title">À partir du *</label>
            <Calendar
              onChange={handleBeginDateChange}
              selected={beginDate ? Moment(beginDate).toDate() : ''}
              filterDate={(date) => getFormatedDate(date) > getFormatedDate(new Date())}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/aaaa"
            />
          </div>
          {isErrorDisplayed() ? (
            <ButtonPrimary disabled={isErrorDisplayed()}>Enregistrer</ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={handleUpdateRecurrentCart}>Enregistrer</ButtonPrimary>
          )}
        </form>
      </div>
    </ModalSaveRecurrentWrapper>
  );
};

const mapStateToProps = (state) => ({
  recurrentCartList: selectRecurentCartData(state),
  user: selectCurrentUser(state),
  selectedPlatform: selectCurrentPlatform(state),
});

const mapDispatchToProps = (dispatch) => ({
  addRecurrentCart: (data, days) => dispatch(recurrentCartActions.createRecurrentCart(data, days)),
  deleteRecurrentCart: (recurrentCartId) =>
    dispatch(recurrentCartActions.deleteRecurentCart(recurrentCartId)),
  updateRecurrentCart: (recurrentCartId, data) =>
    dispatch(recurrentCartActions.updateRecurrentCart(recurrentCartId, data)),
  fetchRecurrentCartList: () => dispatch(recurrentCartActions.fetchRecurrentCartList(0, 4000)),
  copyCart: (cartId) =>
    dispatch(cartActions.copyCart(cartId, cartConstants.CART_STATUS_CUSTOM_RECURRING, '', false)),
  closeModal: () => dispatch(modalActions.close()),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateRecurrentCart);
