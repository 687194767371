import React, { useState, useEffect, useCallback } from 'react';
import ModalSaveRecurrentWrapper from 'components/Modal/ModalSaveRecurrentCart.style';
import { ButtonPrimary } from 'components/Core/Button/Button';
import { modalActions, recurrentCartActions } from 'actions';
import { connect } from 'react-redux';
import { recurrentCartConstants } from 'constants/RecurrentCart.constants';
import { getCollection, getPath, history } from 'helpers';
import Calendar from 'components/Core/Calendar/Calendar';
import { selectCurrentPlatform } from 'selectors/platform';
import { selectCurrentUser } from 'selectors/user';
import { cartService } from 'services';
import Moment from 'moment';
import { toastConstants } from 'constants/Toast.constants';
import { toastActions } from 'actions/Toast';
import { getFormatedDate } from 'helpers/Utils';
import { cartConstants } from '../../constants';

const ModalSaveRecurrentCart = ({
  cart,
  addRecurrentCart,
  withRedirect,
  closeModal,
  user,
  selectedPlatform,
  pushToast,
}) => {
  const [name, setName] = useState(null);
  const [addRecurringProcessing, setAddRecurringProcessing] = useState(null);
  const [email, setEmail] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [days, setDays] = useState({});
  const [beginDate, setBeginDate] = useState(null);
  const [succeedRequestCount, setSucceedRequestCount] = useState(0);
  const [errorDisplay, setErrorDisplay] = useState(null);

  useEffect(() => {
    if (succeedRequestCount >= Object.keys(cart.totals_by_type).length) {
      setAddRecurringProcessing(null);
      if (withRedirect) {
        history.push(getPath('recurrentCartList'));
      }
      closeModal();
    }
  }, [succeedRequestCount]);

  const handleNameChange = useCallback((event) => {
    if (event.target.value.trim() !== '') {
      setName(event.target.value);
      setErrorDisplay(null);
    } else {
      setErrorDisplay('lintitulé de votre commande est invalide');
    }
  });

  const handleEmailChange = useCallback((event) => {
    if (
      event.target.value &&
      event.target.value !== '' &&
      /^\w+([\x2E-]?\w+)*@\w+([\x2E-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
    ) {
      setEmail(event.target.value);
      setErrorDisplay(null);
    } else {
      setEmail(event.target.value);
      setErrorDisplay('Votre email est invalide');
    }
  });

  const handleFrequencyChange = useCallback((event) => {
    if (event.target.value && event.target.value !== '' && event.target.value > 0) {
      setFrequency(event.target.value);
      setErrorDisplay(null);
    } else {
      setErrorDisplay('Vous devez insérer une fréquence supérieure à zéro');
    }
  });

  const handleBeginDateChange = useCallback((date) => {
    if (date && date !== '' && Moment(date, 'YYYY-MM-DD').isValid()) {
      setBeginDate(Moment(date).format('YYYY-MM-DD'));
      setErrorDisplay(null);
    } else {
      setBeginDate('');
      setErrorDisplay('Merci de sélectionner une date de démarrage');
    }
  }, []);

  const handleDaysChange = useCallback((value, temperature) => {
    const newDays = days;
    if (days[temperature] && days[temperature].includes(value)) {
      newDays[temperature] = newDays[temperature].filter((day) => day !== value);
      if (newDays[temperature].length === 0) {
        setErrorDisplay('Vous devez sélectionner au moins un jour de livraison');
        return setDays([]);
      }
      setErrorDisplay(null);
      return setDays(newDays);
    }
    newDays[temperature] = newDays[temperature] ? [...newDays[temperature], value] : [value];

    if (newDays[temperature].length === 0) {
      setErrorDisplay('Vous devez sélectionner au moins un jour de livraison');
      return setDays([]);
    }
    setErrorDisplay(null);
    return setDays(newDays);
  });

  const handleAddRecurrentCartClick = useCallback(() => {
    let succeedRequest = succeedRequestCount;
    setAddRecurringProcessing(true);
    Object.keys(cart.totals_by_type).map((temperature) => {
      const currentTemperatureCartItems = cart.cart_items.filter(
        (cartItem) => cartItem.item_type === temperature
      );

      cartService
        .createCart(
          selectedPlatform.id,
          cartConstants.CART_STATUS_CUSTOM_RECURRING,
          `${name} ${temperature}`,
          currentTemperatureCartItems.map(({ item, quantity }) => ({
            ...item,
            quantity,
          }))
        )
        .then(
          (cart) => {
            addRecurrentCart(
              {
                name: `${name} ${temperature}`,
                frequency: Number(frequency),
                begin_date: `${beginDate}`,
                email,
                cart: { id: cart.id },
              },
              days[temperature],
              () => {
                succeedRequest += 1;
                setSucceedRequestCount(succeedRequest);
                pushToast({
                  type: toastConstants.TYPE_SUCCESS,
                  message: `Commande automatique ${temperature} créée avec succès`,
                });
              }
            );
          },
          (error) => {
            return error;
          }
        );

      return null;
    });

    return null;
  }, [cart, days, frequency, name, selectedPlatform, succeedRequestCount, beginDate]);

  const getCurrentDeliveryPlanningDays = useCallback(
    (temperature) => {
      return user.client.delivery_plannings
        .find(
          (deliveryPlanning) =>
            deliveryPlanning.temperature === temperature &&
            deliveryPlanning.platform_id === selectedPlatform.id
        )
        .days.map((day) => day.week_day);
    },
    [user]
  );

  const isSendButtonDisabled = useCallback(() => {
    let isAllDayFilled = true;
    Object.keys(cart.totals_by_type).map((temperature) => {
      if (!days[temperature] || !days[temperature].length || days[temperature].length === 0) {
        isAllDayFilled = false;
      }

      return null;
    });

    let isEmailValid = true;
    if (email && email !== '' && /^\w+([\x2E-]?\w+)*@\w+([\x2E-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      isEmailValid = false;
    }
    // if (addRecurringProcessing) return true;

    return !name || isEmailValid || !isAllDayFilled || !frequency || !beginDate;
  }, [name, email, cart, days, frequency, beginDate]);

  const errorDsp = () => {
    if (errorDisplay && errorDisplay !== '') {
      return (
        <div
          className=""
          style={{
            color: 'red',
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          {errorDisplay}
        </div>
      );
    }
    return false;
  };

  return (
    <ModalSaveRecurrentWrapper>
      <div className="modal-body">
        {errorDsp()}
        <form>
          <label className="modal-save-recurrent__checkbox-warning-about-quantity">
            (Les références avec une quantité à 0 ne sont pas enregistrées dans votre commande
            automatique. Veuillez vérifier les quantités.)
          </label>
          <label className="modal-save-recurrent__checkbox-title">
            Intitulé commande automatique *
          </label>
          <input onChange={handleNameChange} type="text" placeholder="Nom du panier" />
          <div className="modal-save-recurrent__checkbox-title">Email d'information *</div>
          <input onChange={handleEmailChange} type="text" placeholder="Email de notification" />
          {Object.keys(cart.totals_by_type).map((temperature) => (
            <div>
              <div className="modal-save-recurrent__checkbox-title">
                Jours de livraison {temperature.toLowerCase()} *
              </div>
              <div className="modal-save-recurrent__checkbox-list">
                {recurrentCartConstants.DAYS.map((value, index) => (
                  <div className="modal-save-recurrent__checkbox-wrapper">
                    <input
                      id={`${value}-${temperature}`}
                      type="checkbox"
                      onChange={() => handleDaysChange(index + 1, temperature)}
                      disabled={
                        !getCurrentDeliveryPlanningDays(temperature).includes(String(index + 1))
                      }
                    />
                    <label
                      htmlFor={`${value}-${temperature}`}
                      className={
                        !getCurrentDeliveryPlanningDays(temperature).includes(String(index + 1)) &&
                        'disabled-text'
                      }
                    >
                      {value}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div>
            <label className="modal-save-recurrent__checkbox-title">Récurrence *</label>
            <br />
            Toutes les{' '}
            <input
              type="text"
              onChange={handleFrequencyChange}
              className="modal-save-recurrent__week-input"
            />{' '}
            semaines *
          </div>
          <div>
            <label className="modal-save-recurrent__checkbox-title">À partir du *</label>
            <Calendar
              onChange={handleBeginDateChange}
              selected={beginDate ? Moment(beginDate).toDate() : ''}
              filterDate={(date) =>
                getFormatedDate(date) >
                getFormatedDate(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000))
              }
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/aaaa"
            />
          </div>
          {isSendButtonDisabled() && (
            <ButtonPrimary disabled={isSendButtonDisabled}>Enregistrer</ButtonPrimary>
          )}
          {!isSendButtonDisabled() && (
            <ButtonPrimary disabled={addRecurringProcessing} onClick={handleAddRecurrentCartClick}>
              Enregistrer
            </ButtonPrimary>
          )}
        </form>
      </div>
    </ModalSaveRecurrentWrapper>
  );
};

const mapStateToProps = (state) => {
  const { dayOff } = state;

  const selectedPlatform = selectCurrentPlatform(state);
  const dayOffCollection = getCollection(
    dayOff.collections || [],
    selectedPlatform ? { platform: selectedPlatform.id } : {}
  );
  return {
    user: selectCurrentUser(state),
    selectedPlatform,
    dayOff: dayOffCollection.items || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  addRecurrentCart: (data, days, successCallback) =>
    dispatch(recurrentCartActions.createRecurrentCart(data, days, successCallback)),
  closeModal: () => dispatch(modalActions.close()),
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSaveRecurrentCart);
