/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import LinearLoader from 'components/Core/Spinner/LinearLoader';
import CheckBox from './CheckBox';

const FormSection = ({ name, sectionKey, options, selectedValues, updateFilter, isLoading }) => (
  <div className="filteropen__catalogue">
    <h5>{name}</h5>
    {options.length > 1 && (
      <div className="filter-item block__radio ">
        <label>
          <input
            name={`${sectionKey}-all`}
            type="radio"
            checked={selectedValues.length === 0 || selectedValues.length === options.length}
            onClick={() => updateFilter(sectionKey, [])}
            disabled={selectedValues.length === options.length}
          />
          <span>Tout</span>
          <span className="check" />
        </label>
      </div>
    )}
    {options.slice(0, 2).map(({ label, value }) => {
      const isChecked = selectedValues.includes(value);
      const updateValues = isChecked
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      return (
        <CheckBox
          handleCheck={() => updateFilter(sectionKey, updateValues)}
          name={label}
          value={value}
          isChecked={isChecked}
        />
      );
    })}
    {isLoading && <LinearLoader />}
    {options.slice(2).map(({ label, value }) => {
      const isChecked = selectedValues.includes(value);
      const updateValues = isChecked
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      return (
        <CheckBox
          handleCheck={() => updateFilter(sectionKey, updateValues)}
          name={label}
          value={value}
          isChecked={isChecked}
        />
      );
    })}
  </div>
);

FormSection.propTypes = {
  name: PropTypes.string,
  sectionKey: PropTypes.string,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  updateFilter: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default FormSection;
