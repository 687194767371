/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'components/Core/Spinner/Spinner';
import modalContactsWithStyle from './ModalContacts.style';

const ModalContacts = ({ className, client, isLoading, platform }) => {
  const clientNodes =
    client &&
    client.commercials
      .filter(
        (commercial) =>
          commercial.platform && Number(commercial.platform.id) === Number(platform.selectedId)
      )
      .map((commercial) => (
        <div key={commercial.id}>
          <div className="modal-headerline">
            <h2 className="modal-header-text ">{commercial.type}</h2>
          </div>
          <div className="modal-body">
            <p>{`${commercial.firstname || ''} ${commercial.lastname || ''}`}</p>
            <p>
              {commercial.tel_number1 ? (
                <span>
                  Tél: <a href={`tel:${commercial.tel_number1}`}>{commercial.tel_number1}</a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {commercial.fax ? (
                <span>
                  Fax: <a href={`fax:${commercial.fax}`}>{commercial.fax}</a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {commercial.email ? (
                <span>
                  Email: <a href={`mailto:${commercial.email}`}>{commercial.email}</a>
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
      ));
  const accountingNodes =
    client &&
    client.platforms
      .filter((clientplatform) => Number(clientplatform.id) === Number(platform.selectedId))
      .map((clientplatform) => (
        <div key={clientplatform.id}>
          <div className="modal-headerline">
            <h2 className="modal-header-text ">Service comptabilité</h2>
          </div>
          <div className="modal-body">
            <p>
              {clientplatform.tel_accounting ? (
                <span>
                  Tél:{' '}
                  <a href={`tel:${clientplatform.tel_accounting}`}>
                    {clientplatform.tel_accounting}
                  </a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {clientplatform.fax_accounting ? (
                <span>
                  Fax:{' '}
                  <a href={`fax:${clientplatform.fax_accounting}`}>
                    {clientplatform.fax_accounting}
                  </a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {clientplatform.email_accounting ? (
                <span>
                  Email:{' '}
                  <a href={`mailto:${clientplatform.email_accounting}`}>
                    {clientplatform.email_accounting}
                  </a>
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
      ));
  const technicalNodes =
    client &&
    client.platforms
      .filter((clientplatform) => Number(clientplatform.id) === Number(platform.selectedId))
      .map((clientplatform) => (
        <div key={clientplatform.id}>
          <div className="modal-headerline">
            <h2 className="modal-header-text ">Service technique</h2>
          </div>
          <div className="modal-body">
            <p>
              {clientplatform.tel_technical ? (
                <span>
                  Tél:{' '}
                  <a href={`tel:${clientplatform.tel_technical}`}>{clientplatform.tel_technical}</a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {clientplatform.fax_technical ? (
                <span>
                  Fax:{' '}
                  <a href={`fax:${clientplatform.fax_technical}`}>{clientplatform.fax_technical}</a>
                </span>
              ) : (
                ''
              )}
            </p>
            <p>
              {clientplatform.email_technical ? (
                <span>
                  Email:{' '}
                  <a href={`mailto:${clientplatform.email_technical}`}>
                    {clientplatform.email_technical}
                  </a>
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
      ));

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={className}>
      {clientNodes.length ? (
        clientNodes
      ) : (
        <p className="client-commercials__center">Aucun commercial ne vous est encore attribué.</p>
      )}
      {accountingNodes.length ? (
        accountingNodes
      ) : (
        <p className="client-commercials__center">
          Aucun Service comptabilté ne vous est encore attribué.
        </p>
      )}
      {technicalNodes.length ? (
        technicalNodes
      ) : (
        <p className="client-commercials__center">
          Aucun Service technique ne vous est encore attribué.
        </p>
      )}
    </div>
  );
};

ModalContacts.propTypes = {
  className: PropTypes.string,
  client: PropTypes.array,
  platform: PropTypes.object,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    user: { information, isLoading: userLoading },
    platform,
  } = state;
  const { client } = information || {};
  return {
    client,
    platform,
    isLoading: userLoading || platform.isLoading,
  };
};

export default connect(mapStateToProps)(modalContactsWithStyle(ModalContacts));
