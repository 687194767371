import { combineReducers } from 'redux';
import { appConstants } from '../constants';
import { authentication } from './Authentication';
import { alert } from './Alert';
import { cart } from './Cart';
import { cartItems } from './CartItems';
import { user } from './User';
import { order } from './Order';
import { orderItems } from './OrderItems';
import { platform } from './Platform';
import { catalog } from './Catalog';
import { category } from './Category';
import { modal } from './Modal';
import { filter } from './Filter';
import { product } from './Product';
import { search } from './Search';
import { client } from './Client';
import { assortment } from './Assortment';
import { prospect } from './Prospect';
import { deliveryPlanning } from './DeliveryPlanning';
import { message } from './Message';
import { dayOff } from './DayOff';
import { commercial } from './Commercial';
import { terms } from './Terms';
import { brand } from './Brand';
import { container } from './ContainerV2';
import { moq } from './Moq';
import { credit } from './Credit';
import { manufacturer } from './Manufacturer';
import { invoice } from './Invoice';
import { invoiceItems } from './InvoiceItems';
import { loading } from './Loading';
import { productCard } from './ProductCard';
import { aggridSearchBar } from './AggridSearchBar';
import { aggridCategoryDisplay } from './AggridCategoryDisplay';
import { aggridFilter } from './AggridFilter';
import { toast } from './Toast';
import { ui } from './Ui';
import { maintenance } from './Maintenance';
import { ocrOrderPdf } from './OcrOrderPdf';
import { article } from './Article';
import { articleGroup } from './ArticleGroup';
import { articleGroupItem } from './ArticleGroupItem';
import { promotion } from './Promotion';
import { recurrentCart } from './RecurrentCart';
import { pageContext } from './PageContext';

const appReducer = combineReducers({
  authentication,
  alert,
  cart,
  cartItems,
  user,
  order,
  orderItems,
  platform,
  catalog,
  category,
  modal,
  filter,
  product,
  search,
  client,
  assortment,
  prospect,
  deliveryPlanning,
  message,
  dayOff,
  commercial,
  terms,
  brand,
  moq,
  credit,
  container,
  manufacturer,
  invoice,
  invoiceItems,
  loading,
  productCard,
  aggridSearchBar,
  aggridCategoryDisplay,
  aggridFilter,
  toast,
  ui,
  maintenance,
  ocrOrderPdf,
  article,
  articleGroup,
  articleGroupItem,
  promotion,
  recurrentCart,
  pageContext,
});

const rootReducer = (state, action) => {
  if (action.type === appConstants.RESET) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
