export const tableTypes = {
  TABLE_TYPE_CATALOG: 'catalog',
  TABLE_TYPE_ORDER: 'order',
  TABLE_TYPE_ORDER_ITEMS: 'orderItems',
  TABLE_TYPE_INVOICE_ITEMS: 'invoiceItems',
  TABLE_TYPE_CREDIT_ITEMS: 'credit',
  TABLE_TYPE_CART_ITEMS: 'cartItems',
  TABLE_TYPE_CART: 'cart',
  TABLE_TYPE_SEARCH: 'search',
  TABLE_TYPE_SEARCH_CLIENT: 'searchClient',
  TABLE_TYPE_CLIENT: 'client',
  TABLE_TYPE_MESSAGE: 'message',
  TABLE_TYPE_TERMS: 'terms',
  TABLE_TYPE_COMMERCIAL: 'commercial',
  TABLE_TYPE_ASSORTMENT: 'assortment',
};
export const sectionsTypes = {
  SECTION_TYPE_CATEGORY: 'category',
  SECTION_TYPE_TEMPERATURE: 'temperature',
  SECTION_TYPE_ORDER: 'order',
};
