import styled, { keyframes } from 'styled-components';

export const StyledWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  min-height: 20px;
  bottom: -10px;
  left: 0px
  right: 0px;
  width: 100%;
  z-index: 1;
  transform: translateY(100%);
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 0.15s linear;
  animation-fill-mode: forwards;
  transition: visibility 0.15s linear;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.2),
              0 0 4px 0 rgba(0, 0, 0, 0.1),
              inset 0 0 0 1px rgba(255, 255, 255, 0.2);

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 12px;
    color: #262626;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }

    span {
      flex: 1;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
