// Importing userConstants from the absolute path (`constants`) bugs.
import { userConstants } from '../constants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user, cookie: document.cookie }
  : {
      user: {},
      isLoggedIn: false,
      isLoggingIn: false,
      isSubmittingNewPassword: false,
      isRecoverySuccessful: false,
      hasChangedPassword: false,
      cookie: '',
    };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        isSubmittingNewPassword: false,
        isRecoverySuccessful: false,
        hasChangedPassword: false,
        user: action.user,
        cookie: action.cookie,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        error: action.message,
      };
    case userConstants.RECOVER_PASSWORD_REQUEST:
      return {
        ...state,
        isSubmittingUsername: true,
      };
    case userConstants.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        isRecoverySuccessful: true,
      };
    case userConstants.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmittingUsername: false,
        isRecoverySuccessful: false,
        error: action.message,
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isSubmittingNewPassword: true,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        hasChangedPassword: true,
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmittingNewPassword: false,
        hasChangedPassword: false,
        error: action.message,
      };
    case userConstants.LOGIN_AS:
      return {
        ...state,
        loggedAs: action.username,
      };
    case userConstants.LOGOUT_AS:
      return {
        ...state,
        loggedAs: undefined,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        loggedAs: false,
        user: {},
      };
    default:
      return state;
  }
}
