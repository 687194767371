import React from 'react';
import { IoStatsChart } from '@react-icons/all-files/io5/IoStatsChart';

function TopSalesControl() {
  return (
    <>
      <IoStatsChart style={{ fill: '#606266' }} />
      <span style={{ whiteSpace: 'nowrap' }}>Top ventes</span>
    </>
  );
}

export default TopSalesControl;
